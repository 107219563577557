import { header, getLanguage } from "./header";

const marker = header('restapi/api/v1/marker/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    marker.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    marker.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const MarkerService = {
    getMarkerTopics(sortField = 'CREATED_AT', sortDirection = 'DESC'){
        setLanguage();
        getAuth();
        return marker.get(`topics/?sortField=${sortField}&sortDirection=${sortDirection}`).then(response => {
            return response.data
        })
    },
    getMarkerTopicBlackList(topicsId){
        setLanguage();
        getAuth();
        let topicsList = topicsId.map((k, i) => {
            let index = i === 0 ? '?' : '&'
            return index+'topics='+k
        })
        return marker.get(`topics/blacklist${topicsList.join('')}`).then(response => {
            return response
        })
    },
    addMarkerProfileToBlackList(topicId, url, domain = null){
        setLanguage();
        getAuth();
        return marker.post(`topics/${topicId}/blacklist`, url ? {url} : {domain}).then(response => {
            return response
        })
    },
    removeMarkerProfileFromBlackList(topicId, blacklistId){
        setLanguage();
        getAuth();
        return marker.delete(`topics/${topicId}/blacklist/${blacklistId}`).then(response => {
            return response
        })
    },
}