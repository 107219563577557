import TelegramController from "./telegram-controller";
import { connect } from "react-redux";
import { getValue } from "../../../../store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "../../../../store/side-bar";
import { getTopicName, getSubtopicsFromState, getSubtopicTableFromState,getSubtopicItem } from "../../../../selectors/theme-selector";
import { setSelectedTelegramItem, getSubscriptions, deleteSubscription,createSubscription, setSelectedTelegramTopic, getSubscriptionAlertPolicies, copySubscription, setSubscriptionPolicy, setSubscriptionEmails, resumePauseSubscription } from "../../../../store/telegram"
import { getMyTopics } from "../../../../store/theme-settings";
import { getSubscriptionSelector } from "../../../../selectors/subscription";
import { getAllFilters } from "../../../../store/filters";
import { getSubtopics, setSubtopicsUpdated, setSubtopicItem } from "../../../../store/topic-item";

let mapStateToProps = (state) => {
    return {
        isDataUpdated: state.themeSettings.isDataUpdated,
        topics: getTopicName(state),
        tableHeader: state.telegram.tableHeader,
        selectedTelegramItem: state.telegram.selectedTelegramItem,
        telegramItems: getSubscriptionSelector(state),
        controlButtons: state.telegram.controlButtons,
        myFilters: state.Filters.myFilters,
        subtopics: getSubtopicsFromState(state),
        alertPolicy: state.telegram.alertPolicy,
        selectedLanguage: state.languages.selectedLanguage,
    }
}

export default 
    connect(mapStateToProps, {  getValue, 
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent, 
                                getMyTopics,
                                getAllFilters,
                                setSelectedTelegramItem, 
                                getSubscriptions,
                                deleteSubscription,
                                createSubscription,
                                setSubtopicItem,
                                setSubtopicsUpdated,
                                setSelectedTelegramTopic,
                                getSubscriptionAlertPolicies,
                                copySubscription,
                                setSubscriptionPolicy,
                                setSubscriptionEmails,
                                resumePauseSubscription,
                                getSubtopics})(TelegramController)