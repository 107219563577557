import React from "react";
import UsersSettings from "./users";
import DropdownController from "../../../../items/dropdown/dropdown-controller";
import EditUserContainer from "./edit-user/edit-user-container";

class UsersSetttingsController extends React.Component{

    constructor(props){
        super(props);
        this.tableHeaders = this.getHeaderComponents(props.tableItems.labels);
        this.values = this.props.tableItems.values
    }

    componentDidMount(){
        this.props.setRightMenuComponent(<EditUserContainer />);
        this.props.setShowRightMenu(true)
        this.props.setRightMenuComponent(<div></div>);
    }

    componentWillUnmount(){
        this.props.setRightMenuComponent(null);
    }

    componentDidUpdate(){
        //this.props.setRightMenuComponent(<EditUserContainer />)
    }

    indexSorting = (e)=>{

    }

    getHeaderComponents(headers, selected){
        
        let retHeaders = headers.map((item, i) => {
        let titles = item.items.map((title)=>{return {title: this.props.getValue(title.title), short_name: title.short_name, name: title.name}});
        let names = item.items.map((name) => {return {name: name.name}});
        let short_names = item.items.map((short_name, i) => {return {short_name: short_name.short_name}});
           return  <DropdownController
                items={titles}
                name={item.name}
                callback={this.indexSorting}
                selected={'up'}
                icon={null}
                type="Menu"
                short_name={short_names}
            />
        })
        return retHeaders
    }

    render(){
        return <UsersSettings {...this.props} header={this.tableHeaders} values={this.values}/>
    }

}

export default UsersSetttingsController;