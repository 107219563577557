import React from "react";
import ReportFormContainer from "./report-form/report-form-container";
import ReportTableContainer from "./report-table/report-table-container";
import s from "./reports.module.scss"

const Reports = (props) => {
    return (
        <div style={{display: 'flex'}} className={s.ReportsPage}>
            {props.selectedRole === "ROLE_ADMIN"
                ? <ReportTableContainer />
                : <ReportFormContainer />
            }
        </div>
    )
}

export default Reports