import { header, getLanguage } from "./header";

const accounts = header('restapi/api/v1/accounts/');

const setLanguage = () => {
    accounts.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const AuthoriztionService = {
    createAccount(login, fio, email, phone = null, password){
        
        let params = {login, fio, email, password}
        if(phone)
            params.phone = phone;
        setLanguage();
        return accounts.post('', {...params}).then(response => {
            return response;
        })
    },
    loginAccount(login, password){
        setLanguage();
        return accounts.post('login', {login, password}).then(response => {
            return response;
        })
    },
    resetPassword(request){
        setLanguage();
        return accounts.post('reset', request).then((response => {
            return response;
        }))
    }
}