import { createSelector } from "reselect";
import moment from "moment";
import { getFormatDate } from "service/format-date";

const tags = (state) => {
    return state.Tags.tags;
}

const formatDate = (date) =>{
    return getFormatDate(date, "HH:mm DD.MM.YY");
    //return moment(date).format("DD.MM.YY HH:mm");
}

export const getTags = createSelector(tags, (tags) => {
    let companyTags = []
    if(tags){
        companyTags = tags.map( k => {
            return  [k.id, k.name, k.color, k.textColor, formatDate(k.createdAt), formatDate(k.updatedAt), k.uuid]
                
        });
    }

    return {data: companyTags, tags}
})

export const getTagsName = createSelector(tags, (tags) => {
    let tagsR = [];
    if(tags){
        tagsR = tags.map( k => {
            k 
        })
    }
    return tagsR
})