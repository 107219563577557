const SET_FILTERS_DATA = "SET-FILTERS-DATA";
const SET_FILTER_ITEM = ""

const filtersSettingsData = {
    filterHeader: ['#', 'TITLE'],
    controlButtons: ["show", "delete"],
}

let filtersSettings = (state = filtersSettingsData, action) =>{

    switch(action.type){
        case SET_FILTERS_DATA:
            return {...state}
        default:
            return {...state}
    }
}
export default filtersSettings;