import React from "react";
import { Formik, Form } from "formik";
import { InputField, TextareaField } from "../../commons/form-controls";
import { StandartButton } from "../../commons/form-controls";
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { callUsValidateSchema, callUsValidateSchema2 } from "../../utils/validators"
import s from './call-us.module.scss';

const CallUs = (props) => {

    let errorMessage = null;

    const showExitModal = () => {
        props.modalShowToggle(false);
    }

    const modalWindow = () => {
        props.modalShowToggle(false);
        props.modalShowToggle("Alert", true, showExitModal, props.getValue('MESSAGE_SENDED'), props.getValue('THANK_FOR_MESSAGE'));
    }

    const onSubmitForm = (formData) => {
        let contact = null;
        if(formData && !formData.text)
            return
        if(formData !== undefined){
            if(formData.contacts || formData.email){
                if(!formData.contacts || !formData.email){
                    contact = formData.contacts ? formData.contacts : formData.email;
                }
                props.sendMessageToAPI(formData.name && formData.name.trim(), contact ? contact : formData.contacts.trim(), formData.email && formData.email.trim(), formData.text && formData.text.trim());
            }
            else{
                errorMessage = props.getValue('FIELD_NOT_BE_EMPTY')
            }
        }
        //formData !== undefined && props.sendMessageToAPI(formData.name && formData.name.trim(), formData.contacts && formData.contacts.trim(), formData.email && formData.email.trim(), formData.text && formData.text.trim());
        setTimeout(()=>{modalWindow()}, 300)
    }

    const form = props.isAuth ? <Formik
        initialValues={{
            name: props.fio,
            contacts: props.phone ? props.phone : '',
            email: props.email,
            text: ''
        }}
        validationSchema={callUsValidateSchema}
        onSubmit={onSubmitForm}
    >
        {({isValid, errors, touched, handleChange, handleSubmit})=>(
            <Form
                onSubmit={handleSubmit}
            >
                <div className="formRow">
                    <div className={s.labelRow}>
                        <div className={s.labelText}>{props.getValue('FULL_NAME')}</div>
                        <InputField name="name" type="text" label={ props.getValue('HOW_CAN_WE_CONTACT_TO_YOU') }/>
                    </div>
                </div>
                <div className="formRow">
                    <div className={s.labelRow}>
                        <div className={s.labelText}>{ props.getValue('PHONE_NUMBER')}</div>
                        <InputField name="contacts" type="text" label="+7 7XX XXX XX XX"/>
                    </div>
                </div>
                <div className="formRow">
                    <div className={s.labelRow}>
                        <div className={s.labelText}>{ props.getValue('EMAIL') }</div>
                        <InputField name="email" type="text" label="example@example.com"/>
                    </div>
                </div>
                <div className="formRow">
                    <div className={s.labelRow}>
                        <div className={s.labelText}>{ props.getValue('MESSAGE_TEXT') }</div>
                        <TextareaField name="text" type="text" label={props.getValue('WRITE_HERE_WHAT_YOU_ARE_INTERESTED_IN')}/>
                    </div>
                </div>
                <div className={s.formMessage}>
                    <span>{ props.getValue('WE_ARE_ALWAYS_AWAILABLE_AT')} <a href="mailto:info@alem.kz">echo@1cb.kz</a> {/*props.getValue('OR_BY_PHONE')+7 (727) 357 25 55*/} </span>
                    <StandartButton disabled={!isValid || !touched} type="submit" title={props.getValue('SEND')} icon={SendIcon} callback={onSubmitForm} stroke={'white'}/>
                </div>
            </Form>
        )}
    </Formik>
    :
    <Formik
        initialValues={{
            contacts: '',
            text: ''
        }}
        validationSchema={callUsValidateSchema2}
        onSubmit={onSubmitForm}
    >
                {({isValid, errors, touched, handleChange, handleSubmit})=>(
            <Form
                onSubmit={handleSubmit}
            >
                <div className="formRow">
                    <div className={s.labelRow}>
                        <div className={s.labelText}>{ props.getValue('CONTACT_DETAILS') }</div>
                        <InputField name="contacts" type="text" label={ props.getValue('PHONE_NUMBER_OR_E-MAIL_ADDRESS_SEPARATED_WITH_A_COMMA') }/>
                    </div>
                </div>
                <div className="formRow">
                    <div className={s.labelRow}>
                        <div className={s.labelText}>{ props.getValue('MESSAGE_TEXT') }</div>
                        <TextareaField name="text" type="text" label={ props.getValue('WRITE_HERE_WHAT_YOU_ARE_INTERESTED_IN')} />
                    </div>
                </div>
                <div className={s.formMessage}>
                    <span>{ props.getValue('WE_ARE_ALWAYS_AWAILABLE_AT')} <a href="mailto:info@alem.kz">info@alem.kz</a> {props.getValue('OR_BY_PHONE')} +7 (727) 357 25 55</span>
                    <StandartButton disabled={!isValid} type="submit" title={props.getValue('SEND')} icon={SendIcon} callback={onSubmitForm} stroke={'white'}/>
                </div>
            </Form>
        )}
    </Formik>

    return (
        <div className={s.formBody}>
            {form}
        </div>
    )
}

export default CallUs;