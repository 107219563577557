import GroupingController from "./grouping-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { modalShowToggle } from "store/modal";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setGrouping, setGroupingByPeriod } from "store/calendar";

let mapStateToProps = (state) => {
    return {
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        language: state.languages.selectedLanguage,
    }
}

const GroupingContainer = (connect(mapStateToProps, {getValue, modalShowToggle, setGrouping, setGroupingByPeriod})(GroupingController));

export default GroupingContainer;