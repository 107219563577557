import React from "react";
import AdminTopics from "./admin-topics-item";
import ControlButtons from "items/control-buttons/control-buttons";

import AdminAddItem from "../admin-add-item/admin-add-item";
import AddTopicsItemContainer from "../add-admin-topic-item/add-admin-topic-item-container";
import AdminTopicsAddItemContainer from "./admin-topics-add-item/admin-topics-add-item-container";
import moment from "moment";
import s from "./admin-topics.module.scss"
import { StandartButton } from "commons/form-controls";
import { getFormatDate } from "service/format-date";

class AdminTopicsController extends React.Component{

    constructor(props){
        super(props);
        this.controlButtons = [];
        this.state = {
            currentPage: 0,
            showedItemsCount: 10,
            selectedItem: null,
            searchText: '',
            showMoreText: false
        }
    }

    componentDidMount(){
        this.props.getAllTopics(this.state.currentPage, this.state.showedItemsCount, 'ACTIVE', this.state.searchText);
        //this.props.getAccounts(this.props.isSuperadmin, 0, 9999)
        /*if(this.props.companyId)
            this.props.getCompanyAccounts(this.state.companyId, this.props.isSuperadmin);*/
        this.props.getSuperadminCompanies('ALL', 0, 100, 'SUPERADMIN_TOPICS')
        this.props.getAllMarkers(0, 100);
    }

    componentDidUpdate(){
        if(this.props.currentPage !== this.state.currentPage || this.props.showedItemsCount !== this.state.showedItemsCount){
            if(this.props.currentPage !== this.state.currentPage){
                this.setSelectedItem(null)
                this.closeRightMenu();

            }
            this.props.getAllTopics(this.props.currentPage, this.props.showedItemsCount, 'ACTIVE', this.state.searchText);
            this.setState({
                currentPage: this.props.currentPage,
                showedItemsCount: this.props.showedItemsCount,
                searchText: this.state.searchText
            })
        }
        else{
            this.props.setItemsCount(this.props.adminTopics && this.props.adminTopics.totalElements);
            
        }
    }

    getTopicsSearch = (search, startPage) => {
        this.props.getAllTopics(0, this.props.showedItemsCount, 'ACTIVE', search);
        this.setSearchText(search)
        if(startPage){
            this.props.setCurrentPage(0)
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    formatDate = (date) =>{
        return getFormatDate(date, "HH:mm DD.MM.YY")//moment(date).format("HH:mm DD.MM.YY");
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    addMarkerToTopic = (accountId, topicId) => {
        this.closeRightMenu();
        this.props.makeTopicVisibleToMarker(accountId, this.props.adminTopics.content[this.state.selectedItem].id)
    }

    showRightMenu = (show, topicId, index) => {

        this.closeRightMenu()

        if(show){
            let ftopic = this.props.adminTopics.content[index];
            this.props.setShowRightMenu(true)
            switch(show){
                case 'addItem':
                    this.props.setRightMenuComponent(<AdminTopicsAddItemContainer topicId={this.props.adminTopics.content[index].id} topicName={this.props.adminTopics.content[index].name} companyId={this.props.adminTopics.content[index].companyId}/>, 'markerItems', null, this.props.getValue('ATTACH_ACCOUNT_TO_TOPIC'), true, this.props.getValue('ATTACH_TOPIC_TO_MARKER'));
                    //this.props.setRightMenuComponent(<AddTopicsItemContainer searchText={this.state.searchText} setSearchText={this.setSearchText} topicId={ftopic.id} topic={ftopic} type={'markers'} callback={this.addMarkerToTopic} getValue={this.props.getValue}/>, 'markerItems', null, this.props.getValue('ATTACH_ACCOUNT_TO_TOPIC'), true, this.props.getValue('ATTACH_TOPIC_TO_MARKER'));
                    break;
            }          
            
        }
    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type
        })
        this.title = type;

        switch(type){
            case 'add':
                this.showRightMenu('addItem', key, index)    
                break;
        }
    }

    getControlButtonItems = (id, status, items, index, isSubtopic) => {
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={items} index={index} isSubtopic={isSubtopic}/>
    }

    getCompanyName = (companyId) => {
        let c = this.props.companies ? this.props.companies.filter(k => k.id === companyId) : []
        return (c[0] && c[0].name) ? c[0].name : companyId
    }

    checkKeywords = (keywords) => {
        return keywords.length > 120 && !this.state.showMoreText
                                        ? <span title={keywords}>{keywords.substr(0, 120)+'...'}</span>
                                        : <span>{keywords}</span>
    }

    setTableValues = (tableValues) => {
        return tableValues.map( (k, i) => {
            let ctrl = [];
            
            ctrl = this.props.controlButtons 
            k.keywords = this.checkKeywords(k.keywords)
            k.stopwords = this.checkKeywords(k.stopwords)
            //this.controlButtons.push(this.getControlButtonItems(k.id, k.status, ctrl, i))
            let b = this.getControlButtonItems(k.id, k.status, ctrl, i)
            return [i+1, k.name, this.getCompanyName(k.companyId), this.formatDate(k.createdAt), this.formatDate(k.updatedAt), k.keywords, k.stopwords, b];
        })
        
    }

    setSelectedItem = (item, startPage) => {
        this.setState({
            selectedItem: item,
        })

    }

    setSearchText = (text) => {
        this.setState({
            searchText: text
        })
    }

    render(){

        return(
            <div className={s.AdminTopics}>
                {this.props.adminTopics && <AdminTopics    
                                                    getValue={this.props.getValue} 
                                                    tableHeader={this.getTableHeader(this.props.tableHeader)}
                                                    controllerButtons={this.controlButtons}
                                                    controlButtons={this.props.controlButtons}
                                                    data={this.props.adminTopics && this.props.adminTopics.content.length && this.setTableValues(this.props.adminTopics.content)}
                                                    markers={this.props.markers}
                                                    selectedItem={this.state.selectedItem}
                                                    setSelectedItem={this.setSelectedItem}
                                                    searchText={this.state.searchText}
                                                    setSearchText={this.setSearchText}
                                                    search={this.getTopicsSearch}
                            />}
            </div>

        )
        
    }

    componentWillUnmount(){
        this.closeRightMenu()
        this.props.setItemsCount(0)
        this.props.setCurrentPage(0)
        this.props.setShowedItemsCount(10);
    }
}

export default AdminTopicsController