const FilterList = {
    publicationType: [
        {name: 'ARTICLE_2', list: 'ARTICLE'},
        {name: 'POST_2', list: 'POST'},
        {name: 'COMMENT_2', list: 'COMMENT'},
        {name: 'REPOST_2', list: 'SHARE'},
        {name: 'ADDED_REPOST_2', list: 'EXTENDED_SHARE'},
        {name: 'VIDEO', list: 'VIDEO'}
    ],
    type: [
        {name: 'ARTICLE_2', list: 'ARTICLE'},
        {name: 'POST_2', list: 'POST_2'},
        {name: 'REPOST_2', list: 'SHARE'},
        {name: 'ADDED_REPOST_2', list: 'EXTENDED_SHARE '},
        {name: 'COMMENT_2', list: 'COMMENT'},
    ],
    mediaType: [
        //{name: 'SOCIAL_NETWORKS', list: 'SOCIAL'},
    
        //{name: 'MASS_MEDIA',
        //items: [
            {name: 'NEWS_AGENCIES', list: 'NEWS_AGENCY'},
            {name: 'TELEVISION', list: 'TV'},
            {name: 'PRINT_MAS_MEDIA', list: 'PRINT'},
            {name: 'INTERNET_MASS_MEDIA', list: 'INTERNET'},
        //]},
    ],
    mediaScale: [
        {name: 'INTERNATIONAL', list: 'INTERNATIONAL'}, 
        {name: 'CENTRAL', list: 'CENTRAL'},
        {name: 'REGIONAL', list: 'REGIONAL'}
    ],
    language: [
        {name: 'KAZAKH_2', list: 'kz' },
        {name: 'RUSSIAN_2', list: 'ru'},
        {name: 'ENGLISH_2', list: 'en'}
    ],
    sentiment: [
        {name: 'NEUTRAL', list: 'NEUTRAL'},
        {name: 'POSITIVE', list: 'POSITIVE'},
        {name: 'NEGATIVE', list: 'NEGATIVE'},
        {name: 'NOT_DETERMINDED_3', list: 'UNDEFINED'},
    ],
    verificationStatus: [
        {
            name: 'VERIFIED',
            list: 'VERIFIED'
        },
        {
            name: 'UNVERIFIED',
            list: 'UNVERIFIED'
        },
    ],
    filtersList: [
        {
            name: 'SOURCE_TYPE',
            list: 'mediaType'
        },
        {
            name: 'POST_TYPE',
            list: 'type'
        },
        {
            name: 'TAGS',
            list: 'tags'
        },
        {
            name: 'TONE_AGGRESSION_ADVERTISEMENT',
            list: 'sentiment'
        },
        {
            name: 'KEY_WORDS',
            list: 'keywords'
        },
        {
            name: 'POST_LANGUAGE',
            list: 'language'
        },
        {
            name: 'SOURCES',
            list: 'domain'
        },
        {
            name: 'AUDIENCE_MAS_MEDIA_COVERAGE_INVOLVEMENT',
            list: 'media_scale'
        },
        {
            name: 'AUTHORS',
            list: 'author'
        },
        {
            name: 'VERIFICATION_STATUS',
            list: 'verification_status'
        },
        {
            name: 'ADVERTISING',
            list: 'is_advertising'
        },
        {
            name: 'SWEARING',
            list: 'is_swearing'
        },
        {
            name: 'commonLocationCountryId',
            list: 'common_location_country_id'
        }
    ],
    domains: [
        {
            name: "facebook.com",
            list: "facebook.com"
        }, 
        {
            name: "vk.com",
            list: "vk.com",
        },
        {
            name: "youtube.com",
            list: "youtube.com"
        }, 
        {
            name: "telegram.org",
            list: "telegram.org"
        }, 
        {
            name: "instagram.com",
            list: "instagram.com", 
        }, 
        {
            name: "twitter.com",
            list: "twitter.com"
        }, 
        {
            name: "tiktok.com",
            list: "tiktok.com"
        }, 
        {
            name: "ok.ru",
            list: "ok.ru",
        }, 
        {
            name: "my.mail.ru",
            list: "my.mail.ru",
        }
    ],
    languages: [
        {
            name: "RUSSIAN_2",
            list: "ru"
        },
        {
            name: "KAZAKH_2",
            list: "kz"
        },
        {
            name: "ENGLISH_2",
            list: "en"
        },
        {
            name: "BELORUSSIAN",
            list: "be"
        },
        {
            name: "UKRAINIAN",
            list: "uk"
        },
        {
            name: "GERMAN",
            list: "de"
        },
        {
            name: "DUTCH",
            list: "nl"
        },
        {
            name: "FRENCH",
            list: "fr"
        },
        {
            name: "ITALIAN",
            list: "it"
        },
        {
            name: "SPANISH",
            list: "es"
        },
        {
            name: "DANISH",
            list: "da"
        },
        {
            name: "CATALAN",
            list: "ca"
        },
        {
            name: "SLOVENIAN",
            list: "sl"
        },
        {
            name: "PORTUGUESE",
            list: "pt"
        },
        {
            name: "ROMANIAN",
            list: "ro"
        },
        {
            name: "SLOVAK",
            list: "sk"
        },
        {
            name: "NORWEGIAN",
            list: "no"
        },
                {
            name: "FINNISH",
            list: "fi"
        },
        {
            name: "POLISH",
            list: "pl"
        },
        {
            name: "GREEK",
            list: "el"
        },
        {
            name: "ESTONIAN",
            list: "et"
        },
        {
            name: "SWEDISH",
            list: "sv"
        },
        {
            name: "HUNGURIAN",
            list: "hu"
        },
        {
            name: "LITHUANIAN",
            list: "lt"
        },
        {
            name: "THAI",
            list: "th"
        },
    ],
    sortFilters: [
        {
            name: 'DATE_↑',
            list: 'DATE_ASC',
        },
        {
            name: 'DATE_↓',
            list: 'DATE_DESC',
        }
    ],
    reportFeedTypes: [
        {
            name : 'SELECTION',
            list: 'main',
        },
        {
            name : 'BLACK_LIST',
            list: 'blacklisted',
        },
        {
            name : 'FAVORITE_POSTS',
            list: 'favourite_manual',
        },
        {
            name : 'FAVORITE_AUTHORS_AND_SOURCES',
            list: 'favourite_auto',
        },
        {
            name : 'TRASHBIN',
            list: 'deleted',
        }
    ]
}

export default FilterList;