import React, { useEffect, useState } from "react";
import { InputText } from "commons/input-controls";
import { getFormatDate } from "service/format-date";
import s from  "./migration-item.module.scss"

const MigrationItem = (props) => {

    const getReadOnlyByType = (type) => {
        switch(type){
            case 'show':
                return true;
            case 'CREATE':
                return false;
            default: return true;
        }
    }

    const [migration, setMigration] = useState(props.migration ? props.migration : null);
    const [readOnly, setReadOnly] = useState(getReadOnlyByType(props.type));

    const [topic, setTopic] = useState(props.migration ? props.getTopicName(props.migration.topicUUID) : '');
    const [company, setCompany] = useState(props.migration ? props.getCompanyName(props.migration.companyId) : '');
    const [accountName, setAccountName] = useState(props.account ? props.account.login : '');
    const [docsProgressCount, setDocsProgressCount] = useState(props.migration && props.migration.expectedDocs ? props.getDocsProgress(props.migration.expectedDocs, props.migration.currentDocs) : 0)

    useEffect(() => {
        setMigration(props.migration ? props.migration : null);
        setReadOnly(getReadOnlyByType(props.type));
        setTopic(props.migration ? props.getTopicName(props.migration.topicUUID) : '');
        setCompany(props.migration ? props.getCompanyName(props.migration.companyId) : '');
        setAccountName(props.account ? props.account.login : '');
        setDocsProgressCount(props.migration && props.migration.expectedDocs ? props.getDocsProgress(props.migration.expectedDocs, props.migration.currentDocs) : 0)
    }, [props.migration, props.account])

    return (
        <div className={s.MigrationItem}>
            <div className="formRow">
                <div className="labelText">{props.getValue('TOPIC_NAME')}</div>
                <InputText value={topic ? topic.name : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('COLLECTION')}</div>
                <InputText value={migration ? migration.collection : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('COMPANY_NAME')}</div>
                <InputText value={company ? company.name : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('ACCOUNT_NAME')}</div>
                <InputText value={accountName} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('TYPE')}</div>
                <InputText value={migration ? props.getValue(migration.type) : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('STATUS')}</div>
                <InputText value={migration ? props.getValue(migration.status) : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('CREATED_AT')}</div>
                <InputText value={migration ? getFormatDate(migration.createdAt, "DD-MM-YYYY HH:mm") : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('UPDATED_AT')}</div>
                <InputText value={migration ? getFormatDate(migration.updatedAt, "DD-MM-YYYY HH:mm") : ''} readOnly={readOnly}/>
            </div>
            <div className="formRow ">
                <div className="labelText">{props.getValue('DATE_START') + " / "+props.getValue('DATE_END')}</div>
                <div className={s.migrationRow}>
                    <InputText value={migration ? getFormatDate(migration.dateStart, "DD-MM-YYYY HH:mm") : ''} readOnly={readOnly}/>
                    <span>-</span>
                    <InputText value={migration ? getFormatDate(migration.dateEnd, "DD-MM-YYYY HH:mm") : ''} readOnly={readOnly}/>
                </div>
            </div>
            <div className="formRow ">
                <div className="labelText">{props.getValue('EXPECTED_DOCUMENTS') + " / "+props.getValue('CURRENT_DOCUMENTS')}</div>
                <div className={s.migrationRow}>
                    <InputText value={migration ? migration.expectedDocs : ''} readOnly={readOnly}/>
                    <span>-</span>
                    <InputText value={migration ? migration.currentDocs : ''} readOnly={readOnly}/>
                </div>
                <div className={s.migrationRowCenter}>
                    <progress max="100" value={docsProgressCount}>{docsProgressCount}</progress>
                    <span className={s.migrationPercent}>{docsProgressCount}%</span>
                </div>
            </div>
            <div className="formCol">

            </div>
        </div>
    )
}

export default MigrationItem;