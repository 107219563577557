import { header, getLanguage, checkFilters } from "./header";

const report = header('report/api/v1/admin/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    report.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    report.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const AdminReportService = {
    getReportSettings(pageIndex=0, pageSize=100){
        setLanguage();
        getAuth();
        return report.get(`report-settings/?pageIndex=${pageIndex}&pageSize=${pageSize}`).then( response => {
            return response.data
        })
    },
    createReportSettings(userId, reportSettings){
        setLanguage();
        getAuth();
        return report.post(`report-settings/users/${userId}`, {...reportSettings}).then( response => {
            return response.data
        })
    },
    updateReportSettings(settingsId, reportSettings){
        setLanguage();
        getAuth();
        return report.put(`report-settings/${settingsId}`, {...reportSettings}).then( response => {
            return response.data
        })
    },
    deleteReportSettings(reportOperationId){
        setLanguage();
        getAuth();
        return report.delete(`report-operations/${reportOperationId}`).then( response => {
            return response.data
        })
    }
}