import React, {useState, useEffect} from "react";
import { InputCheckBox } from "../../../../../commons/input-controls";
import s from "./digest-week-days.module.scss";

const DigestWeekDays = (props) => {

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState(props.selectedWeekDays);
    const [rerender, setRerender] = useState(false);
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);

    useEffect(()=>{
        setRerender(!rerender);
        setSelected(props.selectedWeekDays);
        setReadOnly(props.readOnly ? props.readOnly : false);
    }, [props.selectedWeekDays, props.readOnly])

    const setSelectedAll = () => {
        setSelectAll(!selectAll);
        props.selectAllWeekdDays(!selectAll)
    }

    const selectWeekDay = (item) => {
        props.setSelectedWeekDays(item)
    }

    const weekDays = props.weekDays.map( (k, i) => {
        
        let index = selected.indexOf(i) > -1 ? true : false
        return (
         
                <div key={i} className={s.checkItem}>
                    <InputCheckBox isDisabled={readOnly} isChecked={index} name={k} title={props.getValue(k)} callback={selectWeekDay.bind(this, i)}/>
                    <span>{props.getValue(k)}</span>
                </div>)})

    return (
        <div className={s.DigestWeekDays}>
            <div className={s.headerBlock}>
                <div className={s.checkItem}>
                    <InputCheckBox isDisabled={readOnly} isChecked={selectAll} name={"CHOOSE_ALL"} title={props.getValue('CHOOSE_ALL')} callback={setSelectedAll}/><span>{props.getValue('CHOOSE_ALL')}</span>
                </div>
            </div>
            <div className={s.bodyBlock}>
                {weekDays}
            </div>


        </div>
    )
}

export default DigestWeekDays;