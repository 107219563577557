import React, {useState, useEffect} from "react";
import ColumnChart from 'react-apexcharts';
import s from "./apex-column-chart.module.scss";

const ApexColumnChart = (props) => {

  /*const checkByLength = (label) => {
    if(label.length > 10){
      let l = label.split(' ');
      let midpoint = Math.ceil(l.length / 2);
      let firstHalf = l.slice(0, midpoint).join(' ');
      let secondHalf = l.slice(midpoint).join(' ');
      return [firstHalf, secondHalf]

    }
    else
      return label
  }

  const getLabels = (labels) => {
    return labels.map(k=> k.length > 20 ? checkByLength(props.getValue(k)) : props.getValue(k))
  }*/

  const [colors, setColors] = useState(props.colors ? props.colors : null)
  const [g, setG] = useState(props.data.labels)
  
  const [values, setValues] = useState((props.data && props.data.values) ? props.data.values : []);
  const [rerender, setRerender] = useState(false);

    useEffect(() => {                             
      setG(props.data.labels)
      setValues((props.data && props.data.values) ? props.data.values : []);
      setColors(props.colors ? props.colors : null);
      setRerender(!rerender)
  }, [props.data.values, props.data.labels, props.colors, props.title])

    let options = {
          chart: {
            id: props.title,
            type: 'bar',
            height: props.height,
            stacked: props.stacked,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: true
            },
            events: {
              click: (e, chartContext, config)=>{props.clickOnElement(e, chartContext, config)}
            },
            foreColor: 'var( --caption)'
          },
          /*responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                maxHeight: '50px',
                height: '100px',
                position: 'bottom',
                offsetX: 20,
                offsetY: 0
              }
            }
          }],*/
          plotOptions: {
            bar: {
              horizontal: props.horizontal,
              borderRadius: 4,
              barHeight : '30%',
              dataLabels: {
                enabled: props.horizontal,
                orientation: 'horizontal',
                position: 'center'
              }

            },
          },
        dataLabels: {
          enabled: props.horizontal
        },
          xaxis: {
            categories: g,
          },
          title: {
            text: props.getValue(props.title),
            align: 'left',
            style: {
              color: 'var(--caption)'
            }
            
          },
          legend: {
            position: 'top',
            offsetY: 0,
            height: 100,
            show: props.legend ? props.legend : false
          },
          fill: {
            opacity: 1
          },
          toolbar: {
            show: false
          },
          theme: {
            palette: 'palette'
          },
          noData: {
            text: 'sds',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: '14px',
            }
          }
        }
        if(colors) options.colors = colors

      return (
        <div  id={props.title} className={s.ColumnChart}>
            { (g && values && values.length )? <ColumnChart options={options} series={values} type="bar" height={props.height} width={props.width}/> : null}
        </div>
      )
}

export default ApexColumnChart;