import React from "react";
import AddTag from "./add-tag";

class AddTagController extends React.Component{

    componentDidMount(){
        //if(this.props.companyId)
            this.props.getCompanyTags(this.props.companyId);
    }

    componentDidUpdate(){
        if(!this.props.tags && this.props.companyId)
            this.props.getCompanyTags(this.props.companyId);
    }

    getFeedType = (page) => {
        switch(page){
            case 'Documents':
                return 'main'
            case 'Favourite':
                return 'favourite_auto'
            case 'FavouritePublications':
                return 'favourite_manual'
            case 'BlackList':
                return 'blacklisted'
            case 'RecycleBin':
                return 'deleted'
            default:

        }
    }

    render(){
        return <AddTag {...this.props} getFeedType={this.getFeedType}/>
    }

}

export default AddTagController;