import React from "react";
import MigrationCreateItem from "./migration-create-item";
import moment from "moment";
import { getFormatedDate, getFormatDate } from "service/format-date";

class MigrationCreateItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedCompanyItem: null,
            selectedCompanyTopic: null,
            selectedType: null,
            types: ['KEYWORDS', 'PROFILES', 'RESOURCES'],
            companies: this.props.companies ? true : false,
            startDate: getFormatedDate(moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss")),
            endDate: getFormatedDate( moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss") ),
            dateChanged: false,
            selectedCollection: null
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    componentDidMount(){
        this.props.getCompanies('ACTIVE', 0, 9999);
    }

    startMigrationOperation = (startDate, endDate) => {
        this.props.startMigration(this.state.selectedCompanyTopic.uuid, this.state.selectedType, this.state.selectedCollection, this.props.topic, {
            "since": getFormatedDate(startDate),
            "till": getFormatedDate(endDate) 
        });
        this.closeRightMenu()
    }

    getfoundedNumber = (startDate, endDate) => {
        if(this.state.selectedType && this.props.topic){
            //if(this.props.getNumfound || this.state.dateChanged){
                //topicUUID, type, collection
    
                this.props.getMigrationNumFound(this.state.selectedCompanyTopic.uuid, this.state.selectedType, this.state.selectedCollection, this.props.topic, {
                    "since": getFormatedDate(startDate),
                    "till": getFormatedDate(endDate) 
                })
                this.setState({
                    dateChanged: false
                })
                this.props.setGetNumFound(false);
            //}
        }
    }

    setSelectedCompanyItem = (selected) => {
        this.setState({
            selectedCompanyItem: selected
        })
        this.props.getCompanyTopics(selected.id, true)
    }

    setSelectedTopic = (selected) => {
        this.setState({
            selectedCompanyTopic: selected
        })
    }

    setSelectedType = (selected) => {
        this.setState({
            selectedType: selected
        })
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date,
            dateChanged: true
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date,
            dateChanged: true
        })
    }

    setSelectedCollection = (selected) => {
        this.setState({
            selectedCollection: selected,
            dateChanged: true
        })
    }



    render(){
        return <MigrationCreateItem 
                                    getValue={this.props.getValue} 
                                    companies={this.props.companies} 
                                    companyTopics={this.props.companyTopics} 
                                    //getCompanyTopics={this.props.getCompanyTopics}
                                    numfound={this.props.numfound}
                                    getMigrationNumFound={this.props.getMigrationNumFound}
                                    getTopic={this.props.getTopic}
                                    topic={this.props.topic}
                                    types={this.state.types}
                                    selectedCompanyItem={this.state.selectedCompanyItem}
                                    selectedCompanyTopic={this.state.selectedCompanyTopic}
                                    selectedType={this.state.selectedType}
                                    setSelectedTopic={this.setSelectedTopic}
                                    setSelectedType={this.setSelectedType}
                                    setSelectedCompanyItem={this.setSelectedCompanyItem}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    setStartDate={this.setStartDate}
                                    setEndDate={this.setEndDate}
                                    startMigration={this.startMigrationOperation}
                                    collections={this.props.collections}
                                    setSelectedCollection={this.setSelectedCollection}
                                    selectedCollection={this.state.selectedCollection}
                                    getfoundedNumber={this.getfoundedNumber}
                                    />
    }
}

export default MigrationCreateItemController