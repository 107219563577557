import React from "react";
import TopicItem from './topic-item';

class TopicItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = ({
            topicId: this.props.topic ? this.props.topic.id : null,
            language: this.props.selectedLanguage,
            checkProfileItem: this.props.checkProfileItem,
            checkResourceItem: this.props.checkResourceItem,
            selectedRole: this.props.selectedRole
        })
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }


    componentDidMount(){
        if(this.props.topic && this.props.topic.id){
            this.props.getTopicData(this.props.topic.id);
            if(this.props.selectedRole && this.props.selectedRole === 'ROLE_ADMIN')
                this.props.getTopicAccounts(this.props.topic.id)
        }
    }

    saveTopicSettings = (topicItemId, data, userIds) => {
        this.props.updateTopicSettings(topicItemId, data);
        this.closeRightMenu();
        
    }

    updateTopic = (topicId, topicTitle, limit, key, stop, prof, resources, migration, profileMigration, deleteOld, settings) => {
        this.props.editTopic(topicId, topicTitle, limit, key, stop, prof, resources, migration, profileMigration, deleteOld, settings);
        this.closeRightMenu();
    }

    componentDidUpdate(){
        if((this.props.topic && this.props.topic.id) && this.props.topic.id !== this.state.topicId){

            this.props.getTopicData(this.props.topic.id)
            this.props.setCheckProfile(null)
            this.setState({
                topicId: this.props.topic.id
            })

            if(this.props.selectedRole && this.props.selectedRole === 'ROLE_ADMIN'){
                this.props.getTopicAccounts(this.props.topic.id);
            }
        }
        if(!this.props.topicItem || !this.props.topicItem.id){
            if(this.props.topic && this.props.topic.id) 
                this.props.getTopicData(this.props.topic.id)
        }
        if(this.props.isEdit === 'create' && this.state.topicId){
            this.setState({
                topicId: this.props.topic ? this.props.topic.id : null,
            })
            this.props.setSelectedUser([])
        }
        if(this.props.topicItemUpdated){
            this.props.setTopicItemUpdated(false)
            //return true
        }
        if(this.props.selectedChanged){
            this.props.setSelectedChanged(false)
        }
        if(this.props.checkProfileItem !== this.state.checkProfileItem){
            this.setState({
                checkProfileItem: this.props.checkProfileItem
            })
            setTimeout(()=>{
                this.props.setCheckProfile(null)
            }, 5000)
        }
        if(this.props.checkResourceItem !== this.state.checkResourceItem){
            this.setState({
                checkResourceItem: this.props.checkResourceItem
            })
            setTimeout(()=>{
                this.props.setCheckResource(null)
            }, 5000)
        }
        if(this.props.selectedLanguage !== this.state.language && this.props.countries){
            this.getCountries();
            this.setState({
                language: this.props.selectedLanguage
            })
        }
        if(this.props.topicItem && this.props.topicItem.topicSettings && this.props.topicItem.topicSettings.massMediaScale && this.props.topicItem.topicSettings.massMediaScale.indexOf('REGIONAL') !== -1 && !this.props.regions.length){
            this.props.getRegions(true)
        }
        else if(this.props.topicItem && this.props.topicItem.topicSettings && this.props.topicItem.topicSettings.massMediaScale && this.props.topicItem.topicSettings.massMediaScale.indexOf('REGIONAL') === -1 && this.props.regions.length){
            this.props.getRegions(false)
        }
        if(this.props.topicItem && this.props.topicItem.topicSettings && this.props.topicItem.topicSettings.massMediaScale && this.props.topicItem.topicSettings.massMediaScale.indexOf('INTERNATIONAL') !== -1 && !this.props.countries.length){
            this.props.getCountries(true)
        }
        else if(this.props.topicItem && this.props.topicItem.topicSettings && this.props.topicItem.topicSettings.massMediaScale && this.props.topicItem.topicSettings.massMediaScale.indexOf('INTERNATIONAL') === -1 && this.props.countries.length){
            this.props.getCountries(false)
        }
        if(this.props.selectedRole !== this.state.selectedRole){
            this.setState({
                selectedRole: this.props.selectedRole
            })
        }
    }

    getCountries = () => {
        this.props.getGeoplaceCountries();
    }

    setSelectedSocialItem = (item, e) => {
        if(item === 'regions')
            this.props.getRegions(e);
    }

    componentWillUnmount(){
        this.props.setCheckProfile(null);
        this.props.setCheckResource(null);
    }

    render(){
        return (
            <TopicItem {...this.props} closeRightMenu={this.closeRightMenu} getCountries={this.getCountries} setSelectedSocialItem={this.setSelectedSocialItem} saveTopicSettings={this.saveTopicSettings} updateTopic={this.updateTopic}/>
        )
    }
}

export default TopicItemController