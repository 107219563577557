import React from "react";
import TableItem from "../../items/table/table";
import s from "./black-list.module.scss";

const BlackList = (props) => {
    return (
        <div className={s.BlackList}>
            <TableItem 
                data={[props.tableHeaderName, props.topicBlackList]}  
                getValue={props.getValue} 
                titleText={props.getValue('BLACK_LIST')}
                hiddenColumns={[1]}
                height={230}
            />
        </div> 
    )
}

export default BlackList;