import React from "react";
import { StandartButton } from "../../commons/form-controls";
import s from "./modal-with-button.module.scss";
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'


const ModalWithButton = (props) => {
    const clickOnButton = e => {
        props.callback(e);
        props.modalShowToggle(e)
    }

    const buttons =  <div className={s.buttonsRow}>
                        <StandartButton type="button" title={props.buttonName ? props.buttonName : props.getValue('CLOSE')} icon={CloseIcon} callback={clickOnButton.bind(this, 'exit')} stroke={'white'}/>
                    </div>

    return (
        <div className={s.ModalBlock}>
            <span className={s.BodyText}>{props.body}</span>
            {buttons}
        </div>
    )
}

export default ModalWithButton;