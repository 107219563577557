import { compose } from "redux";
import { connect } from "react-redux";
import CompanyEditorController from "./company-editor";

let mapStateToProps = (state) => {
    return {
        company_bin: state.superadmin.company_bin,
        company_name: state.superadmin.company_name,
        company_max_num_topics: state.superadmin.company_max_num_topics,
    }
}
const CompanyEditorContainer =  compose(
    connect(mapStateToProps, {

    }),
)(CompanyEditorController)

export default CompanyEditorContainer;
