import React, { useState, useEffect } from "react";
import { InputCheckBox } from "commons/input-controls";
import s from "./authorities.module.scss"

const Authorities = (props) => {
    const isSelectedAll = (selected, items) => {
        if(Object.keys(selected) && Object.keys(selected).length){
            return Object.keys(selected).length === items.length ? true : false
        }
        else
            return false
    }

    const [authorities, setAuthorities] = useState(props.authorities);
    const [selected, setSelected] = useState(props.selectedAuthorities);
    const [selectAll, setSelectAll] = useState(isSelectedAll(props.selectedAuthorities, props.authorities))
    
    useEffect(() => {
        setAuthorities(props.authorities);
        setSelected(props.selectedAuthorities);
        setSelectAll(isSelectedAll(props.selectedAuthorities, props.authorities))
    }, [props.authorities, props.selectedAuthorities]);

    const addSelectedAuthority = (authority) => {
        !props.showOnly ? props.setSelectedAuthority(authority.id, authority.name) : null
    }

    const selectAllAuthorities = (authorities, e) => {
        props.setSelectedAuthorities(authorities, e)
    }

    const authoritiesList = authorities.map((k, i) => 
        <div key={k.id} className={s.AuthorityItem} onClick={addSelectedAuthority.bind(this, k)}>
            <InputCheckBox isDisabled={props.showOnly} isChecked={selected[k.id]} name={k.name} callback={addSelectedAuthority.bind(this, k, i)} title={k.name} />
            <span className={s.authorityName}>{ k.description }</span>
        </div>
    )
    
    return (
        <div className={s.Authorities}>
            <div className={s.AuthorityItem}>
                <InputCheckBox isDisabled={props.showOnly} isChecked={selectAll} name={'selectAllAuthorities'} callback={selectAllAuthorities.bind(this, authorities)} title={'selectAllAuthorities'} />
                <span className={s.authorityName}>{ props.getValue('CHOOSE_ALL') }</span>
            </div>
            <div className={s.AuthoritiesItems}>
                {authoritiesList}
            </div>
            
        </div>
    )
}

export default Authorities