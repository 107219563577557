import React from "react";
import Signin from "./signin/signin";
import Signup from "./signup/signup";
import { StandartButton } from "../../commons/form-controls";
import {ReactComponent as UserIcon} from "../../assets/icons/user.svg";
import {ReactComponent as OkIcon} from "../../assets/icons/ok.svg";
import s from "./authorization.module.scss";

const Authorization = (props) => {
    const componentsMap = {
        UserIcon,
        OkIcon
    }

    const changePageNumber = (pageNumber) => {
        props.setNewPageNumber(pageNumber)
    }

    const switchMenu = (param) => {
        switch(param){
            case 0:{
                return {
                    page: <Signin 
                            signin={props.signin} 
                            getValue={props.getValue} 
                            showModal={props.modalShowToggle} 
                            resetPassword={props.resetPassword} 
                            errorInResetPassword={props.errorInResetPassword} 
                            setErrorInResetPassword={props.setErrorInResetPassword}
                            loginError={props.loginError}
                            setErrorInLogin={props.setErrorInLogin}
                            unAuthorizedLanguage={props.unAuthorizedLanguage}
                            setSelectedLanguage={props.setSelectedLanguage}
                        />,
                    /*text: <span>Нет аккаунта?</span>,
                    button: <StandartButton type="button" title="Регистрация" icon={componentsMap.UserIcon} callback={changePageNumber.bind(this, 1)}/>*/
                }
            }
            case 1: {
                return {
                    page: <Signup 
                    signup={props.signup} 
                    getValue={props.getValue} 
                    errorInRegistration={props.errorInRegistration}
                    setErrorInRegistration={props.setErrorInRegistration}
                    registrationIsSend={props.registrationIsSend}
                    setRegistrationIsSend={props.setRegistrationIsSend}
                    />,
                    text: <span>Уже есть аккаунт?</span>,
                    button: <StandartButton type="button" title="Вход в систему" icon={componentsMap.OkIcon}  callback={changePageNumber.bind(this, 0)}/>
                }
            }
            default:{
                return{
                    page: <Signin />
                }
            }
        }
    }
    return (
        <div className={s.Authorization}>
            <div className={s.PageForm}>
                { switchMenu(props.pageTypeNumber).page }
                <div className={s.switchButtonRow}>
                        { switchMenu(props.pageTypeNumber).text }
                        { switchMenu(props.pageTypeNumber).button }
                </div>
            </div>
        </div>
    )
}

export default Authorization;