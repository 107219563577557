import CompanyController from "./company-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { getValue } from "store/languages";
import { getMe } from "store/account";
import { getCompany } from "store/admin";
import { getSuperadminCompany } from "store/superadmin"

let mapStateToProps = (state) => {

    return {
        role: state.Account.role,
        adminCompany: state.admin.company,
        companyId: state.superadmin.companyId,
        superadminCompany: state.superadmin.company,
        adminCompanyId: state.Account.companyId,
        isSuperadmin: state.Account.isSuperadmin,
    }
}

const CompanyContainer =  compose(
    connect(mapStateToProps, {
        getSuperadminCompany,
        getCompany,
        getValue,
        getMe,
    }),
    withAuthRedirect
)(CompanyController)

export default CompanyContainer;