import React from "react";
import s from "./badge.module.scss";

const Badge = (props) => {
    const formatNumber = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <div className={s.Badge} style={{background: props.background ? props.background : '', color: props.color ? props.color : ''}}>
            {props.label && props.label}{props.sum ? formatNumber(props.sum) : props.sum}
        </div>
        )
}

export default Badge;