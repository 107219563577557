import React from "react";
import s from "./instruction.module.scss"

const KeywordsInstruction = (props) => {
    return (
        <div className={s.Instruction}>
            <div className={s.keywords}>
                <p>{ props.getValue('FOR_RELEVANT_COLLECTION') }</p>
                <p>{ props.getValue('LOGICAL_OPERATORS_WITH_EXAMPLES') }</p>
                <table>
                <thead>
                    <tr>
                        <td>{ props.getValue('OPERATOR') }</td>
                        <td>{ props.getValue('WHAT_FOR_USE_IT') }</td>
                        <td>{ props.getValue('EXAMPLES_OF_KEYWORDS_WITH_OPERATORS') }</td>
                        <td>{ props.getValue('RESULT_IN_PUBLICATION_TEXT') }</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={2}>{ props.getValue('STAR') }</td>
                        <td>{ props.getValue('MATCHES_ENDING_TO_KEYWORD') }</td>
                        <td>{ props.getValue('TALDYKORGAN_STAR') }</td>
                        <td>{ props.getValue('TALDYKORGAN_DECLINATION') }</td>
                    </tr>
                    <tr>
                        
                        <td>{ props.getValue('CAN_USE_COMPLEX_PHRASES')}</td>
                        <td>{ props.getValue('TALDYKORGAN_AVIA_BASE')}</td>
                        <td>{ props.getValue('TALDYKORGAN_AVIA_BASE_DECLINATION')}</td>
                    </tr>

                    <tr>
                        <td rowSpan={3}>&&</td> 
                        <td>{ props.getValue('LINKING_MULTIPLE_PARTS')}</td>
                        <td>{ props.getValue('TALDYKORGAN_SUBSIDIES')}</td>
                        <td>{ props.getValue('CONSTRUCTIVE_DIALOG_IN_TALDYKORGAN')}</td>
                    </tr>
                    <tr>
                        
                        <td>{ props.getValue('CAN_USE_COMPLEX_PHRASES')}</td>
                        <td>{ props.getValue('TALDYKORGAN_AUTHORITIES_AND_SUBSIDIES')}</td>
                        <td>{ props.getValue('CONSTRUCTIVE_DIALOG_IN_TALDYKORGAN')}</td>
                    </tr>
                </tbody>
            </table>
            </div>

            

        </div>
    )
}

export default KeywordsInstruction