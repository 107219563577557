import React, { useState, useEffect } from "react";
import s from "./switch-admin-mode.module.scss";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as Admin } from 'assets/icons/admin-settings.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';

const SwitchAdminMode = (props) => {
    const [selected, setSelected] = useState(props.selectedRole ? props.selectedRole : 'ROLE_USER');

    useEffect(() => {
        setSelected(props.selectedRole ? props.selectedRole : 'ROLE_USER');
    }, [props]);

    const setSelectedRole = (selected) => {
        props.setSelectedRole(selected)
    }

    const getButton = (title, isSelected, icon) => {
        let stroke = isSelected === title ? 'white' : 'dark';

        let settings = {
            title: props.getValue(title),
            icon: icon,
            background: isSelected === title ? 'var(--item)' : 'var(--background)',
            color: isSelected !== title ? 'var(--caption)' : 'var(--white)',
            callback: setSelectedRole.bind(this, title),
        }
        if(title === 'ROLE_USER')
            settings['stroke'] = stroke
        else if(title === 'ROLE_ADMIN' && title === isSelected)
            settings['fill'] = 'white'

        return <StandartButton {...settings}/>

    }

    return (
        <div className={s.SwitchAdminMode}>
            <div className={s.modeButtons}>
                {getButton('ROLE_USER', selected, User)}
                {getButton('ROLE_ADMIN', selected, Admin)}
            </div>
            
        </div>
    )
}

export default SwitchAdminMode;