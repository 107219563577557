import NotificationsStatusesController from "./notifications-statuses-controller";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import {modalShowToggle} from "../../store/modal";
import { getValue } from "../../store/languages";
import { getNotificationsStatusesData, getCompanyNotificationsStatusesData, retryReportOperation, retryAdminReportOperation, setRetryReport, setNotificationsStatusesData, deleteReportOperation, deleteAdminReportOperation, getNotificationsStatus, setReloadedStatuses } from "../../store/notificationsStatuses";
import { setItemsCount, setCurrentPage, setShowedItemsCount, setToDefaultPaginator } from "store/paginator";
import { getAdminUsers } from "selectors/admin-topics-selector";

let mapStateToProps = (state) => {
    return {
        notificationsStatuses: state.notificationsStatuses.notificationsStatuses,
        notificationsIsFetching: state.notificationsStatuses.notificationsIsFetching,
        notificationsCount: state.notificationsStatuses.notificationsCount,
        retryReport: state.notificationsStatuses.retryReport,
        reloadedStatuses: state.notificationsStatuses.reloadedStatuses,
        reportFormatTypes: state.report.reportFormatTypes,
        selectedRole: state.AdminMode.selectedRole,
        users: getAdminUsers(state),
        role: state.Account.role,
    }
}

const NotificationsStatusesContainer = (NotificationsStatusesController);

export default compose(
                        connect(
                            mapStateToProps, {
                                            modalShowToggle, 
                                            getValue, 
                                            getNotificationsStatusesData, 
                                            getCompanyNotificationsStatusesData, 
                                            retryReportOperation,
                                            retryAdminReportOperation,
                                            setRetryReport, 
                                            setNotificationsStatusesData, 
                                            deleteReportOperation,
                                            deleteAdminReportOperation,
                                            getNotificationsStatus, 
                                            setReloadedStatuses,
                                            setCurrentPage
                                        }))(NotificationsStatusesContainer)