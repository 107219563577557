import React, { useEffect, useState } from "react";
import s from "../side-bar.module.scss";
import { ReactComponent as ShowMore } from "assets/icons/show-more.svg";
import { ReactComponent as AngleUp } from 'assets/icons/angle-up.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';

const LeftMenu = (props) => {


    const [height, setHeight] = useState(200);
    const [mouseDown, setMouseDown] = useState(false);
    const [disabelUpButton, setDisableUpButton] = useState(false)
    const [disabelDownButton, setDisableDownButton] = useState(false)

    const handleMouseDown = (event) => {
        setMouseDown(true);
        event.preventDefault();
    }

    const handleMouseUp = () => {
        setMouseDown(false);
    }

    const handleMouseMove = (event) => {
        if (mouseDown) {
            if(event.pageY-180 > 42 && event.pageY <= 715)
                setHeight(event.pageY-180)
            checkDisableButtons(event.pageY-180)
        }
    }

    const checkDisableButtons = (height) => {
        if(height <= 62){
            setDisableDownButton(true)
        }
        else setDisableDownButton(false)
        if(height <= 500)
            setDisableUpButton(false)
        else setDisableUpButton(true)
    }

    const resizeBlockUp = () => {
        if(height < 500){
            setHeight(height+42)
        }
        checkDisableButtons(height+42)
    }

    const resizeBlockDown = () => {
        if(height > 62){
            setHeight(height-42)
        }
        checkDisableButtons(height-42)
    }

    const getBlock = (c, index) => {
        if(props.leftMenuComponent && props.leftMenuComponent.length > 1)
            return index === 0 
                                ? <div className={s.LeftBlock} key={index} style={{height: height, minHeight: height}}>{c.component}</div> 
                                : <div className={s.LeftBlock} key={index}>{c.component}</div>
        else return <div className={s.LeftBlock} key={index}>{c.component}</div>
    }

    let leftMenu = props.leftMenuComponent && props.leftMenuComponent.map((c, i) => getBlock(c, i))
    let twoBlocks = props.leftMenuComponent && props.leftMenuComponent.length > 1 ? s.twoBlocks : '';
    if(props.leftMenuComponent && props.leftMenuComponent.length > 1){
        leftMenu.splice(1, 0, 
            <div className={s.resizeBlock} onMouseDown={handleMouseDown} key={101}>
                <button disabled={disabelUpButton} className={s.control} onClick={resizeBlockUp}><AngleUp /></button>
                <div className={s.showMoreIcon}>{<ShowMore />}</div>
                <button disabled={disabelDownButton} className={s.control} onClick={resizeBlockDown}><AngleDown /></button>
            </div>
        )
    }
    

    return (
        <div className={props.showMenu ? s.LeftMenu+' '+s.showMenu+' '+twoBlocks : s.LeftMenu} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
            {/*<DropdownWithSearch title={"Темы"} getValue={props.getValue}/>*/}
            {/*props.leftMenuComponent && props.leftMenuComponent.component*/}
            {leftMenu}

        </div>
    )
}

export default LeftMenu;