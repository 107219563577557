import React, { useEffect, useState } from "react";
import List from "pages/settings-page/list/list";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import s from "./company-topic-add-item.module.scss"

const CompanyTopicAddItem = (props) => {

    const [selectedAccounts, setSelectedAccounts] = useState(props.topicAccounts ? props.topicAccounts : [])
    const old = props.selectedAccounts ? [...props.selectedAccounts] : []
    useEffect(() => {
        setSelectedAccounts(props.topicAccounts ? props.topicAccounts : [])
    }, [props.topicAccounts])

    const getToDetachAccounts = () => {
        let detached = old.filter(k => selectedAccounts.indexOf(k) === -1)

        return detached
    }
    const getAttached = () => {
        let attached = selectedAccounts.filter(k => old.indexOf(k) === -1)

        return attached
    }

    const setSelectedAccountsTopics = (selected) => {
        setSelectedAccounts(selected)
    }

    const attachAccountsToTopic = () => {
        //let attached = getAttached()
        props.attachAccountsToTopic(props.companyId, selectedAccounts, props.topicId)
        //let detached = getToDetachAccounts()
        //props.detachAccountsFromTopic(props.companyId, detached, props.topicId)
        props.closeRightMenu();
    }

    return <div className={s.CompanyTopicAddItem}>
        <List list={props.accounts} setSelected={setSelectedAccountsTopics} selected={selectedAccounts} showOnly={false}/>
        <StandartButton 
                    icon={PlusIcon} 
                    stroke={'white'}
                    title={props.detach ? props.getValue('DETACH') : props.getValue('ATTACH')}
                    callback={attachAccountsToTopic}
                    disabled={ false }//!props.detach ? !selectedItemId : !props.companyId}
                />
    </div>
}

export default CompanyTopicAddItem;