import { ReportService } from "../connection/report-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const ADD_NOTIFICATIONS_STATUSES_DATA = "ADD-NOTIFICATIONS-STATUSES-DATA";
const ADD_NOTIFICATION_STATUS = "ADD-NOTIFICATION-STATUS";
const SET_NOTIFICATIONS_IS_FETCHING = "SET-NOTIFICATIONS-IS-FETCHING"
const SET_RETRY_REPORT = "SET_RETRY_REPORT";
const SET_REMOVE_RELOADED_STATUSES = "SET-REMOVE-RELOADED-STATUSES";

let notificationsStatusesData = {
    notificationsStatuses: [],
    notificationsCount: null,
    notificationsIsFetching: false,
    retryReport: false,
    reloadedStatuses: []
}

const notificationsStatuses = (state = notificationsStatusesData, action) => {
    switch(action.type){
        case ADD_NOTIFICATIONS_STATUSES_DATA:
            let content = action.notificationsStatuses.content;
            let count = action.notificationsStatuses.totalElements;
            return {...state, notificationsStatuses: content, notificationsCount: count}
        case ADD_NOTIFICATION_STATUS:
            
            let finded = state.notificationsStatuses.findIndex(k => k.id === action.notificationStatus.id);
            let data = state.notificationsStatuses;
            if(finded !== -1){
                data[finded] = action.notificationStatus;
            }
            return {...state, notificationsStatuses: data, retryReport: false}
        case SET_NOTIFICATIONS_IS_FETCHING:
            return {...state, notificationsIsFetching: action.notificationsIsFetching}
        case SET_RETRY_REPORT:
            return {...state, retryReport: action.retryReport}
        case SET_REMOVE_RELOADED_STATUSES:
            return {...state, reloadedStatuses: action.reloadedStatuses}
        default:
            return {...state}
    }
}

export default notificationsStatuses;

export const setReloadedStatuses = (reloadedStatuses) => {
    return {
        type: SET_REMOVE_RELOADED_STATUSES,
        reloadedStatuses: reloadedStatuses
    }
}

export const setNotificationsIsFetching = (notificationsIsFetching) => {
    return {
        type: SET_NOTIFICATIONS_IS_FETCHING,
        notificationsIsFetching: notificationsIsFetching
    }
}

export const setNotificationsStatusesData = (notificationsStatuses) => {
    return {
        type: ADD_NOTIFICATIONS_STATUSES_DATA,
        notificationsStatuses: notificationsStatuses
    }
}

export const setNotificatrionStatus = (notificationStatus) => {
    return {
        type: ADD_NOTIFICATION_STATUS,
        notificationStatus: notificationStatus
    }
}

export const setRetryReport = (retryReport) => {
    return {
        type: SET_RETRY_REPORT,
        retryReport: retryReport
    }
}

export const getNotificationsStatus = (id) => {
    return (dispatch) => {
        setNotificationsIsFetching(true)
        dispatch(setRetryReport(true));
        ReportService.getReportOperation(id).then(data=>{
            dispatch(setNotificationsIsFetching(false))
            
            dispatch(setNotificatrionStatus(data.data));
            
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getNotificationsStatusesData = (page, pageSize) => {
    return (dispatch) => {
        setNotificationsIsFetching(true)
        ReportService.getReportOperations(page, pageSize).then(data=>{
            dispatch(setNotificationsIsFetching(false))
            dispatch(setNotificationsStatusesData(data));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getCompanyNotificationsStatusesData = (page, pageSize) => {
    return (dispatch) => {
        setNotificationsIsFetching(true)
        ReportService.getCompanyReportOperations(page, pageSize).then(data=>{
            dispatch(setNotificationsIsFetching(false))
            dispatch(setNotificationsStatusesData(data));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const retryReportOperation = (id) => {
    return (dispatch) => {
        dispatch(setRetryReport(true));
        ReportService.retryReportOperation(id).then(data => {
            dispatch(setNotificatrionStatus(data.data));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const retryAdminReportOperation = (id) => {
    return (dispatch) => {
        dispatch(setRetryReport(true));
        ReportService.retryAdminReportOperation(id).then(data => {
            dispatch(setNotificatrionStatus(data.data));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const deleteReportOperation = (id, page, pageSize) => {
    return (dispatch) => {
        setNotificationsIsFetching(true)
        ReportService.deleteReportOperation(id).then(data => {
            dispatch(getNotificationsStatusesData(page, pageSize))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const deleteAdminReportOperation = (id, page, pageSize) => {
    return (dispatch) => {
        setNotificationsIsFetching(true)
        ReportService.deleteAdminReportOperation(id).then(data => {
            dispatch(getCompanyNotificationsStatusesData(page, pageSize))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}
