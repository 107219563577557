import AddTagController from './add-tag-controller';
import { connect } from 'react-redux';
import { getCompanyTags, addNewTag, addNewTagColor, createCompanyTag, addTagToDocument, addTagsToListDocuments, removeTagFromDocument, deleteTagsFromListDocuments } from 'store/tags';
import { setSelectedPostTags, addTagByFilter, removeTagByFilter, updateTagsMultipleTopics, deleteTagsMultipleTopics } from "store/storage"
import { modalShowToggle } from "store/modal";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { getValue } from "store/languages"

let mapStateToProps = (state) => {
    return {
        companyId: state.Account.companyId,
        tags: state.Tags.tags,
        newTag: state.Tags.newTag,
        newTagColor: state.Tags.newTagColor,
        colors: state.Tags.colors,
        updatedDocuments: state.storage.updatedDocuments,
        selectedPostTags: state.storage.selectedPostTags,
        selectedUUId: state.Tree.selectedUUId,
        selectedTopics: state.Filters.selectedTopics,
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        selectedSubItem: state.Tree.selectedSubItem,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        selectedAllList: state.storage.selectedAllList,
        selectedItems: state.Filters.selectedItems,
        selectedMyFilter: state.Filters.selectedMyFilter,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        pageName: state.Filters.pageName,
        searchList: state.SearchText.searchList
    }
}

const CallUsContainer = connect(mapStateToProps, { 
                                                    getCompanyTags, 
                                                    addNewTag, 
                                                    addNewTagColor, 
                                                    createCompanyTag, 
                                                    addTagToDocument, 
                                                    addTagsToListDocuments, 
                                                    modalShowToggle, 
                                                    getValue, 
                                                    removeTagFromDocument, 
                                                    setSelectedPostTags, 
                                                    deleteTagsFromListDocuments, 
                                                    addTagByFilter, 
                                                    removeTagByFilter,
                                                    updateTagsMultipleTopics,
                                                    deleteTagsMultipleTopics })(AddTagController);

export default CallUsContainer;