import { ReactComponent as Light } from "../assets/icons/light.svg";
import { ReactComponent as Mix } from '../assets/icons/mix.svg';
import { ReactComponent as Dark } from '../assets/icons/dark.svg';

const themeName = 'selectedTheme';
const SET_MAIN_THEME = "SET-MAIN-THEME";

if(!sessionStorage.getItem(themeName)){
    sessionStorage.setItem(themeName, 'Echo');
}
else if(sessionStorage.getItem(themeName) !== 'Echo')
    sessionStorage.setItem(themeName, 'Echo');

let selectMainThemeMenu = (selectedTheme) => {
    sessionStorage.setItem(themeName, selectedTheme)
}

let mainThemeState = {
    selectedTheme: sessionStorage.getItem(themeName),
    icon: 'light',
    callback: selectMainThemeMenu,
    ThemeItems: 
    [
        {name: "Light", short_name: 'Light', icon: Light, title: 'LIGHT'},
        {name: "Mix", short_name: 'Mix', icon: Mix, title: 'MIX'},
        {name: "Dark", short_name: 'Dark', icon: Dark, title: 'DARK'},
        {name: "Echo", short_name: 'Echo', icon: Light, title: 'ECHO'}
    ]
}

let mainThemes = (state = mainThemeState, action) => {
    switch(action.type){
        case SET_MAIN_THEME:
            return {...state, selectedTheme: action.selectedTheme}
        default:
            return state

    }
}

export default mainThemes;

export const setSelectedMainTheme = (type, selectedTheme) => {
    selectMainThemeMenu(selectedTheme);
    return {
        type: SET_MAIN_THEME,
        selectedTheme: selectedTheme
    }
}

export const retJs = () => {
    let m = sessionStorage.getItem('selectedLanguage').toLocaleLowerCase();
    //languagesPack = require(`../assets/titles/tiltles_${m}.js`).default;
}
