import PaginatorController from "./paginator-controller";
import { connect } from "react-redux";
import { setItemsCount, setCurrentPage, setShowedItemsCount } from "store/paginator";
import { getValue } from "store/languages";
import { getTopicDocuments } from "../../store/storage";

let mapStateToProps = (state) => {
    return {
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        selectedLanguage: state.languages.selectedLanguage,
    }
}

const PaginatorContainer = (connect(mapStateToProps, {getValue, setItemsCount, setCurrentPage, setShowedItemsCount})(PaginatorController));

export default PaginatorContainer;