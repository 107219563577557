import React from "react";
import NotificationsStatuses from "./notifications-statuses";

class NotificationsStatusesController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            currentPage: 0,
            showedItemsCount: 10,
            count: this.props.notificationsCount,
            selectedRole: this.props.selectedRole
        }
    }

    componentDidMount(){
        this.getNotificationsData(this.state.currentPage, this.state.showedItemsCount);
        //if(!this.props.notificationsIsFetching && !this.props.notificationsStatuses.length)
            
    }

    componentDidUpdate(){
        if(this.props.selectedRole !== this.state.selectedRole){
            this.getNotificationsData(this.state.currentPage, this.state.showedItemsCount);
            this.setState({
                selectedRole: this.props.selectedRole,
                currentPage: 0
            })
        }
    }

    getNotificationsData = (currentPage, showedItemsCount) => {
        if(this.props.selectedRole === 'ROLE_ADMIN'){
            this.props.getCompanyNotificationsStatusesData(currentPage, showedItemsCount)
        }
        else
            this.props.getNotificationsStatusesData(currentPage, showedItemsCount)
    }

    setPage = (page) => {
        this.setState({
            currentPage: page
        });
        this.getNotificationsData(page, this.state.showedItemsCount);
    }

    setShowedCount = (count) => {
        this.getNotificationsData(0, count);
        this.setState({
            showedItemsCount: count,
            currentPage: 0
        });
    }

    componentWillUnmount(){
        this.props.setNotificationsStatusesData([]);
        this.props.setReloadedStatuses([])
    }

    downloadReport = (url) => {
        let link = document.createElement('a');
        link.target = "_blank";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    render(){
        return <NotificationsStatuses downloadReport={this.downloadReport} {...this.props} currentPage={this.state.currentPage} setPage={this.setPage} showedItemsCount={this.state.showedItemsCount} setShowedCount={this.setShowedCount}/>
    }
}

export default NotificationsStatusesController;