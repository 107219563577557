import React from "react";
import Digest from "./digest";
import DigestItemContainer from "./digest-item/digest-item-container";
import DigestViewItem from "./digest-view-item/digest-view-item";
import ControlButtons from "items/control-buttons/control-buttons";

class DigestController extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            title: "",
            topicName: false,
            hiddenColumns: this.props.selectedRole === 'ROLE_ADMIN' ? [1, 5, 9] : [1, 2, 5, 9],
            controlButtons: this.props.controlButtons,
            selectedTopic: this.props.selectedTopic,
            selectedDigestTopic: this.props.selectedDigestTopic,
            controllerButtons: [],
            currentPage: this.props.currentPage,
            selectedRole: this.props.selectedRole,
        }
        this.title = "";
        this.topicNames = []
        this.controlButtons = [];

    }

    closeItem = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    closeMenu = (e) => {
        this.props.setSelectedUser(null);
    }

    getTopicItem = (topic, itemName) => {
        return topic && topic[itemName] ? topic[topicName] : null
    }

    showRightMenu = (show, title, digest) => {

        if(show){
            this.props.setShowRightMenu(true)
            if(digest){
                this.props.setRightMenuComponent(<DigestItemContainer
                                                            topics={this.props.topics}
                                                            closeItem={this.closeItem}
                                                            {...digest}
                                                            
                                                />, 'digest', null, title, true, null, this.closeMenu)
            }
            else{
                this.props.setRightMenuComponent(<DigestViewItem
                    topics={this.props.topics}
                    closeItem={this.closeItem}
                    {...digest}

        />, 'digest', null, title, true)
            }
        }
        else{
            this.props.setRightMenuComponent(null);

        }
    }

    componentDidMount(){
        this.props.getMyTopics();
        this.props.getDigestData(this.props.selectedRole);
        this.props.getAllFilters();
    }
    
    getTopicName = (uuid) => {
        let k = this.props.topics.find((f,i)=>{return f.uuid === uuid}) 
        return k
    }

    getSubtopicName = (topicUUId, uuid, subtopics = null, type = null) => {
        let s = null
        if(!subtopics){
            let k = this.props.topics.find((f,i)=>{return f.uuid === topicUUId})
            if(k && ((k.subtopics && k.subtopics !== undefined)) && k.subtopics.length)
            s = k.subtopics.find(f => f.uuid === uuid)
        }
        else
            s = subtopics.find(f => f.uuid === uuid)
        let res = s ? type ? s[type] : s : null
        return res
    }

    getSubtopics = (uuid) => {
        this.props.getSubtopics(uuid)
    }

    getFilterName = (id, type) => {
        if(this.props.myFilters){
            let f = this.props.myFilters.find((f,i)=>{return f.id === id}) 
            if(f) return !type ? f : (f[type] ? f[type] : null)
            else null
        }

    }

    getPeriodText = (period) => {
        switch(period){
            case 'DAILY':
                return this.props.getValue('DAYS');
            case 'WEEKLY':
                return this.props.getValue('WEEKS');
            case 'MONTHLY':
                return this.props.getValue('MONTHS');
            default:
                return period
        }
    }

    componentDidUpdate(){
        if(this.state.selectedRole !== this.props.selectedRole || this.props.totalElements !== this.state.totalElements ){
            let hiddenColumns = this.state.hiddenColumns;
            if(this.props.selectedRole === 'ROLE_USER'){
                hiddenColumns.push(2)
            }
            else{
                hiddenColumns = [1, 5, 9]
            }
            this.setState({
                selectedRole: this.props.selectedRole,
                hiddenColumns: hiddenColumns,
            })
            
            this.props.getDigestData(this.props.selectedRole);
        }
        if(this.props.tableItems && this.props.tableItems.data && this.props.tableItems.data.length){
            this.props.setItemsCount(this.props.tableItems.data.length);
        }
        
        if(this.props.topics && (this.props.tableItems && this.props.tableItems.data.table)){
            this.topicNames = this.props.tableItems.data.table.map(k => {
                return this.getTopicName(k[0])
            })
        }
        if(this.props.selectedDigestTopic !== this.state.selectedDigestTopic || this.props.isUpdated){
            this.props.setDigestDataUpdated(false);
            return true
        }

    }

    getPeriodIndex = (periods) => {
        let n = [];
        n = periods.map(k => this.props.weekDays.indexOf(k));
        return n
    }

    getUserName = (userId) => {
        if(this.props.users){
            if(isNaN(userId))
            return userId
            let index = this.props.users.findIndex(k => k.id === userId)
            return index !== -1 ? this.props.users[index].name : null
        }
        else return userId

    }

    getPeriodByType = (period, weekDays = null) => {
        switch(period){
            case 'WEEKLY':
                return {weekDays: weekDays};
            case 'MONTHLY':
                return {monthDays : weekDays};
            default:
                return null
        }
    }

    getSelectedReportFormat = (format) => {
        let r = this.props.reportFormatTypes.find(f => f.short_name === format)
        return r && r.title ? this.props.getValue(r.title) : format
    }

    getSelectedDigest = (digest, topic) => {

    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type
        })
        this.title = type;
        let selectedTopic = null
        let selectedDigest = {}
        switch(type){
            case 'create':{
                selectedTopic = {}
                selectedTopic.showType = type;
                this.props.setSubtopics([]);
                this.props.setSelectedDigestTopic(null, null, null, null)
                this.props.setSelectedDigestSubtopic(null, null, null)
                this.showRightMenu(true, "DIGESTS_CREATION", selectedTopic)
                this.props.setSelectedUser(null)
                break;
            }
            case 'delete':
                this.props.deleteDigest(this.props.selectedRole, key)
                break;
            case 'edit':
                let topicAll = this.getTopicName(this.props.tableItems.data[index].topicUUID)
                selectedDigest = {}
                selectedDigest = this.props.tableItems.data[index];
                selectedDigest.topic = topicAll;
                selectedDigest.isSubtopic = this.props.tableItems.data[index].subtopicUUID ? true : false
                selectedDigest.filter = this.getFilterName(this.props.tableItems.data[index].filterId);                                                                              
                selectedDigest.userId = this.props.tableItems.data[index].userId ? this.props.tableItems.data[index].userId : null;
                selectedDigest.periods = this.props.tableItems.data[index].items && this.props.tableItems.data[index].items.length 
                ? (this.props.tableItems.data[index].type === 'WEEKLY' ? this.getPeriodIndex(this.props.tableItems.data[index].items) : this.props.tableItems.data[index].items)
                : []
                selectedDigest.subtopic = this.getSubtopicName(this.props.tableItems.data[index].topicUUID, this.props.tableItems.data[index].subtopicUUID, this.props.tableItems.subtopics)
                //topicAll.showType = 'edit'
                selectedDigest.showType = 'edit';
                this.showRightMenu(true, "DIGEST_DISTRIBUTION_SETUP", selectedDigest);
                break;
            case 'show':
                selectedTopic = {}
                selectedDigest = {}
                let topicAlls = this.getTopicName(this.props.tableItems.data[index].topicUUID)
                selectedDigest = this.props.tableItems.data[index];
                selectedDigest.topic = topicAlls;
                selectedDigest.isSubtopic = this.props.tableItems.data[index].subtopicUUID ? true : false
                selectedDigest.filter = this.getFilterName(this.props.tableItems.data[index].filterId);
                selectedDigest.periods = this.props.tableItems.data[index].items && this.props.tableItems.data[index].items.length 
                ? (this.props.tableItems.data[index].type === 'WEEKLY' ? this.getPeriodIndex(this.props.tableItems.data[index].items) : this.props.tableItems.data[index].items)
                : [] 
                selectedDigest.showType = 'show';
                this.showRightMenu(true, "DIGEST_SETTINGS_VIEW", selectedDigest);
                break;
            case 'ACTIVE':
                this.props.pauseDigest(this.props.selectedRole, this.props.tableItems.data[index].id);
                
                break;
            case 'PAUSED':
                this.props.activateDigest(this.props.selectedRole, this.props.tableItems.data[index].id);
                break;
            case 'copy':
                
                let topic = this.props.tableItems.data[index];      
                let period = this.getPeriodByType(topic.type, topic.items ? topic.items : null)
                let digestSchedule = {type: topic.type, times: topic.times, ...period}
                if(topic.subtopicUUID)
                    this.props.createDigestSubtopic(this.props.selectedRole, topic.userId, topic.topicUUID, topic.subtopicUUID, topic.formatType, topic.emails, topic.filterId, digestSchedule, topic.includeAnalytics)
                else
                    this.props.createDigest(this.props.selectedRole, topic.topicUUID, topic.userId, topic.formatType, topic.emails, topic.filterId, digestSchedule, topic.includeAnalytics);
                break;
            default:
                this.props.modalShowToggle("Alert", true,  this.closeModal, "Действие", "Действие данной кнопки находтся в стадии разработки")
                return;
        }
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        
        c = [...items];
        c.unshift(status)
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    setTableValues = (tableValues) => {
        this.controlButtons = []
        let items = []
        tableValues.forEach((k, i)=>{
            if(typeof(k[k.length-1]) !== "object"){
    
                let id = k.id;
                let name = this.getUserName(k.userId);
                let topic  = this.getTopicName(k.topicUUID);
                let subTopic = k.subtopicUUID ? this.getSubtopicName(k.topicUUID, k.subtopicUUID, null, 'name') : '';
                let period = this.props.getValue(k.type);
                let emails = k.emails.join(",");
                let filter = k.filterId ? this.getFilterName(k.filterId, 'name') : '';
                let format = this.getSelectedReportFormat(k.formatType);
                let times = k.formatedTimes;

                let status = k.status;
                
                let ctrl = [];
                ctrl = this.props.controlButtons

                if(this.props.selectedRole === 'ROLE_ADMIN' && name){
                    items.push([i+1, name, topic && topic.name ? topic.name : '', subTopic, period, emails, filter, times, format])
                    this.controlButtons.push(this.getControlButtonItems(id, status, ctrl, i))
                }
                else if(this.props.selectedRole === 'ROLE_USER'){
                    items.push([i+1, topic && topic.name ? topic.name : '', subTopic, period, emails, filter, times, format])
                    this.controlButtons.push(this.getControlButtonItems(id, status, ctrl, i))
                }
            }
        })
        return items
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    render(){
        
        return this.props.tableItems && <Digest 
                                                tableHeader={this.getTableHeader(this.props.tableHeader)} 
                                                hiddenColumns={this.state.hiddenColumns} 
                                                setSelectedDigest={this.props.setSelectedDigest} 
                                                topics={this.props.topics} 
                                                data={this.props.tableItems.table.length && this.setTableValues(this.props.tableItems.data)} 
                                                getValue={this.props.getValue} 
                                                controllerButtons={this.controlButtons}
                                                controlButtons={this.props.controlButtons}
                                                onTableItemsClick={this.onTableItemsClick}/>
    }

    componentWillUnmount(){
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

}

export default DigestController;