import React,{ useEffect, useState } from "react";
import List from "pages/settings-page/list/list";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import s from './admin-markers-add-item.module.scss'

const AdminMarkerAddItem = (props) => {
    const [topics, setTopics] = useState(props.adminTopics);
    const [selectedTopics, setSelectedTopics] = useState(props.markerTopics ? props.markerTopics : []);

    useEffect(() => {
        setTopics(props.adminTopics);
        setSelectedTopics(props.markerTopics ? props.markerTopics : []);
    }, [props]);

    const setTopicsItems = (topics) => {
        setSelectedTopics(topics)
    }

    const attachTopics = () => {
        props.attachTopicsToAccount(props.isSuperadmin, props.markerId, selectedTopics, props.companyId, props.role)
        props.closeRightMenu()
    }

    return (
        <div className={s.AdminMarkerAddItem}>
            <List list={topics} setSelected={setTopicsItems} selected={selectedTopics} showOnly={false}/>
            <StandartButton title={props.getValue('ATTACH')} callback={attachTopics} icon={PlusIcon} stroke={'white'}/>
        </div>
    )
}

export default AdminMarkerAddItem;