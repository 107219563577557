import React from "react";
import s from "./default-days.module.scss";

const DefaultDays = (props) => {

    const setPeriodDay = (period) => {
        props.callback(period)
    }

    return (
        <div className={s.DefaultDays}>
            <div className={s.DefaultDaysButton} onClick={setPeriodDay.bind(this, 'today')}>
                <span>{props.getValue('TODAY_2')}</span>
            </div>

                <div className={s.Separator}></div>

            <div className={s.DefaultDaysButton} onClick={setPeriodDay.bind(this, 'week')}>
                <span>{props.getValue('WEEK_2')}</span>
            </div>

                <div className={s.Separator}></div>

            <div className={s.DefaultDaysButton} onClick={setPeriodDay.bind(this, 'month')}>
                <span>{props.getValue('MONTH')}</span>
            </div>

                <div className={s.Separator}></div>

            <div className={s.DefaultDaysButton} onClick={setPeriodDay.bind(this, 'year')}>
                <span>{props.getValue('YEAR')}</span>
            </div>
        </div>
    )
}

export default DefaultDays;