import { createSelector } from "reselect";
import moment from "moment";

const digestHeader = (state) => {
    let tableHeader = state.digest.tableHeader;
    if(state.AdminMode.selectedRole === 'ROLE_ADMIN'){
        tableHeader = state.digest.tableAdminHeader
    }
    return tableHeader;
}

const digestTableFromState = (state) => {
    let data = state.digest.data
    
    if(state.AdminMode.selectedRole === 'ROLE_ADMIN'){
        data.AdminMode = true
    }
    return data;
}

const formatDate = (date) =>{
    return moment(date).format("HH:mm DD.MM.YY");
}

const getTimes = (times) => {
    return times.map(k => k.split('+')[0])
}

export const getDigestTableHeader = createSelector(digestHeader, (header) => {
    return header
})

export const getDigestTableFromState = createSelector(digestTableFromState, (table) => {
    if(table && table.content){
        let data = {table: [], data: []}
        table.content.forEach((item, i) => {
            let items = item.digestSchedule.type === "WEEKLY" ? item.digestSchedule.weekDays : (item.digestSchedule.type === "MONTHLY" ? item.digestSchedule.monthDays : '')
            data.table[i] = ['',item.id, item.userId ? item.userId : null, item.topicUUID, item.subtopicUUID, item.digestSchedule.type, item.status, item.emails.join(", "), item.filterId, getTimes(item.digestSchedule.times).join(', '), items, item.type];

            let d = {id: item.id,
                userId: item.userId,
                topicUUID: item.topicUUID,
                subtopicUUID: item.subtopicUUID,
                type: item.digestSchedule.type, 
                status: item.status, 
                emails: item.emails, 
                filterId: item.filterId, 
                items: items, 
                formatType: item.type,
                includeAnalytics: item.includeAnalytics,
                reportFeedType: item.reportFeedType,
                times: item.digestSchedule.times,
                formatedTimes: getTimes(item.digestSchedule.times).join(', '),
            }
            data.data.push(d)
        })
    
        return data
    }

})