import { StorageService } from "../connection/storage-service";
import { SentimentService } from "../connection/sentiment-service";
import { TopicService } from "connection/topics-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import FilterList from "assets/filter-list/filter-list";
import moment from 'moment';

const SET_STORAGE_UUID = "SET-STORAGE-UUID";
const SET_STORAGE_ROWS = "SET-STORAGE-ROWS";
const SET_STORAGE_PAGE = "SET-STORAGE-PAGE";
const SET_STORAGE_DATA = "SET-STORAGE-DATA";
const SET_SELECTED_SENTIMENT = "SET-SELECTED-SENTIMENT";
const SET_IS_FETCHING = "SET-IS-FETCHING";
const SET_UPDATED_DOCUMENTS = "SET-UPDATED-DOCUMENTS";
const SET_UPDATED_LIST_OF_DOCUMENTS = "SET-UPDATED-LIST-OF-DOCUMENTS"
const SET_CLEAR_UPDATED_DOCUMENTS = "SET-CLEAR-UPDATED-DOCUMENTS";
const SET_REMOVE_TAG_FROM_DOCUMENTS = "SET-REMOVE-TAG-FROM-DOCUMENTS";
const SET_REMOVE_POST = "SET-REMOVE-POST";
const SET_UPDATE_STORAGE_DATA = "SET-UPDATE-STORAGE-DATA";
const SET_SELECTED_POST_ITEM = "SET-SELECTED-POST-ITEM";
const SET_SELECT_ALL_ITEMS = "SET-SELECT-ALL-ITEMS";
const SET_SELECTED_ALL_LIST = "SET-SELECTED-ALL-LIST";
const SET_SELECTED_ITEMS_TAGS = "SET-SELECTED-ITEMS_TAGS";
const SET_REMOVE_ITEM_FROM_TAGS = "SET-REMOVE-ITEM-FROM-TAGS";
const SET_NEW_POSTS_COUNT = "SET-NEW-POSTS-COUNT";
const SET_NEW_POSTS_IS_FETCHING = "SET-NEW-POSTS-IS_-FETCHING";
const SET_RESET_COUNTER = "SET-RESET-COUNTER";
const SET_SORT = "SET-SORT";
const SET_ALL_SHOW_MORE_TEXT = "SET-ALL-SHOW-MORE-TEXT";
const SET_STATISTICS_ERROR = "SET-STATISTICS-ERROR";

let storageData = {
    uuid: null,
    rows: 10,
    page: 0,
    storageData: {
        docs: [],
        numFoundNegative: 0,
        numFoundNeutral: 0,
        numFoundPositive: 0,
        numFoundTotal: 0,
        numFoundUndefined: 0,
        topicIDs: null
    },
    updatedDocuments: [],
    isFetching: false,
    selectedSentiment: null,
    updateStorageData: false,
    selectedPostItems: [],
    selectedPostTags: {},
    updatedList: false,
    statisticPostsCount: 0,
    newPostsCount: 0,
    newPostsIsFetching: false,
    selectedAllList: false,
    sort: FilterList.sortFilters[1],
    sortName: 'DATE_↑',
    allShowMoreText: false,
    statisticsError: false
}

let locationsPack = null;

const getDateTimeFormat = (date) => {
    return date//moment(date).format('YYYY-MM-DDTHH:mm:ss')+'Z'
}

export const retJs = () => {
    let m = sessionStorage.getItem('selectedLanguage').toLocaleLowerCase();
    if(m === undefined)
        m = "RUS";
        locationsPack = require(`../assets/locations/locations_${m ? m : 'rus'}.js`).default;
}

export const getLocations = (value) => {
    return (dispatch) => {
        if(!locationsPack)
            dispatch(retJs);
            return locationsPack[value] ? locationsPack[value] : value
        
    }    
}

const storage = (state = storageData, action) => {
    switch(action.type){
        case SET_STORAGE_UUID:
            return {...state, uuid: action.uuid}
        case SET_STORAGE_ROWS:
            return {...state, rows: action.rows}
        case SET_STORAGE_PAGE:
            return {...state, page: action.page}
        case SET_STORAGE_DATA:
            return {...state, storageData: action.storageData, updatedDocuments: []}
        case SET_SELECTED_SENTIMENT:
            return {...state, selectedSentiment: action.selectedSentiment}
        case SET_IS_FETCHING:
            return {...state, isFetching: action.isFetching}
        case SET_UPDATED_DOCUMENTS:
            let nt = state.storageData.docs.findIndex( k => k.signature === action.updatedDocuments.signature )
            if(nt !== -1){
                let docs = {...state.storageData}
                docs.docs[nt] = action.updatedDocuments;
                return {...state, storageData: docs, updatedDocuments: action.updatedDocuments}
            }
        case SET_UPDATED_LIST_OF_DOCUMENTS:
            let docs = {...state.storageData}
            action.updatedDocuments.forEach((item, i) => {
                let nt = state.storageData.docs.findIndex( k => k.signature === item.signature )
                if(nt !== -1){
                    
                    docs.docs[nt] = action.updatedDocuments[i];
                    //rt {...state, storageData: docs, updatedDocuments: action.updatedDocuments}
                }
            });
            return {...state, storageData: docs, updatedDocuments: action.updatedDocuments}
            
        case SET_CLEAR_UPDATED_DOCUMENTS:
            return {...state, updatedDocuments: action.updatedDocuments}
        case SET_REMOVE_TAG_FROM_DOCUMENTS:
            let ntiz = state.storageData.docs.findIndex( k => k.signature === action.signature );
            let fu = state.updatedDocuments;
            let fs = state.storageData;

            if(fu.updatedDocuments && fu.signature === action.signature){
                fu.docs.tags.filter( k => k !== action.tagId)
            }
            
            if(ntiz !== -1){
                fs.docs[ntiz].tags = state.storageData.docs[ntiz].tags.filter(k => k !== action.tagId)
            }

            return {...state, storageData: fs, updatedDocuments: [1]}
        case SET_REMOVE_POST:
            let data = {...state.storageData}
            let posts = [];
            if(action.signature.length)
                posts = data.docs.filter( k => k.signature !== action.signature[0] );
            data.docs = posts;
            let isUpdate = data.docs.length > 0 ? false : true
            return {...state, storageData: data, updateStorageData: isUpdate}
        case SET_UPDATE_STORAGE_DATA:
            return {...state, updateStorageData: action.updateStorageData}
        case SET_SELECTED_POST_ITEM:
            let selectedItems = [...state.selectedPostItems];
            let selected = selectedItems.indexOf(action.selectedPostItem)

            let tags = {...state.selectedPostTags}
            let tag = {...state.selectedPostTags}

            if(selected !== -1){
                selectedItems.splice(selected, 1);
                if(action.tags && action.tags.length){
                    action.tags.forEach((k, i)=>{
                        if(tag[k] && tag[k] > 1){
                            tag[k] -= 1
                        }
                        else
                            delete tag[k]
                    })
                    
                }
            }
            else{
                selectedItems.push(action.selectedPostItem)
                if(action.tags && action.tags.length){
                    action.tags.forEach((k, i)=>{
                        if(!tag[k])
                            tag[k] = 1
                        else
                            tag[k] += 1
                    })
                    
                }
            }            
            return {...state, selectedPostItems: selectedItems, selectedPostTags: tag}
        case SET_SELECT_ALL_ITEMS:
            let post = {signature: [], tags: {}};
            action.selectedPostItems ? action.selectedPostItems.forEach(p => {
                post.signature.push(p.signature);
                if(p.tags && p.tags !== undefined){
                    p.tags.forEach((k, i)=>{
                        if(!post.tags[k])
                            post.tags[k] = 1
                        else
                            post.tags[k] += 1
                    })
                }
            }) : []
            let tagsS = !post.signature.length ? {} : post.tags
            return {...state, selectedPostItems: post.signature, selectedPostTags: tagsS}
        case SET_SELECTED_ITEMS_TAGS:
            let postTags = {...state.selectedPostTags}
            if(action.selected){
                if(action.postCounts){
                    postTags[action.tag] = Number(action.postCounts)
                }
                else{
                    if(postTags[action.tag]) postTags[action.tag] += 1
                    else postTags[action.tag] = 1
                }
            }
            else{
                if(action.postCounts){
                    delete postTags[action.tag]
                }
            }
            return {...state, selectedPostTags: postTags, updatedList : !state.updatedList}
        case SET_REMOVE_ITEM_FROM_TAGS:
            let postTagsSelected = {...state.selectedPostTags}
            if(postTagsSelected[action.tag]){
                delete postTagsSelected[action.tag]}
            return {...state, selectedPostTags: postTagsSelected}
        case SET_NEW_POSTS_COUNT:
            let statisticPostsCount = action.reset ? 0 : state.statisticPostsCount === 0 ? action.newPostsCount : state.statisticPostsCount
            let newPostsCount = action.reset ? 0 : action.newPostsCount - statisticPostsCount
            return {...state, statisticPostsCount: statisticPostsCount, newPostsCount: newPostsCount}
        case SET_NEW_POSTS_IS_FETCHING:
            return {...state, newPostsIsFetching: action.newPostsIsFetching}
        case SET_STATISTICS_ERROR:
            return {...state, statisticsError: action.statisticsError}
        case SET_RESET_COUNTER:
            return {...state, statisticPostsCount: 0, newPostsCount: 0}
        case SET_SELECTED_ALL_LIST:
            return {...state, selectedAllList: action.selectedAllList}
        case SET_SORT:
            return {...state, sort: action.sort, sortName: action.sortName}
        case SET_ALL_SHOW_MORE_TEXT:
            return {...state, allShowMoreText: action.allShowMoreText}
        default:
            return {...state}
    }
}

export default storage;

const setStorageUIID = (uuid) => {
    return {
        type: SET_STORAGE_UUID,
        uuid: uuid
    }
}

export const setAllShowMoreText = (show) => {
    return {
        type: SET_ALL_SHOW_MORE_TEXT,
        allShowMoreText: show
    }
}

export const setSorting = (sort) => {
    return {
        type: SET_SORT,
        sort: sort,
        sortName: sort.name
    }
}

export const setUpdateStorageData = (updateStorageData) => {
    return {
        type: SET_UPDATE_STORAGE_DATA,
        updateStorageData: updateStorageData
    }
}

export const setStorageRows = (rows) => {
    return {
        type: SET_STORAGE_ROWS,
        rows: rows
    }
}

export const setStoragePage = (page) => {
    return {
        type: SET_STORAGE_PAGE,
        page: page
    }
}

export const setSelectedSentiment = (selectedSentiment) => {
    return {
        type: SET_SELECTED_SENTIMENT,
        selectedSentiment: selectedSentiment
    }
}

export const setIsFetching = (isFetching) => {
    return {
        type: SET_IS_FETCHING,
        isFetching: isFetching
    }
}

export const setNewPostsCount = (newPostsCount, reset) => {
    return {
        type: SET_NEW_POSTS_COUNT,
        newPostsCount: newPostsCount,
        reset, reset
    }
}

export const setStatisticsError = (statisticsError) => {
    return {
        type: SET_STATISTICS_ERROR,
        statisticsError: statisticsError
    }
} 

export const setNewPostsIsFetching = (isFetching) => {
    return {
        type: SET_NEW_POSTS_IS_FETCHING,
        newPostsIsFetching: isFetching,
    }
}

export const setSentimentType = (signature, sentiment) => {
    return (dispatch) => {
        SentimentService.setSentiment(signature, sentiment).then(data=>{
            dispatch(setUpdatedDocuments(data.data[0]))
            dispatch(setSelectedSentiment(sentiment))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const SentimentsOfList = (signatures, sentiment) => {
    return (dispatch) => {
        SentimentService.setSentimentOfList(signatures, sentiment).then(data => {
            dispatch(setUpdatedListOfDocuments(data.data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const setStorageData = (storageData) => {
    return {
        type: SET_STORAGE_DATA,
        storageData: storageData
    }
}

export const setSelectedPostItems = (selectedPostItem, tags) => {
    return {
        type: SET_SELECTED_POST_ITEM,
        selectedPostItem, selectedPostItem,
        tags: tags
    }
}

export const setSelectAllItems = (selectedPostItems) => {
    return {
        type: SET_SELECT_ALL_ITEMS,
        selectedPostItems: selectedPostItems
    }
}

export const setSelectedAllList = (selectedAllList) => {
    return {
        type: SET_SELECTED_ALL_LIST,
        selectedAllList: selectedAllList
    }
}

export const setSelectedPostTags = (tag, selected, postCounts = null) => {
    return {
        type: SET_SELECTED_ITEMS_TAGS,
        tag: tag,
        selected: selected,
        postCounts: postCounts
    }
}

export const setRemoveItemFromTags = (tag) => {
    return {
        type: SET_REMOVE_ITEM_FROM_TAGS,
        tag: tag
    }
}
/*
.catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
*/
export const getTopicDocuments = (topicId, rows, page, since, till, grouping, filters, subtopics, sort) => {
    return (dispatch) => {
        dispatch(setIsFetching(true));
        StorageService.getTopicDocuments(topicId, rows, page, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, subtopics, sort).then(data=>{
            dispatch(setStorageData(data));
            dispatch(setIsFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setIsFetching(false));
        })
    }
}

export const getTopicBlackListDocuments = (topicId, rows, page, since, till, grouping, filters, subtopics, sort) => {
    return (dispatch) => {
        dispatch(setIsFetching(true));
        StorageService.getTopicBlackListDocuments(topicId, rows, page, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, subtopics, sort).then(data=>{
            dispatch(setStorageData(data));
            dispatch(setIsFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setIsFetching(false));
        })
    }
}

export const getTopicFavouriteDocuments = (topicId, rows, page, since, till, grouping, filters, subtopics, sort) => {
    return (dispatch) => {
        dispatch(setIsFetching(true));
        StorageService.getFavouriteDocuments(topicId, rows, page, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, subtopics, sort).then(data=>{
            dispatch(setStorageData(data));
            dispatch(setIsFetching(false));
            
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setIsFetching(false));
        })
    }
}

export const getFavouritePublications = (topicId, rows, page, since, till, grouping, filters, subtopics, sort) => {
    return (dispatch) => {
        dispatch(setIsFetching(true));
        StorageService.getFavouritePublications(topicId, rows, page, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, sort).then(data=>{
            dispatch(setStorageData(data));
            dispatch(setIsFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setIsFetching(false));
        })
    }
}

export const getDeletedListDocuments = (topicId, rows, page, since, till, grouping, filters, subtopics, sort) => {
    return (dispatch) => {
        dispatch(setIsFetching(true));
        StorageService.getDeletedDocuments(topicId, rows, page, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, subtopics, sort).then(data=>{
            dispatch(setStorageData(data));
            dispatch(setIsFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setIsFetching(false));
        })
    }
}

export const setDocumentFavouriteStatus = (topicUUId, status, signatures) => {
    return (dispatch) => {
        StorageService.setFavouriteStatus(topicUUId, status, signatures).then(data=>{
            //dispatch(setUpdatedDocuments(data.data[0]))
            dispatch(setUpdatedListOfDocuments(data.data))
            //dispatch(setStorageData(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setIsFetching(false));
        })
    }
}

export const setDocumentFavouriteFilterStatus = (topicUUId, status, since, till, grouping ,filters) => {
    return (dispatch) => {
        StorageService.setFavouriteFilter(topicUUId, status, since, till, grouping ,filters).then(data=>{
            dispatch(setUpdatedDocuments(data.data[0]))
        }).catch(error => {

        })
    }
}
//----updateStatusMultiplePost
export const updateSentimentMultipleTopics = (sentiment, topics, feedType, since, till, grouping, filters) => {
    return (dispatch) => {
        StorageService.updateSentimentMultipleTopics(sentiment, topics, feedType, since, till, grouping, filters).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateStatusMultipleTopics = (status, topics, feedType, since, till, grouping, filters) => {
    return (dispatch) => {
        StorageService.updateStatusMultipleTopics(status, topics, feedType, since, till, grouping, filters).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateTagsMultipleTopics = (tagsUUId, topics, subtopic, feedType, since, till, grouping, filters) => {
    return (dispatch) => {
        StorageService.updateTagsMultipleTopics(tagsUUId, topics, subtopic, feedType, since, till, grouping, filters).then(data => {
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}//

export const deleteTagsMultipleTopics = (tagsUUId, topics, subtopic, feedType, since, till, grouping, filters) => {
    return (dispatch) => {
        StorageService.deleteTagsMultipleTopics(tagsUUId, topics, subtopic, feedType, since, till, grouping, filters).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateFavouriteMultipleTopics = (status, topics, feedType, since, till, grouping, filters) => {
    return (dispatch) => {
        StorageService.updateFavouriteMultipleTopics(status, topics, feedType, since, till, grouping, filters).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const setDomianStatus = (topicUUId, domian, status) => {
    return (dispatch) => {
        StorageService.setDomainsStatus(topicUUId, domian, status).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const setRemovePost = (signature) => {
    return {
        type: SET_REMOVE_POST,
        signature: signature
    }
}

export const setDocumentStatus = (signature, status, type) => {
    return (dispatch) => {
        StorageService.setDocumentStatus(signature, status).then( data => {
            type === 'group' ? dispatch(setUpdateStorageData(true)) : dispatch(setRemovePost(signature))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

//
export const setAuthorsStatus = (topicUUId, profileUrl, status) => {
    return (dispatch) => {
        StorageService.setAuthorsStatus(topicUUId, profileUrl, status).then( data => {
            //dispatch(setRemovePost(signature))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getNewPublicationsCount = (topicUUId, reset) => {
    return (dispatch) => {
        dispatch(setNewPostsIsFetching(true))
        StorageService.getNewPublications(topicUUId).then( data => {
            data.numFoundTotal && dispatch(setNewPostsCount(data.numFoundTotal, reset));
            dispatch(setStatisticsError(false));
        }).catch(error => {
            dispatch(setStatisticsError(true))
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
        dispatch(setNewPostsIsFetching(false))
    }
}


export const getSubtopicsNewPublicationsCount = (topicUUId, subtopicUUId, reset) => {
    return (dispatch) => {
        dispatch(setNewPostsIsFetching(true))
        StorageService.getSubtopicsNewPublications(topicUUId, subtopicUUId).then( data => {
            data.numFoundTotal && dispatch(setNewPostsCount(data.numFoundTotal, reset));
            dispatch(setStatisticsError(false));
        }).catch(error => {
            dispatch(setStatisticsError(true))
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
        dispatch(setNewPostsIsFetching(false))
    }
}


export const updateSentimentByFilter = (topicId, sentiment, since, till, grouping ,filters) => {
    return (dispatch) => {
        StorageService.updateSentimentByFilter(topicId, sentiment, since, till, grouping ,filters).then( data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const addTagByFilter = (topicId, tagUUID, since, till, grouping ,filters) => {
    return (dispatch) => {
        StorageService.addTagByFilter(topicId, tagUUID, since, till, grouping ,filters).then( data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const removeTagByFilter = (topicId, tagUUID, since, till, grouping ,filters) => {
    return (dispatch) => {
        StorageService.removeTagByFilter(topicId, tagUUID, since, till, grouping ,filters).then( data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const setStatusByFilter = (topicId, status, since, till, grouping ,filters) => {
    return (dispatch) => {
        StorageService.setStatusByFilter(topicId, status, since, till, grouping ,filters).then( data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const setPostToBlackList = () => {
    return (dispatch) => {

    }
}

export const setUpdatedDocuments = (updatedDocuments) => {
    return {
        type: SET_UPDATED_DOCUMENTS,
        updatedDocuments: updatedDocuments
    }
}


export const setUpdatedListOfDocuments = (updatedDocuments) => {
    return {
        type: SET_UPDATED_LIST_OF_DOCUMENTS,
        updatedDocuments: updatedDocuments
    }
}

export const setClearUpdatedDocuments = () => {
    return {
        type: SET_UPDATED_DOCUMENTS,
        updatedDocuments: []
    }
}

export const setRemoveTagFromDocuments = (signature, tagId) => {
    return {
        type: SET_REMOVE_TAG_FROM_DOCUMENTS,
        signature: signature,
        tagId: tagId
    }
}

export const resetCounter = () => {
    return {
        type: SET_RESET_COUNTER,
        counter: 0
    }
}


