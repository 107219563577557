import React from "react";
import Authorities from "./authorities";

class AuthoritiesController extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            adminAccountId: props.adminAccountId,
            accountId: props.accountId,
            updatedAuthority: props.updatedAuthority,
            showOnly: props.showOnly
        }
    }

    componentDidMount(){
        this.props.getAuthorities();
        if(this.props.accountId){
            this.props.getAccountAuthorities(this.props.accountId, this.props.isSuperadmin)
        }
    }

    componentDidUpdate(){
        if(!this.props.authorities || this.props.updatedAuthority || this.props.accountId !== this.state.accountId || this.props.showOnly !== this.state.showOnly){
            this.props.getAuthorities();
            this.props.setUpdatedAuthority(false);
            if(this.props.accountId && this.props.accountId !== this.state.accountId){
                this.props.getAccountAuthorities(this.props.accountId, this.props.isSuperadmin);
            }
            this.setState({
                accountId: this.props.accountId,
                showOnly: this.props.showOnly
            })
        }
    }

    render(){
        return (
            this.props.authorities && <Authorities 
                                                    authorities={this.props.authorities} 
                                                    selectedAuthorities={this.props.selectedAuthorities}
                                                    setSelectedAuthority={this.props.setSelectedAuthority}
                                                    roles={this.props.roles}
                                                    showOnly={this.props.showOnly}
                                                    getValue={this.props.getValue}
                                                    setSelectedAuthorities={this.props.setSelectedAuthorities}
                                                    page={this.props.page}
                                                    />
        )
    }

    componentWillUnmount(){
        this.props.setSelectedAuthorities([])
    }
}

export default AuthoritiesController