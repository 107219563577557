import React from "react";
import ReportForm from "./report-form";

class ReportFormController extends React.Component{

    render(){
        return (
            <ReportForm {...this.props}/>
        )
        
    }

}

export default ReportFormController