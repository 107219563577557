
const SET_SELECTED_TABLE_SORT = "SET-SELECTED-TABLE-SORT";

const themeSettingsState = {
    data: {
        labels: [
            {
                name: 'dropdown',
                title: '№',
                items: [
                    {
                        title: 'ALPHABETICALLY_A-Z_↓',
                        name: 'down',
                        short_name: 'down'
                    },
                    {
                        title: 'ALPHABETICALLY_Z-A_↑',
                        name: 'up',
                        short_name: 'up'
                    },
                    {
                        title: 'ALPHABETICALLY_A-Z_LATIN_↓',
                        name: 'latin_down',
                        short_name: 'latin_down'
                    },
                    {
                        title: 'ALPHABETICALLY_Z-A_LATIN_↑',
                        name: 'latin_up',
                        short_name: 'latin_up'
                    }
                ]
            }, 
            {
                name: 'dropdown',
                title: 'Название темы',
                items: [
                    {
                        title: 'ALPHABETICALLY_A-Z_↓',
                        name: 'down',
                        short_name: 'down'
                    },
                    {
                        title: 'ALPHABETICALLY_Z-A_↑',
                        name: 'up',
                        short_name: 'up'
                    },
                    {
                        title: 'ALPHABETICALLY_A-Z_LATIN_↓',
                        name: 'latin_down',
                        short_name: 'latin_down'
                    },
                    {
                        title: 'ALPHABETICALLY_Z-A_LATIN_↑',
                        name: 'latin_up',
                        short_name: 'latin_up'
                    }
                ]
            }, 
            {
                name: 'dropdown',
                title: 'Дата создания',
                items: [
                    {
                        title: 'ALPHABETICALLY_A-Z_↓',
                        name: 'down',
                        short_name: 'down'
                    },
                    {
                        title: 'ALPHABETICALLY_Z-A_↑',
                        name: 'up',
                        short_name: 'up'
                    },
                    {
                        title: 'ALPHABETICALLY_A-Z_LATIN_↓',
                        name: 'latin_down',
                        short_name: 'latin_down'
                    },
                    {
                        title: 'ALPHABETICALLY_Z-A_LATIN_↑',
                        name: 'latin_up',
                        short_name: 'latin_up'
                    }
                ]
            }
        ],
        values: [
            [
                1,2,3,4,5,6,7,8,9,10
            ],
            [
                'Пользователь 1',
                'Пользователь 2',
                'Пользователь 3',
                'Пользователь 4',
                'Пользователь 5',
                'Пользователь 6',
                'Пользователь 7',
                'Пользователь 8',
                'Пользователь 9',
                'Пользователь 10',
                'Пользователь 11',
                'Пользователь 12',
                'Пользователь 13',
                'Пользователь 14',
                'Пользователь 15',
            ],
            [
                '1',
                '12',
                '123',
                '12345',
                '123456',
                '1234567',
                '12345678',
                '123456789',
                '1234567890',
                '12345678901',
                '1234567890',
                '123456789',
                '12345678',
                '1234567',
                '123456',
            ]
        ],
    },
    selectedSortIndexes: []
            
}

const usersSettings = (state = themeSettingsState, action) => {
    switch(action.type){
        case SET_SELECTED_TABLE_SORT:
            let item = state.selectedItems.indexOf(action.selectedItem);
            if(item > -1) return {...state, selectedSortIndexes: state.selectedSortIndexes.filter(k => k !== action.selectedItem)}
            else return {...state, selectedSortIndexes: [...state.selectedSortIndexes, action.selectedItem]}
        default:
            return {...state}
    }
}

export default usersSettings;

export const changeUserPassword = (password, checkPassword) => {
    return (dispatch) => {

    }
}