import React from "react";
import TopicsSelectMenu from "./topics-select-menu";

class TopicsSelectMenuController extends React.Component{

    render(){

        return (
            <TopicsSelectMenu
                            getValue={this.props.getValue}                
                            topics={this.props.topics} 
                            selectedTopicsItems={this.props.selectedTopicsItems}
                            setSelectedTopicsItem={this.props.setSelectedTopicsItem}
                            setSelectedTopicsItems={this.props.setSelectedTopicsItems}
                            topicUUId={this.props.topicUUId}
            />
        )
    }
}

export default TopicsSelectMenuController