import CompanyTopicAddItemController from "./company-topic-add-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getAccounts, getTopicAccounts, attachAccountsToTopic, detachAccountsFromTopic } from "store/superadmin";
import { getAccountsName, getAccountsOnlyName, getActiveAccountsName } from "selectors/superadmin-selector";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";

let mapStateToProps = (state) => {
    return {
        companyId: state.superadmin.companyId,
        adminCompanyId: state.Account.companyId,
        accounts: getActiveAccountsName(state),
        topicAccounts: getAccountsOnlyName(state, true),
        oldTopicAccounts: getAccountsOnlyName(state, true),
        isSuperadmin: state.Account.isSuperadmin,
    }
}

const CompanyTopicAddItemContainer = connect(mapStateToProps, {  
    getValue,
    getAccounts,
    getTopicAccounts,
    attachAccountsToTopic,
    detachAccountsFromTopic,
    setRightMenuComponent, 
    setShowRightMenu, 
    setRemoveRightMenuComponent
})(CompanyTopicAddItemController)

export default CompanyTopicAddItemContainer