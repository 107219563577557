import React, {useState} from "react";
import ModalWithButtons from "./modal-with-buttons/modal-with-buttons";
import ModalWithButton from "./modal-with-button/modal-with-button";
import CallUsContainer from "./call-us/call-us-container";
import ForgotPassword from "./forgot-password/forgot-password";
import NotificationsStatuses from "./notifications-statuses/notifications-statuses";
import closeIcon from "../assets/icons/close.svg";
import s from "./modal-window.module.scss";
import AddTagContainer from "./add-tag/add-tag-container"
import ModalReportContainer from "./modal-report/modal-report-container";
import NotificationsStatusesContainer from "./notifications-statuses/notifications-statuses-container"
import SaveFiltersSettingsContainer from "./save-filters-settings/save-filters-settings-container";
import CompanyEditorContainer from "./company-editor/company-editor-container";
import CompanyUserEditorContainer from "./company-user-editor/company-user-editor-container"
import CompanyUsersContainer from "./company-users/company-users-container";
import DatepickerContainer from "./datepicker/datepicker-container";

import Draggable from 'react-draggable';
import KeywordsInstruction from "./instruction/keywords-instruction";
import SearchTextInstruction from "./instruction/search-text-instruction";
import AccountCabinetContainer from "./account-cabinet/account-cabinet-container";

const ModalWindow = (props) => {

    const [lightnest, setLightnest] = useState('')


    let toggleModal = () => {
        if(props.callback) props.callback('close');
        props.modalShowToggle(false);
    }

    let switchModal = (modal) => {
        switch(modal){
            case 'Exit':
            case 'exitCompany':
            case 'delete':
            case 'ACTIVE':
            
                return <ModalWithButtons {...props}/>
            case 'Alert':
            case 'Keywords':
                return <ModalWithButton {...props}/>
            case 'ForgotPassword':
            case 'ForgotPasswordError':
                return <ForgotPassword {...props} />
            case 'AddTags':
                return <AddTagContainer {...props} />
            case 'CallUs':
                return <CallUsContainer {...props}/>
            case "Report":
                return <ModalReportContainer {...props} />
            case "NOTIFICATIONS":
                return <NotificationsStatusesContainer {...props} />
            case "SaveFilters":
                return <SaveFiltersSettingsContainer {...props} />
            case "CompanyEditor":
                return <CompanyEditorContainer {...props} />
            case "CompanyUserEditor":
                return <CompanyUserEditorContainer {...props}/>
            case "CompanyUsers":
                return <CompanyUsersContainer {...props}/>
            case "Datepicker":
                return <DatepickerContainer {...props} />
            case "KeywordsInstruction":
                return <KeywordsInstruction {...props} />
            case "SearchTextInstruction":
                return < SearchTextInstruction {...props} />
            case "Account":
                return <AccountCabinetContainer {...props}/>
        }
    }

    const handleStart = () => {

    }

    const handleDrag = () => {
        
    }

    const handleStop = () => {
        setLightnest(s.lightnest)
    }

    if(props.showModal || props.errorMessage){
        return(
            <div className={s.ModalWindow+' '+lightnest}>
                <Draggable
                    handle=".handle"
                    onStop={handleStop}
                    bounds="parent"
                >
                    <div className={s.modalWindow}>
                        <div className={s.modalHeader}>
                            <div className={s.draggableZone+' handle'}>
                                <div></div>
                                <span>{props.title}</span>
                                <button onClick={toggleModal} className={s.closeButton}>
                                    {/*closeIcon ? <img src={closeIcon} alt="✕"/> : <div className={s.close}>&#215;</div> */}
                                </button>
                            </div>
                        </div>
                        <div className={s.modalBody}>
                            
                            {switchModal(props.modalName)}
                        </div>
                    </div>
                </Draggable>
                
                
            </div>
        )
    }
}

export default ModalWindow; 