import React, { useState, useEffect } from "react";
import { StandartButton } from "../../commons/form-controls";
import { InputText } from "../../commons/input-controls";
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import s from "./emails-list.module.scss";

const EmailsList = (props) => {

    const [emails, setEmails] = useState(props.emails ? props.emails : []);
    const [showOnly, setShowOnly] = useState(props.showOnly);
    const [rerender, setRerender] = useState(false);

    useEffect(()=>{
        setEmails(props.emails ? props.emails : []);
        setShowOnly(props.showOnly);
        setRerender(!rerender);
    }, [props.emails]);

    const addNewEmail = () => {
        let e = emails;
        e.push(null)
        setEmails(e);
        props.callback(e)
        setRerender(!rerender);
    }

    const setReportEmail = (index, email) => {
        let e = emails;
        e[index] = email;
        setEmails(e);
        props.callback(e)
        setRerender(!rerender);
    }

    const removeEmail = (index) => {
        let e = emails;
        e.splice(index, 1);
        setEmails(e);
        props.callback(e)
        setRerender(!rerender);
    }

    const addNewEmailButton = <StandartButton width={34} icon={PlusIcon} stroke={'dark'} background="background" callback={addNewEmail}/>

    const emailsRow = emails.map((k, i) => {return <div key={i} className={s.emailRow} style={{display: "flex"}}>
                                            <InputText readOnly={showOnly} value={k} callback={setReportEmail.bind(this, i)} placeholder={"email"} />
                                            {!showOnly && <StandartButton width={34} icon={CloseIcon} stroke={'dark'} background="background" callback={removeEmail.bind(this, i)}/>}
                                            {(!showOnly && i === emails.length -1) && addNewEmailButton}
                                        </div>}
    ) 

    return (
        <div className={s.EmailsList}>
            {emailsRow}
            {(!showOnly && !emailsRow.length) && addNewEmailButton}
        </div>
    )
}

export default EmailsList;