import React, { useState, useEffect} from "react";
import {InputText, SelectDropdown} from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import s from "./admin-add-item.module.scss";

const AdminAddItem = (props) => {

    const getItems = (items) => {
        if(items && items.length){
            let filteredItems = items.filter(k => k.status === 'ACTIVE')
            return filteredItems
        }
        else
            return []
    }

    const [items, setItems] = useState(props.items ? getItems(props.items) : []);
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedItemId, setSelectedItemId] = useState(null)
    const [type, setType] = useState(props.type)

    useEffect(()=>{
        setItems(props.items ? getItems(props.items) : []);
        setSelectedItem(null);
        setType(props.type)
    }, [props.items, props.type])

    const selectItem = (item) => {
        setSelectedItem(item.name)
        setSelectedItemId(item.id)
    }

    const addItem = () => {
        props.detach 
                    ? props.callback(props.accountId, props.companyId)
                    : props.callback(selectedItemId)
    }

    const itemList = items.length && items.map((k,i) => {return {name: k.name ? k.name : k.login, id: k.id}})

    return (
        <div className={s.AdminAddItem}>       
            <div className={s.formRow}>
                {props.detach 
                            ? <InputText readOnly={true} value={props.detach}/>
                            : <SelectDropdown items={itemList} setSelected={selectItem} selected={selectedItem}/>
                }
            </div>     
            <div className={s.formRow}>
                <StandartButton 
                    icon={PlusIcon} 
                    stroke={'white'}
                    title={props.detach ? props.getValue('DETACH') : props.getValue('ATTACH')}
                    callback={addItem}
                    disabled={!props.detach ? !selectedItemId : !props.companyId}
                />
            </div>
        </div>

    )
}

export default AdminAddItem