import AddTopicsItemController from "./add-admin-topic-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getMarkerTopics, detachMarkerTopic, setTopicsPage, getAllMarkers } from "store/admin-markers";
import { getTopicMarkers, detachTopicMarker, getAllTopics, setSearchText, setStartSearch } from "store/admin-topics";
import { modalShowToggle } from "store/modal";

let mapStateToProps = (state) => {

    return {
        adminTopics: state.AdminTopics.topics,
        adminMarkers: state.AdminMarkers.markers,
        topicsPage: state.AdminMarkers.topicsPage,
        markerTopics: state.AdminMarkers.markerTopics,
        topicMarkers: state.AdminTopics.topicMarkers,
        searchText: state.AdminTopics.searchText,
        startSearch: state.AdminTopics.startSearch
    }
}

const AddTopicsItemContainer = connect(mapStateToProps, {     
                                                        getValue,
                                                        getMarkerTopics,
                                                        getTopicMarkers,
                                                        detachMarkerTopic,
                                                        detachTopicMarker,
                                                        setTopicsPage,
                                                        getAllMarkers,
                                                        getAllTopics,
                                                        setSearchText,
                                                        setStartSearch,
                                                        modalShowToggle
                                    })(AddTopicsItemController)

export default AddTopicsItemContainer