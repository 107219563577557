import CheckArchive from "./check-archive";
import { checkProfile, checkResource } from "store/topic-item";
import { connect } from "react-redux";
import { getValue } from "store/languages";

let mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, {checkProfile, checkResource, getValue})(CheckArchive)