import React from "react";
import DropdownWithSearch from "./dropdown-with-search";

class DropdownWithSearchController extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            selected: [] 
        }

        
    }
    clickOnCheckBox = (item) => {
        if(this.state.selected.indexOf(item) !== -1){
            this.setState({
                selected: this.state.selected.filter(k => k !== item)
            })
        } 
        else{
            this.setState({
                selected: [...this.state.selected, item]
            })

        }
    }

    render(){
        return <DropdownWithSearch selected={this.state.selected} {...this.props} clickOnCheckBox={this.clickOnCheckBox}/>
    }

}

export default DropdownWithSearchController;