import React, { useEffect, useState } from "react";
import ApexChartContainer from "apex-charts/apex-chart-container";
import WordsBlock from "pages/extended-analytic/analytic/words-block/words-block";
import s from "./analytic.module.scss"

const Analytics = (props) => {

    const getLocations = (location) => {
        let name = props.locationsName && props.locationsName[location] ? props.locationsName[location] : location
        return name
    }

    const getData = (data) => {
        /*let res = {}
        res = data*/
        if(data.chartId === "AuthorsLocations"){
            data.labels = props.locationsName ? data.labels.map(k => getLocations(k)) : data.labels
        }
        return data
    }

    const [firstData, setFirstData] = useState(getData(props.firstData));
    const [secondData, setSecondData] = useState(props.secondData ? props.secondData : props.secondData);
    const [thirdData, setThirdData] = useState(props.thirdData ? props.thirdData : props.thirdData)

    useEffect(() => {
        setFirstData(getData(props.firstData));
        setSecondData(props.secondData ? props.secondData : props.secondData);
        setThirdData(props.thirdData ? props.thirdData : props.thirdData)
    }, [props.firstData, props.secondData, props.thirdData])

    let firstHeight = firstData.height//firstData.chartId === 'AuthorsSentiment' || firstData.chartId === 'MediaType' || firstData.chartId === 'AuthorsLocations' || firstData.chartId === 'Top20' || firstData.chartId === 'KeyWords' ? 450 : 290
    let secondHeight = secondData.height//secondData.chartId === 'FollowersCount' || secondData.chartId === 'Top20Languages'  ? 450 : 290

    return <div className={s.Analytics}>
        <div>
        {((firstData && firstData.pageType === 'KeyWords') || (thirdData && thirdData.pageType === 'PopularWords' ) || (firstData.pageType === 'Tags')) 
        && <WordsBlock data={thirdData} getValue={props.getValue} pageType={thirdData.pageType}/>}
        </div>
        <div className={s.AnalyticChart}>
            {firstData && firstData.data && firstData.data.length ? 
                <ApexChartContainer 
                                    height={firstHeight}
                                    width={'100%'}
                                    title={firstData.title}
                                    type={firstData.chartType} 
                                    getValue={props.getValue} 
                                    data={{values: firstData.data, labels: firstData.labels, topicIDs: firstData.topicIDs}} 
                                    labels={firstData.labels} 
                                    colors={firstData.colors}
                                    fields={firstData.fields}
                                    fieldsType={firstData.fieldsType}
                                    stacked={true}
                                    legend={true}
                /> : <div></div>}
        </div>
        {secondData && secondData.data && secondData.data.length 
            ? <div className={s.AnalyticChart}>
            
                <ApexChartContainer 
                                    height={secondHeight}
                                    width={'100%'}
                                    title={secondData.title}
                                    type={secondData.chartType} 
                                    getValue={props.getValue} 
                                    data={{values: secondData.data, labels: secondData.labels, topicIDs: secondData.topicIDs}} 
                                    labels={secondData.labels} 
                                    colors={secondData.colors}
                                    fields={secondData.fields}
                                    fieldsType={secondData.fieldsType}
                                    stacked={true}
                                    legend={true}
                                    /> 
            </div>
        : <div></div>}
        {

        }
    </div>
}

export default Analytics