import React from "react";
import PeriodCalendar from "./period-calendar";
import moment from "moment";
import { getFormatedDate } from "service/format-date";

class PeriodCalendarController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate
        }
    }

    getDefaultTime = (startDate, endDate) => {
        startDate.set('hour', '00');
        startDate.set('minute', '00');
        startDate.set('seconds', '00');
        //endDate = moment();
        endDate.set('hour', '23');
        endDate.set('minute', '59');
        endDate.set('seconds', '59');
        return [startDate, endDate]
    }

    setPeriodDay = (period) => {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        switch(period){
            case 'today':
                startDate = moment();
                this.props.setGrouping("HOURLY");
                break;
            case 'week':
                startDate = moment().subtract(6, "days");
                this.props.setGrouping("DAILY");
                break;
            case 'month':
                startDate = moment().subtract(1, "months");
                this.props.setGrouping("WEEKLY");
                break;
            case 'year':
                startDate = moment().subtract(1, "years");
                this.props.setGrouping("MONTHLY");
                break;
        }
        endDate = moment();
        [startDate, endDate] = this.getDefaultTime(startDate, endDate)

        this.props.setStartDate(new Date(moment(startDate).format('YYYY-MM-DDTHH:mm:ss')));
        this.props.setEndDate(new Date(moment(endDate).format('YYYY-MM-DDTHH:mm:ss')));
    }

    showCalendar = (show) => {
        this.props.modalShowToggle("Datepicker",true, null, this.props.getValue('SELECTION_DATES_FILTER'))
        //this.props.modalShowToggle("Alert", true, this.closeModal.bind(this, this), this.props.getValue(this.props.notificationInModal.title), this.props.getValue(this.props.notificationInModal.message))
    }

    setGropingType = (grouping) => {
        this.props.setGrouping(grouping)
    }

    render(){
        return  <div>
            <PeriodCalendar 
                                getValue={this.props.getValue}
                                showCalendar={this.showCalendar}
                                setPeriodDay={this.setPeriodDay}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                grouping={this.props.grouping}
                                setGropingType={this.setGropingType}
                                footerButtons={this.props.footerButtons}
                />
        </div>
    }

}

export default PeriodCalendarController;