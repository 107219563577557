import React, {useState, useEffect} from "react";
import { InputText, SelectDropdown } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import ItemsList from "../items/items-list";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as CancelIcon } from "assets/icons/close.svg";
import s from "./add-admin-topic-item.module.scss"

const AddTopicsItem = (props) => {

    const getItems = (items) => {
        if((items && items.content) && items.content.length){
            //let filteredItems = items.content.filter(k => k.status === 'ACTIVE')
            return items.content//filteredItems
        }
        else
            return []
    }

    const [items, setItems] = useState(getItems(props.typeItems));
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [topicsCount, setTopicsCount] = useState(props.typeItems.totalPages);
    const [page, setPage] = useState(props.topicsPage);
    const [itemId, setItemId] = useState(props.itemId);
    const [type, setType] = useState(props.type)
    const [title, setTitle] = useState(props.title)
    const [searchText, setSearchText] = useState(props.searchText);
    const [searchStart, setSearchStart] = useState(false);
    const [markerTopicsList, setMarkerTopicsList] = useState((props.itemItems && props.itemItems.content) ? props.itemItems.content : [])

    useEffect(()=>{
        setItems(getItems(props.typeItems));
        setSelectedItem(null);
        setType(props.type)
        setTopicsCount(props.typeItems.totalPages);
        setPage(props.topicsPage);
        setItemId(props.itemId);
        setTitle(props.title);
        setSearchText(props.searchText);
        setMarkerTopicsList((props.itemItems && props.itemItems.content) ? props.itemItems.content : []);
    }, [props])

    const selectItem = (item) => {

        setSelectedItem(item.name)
        setSelectedItemId(item.id)
    }

    const addItem = () => {
        props.callback(selectedItemId)
        
    }

    const itemList = items.length && items.map((k,i) => {return {name: k.name ? k.name : k.login, id: k.id}})

    const setTopicsPage = (page) => {
        props.setPage(page)
    }

    const pageButtons = (buttonsLength) => {
        let buttons = [];
        for(let i = 0; i < buttonsLength; i++){
            buttons.push(<div key={i} className={i === page ? s.active : null} onClick={setTopicsPage.bind(this, i)}>{i+1}</div>)
        }
        return buttons
    }

    const detachMarkerTopic = (id) => {

        let topicId = type === 'topics' ? id : itemId
        let markerId = type === 'topics' ? itemId : id
        props.detachMarkerTopic('detach', markerId, topicId)
    }

    const getMarkerTopic = (index, topic) => {
        return (
                <tr key={index}>
                    <td>{index}</td>
                    <td>{type === 'topics' ? topic.name : topic.login}</td>
                    <td><button title={props.getValue('SET_REMOVE')} className={s.deleteButton} onClick={detachMarkerTopic.bind(this, topic.id)}><MinusIcon /></button></td>
                </tr>
                )
    }

    const markerTopics = props.itemItems && props.itemItems.content ? props.itemItems.content.map( (k, i) => {return getMarkerTopic(i+1,k)}) : null

    const setText = (text) => {
        props.setSearchText(text)
    }

    const searchItem = (start) => {
        if(!start){
            setSearchText('');
            props.startSearchText(true)
            props.setSearchText('')
        }
        else{
            //props.setSearchText('')
            props.startSearchText(true)
        }
        setSearchStart(start)
    }

    const searchButton = <StandartButton icon={SearchIcon} callback={searchItem.bind(this, true)} fill={'white'}/>

    const cancelButton = searchStart ? <StandartButton icon={CancelIcon} callback={searchItem.bind(this, false)} background={'var(--background)'}/> : null

    return (
        <div className={s.AdminAddItem}>
            <div className={s.formRow}>
                <InputText labelText={type === 'markers' ? props.getValue('TOPIC') : props.getValue('ACCOUNT')} value={title} readOnly={true}/>
            </div>
            {(props.itemItems && props.itemItems) && props.itemItems.content.length ? <div className={s.MarkerTopics}>
                <div className={s.labelText}>{type === 'markers' ? props.getValue('TOPIC_ACCOUNTS') : props.getValue('ACCOUNT_TOPICS')}</div>
                <div className={s.markerTopicsList}>
                    <table>
                        <tbody>
                            {markerTopics}
                        </tbody>
                    </table>
                </div>
                
            </div> : null}
            <div className={s.formRow}>
                <div className={s.labelText}>{type === 'markers' ? props.getValue('ACCOUNTS') : props.getValue('TOPICS')}</div>
                <ItemsList items={itemList} selected={markerTopicsList} callback={selectItem}/>
            </div>
            <div className={s.pages}>
                {topicsCount > 1 ? pageButtons(topicsCount) : null}
            </div>

            {/*<div className={s.formRow}>
                <div className={s.searchBlock}>
                    <InputText callback={setText} value={props.searchText}/>
                    { cancelButton }
                    { searchButton }
                </div>
            </div>
            <div className={s.formRow}>
                {props.detach 
                            ? <InputText readOnly={true} value={props.detach}/>
                            : <SelectDropdown height={300} items={itemList} setSelected={selectItem} selected={selectedItem}/>
                }
            </div>*/}

            <div className={s.formRow}>
                <StandartButton 
                    icon={PlusIcon} 
                    stroke={'white'}
                    title={props.detach ? props.getValue('DETACH') : props.getValue('ATTACH')}
                    callback={addItem}
                    disabled={!props.detach ? !selectedItemId : !props.companyId}
                />
            </div>

        </div>

    )
}

export default AddTopicsItem