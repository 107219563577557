import React, { useState, useEffect } from "react";
import AdvancedItem from "./advanced-item/advanced-item";
import { ReactComponent as AngleUp } from 'assets/icons/angle-up.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';
import { StandartButton } from "commons/form-controls";
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { InputCheckBox, InputText } from "commons/input-controls";
import {ReactComponent as PlusIcon} from "assets/icons/plus.svg";
import {ReactComponent as CloseIcon} from "assets/icons/close.svg";
import s from "./advanced.module.scss"

const AdvancedTopicSettings = (props) => {

    const [selectedItems, setSelectedItems] = useState(props.selectedAdvanced);
    const [showItem, setShowItem] = useState({massMediaScale: true});
    const [socialInclude, setSocialInclude] = useState((props.topicItem && props.topicItem.topicSettings) ? props.topicItem.topicSettings.socialInclude : true)
    const [socialAllowUndefinedLocation, setSocialAllowUndefinedLocation] = useState((props.topicItem && props.topicItem.topicSettings) ? props.topicItem.topicSettings.socialAllowUndefinedLocation : true);
    const [massInclude, setMassInclude] = useState((props.topicItem && props.topicItem.topicSettings) ? props.topicItem.topicSettings.massInclude : true)
    const [massDomainText, setMassDomainText] = useState(null);
    const [socialDomain, setSocialDomain] = useState((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.socialDomain) ? props.topicItem.topicSettings.socialDomain : []);
    const [massDomain, setMassDomain] = useState((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.massDomain) ? props.topicItem.topicSettings.massDomain : [])
    const [advertisement, setAdvertisement] = useState((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.advertisement) ? props.topicItem.topicSettings.advertisement : false);
    const [profanity, setProfanity] = useState((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.profanity) ? props.topicItem.topicSettings.profanity : false);
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [rerender, setRerender] = useState(false);

    useEffect(() => {

        //setSocialInclude((props.topicItem && props.topicItem.topicSettings) ? props.topicItem.topicSettings.socialInclude : true)
        //setSocialAllowUndefinedLocation((props.topicItem && props.topicItem.topicSettings) ? props.topicItem.topicSettings.socialAllowUndefinedLocation : true);
        //setMassInclude((props.topicItem && props.topicItem.topicSettings) ? props.topicItem.topicSettings.massInclude : true)
        //setSocialDomain((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.socialDomain) ? props.topicItem.topicSettings.socialDomain : []);
        //setMassDomain((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.massDomain) ? props.topicItem.topicSettings.massDomain : [])
        setIsEdit(props.isEdit);
        setAdvertisement((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.advertisement) ? props.topicItem.topicSettings.advertisement : false);
        setProfanity((props.topicItem && props.topicItem.topicSettings && props.topicItem.topicSettings.profanity) ? props.topicItem.topicSettings.profanity : false);
        setRerender(!rerender);
    }, [props.topicItem])

    const addMassDomain = (e) => {
        let massDomains = massDomain;
        massDomains.push(massDomainText)
        setMassDomainText(null)
        setMassDomain(massDomains)
        setRerender(!rerender)
    }

    const removeMassDomain = (index) => {
        let massDomains = massDomain;
        massDomains.splice(index, 1)
        setMassDomain(massDomains)
        setRerender(!rerender)
    }

    const addMassDomainText = (e) => {
        setMassDomainText(e)
    }

    const toggleMassInclude = (e) => {
        setMassInclude(e)
    }

    const setInclude = (e) => {
        setSocialInclude(e)
        setRerender(!rerender)
    }

    const setAllowUndefinedLocation = (e) => {
        setSocialAllowUndefinedLocation(e)
        setRerender(!rerender)
    }

    const setIsAdvertisement = (e) => {
        setAdvertisement(e)
        setRerender(!rerender)
    }

    const setIsProfanaty = (e) => {
        setProfanity(e)
        setRerender(!rerender)
    }

    const getObjectNameByTitle = (title) => {
        switch(title){
            case 'massCountryId':
            case 'socialLocationId':
                return 'countries'
            case 'languageList':
                return 'languages'
            default:
                return title
            
        }
    }

    const saveSettings = () => {
        let item = {...props.topicItem.topicSettings};
        let data = {} 
        Object.entries(item).forEach( ([k, v], i) => {
            data[k] = [];
            if((item[k] && (item[k].length === props[getObjectNameByTitle(k)])) || (!item[k] || !item[k].length)){
                delete data[k]
            }
            else{
                data[k] = item[k].map(f => f)
            }
        })
        data.massInclude = massInclude;
        data.socialInclude = socialInclude;
        data.socialAllowUndefinedLocation = socialAllowUndefinedLocation;
        if(massDomain && massDomain.length) data.massDomain = massDomain;
        data.aggression = true
        data.profanity = profanity;
        //data.advertisement = true
        data.complaint = true
        data.advertisement = advertisement;
        props.saveTopicSettings(props.topicItem.id,data)
    }

    const setSelecteItem = (title, item, id, list) => {
        props.setSelectedAdvanced(title, {selectedList: id ? id : list, selectedName: item})
    }

    const setSelectedItemsAll = (title, isSelected, items, except) => {
        props.setSelectedAdvancedAll(title, isSelected, items, except)
    }

    const getSelectedItems = (title, items) => {
        return items && items[title] ? items[title] : []
    }

    const setShowItemBlock = (item, subitem) => {
        let itemsShow = showItem
        if(itemsShow[item])
            delete itemsShow[item]
        else{
            if(item === 'socialLocationId' || item === 'massCountryId'){
                getCountries()
            }
            itemsShow[item] = true
        }
        setShowItem(itemsShow)
        setRerender(!rerender);
    }

    const showBlockIcon = (e, item) => {
        return showItem[item] ? <AngleDown /> : <AngleUp />
    }

    const getDataByTitle = (title) => {
        switch(title){
            case 'massMediaScale':
                return props.massMediaScale;
            case 'massMediaType':
                return props.massMediaType;
            case 'massCountryId':
                return props.countries
            case 'massRegionId':
                return props.regions
            case 'sentimentList':
                return props.sentimentList
            case 'socialLocationId':
                return props.countries
            case 'socialDomain':
                return props.domains
            case 'publicationType':
                return props.publicationType
            case 'languageList':
                return props.languages
        }
    }

    const getCountries = () => {
        props.getCountries()
    }

    const setSelectedSocialItem = (item, e) => {
        props.setSelectedSocialItem(item, e)
    }

    const getTitle = (title) => {

        switch(title){
            case 'massInclude':
                return 'MASS_INCLUDE';
            case 'massMediaType':
                return 'SOURCE_TYPE';
            case 'massMediaScale':
                return 'MEDIA_SCALE'
            case 'massCountryId':
            case 'socialLocationId':
                return 'STATES'
            case 'massRegionId':
                return 'REGIONS'
            case 'publicationType':
                return 'POST_TYPE'
            case 'sentimentList':
                return 'SENTIMENT'
            case 'languageList':
                return 'LANGUAGE'
            case 'socialDomain':
                return 'RESOURCE'
            case 'isAdvertising':
                return 'isAdvertising'
            case 'commonLocationCountryId':
                return 'common_location_country_id'
            default:
                return title
        }
    }
//            name: 'commonLocationCountryId',
//list: 'common_location_country_id'
    const getBlock = (title) => {
        return  <div className={s.AdvancedItemBlock}>
                    <div className={s.TitleBlock}>
                        <span>{props.getValue(getTitle(title))}</span>
                        <div className={s.toggleButton} onClick={setShowItemBlock.bind(this, title)}>{ showBlockIcon(this, title) }</div>
                    </div>
                    <AdvancedItem 
                                selectedChanged={props.selectedChanged} 
                                items={getDataByTitle(title)} 
                                callback={setSelecteItem} 
                                selected={getSelectedItems(title, props.topicItem.topicSettings)} 
                                title={title} setSelectedItemsAll={setSelectedItemsAll} 
                                getValue={props.getValue} showBlock={showItem[title]} 
                                countries={props.countries}
                                setSelectedSocialItem={setSelectedSocialItem}
                                regions={props.regions}
                                socialInclude={socialInclude}
                                setInclude={setInclude}
                                languages={props.languages}
                                isEdit={isEdit}
                                sng={props.sng}/>
                </div>
    }

    const socialDomainsListBlock = massDomain.map( (k, i) => 
                                                                <tr className={s.SocialDomains} key={i}>
                                                                    <td>{i+1}</td>
                                                                    <td>{k}</td>
                                                                    <td><button background="background" icon={CloseIcon} fill="white" onClick={removeMassDomain.bind(this, i)}><CloseIcon /></button></td>
                                                                </tr>)

    return (
        <div className={s.Advanced}>
            <div className={s.AdvancedType}>
                <div className={s.AdvancedItemBlock}>
                    <div className={s.formRow}>
                        <InputCheckBox isDisabled={props.isEdit === 'show' ? true : false} isChecked={massInclude} name={ 'massInclude' } callback={toggleMassInclude} title={'title2'} />
                        <span>{ props.getValue('MASS_INCLUDE') }</span>
                    </div>
                </div>
                {getBlock('massMediaType')}
                {getBlock('massMediaScale')}
                {(props.topicItem.topicSettings && props.topicItem.topicSettings.massMediaScale && props.topicItem.topicSettings.massMediaScale.indexOf('REGIONAL') !== -1) ? props.regions.length && getBlock('massRegionId') : null}
                {(props.topicItem.topicSettings && props.topicItem.topicSettings.massMediaScale && props.topicItem.topicSettings.massMediaScale.indexOf('INTERNATIONAL') !== -1) ? props.countries.length && getBlock('massCountryId') : null}
                {props.isEdit !== 'show' ? <div className={s.AdvancedItemBlock}>
                    <div className={s.formRow}>
                        <InputText value={massDomainText} callback={addMassDomainText} name="filterText" type="text" placeholder={props.getValue('RESOURCE')}  />
                        <StandartButton disabled={!massDomainText || massDomainText === ''} title={props.getValue('ADD')} icon={PlusIcon} fill="white" callback={addMassDomain}/>
                    </div>
                    <div className={s.SocialDomains}>
                        {massDomain.length ? <table><tbody>{socialDomainsListBlock}</tbody></table> : null }
                    </div>
                </div> : null}
            </div>
            <div className={s.AdvancedType}>
                
                <div className={s.AdvancedItemBlock}>
                    <div className={s.formRow}>
                        <InputCheckBox isDisabled={props.isEdit === 'show' ? true : false} isChecked={socialInclude} name={props.getValue('SOCIAL_INCLUDE') } callback={setInclude} title={props.getValue("SOCIAL_INCLUDE")} />
                        <span>{ props.getValue('SOCIAL_INCLUDE') }</span>
                    </div>
                </div>
                {getBlock('socialDomain')}
                <div className={s.AdvancedItemBlock}>
                    <div className={s.formRow}>
                        <InputCheckBox isDisabled={props.isEdit === 'show' ? true : false} isChecked={socialAllowUndefinedLocation} name={props.getValue('SOCIAL_ALLOW_UNDEFINED_LOCATION') } callback={setAllowUndefinedLocation} title={props.getValue('SOCIAL_ALLOW_UNDEFINED_LOCATION')} />
                        <span>{ props.getValue('SOCIAL_ALLOW_UNDEFINED_LOCATION') }</span>
                    </div>
                </div>
                {getBlock('socialLocationId')}
            </div>
            <div className={s.AdvancedType}>
                {getBlock('sentimentList')}
                {getBlock('publicationType')}
                {getBlock('languageList')}
            </div>
            <div className={s.AdvancedType}>
                <div className={s.AdvancedItemBlock}>
                    <div className={s.formRow}>
                        <InputCheckBox isDisabled={props.isEdit === 'show' ? true : false} isChecked={advertisement} name={props.getValue('ISADVERTISING') } callback={setIsAdvertisement} title={props.getValue('ISADVERTISING')} />
                        <span>{ props.getValue('ISADVERTISING') }</span>
                    </div>
                    <div className={s.formRow}>
                        <InputCheckBox isDisabled={props.isEdit === 'show' ? true : false} isChecked={profanity} name={props.getValue('ISSWEARING') } callback={setIsProfanaty} title={props.getValue('ISSWEARING')} />
                        <span>{ props.getValue('ISSWEARING') }</span>
                    </div>
                </div>
            </div>
            {props.isEdit !== 'show' ? <div className="formRow">
                <StandartButton disabled={false} callback={saveSettings} title={props.getValue('SAVE')} icon={SaveIcon} fill={'white'}/>
            </div> : null}
        </div>
    )
}

export default AdvancedTopicSettings;