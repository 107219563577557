import { header, getLanguage } from "./header";

const superadmin = header('restapi/api/v1/superadmin/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    superadmin.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    superadmin.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const SuperadminService = {
    getSuperadminCompanies(query, page = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return superadmin.get(`/companies/?query=${query}&pageIndex=${page}&pageSize=${pageSize}`).then(response => {
            return response.data
        })
    },
    editCompany(query){
        setLanguage();
        getAuth();
        return superadmin.put(`/companies/?query=${query}`).then(response => {
            return response.data
        })
    },
    activateDeactivateCompany(companyId, status){
        setLanguage();
        getAuth();
        return superadmin.post(`companies/${companyId}/${status}`).then(response => {
            return response.data
        })
    },
    createCompany(name, bin, maxNumTopics, status, expiryDate){
        setLanguage();
        getAuth();
        return superadmin.post(`/companies/`, {name, bin, maxNumTopics, expiryDate}).then(
            response => {
                return response.data
            }
        )
    },
    updateSuperAdminCompany(companyId, name, bin, maxNumTopics, status, expiryDate){
        let params = {}
        params.name = name;
        params.maxNumTopics = maxNumTopics;
        params.expiryDate = expiryDate;
        params.bin = bin && bin !== '' ? bin : null
        setLanguage();
        getAuth();
        return superadmin.put(`/companies/${companyId}`, {...params}).then(
            response => {
                return response.data
            }
        )
    },
    deleteSuperadminCompany(companyId){
        setLanguage();
        getAuth();
        return superadmin.delete(`/companies/${companyId}`).then(
            response => {
                return response.data
            }
        )
    },
    createAccount(login, fio, email, phone, password, locale){
        setLanguage();
        getAuth;
        let params = {}
        params.login = login
        params.fio = fio;
        params.password = password;
        if (locale) params.locale = locale
        if (phone) params.phone = phone;
        if (email) params.email = email;
        return superadmin.post(`/accounts/`, {...params}).then(
            response => {
                return response.data
            }
        )
    },
    addSuperadminCompany(companyId, accountId){
        setLanguage();
        getAuth();
        return superadmin.put(`/companies/${companyId}/accounts/${accountId}`).then(
            response => {
                return response.data
            }
        )
    },
    removeSuperadminCompany(companyId, accountId){
        setLanguage();
        getAuth();
        return superadmin.delete(`/companies/${companyId}/accounts/${accountId}`).then(
            response => {
                return response.data
            }
        )
    },


    editCompanyAccount(accountId, fio, email, phone, locale, isDemo){
        setLanguage();
        getAuth();
        let params = {}
        params.accountId = accountId;
        params.fio = fio;
        if(locale) params.locale = locale
        if (phone) params.phone = phone;
        if (email) params.email = email;
        return superadmin.patch(`/accounts/${accountId}`, {...params}).then(
            response => {
                    //return superadmin.get(`/companies/${companyId}/accounts`).then(
                        //response => {
                            return response.data;
                       // }
                    //)
                }
            )
        
    },
    editAccount(accountId, fio, email, phone, locale, isDemo){
        setLanguage();
        getAuth();
        let params = {};
        params.accountId = accountId;
        params.fio = fio;
        if (locale) params.locale = locale
        if (phone) params.phone = phone;
        if (email) params.email = email;
        return superadmin.patch(`/accounts/${accountId}`, {...params}).then(
            response => {
                return response.data;
            }
        )
    },
    attachAccountToCompany(accountId, companyId){
        setLanguage();
        getAuth();
        return superadmin.put(`companies/${companyId}/accounts/${accountId}`).then(
            response => {
                return response.data;
            }
        )
    },
    detachAccountFromCompany(accountId, companyId){
        setLanguage();
        getAuth();
        return superadmin.delete(`companies/${companyId}/accounts/${accountId}`).then(
            response => {
                return response.data;
            }
        )
    },


    activateCompanyAccount(accountId){
        setLanguage();
        getAuth();
        return superadmin.post(`/accounts/${accountId}/activate`).then( response => {
            return response.data
        })
    },
    blockCompanyAccount(accountId){
        setLanguage();
        getAuth();
        return superadmin.post(`/accounts/${accountId}/block`).then( response => {
            return response.data
        })
    },
    getCompanyAccounts(companyId, pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return superadmin.get(`/companies/${companyId}/accounts?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    getAccounts(pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return superadmin.get(`/accounts?query=ALL&sortField=CREATED_AT&sortDirection=DESC&pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    removeAccountFromCompany(accountId){
        setLanguage();
        getAuth();
        return superadmin.delete(`accounts/${accountId}`).then(
            response => {
                return response.data
            }
        )
    },
    getSuperadminTopics(){
        setLanguage();
        getAuth();
        return superadmin.get(`topics?query=ALL&pageIndex=0&pageSize=100`).then(
            response => {
                return response.data
            }
        )
    },
    getCompany(companyId){
        setLanguage();
        getAuth();
        return superadmin.get(`companies/${companyId}`).then(
            response => {
                return response.data
            }
        )
    },
    getSuperadminCompanyTopics(companyId){
        setLanguage();
        getAuth();
        return superadmin.get(`companies/${companyId}/topics?query=ACTIVE&pageIndex=0&pageSize=100`).then(
            response => {
                return response.data
            }
        )
    },
    getAccountsOfTopic(companyId, topicId){
        setLanguage();
        getAuth();
        return superadmin.get(`companies/${companyId}/topics/${topicId}/accounts?pageIndex=0&pageSize=100`).then(
            response => {
                return response.data
            }
        )
    },
    getTopicsOfAnAccounts(companyId, accountId){
        setLanguage();
        getAuth();
        return superadmin.get(`companies/${companyId}/accounts/${accountId}/topics?pageIndex=0&pageSize=100`).then(
            response => {
                return response.data
            }
        )
    },
    detachTopicFromCompany(companyId){
        setLanguage();
        getAuth();
        return superadmin.delete(`topics/${companyId}`).then(
            response => {
                return response.data
            }
        )
    },
    visibleAccountsToTopic(companyId, accountIds, topicId){
        setLanguage();
        getAuth();
        return superadmin.put(`companies/${companyId}/topics/${topicId}/accounts`, [...accountIds]).then(
            response => {
                return response
            }
        )
    },
    visibleTopicToAccount(companyId, accountId, topicId){
        setLanguage();
        getAuth();
        return superadmin.post(`companies/${companyId}/accounts/${accountId}/topics/${topicId}`).then(
            response => {
                return response
            }
        )
    },
    invisibleTopicToAccount(companyId, accountId, topicId){
        setLanguage();
        getAuth();
        return superadmin.delete(`companies/${companyId}/accounts/${accountId}/topics/${topicId}`).then(
            response => {
                return response
            }
        )
    },
    createAccountInCompany(companyId, login, fio, email, phone, password, locale){
        let params = {}
        params.login = login;
        params.fio = fio;
        params.password = password;
        if(locale) params.locale = locale;
        if(phone) params.phone = phone;
        if(email) params.email = email;
        setLanguage();
        getAuth();
        return superadmin.post(`companies/${companyId}/accounts`,{...params},).then(
            response => {
                return response.data
            }
        )
    },
    getAllAccounts(query = 'ALL', pageIndex=0, pageSize=100){
        setLanguage();
        getAuth();
        return superadmin.get(`accounts?query=${query}&sortField=CREATED_AT&sortDirection=DESC&pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    getAccount(accountId){
        setLanguage();
        getAuth();
        return superadmin.get(`accounts/${accountId}`).then(
            response => {
                return response.data
            }
        )
    },
    getAccountAuthorities(accountId){
        setLanguage();
        getAuth();
        return superadmin.get(`accounts/${accountId}/authorities`).then(
            response => {
                return response.data
            }
        )
    },
    addAuthoritiesToAccount(accountId, authorities){
        setLanguage();
        getAuth();
        return superadmin.put(`accounts/${accountId}/authorities`, [...authorities]).then(
            response => {
                return response.data
            }
        )
    },
    deleteAuthoritiesFromAccount(accountId, authority){
        setLanguage();
        getAuth();
        return superadmin.delete(`accounts/${accountId}/authorities/${authority}`).then(
            response => {
                return response.data
            }
        )
    },
    addRoleToAccount(accountId, role){
        setLanguage();
        getAuth();
        return superadmin.put(`accounts/${accountId}/role`, role, {headers: { 'Content-Type': 'application/json'},data: {}}).then(
            response => {
                response.data
            }
        )
    },
    deleteAccount(accountId){
        setLanguage();
        getAuth();
        return superadmin.delete(`accounts/${accountId}`).then(
            response => {
                return response.data
            }
        )
    },
    getAvailableRoles(){
        setLanguage();
        getAuth();
        return superadmin.get(`accounts/roles`).then(
            response => {
                return response.data
            }
        )
    },
    getMarkers(pageIndex=0, pageSize=20, query=null){
        setLanguage();
        getAuth();
        let q = query ? `&query=${query}` : null
        return superadmin.get(`marker/accounts?pageIndex=${pageIndex}&pageSize=${pageSize}${q ? q : ''}`).then(
            response => {
                return response.data
            }
        )
    },
    makeSuperadminTopicVisibleToAccount(accountId, topicIds, companyId){
        setLanguage();
        getAuth();
        return superadmin.put(`companies/${companyId}/accounts/${accountId}/topics/visibilities`, [...topicIds]).then(
            response => {
                return response.data
            }
        )
    },
    makeTopicVisibleToMarkers(accountIds, topicId){
        setLanguage();
        getAuth();
        return superadmin.put(`marker/topics/${topicId}/accounts`, [...accountIds]).then(
            response => {
                return response.data
            }
        )
    },
    makeMarkerTopicVisibleToAccount(accountId, topicIds){
        setLanguage();
        getAuth();
        return superadmin.put(`marker/accounts/${accountId}/topics`, [...topicIds]).then(
            response => {
                return response.data
            }
        )
    },
    makeTopicVisibleToAccount(accountId, topicId, companyId){
        setLanguage();
        getAuth();
        return superadmin.post(`marker/accounts/${accountId}/topics/${topicId}`, {companyId}).then(
            response => {
                return response.data
            }
        )
    },
    getMarkerTopics(accountId, pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return superadmin.get(`marker/accounts/${accountId}/topics?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    getTopicMarkers(topicId, pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return superadmin.get(`marker/topics/${topicId}?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    detachMarkerTopic(accountId, topicId){
        setLanguage();
        getAuth();
        return superadmin.delete(`marker/accounts/${accountId}/topics/${topicId}`).then(
            response => {
                return response.data
            }
        )
    },
    getAllTopics(pageIndex, pageSize, status){
        setLanguage();
        getAuth();
        let q = status ? `&query=ACTIVE` : null
        return superadmin.get(`topics?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    getAllTopicsSearch(pageIndex, pageSize, status, search){
        setLanguage();
        getAuth();
        const data = {search: search};
        const options = {
            headers: {"content-type": "text/plain"}
        }
        let q = status ? `&query=${status}` : ''
        return superadmin.post(`topics?pageIndex=${pageIndex}&pageSize=${pageSize}${q}`, data.search, options).then(
            response => {
                return response.data
            }
        )
    },
}