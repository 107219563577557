import Analytic from '../analytic/analytic';
import { getFormatedDate, getFormatDate } from 'service/format-date';
import moment from 'moment';

const ExtendedAnalyticPages = (props, setRequestSended, selectedUUID) => {

    const getFormatedDate = (date) => {
        if(props.grouping === 'HOURLY')
            return getFormatDate(date, 'HH:mm')
        else
            return getFormatDate(date, 'DD-MM-YY')
    }

    const getTagName = (tagId) => {
        
        let tag = props.tags.findIndex(f => f.uuid === tagId)
        
        let ret = tag !== -1 && props.tags[tag] ? props.tags[tag].name : tagId;
        return ret;
    }
    if(!props.first.topicIDs.length){
        return
    }
    if(props.first && props.first.pageType === 'AuthorsLocations'){
        props.first.locationsName = props.locationsName

    }
    /*if(props.first.topicIDs === undefined || props.second.topicIDs === undefined){
        getPageData(menuId);   
    }
    else{*/
        setRequestSended();
            return <Analytic getValue={props.getValue} rangeData={props.first} data={props.second} third={props.third} formatDateTime={getFormatedDate} isFetching={props.isFetching}/>
    //}
}

export default ExtendedAnalyticPages;