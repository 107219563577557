import TopicsSelectMenuController from "./topics-select-menu-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { getValue } from 'store/languages';
import { setSelectedTopicsItems, setSelectedTopicsItem } from "store/topics-select"
import { getTopics, getSubtopicsFromState } from "selectors/theme-selector";

let mapStateToProps = (state) => {
    return {
        selectedTopicsItems: state.TopicSelectItems.selectedTopicsItems,
        topicUUId: state.TopicSelectItems.topicUUId,
        topics: getTopics(state),
    }
}

export default compose(
    connect(mapStateToProps, {getValue, setSelectedTopicsItems, setSelectedTopicsItem}),
    withAuthRedirect
)(TopicsSelectMenuController)