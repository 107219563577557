import React, {useState, useEffect} from "react";
import TableItem from "items/table/table";
import DropdownController from "items/dropdown/dropdown-controller";

import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import s from "./topics.module.scss";

const Topics = (props) => {

    const getSelectedSort = (sort) => {
        let sorting = props.tableHeaders.findIndex((f, i) => {return f.title === sort})
        return sorting !== -1 ? props.tableHeaders[sorting].items[0].short_name : sort
    }

    const setSort = (type, sort) => {
        switch(type){
            case 'ALPHABETIC':
                setAlphabetic(sort);
                setCreated('CREATED_AT DESC');
                setUpdated('UPDATED_AT DESC');
                break;
            case 'CREATED_AT':
                setCreated(sort);
                setAlphabetic('ALPHABETIC DESC')
                setUpdated('UPDATED_AT DESC');
                break;
            case 'UPDATED_AT':
                setUpdated(sort);
                setCreated('CREATED_AT DESC');
                setAlphabetic('ALPHABETIC DESC');
                break;  
        }
    }

    const sortTopic = (type, i) => {
        setSort(type, i);
        selectTableITem(0)
        let items = i.split(" ");
        props.getTopics(items[0], items[1]);
    }

    const getSort = (sort) => {
        switch(sort){
            case 'ALPHABETIC':
                return alphabetic
            case 'CREATED_AT':
                return created
            case 'UPDATED_AT':
                return updated 
        }
    }

    const getHeaderComponents = (headers, selected) => {
        let retHeaders = props.tableHeaders && props.tableHeaders.map((item, i) => {
            let t = item
            if(item.name === 'dropdown'){
                let titles = item.items.map((title)=>{return {title: props.getValue(title.title), short_name: title.short_name, name: title.name}});
                return <DropdownController
                            items={titles}
                            name={item.name}
                            callback={sortTopic}
                            selected={getSort(item.title)}
                            icon={null}
                            type={item.title}
                            short_name={'short_names'}
                        /> 
            }
            else{
                return props.getValue(item)
            }
        })
            retHeaders.unshift(props.getValue('#'))
        return retHeaders
    }

    const getSubtopicHeader = () => {
        return props.subtopicTableHeader.map(k => props.getValue(k))
    }

    const [alphabetic, setAlphabetic] = useState(getSelectedSort('ALPHABETIC'))
    const [created, setCreated] = useState(getSelectedSort('CREATED_AT'))
    const [updated, setUpdated] = useState(getSelectedSort('UPDATED_AT'))
    let [tableHeaders, setTableHeaders] = useState(getHeaderComponents(props.tableHeaders));
    let [tableValues, setTableValues] = useState(props.tableValues);
    let [selectedItem, setSelectedItem] = useState(0);
    let [selectedSubItem, setSelectedSubItem] = useState(0);

    useEffect(() => {
        /*setAlphabetic(getSelectedSort('ALPHABETIC'));
        setCreated(getSelectedSort('CREATED_AT'));
        setUpdated(getSelectedSort('UPDATED_AT'));*/
        setTableValues(props.tableValues);
        setTableHeaders(getHeaderComponents(props.tableHeaders))
    }, [props.tableValues, props.tableHeaders, props.selectedLanguage])

    const createTopic = (type) => {
        props.clearTopicItem(null);
        let topicId = null;
        let topicUUID = null;
        if(type === 'create' || type === 'createSubtopic'){
            if(type === 'createSubtopic' && ((!props.topics || props.topics === undefined) || !props.topics[selectedItem])){
                return
            }
            type === 'createSubtopic' ? props.createTopic(true, type, selectedItem, props.topics[selectedItem].id, true) : props.createTopic(true, type, null, null);
        }
        else{
            topicId = (props.topics && selectedItem) ? props.topics[selectedItem].id : props.topics[0].id
            topicUUID = (props.topics && selectedItem) ? props.topics[selectedItem].uuid : props.topics[0].uuid
        
        }
       
       
    }

    const addItemButton = <StandartButton title={props.getValue('CREATE_TOPIC')} icon={PlusIcon} fill='white' callback={createTopic.bind(this, "create")}/>
    const addSubItemButton = <StandartButton title={props.getValue('CREATE_SUBTOPIC')} icon={PlusIcon} fill='white' callback={createTopic.bind(this, "createSubtopic")}/>

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            setSelectedItem(item, props.topics[item]);
            props.topics && props.topics[item] ? props.getSubtopics(props.topics[item].id, item) : null
        }
    }

    const selectTableSubItem = (item) => {
        setSelectedSubItem(item)
    }

    const getSelectedTopicName = (item) => {
        return props.topics && props.topics[item] ? props.topics[item].name : '';
    }

    return(
        
        <div className={s.Topics}>
            {(tableHeaders && tableHeaders.length) && <TableItem 
                                                            //controlButtons={<TopicControlButtons k={0} callback={clickControlButton}/>} 
                                                            data={[tableHeaders, tableValues]} 
                                                            titleText={props.getValue('TOPICS_LIST')}
                                                            addItemButton={addItemButton}
                                                            selectItem={selectTableITem}
                                                            selectedItem={selectedItem}
                                                            getValue={props.getValue}
                                                            controlButtons={props.controlButtons}
                                                            controllerButtons={props.controllerButtons}
                                                            hiddenColumns={props.hiddenColumns}
                                                            height={333}
                                                        />}
            { <TableItem 
                data={[getSubtopicHeader(), props.tableSubtopics]} 
                titleText={props.getValue('SUBTOPICS_LIST')+ ' '+getSelectedTopicName(selectedItem)}
                addItemButton={addSubItemButton}
                controlButtons={props.controlButtons}
                controllerButtons={props.subtopicControllerButtons}
                selectItem={selectTableSubItem}
                selectedItem={selectedSubItem}
                getValue={props.getValue}
                height={333}
            />}
            
            
        </div>
    )
}

export default Topics;