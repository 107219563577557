const Locations = {	
    "424313582": "Қытай",
    "424313675": "Әзірбайжан",
    "1700777271": "Қырғызстан",
    "508058549": "Павлодар облысы",
    "508059331": "Шығыс Қазақстан облысы",
    "784756509": "Солтүстік Қазақстан облысы",
    "959501189": "Қарағанды облысы",
    "959502983": "Қостанай облысы",
    "959509383": "Ақмола облысы",
    "974358575": "Алматы облысы",
    "974358688": "Жамбыл облысы",
    "974359207": "Қызылорда облысы",
    "975900646": "Ақтөбе облысы",
    "975902304": "Батыс Қазақстан облысы",
    "975903612": "Атырау облысы",
    "975906931": "Маңғыстау облысы",
    "974358986": "Түркістан облысы",
    "27522202": "Талғар",
    "27524816": "Отбасылар",
    "98022062": "Атырау",
    "214887132": "Түркістан",
    "240470924": "Тараз",
    "240505390": "Шу",
    "240711925": "Жаңатас",
    "240865457": "Қарағанды",
    "240872918": "Павлодар",
    "241035125": "Ақтөбе",
    "240993564": "Өскемен",
    "241455373": "Теміртау",
    "241492821": "Қызылорда",
    "241542056": "Көкшетау",
    "241549456": "Кен",
    "241679356": "Талдықорған",
    "241772277": "Кентау",
    "241781833": "Арыс",
    "242415806": "Сарыөзек",
    "242441466": "Қосқұдық",
    "242455350": "Жоламан",
    "242465214": "Қапшағай",
    "242940822": "Аягөз",
    "243119367": "Ақтоғай",
    "243178767": "Лепси",
    "243182293": "Матай",
    "243235602": "Үшарал",
    "243324293": "Сарканд",
    "243325633": "Жансүгіров",
    "243464146": "Маканчи",
    "243465319": "Үржар",
    "243472864": "Шингоза",
    "243508453": "Достық",
    "243657575": "Жаркент",
    "243659737": "Көктал",
    "243692801": "Шарын",
    "243693248": "Чунджа",
    "243697387": "Подгорное",
    "243708956": "Елшілердің істері",
    "243723775": "Кеген",
    "243725702": "Шелек",
    "243729810": "Жалаңаш",
    "243735997": "Сарыжаз",
    "243737203": "Текес",
    "243741613": "Нарынқол",
    "243743625": "Есік",
    "243748132": "Сумбе",
    "243748155": "Сарыбастау",
    "243798668": "Сати",
    "243850399": "Қарашоқы",
    "243991314": "Ақшы",
    "243995789": "Копа",
    "243997970": "Шамалған",
    "243998902": "Ұзынағаш",
    "244005521": "Бурубайтал станциясы",
    "244010886": "Ақсуек",
    "244017974": "Балқаш",
    "244109105": "Қабанбай",
    "244114769": "Бақты",
    "244118661": "Ақжал",
    "244128763": "Әтештер",
    "244135961": "Майқапшағай",
    "244147213": "Риддер",
    "244198209": "Зырян",
    "244529371": "Ленгер",
    "244532522": "Сарыағаш",
    "247318618": "Жетісай",
    "247318653": "Атакент",
    "247319161": "Шардара",
    "248057498": "Лисаковск",
    "253063867": "Жаңаөзен",
    "253716507": "Құлсары",
    "253725107": "Макат",
    "253729284": "Махамбет",
    "253983700": "Бейнеу",
    "254129240": "Мукур",
    "254135336": "Сағыз",
    "254136309": "Миялы",
    "254139962": "Қарауылкелді",
    "254143253": "Шұбарқұдық",
    "254313000": "Агадыр",
    "254313822": "Ақчатау",
    "254314127": "Қайрақты ауылы",
    "254316151": "Нүктелер",
    "271077515": "Әйтеке би",
    "271080647": "Арал",
    "271085442": "Қазалы",
    "271092323": "Байқоңыр",
    "271120352": "Қорғалжын",
    "271125900": "Басыңыз",
    "271183751": "Хромтау",
    "271203690": "Жезқазған",
    "271292059": "Қаражал",
    "271293913": "Атасу",
    "271296615": "Сәкен Сейфуллин қонысы",
    "271301949": "Қарабас б.",
    "271309842": "Қарқаралы",
    "271311323": "Приозерск",
    "271311686": "Сарай",
    "271312178": "Сәтбаев",
    "271395447": "Осакаровка",
    "271401068": "Ақжарық с.",
    "271402224": "Ақтоғай",
    "271402834": "Нұркен",
    "271413561": "Татан",
    "271416049": "Киевка с.",
    "297359900": "Жамбил",
    "297596160": "Құлан / Құлан",
    "299211831": "Ивановка с.",
    "299923721": "Абай",
    "299924758": "Шешенқара с. (Пролетария б.)",
    "299974347": "Ақтас",
    "299978968": "Ақтау",
    "299982128": "Арқалық",
    "301058599": "Гүлшад",
    "301060581": "Оңтүстік б.",
    "301373626": "Қоңырат б.",
    "301374302": "Кушоки б.",
    "301374694": "Қызылжар б.",
    "301715316": "Шахан б.",
    "301716913": "Шахтинск",
    "301717251": "Жәйрем ауылы",
    "301733193": "Джамбул б.",
    "301734878": "Шашубай с. (Озерное)",
    "301760956": "Ғабиден Мұстафин қонысы (Токаревка)",
    "301761659": "Топар",
    "303821397": "Қарабалық",
    "303894619": "Октябрьское",
    "303908697": "Есіл",
    "303910698": "Сурган",
    "303911228": "Джекс",
    "303913171": "Атбасар",
    "377600470": "Курчатов",
    "410079696": "Державинск",
    "424311521": "Қазақстан",
    "430704251": "Бурлин",
    "435393175": "Ақсай",
    "448814768": "Қостанай",
    "451519656": "Темір бөлігі",
    "451702142": "Қарғалы",
    "451702144": "Үшқоңыр",
    "471558381": "Казгородок",
    "471753777": "Астраханка",
    "471941831": "Алға",
    "471953135": "Чингирлау",
    "472267256": "Ганюшкино",
    "472267642": "Индер",
    "472268433": "Аққыстау",
    "473066535": "Жаңақала",
    "473070642": "Жаңақала с.",
    "473080162": "Жанғала",
    "473098790": "Тускала",
    "473112751": "Переметное",
    "473115328": "Федоровка",
    "473372461": "Жәнібек",
    "473399115": "Сайхин",
    "476263135": "Шетпе",
    "476293790": "Сай-Өтес",
    "476310282": "Ақжігіт",
    "481045720": "Эртис",
    "491923263": "Көкпек",
    "525190320": "Денисовка",
    "525439568": "Құмқұдық",
    "527856584": "Азгир",
    "533754334": "Баршаталар",
    "534085130": "Саяқ ауылы",
    "534085154": "Даратаб",
    "534085155": "Жорға",
    "534085162": "Айыртас",
    "534085168": "Томар С.",
    "534087372": "Джогарги Егинсу",
    "534087495": "Қазымбет",
    "534087953": "Келдемурат",
    "534088599": "Берлік",
    "534097147": "Бесқарағай",
    "537561303": "Екібастұз",
    "539629498": "Аяцкое",
    "539629508": "Фрунзенское",
    "546773876": "Ботакара б.",
    "546786098": "Алгбас",
    "547809505": "Әтештер",
    "547809559": "Байқадам бірге.",
    "547809608": "Бастау с.",
    "547811784": "Корнеевка",
    "554059814": "Шідерті б.",
    "704397626": "Жітіқара",
    "729610410": "Ақтау",
    "761956738": "Батпақ с. (Крониловское)",
    "761956982": "Ошаганды с. (Қызыл құйрық)",
    "761957222": "Баймырза с. (Бағынышты)",
    "765064981": "Ақсу-Аюлы",
    "832355131": "Дубовка",
    "896978591": "Айтабай Назарбеков атындағы",
    "914021120": "Качар",
    "934340038": "Үштөбе",
    "983585435": "Сартөбе с.",
    "986043692": "Долинка б.",
    "1106418007": "Құрылыс б.",
    "1128220486": "Львовка",
    "1128220490": "Тохтарово",
    "1141193378": "Приреченко",
    "1238308608": "Жастар",
    "1253518639": "Жетпіс",
    "1253572824": "Курминское",
    "1253573436": "Жұмабек",
    "1282428111": "Тоғызқұдық с.",
    "1282430541": "Тақта",
    "1284932620": "Ростовка с.",
    "1298132253": "Мамлютка",
    "1314870477": "Майшғыр",
    "1314870478": "Құланөтпес",
    "1314889924": "Оразақ",
    "1314909997": "Баршино",
    "1314942756": "Каскатау",
    "1314942758": "Найман",
    "1315945296": "Каменка",
    "1339442968": "Новонежинка",
    "1362711625": "Челгаши",
    "1362752104": "Ивановка",
    "1369849641": "Алға",
    "1375916594": "Геологиялық",
    "1375916598": "Қаражар с.",
    "1375949711": "Асыл с.",
    "1375960032": "Молодецкое с.",
    "1375975105": "Қызылжар",
    "1375990240": "Березняки с.",
    "1375999660": "Тасауыл ауылы",
    "1376006294": "Тегісжол с.",
    "1376576251": "Жаңа Кронштадт",
    "1379357053": "Келісім",
    "1384387947": "Қарамұрын",
    "1409446799": "Үрпек",
    "1410760848": "Белбасар",
    "1430694778": "Покровка",
    "1457457600": "Петропавл",
    "1466716533": "Шымкент",
    "1492994952": "Абай",
    "1496101001": "Бақырлы",
    "1496101311": "Хуантөбе",
    "1496101371": "Жиңішке",
    "1496101597": "Киров",
    "1496101666": "Коммуна / Коммуна",
    "1496102100": "Тайқоңыр",
    "1496102111": "Талаптар",
    "1496102166": "Ораз ата",
    "1496102346": "Чулаккурган",
    "1496102393": "Қарақұр",
    "1515019354": "Шолдер",
    "1655751674": "Қала маңы",
    "1683604838": "Жасыл",
    "1701097240": "Котяевка",
    "1760005822": "Екпенді",
    "1764497137": "Созақ",
    "1764497143": "Шара / Шара",
    "1775849171": "Когам",
    "1786918916": "Курти",
    "1832841848": "Орал",
    "1913828772": "Бескөл",
    "1915016813": "Боранқұл",
    "1923077056": "Жаңаауыл",
    "1923103026": "Сафоновка",
    "1923117377": "Бай",
    "1952946568": "Төле би",
    "1983402593": "Ауыстыру",
    "1983412808": "Жасталап",
    "1983426001": "Рембаза",
    "2005354523": "Зәкір",
    "2026935419": "Архангельское",
    "2026935421": "Новокаменка",
    "2026935424": "Петерфельд",
    "2026935427": "Подгорное",
    "2026964332": "Боголюбово",
    "2026973156": "Новоникольское",
    "2042658769": "Темірлановка",
    "2050311604": "Жамбыл",
    "2050311607": "Бірлік",
    "2050321939": "Хантау",
    "2053541157": "Қаратоған",
    "2055017561": "Кокси",
    "2055064645": "Джалгизагат",
    "2132913855": "Вознесенка",
    "2132913859": "Сенімділік",
    "2219596229": "Николаевка",
    "2219599703": "Петровка",
    "2219617644": "Чириковка",
    "2304072858": "Бәйтерек",
    "2321177089": "Ұланбел",
    "2363178902": "ЕБЖ",
    "2606780046": "Үшбұлақ",
    "2612028998": "Жанай",
    "26544289": "Алматы",
    "2567720890": "Ақкөл",
    "2583746142": "Ақтөбе",
    "27548009": "Нұр-Сұлтан",
    "424314830": "Ресей",
    "503077246": "Иркутск облысы",
    "505006802": "Рязань облысы",
    "516618494": "Калининград облысы",
    "533719181": "Самара облысы",
    "535136806": "Мурманск облысы",
    "535182674": "Свердлов облысы",
    "573226686": "Татарстан",
    "573226687": "Башқұртстан",
    "573226688": "Удмуртия",
    "869508901": "Мордовия",
    "933435715": "Киров облысы",
    "26903504": "Петрозаводск",
    "26951330": "Вологда",
    "27017589": "Ұлы Новгород",
    "27025689": "Псков",
    "27048976": "Калининград",
    "27072633": "Смоленск",
    "27090043": "Курск",
    "27096008": "Бүркіт",
    "27119039": "Белгород",
    "27490597": "Санкт Петербург",
    "27503892": "Екатеринбург",
    "27503945": "Самара",
    "27503946": "Омбы",
    "27504067": "Қазан",
    "27504156": "Челябинск",
    "27504327": "Уфа",
    "27504363": "Волгоград",
    "27504531": "Пермь",
    "27504590": "Саратов",
    "27505044": "Воронеж",
    "27505046": "Толятти",
    "27505129": "Краснодар",
    "27505266": "Ярославль",
    "27505285": "Барнаул",
    "27505335": "Иркутск",
    "27505477": "Новокузнецк",
    "27505509": "Орынбор",
    "27505582": "Рязань",
    "27505663": "Пенза",
    "27505666": "Тюмень",
    "27505889": "Нижний Новгород",
    "29804102": "Анапа",
    "29980667": "Таганрог",
    "34043670": "Сочи",
    "34389350": "Тула",
    "34395338": "Назран",
    "34506055": "Төменгі Тагил",
    "2452912060": "Мәскеу облысы",
    "36714827": "Ставрополь",
    "76611479": "Липецк",
    "79736088": "Воткинск",
    "87705691": "Новороссийск",
    "90495941": "Обнинск",
    "93711720": "Нальчик",
    "101480194": "Энгельс",
    "143561186": "Долгопрудный",
    "148899210": "Рыбинск",
    "148901123": "Кострома",
    "148901125": "Иваново",
    "160258541": "Пятигорск",
    "178951928": "Красноярск",
    "179374935": "Қорған",
    "188558082": "Череповец",
    "191638748": "Черкесск",
    "191640862": "Йошкар-Ола",
    "191644503": "Махачкала",
    "191648647": "Элиста",
    "191651581": "Саранск",
    "191652335": "Якутск",
    "191655065": "Қызыл",
    "191657470": "Абакан",
    "191697061": "Астрахань",
    "191723030": "Южно-Сахалинск",
    "191736223": "Ханты-Мансийск",
    "191738860": "Биробиджан",
    "191749240": "Набережные Челны",
    "191752285": "Волжский",
    "191754078": "Сургут",
    "191754954": "Амурдағы Комсомольск",
    "191756795": "Стерлитамак",
    "191758663": "Братск",
    "191773560": "Ангарск",
    "191774769": "Нижневартовск",
    "191779212": "Прокопьевск",
    "191780610": "Норильск",
    "191824980": "Армавир",
    "191843200": "Златоуст",
    "191850710": "Табыңыз",
    "191852866": "Березники",
    "191855308": "Рубцовск",
    "191863710": "Миасс",
    "191864581": "Уссурийск",
    "191866198": "Ковров",
    "191870333": "Копейск",
    "191871001": "Первоуральск",
    "191871925": "Одинцово",
    "191872996": "Невинномысск",
    "191875199": "Кисловодск",
    "191879312": "Новочебоксарск",
    "191880180": "Серпухов",
    "191880979": "Хасавюрт",
    "191885615": "Орехово-Зуево",
    "191890746": "Ачинск",
    "191892583": "Сергиев Посад",
    "191894808": "Нефтеюганск",
    "191897505": "Ноябрск",
    "191903740": "Дербент",
    "191908717": "Киселевск",
    "191909355": "Междуреченск",
    "191912193": "Канск",
    "191914736": "Тобольск",
    "205216827": "Ленинск-Кузнецкий",
    "251645471": "Чита",
    "253041038": "Зеленоград",
    "255594586": "Сарапул",
    "256361843": "Раменское",
    "269342417": "Мурманск",
    "276628719": "Архангельск",
    "277060995": "Хабаровск",
    "280304251": "Бердск",
    "285736628": "Соликамск",
    "288126203": "Серов",
    "360001149": "Мытищи",
    "390614421": "Кемерово",
    "683814442": "Эссентуки",
    "711715624": "Ульяновск",
    "740732715": "Чебоксары",
    "1078579692": "Арсеньев",
    "1244147408": "Магнитогорск",
    "1457469393": "Улан-Удэ",
    "1522654732": "Грозный",
    "1679639726": "Ижевск",
    "1686293227": "Мәскеу",
    "1711559378": "Жаңа Уренгой",
    "2095029787": "Воскресенск",
    "2893369031": "Магадан",
    "26878551": "Кандалакша",
    "26898768": "Калевала",
    "26898769": "Кировск",
    "26898825": "Сегежа",
    "26898826": "Зеленоборский",
    "26952234": "Каргопол",
    "27023177": "Шабындықтар",
    "27464354": "Борисоглебский",
    "27464486": "Ростов",
    "29866526": "Йиск",
    "34390960": "Высоцк",
    "34395335": "Беслан",
    "34395341": "Magas",
    "34398261": "Красная Поляна",
    "60078445": "Рославль",
    "60079145": "Тосно",
    "60237699": "Зеленоградск",
    "60238036": "Мамоново",
    "60238387": "Советск",
    "60238585": "Полесск",
    "60405981": "Торжок",
    "76752092": "Порхов",
    "82739207": "Чайковский",
    "90045540": "Медин",
    "92383389": "Углич",
    "92774502": "Мышкин",
    "93701882": "Бақсан",
    "94091844": "Селижарово",
    "94102909": "Осташков",
    "130201149": "Новозыбков",
    "153568874": "Элабуга",
    "174842795": "Есіл",
    "176957217": "Салехард",
    "176960743": "Лабитнанги",
    "178108820": "Төменгі Тавда",
    "178926370": "Тайга",
    "186985543": "Кривошеино",
    "187010245": "Колпашево",
    "191636706": "Горно-Алтайск",
    "191736988": "Анадыр",
    "191907831": "Новотроицк",
    "191909841": "Ухта",
    "204815902": "Дудинка",
    "204816649": "Тур",
    "205159518": "Юрга",
    "205430993": "Яя",
    "205437775": "Мариинск",
    "206131650": "Тавда",
    "206809742": "Каргасок",
    "210111403": "Стрежевой",
    "210305929": "Иланский",
    "210323639": "Тайшет",
    "216890905": "Осинники",
    "217317194": "Кингисепп",
    "217323851": "Ивангород",
    "221109841": "Калтан",
    "221129318": "Мундибаш",
    "221140463": "Теміртау",
    "221143207": "Қаз",
    "221173014": "Таштагөл",
    "221181091": "Шерегеш",
    "221214205": "Мыски",
    "243083247": "Нижнеудинск",
    "243329007": "Тулун",
    "243347526": "Заиграево",
    "243349531": "Петровск-Забайкальский",
    "243355185": "Хилок",
    "243527503": "Карымское",
    "243634984": "Шилка",
    "243695003": "Чернышевск",
    "243836016": "Шадринск",
    "243991807": "Боровский",
    "243991814": "Ялуторовск",
    "243991822": "Заводуковск",
    "243993519": "Абацкое",
    "243995008": "Омутинское",
    "244078631": "Сретенск",
    "244529460": "Тегулдет",
    "246665853": "Усть-Карск",
    "246669195": "Боготол",
    "246678899": "Кировск",
    "246830274": "Арал",
    "251071107": "Аджидель",
    "251072970": "Дюртюли",
    "251532339": "Юрюзан",
    "253258948": "Мижхирия",
    "253737355": "Ахтубинск",
    "253936017": "Палласовка",
    "254587738": "Усть-Илимск",
    "254862887": "Тотма",
    "256361865": "Руза",
    "256827434": "Шаря",
    "256827443": "Котельнич",
    "256827452": "Омутнинск",
    "264358670": "Яранск",
    "264365304": "Советск",
    "270529337": "Железноводск",
    "270531692": "Минералды су",
    "270875270": "Липаха",
    "271283522": "Таруса",
    "271954653": "Чебаркүл",
    "272601866": "Кунгур",
    "272845466": "Никель",
    "274749672": "Суксун",
    "275772536": "Шелехов",
    "275804020": "Сосновоборск",
    "275804138": "Бородино",
    "275804147": "Уяр",
    "275805295": "Лесосібір",
    "275805315": "Енисейск",
    "275806099": "Назарово",
    "275807520": "Дивногорск",
    "275808256": "Минусинск",
    "275808498": "Саяногорск",
    "276092539": "Ужур",
    "276092580": "Алзамай",
    "276092773": "Саянск",
    "276092774": "Қыс",
    "276092781": "Залары",
    "276092950": "Черемхово",
    "276092975": "Свирск",
    "276092977": "Усолье-Сибирское",
    "276113713": "Забайкальск",
    "276627958": "Беломорск",
    "276646949": "Данилов",
    "276994866": "Бикин",
    "277057968": "Дальнереченск",
    "277060596": "Вяземский",
    "277118590": "Тегін",
    "277139530": "Шимановск",
    "277573669": "Ерофей Павлович",
    "277576138": "Амазар",
    "277576357": "Могоха",
    "277576558": "Ключевский",
    "277750915": "Белово",
    "278023046": "Татар",
    "278211683": "Югорск",
    "278216664": "Кеңестік",
    "278298564": "Нерчинск",
    "278304667": "Букачача",
    "278380012": "Зея",
    "278991732": "Ордынское",
    "278992054": "Стоун-Оби",
    "279240513": "Красногорск",
    "279978754": "Усть-Кут",
    "279980750": "Северобайкальск",
    "279982847": "Бабушкин",
    "279983984": "Гусиноозёрск",
    "279985581": "Кяхта",
    "279985631": "Закаменск",
    "279987689": "Тында",
    "279997610": "Таксимо",
    "280067854": "Чулман",
    "280078404": "Томмот",
    "280109123": "Амга",
    "280182070": "Сусуман",
    "280183184": "Усть-Нера",
    "280352878": "Соққылар",
    "280404821": "Усть-Кокса",
    "280513195": "Шебалино",
    "280513628": "Майма",
    "280515128": "Кош-Агач",
    "280521185": "Усть-Кан",
    "280548716": "Белокуриха",
    "280550570": "Турочак",
    "280822642": "Борисоглебск",
    "281293974": "Химиялық",
    "281427282": "Сузун",
    "281428908": "Новоалтайск",
    "281431381": "Талменка",
    "281431605": "Искитим",
    "281431826": "Барабинск",
    "281433268": "Каргат",
    "281433500": "Чулым",
    "282596576": "Нижний Бестях",
    "282635858": "Демянск",
    "282914618": "Карасук",
    "282914871": "Славгород",
    "283588621": "Сковородино",
    "285340786": "Боград",
    "285360076": "Шира",
    "285365947": "Копьево",
    "285734826": "Красновишерск",
    "285745298": "Александровск",
    "285746241": "Kizel",
    "285889059": "Черногорск",
    "286084866": "Ақ-Довурак",
    "286085202": "Чадан",
    "286085444": "Шагонар",
    "286085489": "Тұран",
    "286130437": "Тора-Хем",
    "286200781": "Сарық-қыркүйек",
    "286203959": "Қызыл-Мажалық",
    "286214595": "Абаза",
    "286523928": "Троицк",
    "286560902": "Холмск",
    "286923453": "Абан",
    "286953863": "Уват",
    "286982957": "Калач-на-Дону",
    "287005365": "Новоузенск",
    "287039558": "Салск",
    "287040070": "Қыс",
    "287337481": "Надым",
    "287387383": "Карачаевск",
    "287411141": "Суық",
    "287516647": "Алагир",
    "287660963": "Каспийск",
    "287661153": "Ізбербаш",
    "287662481": "Қасымкент",
    "287670803": "Буйнакск",
    "287673747": "Дағыстан шамдары",
    "287686736": "Кизилюрт",
    "287687179": "Южно-Сухокумск",
    "287702846": "Аргун",
    "287703730": "Гудермес",
    "287704409": "Шалдар",
    "287756202": "Пугачев",
    "287798315": "Қарабұлақ",
    "287798582": "Малгобек",
    "288419752": "Кузнецк",
    "288476845": "Қыштым",
    "288795864": "Яровое",
    "289004179": "Юго-Камский",
    "289011795": "Уас",
    "289464809": "Криводановка",
    "289835271": "Чоя",
    "289843365": "Уас",
    "289980364": "Калачинск",
    "289992321": "Горьковское",
    "289994080": "Одесса",
    "289996348": "Орыс поляны",
    "290215681": "Здвинск",
    "290330613": "Бачатский",
    "290781841": "Туруханск",
    "290884076": "Наушки",
    "290885503": "Новоселенгинск",
    "290889369": "Кабанск",
    "291420659": "Могзон",
    "291558905": "Балахта",
    "291779448": "Алейск",
    "291818590": "Ельцовка",
    "291860771": "Маслянино",
    "292226474": "Заозерск",
    "292231968": "Гаджиево",
    "292235034": "Полярлық",
    "292879578": "Дивеево",
    "292885144": "Снежинск",
    "293459492": "Кола",
    "295072813": "Вершино-Дарасунский",
    "295119416": "Тяжинский",
    "295862317": "Буинск",
    "295864677": "Чистополь",
    "295896340": "Шуя",
    "295902357": "Ермаковское",
    "295939729": "Шушенское",
    "295945680": "Черемушки",
    "295952416": "Курагино",
    "296081305": "Нолинск",
    "296086848": "Нема",
    "296087893": "Килмез",
    "296100014": "Мальмыж",
    "296113691": "Уржум",
    "296130162": "Ува",
    "296134100": "Ядрин",
    "296138675": "Тюкалинск",
    "296379510": "Илький",
    "296410521": "Нигелла",
    "297513007": "Улаган",
    "298352406": "Апшеронск",
    "300582728": "Жаңа Игирма",
    "300588087": "Жұлын",
    "300594850": "Шестаково",
    "300612214": "Железногорск-Илимский",
    "300659754": "Кодинск",
    "301246150": "Краснознаменск",
    "301636452": "Киренск",
    "301639437": "Ленск",
    "301645168": "Бодайбо",
    "301648440": "Рыбное",
    "301710229": "Мамакан",
    "301761027": "Бейбіт",
    "301770161": "Сәтті",
    "301775279": "Нюрба",
    "301778109": "Вилюйск",
    "301789452": "Айхал",
    "301822109": "Berdigestyah",
    "301959493": "Олекминск",
    "302019058": "Турунтаево",
    "302024328": "Гремячинск",
    "302036717": "Качуг",
    "302066783": "Suntar",
    "302372139": "ана",
    "302524562": "Нижнеангарск",
    "302534600": "Бичура",
    "302605557": "Верхоянск",
    "302830616": "Майя",
    "303015164": "Умба",
    "303019430": "Ревда",
    "303353860": "Полярлық таңдар",
    "303973327": "Заполярный",
    "304337561": "Озерск",
    "304552309": "Чокурдах",
    "304605480": "Кемпірқосақ",
    "304663037": "Полина Осипенко атындағы",
    "304721209": "Валуйки",
    "306457009": "Краснотуринск",
    "306457427": "Карпинск",
    "306458157": "Волчанск",
    "307089440": "Урей",
    "307964759": "Углеуральский",
    "308059641": "Лесогорск",
    "308220097": "Лысва",
    "308259963": "Шигондар",
    "308877563": "Домбаровский",
    "308879014": "Жарық",
    "308897102": "Алғыс айту",
    "309493556": "Кем",
    "310831029": "Мортка",
    "310877933": "Ирбит",
    "310912076": "Кириши",
    "313570754": "Инза",
    "314794893": "Гус-Хрустальный",
    "316492711": "Михайловское",
    "321203443": "Еткүл",
    "330235003": "Хатанга",
    "330402488": "Бабаево",
    "330421322": "Жаңа Ургал",
    "330823637": "Қайырмасы",
    "330842003": "Сосногорск",
    "334371762": "Красноусольский",
    "335884516": "Игарка",
    "335978456": "Красноборск",
    "335978473": "Коряжма",
    "335978476": "Солвычегодск",
    "336140198": "Рассказово",
    "336140373": "Усман",
    "336140969": "Қазан",
    "336526404": "Орысша камешкир",
    "336526550": "Новосемейкино",
    "337839371": "Сергиевск",
    "339064480": "Заволжье",
    "339961651": "Багаевская",
    "339980655": "Усть-Донецк",
    "340961881": "Зауральский",
    "340962185": "Еманжелинск",
    "342402547": "Новоржев",
    "346802464": "Илек",
    "352776308": "Покачи",
    "353177210": "Чкаловск",
    "355144185": "Коркино",
    "356772503": "Чертково",
    "360837530": "Кирен",
    "361024504": "Уренгой",
    "361366039": "Георгиевск",
    "364775751": "Чермоз",
    "409086394": "Аскиз",
    "411691832": "Ломоносов",
    "413805673": "Усть-Майя",
    "417406659": "Білімбай",
    "417625373": "Климово",
    "425219949": "Курчатов",
    "425834128": "Қалам",
    "436918452": "Хандыга",
    "438263230": "Ревда",
    "439191804": "Арти",
    "439555704": "Михайловск",
    "440614116": "Зырянка",
    "441108611": "Чунский",
    "442301557": "Бұғы",
    "443694980": "Жаңа Чара",
    "444271257": "Батагай",
    "447905020": "Саскилах",
    "447923120": "Жилинда",
    "450854343": "Қаратұз",
    "451604149": "Идринское",
    "456844533": "Plyos",
    "457342402": "Самағалтай",
    "457351057": "Хау-Аксы",
    "457351426": "Эрзин",
    "457354065": "Каа-Хем",
    "457453957": "Таштип",
    "457497582": "Белый Яр",
    "457505136": "Бей",
    "457507984": "Усть-Абакан",
    "457894655": "Тазовский",
    "457970089": "Красноселкуп",
    "460482858": "Шерловая тауы",
    "461742306": "Петропавл",
    "461810345": "Кичера",
    "461812470": "Жаңа уоян",
    "462126714": "Баргузин",
    "462129412": "Құрымқан",
    "462164030": "Сосново-Озерское",
    "462167682": "Кижинга",
    "462202929": "Иволгинск",
    "463166446": "Тасеево",
    "465238192": "Казачинское",
    "467758650": "Ольга",
    "467895102": "Поярково",
    "468060289": "Архара",
    "470940629": "Тисул",
    "471259093": "Бэйли",
    "472574443": "Кавалерово",
    "472597489": "Дальнегорск",
    "474301764": "Жебе",
    "475538643": "Новоселово",
    "478808135": "Славск",
    "493613381": "Новопавловск",
    "494895608": "Антропово",
    "494895622": "Парфенево",
    "512485280": "Милково",
    "517658001": "Контейнер",
    "517682881": "Колосовка",
    "518743376": "Амурск",
    "524331733": "Болшье",
    "587659863": "Товарково",
    "601116310": "Первомайский",
    "612380967": "Төменгі су тасқыны",
    "628649194": "Көбік",
    "653117001": "Белый Яр",
    "654834896": "Барсово",
    "699741885": "Верхнемарково",
    "703166491": "Апастово",
    "764019333": "Куровское",
    "766484437": "Темір жол",
    "767068249": "Правдинск",
    "795838932": "Снежногорск",
    "821386596": "Коммунист",
    "833665925": "Анжеро-Судженск",
    "849853637": "Середейский",
    "884345171": "Дедовичи",
    "892808209": "Белиги",
    "892808214": "Мағарамкент",
    "892835005": "Каякент",
    "904016707": "Кумух",
    "904026153": "Леваши",
    "911587161": "Бежта",
    "911959278": "Солтүстік",
    "914387646": "Нестеров",
    "935566173": "Яшкул",
    "943663644": "Березовка",
    "975940834": "Семинар",
    "976862420": "Шығыс",
    "1009135181": "Выборг",
    "1010179630": "Ленинск",
    "1028723760": "Николаевка",
    "1028723768": "Эрбогачен",
    "1028723907": "Приаргунск",
    "1028724277": "Охотск",
    "1067034190": "Асқиз",
    "1076441937": "Спасск-Дальный",
    "1077657916": "Лениншіл",
    "1201358624": "Бурея",
    "1205639127": "Жирекен",
    "1205649761": "Ксеньевка",
    "1214516212": "Муравленко",
    "1252364676": "Заринск",
    "1282776137": "Кольцово",
    "1345529992": "Янгель",
    "1348895093": "Зеленогорск",
    "1372682490": "Ирбейское",
    "1404369898": "Плесецк",
    "1416600659": "Белоярский",
    "1420696955": "Гусев",
    "1430774947": "Няган",
    "1480803930": "Шекара",
    "1515028708": "Шахтер",
    "1555616147": "Мятлево",
    "1590340571": "Знаменск",
    "1592488757": "Почеп",
    "1607065754": "Березово",
    "1625195915": "Нерюнгри",
    "1627107363": "Оленегорск",
    "1641900014": "Мухоршибир",
    "1645221793": "Кропоткин",
    "1665035561": "Тарбағатай",
    "1798809766": "Протвино",
    "1805732865": "Белебей",
    "1828409507": "Медвежежорск",
    "1838482543": "Оконешниково",
    "1839536171": "Лахденпохжа",
    "1903971517": "Магдагачи",
    "1908737047": "Суздаль",
    "2071063097": "Алдан",
    "2078883019": "Костомукша",
    "2127195931": "Нарткала",
    "2173162616": "Ванино",
    "2288716213": "Алатыр",
    "2291707774": "Чудово",
    "2300530228": "Myaungja",
    "2414002280": "Тикси",
    "2559814235": "Төменгі Куранах",
    "3030450878": "Оңғудай",
    "60571493": "Београд",
    "432424985": "Грузия",
    "424313803": "Чехия",
    "424316417": "Молдова",
    "249399300": "Беларусь",
    "1648342273": "Украина",
    "838090640": "Ұлыбритания",
    "1683325355": "Германия",
    "424315584": "Австралия",
    "424317935": "АҚШ",
    "424298326": "Түркия",
    "424317428": "Өзбекстан",
    "424314276": "Тәжікстан",
    "249399440": "Түрікменстан",
    "424314145": "Үндістан",
    "424314088": "Пәкстан",
    "249399419": "Сауд Арабиясы",
    "424310703": "Біріккен Араб Әмірліктері",
    "26347360": "Италия",
    "424313451": "Жапония",
    "424313760": "Канада",
    "424297647": "Израиль",
    "437146363": "Тайланд",
    "1363947712": "Франция",
    "26847709": "Аустрия",
    "466507355": "Абхазия",
    "432425060": "Польша",
    "1504546320": "Швейцария",
    "1684793666": "Бельгия",
    "424310601": "Албания",
    "432424981": "Финляндия",
    "432425019": "Латвия",
    "424297773": "Литва",
    "424313832": "Эстония",
    "432425064": "Румыния",
    "432425040": "Моңғолия",
    "432424937": "Бангладеш",
    "432424989": "Грекия",
    "435981993": "Конго Демократиялық Республикасы",
    "424313903": "Кения",
    "424311353": "Хорватия",
    "249399280": "Аргентина",
    "249399406": "Перу",
    "26847706": "Ауғанстан",
    "424298311": "Иран",
    "249399459": "Йемен",
    "432425062": "Катар",
    "26036352": "Филиппиндер",
    "424313428": "Сингапур",
    "248120384": "Жаңа Зеландия",
    "424298167": "Оңтүстік Африка Республикасы",
    "148332300": "Испания",
    "2134362766": "Малайзия",
    "424297217": "Нидерланд"
}

export default Locations;