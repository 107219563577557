import React from "react";
import LeftMenu from "./left-menu";

class LeftMenuController extends React.Component{

    render(){
        return <LeftMenu {...this.props} />
    }

}

export default LeftMenuController;