import DigestItemController from "./digest-item-controller";
import { getSubtopics } from "store/topic-item";
import { connect } from "react-redux";
import { getAdminUsers, getAdminActiveUsers } from "selectors/admin-topics-selector";
import { getValue } from "store/languages";
import { createDigest, updateDigest, createDigestSubtopic, deleteDigest, updateDigestSubtopic, setSelectedDigestTopic, setSelectedUser } from "store/digest";

let mapStateToProps = (state) => {
    return {
        //subtopics: getSubtopicsDropdownFromState(state),
        /*selectedDigestTopic: state.digest.selectedDigestTopic,
        selectedDigestTopicName: state.digest.selectedDigestTopicName,
        selectedDigestTopicUIID: state.digest.selectedDigestTopicUIID,
        selectedDigestTopicSubtopics: state.digest.selectedDigestTopicSubtopics,
        selectedDigestSubtopic: state.digest.selectedDigestSubtopic,
        selectedDigestSubtopicUUID: state.digest.selectedDigestSubtopicUUID,
        selectedDigestSubtopicName: state.digest.selectedDigestSubtopicName,*/
        reportFeedTypes: state.digest.reportFeedTypes,
        selectedRole: state.AdminMode.selectedRole,
        users: getAdminUsers(state),
        activeUsers: getAdminActiveUsers(state),
        selectedUser: state.digest.selectedUser,
        selectedUserId: state.digest.selectedUserId,
        reportFormatTypes: state.report.reportFormatTypes,
        weekDays: state.digest.weekDays,
        myFilters: state.Filters.myFilters,
        digestPeriod: state.digest.digestPeriod,
        email: state.Account.email,
    }
}

export default connect(mapStateToProps, {getValue, getSubtopics, createDigest, updateDigest, createDigestSubtopic, deleteDigest, updateDigestSubtopic, setSelectedDigestTopic, setSelectedUser})(DigestItemController)