import React, {useState} from "react";
import { InputText } from "../../commons/input-controls";
import { StandartButton } from "../../commons/form-controls";
import { ReactComponent as SaveIcon } from "../../assets/icons/save.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import s from "./save-filters-settings.module.scss"

const SaveFiltersSettings = (props) => {

    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [update, setUpdate] = useState(null)

    const onNameChange = (e) => {
        setName(e)
        if(update){
            setUpdate(null)
            setErrorMessage(null)
        }
        if(name && !props.selectedItems){
            setErrorMessage("Нет выбранных фильтров")
            
        }
    }

    const createFilter = () => {
        let n = props.myFilters.filter(k => k.name && k.name === name)
        if(!n.length){
            setErrorMessage("Набор фильтров успешно сохранен");
            props.createNewFilter(name);
            setName(null)
        }
        else{
            setErrorMessage(`Такое название уже существует, перезаписать ${name}?`);
            setUpdate(n[0])
        }
    }

    const updateFilter = () => {
        props.updateFilter(update.name, update.id);
    }

    const closeModal = () => {
        props.modalShowToggle(false)
    }

    return (
        <div className={s.SaveFiltersItems}>
            <span></span>
            <InputText value={name} placeholder={props.getValue('ENTER_SETTINGS_NAME')} callback={onNameChange} goto={update ? updateFilter : createFilter}/>
            {errorMessage && <div className={s.Message}><span>{errorMessage}</span></div>}
            <div className={s.buttonsBlock}>
                <StandartButton disabled={!name || !props.selectedItems} title={update ? "Презаписать" : props.getValue('SAVE')} icon={SaveIcon} fill={'white'} callback={update ? updateFilter : createFilter}/>
                <StandartButton title={props.getValue('CANCEL')} icon={CloseIcon} stroke={'white'} callback={closeModal}/>
            </div>
        </div>
    )
}

export default SaveFiltersSettings;