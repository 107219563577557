import React from "react";
import PostsItem from "./posts-item";
import moment from 'moment';
import 'moment-timezone';
import { getFormatedDate } from "service/format-date";

class PostsController extends React.Component{
    getDate = (date) => {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
        
    }

    constructor(props){
        super(props);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        //this.props.setGrouping("DAILY")
        //let topicUUId = params.get('topicUUID')
        let selectedItem = Number(params.get('menu'));
        this.searchList = this.props.searchList;
        this.state = {
            selectedTopics: this.props.selectedTopics,
            selectedSubItem: this.props.selectedSubUUId, //props.topicData ? props.topicData[0].uuid : props.selectedSubUUId,
            itemsCount: this.props.posts.numFoundTotal,
            currentPage: this.props.currentPage,
            showedItemsCount: this.props.showedItemsCount,
            topics: this.props.topicsSelected ? this.props.topicsSelected.join(',') : '',
            type: this.props.type,
            update: false,
            counter: 0,
            sort: this.props.sort.list
        }
        /*if(selectedItem){
            props.setTreeSelectedItems(selectedItem, topicUUId, selectedItem)
        }*/
        this.currentPage = this.props.currentPage
        //this.selectedTopics = this.props.selectedUUId;
        //this.selectedSubItem = this.props.selectedSubUUId;
        this.startDate = this.props.startDate//moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
        this.endDate = this.props.endDate;
        this.grouping = 'DAILY';
        
    }

    checkDate = () => {
        if(this.startDate !== this.props.startDate || this.endDate !== this.props.endDate){
            this.startDate = this.props.startDate;
            this.endDate = this.props.endDate;
            
            return true;
            
        }
        else
            return false
    }

    getDocumentsByType = (page) => {
        let subtopics = this.props.selectedSubUUId ? [this.props.selectedSubUUId] : []
        let filters = {...this.props.selectedItems }
        
        this.props.resetCounter();
        if(filters.topics)
             delete filters.topics
        if(this.searchList)
            filters.clauses = this.searchList
        switch(this.props.type){
            case 'BlackList':
                this.props.getTopicBlackListDocuments(this.props.selectedTopics, this.props.showedItemsCount, page, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.grouping, filters, subtopics, this.props.sort)
                break;
            case 'RecycleBin':
                this.props.getDeletedListDocuments(this.props.selectedTopics, this.props.showedItemsCount, page, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.grouping, filters, subtopics, this.props.sort)
                break;
            case 'Favourite':
                this.props.getTopicFavouriteDocuments(this.props.selectedTopics, this.props.showedItemsCount, page, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.grouping, filters, subtopics, this.props.sort)
                break;
            case 'FavouritePublications':
                this.props.getFavouritePublications(this.props.selectedTopics, this.props.showedItemsCount, page, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.grouping, filters, subtopics, this.props.sort)
                break;
            default:
                this.props.getTopicDocuments(this.props.selectedTopics[0], this.props.showedItemsCount, page, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.grouping, filters, this.props.selectedSubtopics, this.props.sort)
        }
    }

    componentDidMount(){
        this.props.setSelectAllItems();
        this.props.setSelectedAllList(false);

        this.getDocumentsByType(this.props.page)
        this.props.posts.numFoundTotal && this.props.setItemsCount(this.props.posts.numFoundTotal)
        if(this.props.role !== "ROLE_MARKER"){
            if(this.props.companyId && !this.props.tags)
                this.props.getCompanyTags(this.props.companyId)
        }
    }

    getPostsFromTopic = (page) => {
        this.props.getTopicDocuments(this.state.selectedTopics, this.props.showedItemsCount, page-1, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping, this.props.selectedItems)
    }

    changeRowsCount = (rowsCount) => {
        this.props.setStorageRows(rowsCount);
        this.props.getTopicDocuments(this.state.selectedTopics, rowsCount, this.props.page, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping)
    }

    getName = (obj) => {
        return obj.indexName
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date);
    }

    getTagName = (tagId) => {
        return  this.props.tags.filter(k => Number(tagId) ? k.id === Number(tagId) : k.uuid === tagId)
    }

    checkUpdate = () => {
        if((this.state.topics !== this.props.selectedTopics.join(',')) 
                                            || this.checkDate() 
                                            || this.props.isChanged 
                                            || this.state.selectedSubtopics !== this.props.selectedSubtopics.join(",")
                                            || this.state.showedItemsCount !== this.props.showedItemsCount 
                                            || this.props.updateStorageData 
                                            || this.state.type !== this.props.type
                                            || this.state.sort !== this.props.sort.list){
            
            this.setState({
                selectedTopics: this.props.selectedTopics,
                selectedSubtopics: this.props.selectedSubtopics.join(","),
                currentPage: this.props.currentPage,
                showedItemsCount: this.props.showedItemsCount,
                update: true,
                type: this.props.type,
                topics: this.props.selectedTopics && this.props.selectedTopics.length ? this.props.selectedTopics.join(',') : '',
                sort: this.props.sort.list
            })
                if(this.props.currentPage !== 0) this.props.setCurrentPage(0)  
                    this.props.setUpdateStorageData(false)
                    this.props.setSelectAllItems([])
                    this.props.setSelectedAllList(false)
                    this.props.setIsFilterChanged(false)
                    this.searchList = []
                    //this.props.setGeoplaces({})
                }
                else if(this.state.currentPage !== this.props.currentPage){
                    //this.props.setGeoplaces(null,{})
                    this.setState({
                        currentPage: this.props.currentPage,
                        update: true
                    })
                    this.props.setCurrentPage(this.props.currentPage)
                    this.props.setSelectAllItems([]);
                    this.props.setSelectedAllList(false)
                    
                }

    }

    setAllShowMoreText = (show) => {
        this.props.setShowMoreTextButton(show);
    }

    componentDidUpdate(){
        if(this.searchList !== this.props.searchList){
            this.searchList = this.props.searchList
        }
        if(this.props.companyId && !this.props.tags)
            this.props.getCompanyTags(this.props.companyId)
        if(this.state.itemsCount !== this.props.posts.numFoundTotal){
            this.setState({
                itemsCount: this.props.posts.numFoundTotal
            })
            this.props.setItemsCount(this.props.posts.numFoundTotal)
        }
        if(this.props.posts, !isNaN(this.props.posts.numFoundTotal)){
            this.props.setReturnBackPostCount(this.props.posts.numFoundTotal)
        }
        this.checkUpdate()
        
        if(this.state.update){
            this.setState({
                update: false
            })

            this.getDocumentsByType(this.props.currentPage)

        }
    }

    componentWillUnmount(){
        this.setState({
            currentPage: 0,
            itemsCount: 0
        })
        this.props.setSelectAllItems([])
    }

    render(){
        return <PostsItem 
                    getDocumentsByType={this.getDocumentsByType} 
                    {...this.props} 
                    postType={this.props.type} 
                    changePage={this.getPostsFromTopic} 
                    selected={this.props.selectedTopic} 
                    changeRowsCount={this.changeRowsCount} 
                    getTagName={this.getTagName} 
                    counter={this.state.counter}
                    allShowMoreText={this.props.allShowMoreText}
                    setAllShowMoreText={this.setAllShowMoreText}
                    update={this.state.update}
                />
    }

}

export default PostsController;