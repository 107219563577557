import React from "react";
import s from "../assets/styles/arrow.module.scss"

export const ArrowUp = (props) => {
    return (
        <span className={s.ArrowUp}>
            {'\u00A0'}
            <span style={{fontSize: props.fontSize ? props.fontSize : '12px', color: props.color ? props.color : 'var(--caption)'}}>↑</span>
        </span>
    )
}

export const ArrowDown = (props) => {
    return (
        <span className={s.ArrowDown}>
            {'\u00A0'}
            <span style={{fontSize: props.fontSize ? props.fontSize : '12px', color: props.color ? props.color : 'var(--caption)'}}>↓</span>
        </span>
    )
}