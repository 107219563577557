import TopicsController from "./topics-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent,setRemoveLeftMenuComponent } from "store/side-bar";
import { getTopicTableFromState } from "selectors/theme-selector";
import { modalShowToggle } from "store/modal";
import { getMyTopics, createTopic, setSelectedSort, setIndexSort, setNameSort, setCreatedDateSort, setEditedDateSort, setThemeTableData, deleteTopic, setErrorInAction, setThemeEditType, startTopic, pauseTopic, setUpdatedData} from "store/theme-settings";
import { clearTopicItem } from "store/topic-item";
import { getTopics, } from "selectors/theme-selector";
import { getAdminUsers } from "selectors/admin-topics-selector";

let mapStateToProps = (state) => {
    return {
        tableHeaders: state.themeSettings.tableHeaders,
        subtopicTableHeader: state.themeSettings.subtopicTableHeader,
        selectedSortIndexes: state.themeSettings.selectedSortIndexes,
        indexSort: state.themeSettings.indexSort,
        nameSort: state.themeSettings.nameSort,
        createDateSort: state.themeSettings.createDateSort,
        editedDateSort: state.themeSettings.editedDateSort,
        errorInAction: state.themeSettings.errorInAction,
        isDataUpdated: state.themeSettings.isDataUpdated,
        topics: getTopics(state),
        controlButtons: state.themeSettings.controlButtons,
        role: state.Account.role,
        selectedLanguage: state.languages.selectedLanguage,
        languages: state.TopicItem.languages,
        selectedRole: state.AdminMode.selectedRole,
        users: getAdminUsers(state),
    }
}

export default 
    connect(mapStateToProps, {
                                getValue, 
                                getMyTopics,
                                deleteTopic,
                                startTopic,
                                pauseTopic,
                                setSelectedSort, 
                                setIndexSort, 
                                setNameSort, 
                                setCreatedDateSort, 
                                setEditedDateSort, 
                                setThemeTableData,
                                setRightMenuComponent,
                                modalShowToggle,
                                setErrorInAction,
                                setShowRightMenu,
                                setRemoveRightMenuComponent,
                                setRemoveLeftMenuComponent,
                                setThemeEditType,
                                clearTopicItem,
                                setUpdatedData
                            })
                            (TopicsController)