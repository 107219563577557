import { createSelector } from "reselect";
import { getTranslate } from "assets/titles/title-text";

const getMediaTypesData = (state) => {
    return state.Comparison.data;
}

/*const red = "#E25353";
const green = "#29B593";
const blue = "#5EAAE1";
const grey = "#C6C6D1";
const gray = "#9A9EC3";*/

const red = "#E25353";
const green = "#29B593";
const blue = "#5EAAE1";
const grey = "#C6C6D1";
const gray = "#9A9EC3";

var colors = ["#5EAAE1", "#E25353", "#29B593", "#F58219", "#5555A0", "#EAE460", "#B5DD5F", "#9A9EC3", "#134E8D", "#00CA5F", " #AA4A44"];


const getSentimentName = (sentiment) => {
    switch(sentiment){
        case 'neutral':
            return 'NEUTRAL_3'
        case 'undefined':
            return 'NOT_DETERMINDED_3'
        case "negative":
            return "NEGATIVE_3"
        case "positive":
            return "POSITIVE_3"
    }
}

const getToneColor = (sentiment) => {
    switch(sentiment){
        case 'neutral':
        case 'NEUTRAL_3':
            return blue;
        case 'undefined':
        case 'NOT_DETERMINDED_3':
            return gray;
        case "negative":
        case "NEGATIVE_3":
            return red;
        case "positive":
        case "POSITIVE_3":
            return green;
    }
}

const getLabels = (items, type = 'value') => {
    let labels = [];
    items.forEach((k, i) => {
            k.pivots.map((p, iterator)=>{
                
                if(p.value === 'null'){
                    return
                }
                let f = labels.indexOf(p[type])
                if(f === -1)
                    labels.push(p[type])

                if(type === 'field'){
                    if(p.value === 'false'){
                        labels[iterator] = 'not_'+labels[iterator]
                    }
                }
            })

    })
    
    return labels
}

const getTypeName = (type, label, value) => {
    if(type === 'field'){
        if(value === 'false'){
            
            return 'not_'+label
        }
        else return label
    }
    else return label
}

const langsItems = (items, item) => {
    if(item !== 'kz' && item !== 'ru' && item !== 'en'){
        item = "OTHERS"
    }
    let lang = items.indexOf(item)
    if( lang === -1){
        items.push(item)
        lang = items.indexOf(item)
    }
    return {index: lang, items: items, item: item}
}

const getTopicName = (topicId, topics) => {
    let findedTopic = topics.findIndex(f => f.uuid === topicId);
    return findedTopic !== -1 ? topics[findedTopic].name : topicId
}

const getDataRow = (length) => {
    let data = [];
    data.length = length;
    let res = [];
    for(let i = 0; i<length-1; i++){
        res[i] = 0
    }
    return res
}

const getIndexNumber = (items, item, rowLength, it, colors, locations, max = null) => {

    let name = item.field !== 'profile_location_id' ? getTranslate(item.value) : item.value
    let index = items.findIndex(k => k.name === name)
    /*if(item.field === 'profile_location_id' && max && items.length > max - 1){
        return {items: items, colors: colors, locations: locations}
    }*/
    if(index === -1){
        let data = getDataRow(rowLength)
        data[it] = item.count;
        if(item.value !== 'null') items.push({name: name, data: data})
        if(item.field === 'sentiment') colors.push(getToneColor(item.value))
        else if(item.field === 'profile_location_id' && item.value !== 'null') locations.push(item.value)
    }
    else{
        if(item.value !== 'null') items[index].data[it] = item.count
        if(item.field === 'sentiment') colors[index] = getToneColor(item.value)
        else if(item.field === 'profile_location_id' && item.value !== 'null') locations[index] = item.value
    }    
    let res = {items: items, colors: colors, locations: locations}

    return res
}

export const getComparisonData = createSelector(getMediaTypesData, (mediaTypes) => {
    let d = {topic: [], values: [], labels: [], colors: colors, topicIDs: []}
    d.itemType = mediaTypes.type;
    d.topicIDs = mediaTypes.topicIDs ? mediaTypes.topicIDs : [];
    d.title = mediaTypes.chartSettings && mediaTypes.chartSettings.title ? mediaTypes.chartSettings.title : mediaTypes.type
    d.chartType = mediaTypes && mediaTypes.chartType ? mediaTypes.chartType : 'column'
    d.total = 0;
    let totalFinded = false;
    let colorsType = [];
    let langList = [];
    let locations = []
    if(mediaTypes && mediaTypes.data && mediaTypes.data.length){
        let it = 0
        if(mediaTypes.data[0].value === 'total'){
            d.total = mediaTypes.data[0].count
        }
        mediaTypes.data.forEach((k, i) => {
            let langCount = 0;
            if(k.value !== 'total'){
                if(!totalFinded){
                    d.total += k.count
                }
                if(k.field === 'topic_id'){
                    d.labels[it] = mediaTypes.topics && mediaTypes.topics.length ? getTopicName(k.value, mediaTypes.topics) : mediaTypes.topics}
                else{
                    if(mediaTypes.topics && mediaTypes.topics.length){
                        let topic = mediaTypes.topics.findIndex(f => f.uuid === mediaTypes.topicId[0])
                        let lables = getTopicName(k.value, mediaTypes.topics[topic].subtopics)
                        d.labels[it] = lables
                    }

                }
                k.pivots.map((n, j) => {
                    if(n.field === 'profile_location_id' && j >= mediaTypes.chartSettings.max)
                        return
                    switch(n.field){
                        case 'language':
                            
                            let items = langsItems(langList, n.value)
                            let index = items.index
                            let item = items.item
                            if(!d.topic[index])
                                d.topic[index] = {name: getTranslate(item), data: []}

                            if(item === 'OTHERS'){
                                langCount += n.count
                                d.topic[index].data[it] = langCount
                            }
                            else
                                d.topic[index].data[it] = n.count
                            
                            d.topic[index].name = getTranslate(item)
                            break;
                        default:
                            let res = getIndexNumber(d.topic, n, mediaTypes.data.length, it, colorsType, locations, mediaTypes.chartSettings && mediaTypes.chartSettings.max)
                            d.topic = res.items
                            colorsType = res.colors
                            locations = res.locations
                    }
                })
                it++ 
            }
            else{
                totalFinded = true
                d.total = k.count

            }
        })
    }
    if(colorsType.length){
        d.colors = colorsType
    }
    if(locations.length){
        d.locations = locations
    }
    return d
})

