import { AccountService } from "connection/account-service";
import { SuperadminService } from "connection/superadmin-service";
import { AdminService } from "connection/admin-service";
import { getCompanyAccounts } from "./superadmin";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_AUTHORITIES = "SET-AUTHORITIES";
const SET_SELECTED_AUTHORITY = "SET-SELECTED-AUTHORITY";
const SET_UPDATED_AUTHORITY = "SET-UPDATED-AUTHORITY";
const SET_ACCOUNT_AUTHORITY = "SET-ACCOUNT-AUTHORITY";
const SET_SELECTED_ATUHORITIES = "SET-SELECTED-ATUHORITIES";

const authoritesData = {
    authorities: null,
    selectedAuthorities: {},
    updatedAuthority: false,
}

const Authorities = (state = authoritesData, action) => {
    switch(action.type){
        case SET_AUTHORITIES:
            return {...state, authorities: action.authorities}
        case SET_SELECTED_AUTHORITY:
            let selected = state.selectedAuthorities;
            if(selected[action.selectedAuthority]){
                delete selected[action.selectedAuthority]
            }
            else{
                selected[action.selectedAuthority] = action.selectedAuthorityName 
            }
            return {...state, selectedAuthority: selected, updatedAuthority: true}
        case SET_UPDATED_AUTHORITY:
            return {...state, updatedAuthority: action.updatedAuthority}
        case SET_SELECTED_ATUHORITIES:
            let selectedAuth = {}
            if(action.isChecked === false){
                selectedAuth = []
            }
            else{
                if(action.selectedAuthorities && action.selectedAuthorities.length){
                    action.selectedAuthorities.forEach(item => {
                        selectedAuth[item.id] = item.name
                    });
                }
            }

            return {...state, selectedAuthorities: selectedAuth}
        default:
            return {...state}
    }
}

export default Authorities;

export const setUpdatedAuthority = (updated) => {
    return {
        type: SET_UPDATED_AUTHORITY,
        updatedAuthority: updated
    }
}

export const setSelectedAuthority = (selectedId, selectedName) => {
    return {
        type: SET_SELECTED_AUTHORITY,
        selectedAuthority: selectedId,
        selectedAuthorityName: selectedName
    }
}

export const setSelectedAuthorities = (selectedAuthorities, isChecked) => {
    return {
        type: SET_SELECTED_ATUHORITIES,
        selectedAuthorities: selectedAuthorities,
        isChecked: isChecked
    }
}

export const setAuthorities = (authorities) => {
    return {
        type: SET_AUTHORITIES,
        authorities: authorities
    }
}

export const getAuthorities = () => {
    return (dispatch) => {
        AccountService.getAuthorities().then( data => {
            dispatch(setAuthorities(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getAccountAuthorities = (accountId, isSuperAdmin) => {
    return (dispatch) => {
        if(isSuperAdmin){
            SuperadminService.getAccountAuthorities(accountId).then( data => {
                dispatch(setSelectedAuthorities(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            });
        }
        else{
            AdminService.getAccountAuthorities(accountId).then( data => {
                dispatch(setSelectedAuthorities(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            });
        }
    }
}

export const addAccountAuthorities = (companyId = null, accountId, authorities, isSuperadmin) => {
    let service = isSuperadmin ? SuperadminService : AdminService
    return (dispatch) => {
        service.addAuthoritiesToAccount(accountId, authorities).then( data => {
            /*if(companyId)
                dispatch(getCompanyAccounts(companyId, isSuperadmin))*/
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}