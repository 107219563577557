import MessageText from "./message-text";
import { checkProfile, checkResource } from "store/topic-item";
import { connect } from "react-redux";
import { getValue } from "store/languages";

let mapStateToProps = (state) => {
    return {
        checkProfileItem: state.TopicItem.checkProfileItem,
        checkResourceItem: state.TopicItem.checkResourceItem
    }
}

export default connect(mapStateToProps, {getValue})(MessageText)