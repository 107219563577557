import React from "react";
import TableItem from "items/table/table";
import s from "./filters.module.scss"

const Filters = (props) => {
    return (
        <div className={s.Filters}>
            {
                <TableItem 
                    data={[props.tableHeader, props.data]} 
                    getValue={props.getValue} 
                    titleText={props.getValue('LIST_OF_FILTERS')}
                />
            }
        </div>
    )
}

export default Filters