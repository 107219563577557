import ControlButtonsView from "./control-buttons-view";
import { connect } from "react-redux";
import { getValue } from "../../store/languages";
import { modalShowToggle } from "store/modal";

let mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, {getValue, modalShowToggle})(ControlButtonsView)