const SET_ITEMS_COUNT = "SET-ITEMS-COUNT";
const SET_CURRENT_PAGE = "SET-CURRENT-PAGE";
const SET_SHOWED_ITEMS_COUNT = "SET-SHOWED-ITEMS-COUNT";
const SET_TO_DEFAULT_PAGES = "SET-TO-DEFAULT-PAGES";

const paginatorData = {
    itemsCount: 0,
    currentPage: 0,
    showedItemsCount: 10
}

const paginator = (state = paginatorData, action) => {
    switch(action.type){
        case SET_ITEMS_COUNT:
            return {...state, itemsCount: action.itemsCount}
        case SET_CURRENT_PAGE:
            return {...state, currentPage: action.currentPage}
        case SET_SHOWED_ITEMS_COUNT:
            return {...state, showedItemsCount: action.showedItemsCount}
        case SET_TO_DEFAULT_PAGES:
            return {...state, itemsCount: 0, currentPage: 0, showedItemsCount: 10}
        default:
            return {...state}
    }
}

export default paginator;

export const setToDefaultPaginator = (item = null) => {
    return {
        type: SET_TO_DEFAULT_PAGES,
        item: item
    }
}

export const setItemsCount = (itemsCount) => {
    return {
        type: SET_ITEMS_COUNT,
        itemsCount: itemsCount
    }
}

export const setCurrentPage = (currentPage) => {
    return {
        type: SET_CURRENT_PAGE,
        currentPage: currentPage
    }
}

export const setShowedItemsCount = (showedItemsCount) => {
    return {
        type: SET_SHOWED_ITEMS_COUNT,
        showedItemsCount: showedItemsCount
    }
}