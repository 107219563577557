import React from "react";
import FavouriteList from "./favourite-list";
import ControlButtons from "../control-buttons/control-buttons";

class FavouriteListController extends React.Component{

    constructor(props){
        super(props);

        this.state = ({
            selectedTopics: this.props.selectedTopicsIds ? this.props.selectedTopicsIds.join(',') : ''
        })
    }
    
    componentDidMount(){
        if(this.props.role){
            this.props.getMyTopics(this.props.role);
        }
        else{
            this.props.getMe();
        }
        if(this.props.topics){  
            this.getFavourites();
        }
    }

    getTopicIds = () => {
        let topicIds = [];
        if(this.props.topics && this.props.topics.length){
            topicIds = this.props.selectedTopics.map(k => this.props.topics[this.props.topics.findIndex( j => j.uuid === k)].id)
        }
        return topicIds
    }

    getFavourites = () => {
        
        let t = this.getTopicIds()

        this.props.getFavouriteList(t)
    }

    onTableItemsClick = (type, key, index) => {
        switch(type){
            case 'return':
                this.props.RemoveFavouriteResourceProfile(index, key);
                this.getFavourites();
                break;
        }
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    getTopicName = (uuid) => {
        let k = this.props.topics.find((f,i)=>{return f.uuid === uuid}) 
        return k
    }

    setTableValues = (tableValues) => {
        return tableValues.map((k, i)=>{
            if(typeof(k[k.length-1]) !== "object"){
                let id = k[0]
                let topicId = k[1]
                k[0] = i+1
                let topic = this.props.topics ? this.getTopicName(k[2]) : k[2]
                k[2] = topic && topic.name ? topic.name : k[2]
                k.push(<ControlButtons keyId={id} callback={this.onTableItemsClick} items={["return"]} index={topicId} />)
            }
            return k})

    }

    componentDidUpdate(){
        if(this.state.selectedTopics !== this.props.selectedTopics.join(',') ){
            let t = this.getTopicIds()
            this.props.getFavouriteList(t);
            this.setState({
                selectedTopics: this.props.selectedTopics.join(',')
            })
        }
    }

    render() {
        return <FavouriteList {...this.props} data={(this.props.favourites && this.props.favourites.length) && this.setTableValues(this.props.favourites)} tableHeaderName={this.getTableHeader(this.props.tableHeader)}/>
    }
}

export default FavouriteListController;