import React, {useState, useEffect} from "react";
import { ReactComponent as AgressiveIcon } from 'assets/icons/agressive_smile.svg';
import { ReactComponent as AdvertisingIcon } from 'assets/icons/advertising.svg';
import { ReactComponent as SwearingIcon } from 'assets/icons/swearing.svg';
import s from "./keywords-list.module.scss"

const KeywordsList = (props) => {

    const [columnCount, setColumnCount] = useState(3);

    const keywords = (props.keywordsList && props.keywordsList.length) &&
                    props.keywordsList.map((k,i)=>{
                        
                        return (
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td style={{width: 'auto', maxWidth: '400px'}}>
                                        <span className={s.keyword} style={{background: k.color ? k.color : '', color: k.textColor ? k.textColor : ''}}>
                                            {k.name}
                                        </span>
                                    </td>
                                    <td>{k.count}</td>
                                    {
                                        k.agression !== undefined && <td>{
                                                            <div  className={s.KeywordsListPivots}>
                                                                {k.agression ?   <AgressiveIcon /> : null}
                                                                {k.advertising ? <AdvertisingIcon /> : null}
                                                                {k.swearing ? <SwearingIcon /> : null}
                                                            </div>
                                                        }</td>
                                    }
                                    <td>
                                        <div className={s.KeywordsListPivots}>
                                            {k.neutral ?   <div className={s.KeywordsListPivotsItem+' '+s.NEUTRAL}>
                                                                {k.neutral} 
                                                            </div>
                                            : null}
                                            {k.negative ?   <div className={s.KeywordsListPivotsItem+' '+s.NEGATIVE}>
                                                                {k.negative} 
                                                            </div>
                                            : null}
                                            {k.positive ?   <div className={s.KeywordsListPivotsItem+' '+s.POSITIVE}>
                                                                {k.positive} 
                                                            </div>
                                            : null}
                                            {k.undefined ?   <div className={s.KeywordsListPivotsItem+' '+s.UNDEFINED}>
                                                                {k.undefined} 
                                                            </div>
                                            : null}
                                        </div>
                                        
                                    </td>
                                </tr>
                        )
                    })

    return (
        <div className={s.KeywordsList}>
            <div className={s.KeywordsListHeader}>
                <span className={s.KeywordsTitle}>{props.title}</span>
                <div className={s.KeywordsListButtons}>

                </div>
            </div>
            <div className={s.KeywordsListBody}>
                <div className={s.KeywordsListTable}>
                    <div className={s.tableHeader}>
                        <table>
                            <thead>
                                <tr>

                                    <th></th>
                                    <th>{props.headerTitle}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {keywords ? keywords : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default KeywordsList;