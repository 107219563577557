import React, { useState, useEffect } from "react";
import CheckboxListContainer from "items/checkbox-list/checkbox-list-container";
import { SelectDropdown } from "commons/input-controls";

const CreateReportSettings = (props) => {

    const [users, setUsers] = useState(props.users);
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedUserId, setSelectedUserId] = useState(null)

    const onCreateSend = (reportSettings) => {
        props.callback(selectedUserId, reportSettings)
    }

    const setSelectedItem = (selected) => {
        setSelectedUser(selected.login)
        setSelectedUserId(selected.id)
    }

    return <div>
        <div>
        <SelectDropdown selected={selectedUser} items={users} height={200} setSelected={setSelectedItem}/>
        </div>
        <CheckboxListContainer clickButton={selectedUserId ? true : false} onClose={null} callback={onCreateSend}/>
    </div>
}

export default CreateReportSettings