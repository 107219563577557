const SET_SHOW_PASSWORD = "SET-SHOW-PASSWORD";

let signin = {
    showPassword: false
}

const signinForm = (state = signin, action) => {
    switch(action.type){
        case SET_SHOW_PASSWORD:
            return {...state, showPassword: action.showPassword}
        default:
            return {...state}
    }
}

export default signinForm;

export let setShowPassword = (showPassword) => {
    return {
        type: SET_SHOW_PASSWORD,
        showPassword: showPassword
    }
}