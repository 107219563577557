const Locations = {
    "424313582": "China",
    "424313675": "Azerbaijan",
    "1700777271": "Kyrgyzstan",
    "508058549": "Pavlodar region",
    "508059331": "The East Kazakhstan region",
    "784756509": "North-Kazakhstan region",
    "959501189": "Karaganda region",
    "959502983": "Kostanay region",
    "959509383": "Akmola region",
    "974358575": "Alma-Ata's region",
    "974358688": "Jambyl Region",
    "974359207": "Kyzylorda Region",
    "975900646": "Aktobe region",
    "975902304": "West-Kazakhstan region",
    "975903612": "Atyrau region",
    "975906931": "Mangistau region",
    "974358986": "Turkestan region",
    "27522202": "Talgar",
    "27524816": "Families",
    "98022062": "Atyrau",
    "214887132": "Turkestan",
    "240470924": "Taraz",
    "240505390": "Shu",
    "240711925": "Zhanatas",
    "240865457": "Karaganda",
    "240872918": "Pavlodar",
    "241035125": "Aktobe",
    "240993564": "Ust-Kamenogorsk",
    "241455373": "Temirtau",
    "241492821": "Kyzylorda",
    "241542056": "Kokshetau",
    "241549456": "Ore",
    "241679356": "Taldykorgan",
    "241772277": "Kentau",
    "241781833": "Arys",
    "242415806": "Sary-Ozek",
    "242441466": "Koskuduk",
    "242455350": "Zholaman",
    "242465214": "Kapchagay",
    "242940822": "Ayagoz",
    "243119367": "Aktogay",
    "243178767": "Lepsy",
    "243182293": "Matay",
    "243235602": "Usharal",
    "243324293": "Sarkand",
    "243325633": "Zhansugurov",
    "243464146": "Makanchi",
    "243465319": "Urjar",
    "243472864": "Shingoza",
    "243508453": "Dostyk",
    "243657575": "Zharkent",
    "243659737": "Koktal",
    "243692801": "Charyn",
    "243693248": "Chundzha",
    "243697387": "Podgornoye",
    "243708956": "Acts",
    "243723775": "Kegen",
    "243725702": "Shelek",
    "243729810": "Zhalanash",
    "243735997": "Saryzhaz",
    "243737203": "Tekes",
    "243741613": "Narynkol",
    "243743625": "Esik",
    "243748132": "Sumbe",
    "243748155": "Sarybastau",
    "243798668": "Saty",
    "243850399": "Karashoky",
    "243991314": "Akshy",
    "243995789": "Kopa",
    "243997970": "Shamalgan",
    "243998902": "Uzynagash",
    "244005521": "Burubaital station",
    "244010886": "Aksuek",
    "244017974": "Balkhash",
    "244109105": "Kabanbai",
    "244114769": "Bakhty",
    "244118661": "Akzhal",
    "244128763": "Cockpects",
    "244135961": "Maykapchagay",
    "244147213": "Ridder",
    "244198209": "Zyryanovsk",
    "244529371": "Lenger",
    "244532522": "Saryagash",
    "247318618": "Zhetysay",
    "247318653": "Atakent",
    "247319161": "Shardara",
    "248057498": "Lisakovsk",
    "253063867": "Zhanaozen",
    "253716507": "Kulsary",
    "253725107": "Makat",
    "253729284": "Makhambet",
    "253983700": "Beyneu",
    "254129240": "Mukur",
    "254135336": "Sagiz",
    "254136309": "Miyaly",
    "254139962": "Karauylkeldy",
    "254143253": "Shubarkuduk",
    "254313000": "Agadyr",
    "254313822": "Akchatau",
    "254314127": "Kairakty village",
    "254316151": "Moints",
    "271077515": "Aiteke bi",
    "271080647": "Aralsk",
    "271085442": "Kazalinsk",
    "271092323": "Baikonur",
    "271120352": "Korgalzhyn",
    "271125900": "Press",
    "271183751": "Khromtau",
    "271203690": "Zhezkazgan",
    "271292059": "Karazhal",
    "271293913": "Atasu",
    "271296615": "Saken Seifullin settlement",
    "271301949": "Karabas p.",
    "271309842": "Karkaralinsk",
    "271311323": "Priozersk",
    "271311686": "Barn",
    "271312178": "Satpayev",
    "271395447": "Osakarovka",
    "271401068": "Akzharyk s.",
    "271402224": "Aktogay",
    "271402834": "Nurken",
    "271413561": "Tatan",
    "271416049": "Kievka p.",
    "297359900": "Jambil",
    "297596160": "Kulan / Qulan",
    "299211831": "Ivanovka s.",
    "299923721": "Abay",
    "299924758": "Sheshenkara s. (Proletarian p.)",
    "299974347": "Aktas",
    "299978968": "Aktau",
    "299982128": "Arkalyk",
    "301058599": "Gulshad",
    "301060581": "South p.",
    "301373626": "Konyrat p.",
    "301374302": "Kushoky p.",
    "301374694": "Kyzylzhar p.",
    "301715316": "Shahan p.",
    "301716913": "Shakhtinsk",
    "301717251": "Zhairem village",
    "301733193": "Dzhambul p.",
    "301734878": "Shashubai s. (Ozernoe)",
    "301760956": "Gabiden Mustafin settlement (Tokarevka)",
    "301761659": "Topar",
    "303821397": "Karabalyk",
    "303894619": "Oktyabrskoe",
    "303908697": "Esil",
    "303910698": "Surgan",
    "303911228": "Jaxes",
    "303913171": "Atbasar",
    "377600470": "Kurchatov",
    "410079696": "Derzhavinsk",
    "424311521": "Kazakhstan",
    "430704251": "Burlin",
    "435393175": "Aksai",
    "448814768": "Kostanay",
    "451519656": "Piece of iron",
    "451702142": "Kargaly",
    "451702144": "Ushkonyr",
    "471558381": "Kazgorodok",
    "471753777": "Astrakhanka",
    "471941831": "Alga",
    "471953135": "Chingirlau",
    "472267256": "Ganyushkino",
    "472267642": "Inder",
    "472268433": "Akkistau",
    "473066535": "Zhanakala",
    "473070642": "Zhanakala s.",
    "473080162": "Zhangala",
    "473098790": "Tuskala",
    "473112751": "Peremetnoe",
    "473115328": "Fedorovka",
    "473372461": "Zhanibek",
    "473399115": "Saykhin",
    "476263135": "Shetpe",
    "476293790": "Sai-Utes",
    "476310282": "Akzhigit",
    "481045720": "Ertis",
    "491923263": "Kokpek",
    "525190320": "Denisovka",
    "525439568": "Kumkuduk",
    "527856584": "Azgir",
    "533754334": "Barshatas",
    "534085130": "Sayak village",
    "534085154": "Daratab",
    "534085155": "Zhorga",
    "534085162": "Ayirtas",
    "534085168": "Tomar S.",
    "534087372": "Jogargy Eginsu",
    "534087495": "Kazymbet",
    "534087953": "Keldemurat",
    "534088599": "Berlyk",
    "534097147": "Beskaragay",
    "537561303": "Ekibastuz",
    "539629498": "Ayatskoe",
    "539629508": "Frunzenskoe",
    "546773876": "Botakara p.",
    "546786098": "Algbas",
    "547809505": "Cockpects",
    "547809559": "Baikadam with.",
    "547809608": "Bastau s.",
    "547811784": "Korneevka",
    "554059814": "Shiderty p.",
    "704397626": "Zhitikara",
    "729610410": "Aktau",
    "761956738": "Batpak s. (Kronilovskoe)",
    "761956982": "Oshagandy s. (Red Coot)",
    "761957222": "Baimyrza s. (Submissive)",
    "765064981": "Aksu-Ayuly",
    "832355131": "Dubovka",
    "896978591": "named after Aytabay Nazarbekov",
    "914021120": "Kachar",
    "934340038": "Ushtobe",
    "983585435": "Sartobe s.",
    "986043692": "Dolinka p.",
    "1106418007": "Kurylys p.",
    "1128220486": "Lvovka",
    "1128220490": "Tokhtarovo",
    "1141193378": "Prirechenka",
    "1238308608": "Youth",
    "1253518639": "Zhetpys",
    "1253572824": "Kurminskoe",
    "1253573436": "Zhumabek",
    "1282428111": "Togyzkuduk s.",
    "1282430541": "Board",
    "1284932620": "Rostovka s.",
    "1298132253": "Mamlyutka",
    "1314870477": "Maishgyr",
    "1314870478": "Құlanөtpes",
    "1314889924": "Orazak",
    "1314909997": "Barshino",
    "1314942756": "Kaskatau",
    "1314942758": "Nyaman",
    "1315945296": "Kamenka",
    "1339442968": "Novonezhinka",
    "1362711625": "Chelgashi",
    "1362752104": "Ivanovka",
    "1369849641": "Alga",
    "1375916594": "Geological",
    "1375916598": "Karazhar s.",
    "1375949711": "Asyl s.",
    "1375960032": "Molodetskoe s.",
    "1375975105": "Kyzylzhar",
    "1375990240": "Bereznyaki s.",
    "1375999660": "Tasaul village",
    "1376006294": "Tegiszhol s.",
    "1376576251": "New Krondstadt",
    "1379357053": "Bargain",
    "1384387947": "Karamuryn",
    "1409446799": "Urpek",
    "1410760848": "Belbasar",
    "1430694778": "Pokrovka",
    "1457457600": "Petropavlovsk",
    "1466716533": "Shymkent",
    "1492994952": "Abay",
    "1496101001": "Bakyrly",
    "1496101311": "Juantobe",
    "1496101371": "Zhiңishke",
    "1496101597": "Kirov",
    "1496101666": "Commune / Kommuna",
    "1496102100": "Taikonyr",
    "1496102111": "Talapts",
    "1496102166": "Oraz Ata",
    "1496102346": "Chulakkurgan",
    "1496102393": "Karakur",
    "1515019354": "Shaulder",
    "1655751674": "Suburb",
    "1683604838": "Green",
    "1701097240": "Kotyaevka",
    "1760005822": "Ekpendy",
    "1764497137": "Suzak",
    "1764497143": "Shara / Shara",
    "1775849171": "Kogam",
    "1786918916": "Kurty",
    "1832841848": "Uralsk",
    "1913828772": "Beskol",
    "1915016813": "Borankul",
    "1923077056": "Zhanaaul",
    "1923103026": "Safonovka",
    "1923117377": "Rich",
    "1952946568": "Tole bi",
    "1983402593": "Transshipment",
    "1983412808": "Zhastalap",
    "1983426001": "Rembaza",
    "2005354523": "Anchor",
    "2026935419": "Arkhangelskoe",
    "2026935421": "Novokamenka",
    "2026935424": "Peterfeld",
    "2026935427": "Podgornoye",
    "2026964332": "Bogolyubovo",
    "2026973156": "Novonikolskoe",
    "2042658769": "Temirlanovka",
    "2050311604": "Zhambyl",
    "2050311607": "Birlik",
    "2050321939": "Khantaw",
    "2053541157": "Qaratoghan",
    "2055017561": "Kokcy",
    "2055064645": "Jalghizaghat",
    "2132913855": "Voznesenka",
    "2132913859": "Reliability",
    "2219596229": "Nikolaevka",
    "2219599703": "Petrovka",
    "2219617644": "Chirikovka",
    "2304072858": "Baiterek",
    "2321177089": "Ulanbel",
    "2363178902": "SGP",
    "2606780046": "Ushbulak",
    "2612028998": "Zhanay",
    "26544289": "Almaty",
    "2567720890": "Akkol",
    "2583746142": "Aktobe",
    "27548009": "Nur-Sultan",
    "424314830": "Russian Federation",
    "503077246": "Irkutsk Oblast",
    "505006802": "Ryazan Oblast",
    "516618494": "Kaliningrad region",
    "533719181": "Samara Oblast",
    "535136806": "Murmansk Oblast",
    "535182674": "Sverdlovsk Oblast",
    "573226686": "Tatarstan",
    "573226687": "Bashkortostan",
    "573226688": "Udmurtia",
    "869508901": "Mordovia",
    "933435715": "Kirov Oblast",
    "26903504": "Petrozavodsk",
    "26951330": "Vologda",
    "27017589": "Velikiy Novgorod",
    "27025689": "Pskov",
    "27048976": "Kaliningrad",
    "27072633": "Smolensk",
    "27090043": "Kursk",
    "27096008": "Orel",
    "27119039": "Belgorod",
    "27490597": "Sankt Peterburg",
    "27503892": "Yekaterinburg",
    "27503945": "Samara",
    "27503946": "Omsk",
    "27504067": "Kazan",
    "27504156": "Chelyabinsk",
    "27504327": "Ufa",
    "27504363": "Volgograd",
    "27504531": "Perm",
    "27504590": "Saratov",
    "27505044": "Voronezh",
    "27505046": "Tolyatti",
    "27505129": "Krasnodar",
    "27505266": "Yaroslavl",
    "27505285": "Barnaul",
    "27505335": "Irkutsk",
    "27505477": "Novokuznetsk",
    "27505509": "Orenburg",
    "27505582": "Ryazan",
    "27505663": "Penza",
    "27505666": "Tyumen",
    "27505889": "Nizhny Novgorod",
    "29804102": "Anapa",
    "29980667": "Taganrog",
    "34043670": "Sochi",
    "34389350": "Tula",
    "34395338": "Nazran",
    "34506055": "Nizhny Tagil",
    "2452912060": "Moscow",
    "36714827": "Stavropol",
    "76611479": "Lipetsk",
    "79736088": "Votkinsk",
    "87705691": "Novorossiysk",
    "90495941": "Obninsk",
    "93711720": "Nalchik",
    "101480194": "Engels",
    "143561186": "Dolgoprudny",
    "148899210": "Rybinsk",
    "148901123": "Kostroma",
    "148901125": "Ivanovo",
    "160258541": "Pyatigorsk",
    "178951928": "Krasnoyarsk",
    "179374935": "Kurgan",
    "188558082": "Cherepovets",
    "191638748": "Cherkessk",
    "191640862": "Yoshkar-Ola",
    "191644503": "Makhachkala",
    "191648647": "Elista",
    "191651581": "Saransk",
    "191652335": "Yakutsk",
    "191655065": "Kyzyl",
    "191657470": "Abakan",
    "191697061": "Astrakhan",
    "191723030": "Yuzhno-Sakhalinsk",
    "191736223": "Khanty-Mansiysk",
    "191738860": "Birobidzhan",
    "191749240": "Naberezhnye Chelny",
    "191752285": "Volzhskiy",
    "191754078": "Surgut",
    "191754954": "Komsomolsk-on-Amur",
    "191756795": "Sterlitamak",
    "191758663": "Bratsk",
    "191773560": "Angarsk",
    "191774769": "Nizhnevartovsk",
    "191779212": "Prokopyevsk",
    "191780610": "Noril'sk",
    "191824980": "Armavir",
    "191843200": "Zlatoust",
    "191850710": "Nakhodka",
    "191852866": "Berezniki",
    "191855308": "Rubtsovsk",
    "191863710": "Miass",
    "191864581": "Ussuriysk",
    "191866198": "Kovrov",
    "191870333": "Kopeysk",
    "191871001": "Pervouralsk",
    "191871925": "Odintsovo",
    "191872996": "Nevinnomyssk",
    "191875199": "Kislovodsk",
    "191879312": "Novocheboksarsk",
    "191880180": "Serpukhov",
    "191880979": "Khasavyurt",
    "191885615": "Orekhovo-Zuevo",
    "191890746": "Achinsk",
    "191892583": "Sergiyev Posad",
    "191894808": "Nefteyugansk",
    "191897505": "Noyabrsk",
    "191903740": "Derbent",
    "191908717": "Kiselyovsk",
    "191909355": "Mezhdurechensk",
    "191912193": "Kansk",
    "191914736": "Tobolsk",
    "205216827": "Leninsk-Kuznetsky",
    "251645471": "Chita",
    "253041038": "Zelenograd",
    "255594586": "Sarapul",
    "256361843": "Ramenskoye",
    "269342417": "Murmansk",
    "276628719": "Arkhangelsk",
    "277060995": "Khabarovsk",
    "280304251": "Berdsk",
    "285736628": "Solikamsk",
    "288126203": "Serov",
    "360001149": "Mytischtschi",
    "390614421": "Kemerovo",
    "683814442": "Yessentuki",
    "711715624": "Ul'yanovsk",
    "740732715": "Cheboksary",
    "1078579692": "Arseniev",
    "1244147408": "Magnitogorsk",
    "1457469393": "Ulan-Ude",
    "1522654732": "Grozny",
    "1679639726": "Izhevsk",
    "1686293227": "Moscow",
    "1711559378": "Novy Urengoy",
    "2095029787": "Voskresenk",
    "2893369031": "Magadan",
    "26878551": "Kandalaksha",
    "26898768": "Kalevala",
    "26898769": "Kirovsk",
    "26898825": "Segezha",
    "26898826": "Zelenoborskij",
    "26952234": "Kargopol",
    "27023177": "Luga",
    "27464354": "Borisoglebskiy",
    "27464486": "Rostov",
    "29866526": "Yeysk",
    "34390960": "Vysotsk",
    "34395335": "Beslan",
    "34395341": "Magas",
    "34398261": "Krasnaya Polyana",
    "60078445": "Roslavl'",
    "60079145": "Tosno",
    "60237699": "Selenogradsk",
    "60238036": "Mamonovo",
    "60238387": "Sovetsk",
    "60238585": "Polessk",
    "60405981": "Torzhok",
    "76752092": "Porkhov",
    "82739207": "Chaykovsky",
    "90045540": "Medyn",
    "92383389": "Uglich",
    "92774502": "Myshkin",
    "93701882": "Baksan",
    "94091844": "Selizharovo",
    "94102909": "Ostashkov",
    "130201149": "Novozybkov",
    "153568874": "Yelabuga",
    "174842795": "Ishim",
    "176957217": "Salekhard",
    "176960743": "Labytnangi",
    "178108820": "Nizhnyaya Tavda",
    "178926370": "Tayga",
    "186985543": "Krivosheino",
    "187010245": "Kolpashevo",
    "191636706": "Горно-Алтайск",
    "191736988": "Anadyr",
    "191907831": "Novotroitsk",
    "191909841": "Uhta",
    "204815902": "Dudinka",
    "204816649": "Tura",
    "205159518": "Yurga",
    "205430993": "Yaya",
    "205437775": "Mariinsk",
    "206131650": "Tavda",
    "206809742": "Kargasok",
    "210111403": "Strezhevoy",
    "210305929": "Ilansky",
    "210323639": "Tayshet",
    "216890905": "Osinniki",
    "217317194": "Kingisepp",
    "217323851": "Ivangorod",
    "221109841": "Kaltan",
    "221129318": "Mundybash",
    "221140463": "Temirtau",
    "221143207": "Kaz",
    "221173014": "Tashtagol",
    "221181091": "Sheregesh",
    "221214205": "Myski",
    "243083247": "Nizhneudinsk",
    "243329007": "Tulun",
    "243347526": "Zaigrayevo",
    "243349531": "Petrovsk-Zabaykal'skiy",
    "243355185": "Khilok",
    "243527503": "Karymskoe",
    "243634984": "Shilka",
    "243695003": "Chernyshevsk",
    "243836016": "Shadrinsk",
    "243991807": "Borovskiy",
    "243991814": "Yalutorovsk",
    "243991822": "Zavodoukovsk",
    "243993519": "Abatskiy",
    "243995008": "Omutinsky",
    "244078631": "Sretensk",
    "244529460": "Teguldet",
    "246665853": "Ust'-Karsk",
    "246669195": "Bogotol",
    "246678899": "Kirovsk",
    "246830274": "Ostrov",
    "251071107": "Agidel",
    "251072970": "Dyurtyuli",
    "251532339": "Yuryuzan'",
    "253258948": "Mezhgor'ye",
    "253737355": "Akhtubinsk",
    "253936017": "Палласовка",
    "254587738": "Ust-Ilimsk",
    "254862887": "Totma",
    "256361865": "Ruza",
    "256827434": "Shar'ya",
    "256827443": "Kotelnich",
    "256827452": "Omutninsk",
    "264358670": "Yaransk",
    "264365304": "Sovetsk",
    "270529337": "Zheleznovodsk",
    "270531692": "Mineralnye Vody",
    "270875270": "Gubakha",
    "271283522": "Tarusa",
    "271954653": "Chebarkul'",
    "272601866": "Kungur",
    "272845466": "Nikel",
    "274749672": "Suksun",
    "275772536": "Shelekhov",
    "275804020": "Sosnovoborsk",
    "275804138": "Borodino",
    "275804147": "Uyar",
    "275805295": "Lesosibirsk",
    "275805315": "Yeniseysk",
    "275806099": "Nazarovo",
    "275807520": "Divnogorsk",
    "275808256": "Minusinsk",
    "275808498": "Sayanogorsk",
    "276092539": "Uzhur",
    "276092580": "Alzamay",
    "276092773": "Sayansk",
    "276092774": "Zima",
    "276092781": "Zalari",
    "276092950": "Cheremkhovo",
    "276092975": "Svirsk",
    "276092977": "Usolye-Sibirskoye",
    "276113713": "Zabaykalsky",
    "276627958": "Belomorsk",
    "276646949": "Danilov",
    "276994866": "Bikin",
    "277057968": "Dalnerechensk",
    "277060596": "Vyazemskiy",
    "277118590": "Svobodny",
    "277139530": "Shimanovsk",
    "277573669": "Yerofey Pavlovich",
    "277576138": "Amazar",
    "277576357": "Mogocha",
    "277576558": "Kluchevsky",
    "277750915": "Belovo",
    "278023046": "Tatarsk",
    "278211683": "Yugorsk",
    "278216664": "Sovetskiy",
    "278298564": "Nerchinsk",
    "278304667": "Bukachacha",
    "278380012": "Zeya",
    "278991732": "Ordynskoye",
    "278992054": "Kamen'-na-Obi",
    "279240513": "Krasnogorskiy",
    "279978754": "Ust-Kut",
    "279980750": "Severobaykal'sk",
    "279982847": "Babushkin",
    "279983984": "Gusinoozërsk",
    "279985581": "Kyakhta",
    "279985631": "Zakamensk",
    "279987689": "Tynda",
    "279997610": "Taksimo",
    "280067854": "Chul'man",
    "280078404": "Tommot",
    "280109123": "Amga",
    "280182070": "Susuman",
    "280183184": "Ust'-Nera",
    "280352878": "Kochki",
    "280404821": "Ust'-Koksa",
    "280513195": "Shebalino",
    "280513628": "Mayma",
    "280515128": "Kosh-Agach",
    "280521185": "Ust-Kan",
    "280548716": "Belokurikha",
    "280550570": "Turochak",
    "280822642": "Borisoglebsk",
    "281293974": "Chemal",
    "281427282": "Suzun",
    "281428908": "Novoaltaysk",
    "281431381": "Talmenka",
    "281431605": "Iskitim",
    "281431826": "Barabinsk",
    "281433268": "Kargat",
    "281433500": "Chulym",
    "282596576": "Nizhny Bestyakh",
    "282635858": "Demyansk",
    "282914618": "Karasuk",
    "282914871": "Slavgorod",
    "283588621": "Skovorodino",
    "285340786": "Bograd",
    "285360076": "Shira",
    "285365947": "Kop'yevo",
    "285734826": "Krasnovishersk",
    "285745298": "Aleksandrovsk",
    "285746241": "Kizel",
    "285889059": "Chernogorsk",
    "286084866": "Ak-Dovurak",
    "286085202": "Chadan",
    "286085444": "Shagonar",
    "286085489": "Turan",
    "286130437": "Toora-Khem",
    "286200781": "Saryg-Sep",
    "286203959": "Kyzyl-Mazhalyk",
    "286214595": "Abaza",
    "286523928": "Troitsk",
    "286560902": "Kholmsk",
    "286923453": "Aban",
    "286953863": "Uvat",
    "286982957": "Kalach-na-Donu",
    "287005365": "Novouzensk",
    "287039558": "Salsk",
    "287040070": "Zimovniki",
    "287337481": "Nadym",
    "287387383": "Karachayevsk",
    "287411141": "Prokhladny",
    "287516647": "Alagir",
    "287660963": "Kaspiysk",
    "287661153": "Izberbash",
    "287662481": "Kasumkent",
    "287670803": "Buynaksk",
    "287673747": "Dagestanskiye Ogni",
    "287686736": "Kizilyurt",
    "287687179": "Yuzhno-Sukhokumsk",
    "287702846": "Argun",
    "287703730": "Gudermes",
    "287704409": "Shali",
    "287756202": "Pugachyov",
    "287798315": "Karabulak",
    "287798582": "Malgobek",
    "288419752": "Kuznetsk",
    "288476845": "Kyshtym",
    "288795864": "Yarovoye",
    "289004179": "Yugo-Kamsky",
    "289011795": "Osa",
    "289464809": "Krivodanovka",
    "289835271": "Choya",
    "289843365": "Osa",
    "289980364": "Kalachinsk",
    "289992321": "Gorkovskoye",
    "289994080": "Odesskoye",
    "289996348": "Russkaya Polyana",
    "290215681": "Zdvinsk",
    "290330613": "Bachatskiy",
    "290781841": "Turukhansk",
    "290884076": "Naushki",
    "290885503": "Novoselenginsk",
    "290889369": "Kabansk",
    "291420659": "Mogzon",
    "291558905": "Balakhta",
    "291779448": "Aleysk",
    "291818590": "Yeltsovka",
    "291860771": "Maslyanino",
    "292226474": "Zaozyorsk",
    "292231968": "Gadzhiyevo",
    "292235034": "Polyarny",
    "292879578": "Diveevo",
    "292885144": "Snezhinsk",
    "293459492": "Kola",
    "295072813": "Vershino-Darasunskiy",
    "295119416": "Tyazhinskiy",
    "295862317": "Buinsk",
    "295864677": "Chistopol",
    "295896340": "Shuya",
    "295902357": "Yermakovskoye",
    "295939729": "Shushenskoye",
    "295945680": "Cherëmushki",
    "295952416": "Kuragino",
    "296081305": "Nolinsk",
    "296086848": "Nema",
    "296087893": "Kilmez",
    "296100014": "Malmyzh",
    "296113691": "Urzhum",
    "296130162": "Uva",
    "296134100": "Yadrin",
    "296138675": "Tyukalinsk",
    "296379510": "Ilsky",
    "296410521": "Chernushka",
    "297513007": "Ulagan",
    "298352406": "Apsheronsk",
    "300582728": "Novaya Igirma",
    "300588087": "Khrebtovaya",
    "300594850": "Shestakovo",
    "300612214": "Zheleznogorsk-Ilimsky",
    "300659754": "Kodinsk",
    "301246150": "Krasnoznamensk",
    "301636452": "Kirensk",
    "301639437": "Lensk",
    "301645168": "Bodaybo",
    "301648440": "Ribnoe",
    "301710229": "Mamakan",
    "301761027": "Mirny",
    "301770161": "Udachny",
    "301775279": "Nyurba",
    "301778109": "Vilyuysk",
    "301789452": "Aykhal",
    "301822109": "Berdigestyakh",
    "301959493": "Olëkminsk",
    "302019058": "Turuntayevo",
    "302024328": "Gremyachinsk",
    "302036717": "Kachug",
    "302066783": "Suntar",
    "302372139": "Mama",
    "302524562": "Nizhneangarsk",
    "302534600": "Bichura",
    "302605557": "Verkhoyansk",
    "302830616": "Mayya",
    "303015164": "Umba",
    "303019430": "Revda",
    "303353860": "Polarnie Zori",
    "303973327": "Sapoljary",
    "304337561": "Ozersk",
    "304552309": "Chokurdakh",
    "304605480": "Raduzhny",
    "304663037": "Imeni Poliny Osipenko",
    "304721209": "Valuyki",
    "306457009": "Krasnoturyinsk",
    "306457427": "Karpinsk",
    "306458157": "Volchansk",
    "307089440": "Uray",
    "307964759": "Ugleural'skiy",
    "308059641": "Lesogorsk",
    "308220097": "Lysva",
    "308259963": "Shigony",
    "308877563": "Dombarovskiy",
    "308879014": "Svetly",
    "308897102": "Otradny",
    "309493556": "Kem",
    "310831029": "Mortka",
    "310877933": "Irbit",
    "310912076": "Kirishi",
    "313570754": "Inza",
    "314794893": "Gus'-Khrustalnyy",
    "316492711": "Mikhaylovsky",
    "321203443": "Etkul",
    "330235003": "Khatanga",
    "330402488": "Babayevo",
    "330421322": "Novy Urgal",
    "330823637": "Khor",
    "330842003": "Sosnogorsk",
    "334371762": "Kransousolsky",
    "335884516": "Igarka",
    "335978456": "Krasnoborsk",
    "335978473": "Кorjazhma",
    "335978476": "Solvychegodsk",
    "336140198": "Rasskazovo",
    "336140373": "Usman",
    "336140969": "Oktyabr'skiy",
    "336526404": "Russkiy Kameshkir",
    "336526550": "Novosemeykino",
    "337839371": "Sergievsk",
    "339064480": "Zavolzhie",
    "339961651": "Bagayevsky",
    "339980655": "Ust'-Donetskiy",
    "340961881": "Zauralskiy",
    "340962185": "Emanzhelinsk",
    "342402547": "Novorzhev",
    "346802464": "Ilek",
    "352776308": "Pokachi",
    "353177210": "Chkalovsk",
    "355144185": "Korkino",
    "356772503": "Chertkovo",
    "360837530": "Kyren",
    "361024504": "Urengoy",
    "361366039": "Georgiyevsk",
    "364775751": "Chermoz",
    "409086394": "st.Askiz",
    "411691832": "Lomonosov",
    "413805673": "Ust'-Maya",
    "417406659": "Bilimbay",
    "417625373": "Klimovo",
    "425219949": "Kurchatov",
    "425834128": "Olovyannaya",
    "436918452": "Khandyga",
    "438263230": "Revda",
    "439191804": "Arti",
    "439555704": "Mikhaylovsk",
    "440614116": "Zyryanka",
    "441108611": "Chunskiy",
    "442301557": "Olenyok",
    "443694980": "Novaya Chara",
    "444271257": "Batagay",
    "447905020": "Saskylakh",
    "447923120": "Zhilinda",
    "450854343": "Karatuzskoye",
    "451604149": "Idrinskoye",
    "456844533": "Plyos",
    "457342402": "Samagaltay",
    "457351057": "Khovu-Aksy",
    "457351426": "Erzin",
    "457354065": "Kaa-Khem",
    "457453957": "Tashtyp",
    "457497582": "Belyy Yar",
    "457505136": "Beya",
    "457507984": "Ust'-Abakan",
    "457894655": "Tazovsky",
    "457970089": "Krasnosel'kup",
    "460482858": "Sherlovaya Gora",
    "461742306": "Петропавловка",
    "461810345": "Kichera",
    "461812470": "Novy Uoyan",
    "462126714": "Barguzin",
    "462129412": "Kurumkan",
    "462164030": "Sosnovo-Ozerskoye",
    "462167682": "Kizhinga",
    "462202929": "Ivolginsk",
    "463166446": "Taseyevo",
    "465238192": "Kazachinskoye",
    "467758650": "Ol'ga",
    "467895102": "Poyarkovo",
    "468060289": "Arkhara",
    "470940629": "Tisul'",
    "471259093": "Baley",
    "472574443": "Kavalerovo",
    "472597489": "Dalnegorsk",
    "474301764": "Strelka",
    "475538643": "Novoselovo",
    "478808135": "Slavsk",
    "493613381": "Novopavlovsk",
    "494895608": "Antropovo",
    "494895622": "Parfen'evo",
    "512485280": "Mil'kovo",
    "517658001": "Tara",
    "517682881": "Kolosovka",
    "518743376": "Amursk",
    "524331733": "Bol'sherech'ye",
    "587659863": "Tovarkovo",
    "601116310": "Pervomaiskii",
    "612380967": "Nizhnyaya Poyma",
    "628649194": "Peno",
    "653117001": "Belyy-Jar",
    "654834896": "Barsovo",
    "699741885": "Verkhnemarkovo",
    "703166491": "Apastovo",
    "764019333": "Kurovskoye",
    "766484437": "Železnodorožnyj",
    "767068249": "Pravdinsk",
    "795838932": "Sneschnogorsk",
    "821386596": "Kommunisticheskiy",
    "833665925": "Anzhero-Sudzhensk",
    "849853637": "Seredeiskiy",
    "884345171": "Dedovichi",
    "892808209": "Belidji",
    "892808214": "Magaramkent",
    "892835005": "Kayakent",
    "904016707": "Kumukh",
    "904026153": "Levashi",
    "911587161": "Bejta",
    "911959278": "Severnoye",
    "914387646": "Nesterov",
    "935566173": "Yashkul",
    "943663644": "Beryozovka",
    "975940834": "Semender",
    "976862420": "Vostochniy",
    "1009135181": "Vyborg",
    "1010179630": "Leninsk",
    "1028723760": "Nikolaevka",
    "1028723768": "Yerbogachen",
    "1028723907": "Priargunsk",
    "1028724277": "Okhotsk",
    "1067034190": "Askiz",
    "1076441937": "Spassk-Dalniy",
    "1077657916": "Leninskiy",
    "1201358624": "Bureya",
    "1205639127": "Jireken",
    "1205649761": "Ksenyevka",
    "1214516212": "Muravlenko",
    "1252364676": "Zarinsk",
    "1282776137": "Koltsovo",
    "1345529992": "Yangel",
    "1348895093": "Zelenogorsk",
    "1372682490": "Irbeyskoe",
    "1404369898": "Pleseck",
    "1416600659": "Beloyarsky",
    "1420696955": "Gusev",
    "1430774947": "Nyagan",
    "1480803930": "Mezhevoy",
    "1515028708": "Gornyak",
    "1555616147": "Myatlevo",
    "1590340571": "Znamensk",
    "1592488757": "Pochep",
    "1607065754": "Beryozovo",
    "1625195915": "Neryungri",
    "1627107363": "Olenegorsk",
    "1641900014": "Mukhor-Shibir'",
    "1645221793": "Kropotkin",
    "1665035561": "Tarbagatay",
    "1798809766": "Protvino",
    "1805732865": "Belebei",
    "1828409507": "Medvezhyegorsk",
    "1838482543": "Okoneshnikovo",
    "1839536171": "Lahdenpohja",
    "1903971517": "Magdagachi",
    "1908737047": "Suzdal'",
    "2071063097": "Aldan",
    "2078883019": "Kostomuksha",
    "2127195931": "Nartkala",
    "2173162616": "Vanino",
    "2288716213": "Alatyr",
    "2291707774": "Chudovo",
    "2300530228": "Myaundzha",
    "2414002280": "Tiksi",
    "2559814235": "Nizhniy Kuranakh",
    "3030450878": "Onguday",
    "60571493": "Beograd",
    "432424985": "Georgia",
    "424313803": "Czech Republic",
    "424316417": "Moldova",
    "249399300": "Belarus",
    "1648342273": "Ukraine",
    "838090640": "United Kingdom",
    "1683325355": "Germany",
    "424315584": "Australia",
    "424317935": "United States of America",
    "424298326": "Turkey",
    "424317428": "Uzbekistan",
    "424314276": "Tajikistan",
    "249399440": "Turkmenistan",
    "424314145": "India",
    "424314088": "Pakistan",
    "249399419": "Saudi Arabia",
    "424310703": "United Arab Emirates",
    "26347360": "Italy",
    "424313451": "Japan",
    "424313760": "Canada",
    "424297647": "Israel",
    "437146363": "Thailand",
    "1363947712": "France",
    "26847709": "Austria",
    "466507355": "Abkhazia",
    "432425060": "Poland",
    "1504546320": "Switzerland",
    "1684793666": "Belgium",
    "424310601": "Albania",
    "432424981": "Finland",
    "432425019": "Latvia",
    "424297773": "Lithuania",
    "424313832": "Estonia",
    "432425064": "Romania",
    "432425040": "Mongolia",
    "432424937": "Bangladesh",
    "432424989": "Greece",
    "435981993": "Democratic Republic of the Congo",
    "424313903": "Kenya",
    "424311353": "Croatia",
    "249399280": "Argentina",
    "249399406": "Peru",
    "26847706": "Afghanistan",
    "424298311": "Iran",
    "249399459": "Yemen",
    "432425062": "Qatar",
    "26036352": "Philippines",
    "424313428": "Singapore",
    "248120384": "New Zealand",
    "424298167": "South Africa",
    "148332300": "Spain",
    "2134362766": "Malaysia",
    "424297217": "Netherlands"
}

export default Locations;