const SET_SEARCH_TEXT = "SET-SEARCH-TEXT";
const SET_SEARCH_LIST = "SET-SEARCH-LIST";
const SET_SAVED_SEARCH_LIST = "ADD-SAVED-SEARCH-LIST"
const REMOVE_FROM_LIST = "REMOVE-FROM-LIST";
const CLEAR_SEARCH_LIST = "CLEAR-SEARCH-LIST";
const SET_PARENT_PARENT = "SET-PARENT-PARENT";
const SET_PARENT = "SET-PARENT";

const search = {
    searchText: '',
    searchList: {},
    parentParent: null,
    parent: null
}

const SearchText = (state = search, action) => {
    switch(action.type){
        case SET_SEARCH_TEXT:
            return {...state, searchText: action.searchList}
        case SET_SEARCH_LIST:
            let search = {...state.searchList}
            let searchItem = action.searchText
            if(action.parentParentText){
                if(action.parentText){    
                    if(!search[action.parentParentText][action.parentText][action.searchText]){
                        search[action.parentParentText][action.parentText][action.searchText] = action.searchText
                    }
                }
                else{
                    if(!search[action.parentParentText][searchItem]) search[action.parentParentText][searchItem] = {}
                }
            }
            else{
                if(!search[searchItem]) search[searchItem] = {}
            }
            return {...state, searchList: search, searchText: action.searchText}
        case SET_SAVED_SEARCH_LIST:
            return {...state, searchList: action.searchList}
        case REMOVE_FROM_LIST:
            let searchText = {...state.searchList}
            let searchTextItem = action.searchText
            if(action.parentParentText && action.parentText && action.searchText){
                delete searchText[action.parentParentText][action.parentText][action.searchText];
            }
            else if(action.parentParentText && action.parentText && !action.searchText){
                delete searchText[action.parentParentText][action.parentText]
            }
            else if(action.parentParentText && !action.parentText && !action.searchText){
                delete searchText[action.parentParentText]
            }
            else{
                searchText[searchTextItem] = {}
            }
           
            return {...state, searchList: searchText, searchText: action.searchText}
        case SET_PARENT_PARENT:
            return {...state, parentParent: action.parentParent}
        case SET_PARENT:
            return {...state, parent: action.parent}
        case CLEAR_SEARCH_LIST:
            return {...state, searchList: null, searchText: '', parentParent: null, parent: null}
        default:
            return state
    }
}

export default SearchText;

export const setSavedSearchList = (searchList) => {
    return {
        type: SET_SAVED_SEARCH_LIST,
        searchList: searchList
    }
}

export const setSearchText = (searchList) => {
    return {
        type: SET_SEARCH_TEXT,
        searchList: searchList
    }
}

export const setParentParent = (parentParent) => {
    return {
        type: SET_PARENT_PARENT,
        parentParent: parentParent
    }
}

export const setParent = (parent) => {
    return {
        type: SET_PARENT,
        parent: parent
    }
}


export const clearSearchList = () => {
    return {
        type: CLEAR_SEARCH_LIST
    }
}

export const removeFromSearchList = (parentParentText = null, parentText = null, searchText, key = null) => {
    return {
        type: REMOVE_FROM_LIST,
        searchText: searchText,
        parentParentText: parentParentText,
        parentText: parentText,
        key: key
    }
}

export const setSearchList = (searchText, parentParentText = null, parentText = null, key = null) => {
    return {
        type: SET_SEARCH_LIST,
        searchText: searchText,
        parentParentText: parentParentText,
        parentText: parentText,
        key: key
    }
}