import FavouriteListController from "./favourite-list-controller";
import { connect } from "react-redux";
import { getValue } from "../../store/languages";
import { getFavouriteList } from "../../store/favourite-list"
import { getTopicName, getSubtopicsDropdownFromState} from "../../selectors/theme-selector";
import { getMyTopics, setThemeTableData } from "../../store/theme-settings";
import { getFavoriteList } from "../../selectors/favourite-list-selector";
import { RemoveFavouriteResourceProfile } from "../../store/topic-item";
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        topics: getTopicName(state),
        selectedTopics: getSelectedTopicsUUID(state),
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        favourites: getFavoriteList(state),
        tableHeader: state.FavouriteList.tableHeader,
        isUpdated: state.FavouriteList.isUpdated,
        isFetching: state.FavouriteList.isFetching
    }
}

export default connect(mapStateToProps, {getValue, getFavouriteList, getMyTopics, RemoveFavouriteResourceProfile})(FavouriteListController)