import { setlocale } from "store/languages";
import { header, getLanguage } from "./header";

const migration = header('storage/api/v1/migration-operations');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    migration.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    migration.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const MigrationService = {
    getMigrationOperations(pageIndex = 0, pageSize = 20){
        setLanguage();
        getAuth();
        return migration.get(`?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(response => {
            return response.data
        })
    },
    getMigrationOperation(operationId){
        setLanguage();
        getAuth();
        return migration.get(`/${operationId}/`).then(response => {
            return response.data
        })
    },
    getMigrationFoundNumber(topicUUID, type, collection, topic, filter){
        setLanguage();
        getAuth();
        //let params = {...filters, ...topic}
        return migration.post(`/topics/${topicUUID}/numfound?type=${type}&collection=${collection}`, {topic, filter}).then(response => {
            return response.data
        })
    },
    getNumFound(){
        setLanguage();
        getAuth();
        return migration.get(`/${operationId}`).then(response => {
            return response.data
        })
    },
    startMigration(topicUUID, type, collection, topic, filter){
        setLanguage();
        getAuth();
        //let params = {...filters, ...topic}
        return migration.post(`/topics/${topicUUID}?type=${type}&collection=${collection}`, {topic, filter}).then(response => {
            return response.data
        })
    },
    pauseMigration(operationId){
        setLanguage();
        getAuth();
        return migration.post(`/${operationId}/pause`).then( response => {
            return response.data
        })
    },
    resumeMigration(operationId){
        setLanguage();
        getAuth();
        return migration.post(`/${operationId}/resume`).then( response => {
            return response.data
        })
    },
    retryMigration(operationId){
        setLanguage();
        getAuth();
        return migration.post(`/${operationId}/retry`).then( response => {
            return response.data
        })
    }
}