import React from "react";
import Filters from "./filters";
import moment from 'moment';
import { getFormatedDate } from "service/format-date";

class FiltersController extends React.Component{
    
    getFiltersFields = (filters, pageName, role) =>{ 
        return filters ? 
                        filters.filter(f => {  
                                            return (pageName === 'RecycleBin' && f.name === 'VERIFICATION_STATUS' || role === 'ROLE_MARKER' && f.name === 'TAGS') 
                                                    ? null 
                                                    : f
                                                        }).map(k => k.list === 'mediaType' 
                                                                                        ? 'fields=media_type' : 'fields='+k.list) 
                                                                                        : []
    }

    constructor(props){
        super(props);
        this.searchList = this.props.searchList
        this.state = {
            keywords: (this.props.topics && this.props.topics.keywords) && this.props.topics.keywords.split(',').map(k => {return {name: k}}),
            filtersFields: this.getFiltersFields(this.props.filters, this.props.pageName, this.props.role),
            selectedSubtopics: this.props.selectedSubtopics.join(","),
            startDate: props.startDate,
            endDate: props.endDate,
            pageName: props.type,
            topics: (props.selectedMyFilter && props.selectedMyFilter.topics) ? props.selectedMyFilter.topics.join(',') : '',
            selectedTopics: '',
            page: props.type,
            searchListChanged: false
        }
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date)
    }

    checkDate = () => {
        if(this.state.startDate !== this.props.startDate || this.state.endDate !== this.props.endDate){
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate
            })
            return true;
            
        }
    }

    componentDidMount(){
        if(this.props.companyId && this.props.role !== "ROLE_MARKER") {
            this.props.getCompanyTags(this.props.companyId);
        }
        const search = window.location.search;
        const params = new URLSearchParams(search);
        if(params.get('language')){
            this.props.setAddSelectedMyFilter('language', params.get('language'))
        }
    }

    getSelectedTopicsByPage = (page) => {
        //if(this.props.type && this.props.type !== 'Documents'){
            return this.props.selectedTopics
        //}
        /*else if(page && page === 'Comparison'){
            let s = this.props.selectedTopicsItems ? Object.keys(this.props.selectedTopicsItems).map(k => k) : []
            return s
        }
        else{
            return this.props.selectedUUId;
        }*/
    }

    getFilters = () => {

    }

    componentDidUpdate(){
        if(this.props.clearAll){
            this.props.setClearSelected(false)
        }
        /*if(this.state.selectedUUId !== this.props.selectedUUId){
            this.searchList = []
        }*/
        if( 
            this.checkDate() 
            || this.props.type !== this.state.pageName 
            || this.props.selectedTopics.join(',') !== this.state.selectedTopics 
            || this.props.type !== this.state.page 
            || this.props.isChanged 
            || this.props.itemsListChanged //for  show filter updates
            || this.state.selectedSubtopics !== this.props.selectedSubtopics.join(",")
            || this.props.searchList !== this.searchList){
            let ft = this.getFiltersFields(this.props.filters, this.props.pageName, this.props.role)
            this.searchList = this.props.searchList
            if(this.searchList && Object.keys(this.searchList).length !== 0){
                ft.clauses = this.searchList
            }
            else{
                ft.clauses = []
            }
            let myFilters = this.props.selectedMyFilter;
            /*if(this.state.selectedUUId !== this.props.selectedUUId){
                this.props.setClearSelected(true)
                myFilters = []
            }*/
           
            let subtopics = this.props.selectedSubUUId ? [this.props.selectedSubUUId] : [];

            if(this.props.selectedSubtopics && this.props.selectedSubtopics.length){
                subtopics = this.props.selectedSubtopics
            }
            let t = this.getSelectedTopicsByPage(this.props.pageName)//(this.props.type && this.props.type !== 'Documents') ? this.props.selectedTopics : this.props.selectedUUId;
            //let subtopics = this.props.selectedSubUUId ? {subtopicIds: [this.props.selectedSubUUId]} : []
            let filters = {...myFilters}
            if(t && t.length) this.props.getFiltersList(this.props.startDate, this.props.endDate, t, ft, this.props.selectedSubtopics, filters)
            if(this.props.companyId && this.props.role !== "ROLE_MARKER") {
                this.props.getCompanyTags(this.props.companyId);
            }
            if(this.props.itemsListChanged ) this.props.setFiltersItesListChanged(false)
            this.setState({
                pageName: this.props.type,
                filtersFields: ft,
                topics: (this.props.selectedMyFilter && this.props.selectedMyFilter.selectedTopics) ? this.props.selectedMyFilter.topics.join(',') : '',
                selectedTopics: this.props.selectedTopics.join(','),
                selectedSubtopics: this.props.selectedSubtopics.join(","),
                page: this.props.type,
            })
            
        }
    }

    setFiltersItemsSelected = (items, indexKey) => {
        /*let result = this.props.selectedMyFilter.filter(k => k.indexKey !== indexKey);
        let filterItems = items.map(k => {
            return this.props.topicFilters[indexKey].items[k-1].list
        })

        filterItems.length && result.push({indexKey: indexKey, items: items, itemsName: filterItems, indexName: this.props.topicFilters[indexKey].name, indexList: this.props.topicFilters[indexKey].list});*/
        //this.props.setAddSelectedMyFilter(result);
    }

    setFiltersItemsItemsSelected = (items, itemIndex, kIndex) => {
        
        let result = this.props.selectedItemItems.filter(k => k.itemIndex !== itemIndex)
        let filterItems = items.map(k => {
            return this.props[this.props.topicFilters[kIndex].list][itemIndex].items[k-1].list
        })
        result.push({itemIndex: itemIndex, items: items, itemsName: filterItems, indexName: this.props.filters[kIndex].list});
        this.props.setFiltersItemItemsSelected(result)
    }

    clearFilters = () => {
        this.props.setFiltersItemsSelected([]);
        this.props.clearSearchList();
        this.props.setClearSelected(true);
        setTimeout(() => {this.props.setIsFilterChanged(true, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.selectedTopics, this.state.filtersFields, [...[], []])}, 300) 
    }

    filtersIsChanged = (isChanged) => {
        this.props.setIsFilterChanged(isChanged, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.selectedTopics, this.state.filtersFields, [this.props.selectedSubtopics]);
    }

    render(){
        return <Filters {...this.props} keywords={this.state.keywords} setFiltersItemsSelected={this.setFiltersItemsSelected} setFiltersItemsItemsSelected={this.setFiltersItemsItemsSelected} filtersIsChanged={this.filtersIsChanged} clearFilters={this.clearFilters}/>
    }

}

export default FiltersController;