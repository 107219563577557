import MyFiltersController from "./my-filters-controller";
import { connect } from "react-redux";
import { getValue } from "../../store/languages";
import { modalShowToggle } from "../../store/modal";
import { getAllFilters, getMyFilter, setSelectedMyFilterMenu } from "../../store/filters"

let mapStateToProps = (state) => {
    return {
        myFilters: state.Filters.myFilters,
        selectedMyFilter: state.Filters.selectedMyFilter,
        selectedMyFiltersMenu: state.Filters.selectedMyFiltersMenu
    }
}

export default connect(mapStateToProps, {getValue, modalShowToggle, getAllFilters, getMyFilter, setSelectedMyFilterMenu})(MyFiltersController)