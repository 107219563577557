import EditUserController from "./edit-user-contoller";
import { connect } from "react-redux";
import { getValue } from "../../../../../store/languages";
import { changeUserPassword } from "../../../../../store/users-settings"

let mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, {getValue, changeUserPassword})(EditUserController)