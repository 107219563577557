import React from "react";
import TelegramChat from "./telegram-chat";

class TelegramChatController extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            subscriptionId: this.props.subscriptionId,
            subscriptionChats: this.props.subscriptionChats && this.props.subscriptionChats.length ? this.props.subscriptionChats.join(",") : "",
            subscriptionChatsId: this.props.subscriptionChatsId && this.props.subscriptionChatsId.length ? this.props.subscriptionChatsId.join(",") : "",
            showOnly: this.props.showOnly
        }
    }

    componentDidMount(){
        if(this.props.subscriptionId && !isNaN(this.props.subscriptionId)){
            this.props.getSubscriptionChats(this.props.subscriptionId)
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.subscriptionId && nextProps.subscriptionId !== nextState.subscriptionId || nextState.subscriptionChatsId !== nextProps.subscriptionChatsId.join(",") || nextProps.showOnly !== nextState.showOnly){
            this.props.getSubscriptionChats(nextProps.subscriptionId)
            this.setState({
                subscriptionId: nextProps.subscriptionId,
                subscriptionChatsId: nextProps.subscriptionChatsId && nextProps.subscriptionChatsId.length ? nextProps.subscriptionChatsId.join(",") : '',
                showOnly: nextProps.showOnly
            })
            return true
        }
        else return false
    }

    render(){
        return <TelegramChat 
                            getValue={this.props.getValue} 
                            subscriptionId={this.props.subscriptionId} 
                            subscriptionChats={this.props.subscriptionChats} 
                            subscriptionChatsId={this.props.subscriptionChatsId}
                            deleteSubscriptionChat={this.props.deleteSubscriptionChat}
                            modalShowToggle={this.props.modalShowToggle}
                            showOnly={this.props.showOnly}
                            />
    }
}

export default TelegramChatController;