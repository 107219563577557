import React from "react";
import AdminMarkerAddItem from "./admin-markers-add-item";

class AdminMarkerAddItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            markerId: this.props.markerId
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }


    componentDidMount(){
        if(this.props.markerId){
            this.props.getMarkerTopics(this.props.markerId)
        }
    }
    
    componentDidUpdate(){
        if(this.props.markerId !== this.state.markerId){
            this.props.getMarkerTopics(this.props.markerId)
            this.setState({
                markerId: this.props.markerId
            })
        }
    }

    render(){
        return <AdminMarkerAddItem {...this.props} closeRightMenu={this.closeRightMenu}/>
    }
}

export default AdminMarkerAddItemController;