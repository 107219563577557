import React, { useState, useEffect} from "react";
import s from "./buttons-block.module.scss"
import { useLocation } from "react-router-dom";
import HeaderButton from "./header-button/header-button";
import DropdownController from "../../items/dropdown/dropdown-controller";
import { StandartButton } from "../../commons/form-controls";

import { ReactComponent as Sample } from '../../assets/icons/sample.svg';
import { ReactComponent as Exit } from '../../assets/icons/exit.svg';
import { ReactComponent as ExitCompany } from '../../assets/icons/from-company.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as CallUs } from '../../assets/icons/call-us.svg';
import { ReactComponent as ShortAnalytic } from '../../assets/icons/short-analytic.svg';
import { ReactComponent as DeployedAnalytic } from '../../assets/icons/deployed-analytic.svg';
import { ReactComponent as ComparisonTheme } from '../../assets/icons/comparison-theme.svg';
import { ReactComponent as ReporterConstructor } from '../../assets/icons/reporter-constructor.svg';
import { ReactComponent as AutoRules } from '../../assets/icons/auto-rules.svg';
import { ReactComponent as Admin } from '../../assets/icons/admin-settings.svg';
import { ReactComponent as AdminAccounts } from '../../assets/icons/admin-accounts.svg';
import { ReactComponent as AdminTopics } from '../../assets/icons/admin-topics.svg';
import { ReactComponent as AdminMarkers } from '../../assets/icons/edit.svg';
import { ReactComponent as AdminMigration } from 'assets/icons/migration.svg';
import { ReactComponent as TopicsSettings } from '../../assets/icons/topic-settings.svg';

import { ReactComponent as ChosenPublications } from '../../assets/icons/chosen-publications.svg';
import { ReactComponent as ChosenAuthors } from '../../assets/icons/chosen-authors.svg';
import { ReactComponent as BlackList } from '../../assets/icons/black-list.svg';
import { ReactComponent as RecycleBin } from '../../assets/icons/recycle-bin.svg';
import { ReactComponent as NOTIFICATIONS } from '../../assets/icons/bell.svg';


const ButtonsBlock = (props) => {

    const selected = sessionStorage.getItem('selectedTheme');
    const selectedLang = sessionStorage.getItem('selectedLanguage')
    const [selectedThemeMenu, setSelectedMenu] = useState(sessionStorage.getItem('selectedTheme'));
    const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('selectedLanguage'));
    const [role, setRole] = useState(props.role);

    useEffect(() => { 
        setSelectedMenu(selected);
        setSelectedLanguage(selectedLang)
        setRole(props.role);
    }, [selected, selectedLang, props.role])

    let componentsMap = {
        Sample,
        CallUs,
        Settings,
        Exit,
        ExitCompany,
        ShortAnalytic,
        DeployedAnalytic,
        ComparisonTheme,
        ReporterConstructor,
        AutoRules,
        ChosenPublications,
        ChosenAuthors,
        BlackList,
        RecycleBin,
        NOTIFICATIONS,
        Admin,
        AdminAccounts,
        AdminTopics,
        AdminMarkers,
        AdminMigration
    }

    let selectThemeMenu = (selectedTheme) => {
        //sessionStorage.setItem('selectedTheme', selectedTheme)
        //setSelectedMenu(selectedTheme)
        
    }


    let selectLanguageMenu = (selectedLanguage) => {
        sessionStorage.setItem('selectedLanguage', selectedLanguage)
        setSelectedMenu(selectedLanguage)
    }

    const setAccountPage = (type, item) => {
        buttonClick(item, 'modal')
    }

    let ThemeItems = props.themeItems ? props.themeItems.map(k => {return {name: k.name, short_name: k.short_name, icon: k.icon, title: props.getValue(k.title)}}) : [];
    let AccountItems = props.themeItems ? props.AccountItems.map(k => {return {name: k.name, short_name: k.short_name, icon: k.icon, title: props.getValue(k.title)}}) : [];
    let LanguageItems = props.LanguageItems;

    let dropdowns = {
        Themes: {
            items: ThemeItems,
            icon: 'icon',
            callback: props.themeCallback,
            selected: selectedThemeMenu
        },
        Languages: {
            items: LanguageItems,
            icon: props.languageIcon,
            callback: props.isAuth ? props.languageCallback : props.setSelectedLanguage,
            selected: props.selectedLanguage
        },
        Account: {
            items: AccountItems,
            icon: "icon",
            callback: setAccountPage,
            selected: "ACCOUNTPAGE"
        }
    }

    let buttonClick = (name, type, link, title, icon) => {
        props.setSelectedPage(name, type, link)
    }

    const getButtons = (buttons) => {

        let menuButtons = buttons && buttons.map((b,i ) => {
            if(!props.isAuth && b.name === 'Settings' || !props.isAuth && b.name === 'Exit' || !props.isAuth && b.name === 'ExitCompany')
                return;
            let btn = b.type !== 'dropdown' 
                ? b.type === 'modal' 
                    ? <HeaderButton 
                            onClick={buttonClick.bind(this, b.name, b.type, b.link, b.buttonsTitle, b.buttonsIcon)}
                            image={componentsMap[b.name]} 
                            selected={ props.selected }
                            name={b.name}
                            title={props.getValue(b.base_name)}
                            setClearSelected={props.setClearSelected}
                            pageName={props.pageName}
                            key={i}/>
                    : <HeaderButton 
                        onClick={buttonClick.bind(this, b.name, b.type, b.link)}
                        image={componentsMap[b.name]} 
                        selected={ props.selected }
                        name={b.name}
                        title={props.getValue(b.base_name)}
                        setClearSelected={props.setClearSelected}
                        link={b.link}
                        type={b.type}
                        pageName={props.pageName}
                        key={i}/>
                : <DropdownController
                    items={dropdowns[b.name].items}
                    image={componentsMap[selectedThemeMenu]}
                    type={b.name}
                    icon={dropdowns[b.name].icon}
                    selected={dropdowns[b.name].selected}
                    callback={dropdowns[b.name].callback}
                    setClearSelected={props.setClearSelected}
                    name={b.name}
                    short_name={b.short_name}
                    pageName={props.pageName}
                    key={i}
                    style={{left: -100}}
                />
            return btn;
        });
        
        return menuButtons
    }

    const getButtonsByRole = (role) => {
        
        switch(role){
            case 'ROLE_USER':
            case 'ROLE_ADMIN':
                return getButtons(props.buttons);
            case 'ROLE_SUPER_ADMIN':
                return getButtons(props.buttons);
            default:
                return getButtons(props.buttons);
        }
    }

    let buttonsElements = getButtonsByRole(role)

    return <div className={s.buttonsBlock}>{ buttonsElements }</div>
}

export default ButtonsBlock;