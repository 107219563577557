import TelegramChatController from "./telegram-chat-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getSubscriptionChats, deleteSubscriptionChat } from "store/telegram";
import { getSubscriptionChatsId } from "selectors/subscription"
import { modalShowToggle } from "store/modal";

let mapStateToProps = (state) => {

    return {
        subscriptionChats: state.telegram.subscriptionChats,
        subscriptionChatsId: getSubscriptionChatsId(state)
    }
}

const TelegramChatContainer =  connect(mapStateToProps, {getValue, getSubscriptionChats, deleteSubscriptionChat, modalShowToggle})(TelegramChatController)

export default TelegramChatContainer
