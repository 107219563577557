import SaveFiltersSettingsController from './save-filters-settings-controller';
import { createNewFilter, updateMyFilter } from '../../store/filters'
import { connect } from "react-redux";
import { getStartDate, getEndDate } from "../../selectors/calendar-selector";
import { modalShowToggle } from "../../store/modal";

let mapStateToProps = (state) => {
    return {
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        isChanged: state.Filters.isChanged,
        selectedItems: state.Filters.selectedMyFilter,
        selectedItemItems: state.Filters.selectedItemItems,
        myFilters: state.Filters.myFilters,
        searchList: state.SearchText.searchList,
    }
}

const SaveFiltersSettingsContainer = (SaveFiltersSettingsController);

export default connect(mapStateToProps, {createNewFilter, updateMyFilter, modalShowToggle})(SaveFiltersSettingsContainer)