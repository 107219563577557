import { TopicService } from "../connection/topics-service";
import { AdminService } from "connection/admin-service";
import { MarkerService } from "connection/marker-service";
import { setNotification, checkErrorMessage, getErrorMessage } from "./errors";

const SET_SELECTED_THEME_TABLE_SORT = "SET-SELECTED-THEME-TABLE-SORT";
const SET_INDEX_SORT = "SET-INDEX-SORT";
const SET_CREADET_DATE_SORT = "SET-CREADET-DATE-SORT";
const SET_EDITED_DATE_SORT = "SET-EDITED-DATE-SORT";
const SET_NAME_SORT = "SET-NAME-SORT";
const SET_TABLE_ITEM_COMPONENT = "SET-TABLE-ITEM-COMPONENT";
const SET_THEME_TABLE_DATA = "SET-THEME-TABLE-DATA";
const SET_ERROR_IN_ACTION = "SET-ERROR-IN-ACTION";
const SET_EDIT_TYPE = "SET-EDIT-TYPE";
const SET_UPDATETD_DATA = "SET_UPDATETD_DATA";
const SET_UPDATED_TOPIC = "SET-UPDATED-TOPIC";

const ThemeData = {
    labels: [
        {
            name: 'dropdown',
            title: 'ALPHABETIC',
            type: 'index',
            items: [
                {
                    title: 'ALPHABETICALLY_A-Z_↓',
                    name: 'ALPHABETIC ASC',
                    short_name: 'ALPHABETIC DESC'
                },
                {
                    title: 'ALPHABETICALLY_Z-A_↑',
                    name: 'ALPHABETIC ASC',
                    short_name: 'ALPHABETIC ASC'
                }
            ]
        }, 
        {
            name: 'dropdown',
            title: 'CREATED_AT',
            type: 'name',
            items: [
                {
                    title: 'DATE_CREATED_AT_↓',
                    name: 'CREATED_AT DESC',
                    short_name: 'CREATED_AT DESC'
                },
                {
                    title: 'DATE_CREATED_AT_↑',
                    name: 'CREATED_AT ASC',
                    short_name: 'CREATED_AT ASC'
                }
            ]
        }, 
        {
            name: 'dropdown',
            title: 'UPDATED_AT',
            type: 'createdDate',
            items: [
                {
                    title: 'DATE_UPDATED_AT_↓',
                    name: 'UPDATED_AT DESC',
                    short_name: 'UPDATED_AT DESC'
                },
                {
                    title: 'DATE_UPDATED_AT_↑',
                    name: 'UPDATED_AT ASC',
                    short_name: 'UPDATED_AT ASC'
                },
            ]
        },
        'KEYWORDS',
        'STOPWORDS'
    ]
}

const themeSettingsState = {
    data: null,
    tableHeaders: ThemeData.labels,
    subtopicTableHeader: ['#', 'ITEM_NAME', 'CREATED_AT', 'UPDATED_AT', 'KEYWORDS', 'STOP_WORDS'],
    indexSort: 'ALPHABETIC DESC',
    nameSort: 'CREATED_AT DESC',
    createDateSort: 'down',
    editedDateSort: 'down',
    selectedSortIndexes: [],
    tableItemComponent: null,
    errorInAction: null,
    isEditType: false,
    isDataUpdated: false,
    controlButtons: ["edit", "show", "delete"],
}

const themeSettings = (state = themeSettingsState, action) => {
    switch(action.type){
        case SET_SELECTED_THEME_TABLE_SORT:
            let item = state.selectedSortIndexes.indexOf(action.selectedItem);
            if(item > -1) return {...state, selectedSortIndexes: state.selectedSortIndexes.filter(k => k !== action.selectedItem)}
            else return {...state, selectedSortIndexes: [...state.selectedSortIndexes, action.selectedItem]}
        case SET_INDEX_SORT:
            return {...state, indexSort: action.indexSort}
        case SET_NAME_SORT:
            return {...state, indexSort: action.nameSort}
        case SET_CREADET_DATE_SORT:
            return {...state, indexSort: action.createdDateSort}
        case SET_EDITED_DATE_SORT:
            return {...state, indexSort: action.editedDateSort}
        case SET_THEME_TABLE_DATA:
            let data = action.data && action.data.content ? action.data.content : []
            return {...state, data: data, isDataUpdated: true}
        case SET_TABLE_ITEM_COMPONENT:
            return {...state, tableItemComponent: action.tableItemComponent}
        case SET_ERROR_IN_ACTION:
            return {...state, errorInAction: action.errorInAction}
        case SET_EDIT_TYPE:
            return {...state, isEditType: action.isEditType}
        case SET_UPDATETD_DATA:
            return {...state, isDataUpdated: action.isDataUpdated}
        case SET_UPDATED_TOPIC:{
            let topics = state.data;
            let t = action.topic.data;
            let topicIndex = topics.data.content.findIndex(k => k.id === t.id)
            topics.data.content[topicIndex] = t;
            return {...state, data: topics}
        }
        default:
            return {...state}
    }
}

export default themeSettings;

export const setTableItemComponent = (tableItemComponent) => {
    return {
        type: SET_TABLE_ITEM_COMPONENT,
        tableItemComponent: tableItemComponent
    }
}

export const setSelectedSort = (selectedItem) => {
    return {
        type: SET_SELECTED_THEME_TABLE_SORT,
        selectedItem: selectedItem
    }
}

export const setIndexSort = (indexSort) => {
    return {
        type: SET_INDEX_SORT,
        indexSort: indexSort
    }
}

export const setNameSort = (nameSort) => {
    return {
        type: SET_CREADET_DATE_SORT,
        indexSort: nameSort
    }
}

export const setCreatedDateSort = (createdDateSort) => {
    return {
        type: SET_NAME_SORT,
        createdDateSort: createdDateSort
    }
}

export const setEditedDateSort = (editedDateSort) => {
    return {
        type: SET_EDITED_DATE_SORT,
        editedDateSort: editedDateSort
    }
}

export const setThemeTableData = (tableData) => {
    return {
        type: SET_THEME_TABLE_DATA,
        data: tableData
    }
}

export const setErrorInAction = (error) => {
    return {
        type: SET_ERROR_IN_ACTION,
        errorInAction: error
    }
}

export const setThemeEditType = (isEditType) => {
    return {
        type: SET_EDIT_TYPE,
        isEditType: isEditType
    }
}

export const setUpdatedData = (isDataUpdated) => {
    return {
        type: SET_UPDATETD_DATA,
        isDataUpdated: isDataUpdated
    }
}

export const getMyTopics = (role = "ROLE_USER", sortField, sortDirection) => {
    return (dispatch) => {
        if(role === 'ROLE_MARKER'){
            
            MarkerService.getMarkerTopics(sortField, sortDirection).then(data=>{
                dispatch(setThemeTableData(data));
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                dispatch(setErrorInAction(error.message))
            })
        }
        else if(role === 'ROLE_ADMIN'){
            TopicService.getMyTopics(sortField, sortDirection).then(data=>{
                dispatch(setThemeTableData(data));
                dispatch(setUpdatedData(false))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                dispatch(setErrorInAction(error.message))
            })
        }
        else{
            TopicService.getMyTopics(sortField, sortDirection).then(data=>{
                dispatch(setThemeTableData(data));
                dispatch(setUpdatedData(false))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                dispatch(setErrorInAction(error.message))
            })
        }

    }
}

export const startTopic = (topicId) => {
    return (dispatch) => {
        TopicService.playTopic(topicId).then(data=>{
            dispatch(setUpdatedData(true))
            dispatch(setNotification({title: 'TOPIC_STARTED', message: 'TOPIC_STARTED'}))
        }).catch(error => {

        })
    }
}

export const pauseTopic = (topicId) => {
    return (dispatch) => {
        TopicService.pauseTopic(topicId).then(data=>{
            dispatch(setUpdatedData(true))
            dispatch(setNotification({title: 'TOPIC_STOPED', message: 'TOPIC_STOPED'}))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const deleteTopic = (topicId) => {
    return (dispatch) => {
        TopicService.deleteTopic(topicId).then(data=>{
            dispatch(getMyTopics());
            dispatch(setNotification({title: 'TOPIC_DELETED', message: 'TOPIC_DELETED'}))
        }).catch(error => {
            dispatch(setErrorInAction(error.message));
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}



export const createKeyword = (topicId, keywords, type) => {
        createByType(topicId, keywords, type);
        /*keywords.forEach((keyword, i) => {
            TopicService.createKeyword(keyword).then(data=>{
                keywordsArr.push(data.data)
                if(j === keywords.length-1){
                    TopicService.addKeywordToTopic(topicId, keywordsArr).then(data=>{
                        dispatch(getMyTopics())
                    });
                    
                }
                j++;
            });
            
        });*/ 
}

export const setUpdatedTopic = (topic) => {
    return {
        type: SET_UPDATED_TOPIC,
        topic: topic
    }
}

export const getTopic = (topicId) => {
    return (dispatch) => {
        TopicService.getTopic(topicId).then(data=>{
            dispatch(setUpdatedTopic(data))
        })
    }
}