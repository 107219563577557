import CompanyTopicsTablesController from "./company-topics-table-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getCompanyTopicsSelector } from "selectors/superadmin-selector";
import { startTopic, pauseTopic, deleteCompanyTopic, setTopicId, attachTopicToAccount, detachTopicFromAccount, detachMarkerTopic, makeTopicVisibleToMarker, getTopicAccounts } from "store/superadmin";
import { getAccountsTable, getAccountsName } from "selectors/superadmin-selector";


let mapStateToProps = (state) => {
    return {
        companyId: state.superadmin.companyId,
        companyTopics: getCompanyTopicsSelector(state),
        accountTopics: getCompanyTopicsSelector(state, true),
        tableHeader: state.superadmin.tableHeader,
        topicsTableControlButtons: state.superadmin.topicsTableControlButtons,
        accountsTopicTablerControlButton: state.superadmin.accountsTopicTablerControlButton,
        topicAccounts: getAccountsName(state),
        selectedAccounts: getAccountsName(state, true),
        accounts: getAccountsTable(state),
        accountId: state.superadmin.accountId,
        topicId: state.superadmin.topicId,
        isSuperadmin: state.Account.isSuperadmin,
        adminCompanyId: state.Account.companyId,
        markers: state.superadmin.markers
    }
}

const CompanyTopicsTablesContainer = connect(mapStateToProps, {  
                                getValue,
                                startTopic, 
                                pauseTopic,
                                deleteCompanyTopic,
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                setTopicId,
                                attachTopicToAccount,
                                detachTopicFromAccount,
                                makeTopicVisibleToMarker,
                                detachMarkerTopic,
                                getTopicAccounts
                                })(CompanyTopicsTablesController)

export default CompanyTopicsTablesContainer