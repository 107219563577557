import React, { useState, useEffect } from "react";
import DropdownItem from "./dropdown-item/dropdown-item";
import { InputText, InputCheckBox } from "../../../commons/input-controls";
import s from "../dropdown-with-search.module.scss";

const DropdownItems = (props) => {

    const isCheckAll = () => {
        if(props.selectedMyFilter && props.selectedMyFilter[props.name] !== undefined){
            if(props.selectedMyFilter[props.name].length === props.items.length){
                return true
            }
            else
                return false;
        }
        else{
            return false
        }
    }

    const [isShowChild, setIsShowChild] = useState(false);
    const [indexKey, setindexKey] = useState(props.indexKey);
    const [selected, setSelected] = useState((props.selectedItemItems && props.selectedItemItems[props.indexKey]) ? props.selectedItemItems[props.indexKey].items : []);
    const [selectedItemsName, setSelectedItemsName] = useState((props.selectedItemItems && props.selectedItemItems[props.indexKey]) ? props.selectedItemItems[props.indexKey].items : []);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [selectAllType, setSelectAllType] = useState([]);
    const [filterText, setFilterText] = useState('')
    const [checkBytTitle, setShowByTitle] = useState(false)
    const [checkAll, setCheckAll] = useState(isCheckAll());
    const [filterItems, setFilterItems] = useState(props.items);
    const [rerender, setRerender] = useState(false);
    const [withSelectAll, setWithSelectAll] = useState(props.withSelectAll !== undefined ? props.withSelectAll : true);

    useEffect(() => {
        setFilterItems(props.items);
        if(props.clearAll){
            setSelected([]);
            
        }
        if(props.isChanged){
            setFilterText("")
        }
        setCheckAll(isCheckAll())
        if(props.selectedItemItems && props.selectedItemItems[props.indexKey] ) setSelectedItemsName(props.selectedItemItems[props.indexKey].items)
        else setSelectedItemsName(props.selectedItemItems);
        setWithSelectAll(props.withSelectAll !== undefined ? props.withSelectAll : true)
        setRerender(!rerender);
    }, [props]);
    
    const checkAllItems = () => {
        setCheckAll(!checkAll)
        let arr = []
        if(!checkAll){
            arr = props.items.map(k => k.list);
        }
        else{
            if(props.name === 'topics'){
                props.setSelectedTopics([],[])
            }
            //arr = []
        }
        setSelected(arr)

        props.setMySelectedFilter({[props.name]: arr}, props.name);
        
        /*if(props.name === 'topics'){
            if(!checkAll){
                let ft = []
                let t = []
                let n = []
    
                props.items.forEach((it)=> {
                    t.push(it.uuid)
                    n.push(it.id)
                })
                props.setSelectedTopics(t,n)
            }
            else{
                props.setSelectedTopics([],[])
                //arr = []
            }
        }*/
        
    }

    const onKeyWordChange = (e) => {
        setFilterText(e.toLowerCase())
    }

    const showChilds = (index, name) => {
        setIsShowChild(!isShowChild)
    }
    const setSelectedItems = (item, name, index, id) => {
        let arr = selected;
        let itemItemsSelected = false;
        if(selected.indexOf(item) !== -1) {
            arr = selected.filter(k => k !== item); 
        }
        else {
            itemItemsSelected = true
            arr = [...selected, item]
        }
        setSelected(arr)
        let topic = []
        index === -1 ? props.setAddSelectedMyFilter(props.name, '\\!'+item, topic) : props.setAddSelectedMyFilter(props.name, item, topic)
        if(props.items[item-1] && props.items[item-1].items && props.items[item-1].items.length){
            let array = []
            if(itemItemsSelected)
                array = props.items[item-1].items.map((k, i) => i+1)
            setItemsSelected(array)
            props.setSelectedItemItems(array, item-1, indexKey)
        }
    }

    const seleactAll = () => {
        let arr = [];
        
        arr = props.items.filter((k,i) => i+1);
        setSelected(arr)
        props.setSelectedItems(arr, indexKey)
    }

    const setSelectedItemItems = (item, itemIndex, kIndex) => {
        let arr = itemsSelected
        if(itemsSelected.indexOf(item) !== -1) {
            arr = itemsSelected.filter(k => k !== item);
        }
        else {
            arr = [...itemsSelected, item]
        }
        setItemsSelected(arr)
        props.setSelectedItemItems(arr, itemIndex-1, kIndex)
    }

    const getLocationName = (locationId) => {
        let locations = props.locations
        return locations && locations[locationId] ? locations[locationId] : locationId
    }

    const getFilterName = (tagId, tag) => {
        let f = null
        if(props.filterTags && props.filterTags.length){
            f = props.filterTags.findIndex(k => Number(tagId) ? k.id === Number(tagId) : k.uuid === tagId)
            if(f !== -1)
                return (props.filterTags[f])
            else{
                if(tag.value && tag.name){

                    tag.name = tag.value === '\\*' ? props.getValue('WITH_TAGS') : (tag.value === '\\!' ? props.getValue('WITHOUT_TAGS') : null);
                    tag.textColor = '#ffffff'; 
                    tag.color = '#EE6766';
                    tag.isException = false;
                    if(tag.name) return (tag)
                }
            }
                
        }
    }

    let items = (filterItems && filterItems) ? filterItems.map((item, i ) => {
        let index = i+1;
        let textColor = null;
        if(!item || item === undefined){
            item = {}
            item.name = 'none';
            item.color = 'none';
            item.field = 'none';
        }
        if(item && item !== undefined){
            if(item.field === 'tags'){
                let it = getFilterName(item.value, item);
                //if(it){
                    item.name = it && it.name ? it.name : ""
                    item.color = it && it.color ? it.color : ""
                    item.textColor = it && it.textColor ? it.textColor : "";
                //}

                
            }
            else if(item.field === 'verificationStatus'){
                if(item.value === '\\!'){
                    item.name = 'UNVERIFIED';
                    item.list = "UNVERIFIED"
                    item.isException = false;
                }
                else{
                    item.list = item.list.toUpperCase();

                }

            }

        }

        if(item.field === 'commonLocationCountryId' || item.field === 'common_location_country_id'){
            item.name = getLocationName(item.value ? item.value : '')
            if(item.value === '\\!'){
                item.name = 'WITHOUT_VALUE';
                item.isException = false;
            }
        }
        let locationName = getLocationName(item.value ? item.value : '')
        let finded = 0;

        if(item && item !== undefined){
                if(props.selectedMyFilter && props.selectedMyFilter[item.field]){
                    if(props.selectedMyFilter[item.field].length){
                        finded = props.selectedMyFilter[item.field].indexOf(item.list) !== -1 ? 1 : 0;
                        if(finded === 0) 
                            finded = (props.selectedMyFilter[item.field] && props.selectedMyFilter[item.field].indexOf("\\!"+item.list) !== -1) ? -1 : 0
                    }
                    else{
                        finded = props.selectedMyFilter[item.field].value === 'true' ? 1 : 0
                    }
                }
        }

        return item && item.name !== '' ? (
            <div key={i}> 
                { filterText 
                ? (props.getValue(item.name).toLowerCase().indexOf(filterText) >= 0 ? <DropdownItem locationName={locationName} isException={item.isException} setException={props.setException} textColor={textColor} count={item.count} opened={isShowChild} showChilds={showChilds} selected={finded} setSelectedItems={setSelectedItems} getValue={props.getValue} index={index} indexKey={indexKey} item={item} child={item.items ? true : false}/> : null)
                : <DropdownItem isException={item.isException} setException={props.setException} textColor={textColor} count={item ? item.count : 0} opened={isShowChild} showChilds={showChilds} selected={finded} setSelectedItems={setSelectedItems} getValue={props.getValue} index={index} indexKey={indexKey} item={item} child={item.items ? true : false}/>
                }
                {(item.items && isShowChild) && item.items.map((k, j) => {
                    let kIndex = j+1;
                    let f = itemsSelected.indexOf(kIndex) !== -1 ? true : false

                    return <div className={s.withChild} key={j}>
                        <DropdownItem locationName={locationName} isException={item.isException} setException={props.setException} textColor={textColor} count={k.count} kIndex={indexKey} selected={f} setSelectedItems={setSelectedItemItems} getValue={props.getValue} index={kIndex} indexKey={index} item={k}/>
                    </div>
                    })
                }
            </div>
        ) : null
    }) : <div></div>;

    return (
        <div>
            { withSelectAll ? <div className={s.DropdownWithSearchHeader} >
                <InputText value={filterText} callback={onKeyWordChange} name="filterText" type="text" placeholder={props.getValue('SEARCH_BY_TITLE')} />
                <div className={s.ChooseAll}>
                    <InputCheckBox isChecked={checkAll} name={"selectAll"+indexKey} title={props.getValue('CHOOSE_ALL')} callback={checkAllItems}/>
                    <span>{props.getValue('CHOOSE_ALL')}</span>
                </div>
            </div> : null }
        <div className={s.DropdownWithSearchItemsBody}>{items}</div>
        </div>

    )
}

export default DropdownItems;