import React from "react";
import s from "./admin-accounts-page.module.scss";
import AdminAccountsContainer from "pages/admin/admin-accounts/admin-accounts-container";
import { useState } from "react";
import { useEffect } from "react";

const AdminAccountsPage = (props) => {

    return (
        <div className={s.AdminAccountsPage}>
            <AdminAccountsContainer isAccountsPage={true}/>
        </div>
    )
}

export default AdminAccountsPage;