import React, { useState, useEffect} from "react";
import { ReactComponent as RestartIcon } from 'assets/icons/restart.svg';
import s from "../posts.module.scss"
const NewPostsCount = (props) => {

    const [counter, setCounter] = useState(props.newPostsCount);
    const [newPostCount, setNewPostCount] = useState(props.newPostCount);
    const [rerender, setRerender] = useState(false);
    
    useEffect(() => {
        setNewPostCount(props.newPostCount);
        if(!props.newPostsIsFetching){
            
            if(props.selectedTopics && props.selectedTopics.length){
                const interval = setInterval(() => {
                    setCounter(prevCounter => prevCounter + 1);
                    if(props.selectedSubtopics && props.selectedSubtopics.length)
                        props.getSubtopicsNewPublicationsCount(props.selectedTopics[0], props.selectedSubtopics[0], false);
                    else
                        props.getNewPublicationsCount(props.selectedTopics[0], false)
                }, props.statisticsError ? 60000 : 5000);
                
                return () => clearInterval(interval);
            }
        }

        setRerender(!rerender);
    }, [props.selectedTopics, props.selectedSubtopics, props.newPostCount, props.newPostsIsFetching, props.statisticsError]);

    return (
        <div className={s.postsCount}>
            { (props.newPostsCount && props.newPostsCount > 0 && props.selectedTopics && props.selectedTopics.length) 
                                                        ? <div onClick={props.reload}>{props.getValue('NUMBER_OF_NEW_POSTS')}: <span>{props.newPostsCount}</span><RestartIcon /></div> 
                                                        : null}
        </div>
    )
}

export default NewPostsCount;