import React from "react";
import AdminMigration from "./admin-migration";
import ControlButtons from "items/control-buttons/control-buttons";
import MigrationItemContainer from "./migration-item/migration-item-container";
import MigrationCreateItemContainer from "./migration-create-item/migration-create-item-container";
import { getFormatDate } from "service/format-date";
class AdminMigrationController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            page: this.props.currentPage,
            count: this.props.showedItemsCount,
            migrationCount: this.props.migrationCount,
            migrationPage: this.props.migrationPage,
            selectedItem: this.props.migrationPage,
        }
        this.controlButtons = [];
    }

    componentDidMount(){
        //this.props.getMigrationOperations();
        this.getMigrations(0, 10);
        this.getCompanies()
        this.getAllTopics()
    }

    getMigrations = (page, pageSize) => {
        this.props.getMigrationOperations(page, pageSize);
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    showRightMenu = (show, migrationId, index) => {
        this.closeRightMenu()
        if(show){
            //let fmarker = //this.props.adminMarkers.content[index];
            if(migrationId) this.props.setMigrationId(migrationId)
            this.props.setShowRightMenu(true)
            switch(show){
                case 'show':
                    this.props.setRightMenuComponent(<MigrationItemContainer type={show} getCompanyName={this.getCompanyName} getTopicName={this.getTopicName}/>, 'migrationItem', null, this.props.getValue('VIEW_HISTORICAL_MIGRATION'), true, this.props.getValue('VIEW_HISTORICAL_MIGRATION'));
                    break;
                case 'CREATE':
                    this.props.setMigrationOperation(null)
                    this.props.setRightMenuComponent(<MigrationCreateItemContainer topics={this.props.adminTopics} type={show} />, 'migrationItem', null, this.props.getValue('CREATE_HISTORICAL_MIGRATION'), true, this.props.getValue('CREATE_HISTORICAL_MIGRATION'));
                    break;
            }          
            
        }
    }

    getCompanies = () => {
        this.props.getSuperadminCompanies('ALL', 0, 9999);
    }

    getAllTopics = () => {
        this.props.getAllTopics(0, 9999, 'ALL',);
    }

    getControlButtonItems = (id, status, items, index, isSubtopic) => {
        let c = [];
        c = [...items];
        let s = status
        if(status === 'FAILED' || status === 'REJECTED' || status === 'CANCELLED'){
            //s = 'RETRY'
            c.unshift('RETRY')
        }
        else if(status === 'PENDING'){
            c.unshift('PENDING')
        }
        if(status === 'IN_PROGRESS'){
            s = 'ACTIVE'
            c.unshift(s)
        }
        else if(status === 'FAILED' || status === 'PAUSED' || status === 'CANCELLED'){
            s = 'PAUSED'
            c.unshift(s)
        }
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index} isSubtopic={isSubtopic}/>
    }

    getCompanyName = (companyId) => {
        let company = this.props.companies.filter( k => k.id === companyId)
        return company && company[0] ? company[0] : companyId;
    }

    getTopicName = (topicId) => {
        this.props.adminTopics
        let topic = this.props.adminTopics && this.props.adminTopics.content ? this.props.adminTopics.content.filter( k => k.uuid === topicId) : null;
        return topic && topic[0] ? topic[0] : topicId
    }

    setSelectedItem = (item) => {
        this.setState({
            selectedItem: item
        })
    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type,
            migrationId: key
        })
        this.title = type;
        switch(type){
            case 'show':
                this.showRightMenu('show', key, index)    
                break;
            case 'CREATE':
                this.showRightMenu('create', key, index)    
                break;
            case 'PAUSED':
                this.props.resumeMigration(key);
                break;
            case 'ACTIVE':
                this.props.pauseMigration(key);
                break;
            case 'RETRY':
                this.props.retryMigration(key);
                break;
        }
    }

    getProgressCount = (expectedDocs,  currentDocs) => {
        let result = currentDocs * 100 / expectedDocs
        return Number.isInteger(result) ? result : result.toFixed(2)
    }

    setTableValues = (tableValues) => {
        return tableValues.map( (k, i) => {
            let ctrl = [];
            ctrl = this.props.controlButtons 
            let company = this.getCompanyName(k.companyId)
            let topic = this.getTopicName(k.topicUUID)
            let b = this.getControlButtonItems(k.id, k.status, ctrl, i)
            let expectedDocs = k.expectedDocs ? k.expectedDocs : 0;
            let currentDocs = k.currentDocs ? k.currentDocs : 0;
            let docsProgressCount = k.expectedDocs && k.expectedDocs !== undefined ? this.getProgressCount(k.expectedDocs, k.currentDocs) : 0;
            let docsCountLine = <div>{expectedDocs+" / "+currentDocs}<br /><progress max="100" value={docsProgressCount}>{docsProgressCount}</progress>{" "+docsProgressCount+"%"}</div>
            //let status = k.status
            return [<p>{i+1}</p>, (topic && topic.name) ? topic.name : topic, (company && company.name) ? company.name : company, getFormatDate(k.createdAt, "DD-MM-YYYY HH:mm"), getFormatDate(k.dateStart, "DD-MM-YYYY HH:mm"),getFormatDate(k.dateEnd, "DD-MM-YYYY HH:mm"), docsCountLine, this.props.getValue(k.type), this.props.getValue(k.status), b];
        })
    }

    /*componentDidUpdate(){

    }*/

    componentDidUpdate(){
        if(this.props.showedItemsCount !== this.state.count || this.props.currentPage !== this.state.page || this.props.migrationCount !== this.state.migrationCount || this.props.migrationPage !== this.state.migrationPage){
            if(this.props.showedItemsCount !== this.state.count || this.props.currentPage !== this.state.page){
                this.getMigrations(this.props.currentPage, this.props.showedItemsCount);
                this.closeRightMenu();
            }
            this.setState({
                page: this.props.currentPage,
                count: this.props.showedItemsCount,
                migrationCount: this.props.migrationCount,
                migrationPage: this.props.migrationPage,
            })
            return true;
        }
        else{
            return false;
        }
    }

    getTableHeader = (headers) => {
        return headers.map(k => this.props.getValue(k))
    }

    render(){
        return <AdminMigration 
                            data={this.props.migrations && this.props.migrations.length && this.setTableValues(this.props.migrations)}
                            controllerButtons={this.controlButtons}
                            controlButtons={this.props.controlButtons}
                            selectedItem={this.state.selectedItem}
                            setSelectedItem={this.setSelectedItem}
                            tableHeader={this.getTableHeader(this.props.tableHeader)}
                            showRightMenu={this.showRightMenu}
                            getValue={this.props.getValue}
                            getMigrations={this.getMigrations}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                />
    }

    componentWillUnmount(){
        this.closeRightMenu();
    }
}

export default AdminMigrationController;