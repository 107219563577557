import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { getMe } from "store/account";

let mapStateToPropsForRedirect = (state) => ({
    isAuth: state.authorization.isAuth

})

export const withAuthRedirect = (Component) => {
    class RedirectComponent extends React.Component {

        componentDidMount(){
            if(this.props.isAuth && !this.props.role){
                this.props.getMe();
            }
        }
        
        render(){
            if(!this.props.isAuth){ 
                return <Navigate to={"/page/"} />
            }
            else{
                return <Component {...this.props} />
            }
            
        }
    }

    let ConnectedAuthRedirectComponent = connect(mapStateToPropsForRedirect, {getMe})(RedirectComponent)

    return ConnectedAuthRedirectComponent
}