import { header, getLanguage, checkFilters } from "./header";

const analytic = header('storage/api/v1/analytics/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    analytic.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    analytic.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const AnalyticService = {
    getShortAnalytic(uuid, meditype){
        setLanguage();
        getAuth();
        return analytic.get(`short/topics/${uuid}?mediatype=${meditype}`, {}).then(response => {
            return response.data
        })
    },
    getAnalyticRange(uuid){
        setLanguage();
        getAuth();
        return analytic.get(`short/range/topics/${uuid}`, {}).then(response => {
            return response.data
        })
    },/*DAILY, WEEKLY, MONTHLY*/
    getAnalyticLanguageRange(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}language/range`, {...params}).then(response => {
            return response.data
        })
    },
    getAnalyticLanguage(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}language`, {...params}).then(response => {
            return response.data
        })
    },
    getSentimentRange(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth(); //'https://dev.mm2.cabinet.alem.kz/storage/analytics/extended/topics/8f0ce6d8-c31e-4081-8583-a90a5f90bec0/sentiment/range'
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}sentiment/range`, {...params}).then(response => {
            return response.data
        })
    },
    getSentiment(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}languages/sentiments`, {...params}).then(response => {
            return response.data
        })
    },
    getPublicationTypeRange(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}publication_type/range`, {...params}).then(response => {
            return response.data
        })
    },
    getPublicationType(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}languages/publication_types`, {...params}).then(response => {
            return response.data
        })
    },
    getTop20BySentiment(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}media/top/sentiments`, {...params}).then(response => {
            return response.data
        })
    },
    getTop20ByLanguages(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}media/top/languages`, {...params}).then(response => {
            return response.data
        })
    },
    getKeywordsSentimentRange(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}keywords/range`, {...params}).then(response => {
            return response.data
        })
    },
    getKeywordsSentiment(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}keywords`, {...params}).then(response => {
            return response.data
        })
    },
    getMediaTypesSentiments(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}media_types/sentiments`, {...params}).then(response => {
            return response.data
        })
    },
    getAuthorsSentiment(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}authors/sentiments`, {...params}).then(response => {
            return response.data
        })
    },
    getAuthorsLocations(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}profiles/locations`, {...params}).then(response => {
            return response.data
        })
    },
    getAuthorsLocationsIds(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}profiles/locations/ids`, {...params}).then(response => {
            return response.data
        })
    },
    getAuthorsAge(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}profiles/ages`, {...params}).then(response => {
            return response.data
        })
    },
    getAuthorsGender(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}profiles/genders`, {...params}).then(response => {
            return response.data
        })
    },
    getFollowersCounts(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}profiles/followers-counts`, {...params}).then(response => {
            return response.data
        })
    },
    getTagsSentiments(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}tags/sentiments`, {...params}).then(response => {
            return response.data
        })
    },
    getTagsRange(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}tags/range`, {...params}).then(response => {
            return response.data
        })
    },
    getInvolementSentiment(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return analytic.post(`extended/topics/${uuid}/${subtopicsURL}involvement/sentiments`, {...params}).then(response => {
            return response.data
        })
    }
}
