import React, {useState, useEffect} from "react";
import { InputCheckBox, InputText } from "commons/input-controls";
import { getValue } from "store/languages";
import useComponentVisible from "hooks/useComponentVisible";
import s from "./items-list.module.scss"

const ItemsList = (props) => {

    const getSelectedlist = (list) => {
        return list.map(k => k.name);
    }

    const [items, setItems] = useState(props.items);
    const [selectedItems, setSelectedItems] = useState(getSelectedlist(props.selected));
    const [selected, setSelected] = useState(null);
    const [showBlock, setShowBlock] = useState(false);

    let {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const getSelected = (selectedItem) => {
        let f = props.selected.filter(k => k.name === selectedItem)
        
    }

    useEffect(() => {
        //setItems(props.items);
        setSelectedItems(getSelectedlist(props.selected));
    }, [props.selectedItems]);

    const setItemValue = (item) => {
        setSelected(item.name)
        props.callback(item)
        if(isComponentVisible)
            setIsComponentVisible(!isComponentVisible)
        
    }

    let itemsList = []
    items.length ? items.forEach((k,i) => {
        let s = selected ? selected.toLowerCase() : ''
        let n = k.name ? k.name.toLowerCase() : '';

        if(selected){
            if(n.indexOf(s) >= 0){
                itemsList[i] = <div key={i} className={s.itemBlock} onClick={setItemValue.bind(this, k)}>
                <div>{k.name}</div>
            </div>
            }
        }
        else{
            itemsList[i] = <div key={i} className={s.itemBlock} onClick={setItemValue.bind(this, k)}>
                <div>{k.name}</div>
            </div>
        }

    }) : []

    const setSelectedValue = (v) => {
        setSelected(v);
        if(!isComponentVisible)
            setIsComponentVisible(!isComponentVisible)
    }

    const setShowBlockItems = () => {
        if(!showBlock)
            setIsComponentVisible(!isComponentVisible)
    }

    const setGoTo = (item) => {
        let int = []
        int = items.findIndex((k, i) => {if(k.name.toLowerCase().indexOf(item.toLowerCase()) >= 0){ return k}});
        if(int >= 0){
            setSelectedValue(items[int].name)
            props.callback(items[int])
        }
        if(isComponentVisible)
            setIsComponentVisible(!isComponentVisible)
    }

    return (
        <div className={s.ItemsList}  ref={ref}>
            <div className={s.ItemsBlock}>
                <div className={s.TextBlock} onClick={setShowBlockItems}>
                    <InputText value={selected} callback={setSelectedValue} goto={setGoTo}/>
                </div>
                { isComponentVisible
                    ? <div className={s.listBlock}>
                        { itemsList }
                    </div>
                    : null
                }
                
            </div>
            
        </div>
    )
}

export default ItemsList