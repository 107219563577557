import UsersSetttingsController from "./users-controller";
import { connect } from "react-redux";
import { getValue } from "../../../../store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "../../../../store/side-bar";

let mapStateToProps = (state) => {
    return {
        tableItems: state.usersSettings.data,
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent
    }
}

export default 
    connect(mapStateToProps, {getValue, setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent})(UsersSetttingsController)