import TreeController from "./tree-controller";
import { connect } from "react-redux";
import { setShowRightMenu } from "../../store/side-bar";
import { getValue } from "../../store/languages";
import { setTreeDataItems, setTreeSelectedItems, setTreeSelectedSubItems } from "../../store/tree"
let mapStateToProps = (state) => {
    return {
        data: state.Tree.data,
        selected: state.Tree.selectedItem,
        selectedId: state.Tree.selectedId,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubItem: state.Tree.selectedSubItem,
        selectedSubUUId: state.Tree.selectedSubUUId,
        toNull: state.Tree.toNull,
        isFetching: state.ExtendedAnalytic.isFetching,
    }
}

export default connect(mapStateToProps, {getValue, setShowRightMenu, setTreeDataItems, setTreeSelectedItems, setTreeSelectedSubItems})(TreeController)