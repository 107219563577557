import { ReportService } from "connection/report-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_SELECTED_REPORT = "SET-SELECTED-REPORT";
const SET_SELECTED_REPORT_LIST = "SET-SELECTED-REPORT-LIST";
const SET_SELECT_ALL_REPORT = "SET-SELECT-ALL-REPORT";
const SET_UPDATED_REPORT = "SET-UPDATED-REPORT";
const SET_REPORT_SETTINGS_CHANGED = "SET-REPORT-SETTINGS-CHANGED";

const segments = [
    'TOPIC_NAME', 
    'PERIOD', 
    'REQUEST_DATE', 
    'COLONTITLES', 
    'TABLE_OF_CONTENTS',
    'MAIN_INFO', 
    'SENTIMENT_DYNAMICS', 
    'LANGUAGE_DYNAMICS', 
    'LANGUAGE_TOTAL', 
    'MEDIA_TYPE_DYNAMICS', 
    'MEDIA_TYPE_TOTAL', 
    'MEDIA_TYPE_BY_SENTIMENT', 
    'KEYWORDS', 
    'POPULAR_WORDS', 
    'TAGS', 
    'POPULAR_RESOURCES', 
    'MEDIA_GEOGRAPHY', 
    'MEDIA_SCALE', 
    'SOCIAL_DOMAIN_DYNAMICS', 
    'SOCIAL_DOMAIN', 
    'AUTHORS', 
    'GROUPS', 
    'AUTHORS_GEOGRAPHY', 
    'PROFILE_AGE', 
    'PROFILE_GENDER', 
    'INVOLVEMENT', 
    'PUBLICATION_TYPE_BY_SENTIMENT', 
    'PUBLICATION_TYPE_TOTAL', 
    'MENTIONS_FEED'
]

const documentElements = [
    'DOCUMENT_TITLE', 
    'DOCUMENT_DOMAIN', 
    'DOCUMENT_AUTHOR', 
    'DOCUMENT_URL', 
    'DOCUMENT_TYPE', 
    'DOCUMENT_SENTIMENT', 
    'DOCUMENT_LOCATION', 
    'DOCUMENT_DATE', 
    'DOCUMENT_COVERAGE', 
    'DOCUMENT_TAGS', 
    'DOCUMENT_TEXT', 
    'DOCUMENT_NUMERATION'
]

const textRepresentation = [
    'HIGHLIGHTED_FULL_TEXT', 
    'PLAIN_FULL_TEXT', 
    'HIGHLIGHTED_FRAGMENTS'
]

const groupingType = [
    'BY_SENTIMENT', 
    'BY_MEDIA_TYPE', 
    'BY_DOMAIN', 
    'NO_GROUPING', 
    'BY_SENTIMENT_AND_MEDIA_TYPE', 
    'BY_MEDIA_TYPE_AND_SENTIMENT', 
]

const data = {
    segments,
    documentElements,
    textRepresentation,
    groupingType,
}

const reportSettingsData = {
    data: {...data},
    selectedReport: {},
    updatedReport: false,
    reportSettingsChanged: false,
    listCount: data.segments.length + data.documentElements.length + 2
}

const reportSettings = (state = reportSettingsData, action) => {
    switch(action.type){
        case SET_SELECTED_REPORT:
            let selectedItems = {...state.selectedReport};
            if(selectedItems[action.item]){
                let finded = selectedItems[action.item].indexOf(action.selectedReport)
                
                if(finded !== -1){
                    if(Array.isArray(selectedItems[action.item])){
                        if(selectedItems[action.item].length !== 1){
                            selectedItems[action.item].splice(finded, 1)
                        }
                    }
                }
                else{
                    if(action.item === 'textRepresentation' || action.item === 'groupingType'){
                        selectedItems[action.item] = action.selectedReport;
                    }
                    else{
                        selectedItems[action.item].push(action.selectedReport)
                    }
                        
                }
            }
            else{
                selectedItems[action.item] = [];
                if(action.item === 'textRepresentation' || action.item === 'groupingType'){
                    selectedItems[action.item] = action.selectedReport;
                }
                else{
                    selectedItems[action.item].push(action.selectedReport)
                }
            }
            return {...state, selectedReport: selectedItems, reportSettingsChanged: true}
        case SET_SELECTED_REPORT_LIST:

            let selectedItemsList = {...action.selectedReport}
            return {...state, selectedReport: selectedItemsList}
        case SET_SELECT_ALL_REPORT:
            let all_list = {}
            if(action.selectAll){
                all_list.segments = []
                all_list.documentElements = []
                all_list.segments = [...data.segments]
                all_list.documentElements = [...data.documentElements],
                all_list.textRepresentation = data.textRepresentation[0];
                all_list.groupingType = data.groupingType[0];
            }
            else{
                all_list.segments = [data.segments[0]]
                all_list.documentElements = [data.documentElements[0]],
                all_list.textRepresentation = data.textRepresentation[0];
                all_list.groupingType = data.groupingType[0];
            }
            return {...state, selectedReport: all_list, reportSettingsChanged: true}

        case SET_UPDATED_REPORT:
            return {...state, updatedReport: action.updated}
        case SET_REPORT_SETTINGS_CHANGED:
            return {...state, reportSettingsChanged: action.reportSettingsChanged}
        default:
            return {...state}
    }

}

export default reportSettings;

export const setSelectAllReport = (selectAll) => {
    return {
        type: SET_SELECT_ALL_REPORT,
        selectAll: selectAll
    }
}

export const setSelectedReportList = (selectedReport) => {
    return {
        type: SET_SELECTED_REPORT_LIST,
        selectedReport: selectedReport,
    }
}

export const setSelectedReport = (selectedReport, item) => {
    return {
        type: SET_SELECTED_REPORT,
        selectedReport: selectedReport,
        item, item
    }
}

export const setUpdatedReport = (updated) => {
    return {
        type: SET_UPDATED_REPORT,
        updated: updated
    }
}

export const setReportSettingsChanged = (reportSettingsChanged) => {
    return {
        type: SET_REPORT_SETTINGS_CHANGED,
        reportSettingsChanged: reportSettingsChanged
    }
}

export const getReportSettings = (id) => {
    return (dispatch) => {
        dispatch(setUpdatedReport(true))
        ReportService.getReportSettings().then(data=>{
            dispatch(setSelectedReportList(data.data))
            dispatch(setUpdatedReport(false))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}
export const updateReportSettings = (params) => {
    return (dispatch) => {
        ReportService.updateReportSettings(params).then( data => {
            dispatch(setReportSettingsChanged(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}