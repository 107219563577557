import React from "react";
import SearchText from "./search-text";

class SearchTextController extends React.Component{

    constructor(props){
        super(props);

    }

    render(){

        return (
            <SearchText 
                        getValue={this.props.getValue}
                        modalShowToggle={this.props.modalShowToggle}
                        searchText={this.props.searchText} 
                        searchList={this.props.searchList} 
                        setSearchList={this.props.setSearchList}
                        setSearchText={this.props.setSearchText}
                        removeFromSearchList={this.props.removeFromSearchList}
                        parentParent={this.props.parentParent}
                        parent={this.props.parent}
                        setParentParent={this.props.setParentParent}
                        setParent={this.props.setParent}
                        />
        )
    }

}

export default SearchTextController;