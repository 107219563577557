import { createSelector } from "reselect";

const topicItemKeywords = (state) => {
    return state.TopicItem.keywords;
}

const getAllCountries = (state) => {
    return state.geoplace.countries
}

export const getTopicItemKeywords = createSelector(topicItemKeywords, (keywords) => {
        return keywords.map((item)=>{
            return item.keyword
        });
})

export const getInternationalCountries = createSelector(getAllCountries, (countries) => {
    return countries//.filter(k => k.id !== 424311521)
})
