import React ,{ useState } from "react";
import { InputCheckBox } from "commons/input-controls";
import { ReactComponent as AngleUp } from 'assets/icons/angle-up.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';
import s from "./checkbox-items.module.scss";

const CheckoxItems = (props) => {

    const [items, setItems] = useState(props.items);
    const [title, setTitle] = useState(props.title);
    const [isOpened, setIsOpened] = useState(false);

    const setSelected = (item, itemBlock) => {
        props.setSelectedReport(item, title);
    }

    const setIsOpenedItem = () => {
        setIsOpened(!isOpened)
    }

    const getTitle = (title) => {
        switch(title){
            case 'segments':
                return props.getValue('REPORT_DESIGN');
            case 'documentElements':
                return props.getValue('PUBLICATION_DETAILS');
            case 'textRepresentation':
                return props.getValue('TEXT_REPRESENTATION');
            case 'groupingType':
                return props.getValue('GROUP');
            default:
                return title;
        }
    }

    const angleButton = isOpened ? <AngleDown /> : <AngleUp /> 

    const getCheckboxItem = (title, index) => {
        let selected = props.selectedReport.indexOf(title) !== -1 ? true : false
        return  (
                    <div className={s.item}>
                        <InputCheckBox isChecked={selected} name={title} callback={setSelected.bind(this, title, index)} title={props.getValue(title)} />
                        <span>{props.getValue(title)}</span>
                    </div>
                )
    }

    const itemsList = Array.isArray(items) ? items.map((k, i) => <div key={i}>{getCheckboxItem(k, i)}</div>) : <div key={0}>{getCheckboxItem(items, 0)}</div>

    return (
        <div className={s.CheckoxItems}>
            <div className={s.CheckboxHeader} onClick={setIsOpenedItem}>
                <span className={s.ItemTitle}>{getTitle(title)}</span>
                <div className={s.showItemBlock}>{ angleButton }</div>
            </div>
            {isOpened ? <div className={s.ItemsList}>{itemsList}</div> : null}
        </div>
    )
}

export default CheckoxItems;