import { NotificationService } from "../connection/notification-service";

const SET_SHOW_LEFT_MENU = "SET-SHOW-LEFT-MENU";
const SET_SHOW_RIGHT_MENU = "SET-SHOW-RIGHT-MENU";
const SET_LEFT_MENU_COMPONENT = "SET-LEFT-MENU-COMPONENT";
const SET_RIGHT_MENU_COMPONENT = "SET-RIGHT-MENU-COMPONENT";
const SET_REMOVE_LEFT_MENU = "SET_REMOVE_LEFT_MENU";
const SET_REMOVE_RIGHT_MENU = "SET_REMOVE_RIGHT_MENU";
const SET_REMOVE_FROM_LEFT_MENU = "SET-REMOVE-FROM-LEFT-MENU";
const SET_SIDE_BAR_UPDATED = "SET-SIDE-BAR-UPDATED";
const SET_TOKEN = "SET-TOKEN";

let sideBar = {
    isHasSideBar: false,
    showLeftMenu: false,
    showRightMenu: false,
    leftMenuComponent: [],
    rightMenuComponent: [],
    rightMenuTitle: null,
    withHeader: false,
    sideBarUpdated: false,
    token: '',
    closeMenu: null
}

const SideBarMenu = (state = sideBar, action) => {
    switch(action.type){
        case SET_SHOW_LEFT_MENU:
            return {...state, showLeftMenu: action.showLeftMenu}
        case SET_SHOW_RIGHT_MENU:
            return {...state, showRightMenu: action.showRightMenu}
        case SET_LEFT_MENU_COMPONENT:
            let leftMenu = action.isOne ? [] : [...state.leftMenuComponent.filter(k => k.componentName !== action.componentName)]
            if(action.isFirst){
                leftMenu.unshift({component: action.leftMenuComponent, componentName: action.componentName})
            }
            else{
                leftMenu.push({component: action.leftMenuComponent, componentName: action.componentName})
            }
            return {...state, leftMenuComponent: leftMenu}
        case SET_RIGHT_MENU_COMPONENT:
            let rightMenu = [...state.rightMenuComponent.filter(k => k.componentName !== action.componentName)]
            if(action.isFirst)
                rightMenu.unshift({component: action.rightMenuComponent, componentName: action.componentName, componentTitle: action.componentTitle})
            else
                rightMenu.push({component: action.rightMenuComponent, componentName: action.componentName, componentTitle: action.componentTitle})
            return {...state, rightMenuComponent: rightMenu, rightMenuTitle: action.rightMenuTitle, withHeader: action.withHeader, closeMenu: action.closeMenu ? action.closeMenu : null}
        case SET_REMOVE_FROM_LEFT_MENU:
            let lm = [...state.leftMenuComponent.filter(k => k.componentName !== action.componentName)];
            return {...state, leftMenuComponent: lm, sideBarUpdated: false}
        case SET_REMOVE_LEFT_MENU:
            return {...state, leftMenuComponent: []}
        case SET_REMOVE_RIGHT_MENU:
            return {...state, rightMenuComponent: []}
        case SET_TOKEN:
            return {...state, token: action.token}
        case SET_SIDE_BAR_UPDATED:
            return {...state, sideBarUpdated: action.updated}
        default:
            return state
    }
}

export default SideBarMenu;

export const setSideBarUpdated = (updated) => {
    return {
        type: SET_REMOVE_FROM_LEFT_MENU,
        updated: updated
    }
}


export const setRemoveFromLeftMenu = (leftMenuComponent) => {
    return {
        type: SET_REMOVE_FROM_LEFT_MENU,
        leftMenuComponent: leftMenuComponent
    }
}

export const setRemoveFromLeftMenuUpdate = (leftMenuComponent) => {
    return (dispatch) => {
        dispatch(setSideBarUpdated(true))
        setTimeout(()=> {
            dispatch(setRemoveFromLeftMenu(leftMenuComponent))
        }) 
    }
}

export const setShowLeftMenu = (showLeftMenu) => {
    return {
        type: SET_SHOW_LEFT_MENU,
        showLeftMenu: showLeftMenu
    }
}

export const setShowRightMenu = (showRightMenu) => {
    return {
        type: SET_SHOW_RIGHT_MENU,
        showRightMenu: showRightMenu
    }
}

export const setLeftMenuComponent = (component, componentName, isFirst = false, isOne = false) => {
    return {
        type: SET_LEFT_MENU_COMPONENT,
        leftMenuComponent: component,
        componentName: componentName,
        isFirst: isFirst,
        isOne: isOne
    }
}

export const setRemoveLeftMenuComponent = () => {
    return {
        type: SET_REMOVE_LEFT_MENU,
        leftMenuComponent: [],
    }
}

export const setRightMenuComponent = (component, componentName, isFirst = false, rightMenuTitle, withHeader, componentTitle = null, closeMenu) => {
    return {
        type: SET_RIGHT_MENU_COMPONENT,
        rightMenuComponent: component,
        componentName: componentName,
        componentTitle: componentTitle,
        rightMenuTitle: rightMenuTitle,
        withHeader: withHeader,
        isFirst: isFirst,
        closeMenu: closeMenu
    }
}

export const setRemoveRightMenuComponent = () => {
    return {
        type: SET_REMOVE_RIGHT_MENU,
        leftMenuComponent: [],
    }
}

export const getNotifications = () => {
    return (dispatch) => {

    }
}

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        token: token
    }
}