import React from "react";
import Tags from "./tags";
import ControlButtons from "items/control-buttons/control-buttons";
import TagsItemContainer from "./tags-item/tags-item-container";

class TagsController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            hiddenColumns: [2, 3, 6],
        }
    }

    closeMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    updateCompanyTag = (companyId, tagId, tag) => {
        this.props.updateCompanyTag(companyId, tagId, tag);
        this.closeMenu()
    }

    showRightMenu = (show, title, index) => {
         if(show){
            this.props.setShowRightMenu(true)
            this.props.setRightMenuComponent(<TagsItemContainer companyId={this.props.companyId} updateCompanyTag={this.updateCompanyTag} colors={this.props.colors} tag={this.props.tags.tags[index]} getValue={this.props.getValue}/>, 'TagsItem', null, "EDIT_TAG", true);
        }
        else{
            this.props.setRightMenuComponent(null);
        }
    }

    componentDidMount(){
        if(!this.props.tags || !this.props.tags.length){
            this.props.getCompanyTags(this.props.companyId);
        }
    }

    componentDidUpdate(){
        if(!this.props.tags){
            this.props.getCompanyTags(this.props.companyId);
        }
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }


    onTableItemsClick = (type, key, index) => {
        switch(type){
            case 'delete':{
                this.props.deleteTag(this.props.companyId, key);
                break;
            }
            case 'show':{
                this.showRightMenu(true, type, index);
                break;
            }
            case 'edit':{
                this.showRightMenu(true, type, index);
                break;
            }
        }
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        
        c = [...items];

        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index} />
    }

    setTableValues = (tableValues) => {

        let items = [];
        let styles = [];
        tableValues.forEach((k, i)=>{
            
            items[i] = k
            styles.push({background: k[2], color: k[3]})
            if(typeof(k[k.length-1]) !== "object"){
                let id = k[0];
                let status = k[0];
                let ctrl = this.props.controlButtons;
                k[0] = i+1
                //item = [i+1, k.name, this.getControlButtonItems(id, status, ctrl, i)]
                items[i].push(this.getControlButtonItems(id, status, ctrl, i))

            }
            
            
        })
        return {items: items, styles: styles}
    }

    render(){
        let items = (this.props.tags.data && this.props.tags.data.length) && this.setTableValues(this.props.tags.data).items
        let styles = (this.props.tags.data && this.props.tags.data.length) && this.setTableValues(this.props.tags.data).styles
        return(
            <Tags {...this.props} tableHeader={this.getTableHeader(this.props.tableHeader)} data={items} styles={styles} hiddenColumns={this.state.hiddenColumns} />
        )
    }

    componentWillUnmount(){
        this.closeMenu();
    }
}

export default TagsController;