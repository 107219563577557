import React from "react";
import EditCompanyAccount from "./edit-account";

class EditAccountController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            accountId: this.props.Account.id
        }
    }

    componentDidMount(){
        //this.props.getAllTopics(0, 9999, 'ACTIVE');

        /*if(this.props.isSuperadmin){
            this.props.getSuperadminCompanies('ALL', 0, 100); //get all companies for search company name
        }*/
        if(this.props.Account.id && this.props.companyId){
            
            this.props.getAccountTopics(this.props.companyId ? this.props.companyId : this.props.Account.companyId, this.props.Account.id, this.props.isSuperadmin)
        }
        if(this.props.Account.id && this.props.Account.companyId){
            this.props.getAccountTopics(this.props.Account.companyId, this.props.Account.id, this.props.isSuperadmin);
            this.props.getCompanyTopics(this.props.Account.companyId, this.props.isSuperadmin)
        }
    }

    componentDidUpdate(){
        if(this.state.accountId !== this.props.Account.id){
            if(this.props.Account.id && this.props.companyId){
                //this.props.getCompanyTopics(this.props.Account.companyId, this.props.isSuperadmin)
                this.props.getAccountTopics(this.props.Account.companyId, this.props.Account.id, this.props.isSuperadmin);
            }
            if(this.props.Account.id && this.props.Account.companyId){
                this.props.getAccountTopics(this.props.Account.companyId, this.props.Account.id, this.props.isSuperadmin);
                this.props.getCompanyTopics(this.props.Account.companyId, this.props.isSuperadmin)
            }
            this.setState({
                accountId: this.props.Account.id
            })
        }
    }

    getCompanyName = (companyId) => {
        let f = this.props.allCompanies.filter(k => k.id === companyId);
        return f
    }

    render(){
        return (
            <EditCompanyAccount {...this.props} getCompanyName={this.props.getCompanyName}/>
        )
    }
}

export default EditAccountController