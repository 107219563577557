import React from "react";
import ShortAnalyticChartItem from "./short-analytic-chart-item";

class ShortAnlayticChartItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data: props.data
        }
    }

    componentDidMount(){
        if(this.props.topicIds){
            this.props.getShortAnalytic(this.props.topicIds, this.props.mediaType)
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.data && nextProps.data.topicId !== nextState.data.topicId){
            return true
        }
        else return false
    }

    render(){
        return (this.props.data && this.props.data.topicId) ? <ShortAnalyticChartItem {...this.props} /> : <div className="AnalyticItem"></div>
    }

}

export default ShortAnlayticChartItemController;