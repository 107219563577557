import { header, getLanguage } from "./header";

const notification = header('restapi/api/v1/notifications/');

const setLanguage = () => {
    notification.defaults.headers.common['Accept-Language'] = getLanguage()
}

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    notification.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

export const NotificationService = {
    getTopicsNotifications(token){
        setLanguage();
        getAuth();
        return notification.post('fcm/topics/general/subscribe', {"fcmToken": token}).then(response => {
            return response
        })
    }
    /*sendTopicsNotifications(token){
        getAuth()
        return notification.post('fcm/topics/general', {"fcmToken": token, secret: "4KE_d=!5&TZDRbkf"}).then(response => {
            return response
        })
    }*/
}