import { header, getLanguage } from "./header";

const accounts = header('restapi/api/v1/accounts/');

const setLanguage = () => {
    accounts.defaults.headers.common['Accept-Language'] = getLanguage()
}

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    accounts.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const getLangCode = () => {

}

export const AccountService = {
    getMe(){
        setLanguage();
        getAuth();
        return accounts.get('me').then(response => {
            return response
        })
    },
    locale(lang){
        setLanguage();
        getAuth();
        return accounts.put(`locale/${lang}`)
    },
    getAuthorities(){
        setLanguage();
        getAuth();
        return accounts.get(`authorities`).then( response => {
            return response.data
        })
    },
    updateAccount(fio, email, phone, locale){
        setLanguage();
        getAuth();
        return accounts.patch(`me`, {fio, email, phone, locale}).then( response => {
            return response.data
        })
    },
    updateAccountData(fio, email, phone, locale){
        setLanguage();
        getAuth();
        let params = {}
        if(fio && fio.length) params.fio = fio;
        if(email && email.length) params.email = email;
        if(phone && phone.length) params.phone = phone;
        if(locale && locale.length) params.locale = locale;
        return accounts.put(`me`, {...params}).then( response => {
            
            return response.data
        })
    }
}