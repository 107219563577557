import React, { useEffect, useState } from "react";
import { InputCheckBox } from "commons/input-controls";
import {ReactComponent as AngleUpIcon } from "assets/icons/angle-up.svg";
import {ReactComponent as AngleDownIcon } from "assets/icons/angle-down.svg";
import s from "./topics-select-menu.module.scss";
import { InputText } from "commons/input-controls";

const TopicsSelectMenu = (props) => {
  
    const getIsSelectedALL = (selectedTopicsItems, topics) => {
        if(Object.keys(selectedTopicsItems).length === topics.length)
            return true
        else return false
    }

    const [topics, setTopics] = useState(props.topics ? props.topics : []);
    const [selectedItems, setSelectedItems] = useState(props.selectedTopicsItems);
    const [searchTopicTitle, setSearchTopicTite] = useState(null)
    const [selectedSubItems, setSelectedSubItems] = useState({})
    const [showSubtopics, setShowSubtopics] = useState({})
    const [isSelectAll, setIsSelectAll] = useState(getIsSelectedALL(props.selectedTopicsItems, props.topics));
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        setTopics(props.topics ? props.topics : []);
        setSelectedItems(props.selectedTopicsItems);
        setIsSelectAll(getIsSelectedALL(props.selectedTopicsItems, props.topics));
        setRerender(!rerender);
    }, [props.topics, props.selectedTopicsItems, props.topics])

    const showSubtopic = (uuid, index) => {
        let ss = showSubtopics;
        if(ss[uuid]){
            delete ss[uuid]
        }
        else
            ss[uuid] = true
        setShowSubtopics(ss)
        setRerender(!rerender);
    }

    const isSelectedAll = (length) => {
        if(length === topics.length){
            setIsSelectAll(true)
        }
        else
            setIsSelectAll(false)
    }

    const selectItem = (uuid, type, topicUUID, selected) => {
        props.setSelectedTopicsItem(uuid, selected, type, topicUUID)
    }

    const getItems = (name, uuid, index, withSubtopics, type, topicUUID) => {
        let selectedItem = selectedItems[uuid] || selectedSubItems[uuid] ? true : false

        return (
                <div key={index} className={s.itemRow+' '+s.itemBetween}>
                    <div className={s.itemRow}>
                        <InputCheckBox isChecked={selectedItem} name={uuid} callback={selectItem.bind(this, uuid, type, topicUUID)}/>
                        <span>{name}</span>
                    </div>
                    {withSubtopics 
                    ? <div onClick={showSubtopic.bind(this, uuid, index)}>{showSubtopics[uuid] ? <AngleDownIcon /> : <AngleUpIcon />}</div> 
                    : null}
                </div>
                
            
        )
    }

    const menu = topics.map((k, index) => <div key={index}>
        {getItems(k.name, k.uuid, index, k.subtopics && k.subtopics.length, 'topic')}
        {k.subtopics && k.subtopics.length 
            ? showSubtopics[k.uuid] 
                                    ? <div className={s.itemItemRow}>{ k.subtopics.map( (j, i) => getItems(j.name, j.uuid, i, false, 'subtopic', k.uuid))}</div> 
                                    : null
            : null
        }
    </div>)

    const selectAll = (select) => {
        let s = {}
        if(select)
            topics.forEach(k => {s[k.uuid] = true});
        setSelectedItems(s);
        setIsSelectAll(select);
        props.setSelectedTopicsItems(s, 'topic')
        setRerender(!rerender);
    }

    const setSearchTopicsName = (e) => {

        setSearchTopicTite(e)
        let t = props.topics.filter((k, i) => {if(k.name.toLowerCase().indexOf(e.toLowerCase()) >= 0){ return k}});
        if(e.length){
            setTopics(t)
        }
        else
            setTopics(props.topics)

        setRerender(!rerender)
    }

    return (
        <div className={s.TopicsSelectMenu}>
            <div className={s.MenuHeader}>
                <InputText callback={setSearchTopicsName}/>
                <div className={s.MenuHeaderCheckAll}>
                    <InputCheckBox isChecked={isSelectAll} callback={selectAll}/>
                    <span>{props.getValue('CHOOSE_ALL_TOPICS')}</span>
                </div>

            </div>
            <div className={s.MenuBlock}>
                {menu}
            </div>
        </div>
    )
}

export default TopicsSelectMenu;