import { header, getLanguage } from "./header";

const sentiment = header('storage/api/v1/documents/sentiments/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    sentiment.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    sentiment.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const SentimentService = {
    setSentiment(signature, sentimentType){
        setLanguage();
        getAuth();
        return sentiment.patch(sentimentType, signature)
    },
    setSentimentOfList(signatures, sentimentType){
        setLanguage();
        getAuth();
        return sentiment.patch(sentimentType, signatures)
    }
}