import moment from "moment";

const SET_START_DATE = "SET-START-DATE";
const SET_END_DATE = "SET-END-DATE";
const SET_GROUPING = "SET-GROUPING";
const SET_DATE_CHANGED = "SET-DATE-CHANGED";

const today = new Date();
const dayNow = new Date().setHours(23, 59, 59, 999);

const isMonthlyGrouping = (startDay, endDay) => {
    let e = endDay
    let s = moment(startDay).add(2, 'months')
    let ret = moment(e).isAfter(s) ? true : false
    return ret
}

const isWeeklyGrouping = (startDay, endDay) => {
    let e = endDay
    let s = moment(startDay).add(1, 'months')
    let ret = moment(e).isAfter(s) ? true : false

    return ret
}

const isDailyGrouping = (startDay, endDay) => {
    let e = endDay
    let s = moment(startDay).add(48, 'hours')
    let ret = moment(e).isAfter(s) ? true : false

    return ret
}

const isHourGrouping = (startDay, endDay) => {
    let e = endDay
    let s = moment(startDay).add(48, 'hours')
    let ret = moment(e).isBefore(s) ? true : false

    return ret
}

export const getGroupingByPeriod = (startDay, endDate) => {
    return (dispatch) => {
        if(isMonthlyGrouping(startDay, endDate)){
            return "MONTHLY";
        }
        else if(isWeeklyGrouping(startDay, endDate)){
            return "WEEKLY";
        }
        else if(isDailyGrouping(startDay, endDate)){
            return "DAILY";
        }
        else if(isHourGrouping(startDay, endDate)){
            return "HOURLY";
        }
        else{
            return "DAILY";
        }
    }

}

const getDate = (day) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if(day === 'start'){
        if(params.get('since')){
            return new Date(params.get('since'))
        }
        else{
            return sessionStorage.getItem('dateFrom') 
                ? sessionStorage.getItem('dateFrom') 
                : new Date(today.getFullYear(), today.getMonth(), today.getDate()-6)//.getTime() / 1000
        }
    }
    else{
        if(params.get('till')){
            return new Date(params.get('till'))
        }
        else{
            return sessionStorage.getItem('dateTo') 
                ? sessionStorage.getItem('dateTo') 
                : new Date(dayNow)//.getTime() / 1000
        }
    }

}

const getGrouping = () => {
    return sessionStorage.getItem('grouping') ? sessionStorage.getItem('grouping') : "DAILY"
}

const startDate = getDate('start');

const endDate = getDate('end')

const groupingType = getGrouping();

const calendarData = {
    startDate: startDate,//sessionStorage.getItem('dateFrom') ? sessionStorage.getItem('dateFrom') : new Date(today.getFullYear(), today.getMonth(), today.getDate()-6).getTime() / 1000,
    endDate: endDate,//sessionStorage.getItem('dateTo') ? sessionStorage.getItem('dateTo') : new Date(day).getTime() / 1000,
    grouping: groupingType,
    dateChanged: false,
    minYear: 2017,
    months: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OKT", "NOV", "DEC"],
    days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
}

const Calendar = (state = calendarData, action) => {
    switch(action.type){
        case SET_START_DATE:
            let sd = moment(action.startDate).unix();//new Date(action.startDate).getTime() / 1000;
            if(Number(sd)) sessionStorage.setItem('dateFrom', sd);
            return { ...state, startDate: action.startDate}
        case SET_END_DATE:
            let ed = moment(action.endDate).unix();
            ed = ed < Number(sessionStorage.getItem('dateFrom')) ? Number(sessionStorage.getItem('dateFrom')) : ed;
            (action.endDate && Number(ed)) ? sessionStorage.setItem('dateTo', ed) : sessionStorage.setItem('dateTo', new Date().getTime() / 1000);
            return { ...state, endDate: action.endDate}
        case SET_GROUPING:
            sessionStorage.setItem('grouping', action.grouping)
            return {...state, grouping: action.grouping}
        case SET_DATE_CHANGED:
            return {...state, dateChanged: action.dateChanged}
        default:
            return { ...state }
    }
}

export default Calendar;

export const startDateSet = (startDate) => {
    return {
        type: SET_START_DATE,
        startDate: startDate
    }
}

export const endDateSet = (endDate) => {
    return {
        type: SET_END_DATE,
        endDate: endDate
    }
}

export const setGrouping = (grouping) => {
    return {
        type: SET_GROUPING,
        grouping, grouping
    }
}

export const setGroupingByPeriod = (startDay, endDate) => {
    return (dispatch) => {
        if(isMonthlyGrouping(startDay, endDate)){
            dispatch(setGrouping("MONTHLY"));
        }
        else if(isWeeklyGrouping(startDay, endDate)){
            dispatch(setGrouping("WEEKLY"));
        }
        else if(isDailyGrouping(startDay, endDate)){
            dispatch(setGrouping("DAILY"));
        }
        else if(isHourGrouping(startDay, endDate)){
            dispatch(setGrouping("HOURLY"));
        }
        else{
            dispatch(setGrouping("DAILY"));
        }
    }

}

export const setDateChanged = (dateChanged) => {
    return {
        type: SET_DATE_CHANGED,
        dateChanged: dateChanged
    }
}

export const setStartDate = (startDate) => {
    return (dispatch) => {
        dispatch(startDateSet(startDate));
        dispatch(setDateChanged(true))
    }
}

export const setEndDate = (endDate) => {
    return (dispatch) => {
        dispatch(endDateSet(endDate));
        dispatch(setDateChanged(true))
    }
}
