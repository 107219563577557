import { header, getLanguage } from "./header";

const feedback = header('restapi/api/v1/feedback');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    feedback.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    feedback.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const FeedbackService = {
    feedback(fullname, email, contacts, feedbackText){
        setLanguage();
        return feedback.post('', {fullname, email, contacts, feedbackText}).then(response => {
            return response
        })
    }
}