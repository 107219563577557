import React from "react";
import ModalReport from "./modal-report";

class ModalReportController extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            emails: [props.email]
        }
    }

    componentWillUnmount(){
        this.props.setReportSended(false)
    }

    render(){
        return <ModalReport {...this.props}/>
    }
}

export default ModalReportController;