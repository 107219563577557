import React from "react";
import CheckboxList from "./checkbox-list";

class CheckboxListController extends React.Component{

    constructor(props){
        super(props)
        this.state = ({
            isChanged: props.reportSettingsChanged
        })
    }

    componentDidUpdate(){
        if(this.state.isChanged !== this.props.reportSettingsChanged){
            this.setState({
                isChanged: this.props.reportSettingsChanged
            })
        }
    }

    render(){
        return(
            <CheckboxList {...this.props} isChanged={this.state.isChanged}/>
        )
    }
}

export default CheckboxListController;