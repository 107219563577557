import AdminMarkerAddItemController from "./admin-markers-add-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getAccounts, getTopicAccounts, getCompanyAccounts, attachAccountsToTopic, detachAccountsFromTopic, attachTopicsToAccount } from "store/superadmin";
import { getActiveAccountsName, getAccountsOnlyName, getAdminMarkersName, getAllAdminTopicsName, getMarkerTopicsId } from "selectors/superadmin-selector";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getAllMarkers, getMarkerTopics } from "store/admin-markers";
import { getAdminTopicsNameAndId } from "selectors/admin-topics-selector";

let mapStateToProps = (state) => {
    return {
        isSuperadmin: state.Account.isSuperadmin,
        topicAccounts: getAccountsOnlyName(state, true),
        //accounts: getActiveAccountsName(state),
        adminTopics: getAdminTopicsNameAndId(state), //.AdminTopics.topics,
        markerTopics: getMarkerTopicsId(state) //.AdminMarkers.markerTopics,
    }
}

const AdminMarkersAddItemContainer = connect(mapStateToProps, {     
                                                                getValue, 
                                                                getAllMarkers,
                                                                getMarkerTopics,
                                                                getTopicAccounts,
                                                                getCompanyAccounts,
                                                                attachAccountsToTopic,
                                                                detachAccountsFromTopic,
                                                                setRightMenuComponent, 
                                                                setShowRightMenu, 
                                                                setRemoveRightMenuComponent,
                                                                attachTopicsToAccount
                                    })(AdminMarkerAddItemController)

export default AdminMarkersAddItemContainer