import React, { useEffect, useState } from "react";
import { InputText, SelectDropdown, InputTimeBlock, InputDateBlock} from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import { getFormatedDate, getFormatDate } from "service/format-date";
import { ReactComponent as RetryIcon } from "assets/icons/retry.svg";
import moment from "moment";
import s from "./migration-create-item.module.scss"

const MigrationCreateItem = (props) => {

    const getTime = (date) => {
        let time = {}
        time.h = moment(date).format('H');
        time.m = moment(date).format('m');
        return time
    }

    const getDate = (date) => {
        let time = {}
        time.d = moment(date).format('DD');
        time.m = moment(date).format('MM');
        time.y = moment(date).format('YYYY');

        return time
    }

    const [companies, setCompanies] = useState(props.companies)
    const [selectedCompanyS, setSelectedCompanyS] = useState(props.selectedCompanyItem);
    const [selectedCompanyTopic, setSelectedCompanyTopic] = useState(props.selectedCompanyTopic);
    const [selectedType, setSelectedType] = useState(props.selectedType);
    const [companyTopics, setCompanyTopics] = useState(props.companyTopics);
    const [numfound, setNumfound] = useState(props.numfound);
    const [startDate, setStartDate] = useState(getDate( moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss") ));
    const [startDateTime, setStartDateTime] = useState(getTime(moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss")));
    const [endDate, setEndDate] = useState(getDate( moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss") ));
    const [endDateTime, setEndDateTime] = useState(getTime(moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss")));
    const [selectedCollection, setSelectedCollection] = useState(props.selectedCollection);

    useEffect(() => {
        setCompanyTopics(props.companyTopics);
        setSelectedCompanyS(props.selectedCompanyItem);
        setSelectedType(props.selectedType);
        setSelectedCompanyTopic(props.selectedCompanyTopic);
        setNumfound(props.numfound);
        setCompanies(props.companies);
        /*setStartDate(getDate( moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss") ));
        setStartDateTime( getTime(moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss")));
        setEndDate(getDate( moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss") ));
        setEndDateTime( getTime(moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss")));*/
        setSelectedCollection(props.selectedCollection);
    }, [props]);

    const getDateFormat = (d, t) => {
        let fdate = d.y+"-"+d.m+"-"+d.d+" "+t.h+":"+t.m;

        let r = moment(fdate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
        
        return r;
    }

    const setSelectedCompanyItem = (item) => {
        if(item && item.name){
            props.setSelectedCompanyItem(item)
            //props.getCompanyTopics(item.id, true);
        } // ? setSelectedCompany(item.name) : null
        
    }

    const setSelectedCompanyTopicItem = (item) => {
        item && item.name ? props.setSelectedTopic(item) : null
        if(selectedCompanyS && item && item.name){
            props.getTopic(item.id)
            //getNumfound(item.uuid)
        }
        //props.getCompanyTopics(item.id, true)
    }

    const setSelectedTypeItem = (item) => {
        props.setSelectedType(item)
    }

    const getNumfound = (topic) => {
        //topicUUID, type, collection
        props.getMigrationNumFound(topic, types[0], 'archive-new')
    }
    
    const setNumber = (index, value, type, time) => {
        if(type === 'first'){
            let h = value
            
            let t = {h: h, m: time.m}
            return t
        }
        else if(type === 'second'){
                let m = value
                let t = {h: time.h, m: m}
                return t
        }
    }

    const setDateNumber = (index, value, type, date) => {
        if(type === 'day'){

            let d = value < 10 ? 0+''+value : value
            let t = {d: d, m: date.m, y: date.y}

            return t
        }
        else if(type === 'month'){
                let m = value < 10 ? 0+''+value : value
                let t = {d: date.d, m: m, y: date.y}
                return t
        }
        else if(type === 'year'){
            let y = value < 10 ? 0+''+value : value
            let t = {d: date.d, m: date.m, y: y}
            return t
        }
    }

    const setDate = (type, name, value, val) => {
        switch(type){
            case 'DateStart':
                setStartDate(setDateNumber(0, value, val, startDate), startDateTime);
                break;
            case 'DateEnd':
                let de = setDateNumber(0, value, val, endDate)

                let ds = getDateFormat(startDate, startDateTime)
                
                if(moment(getDateFormat(de, endDateTime)).isAfter(moment(ds)))
                setEndDate(de);
                break;
            case 'TimeStart':
                setStartDateTime(setNumber(0, value, val, startDateTime));
                break;
            case 'TimeEnd':
                setEndDateTime(setNumber(0, value, val, endDateTime));
                break;
        }
    }

    const setSelectedCollectionItem = (item) => {
        props.setSelectedCollection(item)
    }

    const getFoundedNumber = () => {
        let s = getDateFormat(startDate, startDateTime)
        let e = getDateFormat(endDate, endDateTime)

        props.getfoundedNumber(s, e)
    }

    const startMigration = () => {
        let s = getDateFormat(startDate, startDateTime)
        let e = getDateFormat(endDate, endDateTime)
        props.startMigration(s, e)

    }

    return (
        <div className={s.MigrationItem}>
            <div className="formRow">
                <div className="labelText">{props.getValue('COMPANY_NAME')}</div>
                <SelectDropdown selected={selectedCompanyS ? selectedCompanyS.name : null} items={companies} height={200} setSelected={setSelectedCompanyItem}/>
            </div>
            {(selectedCompanyS && companyTopics) ? <div className="formRow">
                <div className="labelText">{props.getValue('TOPIC_NAME')}</div>
                    <SelectDropdown selected={selectedCompanyTopic ? selectedCompanyTopic.name : null} items={companyTopics} height={200} setSelected={setSelectedCompanyTopicItem}/>
                </div> : null}
            <div className="formRow">
                <div className="labelText">{props.getValue('TYPE')}</div>
                <SelectDropdown selected={selectedType} items={props.types} height={200} setSelected={setSelectedTypeItem}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('COLLECTION')}</div>
                <SelectDropdown selected={selectedCollection} items={props.collections} height={200} setSelected={setSelectedCollectionItem}/>
            </div>
            <div className="formRow">
                <span className="labelText">{props.getValue('DATE_START')}</span>
                <div className={s.DateBlock}>
                    {<InputDateBlock values={startDate} name={'date'} callback={setDate.bind(this, 'DateStart')} maxFirst={31} minFirst={1} maxSecond={12} minSecond={1} maxThird={moment().format("YYYY")} minThird={2017}/>}
                    {<InputTimeBlock values={startDateTime} name={'hour'} callback={setDate.bind(this, 'TimeStart')} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/> }
                </div>
            </div>
            <div className="formRow">
                <span className="labelText">{props.getValue('DATE_END')}</span>
                <div className={s.DateBlock}>
                    <div>
                        {<InputDateBlock values={endDate} name={'date'} callback={setDate.bind(this, 'DateEnd')} maxFirst={31} minFirst={1} maxSecond={12} minSecond={1} maxThird={moment().format("YYYY")} minThird={2017}/>}
                    </div>
                    <div>
                        {<InputTimeBlock values={endDateTime} name={'hour'} callback={setDate.bind(this, 'TimeEnd')} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/> }
                    </div>
                </div>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('EXPECTED_DOCUMENTS')}</div>
                <div className={s.migrationRow}>
                    <InputText value={Number(numfound)} readOnly={true}/>
                    <StandartButton disabled={!selectedType || !selectedCompanyTopic || !selectedCollection} icon={RetryIcon} stroke={'white'} name={props.getValue('GET_EXPECTED_DOCUMENTS')} callback={getFoundedNumber}/>
                </div>

            </div>
            <div className="formRow">
                <StandartButton disabled={!selectedCompanyTopic || !selectedType || !selectedCollection} title={props.getValue('START_MIGRATION')} callback={startMigration}/>
            </div>
        </div>
    )
}

export default MigrationCreateItem