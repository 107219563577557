import React from "react";
import SaveFiltersSettings from "./save-filters-settings";
import { getFormatedDate } from "service/format-date";
import moment from 'moment';

class SaveFiltersSettingsController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            grouping: this.props.grouping,
            selectedItems: this.props.selectedItems,
        }
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date)
    }

    createNewFilter = (filterName) => {
        let subtopics = this.props.selectedSubUUId ? {subtopicIds: [this.props.selectedSubUUId]} : []
        let filters = {...this.props.selectedItems, ...subtopics}
        if(this.props.searchList && Object.keys(this.props.searchList).length !== 0){
            filters.clauses = this.props.searchList
        }
        this.props.createNewFilter(filterName, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping, filters)
        this.props.modalShowToggle(false)
    }

    updateFilter = (filterName, id) => {
        let subtopics = this.props.selectedSubUUId ? {subtopicIds: [this.props.selectedSubUUId]} : []
        this.props.selectedItems.clauses = [];

        let filters = {...this.props.selectedItems, ...subtopics, }
        if(this.props.searchList && Object.keys(this.props.searchList).length !== 0){
            filters.clauses = this.props.searchList
        }
        this.props.updateMyFilter(id, filterName, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping, filters)
        this.props.modalShowToggle(false)
    }

    render(){
        return <SaveFiltersSettings {...this.props} createNewFilter={this.createNewFilter} updateFilter={this.updateFilter}/>
    }

}

export default SaveFiltersSettingsController;
