import { header, getLanguage } from "./header";

const digest = header('report/api/v1/digest-settings/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    digest.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
    digest.defaults.headers.common['Content-Type'] = `application/json`
}

const setLanguage = () => {
    digest.defaults.headers.common['Accept-Language'] = getLanguage();
    
}

export const DigestService = {
    getUserDigestSettings(pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return digest.get(`?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(response => {
            return response.data
        })
    },
    createDigestSettings(uuid, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType){
        setLanguage();
        getAuth();
        let params = {type, digestSchedule, includeAnalytics, reportFeedType}
        if(emails) params.emails = emails
        if(filterId) params.filterId = filterId
        return digest.post(`topics/${uuid}`, {...params}).then(response => {
            return response.data
        })
    },
    createDigestSubtopicSettings(uuid, subtopicUUID, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType){
        setLanguage();
        getAuth();
        let params = {type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType}
        return digest.post(`topics/${uuid}/subtopics/${subtopicUUID}`, {...params}).then(response => {
            return response.data
        })
    },
    deleteDigestSettings(id){
        setLanguage();
        getAuth();
        return digest.delete(`${id}`).then(response => {
            return response.data
        })
    },
    updateDigestSettings(id, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType){
        setLanguage();
        getAuth();
        let params = {type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType}
        return digest.put(`${id}`, {...params}).then(response => {
            return response.data
        })
    },
    updateDigestSubtopicSettings(id, uuid, subtopicUUID, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType){
        setLanguage();
        getAuth();
        let params = {type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType}
        return digest.put(`${id}/topics/${uuid}/subtopics/${subtopicUUID}`, {...params}).then(response => {
            return response.data
        })
    },
    updateDigestDifferentTopic(id, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType){
        setLanguage();
        getAuth();
        let params = {type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType}
        return digest.put(`${id}/topics/${topicUUId}`, {...params}).then(response => {
            return response.data
        })
    },
    activateDigestSettings(id){
        setLanguage();
        getAuth();
        return digest.put(`${id}/activate`, {}, {headers: { 'Content-Type': 'application/json'},data: {}}).then(response => {
            return response.data
        })
    },
    pauseDigestSettings(id){
        setLanguage();
        getAuth();
        return digest.put(`${id}/pause`, {}, {headers: { 'Content-Type': 'application/json'},data: {}}).then(response => {
            return response.data
        })
    }

}