import React, { useState } from "react";
import { InputText } from "../../commons/input-controls";
import { StandartButton } from "../../commons/form-controls";
import s from "./forgot-password.module.scss";
import { ReactComponent as OkIcon } from '../../assets/icons/ok.svg'
import { ReactComponent as RestartIcon } from '../../assets/icons/restart.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'


const ForgotPassword = (props) => {
    let [emailOrLogin, setEmailOrLogin] = useState('')

    const clickOnButton = e => {    
        let type = "login";
        if(emailOrLogin.includes("@"))
            type = 'email';
        else {
            type =  "login";
            emailOrLogin = emailOrLogin.toLowerCase();
        }
        props.callback(e, type, emailOrLogin);
        props.modalShowToggle(e)
    }

    const resetOrClose = (e) => {
        let type = 'reset'
        if(e === 'restart'){
            props.modalShowToggle(false)
            props.callback(e, type, emailOrLogin);
            return;
        }
        else if('close'){
            props.modalShowToggle(false)
            props.callback(e, type, emailOrLogin);
            return;
        }
        else{
            props.modalShowToggle(false);
            //props.callback(e, type, emailOrLogin);
            return;
        }
    }

    const onKeyWordChange = (text) => {
        setEmailOrLogin(text)
    }

    const input = props.modalName === 'ForgotPasswordError' 
        ? <div className={s.ErrorMessageBlock}><span className={s.ErrorMessage}>{props.getValue('SORRY_SUCH_MAIL_OR_LOGIN_IS_NOT_REGISTERED')}</span></div> 
        : <InputText value={emailOrLogin} callback={onKeyWordChange} name="forgotPassword" type="text" placeholder={props.getValue("EMAIL_OR_LOGIN")}/>

    const buttons =  props.modalName === 'ForgotPasswordError' 
        ? <div className={s.buttonsRow}>
            <StandartButton type="button" title={props.getValue('TRY_AGAIN')} icon={RestartIcon} callback={resetOrClose.bind(this, 'restart')} stroke={'white'}/>
            <StandartButton type="button" title={props.getValue('CANCEL')} icon={CloseIcon} callback={resetOrClose.bind(this, 'close')} stroke={'white'}/>
        </div>
        : <div className={s.buttonsRow}>
                        <StandartButton disabled={!emailOrLogin} type="button" title={props.getValue("CONTINUE")} icon={OkIcon} callback={clickOnButton.bind(this, 'emailOrLogin')} stroke={'white'}/>
                    </div>

    return (
        <div className={s.ForgotPassword}>
            {input}
            <div className={s.ForgotPasswordButton}>
                {buttons}
            </div>
        </div>
    )
}

export default ForgotPassword;