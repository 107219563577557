import { header, getLanguage } from "./header";

const geoplace = header('geoplace/api/v1/')

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    geoplace.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    geoplace.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const GeoplaceService = {
    async getGeoplaceName(geoplaceId){
        setLanguage();
        getAuth();
        return await geoplace.get(`geoplaces/names/${geoplaceId}`).then( response => {
            return response
        })
    },
    getGeoplaceCountries(){
        setLanguage();
        getAuth();
        return geoplace.get(`geoplaces/countries`).then( response => {
            return response.data
        })
    },
    getGeoplaceRegions(){
        setLanguage();
        getAuth();
        return geoplace.get(`geoplaces/regions`).then( response => {
            return response.data
        })
    },
    getGeoplaceDictionary(locationsIds){
        setLanguage();
        getAuth();
        return geoplace.post(`geoplaces/names/dict`, [...locationsIds], {headers: { 'Content-Type': 'application/json'},data: {}}).then( response => {
            return response.data
        })
    }
}