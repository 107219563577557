import React from "react";
import RightMenu from "./right-menu";

class RightMenuController extends React.Component{

    render(){
        return <RightMenu {...this.props} />
    }

}

export default RightMenuController;