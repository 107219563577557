import { createSelector } from "reselect";
import {getLocation, getLocationById} from "../store/geoplace";
import { getMediaTypesLabelText } from "assets/titles/title-text"
import moment from "moment";
import { getFormatedDate } from "service/format-date";

const range = (state) => {
    return state.ExtendedAnalytic.rangeData;
}

const data = (state) => {
    return state.ExtendedAnalytic.data
}

const languageRange = (state) => {
    return state.ExtendedAnalytic.languageRangeData;
}

const language = (state) => {
    return state.ExtendedAnalytic.languageData;
}

const sentimentRange = (state) => {
    return state.ExtendedAnalytic.sentimentRange
}

const sentimentState = (state) => {
    return state.ExtendedAnalytic.sentiment
}

const publicationsTypeRange = (state) => {
    return state.ExtendedAnalytic.publictationsTypeRange
}

const publicationsType = (state) => {
    return state.ExtendedAnalytic.publictationsType
}

const top20Sentiments = (state) => {
    return state.ExtendedAnalytic.top20Sentiment
}

const top20Languages = (state) => {
    return state.ExtendedAnalytic.top20Languages
}

const mediaTypesSentiment = (state) => {
    return state.ExtendedAnalytic.mediaTypesSentiment
}

const keywordsSentimentRange = (state) => {
    return state.ExtendedAnalytic.keywordsSentimentRange
}

const keywordsSentiment = (state) => {
    return state.ExtendedAnalytic.keywordsSentiment
}

const authorsSentiment = (state) => {
    return state.ExtendedAnalytic.authorsSentiment
}

const tagsSentiments = (state) => {
    return state.ExtendedAnalytic.sentiment
}

const tagsRange = (state) => {
    return state.ExtendedAnalytic.rangeData
}

const involvementSentiment = (state) => {
    return state.ExtendedAnalytic.involvementSentiment
}

const getFollowersCount = (state) => {
    return state.ExtendedAnalytic.followersCount
}

const authorLocation = (state) => {
    return state.ExtendedAnalytic.authorLocations
}

const authorsAges = (state) => {
    return state.ExtendedAnalytic.authorAges
}

const authorsGender = (state) => {
    return state.ExtendedAnalytic.authorGenders
}

const formatDate = (date) =>{
    return getFormatedDate(date)
}

const formatTime = (date) => {
    return moment(date).format("HH:mm")
}

const red = "var(--echoRed)";
const green = "var(--green)";
const grey = "var(--echoSecondary)";
const gray = "#9AAAB5";

const getSentimentName = (sentiment) => {
    switch(sentiment){
        case 'neutral':
            return 'NEUTRAL_3'
        case 'undefined':
            return 'NOT_DETERMINDED_3'
        case "negative":
            return "NEGATIVE_3"
        case "positive":
            return "POSITIVE_3"
    }
}

const getToneColor = (sentiment) => {
    switch(sentiment){
        case 'neutral':
        case 'NEUTRAL_3':
            return grey;
        case 'undefined':
        case 'NOT_DETERMINDED_3':
            return gray;
        case "negative":
        case "NEGATIVE_3":
            return red;
        case "positive":
        case "POSITIVE_3":
            return green;
    }
}

const checkNotNullKeyword = (data) => {
    let result = []
    data.forEach((k, i)=>{
        if(k.value !== 'null'){
            result[i] = k
        }
    })
    return result
}

export const getRange = createSelector(range, (rangeData) => {
    let lang = {data:[[],[],[]], labels:[], topicId: rangeData.topicId, text: ['KAZAKH_3','ENGLISH_3','RUSSIAN_3']}
    if(rangeData.data){
        rangeData.data.map((item,i)=>{
            if(item.values.length){
                switch(item.value){
                    case 'kz':
                        lang.data[0] = item.values.map(k => k.count);
                        if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                        lang.text[0] = 'KAZAKH_3'
                        break;
                    case 'en':
                        lang.data[1] = item.values.map(k => k.count);
                        if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                        lang.text[1] = 'ENGLISH_3'
                        break;
                    case 'ru':
                        lang.data[2] = item.values.map(k => k.count);
                        if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                        lang.text[2] = 'RUSSIAN_3'
                        break;
                }
            }

        })
    }
    return lang;
})

export const getLanguageRange = createSelector(languageRange, (languages) => {
    let lang = {data:[[],[],[]], labels:[], topicId: languages.topicId, text: ['KAZAKH_3','ENGLISH_3','RUSSIAN_3'], list: ['kz', 'en', 'ru']}
    if(languages.data){
        languages.data.map((item,i)=>{
            if(item.values.length){
                switch(item.value){
                    case 'kz':
                        lang.data[0] = item.values.map(k => k.count);
                        if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                        lang.text[0] = 'KAZAKH_3';
                        lang.list[0] = item.value ? item.value : 0;
                        break;
                    case 'en':
                        lang.data[1] = item.values.map(k => k.count);
                        if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                        lang.text[1] = 'ENGLISH_3';
                        lang.list[1] = item.value ? item.value : 0;
                        break;
                    case 'ru':
                        lang.data[2] = item.values.map(k => k.count);
                        if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                        lang.text[2] = 'RUSSIAN_3';
                        lang.list[2] = item.value ? item.value : 0;
                        break;
                }
            }

        })
    }
    return lang;
})

export const getLanguage = createSelector(language, (languages) => {
    let lang = {data:[0, 0, 0], labels:['kz', 'en', 'ru'], topicId: languages.topicId, list: ['kz', 'en', 'ru']}
    if(languages && languages.data){
        languages.data.forEach(item => {
            switch(item.value){
                
                case 'kz':
                    lang.data[0] = item.count ? item.count : 0;
                    break;
                case 'en':
                    lang.data[1] = item.count ? item.count : 0;
                    break;
                case 'ru':
                    lang.data[2] = item.count ? item.count : 0;
                    break;
            }
        });
    }
    return lang
});

const getTotalCount = (data) => {
    return data.reduce((a, b) => {
        return a + b;
    })
}

export const getSentimentRangeSelector = createSelector(sentimentRange, (sentiments) => {
    let sentiment = {data: [[],[],[],[]], labels: [], topicId: sentiments.topicId, text: [], colors: ['','','',''], list: ['NEUTRAL','UNDEFINED','NEGATIVE','POSITIVE']}
    if(sentiments && sentiments.data){
        sentiments.data.forEach((item,i)=>{
            switch(item.value){
                case 'neutral':
                    sentiment.data[0] = item.values.map(k => k.count);
                    if(!sentiment.labels.length) sentiment.labels = item.values.map(k => formatDate(k.value));
                         sentiment.colors[0] = getToneColor('neutral')
                    //}
                    sentiment.text[0] = getSentimentName('neutral');
                    sentiment.list[0] = 'NEUTRAL';
                    break;
                case 'undefined':
                    sentiment.data[1] = item.values.map(k => k.count);
                    if(!sentiment.labels.length) sentiment.labels = item.values.map(k => formatDate(k.value));
                        sentiment.colors[1] = getToneColor('undefined');
                    sentiment.text[1] = getSentimentName('undefined');
                    sentiment.list[1] = 'UNDEFINED';
                    break;
                case 'negative':
                    sentiment.data[2] = item.values.map(k => k.count);
                    if(!sentiment.labels.length) sentiment.labels = item.values.map(k => formatDate(k.value));
                        sentiment.colors[2] = getToneColor('negative')
                    sentiment.text[2] = getSentimentName('negative')
                    sentiment.list[2] = 'NEGATIVE';
                    break;
                case 'positive':
                    sentiment.data[3] = item.values.map(k => k.count);
                    if(!sentiment.labels.length) sentiment.labels = item.values.map(k => formatDate(k.value));
                        sentiment.colors[3] = getToneColor('positive')
                    sentiment.text[3] = getSentimentName('positive')
                    sentiment.list[3] = 'POSITIVE';
                    break;
            }
        })
    }
    return sentiment
});

const checkSentiment = (pivots, data, text, i, withUndefined = false, colors, list) => {
    pivots.forEach(k=>{
        switch(k.value){
            case 'negative':
                data[0][i] = k.count ? k.count : 0;
                text[0] = 'NEGATIVE_3';
                if(list) list[0] = 'NEGATIVE';
                if(colors) colors[0] = getToneColor('NEGATIVE_3');
                break;
            case 'neutral':
                data[1][i] = k.count ? k.count : 0;
                text[1] = 'NEUTRAL_3';
                if(list) list[1] = 'NEUTRAL';
                if(colors) colors[1] = getToneColor('NEUTRAL_3');
                break;
            case 'positive':
                data[2][i] = k.count ? k.count : 0;
                text[2] = 'POSITIVE_3';
                if(list) list[2] = 'POSITIVE';
                if(colors) colors[2] = getToneColor('POSITIVE_3');
                break;
            case 'undefined':
                if(withUndefined){
                    data[3][i] = k.count ? k.count : 0;
                    text[3] = 'NOT_DETERMINDED_3';
                    //list[3] = 'UNDEFINED';
                    if(list) list[3] = 'UNDEFINED';
                    if(colors) colors[3] = getToneColor('NOT_DETERMINDED_3');
                }
        }
    })
    return [data, text, colors && colors, list && list]
}

const checkPublicationsType = (pivots, data, text, i, colors) => {
    pivots.forEach(k=>{
        switch(k.value){
            case 'article':
                data[0][i] = k.count;
                text[0] = 'ARTICLE';
                if(colors) colors[0] = getPublicationTypesColors(0);
                break;
            case 'post':
                data[1][i] = k.count
                text[1] = 'POST_3';
                if(colors) colors[1] = getPublicationTypesColors(1);
                break;
            case 'comment':
                data[2][i] = k.count
                text[2] = 'COMMENT';
                if(colors) colors[2] = getPublicationTypesColors(2);
                break
            case 'share':
                data[3][i] = k.count
                text[3] = 'REPOST_2';
                if(colors) colors[3] = getPublicationTypesColors(3);
                break
            case 'extended_share':
                data[4][i] = k.count
                text[4] = 'ADDED_REPOST_2';
                if(colors) colors[4] = getPublicationTypesColors(4);
                break
        }
    })
    return [data, text, colors && colors]
}

const getPublicationTypesColors = (index) => {
    let colors = ["#F58219", "#9AAAB5", "#9A9EC3", "#EE6766", "#3373B7"];
    return colors[index]
}

export const getSentimentSelector = createSelector(sentimentState, (sentiments) => {

    let lang = {data:[[0,0,0],[0,0,0],[0,0,0]], labels:['kz', 'ru', 'en'], topicId: sentiments.topicId, text: ['NEGATIVE_3', 'NEUTRAL_3', 'POSITIVE_3'], colors: [red, grey, green], list: ['NEUTRAL','UNDEFINED','NEGATIVE','POSITIVE']}

    if(sentiments && sentiments.data){
        sentiments.data.forEach((item) => {
            switch(item.value){
                case 'kz':
                    [lang.data, lang.text, lang.colors, lang.list] = checkSentiment(item.pivots, lang.data, lang.text, 0, false, lang.colors, lang.list);
                    break;
                case 'ru':
                    [lang.data, lang.text, lang.colors, lang.list] = checkSentiment(item.pivots, lang.data, lang.text, 1, false, lang.colors, lang.list)
                    break;
                case 'en':
                    [lang.data, lang.text, lang.colors, lang.list] = checkSentiment(item.pivots, lang.data, lang.text, 2, false, lang.colors, lang.list)
                    break;
            }
        });
    }

    return lang
})

export const getPublicationsTypeRangeSelector = createSelector(publicationsTypeRange, (publicationsType)=>{
    let lang = {data:[], labels:[], topicId: publicationsType.topicId, text: ['article','post', 'comment', 'share', 'comment', 'extended_share'], colors: [], list: ['post', 'comment', 'share', 'article', 'extended_share']}
    if(publicationsType && publicationsType.data){
        lang.data[[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]]
        publicationsType.data.forEach((item, i)=>{
            switch(item.value){
                case 'article':
                    //var p = 0;
                    lang.data[0] = item.values.map(k => k.count);
                    if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                    lang.text[0] = 'ARTICLE';
                    lang.colors[0] = getPublicationTypesColors(0)
                    lang.list[0] = 'ARTICLE';
                    //if(p <= 0) {lang.data[0] = []}
                    break;
                case 'post':
                    //var p = 0;
                    lang.data[1] = item.values.map(k => k.count);
                    if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                    lang.text[1] = 'POST_3';
                    lang.colors[1] = getPublicationTypesColors(1);
                    lang.list[1] = 'POST';
                    //if(p <= 0) {lang.data[1] = []}
                    break;
                case 'comment':
                    //var p = 0;
                    lang.data[2] = item.values.map(k => k.count);
                    if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                    lang.text[2] = 'COMMENT';
                    lang.colors[2] = getPublicationTypesColors(2);
                    lang.list[2] = 'COMMENT';
                    //if(p <= 0) {lang.data[2] = []}
                    break;
                case 'share':
                    //var p = 0;
                    lang.data[3] = item.values.map(k => k.count);
                    if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                    lang.text[3] = 'REPOST';
                    lang.colors[3] = getPublicationTypesColors(3);
                    lang.list[3] = 'SHARE';
                    //if(p <= 0) {lang.data[3] = []}
                    break;
                case 'extended_share':
                    lang.data[4] = item.values.map(k => k.count);
                    if(!lang.labels.length) lang.labels = item.values.map(k => formatDate(k.value));
                    lang.text[4] = 'EXTENDED_REPOST';
                    lang.colors[4] = getPublicationTypesColors(4);
                    lang.list[4] = 'EXTENDED_REPOST';
                    //if(p <= 0) {lang.data[4] = []}
                    break;
            }
        })
    }
    return lang;
})

export const getPublicationsTypeSelector = createSelector(publicationsType, (publicationsType)=>{
    let lang = {data:[], labels:['kz', 'ru', 'en'], topicId: publicationsType.topicId, text: ['ARTICLE', 'POST_3', 'COMMENT', 'REPOST', 'EXTENDED_REPOST'], colors: ["#F58219", "#9AAAB5", "#9A9EC3", "#EE6766", "#3373B7"], list: ['post', 'comment', 'share', 'article', 'extended_share']}
    if(publicationsType && publicationsType.data){
        lang.data = [[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]];
        publicationsType.data.forEach((item) => {
            switch(item.value){
                case 'kz':
                    [lang.data, lang.text, lang.colors] = checkPublicationsType(item.pivots, lang.data, lang.text, 0, lang.colors);
                    break;
                case 'ru':
                    [lang.data, lang.text, lang.colors] = checkPublicationsType(item.pivots, lang.data, lang.text, 1, lang.colors)
                    break;
                case 'en':
                    [lang.data, lang.text, lang.colors] = checkPublicationsType(item.pivots, lang.data, lang.text, 2, lang.colors)
                    break;
            }
        });
    }
    return lang
})

export const getTop20SentimentSelector = createSelector(top20Sentiments, (top20Sentiment) => {
    let sentiment = {data: [], labels: [], topicId: top20Sentiment.topicId, text: [], colors: [], list: ['NEUTRAL','UNDEFINED','NEGATIVE','POSITIVE']}
    if(top20Sentiment && top20Sentiment.data){
        sentiment.data = [[],[],[],[]]
        top20Sentiment.data.forEach((item, i)=>{
            sentiment.data[0][i] = 0
            sentiment.data[1][i] = 0
            sentiment.data[2][i] = 0
            sentiment.data[3][i] = 0
            sentiment.labels[i] = item.value;
            sentiment.text[0] = 'NEGATIVE';
            sentiment.text[1] = 'UNDEFINED';
            sentiment.text[2] = 'NEUTRAL';
            sentiment.text[3] = 'POSITIVE';

            sentiment.colors[0] = getToneColor('NEGATIVE_3');
            sentiment.colors[1] = getToneColor('NOT_DETERMINDED_3');
            sentiment.colors[2] = getToneColor('NEUTRAL_3');
            sentiment.colors[3] = getToneColor('POSITIVE_3');

            item.pivots.forEach((e,j)=>{
                switch(e.value){
                    case 'negative':
                        sentiment.data[0][i] = e.count;
                        break;
                    case 'undefined':
                        sentiment.data[1][i] = e.count; 
                        break;
                    case 'neutral':
                        sentiment.data[2][i] = e.count;
                        break;
                    case 'positive':
                        sentiment.data[3][i] = e.count;
                        break;
                }
            })
        })
    }

    return sentiment
})

export const getTop20LanguagesSelector = createSelector(top20Languages, (top20Language) => {
    let lang = {data:[], labels:[], topicId: top20Language.topicId, text: ['kz', 'ru', 'en'], colors: ["#F58219", "#9AAAB5", "#9A9EC3", "#EE6766", "#3373B7"], labels: []}
    if(top20Language && top20Language.data){
        lang.data = [[0,0,0],[0,0,0],[0,0,0]];
        top20Language.data.forEach((item, i)=>{
            lang.data[0][i] = 0
            lang.data[1][i] = 0
            lang.data[2][i] = 0
            item.pivots.forEach((k, j) => {
                if(k.value === 'kz' || k.value === 'ru' || k.value === 'en'){
                    lang.labels[i] = item.value;
                    if(k.value === 'kz'){
                        lang.data[0][i] = k.count
                        lang.labels[0] = 'kz';
                    }
                    else if(k.value === 'ru'){
                        lang.data[1][i] = k.count;
                        lang.labels[1] = 'ru';
                    }
                    else if(k.value === 'en'){
                        lang.data[2][i] = k.count;
                        lang.labels[2] = 'en';
                    }
                }

            })
        })
    }
    return lang
})

export const getTagsSentimentList = createSelector(sentimentState, (keywordsSentiment)=>{
    
    let keywords = {data: [], topicId: keywordsSentiment.topicId, type: keywordsSentiment.type};
    if(keywordsSentiment.data && keywordsSentiment.data.length){
        keywords.data = checkNotNullKeyword(keywordsSentiment.data)
    }
    return keywords
})

export const getTagsSentimentSelector = createSelector(sentimentState, (keywordSentiment) => {
    let tag = {data:[], labels:[], topicId: keywordSentiment.topicId, text: [], colors: ['#ffffff','#ffffff','#ffffff','#ffffff']}
    if(keywordSentiment && keywordSentiment.data){
        tag.data = [[0,0,0],[0,0,0],[0,0,0],[0,0,0]];
        tag.text[0] = 'NEGATIVE_3';
        tag.text[1] = 'NOT_DETERMINDED_3';
        tag.text[2] = 'NEUTRAL_3';
        tag.text[3] = 'POSITIVE_3';
        keywordSentiment.data.forEach((item, i)=>{       
        if(item.value !== "null"){
            tag.labels[i] = item.value;
            [tag.data, tag.text, tag.colors] = checkSentiment(item.pivots, tag.data, tag.text, i, true, tag.colors);
        }
        })
    }
    return tag
})

export const getTagsRangeSelector = createSelector(tagsRange, (tagsRange) => {
    let tags = {data:[], labels:[], topicId: tagsRange.topicId, text: [], colors: []}
    if(tagsRange && tagsRange.data){
        tags.data = [[0,0,0],[0,0,0],[0,0,0],[0,0,0]];
        tagsRange.data.forEach((item, i)=>{
            tags.data[i] = [];
            tags.text[i] = item.value;
            item.values.forEach((k,j)=>{
                tags.data[i][j] = k.count;
                if(i === 0){
                    tags.labels[j] = formatDate(k.value)
                }
            })

        })
    }
    return tags
})

export const getKeywordsSentimentRangeSelector = createSelector(keywordsSentimentRange, (keywordsSentiment) => {
    let keywords = {data:[], labels:[], topicId: keywordsSentiment.topicId, text: []}
    if(keywordsSentiment && keywordsSentiment.data){
        keywordsSentiment.data.forEach((item, i)=>{
            keywords.data[i] = [];
            keywords.text[i] = item.value;
            item.values.forEach((k,j)=>{
                keywords.data[i][j] = k.count;
                if(i === 0){
                    keywords.labels[j] = formatDate(k.value)
                }
            })
        })
    }
    return keywords
})

export const getKeywordsSentimentSelector = createSelector(keywordsSentiment, (keywordSentiment) => {
    let lang = {data:[], labels:[], topicId: keywordSentiment.topicId, text: []}
    if(keywordSentiment && keywordSentiment.data){
        lang.data = [[0,0,0],[0,0,0],[0,0,0],[0,0,0]];
        keywordSentiment.data.forEach((item, i)=>{
            lang.labels[i] = item.value;
            [lang.data, lang.text] = checkSentiment(item.pivots, lang.data, lang.text, i, true);
        })
    }
    return lang
})

export const getKeywordsSentimentList = createSelector(keywordsSentiment, (keywordsSentiment)=>{  
    let keywords = {data: [], topicId: keywordsSentiment.topicId, type: keywordsSentiment.type};
    if(keywordsSentiment.data && keywordsSentiment.data.length){
        keywords.data = checkNotNullKeyword(keywordsSentiment.data)
    }
    return keywords
})

export const getMediaTypesSentimentSelector = createSelector(mediaTypesSentiment, (mediaTypesSentiment) => {
    let sentiment = {data:[], labels:[], topicId: mediaTypesSentiment.topicId, text: [], colors: [], list: ['NEGATIVE', 'UNDEFINED', 'NEUTRAL', 'POSITIVE']}
    if(mediaTypesSentiment && mediaTypesSentiment.data){
        sentiment.data = [[],[],[],[]]
        mediaTypesSentiment.data.forEach((item, i)=>{
            sentiment.data[0][i] = 0
            sentiment.data[1][i] = 0
            sentiment.data[2][i] = 0
            sentiment.data[3][i] = 0
            sentiment.labels[i] = item.value.toUpperCase();
            sentiment.text[0] = 'NEGATIVE';
            sentiment.text[1] = 'UNDEFINED';
            sentiment.text[2] = 'NEUTRAL';
            sentiment.text[3] = 'POSITIVE';

            sentiment.colors[0] = getToneColor('NEGATIVE_3');
            sentiment.colors[1] = getToneColor('NOT_DETERMINDED_3');
            sentiment.colors[2] = getToneColor('NEUTRAL_3');
            sentiment.colors[3] = getToneColor('POSITIVE_3');

            item.pivots.forEach((e,j)=>{
                switch(e.value){
                    case 'negative':
                        sentiment.data[0][i] = e.count;
                        break;
                    case 'undefined':
                        sentiment.data[1][i] = e.count; 
                        break;
                    case 'neutral':
                        sentiment.data[2][i] = e.count;
                        break;
                    case 'positive':
                        sentiment.data[3][i] = e.count;
                        break;
                }
            })
        })
    }

    return sentiment
})
//authorsSentiment

const getTypeOfCount = (type) => {
    switch(type){
        case 'shares_count':
            return 'REPOSTS';
        case 'likes_count':
            return 'LIKES';
        case 'comments_count':
            return 'COMMENTS';
        default:
            return type
    }
}

const getSentimentData = (authors, data, limit = null) => {
let i = 0;

    authors.data = [[],[],[],[]]
        data.forEach((item)=>{
            if(item.value !== "null" && item.value !== ""){
                if(limit && i > limit){
                    return
                }
                if(authors.type === 'AuthorsInvolvementSentiment'){
                    authors.postType[i] = 0;
                    item.pivots.forEach((k,j)=>{
                        authors.postType[i] += Number(k.count)
                    })
                }
                authors.data[0][i] = 0
                authors.data[1][i] = 0
                authors.data[2][i] = 0
                authors.data[3][i] = 0
                authors.labels[i] = authors.type === "AuthorsSentiment" ? getMediaTypesLabelText(item.value) : getTypeOfCount(item.value);
                authors.text[0] = 'NEGATIVE';
                authors.text[1] = 'UNDEFINED';
                authors.text[2] = 'NEUTRAL';
                authors.text[3] = 'POSITIVE';

                authors.colors[0] = getToneColor('NEGATIVE_3');
                authors.colors[1] = getToneColor('NOT_DETERMINDED_3');
                authors.colors[2] = getToneColor('NEUTRAL_3');
                authors.colors[3] = getToneColor('POSITIVE_3');

                item.pivots.forEach((e,j)=>{
                    
                    switch(e.value){
                        case 'negative':
                            authors.data[0][i] = e.count;
                            break;
                        case 'undefined':
                            authors.data[1][i] = e.count; 
                            break;
                        case 'neutral':
                            authors.data[2][i] = e.count;
                            break;
                        case 'positive':
                            authors.data[3][i] = e.count;
                            break;
                    }
                })
                i++
            }         
        })
        return authors
}

export const getAuthorsSentimentSelector = createSelector(authorsSentiment, (authorsSentiment) => {
    let authors = {data:[], labels:[], topicId: authorsSentiment.topicId, text: [], colors: [], type: authorsSentiment.type}
    if(authorsSentiment && authorsSentiment.data){
        authors = getSentimentData(authors, authorsSentiment.data, 19)
    }
    return authors
})

export const getInvolvementSentimentSelector = createSelector(involvementSentiment, (involvement) => {
    let authors = {data:[], postType: [], labels:[], topicId: involvement.topicId, text: [], colors: [], type: involvement.type}
    if(involvement && involvement.data){
        authors = getSentimentData(authors, involvement.data)
    }
    
    return authors;
});

export const getFollowersCountSelector = createSelector(getFollowersCount, (followersCount) => {
    let followers = {data:[[]], labels:[], topicId: followersCount.topicId, text: [], colors: ["#F58219"], type: followersCount.type, list: []}

    if(followersCount && followersCount.data && followersCount.data.length){
        
        followersCount.data.forEach((item, i)=>{

            followers.labels[i] = [];
            followers.text[i] = [];
            followers.labels[i] = item.value ? item.value : '';
            followers.text[i] = item.value ? item.value : '';
            item.pivots.forEach((pivot, j)=>{
                followers.data[0][i] = Number(pivot.value)
            })
        })
    }

    return followers
})

export const getAuthorLocationSelector = createSelector(authorLocation, (location) => {

    let authors = {data:[], labels:[], topicId: location.topicId, text: [], colors: [], type: location.type}
    if(location.data && location.data.length){
        location.data.forEach( (k, i) => {
            authors.data.push(k.count)
            authors.labels.push(k.value)
            authors.text.push(k.value)
        });
    }
    return authors;
});

export const getAuthorAgesSelector = createSelector(authorsAges, (authorsAges) => {

    let authors = {data:[[]], labels:[], topicId: authorsAges.topicId, text: [], colors: ['#9A9EC3'], type: authorsAges.type}

    if(authorsAges.data && authorsAges.data.length){
        
        authorsAges.data.forEach((item, i)=>{
            authors.labels[i] = [];
            authors.text[i] = [];
            authors.labels[i] = item.value ? item.value : '';
            authors.text[i] = item.value ? item.value : '';
            authors.data[0][i] = Number(item.count)
        })
    }
    return authors;
});

const getGenderName = (gender) => {
    if(gender === 'Male')
        return 'MEN'
    else
        return "WEMAN"
}

const getGenderColor = (gender) => {
    if(gender === 'Male')
        return "#60B9E1"
    else
        return "#F58219"
}

export const getAuthorGenderSelector = createSelector(authorsGender, (authorsGender) => {
    let authors = {data:[], labels:[], topicId: authorsGender.topicId, text: [], colors: ["#60B9E1", "#F58219"], type: authorsGender.type}
    if(authorsGender.data && authorsGender.data.length){
        
        authorsGender.data.forEach((item, i)=>{
            authors.labels[i] = [];
            authors.text[i] = [];
            authors.labels[i] = item.value ? getGenderName(item.value) : '';
            authors.text[i] = item.value ? getGenderName(item.value) : '';
            authors.colors[i] = item.value ? getGenderColor(item.value) : '';
            authors.data[i] = Number(item.count)
        })
    }
    return authors;
});

