import AnalyticController from "./analytic-controller";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { connect } from "react-redux";
import { compose } from "redux";
import { getValue } from "store/languages";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setGroupingByPeriod } from "store/calendar"
import { setLeftMenuComponent, setRemoveLeftMenuComponent, setRightMenuComponent, setRemoveRightMenuComponent, setShowLeftMenu, setShowRightMenu } from 'store/side-bar';
import { getTopics, getTopicsFiltersSelectorTree } from "selectors/theme-selector";
import { setSelectedTopics, setSelectedTopicsAll, setSelectedSubtopicsAll } from "store/topic-menu"
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";
import { setMenus } from "store/settings-menu";
import { getMyTopics, setThemeTableData } from "store/theme-settings";
import { setShowPanel } from "store/header";
import { getDataByType, setAnalyticIsFetching } from "store/extended-analytic";
import { getExtendedData, getExtendedDataSecond, getExtendedDataS, getExtendedThird } from "selectors/extended-selector";
import { setIsFilterChanged, getCompanyTags, getTopicsFilters, setSelectedTopics as setSelectedFiltersTopics, setPageName, getFiltersStatistics } from "store/filters";
import { setIsReturnedBack, setReturnBackPostCount } from "store/return-back-button";

let mapStateToProps = (state) => {

    return {
        selected: state.settingsPageMenu.selectedMenuItem,
        selectedLanguage: state.languages.selectedLanguage,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        leftMenuComponent: state.SideBarMenu.leftMenuComponent,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        topicData: getTopics(state),
        topics: getTopicsFiltersSelectorTree(state),
        selectedTopics: getSelectedTopicsUUID(state),
        selectedSubtopics: getSelectedSubTopicsUUID(state),
        menuItems: state.ExtendedAnalytic.menuItems,
        selectedItems: state.TopicMenu.selectedTopics,
        searchList: state.SearchText.searchList,
        tags: state.Filters.tags,
        isFetchingAnalytic: state.ExtendedAnalytic.isFetching,
        first: getExtendedData(state, 'first'),
        second: getExtendedDataS(state, 'second'),
        third: getExtendedThird(state, 'third'),
        isChanged: state.Filters.isChanged,
        selectedMyFilter: state.Filters.selectedMyFilter,
        locationsName: state.ExtendedAnalytic.locationsName
    }
}

const AnalyticContainer = compose(connect(mapStateToProps, {
    getValue, 
    setRightMenuComponent,
    setRemoveRightMenuComponent,
    setShowRightMenu,
    setShowLeftMenu,
    setLeftMenuComponent,
    setRemoveLeftMenuComponent,
    getMyTopics,
    setSelectedTopicsAll,
    setMenus,
    setShowPanel,
    getDataByType,
    setAnalyticIsFetching,
    getFiltersStatistics,
    setSelectedFiltersTopics,
    setIsFilterChanged,
    setPageName,
    setIsReturnedBack, 
    setReturnBackPostCount,
    setGroupingByPeriod }),withAuthRedirect)(AnalyticController);
export default AnalyticContainer;