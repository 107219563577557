const SET_THEMES = 'SET-THEMES';
const SET_CHECKED_THEMES = 'SET-CHECKED-THEMES';

const themeList = () => {
    
}

const themes = {
    themes: [],
    checkedThemes: []
}

const themesPage = (state = themes, action) => {
    switch(action.type){
        case SET_THEMES:
            return {...state, themes: action.themes}
        case SET_CHECKED_THEMES:
            return {...state, checkedThemes: action.checked}
        default:
            return {...state}
    }
}

export default themesPage;

export const setThemes = (data) => {
    
    return {
        type: SET_THEMES,
        themes: data
    }
}

export const setCheckedThemes = (checked) => {
    return {
        type: SET_THEMES,
        checkedThemes: checked
    }
}

