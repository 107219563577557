import React from "react";
import Admin from "./admin";

class AdminController extends React.Component{

    constructor(props){
        super(props)
    }

    componentDidMount(){
        //this.props.getSuperadminCompanies('ACTIVE')
    }

    render(){
        return (
                <Admin {...this.props}/>
        )
    }
}

export default AdminController;