import React, { useState } from "react";
import { useEffect } from "react";
import { InputNumber, InputText, SelectDropdown} from "commons/input-controls";
import s from "./company-topic-item.module.scss";

const CompanyTopicItem = (props) => {

    const [topic, setTopic] = useState(props.topic);
    const [topicName, setTopicName] = useState(props.topic.name);
    const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
    const [keywords, setKeywords] = useState(props.topic.keywords ? props.topic.keywords.split(',') : null);
    const [stopwords, setStopwords] = useState(props.topic.stopwords ? props.topic.stopwords.split(',') : null);
    const [subtopics, setSubtopics] = useState(props.topic.subtopics ? props.topic.subtopics.map(k => k.name) : null)

    useEffect(()=>{
        setTopic(props.topic);
        setTopicName(props.topic.name);
        setIsReadOnly(props.isReadOnly);
        setKeywords((props.topic.keywords ? props.topic.keywords.split(',') : null));
        setStopwords(props.topic.stopwords ? props.topic.stopwords.split(',') : null);
        setSubtopics(props.topic.subtopics ? props.topic.subtopics.map(k => k.name) : null)
    }, [props.topic, props.readOnly])

    const addTopicName = (e) => {
        setTopicName(topicName)
    }

    const keyWordsList = (keywords && keywords.length) ? keywords.map((k, i) => <div key={i}><span>{i+1}. </span>{k}</div>) : null;

    const stopWordsList = (stopwords && stopwords.length) ? stopwords.map((k, i) => <div key={i}><span>{i+1}. </span>{k}</div>) : null;

    const subtopicsList = (subtopics && subtopics.length) ? subtopics.map((k, i) => <div key={i}><span>{i+1}. </span>{k}</div>) : null;

    return (
        <div className={s.CompanyTopicItem}>
            <div className={s.formRow}>
                <InputText callback={addTopicName} value={topicName} placeholder={props.getValue('COMPANY_NAME')} readOnly={true} labelText={props.getValue('COMPANY_NAME')}/>
            </div>
            {keyWordsList ?<div className={s.formRow}>
             <span className={s.labelText}>{props.getValue('KEY_WORDS_2')}</span>
                <div className={s.keywords}>
                    {keyWordsList}
                </div>
            </div> : null}
            {stopWordsList ?<div className={s.formRow}>
             <span className={s.labelText}>{props.getValue('STOP_WORDS')}</span>
                <div className={s.keywords}>
                    {stopWordsList}
                </div>
            </div> : null}
            {subtopicsList ?<div className={s.formRow}>
             <span className={s.labelText}>{props.getValue('SUBTOPICS')}</span>
                <div className={s.keywords}>
                    {subtopicsList}
                </div>
            </div> : null}
        </div>
    )
}

export default CompanyTopicItem