import React from "react";
import Filters from "./filters";
import ControlButtons from "items/control-buttons/control-buttons";
import FiltersItemContainer from "./filters-item/filters-item-container";

class FiltersController extends React.Component{

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.getAllFilters()
    }

    componentDidUpdate(){


    }

    showRightMenu = (show, title, filter) => {
        if(show){
            this.props.setShowRightMenu(true)
            this.props.setRightMenuComponent(<FiltersItemContainer selectedMyFilterId={this.props.myFilters[filter]}/>, 'filtersItem', null, "VIEW_FILTER", true);
        }
        else{
            this.props.setRightMenuComponent(null);
        }
    }

    onTableItemsClick = (type, key, index) => {
        switch(type){
            case 'delete':{
                this.props.deleteMyFilter(key);
                break;
            }
            case 'show':{
                this.showRightMenu(true, type, index);
                break;
            }
        }
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        
        c = [...items];

        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    setTableValues = (tableValues) => {
        return tableValues.map((k, i)=>{
            let item = [];
            if(typeof(k[k.length-1]) !== "object"){
                let id = k.id;
                let status = k.id;
                let ctrl = this.props.controlButtons;
                item = [i+1, k.name, this.getControlButtonItems(id, status, ctrl, i)]
                //k.push(this.getControlButtonItems(id, status, ctrl, i))
            }
            return item
        })
    }

    render(){
        return(
            <Filters {...this.props} data={this.props.myFilters.length && this.setTableValues(this.props.myFilters)} tableHeader={this.getTableHeader(this.props.header)}/>
        )
    }

    componentWillUnmount(){
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }
}

export default FiltersController;