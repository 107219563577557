import React from "react";
import SideBar from "./side-bar";

class SideBarController extends React.Component{

    componentDidMount(){

    }

    componentDidUpdate(){
        if(this.props.token)
            this.props.getTopics(this.props.token);
    }

    render(){
        if(this.props.isAuth)
            return <SideBar {...this.props} />
    }

}

export default SideBarController;