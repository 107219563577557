import React from "react";
import SortFilter from "./sort-filter";

class SortFilterController extends React.Component{

    setSort = (sort) => {
        this.props.setSorting(sort)
    }


    render(){
        return <SortFilter getValue={this.props.getValue} sortFilters={this.props.sortFilters} setSort={this.setSort} sort={this.props.sort}/>
    }
}

export default SortFilterController;