import React, {useEffect, useState} from "react";
import ApexChart from "apex-charts/apex-chart";
import { header, getLanguage } from "connection/header";
import axios from "axios";
import s from "../../analytic/mentions.module.scss";

const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})

const MediaTypes = React.memo((props) => {
    const [rerender, setRerender] = useState(false);
    const [title, setTitle] = useState(props.getValue(props.title));
    const [topicItems, setTopicItems] = useState(props.topics);

    const getGeoplace = (labels, data) => {

        let ddata = [] 
            geoplace.post(`/geoplaces/names/dict`, [...labels]).then((response) => {

                if(response && response.data){
                    
                    ddata = response.data

                    let n = labels.map( (k, i) => {return {name: ddata[k], data: data[i]}})

                    setRerender(!rerender);
                    setDataCols(n)

                    setRerender(!rerender)
                }
            
        })
    }

    const getByType = (type, label, data, index) => {

        if(type === 'PROFILE_LOCATIONS'){
            if(props.data && props.data.values)
                return getGeoplace(label, props.data.values, index);
            else return label;
        }
        else{
            return props.getValue(label);
        }
    }


    const [dataCols, setDataCols] = useState(props.data ? props.data.values.map((k,i) => {return {name: props.getValue(props.data.labels[i]), data: props.data.values[i]}}) : []);
    const [labels, setLabels] = useState(props.data && props.data.topic.map((k,i) => {return props.getValue(k)}));

    const setLanguage = () => {
        geoplace.defaults.headers.common['Accept-Language'] = getLanguage()
    }

    //if(props.data.itemType=== 'PROFILE_LOCATIONS')
    //debugger
    useEffect(() => {
        //if(dataCols.length !== props.data.values.length)
        if(props.data.itemType === 'PROFILE_LOCATIONS'){
            getGeoplace(props.data.labels, props.data ? props.data.values : [])
        }
            setDataCols(props.data && props.data.values.map((k,i) => {return {name: props.getValue(props.data.labels[i]), data: props.data.values[i]}}));
        setLabels(props.data && props.data.topic.map((k,i) => {return props.getValue(k)}));
        setTimeout(()=>{setRerender(!rerender)},700) 
        setTitle(props.getValue(props.title));
        if(Object.keys(props.topics).length !== props.data.topic.length){
            setRerender(!rerender)
        }

        setTopicItems(props.topics)
    }, [props.data, props.data.itemType, props.newData, props.data.labels, props.title, props.topics])


    let data = {labels, values: dataCols}

    return (
        <div>
            <div className={s.AnalyticRow}>
                <div className={s.AnalyticBlock} style={{width:'100%'}}>
                    {(dataCols && dataCols.length && (dataCols.length === props.data.values.length)) && <ApexChart height={750} getValue={props.getValue} data={data} type={'column'} stacked={true} title={title} colors={props.data.colors} legend={true}/>}
                </div>
            </div>
        </div>
    )
})

export default MediaTypes;