import { CompanyService } from "../connection/company-service";
import { StorageService } from "../connection/storage-service"
import { setUpdatedDocuments, setRemoveTagFromDocuments, setUpdatedListOfDocuments } from "./storage"
import { setTags } from "./filters";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_TAGS = "SET-TAGS";
const SET_IS_TAGS_UPDATED = "SET_IS_TAGS_UPDATED";
const SET_NEW_TAG_COLOR = "SET-NEW-TAG-COLOR";
const ADD_NEW_TAG = "ADD-NEW-TAG";
const SET_DOCUMENT_LIST_TAGS = "SET-DOCUMENT-LIST-TAGS"

const colors = [
    {
        title: 'lightRed',
        color: '#F9DBDB',
        textColor: "#000000"
    },
    {
        title: 'lightOrange',
        color: '#F7E3D0',
        textColor: "#000000"
    },
    {
        title: 'lightYellow', 
        color: '#F7F4BF',
        textColor: "#000000"
    },
    {
        title: 'lightGreen',
        color: '#DFF0B9',
        textColor: "#000000"
    },
    {
        title: 'lightAzure',
        color: '#D4ECF7',
        textColor: "#000000"
    },
    {
        title: 'lightBlue',
        color: '#D5E1F0',
        textColor: "#000000"
    },
    {
        title: 'lightPurple',
        color: '#F1DCF1',
        textColor: "#000000"
    },
    {
        title: 'white',
        color: '#FFFFFF',
        textColor: "#000000"
    },
    {
        title: 'red',
        color: '#EE6766',
        textColor: "#FFFFFF"
    },
    {
        title: 'orange',
        color: '#F58219',
        textColor: "#FFFFFF"
    },
    {
        title: 'yellow',
        color: '#EAE460',
        textColor: "#FFFFFF"
    },
    {
        title: 'green',
        color: '#B5DD5F',
        textColor: "#FFFFFF"
    },
    {
        title: 'azure',
        color: '#60B9E1',
        textColor: "#FFFFFF"
    },
    {
        title: 'blue',
        color: '#3E79BF',
        textColor: "#FFFFFF"
    },
    {
        title: 'purple',
        color: '#A589D4',
        textColor: "#FFFFFF"
    },
    {
        title: 'grey',
        color: '#9AAAB5',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkRed',
        color: '#882120',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkOrange',
        color: '#925014',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkYellow',
        color: '#A98916',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkGreen',
        color: '#269344',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkAzure',
        color: '#2679A8',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkBlue',
        color: '#18477F',
        textColor: "#FFFFFF"
    },
    {
        title: 'darkPurple',
        color: '#61216B',
        textColor: "#FFFFFF"
    },
    {
        title: 'black',
        color: '#000000',
        textColor: "#FFFFFF"
    }
]

const tagsData = {
    tags: null,
    newTag: '',
    newTagColor: null,
    colors: colors,
    isTagsUpdated: false,
    documentListTags: [],
    tableHeader: ['#', 'TEXT_OF_TAG', 'CREATED_AT', 'UPDATED_AT'],
    controlButtons: ["edit", "delete"],
}

const Tags = (state = tagsData, action) => {
    switch(action.type){
        case SET_TAGS:
            return {...state, tags: action.tags}
        case ADD_NEW_TAG:
            return {...state, newTag: action.newTag}
        case SET_NEW_TAG_COLOR:
            return {...state, newTagColor: action.newTagColor}
        case SET_IS_TAGS_UPDATED:
            return {...state, isTagsUpdated: action.isTagsUpdated}
        case SET_DOCUMENT_LIST_TAGS:
            return {...state, documentListTags: [...state.documentListTags, ...action.documentListTags]}
        default:
            return {...state}
    }
}

export default Tags;

export const addNewTag = (newTag) => {
    return {
        type: ADD_NEW_TAG,
        newTag: newTag
    }
}

export const addNewTagColor = (newTagColor) => {
    return {
        type: SET_NEW_TAG_COLOR,
        newTagColor: newTagColor
    }
}

const setCompanyTags = (tags) => {
    return {
        type: SET_TAGS,
        tags: tags
    }
}

const setDocumentListTags = (tags) => {
    return {
        type: SET_DOCUMENT_LIST_TAGS,
        documentListTags: tags
    }
}

const setIsTagsUpdated = (isTagsUpdated) => {
    return {
        type: SET_IS_TAGS_UPDATED,
        isTagsUpdated: isTagsUpdated
    }
}


export const getCompanyTags = (companyId) => {
    return (dispatch) => {
        CompanyService.getCompanyTags(companyId).then(data=>{
            dispatch(setCompanyTags(data.content));
            dispatch(setTags(data.content));
            dispatch(setIsTagsUpdated(true))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const createCompanyTag = (companyId, name, color, textColor) => {
    return (dispatch) => {
        CompanyService.createCompanyTag(companyId, name, color, textColor).then(data=>{
            dispatch(getCompanyTags(companyId));
            dispatch(addNewTag(''));
            dispatch(addNewTagColor(null))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateCompanyTag = (companyId, tagId, tag) => {
    return (dispatch) => {
        CompanyService.updateCompanyTag(companyId, tagId, tag).then( data => {
            dispatch(getCompanyTags(companyId))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const addTagToDocument = (signature, tagUUID) => {
    return (dispatch) => {
        StorageService.addDocumentsTags(signature, tagUUID).then(data=>{
            dispatch(setUpdatedDocuments(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const addTagsToListDocuments = (signatures, tagUUID, tags) => {
    return (dispatch) =>{
        StorageService.addListDocumnetsTags(signatures, tagUUID).then(data=>{
            dispatch(setUpdatedListOfDocuments(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const deleteTagsFromListDocuments = (signatures, tagUUID, tags) => {
    return (dispatch) =>{
        StorageService.removeListDocumentsTags(signatures, tagUUID).then(data=>{
            dispatch(setUpdatedListOfDocuments(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const deleteTag = (companyId, tagId) => {
    return (dispatch) => {
        CompanyService.deleteCompanyTag(companyId, tagId).then(data => {
            dispatch(getCompanyTags(companyId))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const removeTagFromDocument = (signature, tagUUID) => {
    return (dispatch) => {
        StorageService.removeDocumentsTag(signature, tagUUID).then(data=>{
            dispatch(setRemoveTagFromDocuments(signature, tagUUID))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}