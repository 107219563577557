
import { GeoplaceService } from "connection/geoplace-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_GEOPLACE = "SET-GEOPLACE";
const SET_GEOPLACES = "SET_GEOPLACES";
const SET_GEOPLACE_UPDATED = "SET-GEOPLACE-UPDATED";
const SET_GEOPLACE_REGIONS = "SET-GEOPLACE-REGIONS";
const SET_GEOPLACE_KZ = "SET-GEOPLACE-KZ";
const SET_GEOPLACE_COUNTRIES = "SET-GEOPLACE-COUNTRIES";
const SET_EXCEPT_KZ = "SET-EXCEPT-KZ"

let geoplaceState = {
    geoplace: {},
    isGeoplaceUpdated: false,
    regions: [],
    kz: [],
    countries: []
}

let geoplace = (state = geoplaceState, action) => {
    switch(action.type){
        case SET_GEOPLACE:
            let geo = state.geoplace;
            geo[action.signature] = action.geoplace;
            return {...state, geoplace: geo}
        case SET_GEOPLACES:  
            return {...state, geoplace: {}}
        case SET_GEOPLACE_UPDATED:
            return {...state, isGeoplaceUpdated: action.isGeoplaceUpdated}
        case SET_GEOPLACE_REGIONS:
            return {...state, regions: action.regions}
        case SET_GEOPLACE_KZ:
            return {...state, kz: action.kz}
        case SET_GEOPLACE_COUNTRIES:
            return {...state, countries: action.countries}
        default:
            return state

    }
}

export default geoplace; 

const setName = (signature, geoplace) => {
    return {
        type: SET_GEOPLACE,
        geoplace, geoplace,
        signature: signature
    }
}

export const setGeoplaces = (signature, str) => {
    return {
        type: SET_GEOPLACES,
        str, str,
        signature: signature
    }
}

export const setGeoplaceCountries = (countries) => {
    return {
        type: SET_GEOPLACE_COUNTRIES,
        countries: countries
    }
}

export const setGeoplaceRegions = (regions) => {
    return {
        type: SET_GEOPLACE_REGIONS,
        regions: regions
    }
}

export const setGeoplaceUpdated = (updated) => {
    return {
        type: SET_GEOPLACE_UPDATED,
        isGeoplaceUpdated: updated
    }
}

export const getGeoplaceName = (signature, geoplaceId, update) => {
    return (dispatch) => {
        GeoplaceService.getGeoplaceName(geoplaceId).then(data=>{
            dispatch(setName(signature, data.data))
            if(update)
                dispatch(setGeoplaceUpdated(update))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getCountries = (event) => {
    return (dispatch) => {
        if(event){
            dispatch(getGeoplaceCountries())  
        }
        else{
            dispatch(setGeoplaceCountries([]))
        }

    }
}

export const getGeoplaceCountries = () => {
    return (dispatch) => {
        GeoplaceService.getGeoplaceCountries().then( data => {
            dispatch(setGeoplaceCountries(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getRegions = (event) => {
    return (dispatch) => {
        if(event)
            dispatch(getGeoplaceRegions());
        else
            dispatch(setGeoplaceRegions([]));
    }
}

export const getGeoplaceRegions = () => {
    return (dispatch) => {
        GeoplaceService.getGeoplaceRegions().then( data => {
            dispatch(setGeoplaceRegions(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getLocation = (geoplaceId) => {
    return getLocationById(geoplaceId)
}

export const getLocationById = async (geoplaceId) => {
        let n = await GeoplaceService.getGeoplaceName(geoplaceId)
        return n
}

export const setLocationName = (geoplaceId, name) => {
    return (dispatch) => {
        dispatch(setName(geoplaceId, name))
    }
}

export const getValue = (value) => {
    return (dispatch) => {
        if(!languagesPack)
            dispatch(retJs);
            return languagesPack[value] ? languagesPack[value] : value
        
    }    
}