import AuthoritiesController from "./authorities-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { getValue } from 'store/languages';
import { getAccountAuthorities, getAuthorities, setSelectedAuthority, setSelectedAuthorities, setUpdatedAuthority } from "store/authorities";

let mapStateToProps = (state) => {
    return {
        authorities: state.Authorities.authorities,
        //accountId: state.superadmin.accountId,
        adminAccountId: state.Account.id,
        selectedAuthorities: state.Authorities.selectedAuthorities,
        updatedAuthority: state.Authorities.updatedAuthority,
        roles: state.superadmin.roles,
        isSuperadmin: state.Account.isSuperadmin,
    }
}

const AuthoritiesContainer =  compose(
    connect(mapStateToProps, {
        getValue,
        getAuthorities,
        setSelectedAuthority,
        setSelectedAuthorities,
        setUpdatedAuthority,
        getAccountAuthorities
    }),
withAuthRedirect)(AuthoritiesController)

export default AuthoritiesContainer;