import MigrationItemController from "./migration-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getMigrationOperation } from "store/migration";
import { getAdminAccounts, getAccountById } from "store/superadmin"

let mapStateToProps = (state, props) => {
    return {
        migration: state.Migration.migration,
        migrationId: state.Migration.migrationId,
        accounts: state.superadmin.adminAccounts,
        account: state.superadmin.account
    }
}

const MigrationItemContainer = connect(mapStateToProps, { getValue, getMigrationOperation, getAdminAccounts, getAccountById})(MigrationItemController);

export default MigrationItemContainer;