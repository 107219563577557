import { header, getLanguage } from "./header";

const topics = header('restapi/api/v1/topics/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    topics.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    topics.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const TopicService = {
    getMyTopics(sortField = 'CREATED_AT', sortDirection = 'DESC'){
        setLanguage();
        getAuth();
        return topics.get(`?sortField=${sortField}&sortDirection=${sortDirection}`).then(response => {
            return response.data
        })
    },
    getTopic(topicId){
        setLanguage();
        getAuth();
        return topics.get(`${topicId}`).then(response => {
            return response
        })
    },
    createTopic(topicTitle, limit, parentId = null){
        setLanguage();
        getAuth();
        let params = {
            name: topicTitle,
            //limit: limit,
            relation: "main",
            geopostion: "kz"
        }
        if(limit) params.limit = limit
        if(parentId) params.parentId = parentId
        return topics.post('', {...params})
    },
    editTopic(topicId, topicTitle, limit){
        setLanguage();
        getAuth();
        let params = {
            name: topicTitle, 
            relation: "main", 
            geopostion: "kz", 
        }
        if(limit) params.limit = limit
        return topics.put(`${topicId}`, {...params})
    },
    deleteTopic(topicId){
        setLanguage();
        getAuth();
        return topics.delete(`${topicId}`, {topicId: topicId}).then(response => {
            return response.data;
        })
    },
    playTopic(topicId){
        setLanguage();
        getAuth();
        return topics.post(`${topicId}/resume`, {}).then(response => {
            return response
        })
    },
    pauseTopic(topicId){
        getAuth();
        setLanguage();
        return topics.post(`${topicId}/pause`, {}).then(response => {
            return response
        })
    },
    createKeyword(keyword){
        setLanguage();
        getAuth();
        return topics.post('keywords', keyword).then(response => {
            return response.data;
        })
    },
    addKeywordToTopic(topicId, keywords, deleteOld){
        setLanguage();
        getAuth();
        return topics.put(`${topicId}/keywords`, {keywords, deleteOld}).then(response => {
            return response.data;
        })
    },
    createStopword(stopword){
        setLanguage();
        getAuth();
        return topics.post('stopwords', stopword)
    },
    addStopwordToTopic(topicId, stopword){
        setLanguage();
        getAuth();
        return topics.put(`${topicId}/stopwords`, stopword)
    },
    addProfilesToTopic(topicId, url, profileMigration){
        setLanguage();
        getAuth();
        let m = ''
        if(!profileMigration) m = `?migrate=${profileMigration}`
        return topics.put(`${topicId}/socnetprofiles${m}`, url)
    },
    addResources(topicId, domain, migrate){
        setLanguage();
        getAuth();
        let m = ''
        if(!migrate) m = `?migrate=${migrate}`
        return topics.put(`${topicId}/resources${m}`, domain)
    },
    getSubtopics(topicId){
        setLanguage();
        getAuth();
        return topics.get(`${topicId}/subtopics`).then(response => {
            return response
        })
    },
    createSubtopic(topicId, subTopicName){
        setLanguage();
        getAuth();
        return topics.post(`${topicId}/subtopics`, {name: subTopicName, relation: "main", geopostion: "kz"}).then(response => {
            return response
        })
    },
    addKeywordToSubTopic(topicId, keywords, deleteOld){
        setLanguage();
        getAuth();
        return topics.put(`${topicId}/subtopic/keywords`, {keywords, deleteOld}).then(response => {
            return response.data;
        })
    },
    addStopwordToSubTopic(topicId, stopword){
        setLanguage();
        getAuth();
        return topics.put(`${topicId}/subtopic/stopwords`, stopword)
    },
    addFavouriteToTopic(topicId, profileUrl, resourceDomain = null, name = null){
        setLanguage();
        getAuth();
        return topics.post(`${topicId}/favourite`, profileUrl ? {profileUrl, name} : {resourceDomain}).then(response => {
            return response
        })
    },
    deleteFavouriteFromTopic(topicId, favoriteId){
        setLanguage();
        getAuth();
        return topics.delete(`${topicId}/favourite/${favoriteId}?remove=true`).then(response => {
            return response
        })
    },
    getFavouriteResourceProfile(topicIds){
        setLanguage();
        getAuth();
        return topics.get(`/favourite?topics=${topicIds}`).then(response => {
            return response
        })
    },
    getTopicBlackList(topicId){
        setLanguage();
        getAuth();
        return topics.get(`${topicId}/blacklist`).then(response => {
            return response
        })
    },
    getTopicsBlackList(topicsId){
        setLanguage();
        getAuth();
        let topicsList = topicsId.map((k, i) => {
            let index = i === 0 ? '?' : '&'
            return index+'topics='+k})
        return topics.get(`/blacklist${topicsList.join('')}`).then(response => {
            return response
        })
    },
    setProfileToBlackList(topicId, url, name, domain = null){
        setLanguage();
        getAuth();
        return topics.post(`${topicId}/blacklist`, url ? {url, name} : {domain}).then(response => {
            return response
        })
    },
    removeProfileFromBlackList(topicId, blacklistId){
        setLanguage();
        getAuth();
        return topics.delete(`/${topicId}/blacklist/${blacklistId}`).then(response => {
            return response
        })
    },
    updateTopicSettings(topicId, data){
        setLanguage();
        getAuth();
        return topics.put(`/${topicId}/settings`, {...data}).then( response => {
            return response
        })
    },
}