import CheckboxListController from "./checkbox-list-controller";
import { connect } from "react-redux";
import { getValue } from "../../store/languages";
import { setSelectedReport, setSelectAllReport } from "store/report-settings"

let mapStateToProps = (state) => {
    return {
        selectedReport: state.reportSettings.selectedReport,
        data: {...state.reportSettings.data},
        updatedReport: state.reportSettings.updatedReport,
        reportSettingsChanged: state.reportSettings.reportSettingsChanged,
        listCount: state.reportSettings.listCount
    }
}

const CheckboxListContainer= connect(mapStateToProps, {getValue, setSelectedReport, setSelectAllReport})(CheckboxListController)

export default CheckboxListContainer