import React, {useState, useEffect} from "react";
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import s from "./error-message.module.scss";

const ErrorMessageLabel = (props) => {
    const [message, setMessage] = useState(props.message);

    useEffect(() => {
        setMessage(props.message)
    }, [props.message]);

    return(
        <div className={s.ErrorMessageLabel}>
            <div className={s.MessageBlock}>
                <span>{message}</span>
            </div>
            <WarningIcon />
        </div>
    )
}

export default ErrorMessageLabel;