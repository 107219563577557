import React, { useEffect, useState } from "react";
import { InputText } from "commons/input-controls";
import s from "./telegram-chat.module.scss"
import { StandartButton } from "commons/form-controls";
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { modalShowToggle } from "store/modal";

const TelegramChat = (props) => {

    const [subscriptionChats, setSubscriptionChats] = useState(props.subscriptionChats && props.subscriptionChats.length ? props.subscriptionChats : []);
    const [showOnly, setShowOnly] = useState(props.showOnly);

    useEffect(() => {
        setSubscriptionChats(props.subscriptionChats && props.subscriptionChats.length ? props.subscriptionChats : []);
        setShowOnly(props.showOnly);
    }, [props.subscriptionChats, props.showOnly])

    const confirm = (id, type) => {
        if(type === 'delete'){
            removeSubscriptionChat(id, true)
        } 
        else{
            props.modalShowToggle(false)
        }

    }

    const removeSubscriptionChat = (chatId, isConfirmed = false) => {
        if(isConfirmed){
            props.deleteSubscriptionChat(props.subscriptionId, chatId)            
        }
        else{
            props.modalShowToggle('delete', true, confirm.bind(this, chatId), props.getValue('DELETE'), props.getValue('SURE_WANT_TO_DELETE'), props.getValue('DELETE'));
        }
        
    }

    const chatItems = subscriptionChats.map((k,i) => { return <div key={i} className={s.subscriptionRow}>
        <span>{i+1}.</span>
        <InputText value={k.title} readOnly={true} />
        {!showOnly ? <StandartButton icon={CloseIcon} name={props.getValue('DELETE')} stroke={'white'} callback={removeSubscriptionChat.bind(this, k.id)}/> : null }
    </div>});

    return (
        <div className={s.TelegramChat}>
            { chatItems }
        </div>
    )
}

export default TelegramChat