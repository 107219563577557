import React, {useState, useEffect} from "react";
import DefaultDays from "./default-days/default-days";
import GroupingContainer from "./grouping/grouping-container";
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import moment from "moment";
import s from "./period-calendar.module.scss";

const PeriodCalendar = (props) => {

    const [startDateStr, setStartDateStr] = useState(moment(props.startDate).format("DD-MM-YYYY HH:mm"));
    const [enDateStr, setEndDateStr] = useState(moment(props.endDate).format("DD-MM-YYYY HH:mm"));

    useEffect(()=>{
        setStartDateStr(moment(props.startDate).format("DD-MM-YYYY HH:mm"))
        setEndDateStr(moment(props.endDate).format("DD-MM-YYYY HH:mm"));
    }, [props.startDate, props.endDate]);


    return (
        <div className={s.PeriodCalendar}>
            <div className={s.PeriodDisplay} onClick={props.showCalendar}>
                <div className={s.Period}>
                    <span>{startDateStr}</span>
                    <span className={s.Separator}>-</span>
                    <span>{enDateStr}</span>
                </div>
                <CalendarIcon />
            </div>
            <DefaultDays getValue={props.getValue} callback={props.setPeriodDay}/>
            {props.footerButtons === 'grouping' && <GroupingContainer />}
        </div>
    )
}

export default PeriodCalendar;