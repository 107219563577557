import AdminAccountsPageController from "./admin-accounts-page-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { modalShowToggle } from "store/modal";
import { getAccounts, setCompanyId, setAccountId, setTopicId } from "store/superadmin"
import { getAccountsTable, getCompanyTopicsSelector } from "selectors/superadmin-selector";
import { setItemsCount, setCurrentPage, setShowedItemsCount, setToDefaultPaginator } from "store/paginator";

let mapStateToProps = (state) => {

    return {
        companies: state.superadmin.companies,
        users: state.superadmin.users,
        companyId: null,
        accounts: getAccountsTable(state),
        isSuperadmin: state.Account.isSuperadmin,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        adminAccountsCount: state.superadmin.adminAccountsCount
    }
}

const AdminAccountsPageContainer =  compose(
    connect(mapStateToProps, {
        modalShowToggle,
        getAccounts,
        setCompanyId,
        setAccountId, 
        setTopicId,
        setItemsCount, 
        setCurrentPage, 
        setShowedItemsCount,
        setToDefaultPaginator
    }),
withAuthRedirect)(AdminAccountsPageController)

export default AdminAccountsPageContainer;