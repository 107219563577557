import FiltersItemController from "./filters-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getMyFilter } from "store/filters";
import { setSelectedTopics, getTopicsFilters } from "store/filters"
import { setTreeSelectedItems } from "store/tree";
import { getMyFilterItem } from "selectors/filters-selector"
import { getTags } from "selectors/tags-selector";
import { getCompanyTags } from "store/tags";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";

let mapStateToProps = (state) => {
    return {
        myFilter: getMyFilterItem(state),
        filters: state.Filters.filters,
        companyId: state.Account.companyId,
        tags: getTags(state),
        selectedLanguage: state.languages.selectedLanguage,
    }
}

const FiltersItemContainer = connect(mapStateToProps, {  getValue, 
                                                        getMyFilter,
                                                        setSelectedTopics,
                                                        getTopicsFilters,
                                                        setTreeSelectedItems,
                                                        getCompanyTags
                                    })(FiltersItemController)

export default FiltersItemContainer