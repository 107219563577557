import React, {useState, useEffect} from "react";
import WordsBlock from "./words-block/words-block";
import s from "../extended-analytic.module.scss";
import { header, getLanguage } from "connection/header"
import ApexChartContainer from "apex-charts/apex-chart-container";

const setLanguage = () => {
    geoplace.defaults.headers.common['Accept-Language'] = getLanguage()
}

const Analytic = (props) => {

    const [rerender, setRerender] = useState(false);

    const getLables = (pageType) => {
        switch(pageType){
            case 'column':
            case 'line':
                return props.rangeData.labels
            case 'TagsRange':
            case 'Tags':
                return props.rangeData.labels
            case 'AuthorsLocations':
                return props.rangeData && props.rangeData.labels ?  getGoplaceNames(props.rangeData.labels) : []
            default:
                return props.rangeData.labels
        }
    }



    const getValues = (pageType) => {
        
        switch(pageType){
            case 'TagsRange':
                let ret = props.data ? props.data.data.map(k => {return {name: k.name, list: k.name, data: k.data}}) : []
                return ret
            default:
                return props.data.data
        }
    }

    const getDataByPageType = (data, labels) => {
        let d = {...data}
        let pageType = d && d.pageType ? d.pageType : null
        switch(pageType){
            case 'AuthorsLocations':
                if(props.rangeData.locationsName) getGoplaceNames(d.labels, d.data)
                return d ? d.data : []
            //case 'Tags':
            case 'TagsRange':
                let ret = d ? d.data.map(k => {return {name: k.name, list: k.name, data: k.data}}) : []
                return ret
            default:
                return d ? d.data : []
        }
    }

    const getGoplaceNames = (labels) => {
        let d = labels && labels.length ? labels.map(k => props.rangeData.locationsName && props.rangeData.locationsName[k] ? props.rangeData.locationsName[k] : '') : [];
        return d
    }

    const [type, setType] = useState(true);
    const [fisrtChartType, setFisrtChartType] = useState(props.rangeData.chartType);
    const [secondChartType, setSecondChartType] = useState(props.data ? props.data.chartType : null);
    const [piechartLabels, setPieChartLabels] = useState(props.data ? props.data.labels : null);
    const [piechartValues, setPieChartValues] = useState(props.data ? getValues(props.data) : null);
    const [dataCols, setDataCols] = useState(props.rangeData && props.rangeData.data ? getDataByPageType(props.rangeData) : null);
    const [labels, setLabels] = useState(props.rangeData ? props.rangeData.labels/*.map(k => k/*props.formatDateTime(k)) */: []);
    const [datas, setData] = useState(props.rangeData ? {labels: getLables(props.rangeData.pageType), values: getDataByPageType(props.rangeData), topicIDs: props.rangeData.topicIDs} : {name: '', data: []})
    const [colors, setColors] = useState(props.rangeData && props.rangeData.colors ? props.rangeData.colors : null)
    const [chartColors, setChartColor] = useState(props.data && props.data.colors ? props.data.colors : null)//["#5555A0", "#60B9E1", "#F58219"]
    const [fields, setFields] = useState(props.rangeData && props.rangeData.fields ? props.rangeData.fields : null);
    const [fieldsType, setFieldsType] = useState(props.rangeData && props.rangeData.fieldsType ? props.rangeData.fieldsType : null);
    const [secondFields, setSecondFields] = useState(props.data && props.data.fields ? props.data.fields : null);
    const [secondFieldsType, setSecondFieldsType] = useState(props.data && props.data.fieldsType ? props.data.fieldsType : null);
    const [firstWidth, setFirstWidth] = useState('100%');
    const [chartWidth, setChartWitdh] = useState('100%');
    const [chartHeight, setChartHeight] = useState('300px');



    useEffect(() => {
        setFisrtChartType(props.rangeData.chartType);
        setSecondChartType(props.data ? props.data.chartType : null);
        setPieChartLabels(props.data ? props.data.labels : null);
        setPieChartValues(props.data ? getValues(props.data.pageType) : null);
        setDataCols(props.rangeData && props.rangeData.data ? getDataByPageType(props.rangeData) : null);
        setLabels(props.rangeData ? props.rangeData.labels/*.map(k => props.formatDateTime(k))*/ : []);
        setData(props.rangeData ? {labels: getLables(props.rangeData.pageType), values: getDataByPageType(props.rangeData), topicIDs: props.rangeData.topicIDs} : {name: '', data: []});
        setColors(props.rangeData && props.rangeData.colors ? props.rangeData.colors : null)
        setChartColor(props.data && props.data.colors ? props.data.colors : null)
        setFields(props.rangeData && props.rangeData.fields ? props.rangeData.fields : null);
        setFieldsType(props.rangeData && props.rangeData.fieldsType ? props.rangeData.fieldsType : null);
        setSecondFields(props.data && props.data.fields ? props.data.fields : null);
        setSecondFieldsType(props.data && props.data.fieldsType ? props.data.fieldsType : null);
        if(!props.data.topicIDs || !props.data.topicIDs.length){
            setChartHeight('450px');
        }
        else{
            setChartHeight('320px');
        }
        /*if(props.rangeData.pageType === 'KeyWords' || props.rangeData.pageType === 'Tags'){
            setFirstWidth('47.5%')
            setChartWitdh('550px')
            //setChartHeight('300px')
        }
        else{
            setFirstWidth('100%')
            setChartWitdh('100%')
        }*/
        setRerender(!rerender)
    }, [props]);

    let pieChartData = []
    
    pieChartData = {labels: piechartLabels, values: piechartValues, topicIDs: props.data && props.data.topicIDs ? props.data.topicIDs : null}

    const changeType = () => {
        setType(!type)
    }

    return (
        <div className={s.ExtendedAnalyticBlock}>
            
                {((props.rangeData && props.rangeData.pageType === 'KeyWords') || (props.third && props.third.pageType === 'PopularWords' ) || (props.rangeData && props.rangeData.pageType === 'Tags')) && <WordsBlock data={props.third} getValue={props.getValue} pageType={props.third.pageType}/>}
                {/*props.rangeData.pageType === 'KeyWords' || props.rangeData.pageType === 'Tags' ? <KeywordsBlock data={props.rangeData} getValue={props.getValue}/> : null*/}
            
            <div className={s.AnalyticRow} style={{width: firstWidth}}>
                <div className={s.AnalyticBlock} style={{width: '100%'}}>
                    {(dataCols && dataCols.length) ? <ApexChartContainer height={chartHeight} width={chartWidth} getValue={props.getValue} data={datas} type={fisrtChartType} stacked={true} colors={colors} legend={true} title={props.rangeData.title} graphicType={props.rangeData.graphicType} fields={fields} fieldsType={fieldsType}/> : <div></div>}
                </div>
            </div>
            {piechartValues && piechartValues.length 
                ? <div className={s.AnalyticRow} style={{width: firstWidth}}>
                    <div className={s.AnalyticBlock} style={{width: '100%'}}>
                        <ApexChartContainer height={chartHeight} width={chartWidth} getValue={props.getValue} data={pieChartData} stacked={true} type={secondChartType} colors={chartColors} legend={true} title={props.data ? props.data.title : ''} fields={secondFields} fieldsType={secondFieldsType}/> 
                    </div>
                </div>
                : <div></div>
            }
            <div className={s.AnalyticRow}>

            </div>

        </div>
        
    )
}

export default Analytic;