import ReportsController from "./reports-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getReportSettings, updateReportSettings } from "store/report-settings"

let mapStateToProps = (state) => {
    return {
        selectedReport: state.reportSettings.selectedReport,
        updatedReport: state.reportSettings.updatedReport,
        selectedRole: state.AdminMode.selectedRole,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        
    }
}

const ReportsContainer = connect(mapStateToProps, {  getValue, 
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                getReportSettings,
                                updateReportSettings})(ReportsController)

export default ReportsContainer