import { Action } from "history";

const ADD_NEWSBREAKS_DATA = "ADD-NEWSBREAKS-DATA";
const SET_SELECTED_NEWSBREAKS = "SET_SELECTED_NEWSBREAKS";

let newsbreaksData = {
    newsbreaks: [],
    selectedNewsbreaks: []
}

const Newsbreaks = (state = newsbreaksData, action) => {
    switch(action.type){
        case ADD_NEWSBREAKS_DATA:
            return{...state, newsbreaks: action.newsbreaks}
        case SET_SELECTED_NEWSBREAKS:
            if(action.isSelected){
                return { ...state, selectedNewsbreaks: [...state.selectedNewsbreaks, action.selectedNewsbreaks]}}
            else
                return { ...state, selectedNewsbreaks: state.selectedNewsbreaks.filter(k => k !== action.selectedNewsbreaks)}
        default:
            return {...state}
    }
}

export default Newsbreaks;

export const setNewsbreaksData = (newsbreaks) => {
    return {
        type: ADD_NEWSBREAKS_DATA,
        newsbreaks: newsbreaks
    }
}

export const setSelectedNewsbreaks = (isSelected, selectedNewsbreaks) => {
    return {
        type: SET_SELECTED_NEWSBREAKS,
        isSelected: isSelected,
        selectedNewsbreaks: selectedNewsbreaks
    }
}