import React, {useState, useEffect} from "react";
import { SelectDropdown } from "../../commons/input-controls";
import { StandartButton } from "../../commons/form-controls";
import { ReactComponent as SaveIcon } from "../../assets/icons/save.svg";
import { ReactComponent as RestartIcon } from '../../assets/icons/restart.svg';
import s from './my-filters.module.scss';

const MyFilters = (props) => {

    const [myFilters, setMyFilters] = useState(props.myFilters ? props.myFilters.map(f=>f.name) : []);
    const [selected, setSelected] = useState(props.selectedMyFiltersMenu);

    useEffect(()=>{
        setSelected(props.selectedMyFiltersMenu);
        //if(!myFilters.length) setMyFilters( props.myFilters.map(f=>f.name));
        
    })

    const setSelectedFilter = (selected) => {
        let name = selected.name ? selected.name : ''
        setSelected(name)
        props.selectMyFilter(selected.id, selected.name)
    }

    const showSaveFilterModal = () => {
        props.modalShowToggle('SaveFilters', true, null, props.getValue('SAVE_FILTER_SETTINGS'))
    }

    return (
        <div className={s.MyFilters}>
            <SelectDropdown placeholder={props.getValue('SELECT_FILTER')} selected={selected} items={props.myFilters} setSelected={setSelectedFilter}/>
            <StandartButton setClass={'background'} icon={SaveIcon} callback={showSaveFilterModal}/>
            <StandartButton setClass={'background'} icon={RestartIcon} callback={setSelectedFilter.bind(this, [null, null])}/>
        </div>
    )
}

export default MyFilters;