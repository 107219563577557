import MediaTypes from "./media-types/media-types";

const getTopicsName = (topicsList, topics, type, topicUUId) => {

    let finded = topics.map((k, i) => {
            if(type === 'topic'){
                let fi = topicsList.findIndex(f => f.uuid === k);
                return fi !== -1 ? topicsList[fi].name : k
            }
            else{
                let fi = topicsList.findIndex(f => f.uuid === topicUUId);
                if(fi !== -1){
                    let st = topicsList[fi].subtopics.findIndex(f => f.uuid === k )
                    return st !== -1 ? topicsList[fi].subtopics[st].name : k
                }
                else return k
            }
        } )
    let ret = finded
    return ret

}



const TopicsComparisonPages = (menuId, data, getValue, topics, setRequestSended, typeName, getData, type, topicUUId, topicItems, updated) => {
    
    let t = []
    let newData = false;
    if(data && data.topic){
        t = getTopicsName(topics, data.topic, type, topicUUId)
        data.topic = t
    }

    if((data.topicIDs && !data.topicIDs.length) || (topicItems && (Object.keys(topicItems).length !== data.topicIDs.length) || updated)){
        setTimeout(() => { getData(true);  },100) 
    }
    if(!data && data === undefined || data.itemType !== typeName){
        newData = true;
        getData()
        
    }
    //setTimeout(() => {
        setRequestSended()
    //},100) 
    //updated(true)
    const getTitle = (typeName) => {
        switch(typeName){
            case 'MEDIA_TYPES':
                return 'TOPICS_COMPARISON_BY_MASS_MEDIA_TYPE';
            case 'SENTIMENTS':
                return 'TOPICS_COMPARISON_BY_TONE'
            case 'SPECIAL_MARKS':
                return 'TOPICS_COMPARISON_BY_SPECIAL_MARKS'
            case 'LANGUAGES':
                return 'TOPICS_COMPARISON_BY_LANG'
            case 'MEDIA_SCALES':
                return 'TOPICS_COMPARISON_BY_MASS_MEDIA_STATUS'
            case 'PROFILE_LOCATIONS':
                return 'TOPICS_COMPARISON_BY_REGIONS'
        }
        return title
    }
    
    return <MediaTypes topics={topicItems} title={getTitle(typeName)} data={data} getValue={getValue} newData={newData}/>
    switch(menuId){
        case 0:
            return <MediaTypes data={data} getValue={getValue}/>
        case 1:
            return <MediaTypes data={data} getValue={getValue}/>
        default:
            return <MediaTypes data={data} getValue={getValue}/>
    }
}

export default TopicsComparisonPages;