import DropdownWithSearchController from "./dropdown-with-search-controller";
import { connect } from "react-redux";
import { getValue } from "../../store/languages";
import { setSelectedItems } from "../../store/select-dropdown";

let mapStateToProps = (state) => {
    return {
        //selectedItems: state.SelectDropdownWithSearch.selectedItems,
    }
}

export default connect(mapStateToProps, {getValue})(DropdownWithSearchController)