import TagsItem from "./tags-item";
import { connect } from "react-redux";
import { getValue } from "store/languages";

let mapStateToProps = (state) => {
    return {
        selectedLanguage: state.languages.selectedLanguage,
    }
}

const TagsItemContainer = connect(mapStateToProps, {  getValue })(TagsItem)

export default TagsItemContainer