import React from "react";
import Reports from "./reports";
import CheckboxListContainer from "items/checkbox-list/checkbox-list-container";
import s from "./reports.module.scss"

class ReportsController extends React.Component{

    onSend = () => {
        this.props.updateReportSettings(this.props.selectedReport)
    }

    showRightMenu(){

    }

    componentDidMount(){
        this.props.getReportSettings()
        
        if(this.props.selectedRole !== 'ROLE_ADMIN'){
            this.props.setShowRightMenu(true);
            this.props.setRightMenuComponent(<div className={s.ReportsPage}><CheckboxListContainer onClose={this.showRightMenu} callback={this.onSend} /></div>, 'checkbox-list', null, 'REPORT_SETTINGS', true)
        }
            
    }

    componentDidUpdate(){
        if(this.props.updatedReport){
            this.props.getReportSettings()
        }
        if(this.props.selectedRole !== 'ROLE_ADMIN' && !this.props.rightMenuComponent.length){
            this.props.getReportSettings()
            this.props.setShowRightMenu(true);
            this.props.setRightMenuComponent(<div className={s.ReportsPage}><CheckboxListContainer onClose={this.showRightMenu} callback={this.onSend}/></div>, 'checkbox-list', null, 'REPORT_SETTINGS', false)
        }
    }

    componentWillUnmount(){
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    render(){
        return(
            <div>
               <Reports {...this.props} /> 
            </div>
            
        )
    }
}

export default ReportsController;