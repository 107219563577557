import React, {useState, useEffect} from "react";
import TableItem from "items/table/table";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import PaginatorContainer from "items/paginator/paginator-container";

const AdminMigration = (props) => {
    const [selectedItem, setSelectedItem] = useState(0);
    const [page, setPage] = useState(props.page)
    const [pageSize, setPageSize] = useState(props.pageSize);
    const [pending, setPending] = useState(false)

    useEffect(()=>{
        //setSelectedItem(props.selectedItem)
        ///*if(!pending){

        //}*/
        
        setPage(props.page)
        setPageSize(props.pageSize);
        const interval = setInterval(() => {
            setPending(true)
            props.getMigrations(page, 10)
            setPending(false)
        }, 30000)
        return () => clearInterval(interval);
    }, [props])



    const selectTableItem = (item) => {
        /*if(selectedItem !== item){
            props.setSelectedItem(item, props.data[item]);
        }*/
    }

    const createNewMigration = () => {
        props.showRightMenu('CREATE')
    }

    const addItemButton = !props.isTopicAccounts ? <StandartButton 
        icon={PlusIcon} 
        fill={'white'}
        title={props.getValue("CREATE_HISTORICAL_MIGRATION")}
        callback={createNewMigration}
    /> : null

    return (
        <div>
            <PaginatorContainer />
            <TableItem 
                                    data={[props.tableHeader, props.data]} 
                                    getValue={props.getValue}
                                    addItemButton={addItemButton}
                                    //titleText={props.getValue('LIST_OF_USERS')}
                                    hiddenColumns={props.hiddenColumns}
                                    topics={props.topics}
                                    //selectItem={selectTableItem}
                                    //selectedItem={selectedItem}
            />
        </div>
    )
}

export default AdminMigration;

