import AdminController from "./admin-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../hoc/AuthRedirect";
import { modalShowToggle } from "store/modal"


let mapStateToProps = (state) => {
    return {
        companies: state.superadmin.companies,
        users: state.superadmin.users,
        companyId: state.superadmin.companyId,
        selectedLanguage: state.languages.selectedLanguage,
    }
}

const AdminContainer =  compose(
    connect(mapStateToProps, {
        modalShowToggle
    }),
withAuthRedirect)(AdminController)

export default AdminContainer;