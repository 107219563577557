import React, { useState, useEffect } from "react";
import s from "./dropdown-item.module.scss";
import { InputCheckBoxException, InputCheckBox } from "../../../../commons/input-controls";
import { ReactComponent as AngelDown } from "../../../../assets/icons/angle-down.svg";
import { ReactComponent as AngelUp } from "../../../../assets/icons/angle-up.svg";
import Badge from "../../../badge/badge";

const DropdownItem = (props) => {
    const [check, setCheck] = useState(props.selected);
    const [indexKey, setIndexKey] = useState(props.indexKey)
    const [index, setIndex] = useState(props.index)
    const [kIndex, setKIndex] = useState(props.kIndex ? props.kIndex : null)

    useEffect(() => {
        setCheck(props.selected);
        //setIndexKey(props.ind)
        setIndexKey(props.indexKey);
        setKIndex(props.kIndex)
      }, [props.selected, props.indexKey, props.kIndex ? props.kIndex : null]);

    const checkItem = (name, index, one) => {
        props.setSelectedItems(name, index, one)
    }

    const showChilds = (index) => {
        props.showChilds(index)
    }

    return (
        <div className={props.child ? s.DropdownItem + ' ' + s.Child : s.DropdownItem}>
            <div className={s.ItemBlock}>
                {props.isException 
                                ? <InputCheckBoxException isChecked={check} name={indexKey+""+index+""+kIndex} title={props.getValue(props.item.name)} callback={checkItem.bind(this, props.item.list, true)}/> 
                                : <InputCheckBox isChecked={check} name={indexKey+""+index+""+kIndex} title={props.getValue(props.item.name)} callback={checkItem.bind(this, props.item.list)}/>}
                <span style={{backgroundColor: props.item.color ? props.item.color : '', color: props.item.textColor ? props.item.textColor : '', padding: props.item.color ? '2px 4px' : '', borderRadius: '2px'}}>{props.getValue(props.item.name)}</span>
                {props.count && <Badge sum={props.count} />}
            </div>
            {props.child && <div className={s.ShowChilds} onClick={showChilds.bind(this, props.index)}>{props.opened ? <AngelDown /> : <AngelUp />}</div>}
        </div>
    )
}

export default DropdownItem;