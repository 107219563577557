import React, {useState, useEffect, useRef } from "react";
import { InputField, InputPasswordField } from "../../../commons/form-controls";
import { signupValidateSchema } from "../../../utils/validators.js";
import { StandartButton } from "../../../commons/form-controls";
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Formik, Form, useFormik } from "formik";
import s from "./signup.module.scss"

const Signup = (props) => {
    let [showPassword, setShowPassword] = useState(false);
    let [showCheckPassword, setShowCheckPassword] = useState(false);
    let [showError, setShowError] = useState(props.errorInRegistration);
    const loginForm = useRef(null);

    useEffect(() => {
        setShowError(props.errorInRegistration);
        /*if(props.registrationIsSend){

            for(let i = 0; i<8; i++){
                loginForm.current[i].value = "";
                loginForm.current[i].defaultValue = "";
            }}
            props.setRegistrationIsSend(false);
        }*/
    });

    const setShowErrorMessage = (error) => {
        props.setErrorInRegistration(error);
        setShowError(error);
        
    }

    const onShowPassword = (passType) => {
        passType === 0 ? setShowPassword(!showPassword) : setShowCheckPassword(!showCheckPassword)
    }

    const onSubmitForm = (formData) => {
        if(formData){
            let phone = formData.phone !== '' ? formData.phone : null;
            formData !== undefined && props.signup(formData.login.toLowerCase(), formData.password, formData.name, formData.email, phone);
        }
    }

    const changedForm = (e) => {
        showError && setShowErrorMessage(null)
    }

    let componentsMap = {
        SendIcon,
        CloseIcon
    }

    return (
        <div className={s.Signup}>
            <div className="formRow center">
                <h3>{props.getValue('USER_REGISTRATION')}</h3>
            </div>
            <div className={s.errorMessage}>
                { showError && <span>{showError}</span> }
            </div>
            <div className={s.formBody}>
                <Formik
                    initialValues={{
                        login: '',
                        password: '',
                        checkPassword: '',
                        name: '',
                        email: '',
                        phone: '',
                        showPassword: showPassword
                    }}
                    validationSchema={signupValidateSchema}
                    onSubmit={(onSubmitForm, (values, {resetForm})=>{onSubmitForm(values), resetForm({values: ''})})}
                >
                    {({isValid, errors, touched, handleChange, handleSubmit})=>(
                        <Form
                            onSubmit={handleSubmit}
                            onChange={changedForm}
                        >
                            <div className="formRow">
                                <div className={s.labelRow}>
                                    <div className={s.labelText}>{props.getValue("LOGIN")}</div>
                                    <InputField name="login" type="text" label={props.getValue("UNIQUE_LOGIN")}/>
                                </div>
                            </div>
                            <div className="formRow">
                                <div className={s.labelRow}>
                                    <div className={s.labelText}>{props.getValue("PASSWORD")}</div>
                                    <InputPasswordField handleChange={handleChange} onShowPassword={onShowPassword.bind(this, 0)} showPassword={showPassword} name="password" type="password" label={props.getValue("8-12_CHARACTER_LATIN_CAPITAL_LETTERS_NUMBERS")}/>
                                </div>
                            </div>
                            <div className="formRow">
                                <InputPasswordField handleChange={handleChange} onShowPassword={onShowPassword.bind(this, 1)} showPassword={showCheckPassword} name="checkPassword" type="password" label={props.getValue("PASSWORD_REPEAT")}/>
                            </div>
                            <div className="formRow">
                                <div className={s.labelRow}>
                                    <div className={s.labelText}>Email</div>
                                    <InputField name="email" type="text" label="example@email.com"/>
                                </div>
                            </div>
                            <div className="formRow">
                                <div className={s.labelRow}>
                                    <div className={s.labelText}>{props.getValue("FULL_NAME")}</div>
                                    <InputField name="name" type="text" label={props.getValue("YOUR_FULL_NAME")}/>
                                </div>
                            </div>
                            <div className="formRow uppercase">
                                <h4>{props.getValue("OPTIONAL_FIELDS")}</h4>
                            </div>
                            <div className="formRow">
                                <div className={s.labelRow}>
                                    <div className={s.labelText}>{props.getValue("PHONE")}</div>
                                    <InputField name="phone" type="text" label={props.getValue("YOUR_PHONE_NUMBER")}/>
                                </div>
                            </div>
                            
                            <div className="formRow center">
                                <div className={s.halfRow}>
                                    <StandartButton disabled={touched && !isValid} type="submit" title={props.getValue("SEND")} icon={componentsMap.SendIcon} callback={onSubmitForm}/>
                                    <StandartButton type="button" title={props.getValue("CANCEL")} icon={componentsMap.CloseIcon} stroke={'white'}/>
                                </div>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Signup;