import { StandartButton } from "commons/form-controls";
import React, { useState } from "react";
import { useEffect } from "react";
import CheckoxItems from "./checkbox-items/checkbox-items";
import { InputCheckBox } from "commons/input-controls";
import s from "./checkbox-list.module.scss";

const CheckboxList = (props) => {

    const getSelectedItemsCount = () => {
        let count = 0
        if(props.selectedReport.segments){
            count = props.selectedReport.segments.length
        }
        if(props.selectedReport.documentElements){
            count += props.selectedReport.documentElements.length
        }
        if(props.selectedReport.groupingType)
            count += 1
        if(props.selectedReport.textRepresentation)
            count += 1

        return count
    }

    const [items, setItems] = useState(props.data);
    const [changed, setIsChanged] = useState(!props.reportSettingsChanged)
    const itemsList =  Object.entries(props.data).map((k, i) => <CheckoxItems key={i} title={k[0]} items={k[1]} getValue={props.getValue} setSelectedReport={props.setSelectedReport} selectedReport={props.selectedReport[k[0]] ? props.selectedReport[k[0]] : []}/>)
    const [allSelected, setAllSelected] = useState(props.listCount === getSelectedItemsCount() ? true : false)

    useEffect(()=>{
        setIsChanged(!props.reportSettingsChanged);
        setAllSelected(props.listCount === getSelectedItemsCount() ? true : false)
    })

    const saveChange = () => {
        props.callback(props.selectedReport)
    }

    const setSelected = () => {
        props.setSelectAllReport(!allSelected)
    }

    const getDisabledButton = () => {
        if(props.clickButton){
            return false
        }
        if(!changed){
            if((props.selectedReport.segments && props.selectedReport.segments.length) && (props.selectedReport.documentElements && props.selectedReport.documentElements.length) && (props.selectedReport.groupingType && props.selectedReport.groupingType.length) && (props.selectedReport.textRepresentation && props.selectedReport.textRepresentation.length))
                return false
            else
                return true
        }
        else
            return true
    }

    return (
        <div className={s.CheckboxList}>
            <div className={s.buttonsBlock}>
                <div className={s.item}>
                    <InputCheckBox isChecked={allSelected} name={props.getValue('ALL')} callback={setSelected.bind(this, 'title', 'index')} title={props.getValue('ALL')} />
                    <span>{props.getValue('ALL')}</span>
                </div>
                <StandartButton callback={saveChange} title={props.getValue('SAVE')} disabled={getDisabledButton()}/>
            </div>
            { itemsList }
        </div>
    )
}

export default CheckboxList