import { FeedbackService } from "../connection/feedback-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SEND_MESSAGE = "SEND-MESSAGE";
const CHANGE_BUTTON_STATUS = "CHANGE_BUTTON_STATUS";

let callUs = {
    isDisabled: true,
    sendButtonStop: false
}

const callUsPage = (state = callUs, action) =>{
    switch(action.type){
        case CHANGE_BUTTON_STATUS:
            return {...state, isDisabled: action.isDisabled}
        case SEND_MESSAGE:
            return {...state, ...action.sendMessage}
        default:
            return callUs
    }
}

export default callUsPage;

export const changeButtonStatus = (isDisabled) => {
    return{
        type: CHANGE_BUTTON_STATUS,
        isDisabled: isDisabled
    }
}

export const sendMessage = (isDisabled) => {
    return {
        type: SEND_MESSAGE,
        sendMessage: {isSend: true, isDisabled: true}
    }
}

export const sendMessageToAPI = (name, contacts, email, text) => {
    return (dispatch) => {
        FeedbackService.feedback(name, email, contacts, text).then(data=>{
            dispatch(sendMessage(false))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}