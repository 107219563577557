import React from "react";
import ApexChart from "./apex-chart";

class ApexChartController extends React.Component{

    render(){
        return(
            <ApexChart {...this.props}/>
        )
    }
}

export default ApexChartController;