import SearchTextController from "./search-text-controller";
import { connect } from "react-redux";
import { setSearchList, removeFromSearchList, setParentParent, setParent, clearSearchList, setSearchText } from "store/search-text";
import { modalShowToggle } from "store/modal";
import { getValue } from "store/languages";

let mapStateToProps = (state) => {
    return {
        searchText: state.SearchText.searchText,
        searchList: state.SearchText.searchList,
        selectedLanguage: state.languages.selectedLanguage,
        parentParent: state.SearchText.parentParent,
        parent: state.SearchText.parent
    }
}

export default connect(mapStateToProps, {getValue, modalShowToggle, setSearchList, removeFromSearchList, setParentParent, setParent, clearSearchList, setSearchText})(SearchTextController)