import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { getMe } from "store/account";

let mapStateToPropsForRedirect = (state) => ({
    isAuth: state.authorization.isAuth,
    companyId: state.Account.companyId,
    role: state.Account.role,
})

export const withAuthRedirectToMain = (Component) => {
    class RedirectComponentToMain extends React.Component {

        componentDidMount(){
            
            if(this.props.isAuth && !this.props.role){
                this.props.getMe();
            }
        }

        componentDidUpdate(){
            if(this.props.isAuth && !this.props.role){
                this.props.getMe();
            }
        }
        
        getStartPagePath = (role, companyId) => {
            switch(role){
                case 'ROLE_USER':
                case 'ROLE_ADMIN':
                    return "/page/short-analytic"
                case 'ROLE_SUPER_ADMIN':
                    return companyId ? "/page/short-analytic" : "/page/admin"
                case 'ROLE_MARKER':
                    return "/page/main"
                default: 
                    return "/page/short-analytic"
            }
        }

        render(){
            if(this.props.role){
                return <Navigate to={this.getStartPagePath(this.props.role, this.props.companyId)} />
            }
            else
                return <Component {...this.props} />
        }
    }

    let ConnectedAuthRedirectComponent = connect(mapStateToPropsForRedirect, {getMe})(RedirectComponentToMain)

    return ConnectedAuthRedirectComponent
}