import CallUs from "./call-us";
import { connect } from 'react-redux';
import { sendMessageToAPI } from "../../store/call-us";
import { getValue } from "store/languages";
import { modalShowToggle } from "../../store/modal"

let mapStateToProps = (state) => {
    return {
        isAuth: state.authorization.isAuth,
        login: state.Account.login,
        fio: state.Account.fio,
        phone: state.Account.phone,
        email: state.Account.email
    }
}

const CallUsContainer = connect(mapStateToProps, { sendMessageToAPI, modalShowToggle, getValue })(CallUs);

export default CallUsContainer;