import { header, getLanguage } from "./header";

const admin = header('restapi/api/v1/admin/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    admin.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    admin.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const AdminService = {
    getCompany(){
        setLanguage();
        getAuth();
        return admin.get(`companies/me`).then( response => {
            return response.data
        })
    },
    getAccounts(){
        setLanguage();
        getAuth();
        return admin.get(`accounts`).then(
            response => {
                return response.data
            }
        )
    },
    getTopics(){
        setLanguage();
        getAuth();
        return admin.get(`company/topics`).then(
            response => {
                return response.data
            }
        )
    },
    getAccountTopics(accountId, pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return admin.get(`accounts/${accountId}/topics?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    getTopicAccounts(topicId, pageIndex = 0, pageSize = 100){
        setLanguage();
        getAuth();
        return admin.get(`topics/${topicId}/accounts?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(
            response => {
                return response.data
            }
        )
    },
    editAccount(accountId, fio, email, phone){
        setLanguage();
        getAuth();
        return admin.patch(`accounts/${accountId}`, {fio, email, phone}).then(
            response => {
                return response.data
            }
        )
    },
    deleteAccount(accountId){
        setLanguage();
        getAuth();
        return admin.delete(`accounts/${accountId}`, ).then(
            response => {
                return response.data
            }
        )
    },
    activateAccount(accountId){
        setLanguage();
        getAuth();
        return admin.post(`accounts/${accountId}/activate`).then(
            response => {
                return response.data
            }
        )
    },
    blockAccount(accountId){
        setLanguage();
        getAuth();
        return admin.post(`accounts/${accountId}/block`).then(
            response => {
                return response.data
            }
        )
    },
    attachTopicToAccount(accountId, topicId){
        setLanguage();
        getAuth();
        return admin.post(`accounts/${accountId}/topics/${topicId}`).then(
            response => {
                return response.data
            }
        )
    },
    attachAccountsToTopics(accountIds, topicId){
        setLanguage();
        getAuth();
        return admin.put(`topics/${topicId}/accounts`, [...accountIds]).then(
            response => {
                return response.data
            }
        )
    },
    makeTopicVisibiltyToAccount(accountId, topicIds){
        setLanguage();
        getAuth();
        return admin.put(`accounts/${accountId}/topics/visibilities`, [...topicIds]).then(
            response => {
                return response.data
            }
        )
    },
    detachTopicFromAccount(accountId, topicId){
        setLanguage();
        getAuth();
        return admin.delete(`accounts/${accountId}/topics/${topicId}`)
    },
    getAccountAuthorities(accountId){
        setLanguage();
        getAuth();
        return admin.get(`accounts/${accountId}/authorities`).then(
            response => {
                return response.data
            }
        )
    },
    addRoleToAccount(accountId, role){
        setLanguage();
        getAuth();
        return admin.put(`accounts/${accountId}/role`, role, {headers: { 'Content-Type': 'application/json'},data: {}}).then(
            response => {
                response.data
            }
        )
    },
    createAccount(login, fio, email, phone, password, locale){
        setLanguage();
        getAuth;
        let params = {}
        params.login = login
        params.fio = fio;
        params.password = password;
        if (locale) params.locale = locale
        if (phone) params.phone = phone;
        if (email) params.email = email;
        return admin.post(`/accounts/`, {...params}).then(
            response => {
                return response.data
            }
        )
    },
    addAuthoritiesToAccount(accountId, authorities){
        setLanguage();
        getAuth();
        return admin.put(`accounts/${accountId}/authorities`, [...authorities]).then(
            response => {
                return response.data
            }
        )
    },
    getAvailableRoles(){
        setLanguage();
        getAuth();
        return admin.get(`accounts/roles`).then(
            response => {
                return response.data
            }
        )
    }
}