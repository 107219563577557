import React from "react";
import TopicComparisonPage from "./topic-comparison";
import SettingsMenuContainer from "pages/settings-page/menu/settings-menu-container";

import PeriodCalendarContainer from "items/period-calendar/period-calendar-container";
import TopicMenuContainer from "pages/topic-menu/topic-menu-container";
import FiltersContainer from "items/filters/filters-container";
import MyFiltersContainer from "items/myFilters/my-filters-container";
import { getFormatedDate } from "service/format-date";

class TopicComparisonController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selected: 0,
            topics: this.props.topics,
            selectedLanguage: this.props.selectedLanguage,
            selectedTopics: this.props.selectedTopics && this.props.selectedTopics.length ? this.props.selectedTopics.join(",") : "",
            selectedSubtopics: this.props.selectedSubtopics,
            topicLabels: this.props.data.labels.join(','),
            itemType: this.props.data.itemType,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            chartName: this.props.data.itemType,
            grouping: this.props.grouping,
            dataTopicIDs: null,
            locationsDictionary: this.props.locationsDictionary ? Object.keys(this.props.locationsDictionary) : 0,
            total: this.props.data.total,
            firstStart: false,
            max: this.props.max,
            firstLocation: this.props.locationsDictionary,
            firstLabel: this.props.data && this.props.data.topic && this.props.data.topic[0] ? this.props.data.topic[0].name : ''
        }
    }

    showLeftMenu = (topics, menu) => {
        if(this.props.leftMenuComponent.findIndex( c => c.componentName === 'settingsMenu') === -1){
            this.props.setLeftMenuComponent(<SettingsMenuContainer items={menu}/>, 'settingsMenu')
        }
        if(topics && topics.length){
            let topicsName = topics && topics.map( k => k.name);
            this.props.setLeftMenuComponent(
                                                <TopicMenuContainer 
                                                        items={topics}
                                                        selected={this.props.selectedTopics}
                                                        showSubtopics={true}
                                                        />,
                                            'topics', true)
        }
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date);
    }


    getFiltersList = (startDate, endDate, topics, fields, subtopics, filters) => {
        /*let type = this.props.selectedType
        
        let sTopics = {}
        if(this.props.type === 'Comparision' && this.props.selectedType === 'subtopic'){
            sTopics = [{subtopicIds: Object.keys(this.props.selectedTopicsItems).map((k,i) => {return k})}]
            topics = [this.props.topicUUId]
        }
        else{
            sTopics = subtopics
        }*/
        this.props.getFiltersStatistics(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);  
    }

    showRightMenu = (topics) => {
        this.props.setShowRightMenu(true);
        this.props.setRightMenuComponent(<PeriodCalendarContainer />, 'periodCalendar', true);
        this.props.setRightMenuComponent(<MyFiltersContainer />, 'myFilters', true)
       if(topics && topics.length){
            //this.props.setIsFilterChanged(true)
            this.props.setPageName('Comparison')
            this.props.setRightMenuComponent(<FiltersContainer type={'Comparison'} topics={topics} getFiltersList={this.getFiltersList}/>, 'filters')
            //this.props.setIsFilterChanged(false)
       }
       else{
        this.props.setRightMenuComponent(<div></div>, 'filters')
       }
    }

    showInfrastructure = () => {
        //this.props.setMenus(this.props.menu);
        this.showLeftMenu(this.props.topics, this.props.menu);
        this.showRightMenu(this.props.topics)
        this.props.setShowLeftMenu(true);
        this.props.setLeftMenuComponent(<SettingsMenuContainer items={this.props.menu}/>, 'settingsMenu', false, false);

        

        this.props.setShowPanel(true)
    }

    componentDidMount(){
        this.showInfrastructure(); //Lest and Right menu, Top panel
        this.props.getMyTopics();
        this.setState({
            firstStart: true
        })
    }

    setReload = (reload) => {
        this.setState({
            reload: reload
        })
    }

    getData = (menuId, selectedTopics, topics, subtopics, startDate, endDate, grouping, filters, max) => {
        this.props.getDataByType(this.props.menu[menuId].title, selectedTopics, this.getFormatedDate(startDate), this.getFormatedDate(endDate), grouping, filters, topics, subtopics, max )
    }    

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.isComparisonFetching){
            setTimeout(()=>{this.props.setIsComparisonFetching(false)}, 2500);
        }
        if(nextProps.isChanged)
            this.props.setIsFilterChanged(false)
        if(nextProps.data.itemType === 'ProfileLocations' && (nextProps.data.locations && nextProps.data.locations.length)){
            if(!nextProps.locationsDictionary){
                if(!nextProps.isComparisonFetching) nextProps.getGeoplaceDictionary(nextProps.data.locations)
                    return false
            }            
        }
        if(nextProps.topics && nextProps.topics.length && nextState.firstStart && !nextProps.selectedTopics.length){
            //if(nextState.firstStart){
                
                let t = nextProps.topics.map((k,i) => {return {name: k.name, id: k.uuid}})
                this.props.setSelectedTopicsAll(t)

                this.setState({
                    firstStart: false
                })
            //}
        }
        if(nextProps.topics && nextProps.topics.length && (!nextState.topics || nextState.topics == undefined )){
            if(nextProps.selectedTopics.length)
                this.getData(nextProps.selected, nextProps.selectedTopics, nextProps.topics, nextProps.selectedSubtopics, nextProps.startDate, nextProps.endDate, nextProps.grouping, nextProps.selectedMyFilter);
            this.showLeftMenu(nextProps.topics)
            this.showRightMenu(nextProps.selectedTopics)
            this.setState({
                topics: nextProps.topics,
                
            })
            return true
        }
        if(nextProps.selected !== nextState.selected 
                                                    || nextProps.selectedTopics.join(',') !== nextState.selectedTopics
                                                    || nextProps.selectedSubtopics.join(',') !== nextState.selectedSubtopics
                                                    || nextProps.selectedLanguage !== nextState.selectedLanguage
                                                    || nextProps.isChanged 
                                                    || nextProps.data.labels.join(',') !== nextState.topicLabels
                                                    || nextProps.data.itemType !== nextState.itemType
                                                    || nextProps.startDate !== nextState.startDate
                                                    || nextProps.endDate !== nextState.endDate
                                                    || nextProps.data.itemType !== nextState.chartName
                                                    || nextProps.grouping !== nextState.grouping
                                                    || nextProps.data.topicIDs !== nextState.dataTopicIDs
                                                    || nextProps.topics.length !== nextState.topics.length
                                                    //|| nextProps.comparisonLocations !== nextState.comparisonLocations.join(",")
                                                    || (nextProps.locationsDictionary && nextProps.locationsDictionary !== undefined ? Object.keys(nextProps.locationsDictionary).length : 0) !== nextState.locationsDictionary
                                                    || nextProps.data.total !== nextState.total
                                                    || nextProps.max !== nextState.max
                                                    || (nextProps.data && nextProps.data.topic && nextProps.data.topic[0] ? nextProps.data.topic[0].name : 0) !== nextState.firstLabel
                                                    || (nextProps.locationsDictionary && nextProps.locationsDictionary !== undefined ? Object.values(nextProps.locationsDictionary).join(",") : 0) !== nextState.firstLocation
        ){       
            this.setState({
                selectedLanguage: nextProps.selectedLanguage,
                selectedTopics: nextProps.selectedTopics.join(','),
                selectedSubtopics: nextProps.selectedSubtopics.join(','),
                selected: nextProps.selected,
                topicLabels: nextProps.data.labels.join(','),
                itemType: nextProps.data.itemType,
                startDate: nextProps.startDate,
                endDate: nextProps.endDate,
                chartName: nextProps.data.itemType,
                grouping: nextProps.grouping,
                locationsDictionary: nextProps.locationsDictionary && nextProps.locationsDictionary !== undefined ? Object.keys(nextProps.locationsDictionary).length : 0,
                total: nextProps.data.total,
                max: nextProps.max,
                topics: nextProps.topics,
                dataTopicIDs: nextProps.data.topicIDs,
                firstLocation: nextProps.locationsDictionary && nextProps.locationsDictionary !== undefined ? Object.values(nextProps.locationsDictionary).join(",") : 0,
                firstLabel: nextProps.data && nextProps.data.topic && nextProps.data.topic[0] ? nextProps.data.topic[0].name : 0
            })
            this.props.setReturnBackPostCount(nextProps.data.total)
            this.props.setSelectedFiltersTopics(nextProps.selectedTopics)
            if(nextProps.selectedTopics.length && nextProps.topics && nextProps.topics.length && !nextProps.isComparisonFetching){
                this.getData(nextProps.selected, nextProps.selectedTopics, nextProps.topics, nextProps.selectedSubtopics, nextProps.startDate, nextProps.endDate, nextProps.grouping, nextProps.selectedMyFilter, nextProps.max);
            }
            this.showRightMenu(nextProps.selectedTopics)
            return true
        }
        else return false
    }

    closeMenu = () => {
        this.props.setRemoveLeftMenuComponent();
        this.props.setRemoveRightMenuComponent();
        this.props.setShowLeftMenu(false);
        this.props.setShowRightMenu(false);
        this.props.setShowPanel(false);
    }

    componentWillUnmount(){
        this.closeMenu();
        //this.props.setThemeTableData([]);
        //this.props.setSelectedTopicsAll([]);
        this.props.setSelectedSubtopicsAll(this.props.selectedTopicsItems, []);
        this.props.setReturnBackPostCount(0);
        //this.props.setComparisonData([])
        this.setState({
            topics: [],
            selectedTopics: []
        })
    }

    render(){
        if(this.props.selectedTopics && this.props.selectedTopics.length)
        return  <TopicComparisonPage {...this.props} reload={this.state.reload} setReload={this.setReload}/>
        else return <div></div>
    }

}

export default TopicComparisonController;