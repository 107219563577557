import React from "react";
import MyFilters from "./my-filters";

class MyFiltersController extends React.Component{
    
    componentDidMount(){
        this.props.getAllFilters();
    }

    selectMyFilter = (id, name) => {
        this.props.getMyFilter(id);
        this.props.setSelectedMyFilterMenu(name)
    }

    render(){
        return this.props.myFilters && <MyFilters {...this.props} selectMyFilter={this.selectMyFilter}/>
    }
}

export default MyFiltersController;