const SET_SELECTED_ROLE = "SET-SELECTED-ROLE";

const AdminModeState = {
    selectedRole: 'ROLE_USER'
}

const AdminMode = (state = AdminModeState, action) => {
    switch(action.type){
        case SET_SELECTED_ROLE:
            return {...state, selectedRole: action.selectedRole}
        default:
            return {...state}
    }
}

export const setSelectedRole = (selectedRole) => {
    return {
        type: SET_SELECTED_ROLE,
        selectedRole: selectedRole
    }
}

export default AdminMode;