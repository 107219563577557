import React, {useState, useEffect} from "react";
import { InputNumber, InputText, SelectDropdown} from "commons/input-controls";
import s from "./edit-account.module.scss"
import { StandartButton } from "commons/form-controls";
import AuthoritiesContainer from "items/authorities/authorities-container";
import List from "pages/settings-page/list/list";

const EditCompanyAccount = (props) => {
    const getLocale = (item, type, getType) => {
        let loc = props.accountLocales.find(k => k[type] === item)
        return loc[getType]
    }

    const getRole = (item, type, getType) => {
        let role = props.roles.find(k => k[type] === item)
        return role[getType]
    }

    const getTopics = (props) => {
        props.isSuperadmin ? props.adminTopics : props.allTopics
    }

    const [companyId, setCompanyId] = useState(props.companyId ? props.companyId : null);
    const [accountCompanyId, setAccountCompanyId] = useState(props.Account && props.Account.companyId ? props.Account.companyId : null);
    const [company, setCompany] = useState(props.company ? props.company : null);
    const [accountId, setAccountId] = useState(props.Account && props.Account.id ? props.Account.id : '');

    const [accountFio, setAccountFio] = useState(props.Account && props.Account.fio ? props.Account.fio : '');
    const [accountEmail, setAccountEmail] = useState(props.Account && props.Account.email ? props.Account.email : '');
    const [accountPhone, setAccountPhone] = useState(props.Account && props.Account.phone ? props.Account.phone : null);
    const [accountLocale, setAccountLocale] = useState(props.Account && props.Account.locale ? props.getValue(getLocale(props.Account.locale, 'list', 'name')) : '');
    const [accountLocaleList, setAccountLocaleList] = useState(props.Account && props.Account.locale ? props.Account.locale : '');
    const [accountIsDemo, setAccountIsDemo] = useState(props.Account && props.Account.isDemo ? props.Account.isDemo : '');
    const [accountLocales, setAccountLocales] = useState(props.accountLocales && props.accountLocales.map(k => { return {name: props.getValue(k.name), title: k.name, list: k.list}}))
    const [accountRole, setAccountRole] = useState(props.Account && props.Account.role ? props.getValue(props.Account.role) : null)
    const [accountRoleList, setAccountRoleList] = useState(props.Account && props.Account.role ? props.Account.role : null)
    const [roles, setRoles] = useState(props.roles ? props.roles.map(k => {return {name: props.getValue(k.name), list: k.list}}) : null)
    const [companies, setCompanies] = useState(props.activeCompanies ? props.activeCompanies.map( k=> {return {name: props.getValue(k.name), id: k.id}}) : null);
    const [companyName, setCompanyName] = useState(props.companyName ? props.companyName : null);
    //const [selectedAuthorities, setSelectedAuthorities] = useState(props.selectedAuthorities ? props.selectedAuthorities : null)
    const [updateBtnTitle, setUpdateBtnTitle] = useState(props.Account && !props.Account.companyId  ? props.getValue('ATTACH_ACCOUNT_TO_COMPANY_AT_FIRST') : null);
    const [accountTopics, setAccountTopics] = useState(props.topics);
    const [selectedTopics, setSelectedTopics] = useState(props.accountTopics ? props.accountTopics : [])
    const [selectedCompanyToAttach, setSelectedCompanyToAttach] = useState(null)
    const [selectedCompanyNameToAttach, setSelectedCompanyNameToAttach] = useState(null)
    const phoneRegExp = /^[0-9\-\+]{11,13}$/;
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    useEffect(() => {
        setAccountFio(props.Account && props.Account.fio ? props.Account.fio : '');
        setAccountEmail(props.Account && props.Account.email ? props.Account.email : '');
        setAccountPhone(props.Account && props.Account.phone ? props.Account.phone : null);
        setAccountLocale(props.Account && props.Account.locale ? props.getValue(getLocale(props.Account.locale, 'list', 'name')) : '');
        setAccountIsDemo(props.Account && props.Account.isDemo ? props.Account.isDemo : '');
        setAccountLocales(props.accountLocales.map(k => { return {name: props.getValue(k.name), title: k.name, list: k.list}}));
        setAccountLocaleList(props.Account && props.Account.locale ? props.Account.locale : '');
        setAccountRole(props.Account && props.Account.role ? props.getValue(props.Account.role) : null);
        setAccountRoleList(props.Account && props.Account.role ? props.Account.role : null);
        setAccountId(props.Account.id ? props.Account.id : null);
        setAccountCompanyId(props.Account && props.Account.companyId ? props.Account.companyId : null);
        setRoles(props.roles ? props.roles.map(k => {return {name: props.getValue(k.name), list: k.list}}) : null);
        setCompanies(props.activeCompanies ? props.activeCompanies.map(k => {return {name: props.getValue(k.name), id: k.id}}) : null);
        //setSelectedAuthorities(props.selectedAuthorities ? props.selectedAuthorities : null);
        setCompanyId(props.Account.companyId ? props.Account.companyId : null);
        setCompanyName(props.companyName ? props.companyName : null);
        setUpdateBtnTitle(props.Account && !props.Account.companyId ? props.getValue('ATTACH_ACCOUNT_TO_COMPANY_AT_FIRST') : null);
        setAccountTopics(props.topics);
        setSelectedTopics(props.accountTopics ? props.accountTopics : [])
    }, [props.Account, props.selectedAuthorities, props.companyId, props.companyName, props.accountTopics, props.topics, props.adminTopics, props.activeCompanies])

    const addAccountFio = (e) => {
        setAccountFio(e)
    }
    
    const addAccountEmail = (e) => {
        setAccountEmail(e)
    }

    const addAccountPhone = (e) => {
        setAccountPhone(e)
    }

    const addAccountLocale = (e) => {
        setAccountLocale(props.getValue(e.name))
        setAccountLocaleList(e.list)
    }

    const addAccountRole = (e) => {
        setAccountRole(props.getValue(e.name))
        setAccountRoleList(e.list)
    }

    const addAccountIsDemo = (e) => {
        setAccountIsDemo(e)
    }

    const addCompany = (e) => {
        setCompany(e.name)
        setCompanyId(e.id)
    }

    const resetAllList = () => {
        setAccountFio('');
        setAccountEmail('');
        setAccountPhone('');
        setAccountLocale(null);
        setAccountIsDemo('');
        setCompany(null);
    }

    const checkPhoneNumber = () => {
        if(!accountPhone || accountPhone && accountPhone.match(phoneRegExp)){
            return true
        }
        else
            return false
    }

    const checkEmail = () => {
        if(accountEmail && accountEmail.match(emailRegExp)){
            return true
        }
        else
            return false
    }

    const setAuthorities = () => {

    }

    const setSelectedAttachCompany = (company) => {
        setSelectedCompanyToAttach(company)
        setSelectedCompanyNameToAttach(company.name)
    }

    const EditCompanyAccount = () => {
        props.callback(companyId, accountId, accountFio, accountEmail, accountPhone, accountLocaleList, false, accountRoleList, null);
        props.attachTopicsToAccount(props.isSuperadmin, accountId, selectedTopics, companyId, accountRoleList)
        if(selectedCompanyToAttach) props.attachAccountToCompany(accountId, selectedCompanyToAttach.id, 0, 10)
        resetAllList();
    }

    const DetachAccountFromCompany = () => {
        props.callback(companyId, accountId, accountFio, accountEmail, accountPhone, accountLocaleList, false, accountRoleList, "detached");
        resetAllList();
    }

    const AttachAccountToCompany = () => {
        setCompanyId(companyId);
        props.callback(companyId, accountId, accountFio, accountEmail, accountPhone, accountLocaleList, false, accountRoleList, "attached");
        resetAllList();
    }

    const setSelectedTopicName = (topics) => {
        setSelectedTopics(topics)
    }

    return (
        <div className={s.CreateCompanyAccount}>
            <div className={s.formRow}>
                <InputText 
                    callback={addAccountFio}
                    value={accountFio}
                    placeholder={props.getValue("FULL_NAME")}
                    readOnly={props.isEdit ? false : true}
                    labelText={props.getValue("FULL_NAME")}
                />
            </div>
            <div className={s.formRow}>
                <InputText 
                    showError={!checkEmail()}
                    callback={addAccountEmail}
                    value={accountEmail}
                    placeholder={props.getValue("EMAIL")}
                    readOnly={props.isEdit ? false : true}
                    labelText={props.getValue("EMAIL")}
                />
            </div>
            <div className={s.formRow}>
                <InputText
                    showError={!checkPhoneNumber()}
                    callback={addAccountPhone}
                    value={accountPhone}
                    placeholder={props.getValue("PHONE")}
                    readOnly={props.isEdit ? false : true}
                    labelText={props.getValue("PHONE")}
                />
            </div>
            {/*props.isSuperadmin ? <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('COMPANIES')}</span>
                <SelectDropdown
                    items={companies}
                    selected={selectedCompanyNameToAttach}
                    setSelected={setSelectedAttachCompany}
                    showOnly={props.isEdit ? false : true}
                />
    </div> : null*/}
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('ACCOUNT_LOCALE')}</span>
                <SelectDropdown
                    items={accountLocales}
                    selected={accountLocale}
                    setSelected={addAccountLocale}
                    showOnly={props.isEdit ? false : true}
                />
            </div>
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('ROLE')}</span>
                <SelectDropdown
                    items={roles}
                    selected={accountRole}
                    setSelected={addAccountRole}
                    showOnly={props.isEdit ? false : true}
                />
            </div>
            {accountRole === props.getValue('ROLE_USER') ? <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('AUTHORITIES')}</span>
                <AuthoritiesContainer showOnly={props.isEdit ? false : true} accountId={accountId}/>
            </div> : null}
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('TOPICS')}</span>
                { accountTopics && (props.companyId ? props.companyId : props.Account.companyId) && (props.Account && props.Account.status === 'ACTIVE') && (props.Account && (props.Account.role !== 'ROLE_ADMIN' || props.Account.role !== 'ROLE_SUPERADMIN')) ? <List list={accountTopics} setSelected={setSelectedTopicName} selected={selectedTopics} showOnly={props.isEdit ? false : true}/> : null}
            </div>
            {/*
                props.Account.companyId == null && props.isEdit !== 'show' ? 
                <div className={s.AccountBlock}>
                    <div className={s.formRow}>
                        <span className={s.labelText}>{props.getValue('ATTACH_ACCOUNT_TO_COMPANY')}</span>
                        <SelectDropdown
                            items={companies}
                            selected={company}
                            setSelected={addCompany}
                            showOnly={props.isEdit === 'show' ? true : false}
                        />
                    </div>
                    <div className={s.formRow+' '+s.end}>
                        <StandartButton
                            title={props.getValue('ATTACH')}
                            callback={AttachAccountToCompany}
                            disabled={!company && !companyId}
                        />
                       
                    </div>
                   { <span className={s.warningText}>{updateBtnTitle}</span> }
                </div>

                    : props.isEdit !== 'show' ? <div>
                        <StandartButton
                            title={`${props.getValue('DETACH_ACCOUNT_FROM_COMPANY')} ${companyName}`}
                            callback={DetachAccountFromCompany}
                        />
                    </div> : null
            */}

            {props.isEdit ? <div className={s.formRow}>
                <StandartButton
                    name={props.getValue('UPDATE')}
                    title={props.getValue('UPDATE')}
                    callback={EditCompanyAccount}
                    disabled={!accountFio || !checkEmail() || !checkPhoneNumber()}
                />
            </div> : null}
        </div>
    )
}

export default EditCompanyAccount