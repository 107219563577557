import { createSelector } from "reselect";
import { getFormatedDate } from "service/format-date";
import moment from "moment";

const topicData = (state) => {
    return state.ShortAnalytic.dataRange;
}

const mediaTypeData = (state, mediaType, topicUUId) => {
    switch(mediaType){
        case 'MASS_MEDIA':
            let dataMedia = {}
            dataMedia.data = state.ShortAnalytic.dataMedia;
            dataMedia.topicUUId = topicUUId
            return dataMedia
        case 'SOCIAL_MEDIA':
            let dataSocial = {}
            dataSocial.data = state.ShortAnalytic.dataSocial;
            dataSocial.topicUUId = topicUUId
            return dataSocial
        case 'TOTAL':
            let dataTotal = {}
            dataTotal.data = state.ShortAnalytic.dataTotal;
            dataTotal.topicUUId = topicUUId
            return dataTotal
    }
}

const red = "var( --echoRed )";
const green = "var( --echoGreen)";
const blue = "var( --echoSecondary)";
const grey = "#C6C6D1";
const gray = "#9AAAB5";

const getSentimentColor = (sentiment) => {
    switch(sentiment){
        case 'neutral':
            return blue;
        case 'negative':
            return red;
        case 'positive':
            return green;
        case 'undefined':
            return gray;
    }
}

const getSentimentName = (sentiment) => {
    switch(sentiment){
        case 'neutral':
            return 'NEUTRAL'
        case 'undefined':
            return 'UNDEFINED'
        case "negative":
            return "NEGATIVE"
        case "positive":
            return "POSITIVE"
    }
}

const getDate = (time, last = false) => {
    let t = time.split(':')
    let h = t[0];
    let m = t[1];
    let d = new Date();
    d.setHours(h);
    d.setMinutes(m);
    d.setSeconds('00')
    if(last) d.setDate(d.getDate() + 1);

    return moment(d).format('YYYY-MM-DD HH:mm:ss')
}

export const getMediaTypeData = createSelector(mediaTypeData, (mediaType) => {
    let ret = {}
    if(mediaType.data)
        ret = mediaType.data[mediaType.topicUUId] ? mediaType.data[mediaType.topicUUId] : null //mediaType.data.filter(k => k.topicId === mediaType.topicUUId)
    return ret ? ret : {}
});

export const getTopicDataRange = createSelector(topicData, (topic) => {
    let data = [{
        values: [],
        labels: [],
        colors: [],
        list: [],
        topicId: '',
        fields: {}
    }];
    if(topic.length){
        topic.forEach((item, i) => {
            data[i] = [];
            data[i].topicId = item.topicId;
            data[i].values = [];
            data[i].labels = [];
            data[i].colors = [];
            data[i].text = [];
            data[i].list = [];
            data[i].fields = {date: [], sentiment: [], topicId: item.topicId}
            return item.data.map((d, j) => {
                //if(!data[i].fields.date && !data[i].fields.date.length)
                
                
                    data[i].fields.date = d.values.map(k => getDate(k.value));

                    if(data[i].fields.date[0] === data[i].fields.date[data[i].fields.date.length - 1]){
                        data[i].fields.date[data[i].fields.date.length - 1] = getDate("00:00", true)
                    }
                    // add 1 day for last element if it 00:00
                switch(d.value){
                    case 'neutral':
                        data[i].values[0] = d.values.map(k => k.count);
                        if(data[i].labels) data[i].labels = d.values.map(k => k.value);
                        data[i].colors[0] = getSentimentColor('neutral')
                        data[i].text[0] = getSentimentName('neutral');
                        data[i].list[0] = 'NEUTRAL';
                        data[i].fields.sentiment[0] = 'NEUTRAL';
                        //data[i].fields.date[0] = data[i].labels;
                        //data[]
                        break;
                    case 'negative':
                        data[i].values[1] = d.values.map(k => k.count);
                        if(data[i].labels) data[i].labels = d.values.map(k => k.value);
                        data[i].colors[1] = getSentimentColor('negative')
                        data[i].text[1] = getSentimentName('negative');
                        data[i].list[1] = 'NEGATIVE';
                        data[i].fields.sentiment[1] = 'NEGATIVE';
                        //data[i].fields.date[1] = data[i].labels;
                        break;
                    case 'positive':
                        data[i].values[2] = d.values.map(k => k.count);
                        if(data[i].labels) data[i].labels = d.values.map(k => k.value);
                        data[i].colors[2] = getSentimentColor('positive')
                        data[i].text[2] = getSentimentName('positive');
                        data[i].list[2] = 'POSITIVE';
                        data[i].fields.sentiment[2] = 'POSITIVE';
                        //data[i].fields.date[2] = data[i].labels;
                        break;
                    case 'undefined':
                        data[i].values[3] = d.values.map(k => k.count);
                        if(data[i].labels) data[i].labels = d.values.map(k => k.value);
                        data[i].colors[3] = getSentimentColor('undefined')
                        data[i].text[3] = getSentimentName('undefined');
                        data[i].list[3] = 'UNDEFINED';
                        data[i].fields.sentiment[3] = 'UNDEFINED';
                        break;
                }

            })
        })
    }
    return data;
})