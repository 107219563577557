import React, { useEffect, useState } from "react";
import KeywordsList from "items/keywords-list/keywords-list";
import { TagCloud } from 'react-tagcloud';
import s from './words-block.module.scss'

const WordsBlock = (props) => {

    const getSum = (data) => {
        return data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue
          },0);
    }

    const getTitle = (title) => {
        switch(title){
            case 'KeyWordsList':
                return props.getValue('KEY_WORDS');
            case 'TagsList':
                return props.getValue('TAGS');
            case 'PopularWords':
                return props.getValue('POPULAR_WORDS');
        }
    }

    const getLabels = (title) => {
        switch(title){
            case 'KeyWords':
                return props.data.labels;
            case 'Tags':
                return props.data.labels//.map(k => props.getTagName(k))
        }
    }

    const getItemsByType = (title, data) => {
        switch(title){
            case 'KeyWordsList':
                return props.data.data.map(k => {return {value: k.name, count: k.count}})
            case 'TagsList':
                return props.data.data.map(k => {return {value: k.name, count: k.count, color: k.color, textcolor: k.textColor}})
            case 'PopularWords':
                return props.data.data.map(k => {return {value: k.name, count: k.count}})
        }
    }

    const [data, setData] = useState(props.data ? getItemsByType(props.data.pageType, props.data.data) : []);
    const [title, setTitle] = useState(props.data ? getTitle(props.data.pageType) : '')

    useEffect(() => {
        setData(props.data ? getItemsByType(props.data.pageType, props.data.data) : []);
        setTitle(props.data ? getTitle(props.data.pageType) : '')
    }, [props])

    const customRenderer = (tag, size, color, textcolor) => (
        
        <span
          key={tag.value}
          title={tag.value}
          style={{
            animation: 'blinker 3s linear infinite',
            animationDelay: `${Math.random() * 2}s`,
            fontSize: `${size / 1.5}em`,
            border: props.pageType === 'TagsList' ? `2px solid ${color}` : 'none',
            background: props.pageType === 'TagsList' ? `${color}` : 'none',
            borderRadius: `8px`,
            margin: '3px',
            padding: '3px',
            display: 'inline-block',
            color: props.pageType === 'TagsList' ? tag.textcolor ? tag.textcolor : '#ffffff' : color,
            cursor: 'pointer',
            textShadow: '0.5px 0.5px 0.5px gray'
          }}
        >
          {tag.value}
        </span>
    )

    return (
        <div className={s.KeywordsBlock}>
            {
                <div className={s.AnalyticBlock}>
                    <KeywordsList getValue={props.getValue} headerTitle={title} keywordsList={props.data.data} fields={props.data.fields} title={title} pageType={props.data.pageType}/>
                </div>
            }
            {
                <div className={s.AnalyticBlock} style={{ display:"flex", textAlign: 'center', justifyContent: 'center', alignItems: 'center'}} >
                     {<TagCloud
                        minSize={1}
                        maxSize={3}
                        tags={data ? data : []}
                        shuffle={true}
                        onClick={tag => {}}
                        renderer={customRenderer}
                        randomSeed={12}
            />}
                    
                </div>
            }
        </div>
    )
}

export default WordsBlock;