import React from "react";
import Company from "./company";

class CompanyController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            role: this.props.role,
            companyId: this.props.companyId,
        }
    }

    getCompanyByRole = (role) => {
        /*switch(role){
            case 'ROLE_SUPER_ADMIN':*/
            if(this.props.isSuperadmin){
                let companyId = this.props.companyId ? this.props.companyId : this.props.adminCompanyId
                if(companyId) return this.props.getSuperadminCompany(companyId)}
            //case 'ROLE_ADMIN':
            else
                return this.props.getCompany()
        //}
    }

    componentDidMount(){
        if(this.props.role){
            this.getCompanyByRole(this.props.role)
        }
        else{
            this.props.getMe()
        }
        
    }

    componentDidUpdate(){
        if(this.props.role !== this.state.role || this.props.companyId !== this.state.companyId){
            this.getCompanyByRole(this.props.role)
            this.setState({
                companyId: this.props.companyId,
                role: this.props.role
            })
        }
    }

    getCompany = (role) => {
        /*switch(role){
            case 'ROLE_SUPER_ADMIN':*/
            if(this.props.isSuperadmin)
                return this.props.superadminCompany;
            //case 'ROLE_ADMIN':
            else
                return this.props.adminCompany;
        //}
    }

    render(){
        
        return (
            this.props.role ? <Company company={this.getCompany(this.props.role)}/> : <div></div>
        )
    }
}

export default CompanyController