import React, { useState } from "react";
import { useEffect } from "react";
import LineChart from 'react-apexcharts'
import s from "./apex-line-chart.module.scss"

const ApexLineChart = (props) => {

    const [series, setSeries] = useState(props.data.values);

    useEffect(() => {
      setSeries(props.data.values);
    });
    const options = {
        chart: {
          id: props.title,
          height: 100,
          type: 'line',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          events: {
            click: (e, chartContext, config)=>{props.clickOnElement(e, chartContext, config)}
          },
          foreColor: 'var( --caption)'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: props.getValue(props.title),
          align: 'left',
          style: {
            color: 'var(--caption)'
          }
        },
        grid: {
          row: {
            colors: ['#ffffff', 'transparent'],
            opacity: 0.02
          },
        },
        xaxis: {
          categories: props.data && props.data.labels,
        },
        legend: {show: props.legend},
        stroke: {
            width: 2
        },
        //colors: props.colors
        /*tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            return '<div class="arrow_box">' +
              '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
              '</div>'
          }
        }*/
    }
    if(props.colors) options.colors = props.colors

    return (
        <div  id={props.title} className={s.LineChart} style={{background: props.background && props.background}}>
            <LineChart options={options} series={series} type="line" height={props.height} width={props.width} />
        </div>
    )
}

export default ApexLineChart;