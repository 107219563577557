import React from "react";
import ReportTable from "./report-table";
import ControlButtons from "items/control-buttons/control-buttons";
import CheckboxListContainer from "items/checkbox-list/checkbox-list-container";
import CreateReportSettings from "./create-report-settings/create-report-settings";
import s from "../reports.module.scss";
class ReportTableController extends React.Component{

    componentDidMount(){
        this.closeItem();
        this.props.getAdminReportsSettings(0, 100)
    }

    getUserName = (userId) => {
        let user = {}
        if(this.props.users){
            user = this.props.users.filter(k => k.id === userId)
        }
        return user && user[0] ? user[0].login : ''
    }

    closeItem = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    onSend = (reportSettings) => {
        this.props.updateAdminReportSettings(reportSettings.id, {...reportSettings})
        this.closeItem()
    }

    onCreateSend = (userId, reportSettings) => {
        this.props.createAdminReportSettings(userId, reportSettings);
        this.closeItem()
    }

    showRightMenu = (show, title, topic) => {
        if(show){
            this.props.setShowRightMenu(true)
            setTimeout(()=>{this.props.setRightMenuComponent(<TelegramItemContainer topic={topic}/>, 'telegram', null, title, true);},300)
        }
        else{
            this.props.setRightMenuComponent(null);

        }
    }

    onTableItemsClick = (type, key, index) => {
        let title = this.props.getValue('REPORT_SETTINGS')
        if(!key && key === undefined)
            return
        switch(type){
            case 'create':
                this.closeItem();
                this.props.setShowRightMenu(true);
                let selectedData = {};
                selectedData.documentElements = [...this.props.data.documentElements]
                selectedData.segments = [...this.props.data.segments]
                selectedData.textRepresentation = "HIGHLIGHTED_FULL_TEXT";
                selectedData.groupingType = "BY_SENTIMENT";
                this.props.setSelectedReportList(selectedData)
                this.props.setRightMenuComponent(<div className={s.ReportsPage}><CreateReportSettings users={this.props.users} callback={this.onCreateSend} /></div>, 'checkbox-list-create', null, title, true)
                break;
            case 'edit':
                this.closeItem();
                this.props.setSelectedReportList(this.props.reportsData[index])
                this.props.setShowRightMenu(true);
                this.props.setRightMenuComponent(<div className={s.ReportsPage}><CheckboxListContainer onClose={this.showRightMenu} callback={this.onSend}/></div>, 'checkbox-list-edit', false, title, true)
                break;
            case 'delete':
                this.props.deleteReportOperation(key);
                break;
        }
        
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        
        c = [...items];
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    setTableValues = (tableValues) => {
        let users = [];
        let index = 0;
        tableValues.forEach( (k, i) => {
            let user = this.getUserName(k.userId)
            //k.push(this.getControlButtonItems(id, status, ctrl, i))
            if(user){
                index++
                users.push([index, user ? user : '', this.props.getValue(k.groupingType), this.props.getValue(k.textRepresentation), this.getControlButtonItems(k.id, null, this.props.controlButtons, i)])
            }
                
        })
        return users
    }

    getTableHeader = () => {
        return this.props.reportsHeader.map(k => this.props.getValue(k))
    }

    render(){
        return <ReportTable 
                            reportsHeader={this.getTableHeader(this.props.reportsHeader)} 
                            getValue={this.props.getValue} 
                            reportsData={this.props.reportsData} 
                            data={this.setTableValues(this.props.reportsData)}
                            onTableItemsClick={this.onTableItemsClick}
                            />
    }
}

export default ReportTableController;