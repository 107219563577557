import React from "react";
import TopicButton from "./topic-button";

class TopicButtonController extends React.Component{

    render(){
        return (
            <TopicButton {...this.props} />
        )
    }
}

export default TopicButtonController;
