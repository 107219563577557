import { header, getLanguage, checkFilters } from "./header";

const filters = header('restapi/api/v1/filters/')

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    filters.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    filters.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const FilterService = {
    getMediaTypes(){
        setLanguage();
        getAuth();
        return filters.get(`dictionaries/media_types`).then(response => {
            return response.data
        })
    },
    createNewFilter(name, since, till, grouping, filterParameters){
        setLanguage();
        getAuth();
        let params = {name, since, till, grouping, sort: "DATE_DESC",  ...checkFilters(filterParameters)}
        return filters.post('', {...params}).then(response => {
            return response.data
        })
    },
    getAllFilters(){
        setLanguage();
        getAuth();
        return filters.get('', ).then(response =>{ 
            return response.data;
        })
    },
    getFilter(id){
        setLanguage();
        getAuth();
        return filters.get(`${id}`).then(response => {
            return response.data;
        })
    },
    updateFilter(id, name, since, till, grouping, filterParameters){
        setLanguage();
        getAuth();
        
        let params = {name, since, till, grouping, sort: "DATE_DESC",  ...checkFilters(filterParameters)}
        return filters.put(`${id}`, {...params}).then(response => {
            return response.data
        })
    },
    deleteFilter(id){
        setLanguage();
        getAuth();
        return filters.delete(`${id}`).then(response => {
            return response.data;
        })
    }
}