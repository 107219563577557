const ADD_KEYWORD = "ADD-KEYWORD";
const ADD_STOPWORD = "ADD-STOPWORD";
const ADD_PROFILES = "ADD-PROFILES";
const SET_KEYWORDS = "SET-KEYWORDS";
const SET_STOPWORDS = "SET-STOPWORDS";
const SET_RESOURCES = "SET-RESOURCES";
const SET_PROFILES = "SET-PROFILES";
const DELETE_KEYWORD = "DELETE-KEYWORD";
const DELETE_PROFILE = "DELETE-PROFILE";
const SET_UNSAVED_KEYWORDS = "SET-UNSAVED-KEYWORDS";
const SET_TO_DELETE_KEYWORDS = "SET_TO_DELETE_KEYWORDS";
const SET_TYPE = "SET-TYPE";
const DELETE_STOPWORD = "DELETE-STOPWORDS";
const SET_DELETE_OLD = "SET-DELETE-OLD";
const SET_SHOW_DELETE_OLD = "SET-SHOW-DELETE-OLD"


const keywordsItems = {
    keywords: [],
    stopwords: [],
    profiles: [],
    resources: [],
    unsavedKeywords: [],
    toDeleteKeywords: [],
    toDeleteStopwords: [],
    toDeleteProfiles: [],
    isKeyword: true,
    deleteOld: false,
    showDeleteOld: false
}

const Keywords = (state = keywordsItems, action) => {
    switch(action.type){
        case SET_KEYWORDS:
            return {...state, keywords: action.keywords}
        case SET_STOPWORDS:
            return {...state, stopwords: action.stopwords}
        case SET_RESOURCES:
            return {...state, resources: action.resources}
        case SET_PROFILES:
            return {...state, profiles: action.profiles}
        case ADD_KEYWORD:
            return {...state, keywords: [...state.keywords, action.keyword]}
        case ADD_STOPWORD:
            return {...state, stopwords: [...state.stopwords, action.stopword]}
        case ADD_PROFILES:
            return {...state, profiles: [...state.profiles, action.profiles]}
        case SET_UNSAVED_KEYWORDS:
            return {...state, unsavedKeywords: [...state.unsavedKeywords, action.keywords]}
        case DELETE_KEYWORD:
            return {...state, keywords: state.keywords.filter(k => k !== action.keyword), toDeleteKeywords: [...state.toDeleteKeywords, action.keyword]}
        case DELETE_STOPWORD:
            return {...state, stopwords: state.stopwords.filter(k => k !== action.stopword), toDeleteStopwords: [...state.toDeleteStopwords, action.stopword]}
        case DELETE_PROFILE:
            return {...state, profiles: state.profiles.filter(k => k !== action.profiles), toDeleteProfiles: [...state.toDeleteProfiles, action.profiles]}
        case SET_TYPE:
            return {...state, isKeyword: action.isKeyword}
        case SET_DELETE_OLD:
            return {...state, deleteOld: action.deleteOld}
        case SET_SHOW_DELETE_OLD:
            return {...state, showDeleteOld: action.showDeleteOld}
        default:
            return {...state}
    }
}

export default Keywords;

export const addKeyword = (keyword) => {
    return {
        type: ADD_KEYWORD,
        keyword: keyword
    }
}

export const addStopword = (stopword) => {
    return {
        type: ADD_STOPWORD,
        stopword: stopword
    }
}

export const addProfiles = (profiles) => {
    return {
        type: ADD_PROFILES,
        profiles: profiles
    }
}

export const setKeywords = (keywords) => {
    return {
        type: SET_KEYWORDS,
        keywords: keywords
    }
}

export const setStopwords = (stopwords) => {
    return {
        type: SET_STOPWORDS,
        stopwords: stopwords
    }
}

export const setProfiles = (profiles) => {
    return {
        type: SET_PROFILES,
        profiles: profiles
    }
}

export const setResources = (resources) => {
    return {
        type: SET_RESOURCES,
        resources: resources
    }
}

export const setUnsavedKeywords = (keywords) => {
    return {
        type: SET_UNSAVED_KEYWORDS,
        keywords: keywords
    }
}

export const setDeleteKeyWord = (keyword) => {
    return {
        type: DELETE_KEYWORD,
        keyword: keyword
    }
}


export const deleteKeyword = (keyword, type) => {
    return (dispatch) => {
        dispatch(setDeleteKeyWord(keyword, type));
        dispatch(setShowDeleteOld(true))
    }
}

export const deleteStopword = (stopword) => {
    return {
        type: DELETE_STOPWORD,
        stopword: stopword
    }
}

export const deleteProfiles = (profiles) => {
    return {
        type: DELETE_PROFILE,
        profiles: profiles
    }
}

export const setType = (isKeyword) => {
    return {
        type: SET_TYPE,
        isKeyword: isKeyword
    }
}

export const setShowDeleteOld = (showDeleteOld) => {
    return {
        type: SET_SHOW_DELETE_OLD,
        showDeleteOld: showDeleteOld
    }
}

export const setDeleteOld = (deleteOld) => {
    return {
        type: SET_DELETE_OLD,
        deleteOld: deleteOld
    }
}