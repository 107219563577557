import React from "react";
import Header from "./header";
import { Redirect } from 'react-router';
import { current } from "@reduxjs/toolkit";

class HeaderController extends React.Component{
    
    constructor(props){
        super(props)
        //this.props.getMe();
    }
    
    closeModal(){
        this.props.setErrorMessageInModal(null);
        this.props.setNotificationInModal(null)
        this.props.modalShowToggle(false);
        if(this.props.errorMessage && this.props.errorMessage.code === 401){
            this.props.exit();
        }


    }

    clearAllSelected = (page, currentPage = null) => {
        this.props.setReturnBackFilters(null);
        this.props.setReturnBackPageName(null);
        this.props.setMenuSelected(null);
        this.props.setSettingsMenuToNull(true);
        if(page === 'Sample' || page === 'DeployedAnalytic'){
            if(currentPage === 'Document' || currentPage === 'ExtendedAnalytic' || currentPage === 'Comparison'){
                this.props.setIsReturnedBack(true)
            }
            this.props.setMySelectedTopicsFilter(this.props.applyedFilters)
            this.props.setToNull(false);
            this.props.setClearSelected(false);
            this.props.clearSearchList()
        }
        else{
            this.props.setToNull(true);
            this.props.setClearSelected(true);
            this.props.clearSearchList()
        }

    }

    handleResize = () => {

        let width = window.innerWidth;
        if(width < 1000){
            this.props.setDeviceType('mobile')
        }
        else if(width > 1000 && width < 1500){
            this.props.setDeviceType('tablet')
        }
        else{
            this.props.setDeviceType(null)
        }
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        if(this.props.isAuth){
            //this.props.setShowPanel(true);
            !this.props.login && this.props.getMe();
            
        }
        else{
            //this.props.setShowPanel(false);
        }
    }

    componentDidUpdate(){

        if(this.props.notificationInModal && this.props.notificationInModal.title){
            this.props.modalShowToggle("Alert", true, this.closeModal.bind(this, this), this.props.getValue(this.props.notificationInModal.title), this.props.getValue(this.props.notificationInModal.message))
        }
        if(this.props.errorMessage && this.props.errorMessage.code >= 0){
            this.props.modalShowToggle("Alert", true, this.closeModal.bind(this, this), this.props.getValue('ERROR'), this.props.errorMessage.message)
        }
        if((this.props.role === 'ROLE_SUPER_ADMIN' && this.props.companyId) && !this.props.superAdminTopButtons){
            this.props.setSuperadminMenuButtons(true);
            this.props.selectPage('ShortAnalytic')
        }
    }


    render(){
        return <Header {...this.props} clearAllSelected={this.clearAllSelected}/>
    }

}

export default HeaderController;