import React, {useState, useEffect} from "react";
import s from './message-text.module.scss';

const MessageText = (props) => {

    const getProfileMessage = (type, checkProfileItem, checkResourceItem) => {
        if(checkResourceItem !== null || checkProfileItem !== null){
            if(checkResourceItem !== null && type === 'resources'){
                return checkResourceItem ? props.getValue('DOMAIN_IS_ALREADY_BEING_CHECKED') : props.getValue('DOMAIN_IS_NOT_BEING_CHECKED');
            }
            else if(checkProfileItem !== null && type === 'socnetProfiles'){
                return checkProfileItem ? props.getValue('URL_IS_ALREADY_BEING_CHECKED') : props.getValue('URL_IS_NOT_BEING_CHECKED');
            }
        }
        else
            return null
    }

    const [message, setMessage] = useState(getProfileMessage(props.type, props.checkProfileItem, props.checkResourceItem));
    
    useEffect(() => {
        setMessage(getProfileMessage(props.type, props.checkProfileItem, props.checkResourceItem));
    }, [props.type, props.checkProfileItem, props.checkResourceItem])
    return (
        <div className={s.Message}>
            <span>{message ? message : null}</span>
        </div>
    )
        
}

export default MessageText