import React from "react";
import s from "../posts.module.scss";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { StandartButton } from "../../../commons/form-controls";

const ButtonDropdown = (props) => {
    
    let {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)

    const buttonClick = () => {
        props.callback(!isComponentVisible)
        setIsComponentVisible(!isComponentVisible)
        
    }

    const clickItem = (k) => {
        k()
        setIsComponentVisible(!isComponentVisible)
    }

    const items = props.items.map((k, i) => 
         k.show === false ? null : <span key={i} onClick={clickItem.bind(this, k.callback)}>{ k.title }</span>
    )

    return (
        <div ref={ref} className={s.deletePostTypePanel} >
            <div><StandartButton background={'var( --tableItem )'} title={props.getValue(props.buttonText)} icon={props.buttonIcon} callback={buttonClick}/></div>
            {isComponentVisible && <div className={s.deletedPanel}>
                   {items}
                </div>}
        </div>
    )
}

export default ButtonDropdown;