import React from "react";
import AdminTopicsAddItem from "./admin-topics-add-item";

class AdminTopicsAddItemController extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            topicId: this.props.topicId,
            companyId: this.props.companyId,
            isMarker: false
        }
    }

    componentDidMount(){
        this.props.getCompanyAccounts(this.props.companyId, this.props.isSuperadmin, 0, 9999)
        this.props.getTopicAccounts(this.props.companyId, this.props.topicId, this.props.isSuperadmin)
        this.props.getAllMarkers(0, 9999);
    }

    componentDidUpdate(){
        if(this.props.topicId !== this.state.topicId){
            if(this.props.companyId !== this.state.companyId){
                this.props.getCompanyAccounts(this.props.companyId, this.props.isSuperadmin, 0, 9999);
                this.setState({
                    companyId: this.props.companyId
                })
            }
            if(this.state.isMarker)
                this.props.getTopicMarkers(this.props.topicId)
            else
             this.props.getTopicAccounts(this.props.companyId, this.props.topicId, this.props.isSuperadmin);
            this.setState({
                topicId: this.props.topicId
            })
        }

    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    setIsMarker= (isMarker) => {
        if(isMarker){
            this.props.getTopicMarkers(this.props.topicId)
        }
        else{
            this.props.getTopicAccounts(this.props.companyId, this.props.topicId, this.props.isSuperadmin);   
        }
        this.setState({
            isMarker: isMarker
        })
    }

    render(){
        return <AdminTopicsAddItem {...this.props} closeRightMenu={this.closeRightMenu} isMarker={this.state.isMarker} setIsMarker={this.setIsMarker}/>
    }
}

export default AdminTopicsAddItemController;