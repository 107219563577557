import React, { useState } from "react";
import { useEffect } from "react";
import AreaChart from 'react-apexcharts';
import ApexChart from "apex-charts/apex-chart";
import jsPDF from "jspdf";
import s from "./apex-area-chart.module.scss"

const ApexAreaChart = (props) => {

    const [series, setSeries] = useState(props.data.values);

    useEffect(() => {
      setSeries(props.data.values);
    });
    const options = {
        chart: {
          id: props.title,
          height: 100,
          type: 'area',
          
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          events: {
            click: (e, chartContext, config)=>{props.clickOnElement(e, chartContext, config)}
          },
          foreColor: 'var( --caption)'
        },
        dataLabels: {
          enabled: false
        },
        /*stroke: {
          curve: 'straight'
        },*/
        title: {
          text: props.title ? props.getValue(props.title) : '',
          align: 'left',
          style: {
            color: 'var(--caption)'
          }
        },
        grid: {
          row: {
            colors: ['#000000','#000000',],
            opacity: 0.02
          },
        },
        xaxis: {
          categories: props.data && props.data.labels,
        },
        legend: {show: props.legend},
        stroke: {
            width: 2,
            //curve: 'straight'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            opacityFrom: 0.9,
            opacityTo: 0.7,
            
          }
        },
        //colors: props.colors
        /*tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            return '<div class="arrow_box">' +
              '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
              '</div>'
          }
        }*/
    }
    if(props.colors) options.colors = props.colors

    /*const getCtx = () => {
      const chartInstance = ApexCharts.getChartByID(props.title);
     
      const cts = chartInstance ? chartInstance.ctx : null;
        cts.exports.exportToSVG(cts);
        cts.exports.exportToPng()

        var dataURL = cts.dataURI().then((uri) => {

          const downloadLink = document.createElement("a");
          downloadLink.href = uri.imgURI;
          downloadLink.download = "image.png";

          document.body.appendChild(downloadLink);

          downloadLink.click();

          document.body.removeChild(downloadLink);
          var pdf = jsPDF();
          pdf.setProperties({
            title: props.title,
          });

          pdf.addImage(uri.imgURI, 'JPEG', 15, 40, 180, 50);
          pdf.save(props.getValue(props.title)+'.pdf')
        
        });
  }*/

    return (
        <div id={props.title} className={s.LineChart} style={{background: props.background && props.background}}>
            <AreaChart options={options} series={series} type="area" height={props.height} width={props.width} />
        </div>
    )
}

export default ApexAreaChart;