import { createSelector } from "reselect";
import moment from "moment";

const topicTableFromState = (state) => {
    return state.AdminTopics.topics;
}

const accountTopics = (state) => {
    return state.superadmin.accountTopics
}

const AdminUsers = (state) => {
    return state.admin.users;
}

const formatDate = (date) =>{
    return moment(date).format("HH:mm DD.MM.YY");
}

export const getAdminTableFromState = createSelector(topicTableFromState, (table) => {
    if(table){
        return table
        return table.map((item)=>{
            return {id: item.id, name: item.name, createdAt: formatDate(item.createdAt), updatedAt: formatDate(item.updatedAt), keywords: item.keywords, stopwords: item.stopwords, uuid: item.uuid, status: item.status} 
        });
    }

})

export const getAdminTopicsNameAndId = createSelector(topicTableFromState, (topics) => {
    if(topics && topics.content){
        return topics.content.map(topic => { return {id: topic.id, name: topic.name}})
    }
})

export const getAdminUsers = createSelector(AdminUsers, (users) => {
    if(users){
        return users.map(user => { return {...user, name: user.login}})
    }
})
//k => k.uuid === tagId
export const getAdminActiveUsers = createSelector(AdminUsers, (users) => {
    if(users){
        return users.filter(user => { user.name = user.login; return user.status === 'ACTIVE'})
    }
})

export const getAccountTopicsAll = createSelector(accountTopics, (topics) => {
    if(topics){
        return topics
    }
})

export const getAccountTopicsName = createSelector(accountTopics, (topics) => {
    if(topics){
        return topics.map(topic => topic.id)
    }
})