import { createSelector } from "reselect";

const topicData = (state) => {
    return state.TopicMenu.selectedTopics;
}

const subtopicData = (state) => {
    return state.TopicMenu.selectedSubtopics;
}

export const getSelectedTopicsUUID = createSelector(topicData, (topics) => {
        return topics && topics.length ? topics.map((item)=>{
            return item.id
        }) : [];
})

export const getSelectedSubTopicsUUID = createSelector(subtopicData, (subtopics) => {
    return subtopics && subtopics.length ? subtopics.map((item)=>{
        return item.id
    }) : [];
})
