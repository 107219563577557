import FiltersController from "./filters-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getMediaTypesSelector, getToipicFiltersSelector, getSelectedFilters } from "selectors/filters-selector";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { getTopicDropdownFromState, } from "selectors/theme-selector";
import { setFiltersSelected, setIsFilterChanged, setFiltersItemsSelected, setFiltersItemItemsSelected, getMediaTypes, getCompanyTags, getTopicsFilters, getBlackListFilters, setClearSelected, setAddSelectedMyFilter, setMySelectedFilter, setException, setSelectedMyFilterMenu, setFiltersItesListChanged } from "store/filters"
import { clearSearchList } from 'store/search-text';
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        companyId: state.Account.companyId,
        filters: state.Filters.filters,
        //mediaType: state.Filters.mediaType,
        //type: state.Filters.type,
        tags: state.Filters.tags,
        //sentiment: state.Filters.sentiment,
        //keywords: state.Filters.keywords,
        language: state.languages.selectedLanguage,
        selected: state.Filters.selected,
        isChanged: state.Filters.isChanged,
        selectedItems: state.Filters.selectedItems,
        selectedItemItems: state.Filters.selectedItemItems,
        itemsListChanged: state.Filters.itemsListChanged,
        selectedTopic: state.Tree.selectedItem,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        topicData: getTopicDropdownFromState(state),
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        topicFilters: getToipicFiltersSelector(state),
        clearAll: state.Filters.clearAll,
        selectedMyFilter: state.Filters.selectedMyFilter, //getSelectedFilters(state)
        exceptions: state.Filters.exceptions,
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        //selectedTopics: state.Filters.selectedTopics,
        applyedFilters: state.Filters.applyedFilters,
        pageName: state.Filters.pageName,
        selectedTopicsItems: state.TopicSelectItems.selectedTopicsItems,
        searchList: state.SearchText.searchList,
        locations: state.Filters.locations,
        filtersLocations: state.Filters.filtersLocations,
        selectedSubtopics: getSelectedSubTopicsUUID(state),

        selectedTopics: getSelectedTopicsUUID(state),
    }
}

export default connect(mapStateToProps, {getValue, getMediaTypes, setFiltersSelected, setIsFilterChanged, setFiltersItemsSelected, setFiltersItemItemsSelected, getCompanyTags, getTopicsFilters, getBlackListFilters, setClearSelected, setAddSelectedMyFilter, setMySelectedFilter, setException, setSelectedMyFilterMenu, clearSearchList, setFiltersItesListChanged})(FiltersController)