import { SuperadminService } from "connection/superadmin-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_ADMIN_MARKERS = "SET-ADMIN-MARKERS";
const SET_TOPICS_COUNT = "SET-TOPICS-COUNT";
const SET_TOPICS_PAGE = "SET_TOPICS_PAGE";
const SET_MARKER_TOPICS = "SET_MARKER_TOPICS";

const AdminMarkersData = {
    markers: null,
    tableHeader: ['#', 'ROLE_MARKER', 'EMAIL', 'DATE_OF_CREATION', 'STATUS'],
    controlButtons: ["add"],
    page: 0,
    topicsCount: 1,
    topicsPage: 0,
    markerTopics: null
}

const AdminMarkers = (state = AdminMarkersData, action) => {
    switch(action.type){
        case SET_ADMIN_MARKERS:
            return {...state, markers: action.markers}
        case SET_TOPICS_COUNT:
            return {...state, topicsCount: action.topicsCount}
        case SET_TOPICS_PAGE:
            return {...state, topicsPage: action.topicsPage}
        case SET_MARKER_TOPICS:
            return {...state, markerTopics: action.markerTopics}
        default:
            return {...state}
    }
}

export default AdminMarkers;

export const setAdminMarkers = (markers) => {

    return{
        type: SET_ADMIN_MARKERS,
        markers: markers
    }
}

export const setTopicsCount = (topicsCount) => {
    return{
        type: SET_TOPICS_COUNT,
        topicsCount: topicsCount
    }
}

export const setTopicsPage = (page) => {
    return{
        type: SET_TOPICS_PAGE,
        topicsPage: page
    }
}

export const setMarkerTopics = (markerTopics) => {
    return {
        type: SET_MARKER_TOPICS,
        markerTopics: markerTopics
    }
}

export const getAllMarkers = (pageIndex=0, pageSize=20, status=null) => {
    return (dispatch) => {
        SuperadminService.getMarkers(pageIndex, pageSize, status).then(
            data => {
                dispatch(setAdminMarkers(data))
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getMarkerTopics = (accountId) => {
    return (dispatch) => {
        SuperadminService.getMarkerTopics(accountId).then(
            data => {
                dispatch(setMarkerTopics(data))
            }
        )
    }
}

export const detachMarkerTopic = (accountId, topicId) => {
    return (dispatch) => {
        SuperadminService.detachMarkerTopic(accountId, topicId).then(
            data => {
                dispatch(getMarkerTopics(accountId))
            }
        )
    }
}