import React, {useState, useEffect} from "react";
import { StandartButton } from "commons/form-controls";
import {ReactComponent as SearchIcon} from "assets/icons/search.svg";

const CheckArchive = (props) => {

    const [isDisabled, setIsDisabled] = useState(props.isDisabled);
    const [resource, setResource] = useState(props.resource);
    const [type, setType] = useState(props.type);

    useEffect(() => {
        setIsDisabled(props.isDisabled);
        setResource(props.resource);
        setType(props.type);
    }, [props.resource, props.isDisabled, props.type])

    const checkItem = () => {
        type === 'resources' ? props.checkResource(resource) : props.checkProfile(resource)
    }

    return (
        <StandartButton disabled={isDisabled} icon={SearchIcon} callback={checkItem} fill={'white'}/>
    )
        
}

export default CheckArchive