import React from "react";
import AccountCabinet from "./account-cabinet";

class AccountCabinetController extends React.Component{

    render(){
        return (
            <AccountCabinet 
                            LanguageItems={this.props.LanguageItems} 
                            getValue={this.props.getValue}
                            fio={this.props.fio}
                            email={this.props.email}
                            phone={this.props.phone}
                            language={this.props.language}
                            updateAccount={this.props.updateAccount}
                            modalShowToggle={this.props.modalShowToggle}
                            errorAccount={this.props.errorAccount}
                            setErrorAccount={this.props.setErrorAccount}
                        />
        )
    }
}

export default AccountCabinetController;