import React, { useEffect, useState } from "react";
import { InputCheckBox, InputText } from "commons/input-controls";
import s from "./topic-menu.module.scss"
import { ReactComponent as DownRightArrowIcon } from '../../assets/icons/down-right-arrow.svg';

const TopicMenu = (props) => {
    const getSelectedAll = (topicsCount, selectedCount) => {
        return selectedCount === topicsCount ? true : false
    }

    const [items, setItems] = useState(props.items)
    const [selected, setSelected] = useState(props.selected);
    const [selectedSubtopic, setSelectedSubtopic] = useState(props.selectedSubtopics);
    const [showSubtopics, setShowSubtopics] = useState(props.showSubtopics ? props.showSubtopics : false);
    const [selectedAll, setSelectedAll] = useState(getSelectedAll(props.items && props.items.length ? props.items.length : 0, props.selected && props.selected.length ? props.selected.length : 0))
    const [type, setType] = useState(props.type && props.type === 'tree' ? 'tree' : 'checkboxes');

    useEffect( () => {
        setItems(props.items)
        setSelected(props.selected);
        setSelectedSubtopic(props.selectedSubtopics);
        setShowSubtopics(props.showSubtopics ? props.showSubtopics : false);
        setSelectedAll(getSelectedAll(props.items && props.items.length ? props.items.length : 0, props.selected && props.selected.length ? props.selected.length : 0));
        setType(props.type && props.type === 'tree' ? 'tree' : 'checkboxes');
    }, [props])

    const addSearchText = () => {

    }

    const checkLimitTitle = (title, limit = null) => {
        if(limit && title && title.length > limit){
            let t = title.slice(0, limit);
            let str = t.split(" ");
            str.pop();
            
            return str.join(" ")+"...";
        }
        else
            return title
    }

    const selectAll = (e) => {
        let isSelected = !selectedAll
        isSelected ? props.setSelectedTopicsAll(items.map(k => {return {name: k.name, id: k.uuid}})) : props.setSelectedTopicsAll([])
    }

    const selectChecked = (uuid, isSelected, selected) => {
        props.setSelectedTopics({name: selected, id: uuid})
    }

    const selectCheckedSubtopics = (uuid, selected, selectedTopic, isSelected, sele) => {
        props.setSelectedSubtopics({name: selectedTopic, id: selected}, {name: sele, id: uuid})
    }

    const selectItem = (name, uuid) => {
        props.setSelectedTopicsAll([{name: name, id: uuid}])
    }

    const selectSubtopicItem = (topic, subtopic, e) => {
        props.setSelectedSubtopics({name: topic.name, id: topic.uuid}, {name: subtopic.name, id: subtopic.uuid})
    }

    const selectSubtopicsItem = (topic, subtopic, e) => {
        props.setSelectedSubtopicsAll([{name: topic.name, id: topic.uuid}], [{name: subtopic.name, id: subtopic.uuid}])
    }

    const checkboxItems = items.map( (k, i) => 
        <div className={s.CheckItem} key={k.uuid}>
            <div className={s.CheckItemName}>
                <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selected.indexOf(k.uuid) !== -1} name={k.name} callback={selectChecked.bind(this, k.uuid)}/>
                <span>{checkLimitTitle(k.name, 60)}</span>
            </div>

            {showSubtopics && k.subtopics /*&& props.selectedTopics.indexOf(k.uuid) !== -1*/ ? k.subtopics.map((subtopic, j) => <div key={subtopic.uuid} className={s.CheckItemName+' '+s.SubtopicItemName}>
                <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectedSubtopic.indexOf(subtopic.uuid) !== -1} name={subtopic.name} callback={selectCheckedSubtopics.bind(this, subtopic.uuid, k.uuid, k.name)}/>
                <span>{checkLimitTitle(subtopic.name, 60)}</span>
            </div>) : null}
        </div>
    )

    const buttonItems = items.map((k, i) => {
            return <div className={s.topicItem} key={k.uuid}>
                        <button onClick={selectItem.bind(this, k.name, k.uuid)} className={props.selected.indexOf(k.uuid) !== -1 ? s.topic+' '+s.selected : s.topic+''}>{checkLimitTitle(k.name, 60)}</button>
                        {showSubtopics && k.subtopics && props.selected.indexOf(k.uuid) !== -1 ? k.subtopics.map((subtopic, j) => <div key={subtopic.uuid} className={s.subtopicItem}><DownRightArrowIcon /><button onClick={selectSubtopicsItem.bind(this, k, subtopic)} className={selectedSubtopic.indexOf(subtopic.uuid) !== -1 ? s.subtopic+" "+s.selectedSubtopic : s.subtopic}>{checkLimitTitle(subtopic.name, 60)}</button></div>) : null}
                    </div>
        }
    )

    return (
        <div className={s.TopicMenu}>
            {type === 'checkboxes' 
                ?   <div className={s.Checkboxes}>
                        <div className={s.ItemHeader}>
                            <div className={s.CheckItem}>
                                <div className={s.CheckAllItem}>
                                    <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectedAll} callback={selectAll} name={'name+121'} title={props.getValue('CHOOSE_ALL')} />
                                    <span>{props.getValue('CHOOSE_ALL')}</span>
                                </div>

                            </div>
                        </div>
                        <div className={s.MenuBody}>
                            { checkboxItems }
                        </div>
                    </div>



                :   <div className={s.Tree}>
                        { buttonItems }
                    </div>
            }
        </div>
    )
}

export default TopicMenu;