const SET_TABLE_DATA = "SET-TABLE-DATA";
const SET_SELECTED_TABLE_ITEM = "SET_SELECTED_TABLE_ITEM";

const tableData = {
    data: null,
    selectedTableItem: null
}

const table = (state = tableData, action) => {
    switch(action.type){
        case SET_TABLE_DATA:
            return {...state, data: action.data}
        case SET_SELECTED_TABLE_ITEM:
            return {...state, selectedTableItem: action.selectedTableItem}
        default:
            return {...state}
    }
}

export default table;

export const setTableData = (data) => {
    return{
        type: SET_TABLE_DATA,
        data: data
    }
}

export const setSelectedTableItem = (selected) => {
    return {
        type: SET_SELECTED_TABLE_ITEM,
        selectedTableItem: selected
    }
}