import TelegramItemController from "./telegram-item-controler"
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getTopicDropdownFromState, getSubtopicsFromState, getSubtopicsDropdownFromState, getSubtopicTableFromState,getSubtopicItem} from "selectors/theme-selector";
import { getSubtopics, setSubtopicsUpdated, setSubtopicItem } from "store/topic-item";
import {    setSelectedTelegramItem, 
            setSelectedTelegramTopic, 
            setSelectedTelegramSubTopic, 
            createSubscription,
            createSubtopicSubscription,
            updateSubscription,
            updateSubtopicSubscription,
            addSubscriberToSubscription, 
            setSubscriptionId, 
            getSubscriptionAlertPolicies,
            setReplaceSubscribers,
            setUpdated,
            deleteSubtopicSubscriptionAndCreateSubscription,
            setDeletedPolicies,
            getSubscriptionEmails,
            setSubscriptionEmails
        } from "store/telegram";
import { getMyTopics } from "store/theme-settings";
import { getAllFilters } from "store/filters";

let mapStateToProps = (state) => {

    return {
        isDataUpdated: state.themeSettings.isDataUpdated,
        topics: getTopicDropdownFromState(state),
        subtopics: getSubtopicsDropdownFromState(state),
        selectedTelegramItem: state.telegram.selectedTelegramItem,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        selectedTelegramTopic: state.telegram.selectedTelegramTopic,
        selectedTelegramTopicUUId: state.telegram.selectedTelegramTopicUUId,
        selectedTelegramSubTopic: state.telegram.selectedTelegramSubTopic,
        mediaTypes: state.telegram.mediaTypes,
        myFilters: state.Filters.myFilters,
        subscriptionId: state.telegram.subscriptionId,
        subscribers: state.telegram.subscribers,
        telegramPolicies: state.telegram.telegramPolicies,
        groupings: state.telegram.groupings,
        updated: state.telegram.updated,
        alertPolicy: state.telegram.alertPolicy,
        metricTypes: state.telegram.metricTypes,
        isSubtopicsUpdated: state.TopicItem.isSubtopicsUpdated,
        telegramSentiments: state.telegram.telegramSentiments,
        deletedPolicies: state.telegram.deletedPolicies,
        email: state.Account.email,
        emails: state.telegram.subscriptionEmails,
        selectedLanguage: state.languages.selectedLanguage,
    }
}

export default 
    connect(mapStateToProps, {  getValue, 
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent, 
                                getMyTopics, 
                                setSelectedTelegramItem, 
                                getSubtopics, 
                                setSubtopicsUpdated, 
                                setSelectedTelegramTopic, 
                                setSelectedTelegramSubTopic,
                                createSubscription,
                                createSubtopicSubscription,
                                getAllFilters,
                                addSubscriberToSubscription,
                                setSubscriptionId,
                                updateSubscription,
                                updateSubtopicSubscription,
                                getSubscriptionAlertPolicies,
                                setReplaceSubscribers,
                                setUpdated,
                                setSubtopicItem,
                                deleteSubtopicSubscriptionAndCreateSubscription,
                                setDeletedPolicies,
                                getSubscriptionEmails,
                                setSubscriptionEmails
                            })(TelegramItemController)