import moment from "moment";

export const getTimezone = (date) => {
    return moment.tz(date, moment.tz.guess())
}

export const getFormatDate = (date, format) => {
    let res = getTimezone(date).format(format)
    return res;
}

export const getFormatedDate = (date) => {
    let timezone = getTimezone(date);
    let formatedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss")
    
    let res = formatedDate+timezone.format('Z')
    return res;
}