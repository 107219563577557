import React, { useState } from "react";
import TableItem from "items/table/table";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

const ReportTable = (props) => {
    let [selectedItem, setSelectedItem] = useState(0);

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            setSelectedItem(item);
        }
    }

    const createReportSettings = (type) => {
        props.onTableItemsClick(type, 1)
    }

    const addItemButton = <StandartButton title={props.getValue('CREATE_REPORT_SETTINGS')} icon={PlusIcon} fill='white' callback={createReportSettings.bind(this, "create")}/>

    return <div>
                    {<TableItem 
                        addItemButton={addItemButton} 
                        data={[props.reportsHeader, props.data]}
                        hiddenColumns={props.hiddenColumns}
                        getValue={props.getValue}
                        selectedItem={selectedItem}
                        selectItem={selectTableITem}
                        height={300}
                        titleText={props.getValue('NOTIFICATIONS')}/>}
    </div>
}

export default ReportTable;