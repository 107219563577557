import { header, getLanguage } from "./header";

const company = header('restapi/api/v1/companies/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    company.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    company.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const CompanyService = {
    getCompanyTags(companyId, pageIndex = 0, pageSize = 9999){
        setLanguage();
        getAuth();
        return company.get(`${companyId}/tags/?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(response => {
            return response.data
        })
    },
    createCompanyTag(companyId, name, color, textColor){
        return company.post(`${companyId}/tags/`, {name, color, textColor}).then(response => {
            return response.data
        })
    },
    deleteCompanyTag(companyId, tagId){
        return company.delete(`${companyId}/tags/${tagId}`,).then(response => {
            return response.data
        })
    },
    updateCompanyTag(companyId, tagId, tag){
        return company.put(`${companyId}/tags/${tagId}`, {...tag}).then( response => {
            return response.data
        })
    },
    createCompanyAccount(companyId, login, fio, email, phone, password, locale){
        setLanguage();
        getAuth();
        return company.post(
            `/${companyId}/accounts`, {
                login, fio, email, phone, password, locale
            }
        ).then(
            response => {
                return response.data
            }
        )    
    },
}