import React, {useState, useEffect} from "react";
import { InputText, SelectDropdown } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import {ReactComponent as EditIcon} from "assets/icons/edit.svg"
import ErrorMessage from "pages/authorization/signin/error-message/error-message";
import s from "./account-cabinet.module.scss";


const AccountCabinet = (props) => {

    const phoneRegExp = /^[0-9\-\+]{11,13}$/;
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const checkPhoneNumber = (phone) => {
        if(!phone || phone && phone.match(phoneRegExp)){
            setIsPhoneValid(null);
            setRerender(!rerender)
            return true
        }
        else{
            setIsPhoneValid(props.getValue("NOT_VALID_PHONE"));
            setRerender(!rerender)
            return false
        }
    }

    const checkEmail = (email) => {
        if(!email || email && email.match(emailRegExp)){
            setIsEmailValid(null);
            setRerender(!rerender)
            return true
        }
        else{
            setIsEmailValid(props.getValue("NOT_VALID_EMAIL"));
            setRerender(!rerender)
            return false
        }
    }

    const getLanguage = (lang) => {
        switch(lang){
            case 'RUS':
                return 'ru';
            case 'ENG':
                return 'en'
            case 'KAZ':
                return 'kk';
        }
    }

    const getLanguages = (languages) => {
        return languages && languages.length ? languages.map(k => { return {name: props.getValue(getLanguage(k.short_name ? k.short_name : k.list)), list: k.short_name}}) : []
    }

    const [fio, setFio] = useState(props.fio ? props.fio : '');
    const [phone, setPhone] = useState(props.phone ? props.phone : '');
    const [email, setEmail] = useState(props.email ? props.email : '');
    const [languages, setLanguages] = useState(getLanguages(props.LanguageItems));
    const [selectedLanguage, setSelectedLanguage] = useState({name: props.getValue(getLanguage(props.language)), list: props.language});
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(null);
    const [isEdit, setIsEdit] = useState(true);
    const [isChanged, setIsChanges] = useState(false);
    let [showError, setShowError] = useState(props.errorAccount);
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        setFio(props.fio ? props.fio : '');
        setPhone(props.phone ? props.phone : '');
        setEmail(props.email ? props.email : '');
        setLanguages(getLanguages(props.LanguageItems));
        setSelectedLanguage({name: props.getValue(getLanguage(props.language)), list: props.language});
        setShowError(props.errorAccount);
        setRerender(!rerender);
    }, [props.fio, props.phone, props.email, props.language, props.errorAccount])

    const closeModal = () => {
        props.modalShowToggle(false)
    }

    const setLanguage = (language) => {
        setSelectedLanguage(language);
        setIsChanges(true);
    }

    const changeName = (value) => {
        setFio(value);
        setIsChanges(true);
    }

    const changePhone = (value, e) => {
        /*if(e.nativeEvent.inputType === 'deleteContentBackward'){
            setIsEmailValid(null)
        }
        else{*/
            checkPhoneNumber(value);
        //}
        setRerender(!rerender)
        setPhone(value);
        setIsChanges(true);
    }

    const changeEmail = (value, e) => {
        
        /*if(e.nativeEvent.inputType === 'deleteContentBackward'){
            setIsEmailValid(null)
        }
        else{*/
            checkEmail(value);
            setEmail(value);
        //}
        setIsChanges(true);
        setRerender(!rerender)
    }

    const setIsEditButton = () => {
        setIsEdit(!isEdit)
        if(!isEdit){
            setEmail(props.email);
            setFio(props.fio);
            setPhone(props.phone);
            setSelectedLanguage({name: props.getValue(getLanguage(props.language)), list: props.language});
            setIsEmailValid(null);
            setIsPhoneValid(null);
            setIsChanges(false);
        }

    }
    
    const editAccount = () => {
        let isValid = true;
        if(!checkEmail(email)){
            setIsEmailValid(props.getValue("NOT_VALID_EMAIL"));
            isValid = false
        }
        else{
            setIsEmailValid(null)
            isValid = true;
        }
        if(!checkPhoneNumber(phone)){
            setIsPhoneValid(props.getValue("NOT_VALID_PHONE"));
        }
        else
            setIsPhoneValid(null)
        if(isValid){
            props.updateAccount(fio, email, phone, getLanguage(selectedLanguage.list))
            setIsEdit(!isEdit)
            setIsChanges(!isChanged);
            //isChanged(false)
        } 
    }

    if(showError){
        setTimeout(() => {
            props.setErrorAccount(null)
        }, 7500)
    }

    return(
        <div className={s.AccountCabinet}>
            {showError && (showError.code !== 401 && showError.code !== 404) && 
                <ErrorMessage getValue={props.getValue} message={showError}/>
            }
            <div className={s.AccountContainer}>
                <div className={s.rowBetween}>
                    <div></div>
                    <StandartButton title={props.getValue("UPDATE")} icon={EditIcon} background={isEdit ? 'white' : ''} callback={setIsEditButton} fill={!isEdit ? 'white' : ''} stroke={!isEdit ? 'white' : ''}/>
                </div>
                <div className="formRow">
                    <span className="labelText">{props.getValue('FULL_NAME')}</span>
                    <InputText readOnly={isEdit} value={fio} callback={changeName}/>     
                </div>
                <div className="formRow">
                    <InputText readOnly={isEdit} value={phone} labelText={props.getValue('PHONE')} callback={changePhone} error={isPhoneValid ? isPhoneValid : null}/>
                </div>
                <div className="formRow">
                    <span className="labelText">{props.getValue('LANGUAGE')}</span>
                    <SelectDropdown showOnly={isEdit} selected={selectedLanguage.name} items={languages} setSelected={setLanguage}/>
                </div>
                <div className="formRow">
                    <InputText readOnly={isEdit} value={email} labelText={props.getValue('EMAIL')} error={isEmailValid ? isEmailValid : null} callback={changeEmail}/>
                </div>
                <div className={s.rowBetween}>
                    <StandartButton disabled={isPhoneValid || isEmailValid || !isChanged} title={props.getValue('SAVE')} callback={editAccount}/>
                    {<StandartButton title={props.getValue('CANCEL')} callback={closeModal}/>}
                </div>
            </div>

        </div>
    )
}

export default AccountCabinet;