import React from "react";
import MultiPostPanel from "./multi-post-panel";

class MultiPostPanelController extends React.Component{

    componentDidMount(){

    }

    getFeedType = (page) => {
        switch(page){
            case 'Documents':
                return 'main'
            case 'Favourite':
                return 'favourite_auto'
            case 'FavouritePublications':
                return 'favourite_manual'
            case 'BlackList':
                return 'blacklisted'
            case 'RecycleBin':
                return 'deleted'
            default:

        }
    }

    updateMultipleTopics = (sentiment, selectedUUId, feedType, startDate, endDate, grouping, filters, selectedPostItems) => {
        this.props.updateSentimentMultipleTopics(sentiment, selectedUUId, feedType, startDate, endDate, grouping, filters)
        this.props.SentimentsOfList(selectedPostItems, sentiment)
    }

    updateMultipleTopicsStatus = (status, selectedUUId, feedType, startDate, endDate, grouping, filters, selectedPostItems) => {
        this.props.updateStatusMultipleTopics(status, selectedUUId, feedType, startDate, endDate, grouping, filters);
        //this.props.setDocumentStatus(selectedPostItems, status, 'group');
    }

    updateMultipleTopicsToFavourite = (favoriteStatus, selectedUUId, feedType, startDate, endDate, grouping, filters, selectedPostItems) => {
        
        this.props.setDocumentFavouriteStatus(selectedUUId, favoriteStatus, selectedPostItems)
        this.props.updateFavouriteMultipleTopics(favoriteStatus, selectedUUId, feedType, startDate, endDate, grouping, filters);
    }

    render(){
        return <MultiPostPanel {...this.props} updateMultipleTopics={this.updateMultipleTopics} updateMultipleTopicsStatus={this.updateMultipleTopicsStatus} updateMultipleTopicsToFavourite={this.updateMultipleTopicsToFavourite} getFeedType={this.getFeedType}/>
    }

}

export default MultiPostPanelController;