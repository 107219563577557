import React from "react";
import AdminAccountsPage from "./admin-accounts-page";

class AdminAccountsPageController extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            currentPage: 0,
            showedItemsCount: 10
        }
    }

    getAccounts = (currentPage, showedItemsCount) => {
        this.props.getAccounts(this.props.isSuperadmin, currentPage, showedItemsCount)
    }

    componentDidMount(){
        this.props.setCompanyId(null)
        this.getAccounts(0, 10);
    }

    componentDidUpdate(){
        if(this.props.currentPage !== this.state.currentPage || this.props.showedItemsCount !== this.state.showedItemsCount){
            
            this.setState({
                currentPage: this.props.currentPage,
                showedItemsCount: this.props.showedItemsCount
            })
            this.getAccounts(this.props.currentPage, this.props.showedItemsCount);
        }
        if(Number(this.props.adminAccountsCount) && this.props.itemsCount !== this.props.adminAccountsCount){
            this.props.setItemsCount(this.props.adminAccountsCount)
        }
    }

    render(){
        return (
            <AdminAccountsPage />
        )
    }

    componentWillUnmount(){
        this.props.setAccountId(null)
        this.props.setTopicId(null)
        /*this.props.setItemsCount(null)
        this.props.setCurrentPage(0)
        this.props.setShowedItemsCount(10)*/
        this.props.setToDefaultPaginator();
    }
}

export default AdminAccountsPageController;