import { createSelector } from "reselect";

const calendarStartDate = (state) => {
    return state.Calendar.startDate;
}

const calendarEndDate = (state) => {
    return state.Calendar.endDate;
}

const formatDate = (date) =>{
    return new Date(date); 
}

export const getStartDate = createSelector(calendarStartDate, (startDate) => {
    return parseInt(startDate) ? formatDate(startDate * 1000) : startDate
})

export const getEndDate = createSelector(calendarEndDate, (endDate) => {
    return endDate || endDate !== 'null' ? (parseInt(endDate) ? formatDate(endDate * 1000) : endDate) : null
})

