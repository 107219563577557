import React, {useState, useEffect} from "react";
import DigestWeekDays from "../digest-week-days/digest-week-days";
import DigestMonthDays from "../digest-month-days/digest-month-days";
import { StandartButton } from "commons/form-controls";
import { InputText, SelectDropdown, InputTimeBlock, InputCheckBox } from "commons/input-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import EmailsList from "items/emails-list/emails-list";
import { getTimezone } from "service/format-date"
import s from "./digest-item.module.scss";
import { getValue } from "store/languages";

const DigestItem = (props) => {

    const getReportFeedTypeName = (feedTypes) => {
        return feedTypes.map( k => {return {name: props.getValue(k.name), list: k.list}})
    }

    const getFeedType = (item, type) => {
        return props.reportFeedTypes.find(f => f[type] === item)
    }

    const getTimeNumbers = (time) => {
        if(time){
            let t = time.map(k => k.split(":"));
            let h = t.map(k => {return {h: Number(k[0]), m: Number(k[1].split("+")[0])}})
            return h
        }     
    }

    const getAllFilters = (f) => {
        let filter = f;
        if(filter && filter.length){
            if(filter[0].name !== null)
                filter.unshift({id: null, name: null})
        }
        return filter
    }

    const getPeriods = (periods) => {
        return periods.map(k => {return {name: props.getValue(k), list: k}});
    }

    const getSelectedReportFormat = (format) => {
        let r = props.reportFormatTypes.find(f => f.short_name === format)
        return props.getValue(r.title)
    }

    const getUser = (selectedRole, selectedUserId) => {
        if(selectedRole === "ROLE_ADMIN" && selectedUserId)
            return props.getUserName(selectedUserId)
        else
            return null
    }

    const getSubtopics = (subtopics) => {
        if(subtopics[0].name)
            subtopics.unshift({name: null, id: null})
        return subtopics
    }

    const [emails, setEmails] = useState(props.emails ? props.emails : [props.email])
    const [selectedTopicId, setSelectedTopicId] = useState(null)
    const [account, setAccounts] = useState(props.users);
    const [selectedUser, setSelectedUser] = useState(props.showType === 'create' ? getUser(props.selectedRole, props.selectedUserId) : getUser(props.selectedRole, props.userId));
    const [periods, setPeriods] = useState(props.digestPeriod ? getPeriods(props.digestPeriod) : [])
    const [topic, setTopic] = useState(props.topic);
    const [selectedTopic, setSelectedTopic] = useState(props.topic ? props.topic.name : null);
    const [selectedTopicUUId, setSelectedTopicUUId] = useState(props.topicUUID);
    const [selectedFilter, setSelectedFilter] = useState(props.filter ? props.filter.name : null);
    const [filterId, setfilterId] = useState(props.filter ? props.filter.id : null);
    const [selectedPeriod, setSelectedPeriod] = useState(props.type ? props.type : 'DAILY');
    const [selectedPeriodName, setSelectedPeriodName] = useState(props.type ? props.getValue(props.type) : props.getValue('DAILY'));
    const [reportFormatTypes, setReportFormatTypes] = useState(props.reportFormatTypes.map(k => {return {title: k.title, name: props.getValue(k.name), short_name: k.short_name}}))
    const [selectedFormat, setSelectedFormat] = useState((props.formatType && props.formatType !== undefined) ? props.reportType : null);
    const [selectedFormatName, setSelectedFormatName] = useState((props.reportType && props.reportType !== undefined) ? getSelectedReportFormat(props.reportType) : null);
    const [digestTimes, setDigestTimes] = useState(props.times ? getTimeNumbers(props.times) : [null]);
    const [selectedWeek, setSelectedWeek] = useState(props.periods ? props.periods : []);
    const [dayMonth, setDayMonth] = useState(props.periods ? props.periods : []);
    const [rerender, setRerender] = useState(false);
    const [subtopics, setSubtopics] = useState(props.topic && props.topic.subtopics ? getSubtopics(props.topic.subtopics) : []);
    const [withoutGraphics, setWithoutGraphics] = useState(props.includeAnalytics ? props.includeAnalytics : false );
    const [selectedSubtopic, setSelectedSubtopic] = useState(props.selectedDigestSubtopicUUID ? props.selectedDigestSubtopicUUID :  null);
    const [selectedSubtopicName, setSelectedSubtopicName] = useState(props.selectedDigestSubtopicName ? props.selectedDigestSubtopicName :  null);
    const [filters, setFilters] = useState(props.myFilters ? getAllFilters(props.myFilters) : null);
    const [reportFeedTypes, setReportFeedTypes] = useState( getReportFeedTypeName(props.reportFeedTypes) );
    const [selectedFeedType, setSelectedFeedType] = useState( props.reportFeedType ? getFeedType(props.reportFeedType, 'list') : props.reportFeedTypes[0] );
    const [readOnly, setReadOnly] = useState( props.showType === 'show' ? true : false);

    useEffect(()=>{
        setAccounts(props.users);
        setSelectedUser(props.showType === 'create' ? getUser(props.selectedRole, props.selectedUserId) : getUser(props.selectedRole, props.userId));
        setSelectedTopic(props.topic && props.topic.name ? props.topic.name : null);
        setTopic(props.topic);
        setSelectedTopicUUId(props.topicUUID);
        setPeriods(props.digestPeriod ? getPeriods(props.digestPeriod) : [])
        setSelectedPeriod(props.type ? props.type : 'DAILY');
        setSelectedPeriodName(props.type ? props.getValue(props.type) : props.getValue('DAILY'));
        setSelectedWeek(props.periods ? props.periods : [])
        setDayMonth(props.periods ? props.periods : [])
        setSelectedFormat(props.formatType ? props.formatType : null);
        setSelectedFormatName((props.formatType && props.formatType !== undefined) ? getSelectedReportFormat(props.formatType) : null);
        setSelectedFilter(props.filter ? props.filter.name : null);
        setEmails(props.emails ? props.emails : [props.email]);
        setDigestTimes(props.times ? getTimeNumbers(props.times) : [null]);
        setWithoutGraphics(props.includeAnalytics ? props.includeAnalytics : false );
        setSubtopics(props.topic && props.topic.subtopics ? getSubtopics(props.topic.subtopics) : []);
        setSelectedSubtopic(props.subtopic ? props.subtopic.uuid :  null);
        setSelectedSubtopicName(props.subtopic ? props.subtopic.name :  null);
        setFilters(props.myFilters ? getAllFilters(props.myFilters) : null);
        setReportFeedTypes( getReportFeedTypeName(props.reportFeedTypes) );
        setSelectedFeedType( props.reportFeedType ? getFeedType(props.reportFeedType, 'list') : props.reportFeedTypes[0] );
        setReadOnly( props.showType === 'show' ? true : false);
        setRerender(!rerender);
    },[props]);

    const getFormatedTime = (time) => {
        let tz = getTimezone().format("Z");
        if(time){
            if(!time.m)
                time.m = 0
            if(!time.h)
                time.h = 0
            let f = (!time.h || time.h < 10) ? '0'+time.h : time.h;
            let s = (!time.m || time.m < 10) ? '0'+time.m : time.m;
            return f+':'+s+tz;
            
        }
        else
            return '00:00'+tz;
    }

    const addDigestTimes = () => {
        let n = digestTimes;
        n.push(null);
        setDigestTimes(n)
        n.length <= (selectedPeriod === "DAILY" ? 24 : 1) && setRerender(!rerender);
    }

    const selectPeriod = (period) => {
        setDayMonth([]);
        setSelectedWeek([])
        setSelectedPeriod(period.list)
        setSelectedPeriodName(props.getValue(period.name))
    }

    const setEmailsList = (emails) => {
        setEmails(emails)
    }

    const selectTopic = (selectedTopic) => {

        let subtopics = selectedTopic.subtopics
        if(subtopics && subtopics.length){
            if(subtopics[0].name)
                subtopics.unshift({name: null, id: null})
            setSubtopics(subtopics)
        }
        else{
            setSubtopics(null)
        }

        setSelectedTopic(selectedTopic.name);
        setSelectedTopicUUId(selectedTopic.uuid)
        setSelectedTopicId(selectedTopic.id);
        setSelectedSubtopic(null);
        setSelectedSubtopicName(null)
        setRerender(!rerender)
    }

    const selectSubtopic = (subtopic) => {
        setSelectedSubtopic(subtopic.uuid)
        setSelectedSubtopicName(subtopic.name)
    }

    const selectFilter = (filter) => {
        setSelectedFilter(filter.name)
        setfilterId(filter.id)
    }

    const setNumber = (index, value, type) => {
        let n = digestTimes;
        if(!n[index])
            n[index] = {h: '', m: ''}
        if(type === 'first'){
            n[index].h = value;
        }
       else if(type === 'second'){
            n[index].m = value;
        }
        setDigestTimes(n);
    }

    const removeDigestTime = (index) => {
        let e = digestTimes;
        e.splice(index, 1);
        setDigestTimes(e);
        setRerender(!rerender);
    }

    const setWithoutGraphic = () => {
        setWithoutGraphics(!withoutGraphics)
    }

    const setFormat = (format) => {
        setSelectedFormat(format.short_name)
        setSelectedFormatName(getValue(format.title))
    }

    const getPeriodByType = (period) => {

        switch(period){
            case 'WEEKLY':
                return {weekDays : selectedWeek.map(k => props.weekDays[k])};
            case 'MONTHLY':
                return {monthDays : dayMonth};
            default:
                return null
        }
    }

    const updateDigest = (digestSchedule) => {
        if(selectedSubtopic) {
            if(!props.isSubtopic){
                props.deleteDigest(props.selectedRole, props.id);
                props.createDigestSubtopic(props.selectedRole, props.userId, selectedTopicUUId, selectedSubtopic, selectedFormat, emails, filterId, digestSchedule, withoutGraphics, selectedFeedType.list)
            }
            else{
                props.updateDigestSubtopic(props.selectedRole, props.userId, props.id, selectedTopicUUId, selectedSubtopic, selectedFormat, emails, filterId, digestSchedule, withoutGraphics, selectedFeedType.list)
            }
        }
        else{
            if(props.isSubtopic){
                props.deleteDigest(props.selectedRole, props.id);
                props.createDigest(props.selectedRole, selectedTopicUUId, props.userId, selectedFormat, emails, filterId, digestSchedule, withoutGraphics, selectedFeedType.list)
            }
            else
                props.updateDigest(props.selectedRole, props.userId, props.id, selectedTopicUUId, selectedFormat, emails, filterId, digestSchedule, withoutGraphics, selectedFeedType.list)
        }
    }

    const createDigest = () => {
        let time = digestTimes.map( t => getFormatedTime(t))

        let period = getPeriodByType(selectedPeriod)

        let digestSchedule = {type: selectedPeriod,times: time, ...period}
        let emailsList = emails.filter(e => e !== null)
        if(props.showType === 'edit'){
            updateDigest(digestSchedule);    
        }
        else{
            selectedSubtopic 
                ? props.createDigestSubtopic(props.selectedRole, props.showType === 'create' ? props.selectedUserId : props.userId, selectedTopicUUId, selectedSubtopic, selectedFormat, emailsList, filterId, digestSchedule, withoutGraphics, selectedFeedType.list)
                : props.createDigest(props.selectedRole, selectedTopicUUId, props .showType === 'create' ? props.selectedUserId : props.userId, selectedFormat, emailsList, filterId, digestSchedule, withoutGraphics, selectedFeedType.list)
        } 
        props.closeItem();
    }

    const setSelectedWeekDays = (selectedItem) => {
        let e = selectedWeek;
        let n = e.indexOf(selectedItem)
        if(n > -1) e.splice(n, 1);
        else e.push(selectedItem)
        setSelectedWeek(e);
        setRerender(!rerender);
    }

    const selectAllWeekdDays = (select) => {
        let n = []
        if(select){
            
            props.weekDays.forEach((e, i)=>{
                n.push(i)
            })
            
        }
        else n = []
        setSelectedWeek(n);
        setRerender(!rerender)
    }

    const addDayMonth = (value) => {
        if(dayMonth.length < 31){
            let n = dayMonth;
            n.push(value);
            setDayMonth(n);
            setRerender(!rerender)
        }
    }

    const removeDayMonth = (value) => {
        let d = dayMonth;
        let n = d.indexOf(value)
        if(n > -1) d.splice(n, 1);
        setDayMonth(d);
        setRerender(!rerender)
    }

    const selectFeedType = (selected) => {
        setSelectedFeedType(selected)
    }

    const setSelectedAccount = (user, index) => {
        props.setSelectedUser(user.id)
    }

    const addNewDigestTimeButton = <StandartButton width={34} icon={PlusIcon} stroke={'dark'} background="background" callback={addDigestTimes}/>
    
    const timesBlock = digestTimes.map((k,i) =>  
                                            <div className={s.timesBlockRow} key={i}>
                                                <InputTimeBlock readOnly={readOnly} values={k} name={i} callback={setNumber} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/> 
                                                {!readOnly ? <StandartButton width={34} icon={CloseIcon} stroke={'dark'} background="background" callback={removeDigestTime.bind(this, i)}/> : null}
                                                {(i === digestTimes.length - 1 && selectedPeriod === 'DAILY' && !readOnly) &&  addNewDigestTimeButton }
                                            </div>
                                    )
    const sendPeriod = selectedPeriod === 'WEEKLY'  ? <div className={s.ItemRow}>
                                                            <span className={s.labelText}>{props.getValue('DIGESTS_SENDING_DAYS')}</span>
                                                            <DigestWeekDays 
                                                                getValue={props.getValue} 
                                                                weekDays={props.weekDays} 
                                                                selectedWeekDays={selectedWeek} 
                                                                setSelectedWeekDays={setSelectedWeekDays} 
                                                                selectAllWeekdDays={selectAllWeekdDays}
                                                                readOnly={readOnly}
                                                            />
                                                        </div>
                                                    : (selectedPeriod === 'MONTHLY' ?   <div className={s.ItemRow}>
                                                                                            <span className={s.labelText}>{props.getValue('DIGESTS_SENDING_DATES')}</span>
                                                                                            <DigestMonthDays 
                                                                                                monthDays={dayMonth} 
                                                                                                addDayMonth={addDayMonth}
                                                                                                removeDayMonth={removeDayMonth}
                                                                                                getValue={props.getValue}
                                                                                                readOnly={readOnly}
                                                                                                 />
                                                                                        </div>
                                                                                    : null)

    return (
        <div>
            <div className={s.DigestItem}>
                {props.selectedRole === 'ROLE_ADMIN'  ? <div className={s.ItemRow}>
                    {!readOnly && props.showType !== 'edit' ? <SelectDropdown selected={selectedUser} items={props.activeUsers ? props.activeUsers : []} placeholder={props.getValue('ACCOUNTS')} labelText={props.getValue('ACCOUNTS')} setSelected={setSelectedAccount}/>
                                : <InputText readOnly={true} value={selectedUser} labelText={props.getValue('ACCOUNTS')} />}
                </div> : null }
                <div className={s.ItemRow}>
                    {!readOnly  ? <SelectDropdown selected={selectedTopic} items={props.topics ? props.topics : []} placeholder={props.getValue('TOPIC_NAME')} labelText={props.getValue('TOPIC')} setSelected={selectTopic}/>
                                : <InputText readOnly={true} value={selectedTopic} labelText={props.getValue('TOPIC_NAME')} />}
                </div>
                {(subtopics && subtopics.length) ? <div className={s.ItemRow}>
                    {!readOnly  ? <SelectDropdown selected={selectedSubtopicName} items={subtopics} placeholder={props.getValue('SUBTHEME_NAME')} labelText={props.getValue('SUBTHEME_NAME')} setSelected={selectSubtopic}/>
                                : <InputText readOnly={true} value={selectedSubtopicName} labelText={props.getValue('SUBTHEME_NAME')} />}
                </div> : null }
                <div className={s.ItemRow}>
                    {!readOnly  ? <SelectDropdown selected={selectedPeriodName} items={periods ? periods : []} placeholder={props.getValue('PERIODICITY')} labelText={props.getValue('PERIODICITY')} setSelected={selectPeriod}/>
                                : <InputText readOnly={true} value={selectedPeriodName} labelText={props.getValue('PERIODICITY')} />}
                </div>
                <div className={s.ItemRow}>
                    {!readOnly  ? <SelectDropdown selected={props.getValue(selectedFeedType.name)} items={reportFeedTypes} placeholder={props.getValue('FEED_TYPES')} labelText={props.getValue('FEED_TYPES')} setSelected={selectFeedType}/>
                                : <InputText readOnly={true} value={props.getValue(selectedFeedType.name)} labelText={props.getValue('FEED_TYPES')} />}
                </div>
                <div className={s.ItemRow} style={{display: 'flex', alignItems: 'flex-end'}}>
                    <div className={s.ItemCol}>
                        <span className={s.labelText}>{props.getValue('DIGESTS_SENDING_TIME')}</span>
                        { timesBlock }
                        {!digestTimes.length && addNewDigestTimeButton}
                    </div> 
                </div>
                    {sendPeriod}
                <div className={s.ItemRow}>
                    <span className={s.labelText}>{props.getValue('RECIPIENTS_EMAILS')}</span>
                    {!readOnly ? <EmailsList emails={emails} callback={setEmailsList}/>
                                : emails.length ? <InputText title={emails.join(",")} readOnly={true} value={emails.join(",")} /> : null}
                </div>
                <div className={s.ItemRow}>
                    {!readOnly  ? <SelectDropdown selected={selectedFilter} items={filters ? filters : []} placeholder={props.getValue('SAVED_FILTERS')} labelText={props.getValue('SAVED_FILTERS')} setSelected={selectFilter}/>
                                : <InputText readOnly={true} value={selectedFilter} labelText={props.getValue('SAVED_FILTERS')} />}
                </div>
                {!readOnly  ? <div className={s.ItemRow}>
                    <span className={s.labelText}>{props.getValue('CHARTS')}</span>
                    <InputCheckBox isChecked={withoutGraphics} name={props.getValue('DIGEST_WITHOUT_CHARTS')} title={props.getValue('DIGEST_WITHOUT_CHARTS')} callback={setWithoutGraphic}/>
                </div> : null}
                <div className={s.ItemRow}>
                    {!readOnly  ? <SelectDropdown selected={selectedFormatName} items={reportFormatTypes ? reportFormatTypes : []} placeholder={props.getValue('DIGEST_FORMAT')} labelText={props.getValue('DIGEST_FORMAT')} setSelected={setFormat}/>
                                : <InputText readOnly={true} value={selectedFormatName} labelText={props.getValue('DIGEST_FORMAT')} />}
                </div>
                {props.showType !== 'show' ? <div className={s.ItemRow+' '+s.buttons}>
                    <StandartButton disabled={!selectedTopic || !selectedFormat || (selectedPeriod === 'WEEKLY' && !selectedWeek.length || selectedPeriod === 'MONTHLY' && !dayMonth.length)} title={props.getValue('SAVE')} icon={SaveIcon} fill={'white'} callback={createDigest}/>
                    <StandartButton disabled={!digestTimes.length} title={props.getValue('CANCEL')} icon={CloseIcon} stroke={'white'} callback={props.closeItem}/>
                </div> : null}
            </div>
        </div>
    )
}

export default DigestItem;