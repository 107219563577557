import { header, getLanguage, checkFilters } from "./header";

const popular = header('popular-words/');

const setLanguage = () => {
    popular.defaults.headers.common['Accept-Language'] = getLanguage()
}

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    popular.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

export const PopularWordsService = {
    getPopularWords(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters), keywords: []}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return popular.post(`topics/${uuid}`, {...params}).then(response => {
            return response.data
        })
    },
    getPopularWordsFacets(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return popular.post(`topics/${uuid}/facets`, {...params}).then(response => {
            return response.data
        })
    },
    getPopularWordsBurst(uuid, since, till, grouping = "DAILY", filters = null, subtopics = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        let subtopicsURL = (subtopics && subtopics.length) ? `subtopics/${subtopics}/` : '';
        return popular.post(`topics/${uuid}/words`, {...params}).then(response => {
            return response.data
        })
    },
}