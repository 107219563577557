import { TopicService } from "../connection/topics-service";
//import { setKeywords, setStopwords, setResources, setDeleteOld, setShowDeleteOld, setProfiles } from "./keywords";
import { getMyTopics, setUpdatedData } from "./theme-settings";

import{ checkErrorMessage, setNotification, getErrorMessage } from "./errors";
import FilterList from "assets/filter-list/filter-list";
import { StorageService } from "connection/storage-service";
import { AccountService } from "connection/account-service";
import Admin from "pages/admin/admin";
import { AdminService } from "connection/admin-service";

const SET_TOPIC_ITEM = "SET-TOPIC-ITEM";
const SET_SELECTED_USER = "SET-SELECTED-USER";
const SET_OLD_SELECTED_USER = "SET-OLD-SELECTED-USER"
const SET_ERROR_IN_TOPIC_ITEM = "SET-ERROR-IN-TOPIC-ITEM";
const SET_SUBTOPICS = "SET-SUBTOPICS";
const SET_SUBTOPIC_ITEM = "SET-SUBTOPIC-ITEM";
const SET_SUBTOPICS_UPDATED = "SET-SUBTOPICS-UPDATED";
const SET_ITEM_BY_TYPE = "SET-ITEM-BY-TYPE";
const REMOVE_ITEM_BY_TYPE = "REMOVE-ITEM-BY-TYPE";
const SET_SUBTOPIC_ITEM_UPDATED = "SET-SUBTOPIC-ITEM-UPDATED";
const SET_SELECTED_ADVANCED = "SET-SELECTED-ADVANCED";
const SET_SELECTED_ALL_ADVANCED = "SET-SELECTED-ALL-ADVANCED";
const SET_SELECTED_CHANGED = "SET-SELECTED-CHANGED";
const SET_CHECK_PROFILE = "SET-CHECK-PROFILE";
const SET_CHECK_RESOURCE = "SET-CHECK-RESOURCE";

const SNG = [
    {id: '424313675', name: 'AZERBAIJAN'},
    {id: '249399277', name: 'ARMENIA'},
    {id: '249399300', name: 'BELARUS'},
    {id: '424311521', name: 'KAZAKHSTAN'},
    {id: '1700777271', name: 'KYRGYZSTAN'},
    {id: '424316417', name: 'MOLDOVA'},
    {id: '424314830', name: 'RUSSIA'},
    {id: '424314276', name: 'TAJIKISTAN'},
    {id: '249399440', name: 'TURKMENISTAN'},
    {id: '424317428', name: 'UZBEKISTAN'},
    {id: '1648342273', name: 'UKRAINE'},
]

const topicItemData = {
    id: null,
    name: null,
    relation: null,
    keywords: [],
    stopwords: [],
    profiles: [],
    resources: [],
    geoposition: null,
    parentId: null,
    mediaLimit: 0,
    socialNetworkLimit: 0,
    createdAt: null,
    updatedAt: null,
    errorInTopicItem: null,
    notificationInModal: null,
    subtopics: [],
    subtopicItem: [],
    isSubtopicsUpdated: false,
    topicItem: {
        topicSettings: {}
    },
    topicItemUpdated: false,
    massMediaType: FilterList.mediaType,
    massMediaScale: FilterList.mediaScale,
    sentimentList: FilterList.sentiment,
    domains: FilterList.domains,
    selectedAdvancedItems: {},
    selectedChanged: false,
    publicationType: FilterList.publicationType,
    languages: FilterList.languages,
    //socialInclude: true,
    //socialAllowUndefinedLocation: true,
    selectedUser: [],
    oldSelectedUser: [],
    checkProfileItem: null,
    checkResourceItem: null,
    sng: SNG
}

const getByType = (type) => {
    switch(type){
        case 'socnetProfiles':
            return 'url'
        default:
            return 'keyword'
    }
}

const TopicItem = (state = topicItemData, action) => {
    switch(action.type){
        case SET_TOPIC_ITEM:
            let data = action.data;
            return {...state, topicItem: data }//{...state, id: data.id, name: data.name, relation: data.relation, keywords: data.keywords, mediaLimit: data.mediaLimit, socialNetworkLimit: data.socialNetworkLimit}
        case SET_ITEM_BY_TYPE:
            let topic = {...state.topicItem}
            if(!topic[action.itemType])
                topic[action.itemType] = []
            topic[action.itemType].push({id: null, [getByType(action.itemType)]: action.value})
            return {...state, topicItem: topic, topicItemUpdated: true}
        case REMOVE_ITEM_BY_TYPE:
            let rTopic = {...state.topicItem}
            let n = rTopic[action.itemType].findIndex(k => k[getByType(action.itemType)] === action.value);
            rTopic[action.itemType].splice(n, 1)
            return {...state, topicItem: rTopic, topicItemUpdated: true}
        case SET_SUBTOPIC_ITEM_UPDATED:
            return {...state, topicItemUpdated: action.updated}
        case SET_ERROR_IN_TOPIC_ITEM:
            return {...state, errorInTopicItem: action.error}
        case SET_SUBTOPICS:
            return {...state, subtopics: {data: action.subtopics, topicId: action.topicId}}
        case SET_SUBTOPIC_ITEM:
            return {...state, subtopicItem: action.subtopicItem, topicId: action.topicId}
        case SET_SUBTOPICS_UPDATED:
            return {...state, isSubtopicsUpdated: action.isSubtopicsUpdated}
        case SET_SELECTED_ADVANCED:
            let selectedTopic = {...state.topicItem}
            let selectedAdvanced = selectedTopic.topicSettings;
                if(!selectedAdvanced[action.title])
                    selectedAdvanced[action.title] = [];

                let findedIndex = selectedAdvanced[action.title].indexOf(action.selected.toString());
                if(findedIndex !== -1){
                    selectedAdvanced[action.title].splice(findedIndex, 1);
                }
                else{
                    selectedAdvanced[action.title].push(action.selected.toString())
                }

            /*let selectedAdvanced = state.selectedAdvancedItems
            let selectedAdvancedItem = selectedAdvanced[action.title] ? selectedAdvanced[action.title] : {}
            if(selectedAdvancedItem[action.selected]){
                delete selectedAdvancedItem[action.selected]
            }
            else
                selectedAdvancedItem[action.selected] = action.selectedName
            selectedAdvanced[action.title] = selectedAdvancedItem ? selectedAdvancedItem : null
            if(!selectedAdvanced[action.title])
                delete selectedAdvanced[action.title]*/
            selectedTopic.topicSettings = selectedAdvanced
            return {...state, topicItem: selectedTopic, selectedChanged: true}
        case SET_SELECTED_ALL_ADVANCED:
            let selectedAdvancedAll = []
            if(action.isSelected){
                action.selected.forEach((item) => {
                    if(action.except.findIndex(k => k.id === item.id) === -1)
                        selectedAdvancedAll.push(item.id ? item.id.toString() : item.list)// = item.list ? item.list : item.name
                });
            }
            /*else
                selectedAdvancedAll = {}*/
            let selectedAllAdvanced = {...state.topicItem}
            if(action.selected)
                selectedAllAdvanced.topicSettings[action.title] = selectedAdvancedAll
            else
                delete selectedAllAdvanced.topicSettings[action.title]
            return {...state, topicItem: selectedAllAdvanced, selectedChanged: true}
        case SET_SELECTED_CHANGED:
            return {...state, selectedChanged: action.changed}
        case SET_CHECK_PROFILE:
            return {...state, checkProfileItem: action.checkProfileItem}
        case SET_CHECK_RESOURCE:
            return {...state, checkResourceItem: action.checkResourceItem}
        case SET_SELECTED_USER:
            let s = action.selectedUser;
            if(action.onlyIDs){
                s = s.map(k => k.id)
            }
            return {...state, selectedUser: s}
        case SET_OLD_SELECTED_USER:
            let o = [...action.oldSelectedUser];
            if(action.onlyIDs){
                o = o.map(k => k.id)
            }
            return {...state, oldSelectedUser: o}
        default:
            return {...state}
    }
}

export default TopicItem;

export const setCheckProfile = (checkProfileItem) => {
    return {
        type: SET_CHECK_PROFILE,
        checkProfileItem: checkProfileItem
    }
}

export const setCheckResource = (checkResourceItem) => {
    return {
        type: SET_CHECK_RESOURCE,
        checkResourceItem: checkResourceItem
    }
}

export const setTopicItem = (data) => {
    return {
        type: SET_TOPIC_ITEM,
        data: data
    }
}

export const setItemByType = (type, value) => {
    return {
        type: SET_ITEM_BY_TYPE,
        itemType: type,
        value: value
    }
}

export const removeItemByType = (type, value) => {
    return {
        type: REMOVE_ITEM_BY_TYPE,
        itemType: type,
        value: value
    }
}

export const setTopicItemUpdated = (updated) => {
    return {
        type: SET_SUBTOPIC_ITEM_UPDATED,
        updated: updated
    }
}

export const setSubtopics = (data, topicId) => {
    return {
        type: SET_SUBTOPICS,
        subtopics: data,
        topicId, topicId
    }
}

export const setSubtopicItem = (data) => {
    return {
        type: SET_SUBTOPIC_ITEM,
        subtopicItem: data
    }
}

export const clearTopicItem = (data) => {
        /*clearTopic(data);
        setKeywords([]);
        setStopwords([])*/
    return (dispatch) => {
        dispatch(clearTopic(data));
        //dispatch(setKeywords([]));
        //dispatch(setStopwords([]));
        //dispatch(setProfiles([]))
    }
}

export const clearTopic = (data) => {
    return {
        type: SET_TOPIC_ITEM,
        data: {id: null, name: null, relation: null, keywords: [], stopwords: [], socnetProfiles: [], resources: [], mediaLimit: null, socialNetworkLimit: null}
    }
}

export const setSubtopicsUpdated = (isSubtopicsUpdated) => {
    return {
        type: SET_SUBTOPICS_UPDATED,
        isSubtopicsUpdated: isSubtopicsUpdated
    }
}

export const setSelectedAdvanced = (title, selected, selectedList) => {
    return {
        type: SET_SELECTED_ADVANCED,
        title: title,
        selected: selected.selectedList,
        selectedName: selected.selectedName,
    }
}

export const setSelectedAdvancedAll = (title, isSelected, selected, except = {}) => {
    return {
        type: SET_SELECTED_ALL_ADVANCED,
        title: title,
        selected: selected,
        isSelected: isSelected,
        except: except
    }
}

export const setSelectedChanged = (changed) => {
    return {
        type: SET_SELECTED_CHANGED,
        changed: changed
    }
}

export const setSelectedUser = (selected, onlyIDs = false) => {
    return {
        type: SET_SELECTED_USER,
        selectedUser: selected,
        onlyIDs: onlyIDs
    }
}

export const setOldSelectedUser = (selected, onlyIDs = false) => {
    return {
        type: SET_OLD_SELECTED_USER,
        oldSelectedUser: selected,
        onlyIDs: onlyIDs
    }
}

export const getTopicData = (topicId) => {
    return (dispatch) => {
        TopicService.getTopic(topicId).then(data=>{
            dispatch(setTopicItem(data.data));
            /*let keywords = data.data.keywords.map((k)=>{return k.keyword});
            let stopwords = data.data.stopwords.map((k)=>{return k.keyword});
            let profiles = data.data.socnetProfiles.map((k)=>{return k.url});
            dispatch(setKeywords(keywords));
            dispatch(setStopwords(stopwords));
            dispatch(setProfiles(profiles))*/
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getSubtopics = (topicId, some = null) => {
    return (dispatch) => {
        TopicService.getSubtopics(topicId).then(data=>{
            dispatch(setSubtopics(data, topicId));
            dispatch(setSubtopicsUpdated(true))
            dispatch(setUpdatedData(false))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}



const notification = () => {
    
}

const addKeywords = async (topicId, keywords) => {
    
    const res = await TopicService.addKeywordToTopic(topicId, keywords).then(keys=>{
            return keys
        }).catch(error => {
            error
        })
    return res
}

export const createTopic = (topicTitle, keywords, stopwords, profiles, resources, limit, migration=true, profileMigration=true, deleteOld, parentId = null, usersIds = [], selectedRole = null) => (dispatch) =>{
    TopicService.createTopic(topicTitle, limit, parentId).then(data=>{
        if(selectedRole === 'ROLE_ADMIN') dispatch(topicVisibilitiesToAccount(usersIds, data.data.id))
        TopicService.addStopwordToTopic(data.data.id, stopwords).then( stops=>{    
            TopicService.addKeywordToTopic(data.data.id, keywords, deleteOld).then( keys=>{
            
                TopicService.addProfilesToTopic(data.data.id, profiles, profileMigration).then( profiles=>{     
                    //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}));
                    /*dispatch(setKeywords([]));
                    dispatch(setStopwords([]));
                    dispatch(setDeleteOld(false));
                    dispatch(setShowDeleteOld(false));*/
                    dispatch(getMyTopics())
                }).then( 
                    res => {
                        TopicService.addResources(data.data.id, resources, migration).then( resource => {
                            //dispatch(setResources([]))
                        })
                    }
                )
            }).catch(
                error => {
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}));
                }
            )
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}));
        })
        dispatch(setUpdatedData(true))
    }).catch(error => {
        dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}));
    })
}

export const createSubTopic = (parentUuid, topicTitle, keywords, stopwords, deleteOld) => (dispatch) =>{
    //return (dispatch) => {

        TopicService.createSubtopic(parentUuid, topicTitle).then(data=>{
            let text = "Подтема успешно создана.";
            //let ntext = addKeywords(data.data.id, keywords)
            TopicService.addKeywordToSubTopic(data.data.id, keywords, deleteOld).then(keys=>{
                TopicService.addStopwordToSubTopic(data.data.id, stopwords).then(stops=>{
                    text += " Стоп слова успешно прикреплены";
                    dispatch(setNotification({title: 'Подтема успешно создана', message: text}));
                    /*dispatch(setKeywords([]));
                    dispatch(setStopwords([]));
                    dispatch(setDeleteOld(false));
                    dispatch(setShowDeleteOld(false));*/
                    dispatch(getMyTopics())
                    //dispatch(getSubtopics(topicId, 'some text'))
                }).catch(error => {
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                })
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
            
            


        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    //}
}

export const updateTopicSettings = (topicId, settings) => {
    return (dispatch) => {
        TopicService.updateTopicSettings(topicId, settings).then( data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const editTopic = (topicId, topicTitle, limit, keywords, stopwords, profiles, resources, migration, profileMigration, deleteOld, settings) => {
    return (dispatch)=>{
        TopicService.editTopic(topicId, topicTitle, limit, settings).then(data=>{
            //dispatch(createByType(topicId, keywords, stopwords));
            TopicService.addStopwordToTopic(topicId, stopwords).then( data => {
                TopicService.addKeywordToTopic(topicId, keywords, deleteOld).then( data => {
                
                    TopicService.addProfilesToTopic(topicId, profiles, profileMigration).then( data => {
                        
                            dispatch(clearTopic(null));
                            /*dispatch(setKeywords([]));
                            dispatch(setStopwords([]));
                            
                            dispatch(setDeleteOld(false));
                            dispatch(setShowDeleteOld(false));*/
                            dispatch(getMyTopics())
                        

                    }).then(
                        data => {
                            if(resources){
                                TopicService.addResources(topicId, resources,  migration).then( data => {
                                    //dispatch(setResources([]));
                                })
                            }

                        }
                    ).catch(error => {
                        dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                    })

                }).catch(error => {
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                })
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
            
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
        
    }
}

export const addFavouriteToTopic = (topicId, profileUrl, resourceDomain, name) => {
    return (dispatch)=>{
        TopicService.addFavouriteToTopic(topicId, profileUrl, resourceDomain, name).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const RemoveFavouriteResourceProfile = (topicId, favouriteId) => {
    return (dispatch)=>{
        TopicService.deleteFavouriteFromTopic(topicId, favouriteId).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const profileToBlackList = (topicId, url, domain, name) => {
    return (dispatch)=>{
        TopicService.setProfileToBlackList(topicId, url, name, domain).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const profileFromBlackList = (topicId, blacklistId) => {
    return (dispatch)=>{
        TopicService.removeProfileFromBlackList(topicId, blacklistId).then(data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const checkProfile = (url) => {
    return (dispatch) => {
        StorageService.checkProfiles(url).then( data => {
            dispatch(setCheckProfile(data))
        }).catch( error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const checkResource = (url) => {
    return (dispatch) => {
        StorageService.checkResources(url).then( data => {
            dispatch(setCheckResource(data, data))
        }).catch( error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const topicVisibilitiesToAccount = (userIds, topicId) => {
    return (dispatch) => {
        //userIds.forEach((k, i) => {
            AdminService.attachAccountsToTopics(userIds, topicId).then( data => {

            })
        //});
        
    }
}

export const topicNotVisibleToAccount = (userIds, topicId) => {
    return (dispatch) => {
        userIds.forEach((k, i) => {
            AdminService.detachTopicFromAccount(k, topicId).then( data => {

            })
        });
        
    }
}

export const setErrorInTopicItem = (error) => {
    return {
        type: SET_ERROR_IN_TOPIC_ITEM,
        errorInTopicItem: error
    }
}

export const getTopicAccounts = (topicId) => {

    return (dispatch) => {
        AdminService.getTopicAccounts(topicId).then( data => {
            if(data && data.content) {
                dispatch(setSelectedUser(data.content, true))
                dispatch(setOldSelectedUser(data.content, true))
            }
        });
    }
}



