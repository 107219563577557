import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { modalShowToggle } from "../store/modal";
import ModalWindow from "./modal-window";
import { getValue } from "../store/languages";

let mapStateToProps = (state) => {
    return {
        showModal: state.Modal.showModal,
        modalName: state.Modal.name,
        callback: state.Modal.callback,
        title: state.Modal.title,
        body: state.Modal.body,
        buttons: state.Modal.buttons,
        icons: state.Modal.icons,
        language: state.languages.selectedLanguage,
        buttonName: state.Modal.buttonName,
        errorMessage: state.ErrorHandler.errorMessageInModal
    }
}

const ModalWindowContainer = (ModalWindow);

export default compose(connect(mapStateToProps, {modalShowToggle, getValue}))(ModalWindowContainer)