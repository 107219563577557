import TopicMenu from "./topic-menu";
import { getValue } from "store/languages";
import { setSelectedTopics, setSelectedSubtopics, setSelectedTopicsAll, setSelectedSubtopicsAll } from "store/topic-menu";
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";
import { connect } from "react-redux";

let mapStateToProps = (state, props) => {

    return {
        selectedTopics: state.TopicMenu.selectedTopics,
        selectedSubtopics: getSelectedSubTopicsUUID(state),//.TopicMenu.selectedSubtopics,
        selected: getSelectedTopicsUUID(state),
        isComparisonFetching: state.Comparison.isComparisonFetching,
        pageName: state.Filters.pageName,
    }
}

const TopicMenuContainer = 
    connect(mapStateToProps, {
                                getValue, 
                                setSelectedTopics,
                                setSelectedSubtopics,
                                setSelectedTopicsAll,
                                setSelectedSubtopicsAll
                            })(TopicMenu)

export default TopicMenuContainer