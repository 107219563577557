import AdminMigrationController from "./admin-migration-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getMigrationOperations, setMigrationId, setMigrationOperation, pauseMigration, resumeMigration, retryMigration } from "store/migration";
import { getSuperadminCompanies } from "store/superadmin";
import { getAllTopics } from "store/admin-topics";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { setItemsCount, setCurrentPage, setShowedItemsCount, setToDefaultPaginator } from "store/paginator";

let mapStateToProps = (state) => {

    return {
        migrations: state.Migration.migrations,
        migrationCount: state.Migration.count,
        migrationPage: state.Migration.page,
        controlButtons: state.Migration.controlButtons,
        companies: state.superadmin.companies,
        adminTopics: state.AdminTopics.topics,
        isSuperadmin: state.Account.isSuperadmin,
        adminTopics: state.AdminTopics.topics,
        tableHeader: state.Migration.tableHeader,
        selectedLanguage: state.languages.selectedLanguage,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
    }
}

const AdminMigrationContainer = connect(mapStateToProps, { 
                                                            getValue, 
                                                            getMigrationOperations, 
                                                            setMigrationId, 
                                                            getSuperadminCompanies, 
                                                            getAllTopics, 
                                                            setRightMenuComponent, 
                                                            setShowRightMenu, 
                                                            setRemoveRightMenuComponent, 
                                                            setItemsCount, 
                                                            setCurrentPage, 
                                                            setShowedItemsCount, 
                                                            setToDefaultPaginator,
                                                            setMigrationOperation,
                                                            pauseMigration, 
                                                            resumeMigration, 
                                                            retryMigration
                                                        })
                                                        (AdminMigrationController);

export default AdminMigrationContainer;
