import Menu from "assets/menu/menu";

const SET_COMPONENT = "SET-COMPONENT";

let settings = {
    component: null,
    menuItems: Menu.SettinsPages,
    adminMenu: Menu.SettinsAdminPages
}

const settingsPage = (state = settings, action) => {
    switch(action.type){
        case SET_COMPONENT:
            return {...state, component: action.component}
        default:
            return {...state}
    }
}

export default settingsPage;

export let setComponent = (component) => {
    return {
        type: SET_COMPONENT,
        component: component
    }
}