const SET_TOPICS_ITEMS = "SET-TOPICS-ITEMS";
const SET_SELECTED_TOPICS_ITEMS = "SET-SELECTED-TOPICS-ITEMS";
const SET_SELECTED_TOPICS_ITEM = "SET-SELECTED-TOPICS-ITEM";
const SEt_SELECTED_TOPICS_TYPE = "SEt-SELECTED-TOPICS-TYPE"

const topicsSelect = {
    topicsItems: null,
    selectedTopicsItems: {},
    selectedType: 'topic',
    topicUUId: null
}

export const TopicSelectItems = (state = topicsSelect, action) => {
    switch(action.type){
        case SET_TOPICS_ITEMS:
            return {...state, topicsItems: action.topicsItems}
        case SET_SELECTED_TOPICS_ITEMS:
            return {...state, selectedTopicsItems: action.selectedTopicsItems, selectedType: action.selectedType}
        case SET_SELECTED_TOPICS_ITEM:
            let selected = {...state.selectedTopicsItems}
            if(action.select){
                if(action.selectedType !== state.selectedType){
                    selected = {}
                }
                if(action.selectedType === 'subtopic' && action.topicUUId !== state.topicUUId)
                    selected = {}
                selected[action.selectedTopicsItem] = true
            }
            else{
                if(Object.keys(selected).length > 1) delete selected[action.selectedTopicsItem]
            }
            let topicUUId = action.topicUUId ? action.topicUUId : null
            return {...state, selectedTopicsItems: selected, selectedType: action.selectedType, topicUUId: topicUUId}
        case SEt_SELECTED_TOPICS_TYPE:
            return {...state, selectedType: action.selectedType}
        default:
            return {...state}
    }
}

export default TopicSelectItems;

export const setSelectedTopicsType = (selectedType) => {
    return {
        type: SEt_SELECTED_TOPICS_TYPE,
        selectedType: selectedType
    }
}

export const setTopicsItems = (topicsItems) => {
    return {
        type: SET_TOPICS_ITEMS,
        topicsItems: topicsItems
    }
}

export const setSelectedTopicsItem = (selectedTopicsItem, select, selectedType, topicUUId) => {
    return {
        type: SET_SELECTED_TOPICS_ITEM,
        selectedTopicsItem: selectedTopicsItem,
        select: select,
        selectedType: selectedType,
        topicUUId: topicUUId
    }
}

export const setSelectedTopicsItems = (selectedTopicsItems, selectedType) => {
    return {
        type: SET_SELECTED_TOPICS_ITEMS,
        selectedTopicsItems: selectedTopicsItems,
        selectedType: selectedType
    }
}