import React, {useState, useEffect} from "react";
import PostsContainer from "../../items/posts/posts-container";
import MultiPostPanel from "../../items/multi-post-panel/multi-post-panel";
import MultiPostPanelContainer from "items/multi-post-panel/multi-post-panel-container";
import FavouriteListContainer from "../../items/favourite_list/favourite-list-container";
import BlackListContainer from "../../items/black-list/black-list-container";

const MainPage = (props) => {
    const [selectedTopic, setSelectedTopic] = useState(props.selectedUUId);

 
    const [selected, setSelected] = useState(props.selectedTopic ? props.selectedTopic : 0);

    const multipost = <MultiPostPanelContainer 
                                    /*SentimentsOfList={props.SentimentsOfList} 
                                    selected={props.selectedPostItems}
                                    selectedAllList={props.selectedAllList}
                                    getValue={props.getValue} 
                                    modalShowToggle={props.modalShowToggle} 
                                    documentListTags={props.selectedPostTags} 
                                    tags={props.tags} 
                                    setRemoveItemFromTags={props.setRemoveItemFromTags} 
                                    setDocumentStatus={props.setDocumentStatus}
                                    updateSentimentByFilter={props.updateSentimentByFilter}
                                    selectedUUId={props.selectedUUId}
                                    selectedItems={props.selectedItems}*/
                                    page={props.type}
                        /> 

    useEffect(() => {
        setSelectedTopic(props.selectedUUId);
    })
    return (
        <div style={{height: props.type === 'Favourite' || props.type === 'BlackList' ? '63%' : '100%'}}>
            {props.type === 'Favourite' && <FavouriteListContainer /> }
            {props.type === 'BlackList' && <BlackListContainer /> }
            {(props.selectedPostItems && props.selectedPostItems.length >= 2 || props.selectedAllList) && multipost}
            {<PostsContainer topicsSelected={props.topicsSelected} type={props.type}/>}
        </div>
        )
}

export default MainPage;