import React, { useState, useEffect } from "react";
import PaginatorContainer from "items/paginator/paginator-container";
import TableItem from "items/table/table";
import s from "./admin-markers.module.scss"

const AdminMarkers = (props) => {
    
    const [selectedItem, setSelectedItem] = useState(props.selectedItem);

    useEffect(()=>{
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem])

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            props.setSelectedItem(item, props.data[item]);
        }
    }

    return <div className={s.AdminMarkers}>
        <PaginatorContainer />
        { <TableItem 
                                    data={[props.tableHeader, props.data]} 
                                    getValue={props.getValue} 
                                    //titleText={props.getValue('LIST_OF_USERS')}
                                    hiddenColumns={props.hiddenColumns}
                                    topics={props.topics}
                                    selectItem={selectTableITem}
                                    selectedItem={selectedItem}
                                    //controllerButtons={props.controllerButtons}
                                    //controlButtons={props.controlButtons}
                                    tableColWidth={{index: 6, width: 40}}
                                    height={415}
                                    weekDays={props.weekDays}/>}
    </div>
}

export default AdminMarkers;