import EditAccountController from "./edit-account-controller";
import { connect } from "react-redux";
//import { getAccountTopics } from "store/admin";
import { getAccountTopics, getCompanyTopics} from "store/superadmin"
import { getValue } from 'store/languages';
import { getAllTopics} from "store/admin-topics";
import { getAccountTopicsAll, getAccountTopicsName } from "selectors/admin-topics-selector";
import { getCompanyTopicsSelector, getCompanyTopics as getCTopics, getAllAdminTopics, getAccountsTable, getActiveCompanies } from "selectors/superadmin-selector";
import { attachTopicsToAccount, getSuperadminCompanies, attachAccountToCompany  } from "store/superadmin"
import { getAllTopicsName } from "selectors/theme-selector";

let mapStateToProps = (state) => {
    return {
        adminTopics: getAllAdminTopics(state),
        topics: getCTopics(state),
        allTopics: getAllTopicsName(state),
        accountTopics: getAccountTopicsName(state),
        companyId: state.superadmin.companyId,
        isSuperadmin: state.Account.isSuperadmin,
        accounts: getAccountsTable(state),
        //companies: state.superadmin.companies,
        //activeCompanies: getActiveCompanies(state),
        //allComapnies: state.superadmin.companies
    }
}

const EditAccountContainer = 
connect(mapStateToProps, {
        getValue,
        getAccountTopics,
        getCompanyTopics,
        attachTopicsToAccount,
        getSuperadminCompanies,
        getAllTopics,
        attachAccountToCompany
    })(EditAccountController)

export default EditAccountContainer;