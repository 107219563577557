import React, {useState, useEffect} from "react";
import { InputNumber, InputText, SelectDropdown, InputCheckBox } from "commons/input-controls";
import Keywords from 'items/keywords/keywords'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { StandartButton } from "commons/form-controls";
import List from "pages/settings-page/list/list";
import s from '../topic-item.module.scss';


const GeneralTopicSettings = (props) => {

    const getUserName = (userid, users, type = 'name') => {
        if(users && users.length){
            let user = users.findIndex(k => k.id === userid);
            return user !== -1 ? users[user][type] : null
        }
        else return null

    }

    const [users, setUsers] = useState(props.activeUsers ? props.activeUsers : []);
    const [topicId, setTopicId] = useState(props.topicItem && props.topicItem.id ? props.topicItem.id : null);
    const [topicTitle, setTopicTitle] = useState(props.topicName ? props.topicName : '');
    const [limit, setlimit] = useState(props.topicItem && props.topicItem.limit ? props.topicItem.limit : 5000);
    /*const [massMedia, setMassMedia] = useState(props.topicItem && props.topicItem.mediaLimit ? props.topicItem.mediaLimit : 2500);
    const [socialNetwork, setSocialNetwork] = useState(props.topicItem && props.topicItem.socialNetworkLimit ? props.topicItem.socialNetworkLimit : 2500);*/
    const [deleteOld, setDeleteOld] = useState(false);
    const [showDeleteOld, setShowDeleteOld] = useState(false)
    const [keywords, setKeywords] = useState(props.topicItem && props.topicItem.keywords ? props.topicItem.keywords : [])
    const [stopwords, setStopwords] = useState(props.topicItem && props.topicItem.stopwords ? props.topicItem.stopwords : [])
    const [socnetProfiles, setSocnetProfiles] = useState(props.topicItem && props.topicItem.socnetProfiles ? props.topicItem.socnetProfiles : []);
    const [resources, setResources] = useState(props.topicItem && props.topicItem.resources ? props.topicItem.resources : [])
    const [showOnly, setShowOnly] = useState(props.isEdit === 'show' ? true : false);
    const [parentTopicId, setParentTopicId] = useState(props.parentTopicId ? props.parentTopicId : null);
    const [migration, setMigration] = useState(true);
    const [profileMigration, setProfileMigration] = useState(true);
    const [selectedUser, setSelectedUser] = useState(props.selectedUser);
    //const [checkProfileItem, setCheckProfileItem] = useState(getProfileMessage(props.checkProfileItem, 'socnetProfiles'));
    //const [checkResourceItem, setCheckResourceItem] = useState(getProfileMessage(props.checkResourceItem, 'resources'));
    const [rerender, setRerender] = useState(false);
    const [isChangedUsers, setIsChangedUsers] = useState(false);

    useEffect(() => {
        setTopicId(props.topicItem && props.topicItem.id ? props.topicItem.id : null);
        setTopicTitle(props.topicName ? props.topicName : '')
        setlimit(props.topicItem && props.topicItem.limit ? props.topicItem.limit : 5000);
        /*setMassMedia(props.topicItem && props.topicItem.mediaLimit ? props.topicItem.mediaLimit : 2500);
        setSocialNetwork(props.topicItem && props.topicItem.socialNetworkLimit ? props.topicItem.socialNetworkLimit : 2500);*/
        setDeleteOld(false);
        setKeywords(props.topicItem && props.topicItem.keywords ? props.topicItem.keywords : [])
        setStopwords(props.topicItem && props.topicItem.stopwords ? props.topicItem.stopwords : [])
        setSocnetProfiles(props.topicItem && props.topicItem.socnetProfiles ? props.topicItem.socnetProfiles : []);
        setResources(props.topicItem && props.topicItem.resources ? props.topicItem.resources : [])
        setParentTopicId(props.parentTopicId ? props.parentTopicId : null)
        setShowOnly(props.isEdit === 'show' ? true : false);
        setShowDeleteOld(false)
        //setCheckProfileItem(getProfileMessage(props.checkProfileItem, 'socnetProfiles'));
        //setCheckResourceItem(getProfileMessage(props.checkResourceItem, 'resources'));
        setUsers(props.activeUsers ? props.activeUsers : []);
        setSelectedUser(props.selectedUser);
        setRerender(!rerender);
    }, [props.topicItem, props.topicName, props.isEdit, props.parentTopicId, props.selectedUser, props.activeUsers])

    const createTopic = () => {

        props.createTopic(topicTitle, keywords, stopwords, socnetProfiles, resources, limit, migration, profileMigration, deleteOld, props.isEdit === 'createSubtopic' && parentTopicId ? parentTopicId : null, selectedUser);
        setTopicTitle('');
        setlimit(5000);
        setDeleteOld(false);
        setKeywords([]);
        setStopwords([]);
        setSocnetProfiles([]);
        setResources([])
        setRerender(!rerender)
    }

    const getToDetachAccounts = () => {
        let detached = props.oldSelectedUser.filter(k => selectedUser.indexOf(k) === -1)
        return detached
    }

    const getAttached = () => {
        let attached = selectedUser.filter(k => props.oldSelectedUser.indexOf(k) === -1)

        return attached
    }
//props.editTopic(props.topicId, topicTitle, massMedia, socialNetwork, props.keywords, props.stopwords, props.profiles, props.deleteOld);
    const editTopic = (topicId) => {
        if(props.selectedRole === 'ROLE_ADMIN' && isChangedUsers){
            props.attachAccountsToTopics(selectedUser, topicId);
        }

        props.editTopic(topicId, topicTitle, limit, keywords, stopwords, socnetProfiles, resources, migration, profileMigration, deleteOld)
        setDeleteOld(false)
    }

    const addMassMedia = (value) => {
        setMassMedia(value)
        //props.addMassMedia(value);
    }

    const addLimit = (limit) => {
        setlimit(limit)
    }

    const addSocialNetwork = (value) => {
        setSocialNetwork(value)
        //props.addSocialNetwork(value)
    }

    const checkDeleteOld = () => {
        setDeleteOld(!deleteOld)
    }

    const setIsMigration = isMigration => {
        setMigration(isMigration)
    }

    const setProfileIsMigration = isMigration => {
        setProfileMigration(isMigration)
    }

    const getByType = (type) => {
        switch(type){
            case 'socnetProfiles':
                return 'url'
            case 'resources':
                return 'domain'
            default:
                return 'keyword'
        }
    }

    const getObjectByType = (type) => {
        switch(type){
            case 'keywords':
                return keywords
            case 'stopwords':
                return stopwords
            case 'socnetProfiles':
                return socnetProfiles
            case 'resources':
                return resources
        }
    }

    const setItemByType = (type, value) => {

        let keyWords = getObjectByType(type)
        let key = keyWords.findIndex(k => k[getByType(type)] === value);
        if(key === -1){
            keyWords.push({id: null, [getByType(type)]: value});
            switch(type){
                case 'keywords':
                    let ks = [...keyWords]

                    setKeywords(ks);
                    //setRerender(!rerender)
                    break;
                case 'stopwords':
                    let st = [...keyWords]
                    setStopwords(st);
                    break;
                case 'resources':
                    setResources(keyWords);
                    break;
                case 'socnetProfiles':
                    setSocnetProfiles(keyWords);
                    break;
            }
            setRerender(!rerender);
        }
    }

    const openKeywords = (type) => {
        const items = getObjectByType(type)
        props.modalShowToggle("Keywords", true, closeModal.bind(this, this), props.getValue(type.toUpperCase()), 
            <Keywords showOnly={showOnly} BigWindow={true} type={type} setItemByType={setItemByType} removeItemByType={removeItemByType} stopwords={items} keywords={items} getValue={props.getValue} reg={true} />);
    }

    const removeItemByType = (type, value) => {

        let keyWords = getObjectByType(type)
        let key = keyWords.findIndex(k => k[getByType(type)] === value);
        if(key !== -1){
            
            switch(type){
                case 'keywords':
 
                    let topicKeywords = props.topic && props.topic.keywords ? props.topic.keywords.split(',') : []
                    if(topicKeywords && topicKeywords.findIndex(f => f.trim() === value) !== -1){
                        setShowDeleteOld(true)
                    }
                    keyWords.splice(key, 1)
                    let ks = [...keyWords]
                    setKeywords(ks);
                    setRerender(!rerender)
                    break;
                case 'stopwords':
                    keyWords.splice(key, 1)
                    let ts = [...keyWords]
                    setStopwords(ts);
                    break;
                case 'socnetProfiles':
                    setSocnetProfiles(keyWords);
                    break;
                case 'recources':
                    setResources(keyWords);
                    break;
            }
            
            setRerender(!rerender);
        }
    }

    const closeModal = () => {
        props.modalShowToggle(false)
    }

    const showInstruction = () => {
        props.modalShowToggle("KeywordsInstruction", true,  closeModal, props.getValue('KEYWORDS')+" / "+props.getValue('STOPWORDS'), "Действие данной кнопки находтся в стадии разработки")
    }

    const setSelectedAccount = (usersIds) => {
        setIsChangedUsers(true);
        props.setSelectedUser(usersIds);
    }

    return (
        <div className={s.General}>
            { props.selectedRole === 'ROLE_ADMIN' && users && !props.isSubtopic 
            //&& props.topicItem.status === "ACTIVE"
            
                ?   <div className="formRow">
                        <div className="labelText">{props.getValue('ACCOUNTS')}</div>
                        <List list={users} setSelected={setSelectedAccount} selected={selectedUser} showOnly={showOnly}/>
                    </div>
                : null
            }
            {/*<div>
                <div className="formCol">
                    <div className="labelText">{props.getValue('SELECTION_LIMIT')}</div>
                    <div className="formRow spaceBetween">
                        <div className={s.ItemRow}>
                            <span className={s.InputTitle}>{props.getValue('MASS_MEDIA')}:</span><InputNumber readOnly={showOnly} callback={addMassMedia} value={massMedia} min={0}/>
                        </div>
                    </div>
                </div>
                <div className="formRow spaceBetween">
                    <div className={s.ItemRow}>
                        <span className={s.InputTitle}>{props.getValue('SOCIAL_NETWORKS')}:</span><InputNumber readOnly={showOnly} callback={addSocialNetwork} value={socialNetwork} min={0}/>
                    </div> 
                </div>
    </div> */}


            {/*<div className="formRow">
                <div className="labelText">{props.getValue('CONNECTION_WITH_CABINET')}</div>
                <SelectDropdown items={['one', 'two', 'three']} placeholder={props.getValue('THOOSE_NAME_OF_THE_GROUP')} />
            </div>*/}
            {!props.isSubtopic ? <div className="formRow">
                <div className="labelText">
                    {props.getValue('SELECTION_LIMIT')}
                </div>
                {<InputNumber readOnly={showOnly} callback={addLimit} value={limit} min={0}/>}
            </div> : null}
            <div className="formRow">
                <div className="labelText">
                    {props.getValue('KEY_WORDS_2')} {props.isEdit !== 'show' && <span>•<span className={s.blueText+" "+s.showInstruction} onClick={showInstruction}> {props.getValue('KEY_WORDS_CREATION')}</span></span>}
                </div>
                {<Keywords showOnly={showOnly} type={'keywords'} setItemByType={setItemByType} removeItemByType={removeItemByType} keywords={keywords} getValue={props.getValue} reg={true} openKeywords={openKeywords.bind(this, 'keywords')}/>}
            </div>
            { showDeleteOld && <div className="formRow">
                <div className="labelText">
                    {props.getValue('CLEAR_DATA_CONTENTS_DELETED_KEYWORDS')}
                    <InputCheckBox isChecked={deleteOld} name="signin" title={props.getValue('CLEAR_DATA_CONTENTS_DELETED_KEYWORDS')} callback={checkDeleteOld}/>
                </div>

            </div>}
            <div className="formRow">
                <div className="labelText">
                    {props.getValue('STOP_WORDS')} {props.isEdit !== 'show' && <span>•<span className={s.blueText+" "+s.showInstruction} onClick={showInstruction}>{props.getValue('KEY_WORDS_CREATION')}</span></span>}
                </div>
                <Keywords showOnly={showOnly} type={'stopwords'} setItemByType={setItemByType} removeItemByType={removeItemByType} stopwords={stopwords} getValue={props.getValue} reg={true} openKeywords={openKeywords.bind(this, 'stopwords')}/>
            </div>
            <div className="formRow">
                <div className="labelText">
                    {props.getValue('LINKS_TO_PROFILES')} {props.isEdit === 'edit'}
                </div>
                <Keywords title={'example.com/value'} showOnly={showOnly} type={'socnetProfiles'} setItemByType={setItemByType} removeItemByType={removeItemByType} getValue={props.getValue} profiles={socnetProfiles} reg={true}/>
            </div>
            {props.isEdit !== 'show' ? <div className="formRow">
                <div className="labelText">
                    {props.getValue('ARCHIVE_COLLECTION_BY_PRFILES')} {props.isEdit === 'edit'}
                </div>
                <InputCheckBox isChecked={profileMigration} name="profileMigration" title={props.getValue('CLEAR_DATA_CONTENTS_DELETED_KEYWORDS')} callback={setProfileIsMigration}/>
            </div> : null}
            <div className="formRow">
                <div className="labelText">
                    {props.getValue('LINKS_TO_SOURCES')} {props.isEdit === 'edit'}
                </div>
                <Keywords showOnly={showOnly} type={'resources'} setItemByType={setItemByType} removeItemByType={removeItemByType} getValue={props.getValue} resources={resources} domains={props.domains} reg={true}/>
            </div>
            {props.isEdit !== 'show' ? <div className="formRow">
                <div className="labelText">
                    {props.getValue('ARCHIVE_COLLECTION_BY_RESOURCES')} {props.isEdit === 'edit'}
                </div>
                <InputCheckBox isChecked={migration} name="migration" title={props.getValue('CLEAR_DATA_CONTENTS_DELETED_KEYWORDS')} callback={setIsMigration}/>
            </div> : null}
            {props.isEdit !== 'show' ? <div className="formRow">
                <StandartButton disabled={!topicTitle || topicTitle === ''} callback={props.isEdit === 'edit' ? editTopic.bind(this, topicId) : createTopic} title={props.getValue('SAVE')} icon={SaveIcon} fill={'white'}/>
            </div> : null}
        </div>
    )
}

export default GeneralTopicSettings