import { DigestService } from "connection/digest-service";
import { DigestAdminService } from "connection/digest-admin-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import FilterList from "assets/filter-list/filter-list";

const SET_DIGEST_DATA = "SET-DIGEST-DATA";
const SET_DIGEST_UPDATED = "SET-DIGEST-UPDATED";
const SET_SELECTED_DIGEST = "SET-SELECTED-DIGEST";
const SET_DIGEST_PERIOD = "SET-DIGEST-PERIOD";
const SET_SELECTED_DIGEST_TOPIC = "SET-SELECTED-DIGEST-TOPIC";
const SET_SELECTED_DIGEST_SUBTOPIC = "SET-SELECTED-DIGEST-SUBTOPIC"
const SET_UPDATE_DIGEST_STATUS = "SET-DIGEST-UPDATE-STATUS";
const SET_SELECTED_USER = "SET-SELECTED-USER";

let digestData = {
    data: [],
    //totalElements: null,
    selectedDigest: null,
    selectedDigestTopic: null,
    selectedDigestTopicName: null,
    selectedDigestTopicSubtopics: null,
    selectedDigestTopicUIID: null,
    selectedDigestSubtopic: null,
    selectedDigestSubtopicUUID: null,
    selectedDigestSubtopicName: null,
    selectedUser: null,
    selectedUserId: null,
    isUpdated: false,
    tableHeader: ['#', 'TOPIC_NAME', 'SUBTHEME_NAME', 'PERIODICITY', 'EMAIL', 'SAVED_FILTER_SETTINGS', 'SENDING_TIME', 'FORMAT'],
    tableAdminHeader: ['#', 'ACCOUNT', 'TOPIC_NAME', 'SUBTHEME_NAME', 'PERIODICITY', 'EMAIL', 'SAVED_FILTER_SETTINGS', 'SENDING_TIME', 'FORMAT'],
    controlButtons: ["edit", "show", "copy", "delete"],
    digestPeriod: ['MONTHLY', 'WEEKLY', 'DAILY'],
    reportFeedTypes: FilterList.reportFeedTypes,
    weekDays: ['MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'],
    countOfDigests: [1, 3, 5, 10, 20]
}

const digest = (state = digestData, action) => {
    switch(action.type){
        case SET_DIGEST_DATA:
            let data = action.data ? action.data : [];
            return {...state, data: data}
        case SET_DIGEST_UPDATED:
            return {...state, isUpdated: action.isUpdated}
        case SET_SELECTED_DIGEST:
            return {...state, selectedDigest: action.selectedDigest}
        case SET_SELECTED_DIGEST_TOPIC:
            return {...state, selectedDigestTopic: action.selectedDigestTopic, selectedDigestTopicName: action.selectedDigestTopicName, selectedDigestTopicUIID: action.selectedDigestTopicUIID, selectedDigestTopicSubtopics: action.selectedDigestTopicSubtopics, selectedDigestSubtopic: null, selectedDigestSubtopicName: null, selectedDigestSubtopicUUID: null}
        case SET_SELECTED_DIGEST_SUBTOPIC:
            return {...state, selectedDigestSubtopic: action.selectedDigestSubtopic, selectedDigestSubtopicName: action.selectedDigestSubtopicName, selectedDigestSubtopicUUID: action.selectedDigestSubtopicUUID}
        case SET_UPDATE_DIGEST_STATUS:
            let digestList = {}
            digestList.content = [...state.data.content]
            
            let digestIndex = digestList.content.findIndex(k => k.id === action.digest.id)
            if(digestIndex !== -1){
                digestList.content[digestIndex] = action.digest
            }
            return {...state, data: digestList, isUpdated: true}
        case SET_SELECTED_USER:
            return {...state, selectedUserId: action.selectedUserId}
        default:
            return {...state}
    }
}

export default digest;

const setDigestData = (data) => {
    return {
        type: SET_DIGEST_DATA,
        data: data
    }
}

export const setSelectedDigest = (selectedDigest) => {
    return {
        type: SET_SELECTED_DIGEST,
        selectedDigest: selectedDigest
    }
}

export const setSelectedDigestTopic = (topicId, topicName, topicUUID, subtopics) => {
    return {
        type: SET_SELECTED_DIGEST_TOPIC,
        selectedDigestTopic: topicId,
        selectedDigestTopicName: topicName,
        selectedDigestTopicUIID: topicUUID,
        selectedDigestTopicSubtopics: subtopics
    }
}

export const setSelectedDigestSubtopic = (id, name, uuid) => {
    return {
        type: SET_SELECTED_DIGEST_SUBTOPIC,
        selectedDigestSubtopic: id,
        selectedDigestSubtopicName: name,
        selectedDigestSubtopicUUID: uuid
    }
}

export const setDigestPeriod = (digestPeriod) => {
    return {
        type: SET_DIGEST_PERIOD,
        digestPeriod: digestPeriod
    }
}

export const setDigestDataUpdated = (isUpdated) => {
    return {
        type: SET_DIGEST_UPDATED,
        isUpdated: isUpdated
    }
}

export const setUpdateDigestStatus = (digest) => {
    return {
        type: SET_UPDATE_DIGEST_STATUS,
        digest: digest
    }
}

export const setSelectedUser = (selectedUserId) => {
    return {
        type: SET_SELECTED_USER,
        selectedUserId: selectedUserId
    }
}

export const createDigest = (role = 'ROLE_USER', uuid, userId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(createDigestUserSettings(uuid, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType));
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(createDigestAdminSettings(uuid, userId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType))
        }
    }
}

export const createDigestAdminSettings = (uuid, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestAdminService.createDigestSettings(uuid, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData('ROLE_ADMIN'))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const createDigestUserSettings = (uuid, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestService.createDigestSettings(uuid, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData('ROLE_USER'))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const createDigestSubtopic = (role = 'ROLE_USER', userId, uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {

    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(createUserDigestSubtopic(uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType));
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(createAdminDigestSubtopic(userId, uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType))
        }
    }
}

export const createUserDigestSubtopic = (uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestService.createDigestSubtopicSettings(uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData("ROLE_USER"))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const createAdminDigestSubtopic = (userId, uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestAdminService.createDigestSubtopicSettings(userId, uuid, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData("ROLE_ADMIN"))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getDigestData = (role = 'ROLE_USER') => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(getUserDigestSettings());
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(getCompanyDigestSettings())
        }
    }
}

export const getUserDigestSettings = () => {
    return (dispatch) => {
        DigestService.getUserDigestSettings().then(data=>{
            //let content = data.data.content.map((c, i)=>{return [c.id, c.name, c.keywords, c.createdAt, c.updatedAt]})
            dispatch(setDigestData(data));
            dispatch(setDigestDataUpdated(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getCompanyDigestSettings = () => {
    return (dispatch) => {
        DigestAdminService.getCompanyDigestSettings().then(data=>{
            //let content = data.data.content.map((c, i)=>{return [c.id, c.name, c.keywords, c.createdAt, c.updatedAt]})
            dispatch(setDigestData(data));
            dispatch(setDigestDataUpdated(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const deleteAndUpdate = () => {
    return (dispatch) => {
        
    }
}

export const deleteDigest = (role = 'ROLE_USER', id) => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(deleteUserDigest(id));
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(deleteAminDigest(id))
        }
    }
}

export const deleteUserDigest = (id) => {
    return (dispatch) => {
        DigestService.deleteDigestSettings(id).then(data=>{
            dispatch(getDigestData('ROLE_USER'))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const deleteAminDigest = (id) => {
    return (dispatch) => {
        DigestAdminService.deleteDigestSettings(id).then(data=>{
            dispatch(getDigestData('ROLE_ADMIN'))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const updateDigest = (role = 'ROLE_USER', userId, id, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(updateUserDigest(id, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType))
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(updateAdminDigest(id, userId, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType))
        }
    }
}

export const updateAdminDigest = (id, userId, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestAdminService.updateDigestSettings(id, userId, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData('ROLE_ADMIN'));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const updateUserDigest = (id, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestService.updateDigestDifferentTopic(id, topicUUId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData('ROLE_USER'));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const updateDigestSubtopic = (role = 'ROLE_USER', userId, id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(updateUserDigestSubtopic(id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType))
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(updateAdminDigestSubtopic(userId, id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType))
        }
    }
}

export const updateUserDigestSubtopic = (id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestService.updateDigestSubtopicSettings(id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData('ROLE_USER'));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const updateAdminDigestSubtopic = (userId, id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType) => {
    return (dispatch) => {
        DigestAdminService.updateDigestSubtopicSettings(id, topicId, subtopicId, type, emails, filterId, digestSchedule, includeAnalytics, reportFeedType).then(data=>{
            dispatch(getDigestData('ROLE_ADMIN'));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const activateDigest = (role = 'ROLE_USER', id) => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(activateUserDigest(id))
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(activateAdminDigest(id))
        }
    }
    
}

export const activateUserDigest = (id) => {
    return (dispatch) => {
        DigestService.activateDigestSettings(id).then(data=>{
            dispatch(setUpdateDigestStatus(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const activateAdminDigest = (id) => {
    return (dispatch) => {
        DigestAdminService.activateDigestSettings(id).then(data=>{
            dispatch(setUpdateDigestStatus(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const pauseDigest = (role = 'ROLE_USER', id) => {
    return (dispatch) => {
        if(role === 'ROLE_USER'){
            dispatch(pauseUserDigest(id))
        }
        else if(role === 'ROLE_ADMIN'){
            dispatch(pauseAdminDigest(id))
        }
    }
}

export const pauseUserDigest = (id) => {
    return (dispatch) => {
        DigestService.pauseDigestSettings(id).then(data=>{
            dispatch(setUpdateDigestStatus(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const pauseAdminDigest = (id) => {
    return (dispatch) => {
        DigestAdminService.pauseDigestSettings(id).then(data=>{
            dispatch(setUpdateDigestStatus(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}
