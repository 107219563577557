import React, {useState, useEffect} from "react";
import s from "./company.module.scss";

const Company = (props) => {

    const [companyName, setCompanyName] = useState(props.company && props.company.name ? props.company.name : null);

    useEffect(() => {
        setCompanyName(props.company && props.company.name ? props.company.name : null)
    })

    return (
        <div className={s.Company}>
            <span>{companyName}</span>
        </div>
    )
}

export default Company;