import { useState, useEffect, useRef } from 'react';
import { header, getLanguage } from "connection/header";
import axios from "axios";

const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})

const setLanguage = () => {
    geoplace.defaults.headers.common['Accept-Language'] = getLanguage()
}

export default function useGeolocation(locationId) {
    const [geolocation, setGeolocation] = useState("-");

    useEffect(() => {
        if(locationId){
            setLanguage();
            geoplace.get(`/geoplaces/names/${locationId}`).then((response) => {
                setGeolocation(response.data)
            })
        }

    }, [getLanguage()]);

    return {geolocation, setGeolocation };
}