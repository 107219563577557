import { createSelector } from "reselect";

const getPosts = (state) => {
    return state.storage.storageData
}

export const getPostsSignatures = createSelector(getPosts, (postsList) => {
    let posts = postsList.docs;
    let signatures = []
    if(posts && posts.length){
        signatures = posts.map(post => post.signature);
    }
    return signatures
});