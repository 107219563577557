import DigestController from "./digest-controller";
import { connect } from "react-redux";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getTopicName, getSubtopicsDropdownFromState} from "selectors/theme-selector";
import { getDigestTableFromState, getDigestTableHeader } from "selectors/digest-selector";
import { getValue } from "store/languages";
import { getDigestData, createDigest, createDigestSubtopic, updateDigest, deleteDigest, setSelectedDigest, activateDigest, pauseDigest, setSelectedDigestTopic, setSelectedDigestSubtopic, setDigestDataUpdated, setSelectedUser } from "store/digest";
import { getSubtopics, setSubtopicsUpdated, setSubtopicItem, setSubtopics } from "store/topic-item";
import { getMyTopics } from "store/theme-settings";
import { getAllFilters } from "store/filters";
import { setItemsCount, setCurrentPage } from "store/paginator";
import { getAdminUsers } from "selectors/admin-topics-selector";

let mapStateToProps = (state) => {
    return {
        tableItems: getDigestTableFromState(state),
        controlButtons: state.digest.controlButtons,
        tableHeader: getDigestTableHeader(state),
        digestPeriod: state.digest.digestPeriod,
        //totalElements: state.digest.totalElements,
        weekDays: state.digest.weekDays,
        countOfDigests: state.digest.countOfDigests,
        selectedDigestTopic: state.digest.selectedDigestTopic,
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        topics: getTopicName(state),
        subtopics: getSubtopicsDropdownFromState(state),
        email: state.Account.email,
        reportFormatTypes: state.report.reportFormatTypes,
        myFilters: state.Filters.myFilters,
        isUpdated: state.digest.isUpdated,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        reportFeedTypes: state.digest.reportFeedTypes,
        selectedRole: state.AdminMode.selectedRole,
        users: getAdminUsers(state),
    }
}

export default connect(mapStateToProps, {getValue,
                                        getMyTopics, 
                                        getAllFilters, 
                                        setShowRightMenu, 
                                        getDigestData, 
                                        createDigest,
                                        createDigestSubtopic,
                                        updateDigest, 
                                        deleteDigest, 
                                        setRightMenuComponent, 
                                        setRemoveRightMenuComponent, 
                                        setSelectedDigest, 
                                        activateDigest,
                                        getSubtopics,
                                        setSelectedDigestTopic,
                                        setSelectedDigestSubtopic,
                                        setSubtopics,
                                        setDigestDataUpdated,
                                        setItemsCount,
                                        setCurrentPage,
                                        setSelectedUser,
                                        pauseDigest })(DigestController)