import { Formik, Form, Field } from "formik";
import { StandartButton, InputField } from "../../commons/form-controls";
import { companyValidateSchema } from "../../utils/validators.js"
import { InputNumber } from "../../commons/input-controls.js"

const CompanyEditor = (props) => {

    const onSubmitForm = (formData) => { 
        if(formData){
            let company_bin = formData.company_bin !== '' ? formData.company_bin : null;
            let company_name = formData.company_name !== '' ? formData.company_name : null;
            let company_max_num_topics = formData.company_max_num_topics !== '' ? formData.company_max_num_topics : null;
        }
    }

    const saveCompany = () => {
        
    }

    return (
        <div>
            <Formik
                initialValues={
                    {
                        company_bin: props.company_bin,
                        company_name: props.company_name,
                        company_max_num_topics: props.company_max_num_topics
                    }
                }
                validationSchema={companyValidateSchema}
                onSubmit={
                    (
                        onSubmitForm, 
                        (
                            values, {resetForm}
                        ) => {
                            onSubmitForm(values), resetForm({values: ''})
                        }          
                    )
                }
            >
                {
                    ({
                        values,
                        isValid, 
                        errors, 
                        touched, 
                        handleChange, 
                        handleSubmit
                    }) => (
                        <Form
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                        >
                            <div className="formRow">
                                <div>Company bin</div>
                                <InputField 
                                    name="company_bin" 
                                    type="text" 
                                    label="Cool bin"
                                    value={values.company_bin === undefined ? '' : values.company_bin}
                                />
                            </div>
                            <div className="formRow">
                                <div>Company name</div>
                                <InputField 
                                    name="company_name" 
                                    type="text"
                                    label="Cool name"
                                    value={values.company_name === undefined ? '' : values.company_name}
                                />
                            </div>
                            <div>
                                <div>Topic limit</div>
                                <InputField
                                    name="company_max_num_topics"
                                    type="number"
                                    label="Up to 60 topics"
                                    value={values.company_max_num_topics === undefined ? '' : values.company_max_num_topics}
                                />
                            </div>
                            <div className="formRow center">
                                <StandartButton
                                    title="Save" 
                                    callback={saveCompany}
                                    type="submit"
                                    disabled={!isValid ? true : undefined}
                                />
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default CompanyEditor;

