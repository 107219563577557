import React from "react";
import Tree from "./tree";

class TreeController extends React.Component{

    selectTreeItem = (item, uuid, id, subItem) => {
        this.props.setTreeSelectedItems(item, uuid, id, subItem);
    }

    componentDidMount(){
        this.props.setTreeDataItems(this.props.items)
    }
 
    componentWillUnmount(){
        if(this.props.toNull){
            this.props.setTreeSelectedItems(0,null,null,null);
            this.props.setTreeSelectedSubItems(null,0,null,null)
        }
    }

    render(){
        return <Tree {...this.props} selectedSubItem={this.props.selectedSubItem} selectTreeItem={this.selectTreeItem}/>
    }

}

export default TreeController;