import TopicComparisonController from "./topic-comparison-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { setLeftMenuComponent, setShowLeftMenu, setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent, setRemoveLeftMenuComponent } from 'store/side-bar';
import { getMyTopics, setThemeTableData } from "store/theme-settings";
import { getTopicDropdownFromState, getTopics, getSubtopicsFromState, getTopicsFiltersSelector, getTopicsFiltersSelectorTree } from "selectors/theme-selector";
import { setIsFilterChanged, getCompanyTags, getTopicsFilters, setSelectedTopics as setSelectedFiltersTopics, setPageName, getFiltersStatistics } from "store/filters";
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";
import { setAddSelectedMyFilter } from "store/filters";
import { getDataByType, setIsChartUpdated, setIsComparisonFetching, getGeoplaceDictionary, setComparisonMax, setComparisonLocationsDictionary, setComparisonData } from "store/comparison";
import { setSelectedTopics, setSelectedTopicsAll, setSelectedSubtopicsAll } from "store/topic-menu"
import { setMenus } from "store/settings-menu";
import { setShowPanel } from "store/header"
import { getValue } from 'store/languages';
import { setThemes } from "store/theme";
import { getComparisonData } from "selectors/comparison-selector"
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setSelectedTopicsItems } from "store/topics-select";
import { setReturnBackPostCount } from "store/return-back-button";


let mapStateToProps = (state) => {

    return {
        selectedLanguage: state.languages.selectedLanguage,
        role: state.Account.role,
        themes: state.themesPage.themes,
        checkedThemes: state.themesPage.checkedThemes,
        leftMenuComponent: state.SideBarMenu.leftMenuComponent,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        topicData: getTopics(state),
        topics: getTopicsFiltersSelectorTree(state),
        subtopics: getSelectedSubTopicsUUID(state),
        selectedSubtopics: getSelectedSubTopicsUUID(state),
        data: getComparisonData(state),
        menu: state.Comparison.menu,
        selectedTopics: getSelectedTopicsUUID(state),
        selectedTopicsItems: state.TopicMenu.selectedTopics,
        selected: state.settingsPageMenu.selectedMenuItem,
        selectedMyFilter: state.Filters.selectedMyFilter,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        isComparisonFetching: state.Comparison.isComparisonFetching,
        //selectedItems: state.Filters.selectedMyFilter,
        isChanged: state.Filters.isChanged,
        isChartUpdated: state.Comparison.isChartUpdated,
        comparisonLocations: state.Comparison.comparisonLocations,
        locationsDictionary: state.Comparison.locationsDictionary,
        selectedItems: state.Filters.selectedItems,
        max: state.Comparison.max
    }
}



const TopicComparisonContainer= compose(
    connect(mapStateToProps, {
                                setShowPanel,
                                setShowLeftMenu, 
                                setLeftMenuComponent,
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                setRemoveLeftMenuComponent,
                                getValue, 
                                setThemes, 
                                getMyTopics,
                                getDataByType, 
                                setMenus,
                                setAddSelectedMyFilter,
                                setSelectedTopics,
                                setSelectedTopicsAll,
                                setSelectedSubtopicsAll,
                                setSelectedFiltersTopics,
                                setIsChartUpdated,
                                setIsComparisonFetching,
                                getTopicsFilters,
                                setIsFilterChanged,
                                setPageName,
                                setSelectedTopicsItems,
                                getFiltersStatistics,
                                setThemeTableData,
                                setReturnBackPostCount,
                                getGeoplaceDictionary,
                                setComparisonLocationsDictionary,
                                setComparisonMax,
                                setComparisonData
                            }),
    withAuthRedirect
)(TopicComparisonController)

export default TopicComparisonContainer