import React, {useState, useEffect} from "react";
import { InputText } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DownRightArrowIcon } from '../../assets/icons/down-right-arrow.svg';
import s from "./search-text.module.scss"

const SearchText = (props) => {

    const [searchText, setSearchText] = useState(props.searchText);
    const [searchList, setSearchList] = useState(props.searchList);
    const [parentParent, setParentParent] = useState(props.parentParent);
    const [validation, setValidation] = useState(false);
    const [parent, setParent] = useState(props.parent);
    const [errorText, setErrorText] = useState(null)

    useEffect(() => {
        setSearchList(props.searchList);
        setParentParent(props.parentParent);
        setParent(props.parent)
        setSearchText(props.searchText);
    }, [props.searchList, props.parentParent, props.parent, props.searchText]);

    const checkException = (searchText) => {
        if((searchText && searchText.length) && (searchText[0] === '!' || searchText[0] === '-')){
            return true
        }
        else return false
    }

    const setSearchTextItem = (e) => {
        props.setSearchText(e);
    }

    const searchButton = () => {
        if(!searchText) return
        let ex = checkException(searchText)
        if(!ex || (!parent && !parentParent)){
            props.setSearchList(searchText, parentParent, parent)
            props.setSearchText('')
        }
        else{
            setErrorMessage(props.getValue("QUERY_WITHOUT_EXCLUSIVE_SUBQERIES"));
        }

    }

    const setErrorMessage = (error) => {
        setValidation(error);
        setTimeout(() => {
            setValidation(null);
        }, 2000);
    }

    const setParentParentItem = (parentItem) => {
        let isException = checkException(parentItem)
        if(parentParent === parentItem){
            props.setParentParent(null) 
        }
        else{
            if(!isException){
                props.setParentParent(parentItem)
            }
            else setErrorMessage(props.getValue('EXCLUSIVE_QUERIES_WITHOUT_SUBQERIES'));
        }
        props.setParent(null)
    }

    const setParentItem = (parentParent, parent) => {
        let isException = checkException(parentParent)
        if(!isException){
            props.setParentParent(parentParent)
            props.setParent(parent)
        }
        else{
            setErrorMessage(props.getValue('EXCLUSIVE_QUERIES_WITHOUT_SUBQERIES'))
        }
    }

    const removeItem = (parentParent, parent, item) => {
        props.removeFromSearchList(parentParent, parent, item);
        props.setParentParent(null);
        props.setParent(null)
    }

    const closeModal = () => {
        props.modalShowToggle(false)
    }
    
    const showInstruction = () => {
        props.modalShowToggle("SearchTextInstruction", true,  closeModal, props.getValue('TEXT_FILTER'), "Действие данной кнопки находтся в стадии разработки")
    }

    const searchListItems = searchList && Object.entries(searchList).map((k, i) => 
                                                                    <div key={i} className={s.parentParent} >
                                                                        <div className={s.searchRow}>
                                                                            <div onClick={setParentParentItem.bind(this, k[0])} className={s.searchTextItem + ' '+(parentParent === k[0] ? s.selectedItem : '')}>{k[0]}</div>
                                                                            <div className={s.removeButton} onClick={removeItem.bind(this, k[0], null, null)}><CloseIcon /></div>
                                                                           
                                                                        </div>

                                                                        {k[1] && Object.entries(k[1]).length ? Object.entries(k[1]).map((item, j) => {return <div key={j} className={ s.parent }>
                                                                                                                                                            <div className={s.searchRow}>
                                                                                                                                                                <DownRightArrowIcon />
                                                                                                                                                                <div onClick={setParentItem.bind(this, k[0], item[0], null)} className={s.searchTextItem + ' '+(parent === item[0] ? s.selectedItem : '')}>{item[0]}</div>
                                                                                                                                                                <div className={s.removeButton} onClick={removeItem.bind(this, k[0], item[0], null)}><CloseIcon /></div>
                                                                                                                                                            </div>
                                                                                                                                                          
                                                                                                                                                    {
                                                                                                                                                        item[1] && Object.entries(item[1]).length ? Object.entries(item[1]).map((it, kj) => <div key={kj} className={s.child}>
                                                                                                                                                                                                                                                <DownRightArrowIcon />
                                                                                                                                                                                                                                                <div className={s.searchTextItem}>{it[0]}</div>
                                                                                                                                                                                                                                                <div className={s.removeButton} onClick={removeItem.bind(this, k[0], item[0], it[0])}><CloseIcon /></div>
                                                                                                                                                                                                                                            </div>) : null
                                                                                                                                                    }
                                                                                                                                                </div>}) : null}
                                                                        </div>
                                            )

    return (
        <div className={s.SearchText}>
            <div className="formRow">
                <div className="labelText">
                    {props.getValue('TEXT_FILTER')} {<span>•<span className={s.blueText+" "+s.showInstruction} onClick={showInstruction}>{props.getValue('TEXT_FILTER_INSTRUCTION')}</span></span>}
                </div>
                <div className={s.TextBlock}>
                    <InputText value={searchText} callback={setSearchTextItem} goto={searchButton} placeholder={props.getValue('TEXT_FILTER')}/>
                    <div className={s.searchButton}>
                        <StandartButton disabled={!searchText || searchText === ''} title={props.getValue('ADD')} icon={PlusIcon} callback={searchButton} fill={'white'}/>
                    </div>
                </div>
                <div className={s.ValidationBlock}>
                    {validation}
                </div>
                <div className={s.SearchList}>
                    
                    {searchListItems}
                </div>
            </div>
        </div>
    ) 
}

export default SearchText;