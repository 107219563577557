import React, { useState, useEffect } from "react";
import s from "./signin.module.scss";
import { ReactComponent as Logo} from "../../../assets/icons/logo.svg";
import { InputField, InputPasswordField } from "../../../commons/form-controls";
import { signinValidateSchema } from "../../../utils/validators.js";
import { StandartButton } from "../../../commons/form-controls";
import { InputCheckBox } from "../../../commons/input-controls";
import { ReactComponent as OkIcon } from '../../../assets/icons/ok.svg';
import ErrorMessage from "./error-message/error-message";
import ErrorMessageLabel from "./error-message/error-message-label";
import { Formik, Form } from "formik";


const Signin = (props) => {

    let [showPassword, setShowPassword] = useState(false);
    let [checkPrevious, setCheckPrevious] = useState(false);
    let [ForgotPasswordError, setForgotPasswordError] = useState(props.errorInResetPassword);
    let [showError, setShowError] = useState(props.loginError);

    useEffect(() => {
        setShowError(props.loginError)
        setForgotPasswordError(props.errorInResetPassword);
    },[props]);

    let componentsMap = {
        OkIcon
    }

    const onSubmitForm = (formData) => {
        if(formData !== undefined){
            props.signin(formData.login.toLowerCase(), formData.password);
            /*if(props.unAuthorizedLanguage){
                setTimeout(() => {props.setSelectedLanguage('unauth',props.unAuthorizedLanguage)}, 2000) 
            }*/
        }
    }

    const onShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const previousSystemVersionCheck = () => {
        setCheckPrevious(!checkPrevious)
    }

    const getValue = (value) => {
        return props.getValue(value)
    }

    const resetPassword = (e, type, value) => {
        if(e === 'restart'){
            props.setErrorInResetPassword(null);        
            setTimeout(()=>{forgotPassword('restart'), 300}); 
        }
        else if(e === 'close'){
            props.setErrorInResetPassword(null)
        }
        else{
            if(type && value){
                props.resetPassword(type, value);
                setTimeout(()=>{props.showModal('Alert', true, closeModal, props.getValue('PASSWORD_RESTORE'), props.getValue('A_LETTER_WITH_INSTRUCTIONS_WAS_SENT_TO_YOUR_EMAIL_ADDRESS'), );}, 100) 
            }
            else
                props.setErrorInResetPassword(null)
        }
    }

    const forgotPassword = (type = null) => {
        if(type === 'restart')
            props.showModal('ForgotPassword', true, resetPassword, props.getValue('PASSWORD_RESTORE'), props.getValue('ARE_YOU_REALLY_WANT_TO_EXIT')+'?');
        else if(type === 'success'){
            props.showModal('Alert', true, null, props.getValue('A_LETTER_WITH_INSTRUCTIONS_WAS_SENT_TO_YOUR_EMAIL_ADDRESS'), );
        }
        else
            props.showModal(ForgotPasswordError ? 'ForgotPasswordError' : 'ForgotPassword', true, resetPassword, props.getValue('PASSWORD_RESTORE'), props.getValue('ARE_YOU_REALLY_WANT_TO_EXIT')+'?');
    }

    const changedForm = (e) => {
        showError &&  props.setErrorInLogin(null)
    }


    if(props.errorInResetPassword){
        setTimeout(()=>{forgotPassword()}, 100) }

    return (
        <div className={s.Signin}>
            <div className={s.Header}>
                
                <h1 className={s.titleText}>
                    
                    
                    <span>{getValue('WELLCOME_TO_THE')}</span>
                    <Logo />
                    {/*<span>{getValue('FCBK_ECHO')}</span>
                    <span style={{fontWeight: 'bold'}}>{getValue('MEDIA_MONITORING')}</span>*/}
                </h1>
            </div>
            {showError && (showError.code !== 401 && showError.code !== 404 && showError.code !== 400) && 
                <ErrorMessage getValue={props.getValue} message={showError}/>
            }
            <div className={s.Body}>
                <Formik
                    initialValues={{
                        login: '',
                        password: '',
                        showPassword: showPassword
                    }}
                    validationSchema={signinValidateSchema}
                    onSubmit={onSubmitForm}
                >
                     {({handleChange, handleSubmit})=>(
                        <Form
                            onSubmit={handleSubmit}
                            onChange={changedForm}
                        >
                            <div className="formRow">
                                <span className="labelText">{showError && showError.code && (showError.code == 404 || showError.code == 400) ? <ErrorMessageLabel message={showError.message}/> : null}{getValue('LOGIN')}</span>
                                <InputField name="login" type="text" label={getValue('LOGIN')}/>
                            </div>
                            <div className="formRow">
                                <span className="labelText">{showError && showError.code && showError.code == 401 ? <ErrorMessageLabel message={showError.message} /> : null}{getValue('PASSWORD')}</span>
                                <InputPasswordField handleChange={handleChange} onShowPassword={onShowPassword} showPassword={showPassword} name="password" type="password" label={getValue('PASSWORD')}/>
                                <div className={s.noPass} onClick={forgotPassword}><span>{props.getValue("DON'T_REMEMBER_PASSWORD")}</span></div>
                            </div>
                            <div className="formRow spaceBetween">
                                <div className={s.checkboxRow}>
                                    {/*<InputCheckBox isChecked={checkPrevious} name="signin" title={props.getValue('PREVIOUS_VERISON')} callback={previousSystemVersionCheck}/>
                                    <span>{props.getValue('PREVIOUS_VERISON')}</span>*/}
                                </div>
                                <StandartButton width={450} type="submit" title={props.getValue('SIGN_IN')} icon={componentsMap.OkIcon} callback={onSubmitForm} stroke={'white'}/>
                            </div>
                        </Form>
                     )}
                </Formik>
            </div>
        </div>

    )
}

export default Signin;