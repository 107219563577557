import SettingsController from "./settings-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../hoc/AuthRedirect";
import { getMe } from "../../store/account";
import { setComponent } from "../../store/settings";
import { getValue } from '../../store/languages';
import { setThemeTableData } from "../../store/theme-settings";
import { setLeftMenuComponent, setRemoveLeftMenuComponent, setRightMenuComponent, setShowLeftMenu } from '../../store/side-bar';
import { setMenus } from "../../store/settings-menu";
import { setSubtopicItem } from "../../store/topic-item";
import { setSelectedMenu } from "../../store/settings-menu"

let mapStateToProps = (state) => {

    return {
        component: state.settingsPage.component,
        menuItems: state.settingsPage.menuItems,
        settingsMenuItems: state.settingsPageMenu.items,
        leftMenuComponent: state.SideBarMenu.leftMenuComponent,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        selected: state.settingsPageMenu.selectedMenuItem,
        showLeftMenu: state.SideBarMenu.showLeftMenu,
        selectedLanguage: state.languages.selectedLanguage,
        selectedMyFilter: state.Filters.selectedMyFilter,
        selectedRole: state.AdminMode.selectedRole
    }
}

export default compose(
    connect(mapStateToProps, {getValue, getMe, setComponent, setLeftMenuComponent, setRemoveLeftMenuComponent, setRightMenuComponent, setShowLeftMenu, setMenus, setThemeTableData, setSubtopicItem, setSelectedMenu}),
    withAuthRedirect
)(SettingsController)