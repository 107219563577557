import CompanyUsersController from "./company-users-controller"
import { getValue } from "store/languages";
import { compose } from "redux";
import { connect } from "react-redux";

let mapStateToProps = (state) => {
    return {
        blank: null
    }
}

const CompanyUsersContainer = (CompanyUsersController);

export default compose(
    connect(
        mapStateToProps, {
            getValue
        }
    )
) (CompanyUsersContainer)