import React from "react";
import TableItem from "../../items/table/table"//"../../../../items/table/table";
import s from "./favourite-list.module.scss";

const FavouriteList = (props) => {
    return (
        <div className={s.FavouriteList}>
            {<TableItem 
                data={[props.tableHeaderName, props.data]}  
                getValue={props.getValue}
                hiddenColumns={[1]}
                titleText={props.getValue('FAVORITE_AUTHORS_AND_SOURCES')}
                height={230}
                /> 
            }
        </div>
    )
}

export default FavouriteList;