import { createSelector } from "reselect";
import moment from "moment";
import { getFormatedDate } from "service/format-date";

const favoriteList = (state) => {
    return state.FavouriteList.favourites
}

const formatDate = (date) =>{
    return moment(date).format("HH:mm DD.MM.YY");
}

export const getFavoriteList = createSelector(favoriteList, (favourites) => {
    if(favourites && favourites.length){
        return favourites.map((item)=>{
            return [item.id, item.topicId, item.topicUUID, formatDate(item.createdAt), formatDate(item.updatedAt), item.name, item.profileUrl ? item.profileUrl : '', item.resourceDomain ? item.resourceDomain : ''];
        });
    }
    else
        return []
    
})