import React from "react";
import s from "./preload.module.scss"

const Spinner = (props) => {
    return (
        <div className={s.SpinnerBlock}>
            <div className={s.Spinner}>
                <div></div><div></div><div></div><div></div>
            </div>
        </div>

    )
}

export default Spinner;