import React from "react";
import TableItem from "../../../../items/table/table";
import EditUserContainer from "./edit-user/edit-user-container";

const UsersSettings = (props) => {

    return(
        <div>
            <TableItem data={[props.header, props.values]} getValue={props.getValue} titleText={props.getValue('TOPICS_LIST')}/> 
        </div>
    )
}

export default UsersSettings;