import React from "react";
import Paginator from "./paginator";

class PaginatorController extends React.Component{

    nextPage = () => {
        this.props.nextPage();
    }

    callback = (event) => {
        this.props.callback(event);
    }

    render(){
        return <Paginator {...this.props} />
    }

}

export default PaginatorController;