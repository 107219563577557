import React, {useState} from "react";
import s from './side-bar.module.scss';
import LeftMenu from "./left-menu/left-menu-container";
import RightMenu from "./righ-menu/right-menu-container";

const SideBar = (props) => {
    //let twoBlocks = props.LeftMenu.leftMenuComponent && props.LeftMenu.leftMenuComponent.length === 2 ? s.
    
    return (
        
        <div className={s.SideBar}>
            <LeftMenu {...props} showMenu={props.showLeftMenu} />
            <RightMenu {...props} showMenu={props.showRightMenu} />
        </div>
    )
}

export default SideBar;