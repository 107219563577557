const Locations = {
    "424313582": "Китай",
    "424313675": "Азербайджан",
    "1700777271": "Кыргызстан",
    "508058549": "Павлодарская область",
    "508059331": "Восточно-Казахстанская область",
    "784756509": "Северо-Казахстанская область",
    "959501189": "Карагандинская область",
    "959502983": "Костанайская область",
    "959509383": "Акмолинская область",
    "974358575": "Алматинская область",
    "974358688": "Жамбылская область",
    "974359207": "Кызылординская область",
    "975900646": "Актюбинская область",
    "975902304": "Западно-Казахстанская область",
    "975903612": "Атырауская область",
    "975906931": "Мангистауская область",
    "974358986": "Туркестанская область",
    "27522202": "Талгар",
    "27524816": "Семей",
    "98022062": "Атырау",
    "214887132": "Туркестан",
    "240470924": "Тараз",
    "240505390": "Шу",
    "240711925": "Жанатас",
    "240865457": "Караганда",
    "240872918": "Павлодар",
    "241035125": "Актобе",
    "240993564": "Усть-Каменогорск",
    "241455373": "Темиртау",
    "241492821": "Кызылорда",
    "241542056": "Кокшетау",
    "241549456": "Рудный",
    "241679356": "Талдыкорган",
    "241772277": "Кентау",
    "241781833": "Арысь",
    "242415806": "Сары-Озек",
    "242441466": "Коскудук",
    "242455350": "Жоламан",
    "242465214": "Капчагай",
    "242940822": "Аягоз",
    "243119367": "Актогай",
    "243178767": "Лепсы",
    "243182293": "Матай (Matay)",
    "243235602": "Ушарал",
    "243324293": "Сарканд",
    "243325633": "Жансугуров",
    "243464146": "Маканчи",
    "243465319": "Урджар",
    "243472864": "Шингожа",
    "243508453": "Достык",
    "243657575": "Жаркент",
    "243659737": "Коктал",
    "243692801": "Чарын",
    "243693248": "Чунджа",
    "243697387": "Подгорное",
    "243708956": "Актам",
    "243723775": "Кеген",
    "243725702": "Шелек",
    "243729810": "Жаланаш",
    "243735997": "Сарыжаз",
    "243737203": "Текес",
    "243741613": "Нарынкол",
    "243743625": "Есик",
    "243748132": "Сумбе",
    "243748155": "Сарыбастау",
    "243798668": "Саты",
    "243850399": "Карашокы",
    "243991314": "Акший",
    "243995789": "Копа (Kopa)",
    "243997970": "Шамалган",
    "243998902": "Узынагаш",
    "244005521": "станция Бурубайтал",
    "244010886": "Аксуек",
    "244017974": "Балхаш",
    "244109105": "Кабанбай",
    "244114769": "Бахты",
    "244118661": "Акжал",
    "244128763": "Кокпекты",
    "244135961": "Майкапчагай",
    "244147213": "Риддер",
    "244198209": "Зыряновск",
    "244529371": "Ленгер",
    "244532522": "Сарыагаш",
    "247318618": "Жетысай",
    "247318653": "Атакент",
    "247319161": "Шардара",
    "248057498": "Лисаковск",
    "253063867": "Жанаозен",
    "253716507": "Кульсары",
    "253725107": "Макат",
    "253729284": "Махамбет",
    "253983700": "Бейнеу",
    "254129240": "Мукур",
    "254135336": "Сагиз",
    "254136309": "Миялы",
    "254139962": "Карауылкелды",
    "254143253": "Шубаркудук",
    "254313000": "Агадырь",
    "254313822": "Акчатау",
    "254314127": "Кайракты пос.",
    "254316151": "Моинты",
    "271077515": "Айтеке би",
    "271080647": "Аральск",
    "271085442": "Казалинск",
    "271092323": "Байконур",
    "271120352": "Коргалжын",
    "271125900": "Жем",
    "271183751": "Хромтау",
    "271203690": "Жезказган",
    "271292059": "Каражал",
    "271293913": "Атасу",
    "271296615": "Сакен Сейфуллин пос.",
    "271301949": "Карабас п.",
    "271309842": "Каркаралинск",
    "271311323": "Приозёрск",
    "271311686": "Сарань",
    "271312178": "Сатпаев",
    "271395447": "Осакаровка",
    "271401068": "Акжарык с.",
    "271402224": "Актогай",
    "271402834": "Нуркен",
    "271413561": "Татан",
    "271416049": "Киевка п.",
    "297359900": "Jambil",
    "297596160": "Kulan / Qulan",
    "299211831": "Ивановка с.",
    "299923721": "Абай",
    "299924758": "Шешенкара с. (Пролетарский п.)",
    "299974347": "Актас",
    "299978968": "Актау",
    "299982128": "Аркалык",
    "301058599": "Gulshad",
    "301060581": "Южный п.",
    "301373626": "Конырат п.",
    "301374302": "Кушокы п.",
    "301374694": "Кызылжар п.",
    "301715316": "Шахан п.",
    "301716913": "Шахтинск",
    "301717251": "Жайрем пос.",
    "301733193": "Джамбул п.",
    "301734878": "Шашубай с. (Озёрное)",
    "301760956": "Габидена Мустафина пос. (Токаревка)",
    "301761659": "Топар",
    "303821397": "Карабалык",
    "303894619": "Октябрьское",
    "303908697": "Есиль",
    "303910698": "Сурган",
    "303911228": "Жаксы",
    "303913171": "Атбасар",
    "377600470": "Курчатов",
    "410079696": "Державинск",
    "424311521": "Казахстан",
    "430704251": "Бурлин",
    "435393175": "Аксай",
    "448814768": "Костанай",
    "451519656": "Железинка",
    "451702142": "Каргалы",
    "451702144": "Ушконыр",
    "471558381": "Казгородок",
    "471753777": "Астраханка",
    "471941831": "Алга",
    "471953135": "Чингирлау",
    "472267256": "Ганюшкино",
    "472267642": "Индер",
    "472268433": "Аккистау",
    "473066535": "Жанакала",
    "473070642": "Жанакала с.",
    "473080162": "Жангала",
    "473098790": "Таскала",
    "473112751": "Перемётное",
    "473115328": "Федоровка",
    "473372461": "Жанибек",
    "473399115": "Сайхин",
    "476263135": "Шетпе",
    "476293790": "Сай-Утёс",
    "476310282": "Акжигит",
    "481045720": "Ертіс",
    "491923263": "Кокпек",
    "525190320": "Денисовка",
    "525439568": "Кумкудук",
    "527856584": "Азгир",
    "533754334": "Баршатас",
    "534085130": "Саяк пос.",
    "534085154": "Даратаб",
    "534085155": "Жорга",
    "534085162": "Аыйртас",
    "534085168": "Томар с.",
    "534087372": "Жогаргы Егинсу",
    "534087495": "Казымбет",
    "534087953": "Келдемурат",
    "534088599": "Берлык",
    "534097147": "Бескарагай",
    "537561303": "Экибастуз",
    "539629498": "Аятское",
    "539629508": "Фрунзенское",
    "546773876": "Ботакара п.",
    "546786098": "Алгбас",
    "547809505": "Кокпекты",
    "547809559": "Байкадам с.",
    "547809608": "Бастау с.",
    "547811784": "Корнеевка",
    "554059814": "Шидерты п.",
    "704397626": "Житикара",
    "729610410": "Актау",
    "761956738": "Батпак с. (Крониловское)",
    "761956982": "Ошаганды с. (Красный Кут)",
    "761957222": "Баймырза с. (Покорное)",
    "765064981": "Аксу-Аюлы",
    "832355131": "Дубовка",
    "896978591": "имени Айтабая Hазарбекова",
    "914021120": "Качар",
    "934340038": "Уштобе",
    "983585435": "Сартобе с.",
    "986043692": "Долинка п.",
    "1106418007": "Курылыс п.",
    "1128220486": "Львовка",
    "1128220490": "Тохтарово",
    "1141193378": "Приреченка",
    "1238308608": "Молодежный",
    "1253518639": "Жетпыс",
    "1253572824": "Курминское",
    "1253573436": "Жумабек",
    "1282428111": "Тогызкудук с.",
    "1282430541": "Доскей",
    "1284932620": "Ростовка с.",
    "1298132253": "Мамлютка",
    "1314870477": "Майшұқыр",
    "1314870478": "Құланөтпес",
    "1314889924": "Оразак",
    "1314909997": "Баршино",
    "1314942756": "Kaskatau",
    "1314942758": "Нығман",
    "1315945296": "Каменка",
    "1339442968": "Новонежинка",
    "1362711625": "Челгаши",
    "1362752104": "Ивановка",
    "1369849641": "Алга",
    "1375916594": "Геологическое",
    "1375916598": "Каражар с.",
    "1375949711": "Асыл с.",
    "1375960032": "Молодецкое с.",
    "1375975105": "Кызылжар",
    "1375990240": "Березняки с.",
    "1375999660": "Тасаул пос.",
    "1376006294": "Тегисжол с.",
    "1376576251": "Новый Крондштадт",
    "1379357053": "Торгай",
    "1384387947": "Карамурын",
    "1409446799": "Урпек",
    "1410760848": "Белбасар",
    "1430694778": "Покровка",
    "1457457600": "Петропавловск",
    "1466716533": "Шымкент",
    "1492994952": "Абай",
    "1496101001": "Бакырлы",
    "1496101311": "Жуантобе",
    "1496101371": "Жиңишке",
    "1496101597": "КИРОВА",
    "1496101666": "Коммуна / Kommuna",
    "1496102100": "Тайконыр",
    "1496102111": "Талапты",
    "1496102166": "Ораз Ата",
    "1496102346": "Чулаккурган",
    "1496102393": "Каракур",
    "1515019354": "Шаульдер",
    "1655751674": "Подгородка",
    "1683604838": "Зеленое",
    "1701097240": "Котяевка",
    "1760005822": "Екпенды",
    "1764497137": "Сузак",
    "1764497143": "Шага / Shara",
    "1775849171": "Когам",
    "1786918916": "Курты",
    "1832841848": "Уральск",
    "1913828772": "Бескол",
    "1915016813": "Borankul",
    "1923077056": "Жанааул",
    "1923103026": "Сафоновка",
    "1923117377": "Богатое",
    "1952946568": "Толе би",
    "1983402593": "Перевалoчнoе",
    "1983412808": "Жасталап",
    "1983426001": "Рембаза",
    "2005354523": "Якорь",
    "2026935419": "Архангельское",
    "2026935421": "Новокаменка",
    "2026935424": "Петерфельд",
    "2026935427": "Подгорное",
    "2026964332": "Боголюбово",
    "2026973156": "Новоникольское",
    "2042658769": "Темирлановка",
    "2050311604": "Жамбыл",
    "2050311607": "Бирлик",
    "2050321939": "Хантау / Khantaw",
    "2053541157": "Qaratoghan",
    "2055017561": "Kokcy",
    "2055064645": "Jalghizaghat",
    "2132913855": "Вознесенка",
    "2132913859": "Надежка",
    "2219596229": "Николаевка",
    "2219599703": "Петровка",
    "2219617644": "Чириковка",
    "2304072858": "Байтерек",
    "2321177089": "Уланбель",
    "2363178902": "ЗВП",
    "2606780046": "Ушбулак",
    "2612028998": "Жанай",
    "26544289": "Алматы",
    "2567720890": "Акколь",
    "2583746142": "Актобе",
    "27548009": "Нур-Султан",
    "424314830": "Россия",
    "503077246": "Иркутская область",
    "505006802": "Рязанская область",
    "516618494": "Калининградская область",
    "533719181": "Самарская область",
    "535136806": "Мурманская область",
    "535182674": "Свердловская область",
    "573226686": "Татарстан",
    "573226687": "Башкортостан",
    "573226688": "Удмуртия",
    "869508901": "Мордовия",
    "933435715": "Кировская область",
    "26903504": "Петрозаводск",
    "26951330": "Вологда",
    "27017589": "Великий Новгород",
    "27025689": "Псков",
    "27048976": "Калининград",
    "27072633": "Смоленск",
    "27090043": "Курск",
    "27096008": "Орёл",
    "27119039": "Белгород",
    "27490597": "Санкт-Петербург",
    "27503892": "Екатеринбург",
    "27503945": "Самара",
    "27503946": "Омск",
    "27504067": "Казань",
    "27504156": "Челябинск",
    "27504327": "Уфа",
    "27504363": "Волгоград",
    "27504531": "Пермь",
    "27504590": "Саратов",
    "27505044": "Воронеж",
    "27505046": "Тольятти",
    "27505129": "Краснодар",
    "27505266": "Ярославль",
    "27505285": "Барнаул",
    "27505335": "Иркутск",
    "27505477": "Новокузнецк",
    "27505509": "Оренбург",
    "27505582": "Рязань",
    "27505663": "Пенза",
    "27505666": "Тюмень",
    "27505889": "Нижний Новгород",
    "29804102": "Анапа",
    "29980667": "Таганрог",
    "34043670": "Сочи",
    "34389350": "Тула",
    "34395338": "Назрань",
    "34506055": "Нижний Тагил",
    "2452912060": "Московская область",
    "36714827": "Ставрополь",
    "76611479": "Липецк",
    "79736088": "Воткинск",
    "87705691": "Новороссийск",
    "90495941": "Обнинск",
    "93711720": "Нальчик",
    "101480194": "Энгельс",
    "143561186": "Долгопрудный",
    "148899210": "Рыбинск",
    "148901123": "Кострома",
    "148901125": "Иваново",
    "160258541": "Пятигорск",
    "178951928": "Красноярск",
    "179374935": "Курган",
    "188558082": "Череповец",
    "191638748": "Черкесск",
    "191640862": "Йошкар-Ола",
    "191644503": "Махачкала",
    "191648647": "Элиста",
    "191651581": "Саранск",
    "191652335": "Якутск",
    "191655065": "Кызыл",
    "191657470": "Абакан",
    "191697061": "Астрахань",
    "191723030": "Южно-Сахалинск",
    "191736223": "Ханты-Мансийск",
    "191738860": "Биробиджан",
    "191749240": "Набережные Челны",
    "191752285": "Волжский",
    "191754078": "Сургут",
    "191754954": "Комсомольск-на-Амуре",
    "191756795": "Стерлитамак",
    "191758663": "Братск",
    "191773560": "Ангарск",
    "191774769": "Нижневартовск",
    "191779212": "Прокопьевск",
    "191780610": "Норильск",
    "191824980": "Армавир",
    "191843200": "Златоуст",
    "191850710": "Находка",
    "191852866": "Березники",
    "191855308": "Рубцовск",
    "191863710": "Миасс",
    "191864581": "Уссурийск",
    "191866198": "Ковров",
    "191870333": "Копейск",
    "191871001": "Первоуральск",
    "191871925": "Одинцово",
    "191872996": "Невинномысск",
    "191875199": "Кисловодск",
    "191879312": "Новочебоксарск",
    "191880180": "Серпухов",
    "191880979": "Хасавюрт",
    "191885615": "Орехово-Зуево",
    "191890746": "Ачинск",
    "191892583": "Сергиев Посад",
    "191894808": "Нефтеюганск",
    "191897505": "Ноябрьск",
    "191903740": "Дербент",
    "191908717": "Киселёвск",
    "191909355": "Междуреченск",
    "191912193": "Канск",
    "191914736": "Тобольск",
    "205216827": "Ленинск-Кузнецкий",
    "251645471": "Чита",
    "253041038": "Зеленоград",
    "255594586": "Сарапул",
    "256361843": "Раменское",
    "269342417": "Мурманск",
    "276628719": "Архангельск",
    "277060995": "Хабаровск",
    "280304251": "Бердск",
    "285736628": "Соликамск",
    "288126203": "Серов",
    "360001149": "Мытищи",
    "390614421": "Кемерово",
    "683814442": "Ессентуки",
    "711715624": "Ульяновск",
    "740732715": "Чебоксары",
    "1078579692": "Арсеньев",
    "1244147408": "Магнитогорск",
    "1457469393": "Улан-Удэ",
    "1522654732": "Грозный",
    "1679639726": "Ижевск",
    "1686293227": "Москва",
    "1711559378": "Новый Уренгой",
    "2095029787": "Воскресенск",
    "2893369031": "Магадан",
    "26878551": "Кандалакша",
    "26898768": "Калевала",
    "26898769": "Кировск",
    "26898825": "Сегежа",
    "26898826": "Зеленоборский",
    "26952234": "Каргополь",
    "27023177": "Луга",
    "27464354": "Борисоглебский",
    "27464486": "Ростов",
    "29866526": "Ейск",
    "34390960": "Высоцк",
    "34395335": "Беслан",
    "34395341": "Магас",
    "34398261": "Красная Поляна",
    "60078445": "Рославль",
    "60079145": "Тосно",
    "60237699": "Зеленоградск",
    "60238036": "Мамоново",
    "60238387": "Советск",
    "60238585": "Полесск",
    "60405981": "Торжок",
    "76752092": "Порхов",
    "82739207": "Чайковский",
    "90045540": "Медынь",
    "92383389": "Углич",
    "92774502": "Мышкин",
    "93701882": "Баксан",
    "94091844": "Селижарово",
    "94102909": "Осташков",
    "130201149": "Новозыбков",
    "153568874": "Елабуга",
    "174842795": "Ишим",
    "176957217": "Салехард",
    "176960743": "Лабытнанги",
    "178108820": "Нижняя Тавда",
    "178926370": "Тайга",
    "186985543": "Кривошеино",
    "187010245": "Колпашево",
    "191636706": "Горно-Алтайск",
    "191736988": "Анадырь",
    "191907831": "Новотроицк",
    "191909841": "Ухта",
    "204815902": "Дудинка",
    "204816649": "Тура",
    "205159518": "Юрга",
    "205430993": "Яя",
    "205437775": "Мариинск",
    "206131650": "Тавда",
    "206809742": "Каргасок",
    "210111403": "Стрежевой",
    "210305929": "Иланский",
    "210323639": "Тайшет",
    "216890905": "Осинники",
    "217317194": "Кингисепп",
    "217323851": "Ивангород",
    "221109841": "Калтан",
    "221129318": "Мундыбаш",
    "221140463": "Темиртау",
    "221143207": "Каз",
    "221173014": "Таштагол",
    "221181091": "Шерегеш",
    "221214205": "Мыски",
    "243083247": "Нижнеудинск",
    "243329007": "Тулун",
    "243347526": "Заиграево",
    "243349531": "Петровск-Забайкальский",
    "243355185": "Хилок",
    "243527503": "Карымское",
    "243634984": "Шилка",
    "243695003": "Чернышевск",
    "243836016": "Шадринск",
    "243991807": "Боровский",
    "243991814": "Ялуторовск",
    "243991822": "Заводоуковск",
    "243993519": "Абатское",
    "243995008": "Омутинское",
    "244078631": "Сретенск",
    "244529460": "Тегульдет",
    "246665853": "Усть-Карск",
    "246669195": "Боготол",
    "246678899": "Кировск",
    "246830274": "Остров",
    "251071107": "Агидель",
    "251072970": "Дюртюли",
    "251532339": "Юрюзань",
    "253258948": "Межгорье",
    "253737355": "Ахтубинск",
    "253936017": "Палласовка",
    "254587738": "Усть-Илимск",
    "254862887": "Тотьма",
    "256361865": "Руза",
    "256827434": "Шарья",
    "256827443": "Котельнич",
    "256827452": "Омутнинск",
    "264358670": "Яранск",
    "264365304": "Советск",
    "270529337": "Железноводск",
    "270531692": "Минеральные Воды",
    "270875270": "Губаха",
    "271283522": "Таруса",
    "271954653": "Чебаркуль",
    "272601866": "Кунгур",
    "272845466": "Никель",
    "274749672": "Суксун",
    "275772536": "Шелехов",
    "275804020": "Сосновоборск",
    "275804138": "Бородино",
    "275804147": "Уяр",
    "275805295": "Лесосибирск",
    "275805315": "Енисейск",
    "275806099": "Назарово",
    "275807520": "Дивногорск",
    "275808256": "Минусинск",
    "275808498": "Саяногорск",
    "276092539": "Ужур",
    "276092580": "Алзамай",
    "276092773": "Саянск",
    "276092774": "Зима",
    "276092781": "Залари",
    "276092950": "Черемхово",
    "276092975": "Свирск",
    "276092977": "Усолье-Сибирское",
    "276113713": "Забайкальск",
    "276627958": "Беломорск",
    "276646949": "Данилов",
    "276994866": "Бикин",
    "277057968": "Дальнереченск",
    "277060596": "Вяземский",
    "277118590": "Свободный",
    "277139530": "Шимановск",
    "277573669": "Ерофей Павлович",
    "277576138": "Амазар",
    "277576357": "Могоча",
    "277576558": "Ключевский",
    "277750915": "Белово",
    "278023046": "Татарск",
    "278211683": "Югорск",
    "278216664": "Советский",
    "278298564": "Нерчинск",
    "278304667": "Букачача",
    "278380012": "Зея",
    "278991732": "Ордынское",
    "278992054": "Камень-на-Оби",
    "279240513": "Красногорский",
    "279978754": "Усть-Кут",
    "279980750": "Северобайкальск",
    "279982847": "Бабушкин",
    "279983984": "Гусиноозёрск",
    "279985581": "Кяхта",
    "279985631": "Закаменск",
    "279987689": "Тында",
    "279997610": "Таксимо",
    "280067854": "Чульман",
    "280078404": "Томмот",
    "280109123": "Амга",
    "280182070": "Сусуман",
    "280183184": "Усть-Нера",
    "280352878": "Кочки",
    "280404821": "Усть-Кокса",
    "280513195": "Шебалино",
    "280513628": "Майма",
    "280515128": "Кош-Агач",
    "280521185": "Усть-Кан",
    "280548716": "Белокуриха",
    "280550570": "Турочак",
    "280822642": "Борисоглебск",
    "281293974": "Чемал",
    "281427282": "Сузун",
    "281428908": "Новоалтайск",
    "281431381": "Тальменка",
    "281431605": "Искитим",
    "281431826": "Барабинск",
    "281433268": "Каргат",
    "281433500": "Чулым",
    "282596576": "Нижний Бестях",
    "282635858": "Демянск",
    "282914618": "Карасук",
    "282914871": "Славгород",
    "283588621": "Сковородино",
    "285340786": "Боград",
    "285360076": "Шира",
    "285365947": "Копьево",
    "285734826": "Красновишерск",
    "285745298": "Александровск",
    "285746241": "Кизел",
    "285889059": "Черногорск",
    "286084866": "Ак-Довурак",
    "286085202": "Чадан",
    "286085444": "Шагонар",
    "286085489": "Туран",
    "286130437": "Тоора-Хем",
    "286200781": "Сарыг-Сеп",
    "286203959": "Кызыл-Мажалык",
    "286214595": "Абаза",
    "286523928": "Троицк",
    "286560902": "Холмск",
    "286923453": "Абан",
    "286953863": "Уват",
    "286982957": "Калач-на-Дону",
    "287005365": "Новоузенск",
    "287039558": "Сальск",
    "287040070": "Зимовники",
    "287337481": "Надым",
    "287387383": "Карачаевск",
    "287411141": "Прохладный",
    "287516647": "Алагир",
    "287660963": "Каспийск",
    "287661153": "Избербаш",
    "287662481": "Касумкент",
    "287670803": "Буйнакск",
    "287673747": "Дагестанские Огни",
    "287686736": "Кизилюрт",
    "287687179": "Южно-Сухокумск",
    "287702846": "Аргун",
    "287703730": "Гудермес",
    "287704409": "Шали",
    "287756202": "Пугачёв",
    "287798315": "Карабулак",
    "287798582": "Малгобек",
    "288419752": "Кузнецк",
    "288476845": "Кыштым",
    "288795864": "Яровое",
    "289004179": "Юго-Камский",
    "289011795": "Оса",
    "289464809": "Криводановка",
    "289835271": "Чоя",
    "289843365": "Оса",
    "289980364": "Калачинск",
    "289992321": "Горьковское",
    "289994080": "Одесское",
    "289996348": "Русская Поляна",
    "290215681": "Здвинск",
    "290330613": "Бачатский",
    "290781841": "Туруханск",
    "290884076": "Наушки",
    "290885503": "Новоселенгинск",
    "290889369": "Кабанск",
    "291420659": "Могзон",
    "291558905": "Балахта",
    "291779448": "Алейск",
    "291818590": "Ельцовка",
    "291860771": "Маслянино",
    "292226474": "Заозёрск",
    "292231968": "Гаджиево",
    "292235034": "Полярный",
    "292879578": "Дивеево",
    "292885144": "Снежинск",
    "293459492": "Кола",
    "295072813": "Вершино-Дарасунский",
    "295119416": "Тяжинский",
    "295862317": "Буинск",
    "295864677": "Чистополь",
    "295896340": "Шуя",
    "295902357": "Ермаковское",
    "295939729": "Шушенское",
    "295945680": "Черёмушки",
    "295952416": "Курагино",
    "296081305": "Нолинск",
    "296086848": "Нема",
    "296087893": "Кильмезь",
    "296100014": "Малмыж",
    "296113691": "Уржум",
    "296130162": "Ува",
    "296134100": "Ядрин",
    "296138675": "Тюкалинск",
    "296379510": "Ильский",
    "296410521": "Чернушка",
    "297513007": "Улаган",
    "298352406": "Апшеронск",
    "300582728": "Новая Игирма",
    "300588087": "Хребтовая",
    "300594850": "Шестаково",
    "300612214": "Железногорск-Илимский",
    "300659754": "Кодинск",
    "301246150": "Краснознаменск",
    "301636452": "Киренск",
    "301639437": "Ленск",
    "301645168": "Бодайбо",
    "301648440": "Рыбное",
    "301710229": "Мамакан",
    "301761027": "Мирный",
    "301770161": "Удачный",
    "301775279": "Нюрба",
    "301778109": "Вилюйск",
    "301789452": "Айхал",
    "301822109": "Бердигестях",
    "301959493": "Олёкминск",
    "302019058": "Турунтаево",
    "302024328": "Гремячинск",
    "302036717": "Качуг",
    "302066783": "Сунтар",
    "302372139": "Мама",
    "302524562": "Нижнеангарск",
    "302534600": "Бичура",
    "302605557": "Верхоянск",
    "302830616": "Майя",
    "303015164": "Умба",
    "303019430": "Ревда",
    "303353860": "Полярные Зори",
    "303973327": "Заполярный",
    "304337561": "Озёрск",
    "304552309": "Чокурдах",
    "304605480": "Радужный",
    "304663037": "имени Полины Осипенко",
    "304721209": "Валуйки",
    "306457009": "Краснотурьинск",
    "306457427": "Карпинск",
    "306458157": "Волчанск",
    "307089440": "Урай",
    "307964759": "Углеуральский",
    "308059641": "Лесогорск",
    "308220097": "Лысьва",
    "308259963": "Шигоны",
    "308877563": "Домбаровский",
    "308879014": "Светлый",
    "308897102": "Отрадный",
    "309493556": "Кемь",
    "310831029": "Мортка",
    "310877933": "Ирбит",
    "310912076": "Кириши",
    "313570754": "Инза",
    "314794893": "Гусь-Хрустальный",
    "316492711": "Михайловское",
    "321203443": "Еткуль",
    "330235003": "Хатанга",
    "330402488": "Бабаево",
    "330421322": "Новый Ургал",
    "330823637": "Хор",
    "330842003": "Сосногорск",
    "334371762": "Красноусольский",
    "335884516": "Игарка",
    "335978456": "Красноборск",
    "335978473": "Коряжма",
    "335978476": "Сольвычегодск",
    "336140198": "Рассказово",
    "336140373": "Усмань",
    "336140969": "Октябрьский",
    "336526404": "Русский Камешкир",
    "336526550": "Новосемейкино",
    "337839371": "Сергиевск",
    "339064480": "Заволжье",
    "339961651": "Багаевская",
    "339980655": "Усть-Донецкий",
    "340961881": "Зауральский",
    "340962185": "Еманжелинск",
    "342402547": "Новоржев",
    "346802464": "Илек",
    "352776308": "Покачи",
    "353177210": "Чкаловск",
    "355144185": "Коркино",
    "356772503": "Чертково",
    "360837530": "Кырен",
    "361024504": "Уренгой",
    "361366039": "Георгиевск",
    "364775751": "Чермоз",
    "409086394": "Аскиз",
    "411691832": "Ломоносов",
    "413805673": "Усть-Мая",
    "417406659": "Билимбай",
    "417625373": "Климово",
    "425219949": "Курчатов",
    "425834128": "Оловянная",
    "436918452": "Хандыга",
    "438263230": "Ревда",
    "439191804": "Арти",
    "439555704": "Михайловск",
    "440614116": "Зырянка",
    "441108611": "Чунский",
    "442301557": "Оленёк",
    "443694980": "Новая Чара",
    "444271257": "Батагай",
    "447905020": "Саскылах",
    "447923120": "Жилинда",
    "450854343": "Каратузское",
    "451604149": "Идринское",
    "456844533": "Плёс",
    "457342402": "Самагалтай",
    "457351057": "Хову-Аксы",
    "457351426": "Эрзин",
    "457354065": "Каа-Хем",
    "457453957": "Таштып",
    "457497582": "Белый Яр",
    "457505136": "Бея",
    "457507984": "Усть-Абакан",
    "457894655": "Тазовский",
    "457970089": "Красноселькуп",
    "460482858": "Шерловая Гора",
    "461742306": "Петропавловка",
    "461810345": "Кичера",
    "461812470": "Новый Уоян",
    "462126714": "Баргузин",
    "462129412": "Курумкан",
    "462164030": "Сосново-Озёрское",
    "462167682": "Кижинга",
    "462202929": "Иволгинск",
    "463166446": "Тасеево",
    "465238192": "Казачинское",
    "467758650": "Ольга",
    "467895102": "Поярково",
    "468060289": "Архара",
    "470940629": "Тисуль",
    "471259093": "Балей",
    "472574443": "Кавалерово",
    "472597489": "Дальнегорск",
    "474301764": "Стрелка",
    "475538643": "Новоселово",
    "478808135": "Славск",
    "493613381": "Новопавловск",
    "494895608": "Антропово",
    "494895622": "Парфеньево",
    "512485280": "Мильково",
    "517658001": "Тара",
    "517682881": "Колосовка",
    "518743376": "Амурск",
    "524331733": "Большеречье",
    "587659863": "Товарково",
    "601116310": "Первомайский",
    "612380967": "Нижняя Пойма",
    "628649194": "Пено",
    "653117001": "Белый Яр",
    "654834896": "Барсово",
    "699741885": "Верхнемарково",
    "703166491": "Апастово",
    "764019333": "Куровское",
    "766484437": "Железнодорожный",
    "767068249": "Правдинск",
    "795838932": "Снежногорск",
    "821386596": "Коммунистический",
    "833665925": "Анжеро-Судженск",
    "849853637": "Середейский",
    "884345171": "Дедовичи",
    "892808209": "Белиджи",
    "892808214": "Магарамкент",
    "892835005": "Каякент",
    "904016707": "Кумух",
    "904026153": "Леваши",
    "911587161": "Бежта",
    "911959278": "Северное",
    "914387646": "Нестеров",
    "935566173": "Яшкуль",
    "943663644": "Берёзовка",
    "975940834": "Семендер",
    "976862420": "Восточный",
    "1009135181": "Выборг",
    "1010179630": "Ленинск",
    "1028723760": "Николаевка",
    "1028723768": "Ербогачён",
    "1028723907": "Приаргунск",
    "1028724277": "Охотск",
    "1067034190": "Аскиз",
    "1076441937": "Спасск-Дальний",
    "1077657916": "Ленинский",
    "1201358624": "Бурея",
    "1205639127": "Жирекен",
    "1205649761": "Ксеньевка",
    "1214516212": "Муравленко",
    "1252364676": "Заринск",
    "1282776137": "Кольцово",
    "1345529992": "Янгель",
    "1348895093": "Зеленогорск",
    "1372682490": "Ирбейское",
    "1404369898": "Плесецк",
    "1416600659": "Белоярский",
    "1420696955": "Гусев",
    "1430774947": "Нягань",
    "1480803930": "Межевой",
    "1515028708": "Горняк",
    "1555616147": "Мятлево",
    "1590340571": "Знаменск",
    "1592488757": "Почеп",
    "1607065754": "Берёзово",
    "1625195915": "Нерюнгри",
    "1627107363": "Оленегорск",
    "1641900014": "Мухоршибирь",
    "1645221793": "Кропоткин",
    "1665035561": "Тарбагатай",
    "1798809766": "Протвино",
    "1805732865": "Белебей",
    "1828409507": "Медвежьегорск",
    "1838482543": "Оконешниково",
    "1839536171": "Лахденпохья",
    "1903971517": "Магдагачи",
    "1908737047": "Суздаль",
    "2071063097": "Алдан",
    "2078883019": "Костомукша",
    "2127195931": "Нарткала",
    "2173162616": "Ванино",
    "2288716213": "Алатырь",
    "2291707774": "Чудово",
    "2300530228": "Мяунджа",
    "2414002280": "Тикси",
    "2559814235": "Нижний Куранах",
    "3030450878": "Онгудай",
    "60571493": "Београд",
    "432424985": "Грузия",
    "424313803": "Чехия",
    "424316417": "Молдова",
    "249399300": "Беларусь",
    "1648342273": "Украина",
    "838090640": "Соединенное Королевство",
    "1683325355": "Германия",
    "424315584": "Австралия",
    "424317935": "Соединенные Штаты Америки",
    "424298326": "Турция",
    "424317428": "Узбекистан",
    "424314276": "Таджикистан",
    "249399440": "Туркменистан",
    "424314145": "Индия",
    "424314088": "Пакистан",
    "249399419": "Саудовская Аравия",
    "424310703": "Объединенные Арабские Эмираты",
    "26347360": "Италия",
    "424313451": "Япония",
    "424313760": "Канада",
    "424297647": "Израиль",
    "437146363": "Таиланд",
    "1363947712": "Франция",
    "26847709": "Австрия",
    "466507355": "Абхазия",
    "432425060": "Польша",
    "1504546320": "Швейцария",
    "1684793666": "Бельгия",
    "424310601": "Албания",
    "432424981": "Финляндия",
    "432425019": "Латвия",
    "424297773": "Литва",
    "424313832": "Эстония",
    "432425064": "Румыния",
    "432425040": "Монголия",
    "432424937": "Бангладеш",
    "432424989": "Греция",
    "435981993": "Демократическая Республика Конго",
    "424313903": "Кения",
    "424311353": "Хорватия",
    "249399280": "Аргентина",
    "249399406": "Перу",
    "26847706": "Афганистан",
    "424298311": "Иран",
    "249399459": "Йемен",
    "432425062": "Катар",
    "26036352": "Филиппины",
    "424313428": "Сингапур",
    "248120384": "Новая Зеландия",
    "424298167": "ЮАР",
    "148332300": "Испания",
    "2134362766": "Малайзия",
    "424297217": "Нидерланды"
}

export default Locations;