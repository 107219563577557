import React from "react";
import CompanyUsers from "./company-users";

class CompanyUsersController extends React.Component{
    
    render() {
        return <CompanyUsers></CompanyUsers>
    }
}

export default CompanyUsersController;