import { setClearSelected } from "./filters";
import { clearSearchList } from "./search-text"
const SET_TREE_DATA_ITEMS = "SET-TREE-DATA-ITEMS";
const SET_TREE_SELECTED_ITEMS = "SET-TREE-SELECTED-ITEMS";
const SET_TREE_SELECTED_SUB_ITEMS = "SET-TREE-SELECTED_SUB-ITEMS";
const SET_TREE_SELECTED_UUID = "SET-TREE-SELECTED-UUID";
const SET_TREE_TO_NULL = "SET-TREE-TO-NULL";
const SET_TOPIC_ID = "SET_TOPIC_ID";

const selectTree = {
    data: [],
    selectedItem: 0,
    selectedId: null,
    selectedUUId: null,
    selectedSubItem: null,
    selectedSubUUId: null,
    selectedSubId: null,
    toNull: true
}

const Tree = (state = selectTree, action) => {
    switch(action.type){
        case SET_TREE_DATA_ITEMS:
            let data = {}
            let fi = 0
            if(!state.selectedUUId){
                data = action.data && action.data[0] ? action.data[0] : {id: null, uuid: null}
            }
            else{
                fi = action.data ? action.data.findIndex(f => f.uuid === state.selectedUUId) : 0
                data = fi !== -1 ? action.data[fi] : action.data[0]
            }
            return {...state, selectedItem: fi, data: action.data, selectedId: data.id, selectedUUId: data.uuid}
        case SET_TREE_SELECTED_ITEMS:
            return {...state, selectedItem: action.selectedItem, selectedId: action.selectedId, selectedUUId: action.selectedUUId, selectedSubItem: null, selectedSubId: null, selectedSubUUId: null}
        case SET_TREE_SELECTED_SUB_ITEMS:
            return {...state, selectedSubItem: action.selectedSubItem, selectedId: action.selectedId, selectedSubId: action.selectedSubId, selectedSubUUId: action.selectedSubUUId}
        case SET_TREE_SELECTED_UUID:
            let d = [...state.data];
            let index = null;
            let id = null;
            if(d.length){
                index = d.findIndex(f => f.uuid === action.selectedUUId);
                index = index !== -1 ? index : 0
                id = d[index].id
                
            }
            return {...state, selectedItem: index, selectedId: id, selectedUUId: action.selectedUUId, selectedSubItem: null, selectedSubId: null, selectedSubUUId: null}
        case SET_TREE_TO_NULL:
            return {...state, toNull: action.toNull}
        default:
            return {...state}
    }
}

export default Tree;

export const setToNull = (toNull) => {
    return {
        type: SET_TREE_TO_NULL,
        toNull: toNull
    }
}

export const setTreeDataItems = (data) => {
    return {
        type: SET_TREE_DATA_ITEMS,
        data: data
    }
}

export const setTreeSelectedUUId = (selectedUUId) => {
    return {
        type: SET_TREE_SELECTED_UUID,
        selectedUUId: selectedUUId
    }
}

export const setTreeSelectedItemsc = (selectedItem, selectedUUId, selectedId) => {
    return {
        type: SET_TREE_SELECTED_ITEMS,
        selectedItem: selectedItem,
        selectedId: selectedId,
        selectedUUId: selectedUUId
    }
}

export const setTreeSelectedItemsd = (selectedItem, selectedUUId, selectedId) => {
    return (dispatch) => {
        dispatch(setClearSelected(true));
        dispatch(clearSearchList())
        dispatch(setTreeSelectedItemsc(selectedItem, selectedUUId, selectedId))
    }
}

export const setTreeSelectedItems = (selectedItem, selectedUUId, selectedId) => {
    return (dispatch) => {
        //dispatch(setClearSelected(true))
        dispatch(setTreeSelectedItemsd(selectedItem, selectedUUId, selectedId))
        
    }
}

export const setTreeSelectedSubItems = (selectedSubItem, selectedItem, selectedSubId, selectedSubUUId) => {
    return {
        type: SET_TREE_SELECTED_SUB_ITEMS,
        selectedSubItem: selectedSubItem,
        selectedItem: selectedItem,
        selectedSubId, selectedSubId,
        selectedSubUUId: selectedSubUUId
    }
}