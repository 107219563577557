import React from "react";
import { ReactComponent as DownRightArrowIcon } from '../../assets/icons/down-right-arrow.svg';
import s from "./tree.module.scss";

const Tree = (props) => {
    const selectItem = (item, uuid, id) => {
        if(!props.isFetching)
        props.selectTreeItem(item, uuid, id)
    }

    const selectSubItem = (item, selectedId, id, uuid) => {
        props.setTreeSelectedSubItems(item, selectedId, id, uuid)
    }
    
    /*const itemItems = props.subItems && props.subItems.map((subitem, j) => 
                                                                            {return <div key={j} className={s.TreeItemsItemBlock}>
                                                                                        <DownRightArrowIcon/>
                                                                                        <div 
                                                                                            className={
                                                                                                        props.selectedSubItem !== null && parseInt(props.selectedSubItem) === j 
                                                                                                        ? s.TreeItemsItem+" "+s.SelectedTree 
                                                                                                        : s.TreeItemsItem
                                                                                                      } 
                                                                                            onClick={selectSubItem.bind(this, j, props.selected, subitem.id, subitem.uuid)}
                                                                                        >
                                                                                                {subitem.name}
                                                                                        </div>
                                                                                    </div>});*/
    

    const treeItems = props.items && props.items.map((item, i) => 
                                                                {return <div key={i}>
                                                                            <div 
                                                                                className={props.selected === i ? s.TreeItem+" "+s.SelectedTree : s.TreeItem} 
                                                                                onClick={selectItem.bind(this, i, item.uuid, item.id)}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                            {/*props.selected === i && itemItems*/}
                                                                            {props.selected === i && item.subtopics 
                                                                                            ? item.subtopics.map((k,j) => 
                                                                                                                <div key={j} className={s.TreeItemsItemBlock}>
                                                                                                                    <DownRightArrowIcon/>
                                                                                                                    <div className={
                                                                                                                        props.selectedSubItem !== null && parseInt(props.selectedSubItem) === j 
                                                                                                                        ? s.TreeItemsItem+" "+s.SelectedTree 
                                                                                                                        : s.TreeItemsItem
                                                                                                                      } onClick={selectSubItem.bind(this, j, i, k.id, k.uuid)} >{k.name}</div>
                                                                                                                </div>
                                                                                                                ) 
                                                                                            : null}
                                                                        </div>});
    return (
        <div className={s.Tree}>
            {treeItems}
        </div>
    )
}

export default Tree;