import React from "react";
import DigestItem from "./digest-item";

class DigestItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            topic: this.props.topic,
            selectedRole: this.props.selectedRole
        }
    }

    getUserName = (userId) => {
        let index = this.props.users && this.props.users.length ? this.props.users.findIndex(k => k.id === userId) : -1
        return index !== -1 ? this.props.users[index].login : null
    }

    getTopic = (uuid) => {
        let k = this.props.topics.find((f,i)=>{return f.uuid === uuid}) 
        return k
    }

    componentDidUpdate(){
        if(this.props.selectedRole !== this.state.selectedRole){
            this.setState({
                selectedRole: this.props.selectedRole
            })
        }
    }

    componentWillUnmount(){
        this.props.setSelectedUser(null)
    }

    render(){
        return <DigestItem {...this.props} getUserName={this.getUserName} />
    }
}

export default DigestItemController