import React, { useEffect, useState } from "react";
import ApexChartContainer from "apex-charts/apex-chart-container";
import s from "./topic-comparison.module.scss"

const TopicComparisonPage = (props) => {

    const [showMax, setMax] = useState(props.data && props.data.itemType && props.data.itemType === "ProfileLocations" ? true : false);

    useEffect(() => {
        setMax(props.data && props.data.itemType && props.data.itemType === "ProfileLocations" ? true : false);
    })

    let data = props.data && props.data.topic ? props.data.topic : []//props.data && props.data.values ? props.data.values.map(k => {return {name: k.name, list: k.name, data: k}}) : []
    const getLocations = (location) => {
        return props.locationsDictionary && props.locationsDictionary[location] ? props.locationsDictionary[location] : location
    }

    const setMaximum = (max) => {
        props.setComparisonMax(max)
    }

    let xf = props.data.itemType !== "ProfileLocations" 
                                                        ? {labels: props.data.labels, values: data}
                                                        : {labels: props.data.labels, values: data.map(k => {return {name: getLocations(k.name), data: k.data}})}
    
    let limit = props.data.itemType === "ProfileLocations" ? true : false

    return (
        <div className={s.Comparison}>
            {data && data.length ? <ApexChartContainer setMax={setMaximum} showLimit={showMax ? props.max : null} type={props.data.chartType} height={'600'} width={'100%'} getValue={props.getValue} data={xf} stacked={true} colors={props.data.colors} legend={true} title={props.getValue(props.data.title)} graphicType={'pie'} fields={null} fieldsType={null}/> : <div></div>}
        </div>
        )
}

export default TopicComparisonPage;