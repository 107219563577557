import AccountCabinetController from "./account-cabinet-controller";
import { connect } from 'react-redux';
import { getValue } from "store/languages";
import { updateAccount,setErrorAccount } from "store/account";
import { modalShowToggle } from "store/modal";

let mapStateToProps = (state) => {
    return {
        LanguageItems: state.languages.LanguageItems,
        isAuth: state.authorization.isAuth,
        login: state.Account.login,
        fio: state.Account.fio,
        phone: state.Account.phone,
        email: state.Account.email,
        errorAccount: state.Account.errorAccount
    }
    
}

const AccountCabinetContainer = connect(mapStateToProps, { getValue, updateAccount, modalShowToggle, setErrorAccount })(AccountCabinetController)

export default AccountCabinetContainer;