const SHOW_MODAL_WINDOW = "SHOW-MODAL-WINDOW";
const SET_BUTTON_NAME = "SET-BUTTON-NAME";

let modalWindow = {
    showModal: false,
    name: '',
    callback: null,
    buttonName: 'APPLY',
    title: '', 
    body: ''
}

const Modal = (state = modalWindow, action) => {
    switch(action.type){
        case SHOW_MODAL_WINDOW:
            return { ...state, showModal: action.showModal, name: action.name, callback: action.callback, title: action.title, body: action.body, buttonName: action.buttonName}
        default:
            return state;
    }
}

export default Modal;

export let modalShowToggle = (name, showModal, callback, title, body, buttonName) => {
    return {
        type: SHOW_MODAL_WINDOW,
        showModal: showModal,
        name: name,
        callback: callback,
        title: title,
        body: body,
        buttonName: buttonName
    }
}