import React, { useState, useEffect } from "react";
import s from "./digest-view-item.module.scss"

const DigestViewItem = (props) => {
    const getReportFeedTypeName = (feedTypes) => {
        return feedTypes.map( k => {return {name: props.getValue(k.name), list: k.list}})
    }

    const getSelectedReportFormat = (format) => {
        let r = props.reportFormatTypes.find(f => f.short_name === format)
        return props.getValue(r.title)
    }

    const getPeriodsName = (days) => {
        return days.map((day,i) => {return (i+1)+'.'+props.getValue(day)})
    }

    const getTimes = (times) => {
        return times.map(k => k.split('+')[0])
    }

    const getFeedType = (item, type) => {
        return props.reportFeedTypes.find(f => f[type] === item)
    }

    const [themeName, setThemeName] = useState(props.topic.name);
    const [subtopicName, setsubtopicName] = useState(props.subtopicName);
    const [period, setPeriod] = useState(props.selectedPeriod);
    const [times, setTimes] = useState(props.times ? getTimes(props.times).join(", ") : null);
    const [periods, setPeriods] = useState(getPeriodsName(props.periods));
    const [emails, setEmails] = useState(props.emails ? props.emails : null)
    const [includeAnalytics, setIncludeAnalytics] = useState(props.includeAnalytics);
    const [selectedReportType, setSelectedReportType] = useState(props.selectedReportType ? getSelectedReportFormat(props.selectedReportType) : '');
    const [selectedFilterName, setSelectedFilterName] = useState(props.selectedFilterName);
    const weekDays = props.periods.map((item, i) => { return <div key={i} className={s.periods}><span key={i}>{(i+1)}.</span> <span>{props.getValue(props.weekDays[item])}</span></div>})
    const [reportFeedTypes, setReportFeedTypes] = useState( getReportFeedTypeName(props.reportFeedTypes) );
    const showDays = props.periods.map((item, i) => {return <div key={i} className={s.periods}><span>{i+1}.</span> <span>{item}</span></div>})
    const [selectedFeedType, setSelectedFeedType] = useState( props.reportFeedType ? getFeedType(props.reportFeedType, 'list') : props.reportFeedTypes[0] );

    useEffect(()=>{
        setThemeName(props.topic.name);
        setsubtopicName(props.subtopicName);
        setPeriod(props.selectedPeriod);
        setTimes(props.times ? getTimes(props.times).join(", ") : null);
        setPeriods(getPeriodsName(props.periods));
        setEmails(props.emails ? props.emails : null);
        setIncludeAnalytics(props.includeAnalytics);
        setSelectedReportType(props.selectedReportType ? getSelectedReportFormat(props.selectedReportType) : '');
        setSelectedFilterName(props.selectedFilterName)
        setReportFeedTypes( getReportFeedTypeName(props.reportFeedTypes) );
        setSelectedFeedType( props.reportFeedType ? getFeedType(props.reportFeedType, 'list') : props.reportFeedTypes[0] );
    },[props.selectedTopic, props.subtopicName, props.selectedPeriod, props.selectedFilterName , props.reportFeedType])

    return (
        <div className={s.DigestViewItem}>
            {props.selectedRole === 'ROLE_ADMIN' && props.userName ? <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('ACCOUNT')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{props.userName}</span>
                </div>
            </div> : null}
            <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('TOPIC')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{props.getValue(themeName)}</span>
                </div>
            </div>
            {subtopicName && <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('SUBTHEME_NAME')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{props.getValue(subtopicName)}</span>
                </div>
            </div>}
            <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('PERIODICITY')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{props.getValue(period)}</span>
                </div>
            </div>
            {props.selectedPeriod !== 'DAILY' && <div className={s.ItemRow}>
                <span className={s.labelText}>{props.selectedPeriod === 'WEEKLY' ? props.getValue('DIGESTS_SENDING_TIME') : props.getValue('DIGESTS_SENDING_DATES')}</span>
                <div className={s.ItemShowBlock} style={{display: 'flex', flexDirection: 'column', whiteSpace: 'break-spaces', wordBreak: 'break-all'}}>
                    {props.selectedPeriod === 'WEEKLY' ? weekDays : showDays }
                </div>
            </div>}
            <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('FEED_TYPES')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{props.getValue(selectedFeedType ? selectedFeedType.name : '')}</span>
                </div>
            </div>
            <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('DIGESTS_SENDING_TIME')}</span>
                <div className={s.ItemShowBlock} style={{display: 'flex', flexDirection: 'column'}}>
                    <span>{times}</span>
                </div>
            </div>
            { (emails && emails.length) ? <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('RECIPIENTS_EMAILS')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{emails}</span>
                </div>
            </div> : null }
            {selectedFilterName ? <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('SAVED_FILTERS')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{selectedFilterName}</span>
                </div>
            </div> : null }
            <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('CHARTS')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{includeAnalytics ? props.getValue('CHARTS') : props.getValue('DIGEST_WITHOUT_CHARTS')}</span>
                </div>
            </div>

            <div className={s.ItemRow}>
                <span className={s.labelText}>{props.getValue('DIGEST_FORMAT')}</span>
                <div className={s.ItemShowBlock}>
                    <span>{selectedReportType}</span>
                </div>
            </div>
        </div>
    )
}

export default DigestViewItem;