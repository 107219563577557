import ShortAnlayticChartItemController from "./short-analytic-chart-item-controller";
import { connect } from "react-redux";
import { setShowRightMenu } from "store/side-bar";
import { getValue } from "store/languages";
import { getShortAnalytic } from "store/short-analytic";
import { getMediaTypeData } from "selectors/short-analytic-selector"


let mapStateToProps = (state, props) => {
    return {
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        data: getMediaTypeData(state, props.mediaType, props.topicId),
        language: state.languages.selectedLanguage,
    }
}

export default connect(mapStateToProps, {getValue, setShowRightMenu, getShortAnalytic})(ShortAnlayticChartItemController)