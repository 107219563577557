import React from "react";
import Authorization from "./authorization";

class AuthorizationController extends React.Component{

    showModal = () => {
        this.props.modalShowToggle("ForgotPassword", )
    }

    signin = (email, password) => {
        this.props.signin(email, password)
    }

    render(){
        return (
            <Authorization {...this.props}/>
            
        )
    }
}

export default AuthorizationController