import AdminAccountsController from "./admin-accounts-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { getValue } from 'store/languages';
import { modalShowToggle } from "store/modal"
import {    getSuperadminCompanies, 
            getCompanyAccounts, deleteCompany, 
            attachAccountToCompany, detachAccountFromCompany, makeTopicVisibleToMarker,
            setCompanyId, createCompanyAccount, editCompanyAccount, createAccount, editAccount,
            setAccountId, deleteAccount, activateAccount, blockAccount,
            attachTopicToAccount, detachTopicFromAccount, detachMarkerTopic,
            getAvailableRoles
} from "store/superadmin";
import { getAllTopics} from "store/admin-topics";
import { adminCreateAccount, getAccountTopics } from "store/admin";
import { getAccounts } from "store/admin"
import { getAccountsTable, getCompanyTopicsSelector, getActiveCompanies, getAccountsControllButtons, getAccountsTableItems } from "selectors/superadmin-selector";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { addAccountAuthorities } from "store/authorities"
import { setItemsCount, setCurrentPage, setShowedItemsCount } from "store/paginator";
import { getMyTopics } from "store/theme-settings";
import { getAllTopicsName } from "selectors/theme-selector";
import { getAccountTopicsName } from "selectors/admin-topics-selector"
 
let mapStateToProps = (state) => {
    return {
        companies: state.superadmin.allCompanies,
        activeCompanies: getActiveCompanies(state),
        accounts: getAccountsTable(state),//.superadmin.accounts,
        topics: getAllTopicsName(state),
        topicAccounts: getAccountsTable(state, true),
        companyTopics: getCompanyTopicsSelector(state),
        companyId: state.superadmin.companyId,
        accountId: state.superadmin.accountId,
        topicId: state.superadmin.topicId,
        accountsTableControlButtons: getAccountsControllButtons(state), //.superadmin.accountsTableControlButtons,
        accountsTopicTablerControlButton: state.superadmin.accountsTopicTablerControlButton,
        accountTableHeader: getAccountsTableItems(state),// .superadmin.accountTableHeader,
        isSuperadmin: state.Account.isSuperadmin,
        adminCompanyId: state.Account.companyId,
        accountLocales: state.superadmin.accountLocales,
        roles: state.superadmin.roles,
        selectedAuthorities: state.Authorities.selectedAuthorities,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        accountTopics: getAccountTopicsName(state),
        role: state.Account.role
    }
}

const AdminAccountsContainer =  compose(
    connect(mapStateToProps, {
        getSuperadminCompanies, 
        getCompanyAccounts,
        deleteCompany,
        setCompanyId,
        deleteAccount,
        modalShowToggle,
        getValue,
        setRightMenuComponent,
        setShowRightMenu,
        createCompanyAccount,
        createAccount,
        editCompanyAccount,
        editAccount,
        setRemoveRightMenuComponent,
        setAccountId,
        attachTopicToAccount,
        detachTopicFromAccount,
        activateAccount, 
        blockAccount,
        addAccountAuthorities,
        attachAccountToCompany,
        detachAccountFromCompany,
        detachMarkerTopic,
        makeTopicVisibleToMarker,
        adminCreateAccount,
        getAvailableRoles,
        setItemsCount, 
        setCurrentPage, 
        setShowedItemsCount,
        getMyTopics,
        getAllTopics,
        getAccountTopics
    }),
withAuthRedirect)(AdminAccountsController)

export default AdminAccountsContainer;