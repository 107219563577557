import React from "react";
import s from "./instruction.module.scss"

const SearchTextInstruction = (props) => {
    return (
        <div className={s.Instruction}>
            <p>{props.getValue("TO_SEARCH_ENTER_WORDS")}</p>
            <p>{props.getValue("FOR_MORE_PRECISE")}</p>
            <ul className={s.minusTypeList}>
                <li> *</li>
                <li> ?</li>
                <li> ~/~1/~2</li>
                <li> "..."~N</li>
                <li> {props.getValue("EXCLUSIVE")} ({props.getValue("NEGATIVE_2")})</li>
                <li>{props.getValue("SEARCH_HIGHLIGHT")}
                    <ul>
                        <li>*</li>
                        <li>?</li>
                        <li>~/~1/~2</li>
                        <li>"..."~N</li>
                    </ul>
                </li>
            </ul>
            <h4>{props.getValue("EXAMPLES_OF_USING_SEARCH_OPERATORS")}</h4>
            <p>{props.getValue("FOLLOWING_SEARCH_OPERATORS")} *, ?, ~ ({props.getValue("FUZZY_SEARCH")}) {props.getValue("AND")} ~N ({props.getValue("PRESUMPTIVE_SEARCH")}):</p>
            <ul className={s.minusTypeList}>
                <li><strong>\*</strong> {props.getValue("CAN_BE_USED_A_WORD")}</li>
                <ul>
                    <li>test* {props.getValue("WILL_RETURN")} testing, tested {props.getValue("AND")} {props.getValue("ETC")}</li>
                    <li>te*t {props.getValue("WILL_RETURN")} test, tent, tenant</li>
                    <li>*est - test, best, protest, latest</li>
                </ul>
                <li><strong>?</strong> {props.getValue("USED_TO_MATCH_FIXED")}</li>
                <ul>
                    <li>{props.getValue("RETURNED_CORONAVIRUS")}</li>
                    <li>{props.getValue("RETURNED_AMERICA")}</li>
                </ul>
                <li><strong>~N ({props.getValue("PRESUMPTIVE_SEARCH")})</strong> {props.getValue("USED_AT_THE_END")}</li>
                <ul>
                    <li>{props.getValue("GAVE_AN_ORDER")}</li>
                    <li>{props.getValue("QUERIES_FORMULATED_INDIVIDUAL")}</li>
                    <li>{props.getValue("SEARCH_OPERATORS_COMBINED")}</li>
                </ul>
            </ul>            
        </div>
    )
}

export default SearchTextInstruction;