import { SuperadminService } from "connection/superadmin-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_ADMIN_TOPICS = "SET-ADMIN-TOPICS";
const SET_IS_TOPICS_FETCHING = "SET-IS-TOPICS-FETCHING";
const SET_TOPIC_MARKERS = "SET-TOPIC-MARKERS";
const SET_SEARCH_TEXT = "SET-SEARCH-TEXT";
const SET_START_SEARCH = "SET-START-SEARCH";

const AdminTopicsData = {
    topics: null,
    tableHeader: ['#', 'TOPIC_NAME', 'COMPANY', 'CREATED_AT', 'UPDATED_AT', 'KEYWORDS', 'STOPWORDS'],
    controlButtons: ["add"],
    page: 0,
    isTopicsFetching: false,
    topicMarkers: null,
    searchText: '',
    startSearch: false
}

const AdminTopics = (state = AdminTopicsData, action) => {
    switch(action.type){
        case SET_ADMIN_TOPICS:
            let topics = action.topics
            return {...state, topics: topics, startSearch: false}
        case SET_IS_TOPICS_FETCHING:
            return {...state, isTopicsFetching: action.isTopicsFetching}
        case SET_TOPIC_MARKERS:
            return {...state, topicMarkers: action.topicMarkers}
        case SET_SEARCH_TEXT:
            return {...state, searchText: action.searchText}
        case SET_START_SEARCH:
            return {...state, startSearch: action.startSearch}
        default:
            return {...state}
    }
}

export default AdminTopics;

export const setTopicsIsFethcing = (isTopicsFetching) => {
    return {
        type: SET_IS_TOPICS_FETCHING,
        isTopicsFetching: isTopicsFetching
    }
}

export const setAdminTopics = (topics) => {
    return{
        type: SET_ADMIN_TOPICS,
        topics: topics
    }
}

export const setTopicMarkers = (topicMarkers) => {
    return {
        type: SET_TOPIC_MARKERS,
        topicMarkers: topicMarkers
    }
}

export const setSearchText = (searchText) => {
    return {
        type: SET_SEARCH_TEXT,
        searchList: searchText
    }
}

export const setStartSearch = (startSearch) => {

    return {
        type: SET_START_SEARCH,
        startSearch: startSearch
    }
}

export const getAllTopics = (pageIndex, pageSize, status, search = null) => {
    let s = '%'
    if(!search || search === '')
        s = '%'
    else{
        s = '%'+search+'%'
        pageIndex = pageIndex;
        pageSize = pageSize;
    }

    return (dispatch) => {
            dispatch(setTopicsIsFethcing(true))
            dispatch(setStartSearch(false))
            SuperadminService.getAllTopicsSearch(pageIndex, pageSize, status, s).then(
                data => {
                    
                    dispatch(setAdminTopics(data));
                    dispatch(setTopicsIsFethcing(false))
                }
            ).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            });
        

    }
}

export const getTopicMarkers = (topicId) => {
    return (dispatch) => {
        SuperadminService.getTopicMarkers(topicId).then(
            data => {
                dispatch(setTopicMarkers(data))
            }
        )
    }
}

export const detachTopicMarker = (accountId, topicId) => {
    return (dispatch) => {
        SuperadminService.detachMarkerTopic(accountId, topicId).then(
            data => {
                dispatch(getTopicMarkers(topicId))
            }
        )
    }
}