import MigrationCreateItemController from "./migration-create-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getMigrationOperations, setMigrationId, setMigrationOperation, getMigrationNumFound, getTopic, setGetNumFound, getCompanyTopics, getCompanies, startMigration, pauseMigration, resumeMigration, retryMigration} from "store/migration";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";

let mapStateToProps = (state) => {
    return {
        companies: state.Migration.companies,
        companyTopics: state.Migration.companyTopics,
        numfound: state.Migration.numfound,
        topic: state.Migration.topic,
        getNumfound: state.Migration.getNumfound,
        collections: state.Migration.collections
    }
}

const MigrationCreateItemContainer = connect(mapStateToProps, { 
    getValue, 
    getMigrationOperations, 
    setMigrationId,
    setMigrationOperation,
    getCompanyTopics,
    getMigrationNumFound,
    getTopic,
    setGetNumFound,
    getCompanies,
    startMigration,
    setRightMenuComponent, 
    setShowRightMenu, 
    setRemoveRightMenuComponent,
    pauseMigration, 
    resumeMigration, 
    retryMigration
})
(MigrationCreateItemController);

export default MigrationCreateItemContainer;
