import MainPageController from "./main-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { getMe } from "store/account";
import { getTopicDocuments, SentimentsOfList, setRemoveItemFromTags, setDocumentStatus, resetCounter, setStorageData, updateSentimentByFilter } from "store/storage";
import { setLeftMenuComponent, setRemoveLeftMenuComponent, setShowLeftMenu, setRightMenuComponent, setRemoveRightMenuComponent, setShowRightMenu  } from 'store/side-bar';
import { getTopicDropdownFromState, getTopics, getSubtopicsFromState, getTopicsFiltersSelectorTree } from "selectors/theme-selector";
import { setThemes } from "store/theme";
import { getValue } from 'store/languages';
import { getMyTopics, setThemeTableData } from "store/theme-settings";
import { getSubtopics, setSubtopicsUpdated, setSubtopicItem, setSubtopics } from "store/topic-item";
import { setShowPanel } from "store/header";
import { setSelectedMenu } from "store/settings-menu";
import { modalShowToggle } from "store/modal";
import { getCompanyTags } from 'store/tags';
import { setGrouping, getGroupingByPeriod, setStartDate, setEndDate } from "store/calendar";
import { clearSearchList } from 'store/search-text';
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setAddSelectedMyFilter, setMySelectedTopicsFilter, setClearSelected, setMySelectedFilter, setSelectedTopics as setSelectedFiltersTopics, filtersChanged, getTopicsFilters, getBlackListFilters, getFavouriteManualFilters, getFavouriteAutoFilters, getDeletedFilters, setPageName, setException, setSelectedMyFilterMenu } from "store/filters";
import { setIsReturnedBack } from "store/return-back-button";
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";
import { setSelectedTopics, setSelectedTopicsAll, setSelectedSubtopicsAll  } from "store/topic-menu"

let mapStateToProps = (state) => {

    return {
        role: state.Account.role,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        topicData: getTopics(state),
        topics: getTopicsFiltersSelectorTree(state),
        subtopics: getSubtopicsFromState(state),
        data: state.storage.data,
        themes: state.themesPage.themes,
        checkedThemes: state.themesPage.checkedThemes,
        leftMenuComponent: state.SideBarMenu.leftMenuComponent,
        selectedTopic: state.Tree.selectedItem,
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        isSubtopicsUpdated: state.TopicItem.isSubtopicsUpdated,
        selectedId: state.Tree.selectedId,
        selectedUUId: state.Tree.selectedUUId,
        selectedPostItems: state.storage.selectedPostItems,
        selectedAllList: state.storage.selectedAllList,
        documentListTags: state.Tags.documentListTags,
        selectedPostTags: state.storage.selectedPostTags,
        companyId: state.Account.companyId,
        tags: state.Tags.tags,
        updatedList: state.storage.updatedList,
        selectedMyFilter: state.Filters.selectedMyFilter,
        selectedItems: state.TopicMenu.selectedTopics,
        isChanged: state.Filters.isChanged,
        selectedTopics: getSelectedTopicsUUID(state),
        selectedTopicsItems: state.TopicMenu.selectedTopics,
        //subtopics: getSelectedSubTopicsUUID(state),
        /*selectedTopics: state.Filters.selectedTopics,*/
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        pageName: state.Filters.pageName,
        selectedLanguage: state.languages.selectedLanguage,
        isReturnedBack: state.ReturnBack.isReturnedBack,
        returnedPageName: state.ReturnBack.pageName,
    }
}

export default compose(
    connect(mapStateToProps, {getMe, 
                            getTopicDocuments, 
                            setLeftMenuComponent, 
                            setRemoveLeftMenuComponent, 
                            setShowLeftMenu, 
                            setThemes, 
                            getValue, 
                            getMyTopics, 
                            setRightMenuComponent, 
                            setRemoveRightMenuComponent, 
                            setShowRightMenu,
                            getSubtopics,
                            setSubtopicsUpdated,
                            setShowPanel,
                            setThemeTableData,
                            setSubtopicItem,
                            setSubtopics,
                            setSelectedMenu,
                            SentimentsOfList,
                            modalShowToggle,
                            getCompanyTags,
                            setRemoveItemFromTags,
                            setDocumentStatus,
                            setAddSelectedMyFilter,
                            setMySelectedFilter,
                            setMySelectedTopicsFilter,
                            setException,
                            setClearSelected,
                            setSelectedFiltersTopics,

                            setSelectedTopics, setSelectedTopicsAll, setSelectedSubtopicsAll,
                            filtersChanged, 
                            getTopicsFilters, 
                            getBlackListFilters,
                            getDeletedFilters,
                            getFavouriteManualFilters,
                            getFavouriteAutoFilters,
                            resetCounter,
                            setPageName,
                            setStorageData,
                            updateSentimentByFilter,
                            setSelectedMyFilterMenu,
                            setGrouping,
                            clearSearchList,
                            getGroupingByPeriod, setStartDate, setEndDate,
                            setIsReturnedBack
                            }),
    withAuthRedirect
)(MainPageController)