import React, { useState, useEffect} from "react";
import s from "./dropdown.module.scss";
import useComponentVisible from "hooks/useComponentVisible";
import { ReactComponent as AngleUp } from 'assets/icons/angle-up.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';

const Dropdown = (props) => {

    let ButtonImage = props.icon === 'icon' && props.items.length ? props.items[0].icon : null;
    let SetName = "";

    let setSelected = (selectedItem, type) => {
        props.callback(type, selectedItem);
        setIsComponentVisible(false)
    }

    const [styles, setStyle] = useState(props.style ? props.style : null);

    useEffect(() => {
        setStyle(props.style ? props.style : null);
    })

    const getSeletedName = (selected) => {
        return props.items && props.items.find((k)=>{return k.name === selected})
    }
    

    let buttonItems = props.items.map((b, i)=>{
        let Img = b.icon ? b.icon : null;
        
        if(props.selected === b.short_name) {
            ButtonImage = props.icon === 'icon' && b.icon;
            SetName = b.title;
        }
        let btn =   <button 
                        className={props.selected === b.short_name ? s.itemButton+" "+s.selected : s.itemButton} key={i}
                        onClick={setSelected.bind(this, b.short_name, props.type)}
                        
                    >
            
            {props.icon === 'icon' && <Img />}
            <span>{b.title}</span>
        </button>
        return btn;
    })
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);
    //props.callback(buttonItems);

    return (
        <div className={s.Dropdown} ref={ref}>
            <button title={SetName} className={s.Header} onClick={()=> setIsComponentVisible(!isComponentVisible)} style={{background: props.background, borderColor: props.borderColor}}>
                {props.icon === 'icon' ? <ButtonImage /> : <span style={{color: props.color}}>{SetName}</span>}
                {isComponentVisible ? <AngleDown /> : <AngleUp />}
            </button>
            {isComponentVisible && <div className={s.Body} style={{...styles}}>
                {buttonItems}
            </div>}
        </div>
    )
}

export default Dropdown;