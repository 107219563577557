import { AnalyticService } from "../connection/analytic-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_SHORT_ANALYTIC_DATA = "SET-SHORT-ANALYTIC-DATA";
const SET_SHORT_ANALYTIC_DATA_RANGE = "SET-SHORT-ANALYTIC-DATA-RANGE";
const SET_CLEAR_DATA = "SET-CLEAR-DATA";
const SET_IS_SHORT_ANALYTIC_IS_FETCHING = "SET-IS-SHORT-ANALYTIC-IS-FETCHING"

const headerItems = [
    {
        title: 'ALPHABETICALLY_A-Z_↓',
        name: 'ALPHABETIC ASC',
        short_name: 'ALPHABETIC DESC'
    },
    {
        title: 'ALPHABETICALLY_Z-A_↑',
        name: 'ALPHABETIC ASC',
        short_name: 'ALPHABETIC ASC'
    },
    {
        title: 'DATE_UPDATED_AT_↓',
        name: 'UPDATED_AT DESC',
        short_name: 'UPDATED_AT DESC'
    },
    {
        title: 'DATE_UPDATED_AT_↑',
        name: 'UPDATED_AT ASC',
        short_name: 'UPDATED_AT ASC'
    },
    {
        title: 'DATE_CREATED_AT_↓',
        name: 'CREATED_AT DESC',
        short_name: 'CREATED_AT DESC'
    },
    {
        title: 'DATE_CREATED_AT_↑',
        name: 'CREATED_AT ASC',
        short_name: 'CREATED_AT ASC'
    }
]

let shortAnalyticData = {
    headerItems: headerItems,
    dataTotal: {},
    dataMedia: {},
    dataSocial: {},
    dataRange: [],
    isShortAnalyticFetching: false
}   

const ShortAnalytic = (state = shortAnalyticData, action) => {
    switch(action.type){
        case SET_SHORT_ANALYTIC_DATA:
            let analyticData = [];
            switch(action.mediatype){
                case 'TOTAL':
                    analyticData = state.dataTotal;
                    analyticData[action.data.topicId] = action.data
                    return {...state, dataTotal: analyticData}
                case 'MASS_MEDIA':
                    analyticData = state.dataMedia;
                    analyticData[action.data.topicId] = action.data
                    return {...state, dataMedia: analyticData}
                case 'SOCIAL_MEDIA':
                    analyticData = state.dataSocial;
                    analyticData[action.data.topicId] = action.data
                    return {...state, dataSocial: analyticData}
            }
            break;
        case SET_SHORT_ANALYTIC_DATA_RANGE:

            let analyticRange = [];
            analyticRange = state.dataRange;
            analyticRange.push(action.dataRange);
            return {...state, dataRange: [...new Map(analyticRange.map(item =>[item['topicId'], item])).values()]}
        case SET_IS_SHORT_ANALYTIC_IS_FETCHING:
            return {...state, isShortAnalyticFetching: action.isShortAnalyticFetching}
        default:
            return {...state}
    }
}

export default ShortAnalytic;

export const setShortAnalyticData = (data, mediatype) => {
    return {
        type: SET_SHORT_ANALYTIC_DATA,
        data: data,
        mediatype, mediatype
    }
}

export const setShortAnalyticDataRange = (dataRange) => {
    return {
        type: SET_SHORT_ANALYTIC_DATA_RANGE,
        dataRange: dataRange
    }
}

export const getShortAnalytic = (topicId, mediatype) => {
    return (dispatch) => {
        dispatch(setIsShortAnalyticFetching(true));
        AnalyticService.getShortAnalytic(topicId, mediatype).then(data=>{
            dispatch(setShortAnalyticData({...data, topicId: topicId}, mediatype));
            dispatch(setIsShortAnalyticFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            setIsShortAnalyticFetching(false);
        })
    }
}

export const getShortAnalyticRange = (topicId) => {
    
    return (dispatch) => {
        dispatch(setIsShortAnalyticFetching(true));
        AnalyticService.getAnalyticRange(topicId).then(data=>{
            dispatch(setShortAnalyticDataRange({data: [...data], topicId: topicId}));
            dispatch(setIsShortAnalyticFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}));
            dispatch(setIsShortAnalyticFetching(false));
        })
    }
}

export const setIsShortAnalyticFetching = (isShortAnalyticFetching) => {
    return {
        type: SET_IS_SHORT_ANALYTIC_IS_FETCHING,
        isShortAnalyticFetching: isShortAnalyticFetching
    }
}