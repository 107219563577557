import React from "react";
import SettingsMenu from "./settings-menu";

class SettingsMenuController extends React.Component{

    constructor(props){
        super(props);
        /*this.state = {
            selected: props.startPage ? props.startPage : props.selected
        }*/
    }

    componentDidMount(){
        //this.props.setSelectedMenu(2)
    }

    setSelectedMenu = (selectedItem) => {
        this.props.setSelectedMenu(selectedItem);
        this.setState({
            selected: selectedItem
        })
        this.props.setComponent(null);
    }

    componentWillUnmount(){
        if(this.props.settingMenuToNUll)
            this.props.setSelectedMenuItem(0)
    }

    render(){
        return <SettingsMenu 
                            items={this.props.items} 
                            setSelectedMenu={this.props.setSelectedMenuItem} 
                            selected={this.props.selected}
                            showLeftMenu={this.props.showLeftMenu}
                            getValue={this.props.getValue}
                            role={this.props.role}
                            adminMode={this.props.adminMode}
                            isFetching={this.props.isFetching}
                            isComparisonFetching={this.props.isComparisonFetching}
                            />
    }

}

export default SettingsMenuController;