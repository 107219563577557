import SwitchAdminModeController from "./switch-admin-mode-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { setSelectedRole } from "store/switch-admin-mode";
import { getAccounts } from "store/admin";

let mapStateToProps = (state) => {
    
    return {
        companies: state.superadmin.companies,
        users: state.superadmin.users,
        companyId: state.superadmin.companyId,
        selectedLanguage: state.languages.selectedLanguage,
        selectedRole: state.AdminMode.selectedRole
    }
}

const SwitchAdminModeContainer =  compose(
    connect(mapStateToProps, {
        getValue,
        setSelectedRole,
        getAccounts,
        setRightMenuComponent, 
        setShowRightMenu, 
        setRemoveRightMenuComponent
    }),
withAuthRedirect)(SwitchAdminModeController)

export default SwitchAdminModeContainer;