import SettingsMenuController from "./settings-menu-controller";
import SettingsMenu from "./settings-menu";
import { connect } from "react-redux";
import { setSelectedMenuItem } from "../../../store/settings-menu"
import { setComponent } from "../../../store/settings";
import { getValue } from "../../../store/languages"

let mapStateToProps = (state) => {
    return {
        //items: state.settingsPageMenu.items,
        selected: state.settingsPageMenu.selectedMenuItem,
        showLeftMenu: state.SideBarMenu.showLeftMenu,
        selectedLanguage: state.languages.selectedLanguage,
        settingMenuToNUll: state.settingsPageMenu.settingMenuToNUll,
        role: state.Account.role,
        isFetching: state.ExtendedAnalytic.isFetching,
        isComparisonFetching: state.Comparison.isComparisonFetching,
    }
}

export default connect(mapStateToProps, {setSelectedMenuItem, setComponent, getValue})(SettingsMenu)