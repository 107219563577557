import React, {useState, useEffect} from "react";
import s from "./report-form.module.scss";

const ReportForm = (props) => {

    const [items, setItems] = useState(props.data);
    const [selected, setSelected] = useState(props.selectedReport);

    useEffect(() => {
        setItems(props.data);
        setSelected(props.selectedReport);
    }, [props])

    const drawPage = (items, i) => {
        return (
            <div className={s.FormPage} key={i}>
                {items}
            </div>
        )
    }

    const drawLine = (element) => {
        return (
            <div className={s.ReportLine}>{ element }</div>
        )
    }

    const drawColontitle = (text, index) => {return <div className={s.FormItem+" "+s.Colontitle} key={index}>{text}</div>}

    const drawTableOfContents = <div className={s.FormItem+" "+s.TableOfContents} key="1">{props.getValue('TABLE_OF_CONTENTS')}</div>
    
    const drawReportDesign = <div className={s.FormItem+" "+s.Colontitle}>{props.getValue('REPORT_DESIGN')}</div>

    const drawReportElement = () => {<div className={s.FormItem+" "+s.ReportElement} >{props.getValue('REPORT_ELEMENT')}</div>}

    const documentElements = (elements, type) => {
        let ret = []
        let d = []
        elements.forEach((element, i) => {
            if(selected && selected[type].indexOf(element) !== -1){
                ret.push(drawColontitle(props.getValue(element), i))
                
                if(i>0 && i%9 === 0 || i === elements.length-1){
                    
                    d.push(drawPage(ret, 10+i))
                    ret = []
                }
            }

            
        });
        return d
    }
    let docs = items.documentElements && selected.documentElements ? documentElements(items.documentElements, 'documentElements') : []
    let segments = items.segments && selected.segments ? documentElements(items.segments, 'segments') : []
 
    return (
        <div className={s.ReportForm}>
            <span className={s.ReportTitle}>{props.getValue('REPORT_PREVIEW_WINDOW')}</span>
            {/*<div className={s.ReportFormBlock}>
                {drawPage([drawColontitle(props.getValue('TOPIC_NAME')), drawColontitle(props.getValue('REQUEST_DATE')), drawColontitle(props.getValue('COLONTITLES')), drawColontitle(props.getValue('COLONTITLES')), drawColontitle(props.getValue('COLONTITLES'))], 0)}
                {drawPage([drawTableOfContents], 1)}
                {drawPage([drawColontitle(props.getValue('MENTIONS'))], 2)}
                {drawPage([drawColontitle(props.getValue('COLONTITLES')), drawLine([drawReportElement(), drawReportElement()]), drawColontitle(props.getValue('COLONTITLES'))], 3)}
    </div>*/}
            <div className={s.ReportFormBlock}>
                <span className={s.ReportSubTitle}>{props.getValue('REPORT_DESIGN')}</span>
                    {segments}
                <span className={s.ReportSubTitle}>{props.getValue('PUBLICATION_DETAILS')}</span>
                    {docs}
            </div>
        </div>

    )
}

export default ReportForm;