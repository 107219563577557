import AuthorizationController from "./authorization-controller";
import { withAuthRedirectToMain } from "../../hoc/AuthRedirectToMain";
import { connect } from "react-redux"
import { compose } from "redux";
import {setNewPageNumber, signin, signup, resetPassword, setErrorInResetPassword, setErrorInRegistration, setErrorInLogin, setRegistrationIsSend} from "../../store/authorization"; 
import { getValue, setSelectedLanguage } from '../../store/languages';
import { modalShowToggle } from "../../store/modal";

let mapStateToProps = (state) => {
    return {
        pageTypeNumber: state.authorization.pageTypeNumber,
        language: state.languages.selectedLanguage,
        errorInResetPassword: state.authorization.errorInResetPassword,
        errorInRegistration: state.authorization.registrationError,
        loginError: state.authorization.loginError,
        registrationIsSend: state.authorization.registrationIsSend,
        access_token: state.authorization.access_token,
        unAuthorizedLanguage: state.languages.unAuthorizedLanguage
    }
}

const AuthorizationContainer = compose(
    connect(mapStateToProps, {setNewPageNumber, signin, signup, getValue, setSelectedLanguage, modalShowToggle, resetPassword, setErrorInResetPassword, setErrorInRegistration, setErrorInLogin, setRegistrationIsSend}),
    withAuthRedirectToMain
)(AuthorizationController)
export default AuthorizationContainer

