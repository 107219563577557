import React from "react";
import ShortAnalyticLineChart from "./short-analytic-line-chart";

class ShortAnlayticLineChartController extends React.Component{

    componentDidMount(){
        if(this.props.topicId){
            this.getTopicAnalyticRange(this.props.topicId)
        }
    }

    getTopicAnalyticRange = (uuid) => {
        this.props.getShortAnalyticRange(uuid)
    }

    getDataRange = (topicId) => {
        return {...this.props.dataRange.filter(k => k.topicId === topicId)}[0]; 
    }

    componentDidUpdate(){
        
    }

    render(){
        return (this.props.dataRange && this.props.dataRange.values) && <ShortAnalyticLineChart {...this.props} topicRange={this.getDataRange(this.props.topicId)}/>
    }

}

export default ShortAnlayticLineChartController;