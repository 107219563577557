import { createSelector } from "reselect";
import moment from "moment";
import { getFormatDate } from "service/format-date";

const companyTopics = (state, type) => {
    if(type)
        return state.superadmin.accountTopics
    else
        return state.superadmin.companyTopics;
}

const accountsControlButtons = (state) => {
    if(state.Account.isSuperadmin && !state.superadmin.companyId){
        return state.superadmin.accountsTableControlButtons
    }
    else{
        return state.superadmin.accountsControlButtons
    }
}

const adminTopics = (state) =>{
    return state.AdminTopics.topics
}

const adminMarkers = (state) =>{
    return state.AdminMarkers.markers
}

const markerTopics = (state) =>{
    return state.AdminMarkers.markerTopics
}

const companies = (state) => {
    return state.superadmin.companies
}

const accountTableItems = (state) => {
    if(state.Account.isSuperadmin && !state.superadmin.companyId){
        return state.superadmin.accountAdminTableHeader
    }
    else{
        return state.superadmin.accountTableHeader
    }
    
}

const accounts = (state, type) => {
    let isSuper = false;
    if(state.Account.isSuperadmin && !state.superadmin.companyId){
        isSuper = true
    }
    if(type)
        return {data: state.superadmin.topicAccounts, isSuperadmin: isSuper}
    else
        return {data: state.superadmin.accounts, isSuperadmin: isSuper}
}

const adminAccounts = (state, type) => {
    return state.superadmin.adminAccounts
}

const companyId = (state) => {
    if(state.Account.role === 'ROLE_SUPERADMIN'){
        return state.superadmin.companyId
    }
    else{
        return state.Account.companyId
    }
}

const formatDate = (date) =>{
    return getFormatDate(date, "DD.MM.YY HH:mm");
    //return moment(date).format("DD.MM.YY HH:mm");
}

export const getAccountsControllButtons = createSelector(accountsControlButtons, (controlButtons) => {
    return controlButtons
})

export const getAccountsTableItems = createSelector(accountTableItems, (tableItems) => {
    return tableItems
})

export const getCompaniesTable = createSelector(companies, (companies) => {
    let companiesList = []
    if(companies && companies.length){
        companiesList = companies.map( k => {
            return  [k.id, k.name, k.bin, k.status, k.expiryDate ? formatDate(k.expiryDate) : null, k.maxNumTopics]     
        });
    }
    return {data: companiesList, companies: companies}
})

export const getActiveCompanies = createSelector(companies, (companies) => {
    let activeCompaniesList = []
    if(companies && companies.length){
        activeCompaniesList = companies.filter( (k) => { if(k.status === 'ACTIVE') return k});
    }
    return activeCompaniesList
})

export const getAccountsOnlyName = createSelector(accounts, (accounts) => {
    
    if(accounts && accounts.data && accounts.data.length){
        return accounts.data.map(account => account.id)
    }
})

export const getAccountsName = createSelector(accounts, (accounts) => {
    let a = []
    if(accounts && accounts.data && accounts.data.length){
        return accounts.data.map(account => { return {...account, name: account.login}})
    }
})

export const getActiveAccountsName = createSelector(accounts, (accounts) => {
    
    let a = []
    if(accounts && accounts.data && accounts.data.length){
        accounts.data.forEach((account, i) => {
            if(account.status === 'ACTIVE'){
                a.push({id: account.id, name: account.login, role: account.role})
            }
        });
        //companies.filter( (k) => { if(k.status === 'ACTIVE') return k});
        //a = accounts.filter(account => { if(account.status === 'ACTIVE') return {...account, name: account.login}})
    }
    return a
})

export const getActiveAdminAccountsName = createSelector(adminAccounts, (accounts) => {
    let a = []
    if(accounts && accounts.content){
        accounts.content.forEach((account, i) => {
            if(account.status === 'ACTIVE'){
                a.push({id: account.id, name: account.login, role: account.role})
            }
        });
    }
    return a
})

export const getAccountsTable = createSelector(accounts, (accounts) => {
    let accountsList = []
    
    if(accounts && accounts.data && accounts.data.length){
        accountsList = accounts.data.map( k => {
            return accounts.isSuperadmin ? [k.id, k.login, k.companyId, k.fio, k.email, k.role, k.status, k.createdAt ? formatDate(k.createdAt) : null]
                                        : [k.id, k.login, k.fio, k.email, k.role, k.status, k.createdAt ? formatDate(k.createdAt) : null]     
        });
    }
    return {data: accountsList, accounts: accounts.data}
})

export const getCompanyTopics = createSelector(companyTopics, (topics) => {
    let companyTopics = []
    if(topics && topics.length){
        return topics
    }
})

export const getCompanyTopicsSelector = createSelector(companyTopics, (topics) => {
    let companyTopics = []
    if(topics && topics.length){
        companyTopics = topics.map( k => {
            return  [k.id, k.name, k.keywords, k.stopwords, k.status, k.subtopics ? k.subtopics.map(k => k.name).join(',') : '', formatDate(k.createdAt), formatDate(k.updatedAt), k.uuid]     
        });
    }
    return {data: companyTopics, topics: topics}
})

export const getAllTopics = createSelector(adminTopics, (topics) => {
    let allTopics = topics ? topics : {}
    return allTopics
})

export const getAllAdminTopics = createSelector(adminTopics, (topics) => {
    let allTopics = topics && topics.content ? topics : {}
    return allTopics.content
})

export const getMarkerTopicsId = createSelector(markerTopics, (topics) => {
    return topics && topics.content ? topics.content.map(k => k.id) : []
})

export const getAllAdminTopicsName = createSelector(adminTopics, (topics) => {
    return topics.content.map(k => k.id)
})

export const getAdminMarkersName = createSelector(adminMarkers, (markers) => {
    return markers && markers.content ? markers.content.map(k => k.id) : []
})
//sers.filter(user => { user.name = user.login; return user.status === 'ACTIVE'})
export const getActiveAdminMarkersName = createSelector(adminMarkers, (markers) => {
    let ret = markers && markers.content ? markers.content.filter(k => {return k.status === 'ACTIVE'}).map(k => {return {id: k.id, name: k.login}}) : []
    return ret
})

export const getAllMarkersSelector = createSelector(adminMarkers, (markers) => {

    let allMarkers = markers ? markers : {}
    let contents = []
    let m = {}
    if(allMarkers && allMarkers.content){
        contents = allMarkers.content.map((k,i)=>{
            return {login: k.login, fio: k.fio, email: k.email, createdAt: k.createdAt, status: k.status, phone: k.phone, id: k.id, role: k.role, companyId: k.companyId}
        })
    }
    allMarkers.content = contents
    return allMarkers
       // return allMarkers/*.content.map((k,i)=>{
       //     return {login: k.login, fio: k.fio, email: k.email, createdAt: k.createdAt, status: k.status}
      //  });*/
})