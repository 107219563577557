import filters, { setClearSelected } from "./filters";
import { setTreeSelectedSubItems } from "./tree"
import Menu from "assets/menu/menu";

const SET_SELECTED_MENU = "SET-SELECTED-MENU";
const SET_MENUS = "SET_MENUS";
const SET_SETTINGS_MENU_TO_NULL = "SET-SETTINGS-MENU-TO-NULL";

let settingsMenu = {
    items: [],
    selectedMenuItem: 0,//Menu.AnlyticPages
    settingMenuToNUll: true
}

const settingsPageMenu = (state = settingsMenu, action) => {
    
    switch(action.type){
        case SET_SELECTED_MENU:
            return {...state, selectedMenuItem: action.selected}
        case SET_MENUS:
            return {...state, items: action.items}
        case SET_SETTINGS_MENU_TO_NULL:
            return {...state, settingMenuToNUll: action.settingMenuToNUll, selectedMenuItem: 0}
        default:
            return {...state}
    }
}

export default settingsPageMenu;

export const setSettingsMenuToNull = (settingMenuToNUll) => {
    return {
        type: SET_SETTINGS_MENU_TO_NULL,
        settingMenuToNUll: settingMenuToNUll
    }
}

export let setMenus = (menus) => {
    return {
        type: SET_MENUS,
        items: menus
    }
}

export const setSelectedMenuItem = (selected) => {
    return {
        type: SET_SELECTED_MENU,
        selected: selected
    }
}

export const setSelectedMenuClear = (selected) => {
    return (dispatch) => {
        dispatch(setSelectedMenuItem(selected))
    }
}

export const setSelectedMenu = (selected) => {
    return (dispatch) => {
        dispatch(setSelectedMenuItem(selected))
    }
}

