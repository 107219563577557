import React from 'react';
import './index.scss';
import store from './store/store';
import App from './App';

import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

let reRender = (state) => {
    
    root.render(
        //<React.StrictMode>
            <Provider store={store}>
                <App store={store.getState()}/>
            </Provider>
        //</React.StrictMode>
    )
}

reRender(store.getState());

store.subscribe(()=>{
    let state = store.getState();
    /*theme = state.mainThemes.selectedTheme.toLowerCase();
    languagesPack = require(`./assets/styles/colors_${theme}.scss`).default;*/
    reRender(state)
});

