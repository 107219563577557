import { AdminService } from "connection/admin-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_ADMIN_USERS = "SET-ADMIN-USERS";
const SET_ADMIN_COMPANY_ID = "SET0COMPANY-ID";
const SET_ADMIN_COMPANY = "SET-ADMIN-COMPANY";
const SET_ACCOUNT_TOPICS = "SET-ACCOUNT-TOPICS";

const Admin = {
    users: null,
    companyId: null,
    company: null,
    topics: null,
    accountTopics: []
}

const admin = (state = Admin, action) => {
    switch(action.type){
        case SET_ADMIN_USERS:
            let users = action.users ? action.users : []
            return {...state, users: users}
        case SET_ADMIN_COMPANY:
            return {...state, company: action.company}
        case SET_ADMIN_COMPANY_ID:
            return {...state, companyId: action.companyId}
        case SET_ACCOUNT_TOPICS:
            return {...state, accountTopics: action.accountTopics.content}
        default:
            return {...state}
    }
}

export default admin;

export const setAdminUsers = (users) => {
    return{
        type: SET_ADMIN_USERS,
        users: users
    }
}

export const setAdminCompanyId = (companyId) => {
    return {
        type: SET_ADMIN_COMPANY_ID,
        companyId: companyId
    }
}

export const setAdminCompany = (company) => {
    return {
        type: SET_ADMIN_COMPANY,
        company: company
    }
}

export const setAccountTopics = (accounts) => {
    return {
        type: SET_ACCOUNT_TOPICS,
        accountTopics: accounts
    }
}

export const getCompany = () => {
    return (dispatch) => {
        AdminService.getCompany().then( data => {
            dispatch(setAdminCompany(data))
        })
    }
}

/* ACCOUNTS */

export const getAccounts = () => {
    return (dispatch) => {
        AdminService.getAccounts().then( data => {
            dispatch(setAdminUsers(data))
        })
    }
}

export const deleteAccount = (companyId, accountId) => {
    return (dispatch) => {
        AdminService.deleteAccount(accountId).then( data => {
            dispatch(getCompanyUsers(companyId))
        })
    }
}

export const adminCreateAccount = (login, fio, email, phone, password, locale, role, authorities = null, isSuperadmin) => {
    return (dispatch) => {
        AdminService.createAccount(
            login,
            fio,
            email,
            phone,
            password,
            locale,
        ).then(
            data=>{
                dispatch(getAccounts());
                if(role){
                    dispatch(addRoleToAccount(data.id, role))
                }
                if(authorities){
                    dispatch(addAccountAuthorities(data.companyId, data.id, authorities, isSuperadmin))
                }
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const addAuthoritiyToAccount = (accountId, authorities) => {
    return (dispatch) => {
        AdminService.addAuthoritiesToAccount(accountId, authorities).then(
            data => {

            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const addRoleToAccount = (accountId, role) => {
    return (dispatch) => {
        AdminService.addRoleToAccount(accountId, role).then(
            data => {

            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getAccountTopics = (accountId) => {
    return (dispatch) => {
        AdminService.getAccountTopics(accountId).then( data => {
            dispatch(setAccountTopics(data))
        })
    }
}

export const attachTopicsToAccount = (accountId, topicIds) => {
    return (dispatch) => {
        AdminService.makeTopicVisibiltyToAccount(accountId, topicIds)
    }
}