import React from "react";
import TelegramItem from "./telegram-item";

class TelegramItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedTelegramTopic: this.props.selectedTelegramTopic,
            selectedTelegramItem: this.props.selectedTelegramItem ? this.props.selectedTelegramItem.id : null
        }
    }

    componentDidMount(){
        this.props.getMyTopics();
        this.props.getAllFilters();
        
        if(this.props.selectedTelegramItem && (this.props.selectedTelegramItem.findedTopic && this.props.selectedTelegramItem.findedTopic.uuid !== undefined)){
            //this.setSelectedTelegramTopic(this.props.selectedTelegramItem.findedTopic.id, this.props.selectedTelegramItem.findedTopic.uuid )
            this.props.getSubtopics(this.props.selectedTelegramItem.findedTopic.id)
            
            if(this.props.selectedTelegramItem) this.props.getSubscriptionAlertPolicies(this.props.selectedTelegramItem.id)
        }
    }

    componentDidUpdate(){
        if((this.props.selectedTelegramTopic && this.props.selectedTelegramTopic !== this.state.selectedTelegramTopic) || this.props.selectedTelegramItem && (this.props.selectedTelegramItem.id !== this.state.selectedTelegramItem) || this.props.updated){

            this.setState({
                selectedTelegramTopic: this.props.selectedTelegramTopic,
                selectedTelegramItem: this.props.selectedTelegramItem ? this.props.selectedTelegramItem.id : null
            })
            if(!this.props.alertPolicy && (this.props.selectedTelegramItem && this.props.selectedTelegramItem.id)) this.props.getSubscriptionAlertPolicies(this.props.selectedTelegramItem.id)
            this.props.getSubtopics(this.props.selectedTelegramTopic);
            if(this.props.isSubtopicsUpdated){
                
                this.props.setSubtopicsUpdated(false)
            }
            if(this.props.selectedTelegramItem && this.props.selectedTelegramItem.id){ this.props.getSubscriptionEmails(this.props.selectedTelegramItem.id)}
            this.props.setUpdated(false)
            //if(this.props.selectedTelegramItem && this.props.selectedTelegramItem.findedTopic){
                
                
            /*}
            else
                this.props.setSubtopicItem([])*/
            if(this.props.selectedTelegramItem && this.props.selectedTelegramItem.id && !this.props.updated){ this.props.getSubscriptionAlertPolicies(this.props.selectedTelegramItem.id)}
            this.props.setReplaceSubscribers([])
        }

    }

    setSelectedTelegramTopic(selectedTelegramTopic){
        this.props.setSelectedTelegramTopic(selectedTelegramTopic)
    }

    setSelectedTelegramSubTopic(selectedTelegramSubTopic){
        this.props.setSelectedTelegramSubTopic(selectedTelegramSubTopic)
    }

    closeSubscription = () => {
        this.props.setShowRightMenu(false) 
        this.props.setRemoveRightMenuComponent(null)
        this.props.setSubscriptionEmails([])
    }

    render(){
        return <TelegramItem closeSubscription={this.closeSubscription} {...this.props} />
    }
}

export default TelegramItemController;