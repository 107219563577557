import { createSelector } from "reselect";
import { getFormatedDate, getFormatDate } from "service/format-date";
import moment from "moment";

const getSubscription = (state) => {
    return state.telegram.telegramItems;
}

const getSubscriptionChats = (state) => {
    return state.telegram.subscriptionChats;
}

const formatDate = (date) =>{
    //return moment(date).format("HH:mm DD.MM.YY");
    return getFormatDate(date, "HH:mm DD.MM.YY");
}

export const getSubscriptionSelector = createSelector(getSubscription, (subscription) => {
    let data = {table: [], data: []}
    if(subscription && subscription.length){
        subscription.forEach(item => {
            data.table.push([ item.id, item.topicName, item.subtopicId ? item.subtopicId : null, formatDate(item.createdAt), formatDate(item.updatedAt), item.filterId, item.status]);
            data.data.push(item)
        });
        return data
    }
    
});

export const getSubscriptionChatsId = createSelector(getSubscriptionChats, (subscriptionChats) => {
    let data = [];
    if(subscriptionChats && subscriptionChats.length){
        data = subscriptionChats.map(k => {
            return k.telegramChatId
        })
    }
    return data
})