import AdminTopicsAddItemController from "./admin-topics-add-item-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getAccounts, getTopicAccounts, getCompanyAccounts, attachAccountsToTopic, detachAccountsFromTopic, getAdminAccounts, attachMarkersToTopic, detachMarkersfromTopic, getTopicMarkers } from "store/superadmin";
import { getActiveAccountsName, getAccountsOnlyName, getActiveAdminAccountsName } from "selectors/superadmin-selector";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getAllMarkers } from "store/admin-markers";
import { getActiveAdminMarkersName } from "selectors/superadmin-selector";

let mapStateToProps = (state) => {
    return {
        isSuperadmin: state.Account.isSuperadmin,
        topicAccounts: getAccountsOnlyName(state, true),
        accounts: getActiveAccountsName(state),
        markers: getActiveAdminMarkersName(state),//.AdminMarkers.markers,
        adminAccounts: getActiveAdminAccountsName(state), //.superadmin.adminAccounts
        //topicMarkers: state.
    }
}

const AdminTopicsAddItemContainer = connect(mapStateToProps, {     
                                                                getValue, 
                                                                getAccounts,
                                                                getTopicAccounts,
                                                                getCompanyAccounts,
                                                                getAdminAccounts,
                                                                attachAccountsToTopic,
                                                                detachAccountsFromTopic,
                                                                setRightMenuComponent, 
                                                                setShowRightMenu, 
                                                                setRemoveRightMenuComponent,
                                                                getAllMarkers,
                                                                attachMarkersToTopic,
                                                                detachMarkersfromTopic,
                                                                getTopicMarkers
                                    })(AdminTopicsAddItemController)

export default AdminTopicsAddItemContainer