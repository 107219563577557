import DatepickerController from "./datepicker-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { modalShowToggle } from "store/modal";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setStartDate, setEndDate, setGrouping, setDateChanged, setGroupingByPeriod } from "store/calendar";

let mapStateToProps = (state) => {
    return {
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        months: state.Calendar.months,
        days: state.Calendar.days,
        minYear: state.Calendar.minYear,
        grouping: state.Calendar.grouping,
    }
}

const DatepickerContainer = (connect(mapStateToProps, {getValue, modalShowToggle, setStartDate, setEndDate, setGrouping, setGroupingByPeriod, setDateChanged})(DatepickerController));

export default DatepickerContainer;