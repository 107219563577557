import { TopicService } from "../connection/topics-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_FAVOURITE_LIST = 'SET-FAVOURITE-LIST';
const SET_FAVOURITE_IS_FETCHING = "SET-FAVOURITE-IS-FETCHING";
const SET_FAVOURITE_IS_UPDATED = "SET-FAVOURITE-IS-UPDATED";



const favorites = {
    favourites: [],
    tableHeader: ['#', 'TOPIC_NAME', 'CREATED_AT', 'UPDATED_AT', 'AUTHORS_NAME', 'PROFILE_URL', 'RESOURCE' ],
    isFetching: false,
    isUpdated: false,
}

let FavouriteList = (state = favorites, action) =>{

    switch(action.type){
        case SET_FAVOURITE_LIST:
            return {...state, favourites: action.favourites, isUpdated: false}
        case SET_FAVOURITE_IS_FETCHING:
            return {...state, isFetching: action.isFetching}
        case SET_FAVOURITE_IS_UPDATED:
            return {...state, isUpdated: action.isUpdated}
        default:
            return {...state}
    }
}

export const setFavouriteIsFetching = (isFetching) => {
    return {
        type: SET_FAVOURITE_IS_FETCHING,
        isFetching: isFetching
    }
}

export const setFavouriteIsUpdated = (isUpdated) => {
    return {
        type: SET_FAVOURITE_IS_UPDATED,
        isUpdated: isUpdated
    }
}

export const setFavouriteList = (favouriteList) => {
    return {
        type: SET_FAVOURITE_LIST,
        favourites: favouriteList
    }
}

export const getFavouriteList = (topicIds) => {
    return (dispatch) => {
        dispatch(setFavouriteIsFetching(true))
        TopicService.getFavouriteResourceProfile(topicIds).then(data=>{
            dispatch(setFavouriteIsUpdated(true))
            dispatch(setFavouriteList(data.data));
            dispatch(setFavouriteIsFetching(false))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export default FavouriteList;