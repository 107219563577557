import SideBarController from "./side-bar-controller";
import { connect } from "react-redux";
import { getTopics } from "../../store/authorization";
import { setToken } from "../../store/side-bar";
import { modalShowToggle } from "../../store/modal";

let mapStateToProps = (state) => {
    return {
        showLeftMenu: state.SideBarMenu.showLeftMenu,
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuTitle: state.SideBarMenu.rightMenuTitle,
        withHeader: state.SideBarMenu.withHeader,
        isAuth: state.authorization.isAuth,
        token: state.SideBarMenu.token,
        closeMenu: state.SideBarMenu.closeMenu,
        //leftMenuComponent: state.SideBarMenu.leftMenuComponent
    }
}

export default 
    connect(mapStateToProps, {getTopics, setToken, modalShowToggle})(SideBarController)