import { SuperadminService } from "connection/superadmin-service";
import { AdminService } from "connection/admin-service";
import { TopicService } from "connection/topics-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import { getMe } from "./account";
import { addAccountAuthorities } from "./authorities";

const SET_SUPERADMIN_COMPANIES = "SET-SUPERADMIN-COMPANIES";
const SET_SUPERADMIN_ACCOUNTS = "SET-SUPERADMIN-ACCOUNTS";
const SET_SUPERADMIN_TOPICS = "SET-SUPERADMIN-TOPICS";
const SET_TOPIC_ACCOUNTS = "SET-TOPIC-ACCOUNTS";
const SET_ACCOUNT_TOPICS = "SET-ACCOUNT-TOPICS";
const SET_SUPERADMIN_COMPANY_TOPICS = "SET-SUPERADMIN-COMPANY-TOPICS"
const SET_SUPERADMIN_COMPANY = "SET_SUPERADMIN_COMPANY"
const SET_COMPANY_ID = "SET-COMPANY-ID";
const SET_TOPIC_ID = "SET-TOPIC-ID";
const SET_ACCOUNT_ID = "SET-ACCOUNT-ID";
const SET_ADMIN_ACCOUNTS = "SET-ADMIN-ACCOUNTS";
const SET_COMPANY_TO_ACCOUNT = "SET-COMPANY-TO-ACCOUNT";
const SET_AVAILABLE_ROLES = "SET-AVAILABLE-ROLES";
const SET_MARKERS = "SET-MARKERS";
const SET_IS_SUPERADMIN_COMPANIES_FETCHING = "SET-IS-SUPERADMIN-COMPANIES-FETCHING";
const SET_SELECTED_ACCOUNT = "SET-SELECTED-ACCOUNT";
const companyStatuses = ['ACTIVE', 'CONTRACT_EXPIRED', 'BLOCKED', 'DELETED'];

const roles = [
    {
        name: 'ROLE_SUPER_ADMIN',
        list: 'ROLE_SUPER_ADMIN',
    },
    {
        name: 'ROLE_ADMIN',
        list: 'ROLE_ADMIN'
    }, 
    {
        name: 'ROLE_MARKER',
        list: 'ROLE_MARKER'
    }, 
    {
        name: 'ROLE_USER',
        list: 'ROLE_USER'
    }
]

const superAdmin = {
    company: null,
    allCompanies: null,
    companies: null,
    companiesCount: null,
    isSuperAdmin: false,
    accounts: null,
    adminAccounts: null,
    adminAccountsCount: null,
    topics: null,
    companyTopics: null,
    topicAccounts: null,
    accountTopics: null,
    markers: null,
    companyId: null,
    companyName: null,
    companyIndex: null,
    topicId: null,
    accountId: null,
    superadminCompanyId: null,
    account: null,
    companyStatuses: companyStatuses,
    companyTableHeader: ['#', 'COMPANY', 'BIN', 'STATUS', 'EXPIRE_DATE', 'TOPICS'],
    accountAdminTableHeader: ['#', 'ACCOUNT', 'COMPANY', 'FULL_NAME', 'EMAIL', 'ROLE', 'STATUS', 'CREATED_AT'],
    accountTableHeader: ['#', 'ACCOUNT', 'FULL_NAME', 'EMAIL', 'ROLE', 'STATUS', 'CREATED_AT'],
    tableHeader: ['#', 'TOPIC_NAME', 'KEYWORDS', 'STOPWORDS', 'SUBTOPICS', 'CREATED_AT', 'UPDATED_AT'],
    companyControlButtons: ["edit", "show", "delete", 'company'],
    topicsTableControlButtons: ["show","add", "delete"],
    accountsTableControlButtons: ["add", "edit", "show", "delete"],
    accountsControlButtons: ["edit", "show", "delete"],
    accountsTopicTablerControlButton: ["remove"],
    isSuperadminCompaniesFetching: false,
    accountLocales: [
        {
            name: 'RUSSIAN',
            list: 'ru'
        },
        {
            name: 'ENGLISH',
            list: 'en'
        },
        {
            name: 'KAZAKH',
            list: 'kk'
        }
    ],
    roles: null
}



const superadmin = (state = superAdmin, action) => {
    switch(action.type){
        case SET_SUPERADMIN_COMPANY:
            return {...state, company: action.company}
        case SET_SUPERADMIN_COMPANIES:
            let companies = (action.companies && action.companies.content ) ? action.companies.content : []
            return action.pageType && (action.pageType === 'SUPERADMIN_ACCOUNTS' || action.pageType === 'SUPERADMIN_TOPICS')
                    ? {...state, allCompanies: companies, companiesCount: action.companies.totalElements}
                    : {...state, companies, companiesCount: action.companies.totalElements}
        case SET_SUPERADMIN_TOPICS:
            let topics = action.topics ? action.topics : []
            return {...state, topics: topics}
        case SET_ADMIN_ACCOUNTS:
            return {...state, adminAccounts: action.adminAccounts}
        case SET_TOPIC_ACCOUNTS:
            
            let topicAccounts = action.topicAccounts ? action.topicAccounts.content : []
            return {...state, topicAccounts: topicAccounts}
        case SET_ACCOUNT_TOPICS:
            let accountTopics = action.accountTopics ? action.accountTopics.content : []
            return {...state, accountTopics: accountTopics}
        case SET_SUPERADMIN_ACCOUNTS:
            let accounts = action.accounts ? (action.isContent ? action.accounts.content : action.accounts ): []      
            let count = (action.accounts && action.accounts.totalElements) ? action.accounts.totalElements : action.accounts.length
            return {...state, accounts: accounts, adminAccountsCount: count}
        case SET_SUPERADMIN_COMPANY_TOPICS:
            let companyTopics = action.companyTopics ? action.companyTopics.content : []
            return {...state, companyTopics: companyTopics}
        case SET_COMPANY_ID:
            let companyIndex = null
            if(!isNaN(action.companyIndex)){
                companyIndex = Number(action.companyIndex)
            }
            return {...state, companyId: action.companyId, companyName: !action.companyId ? null : action.companyName, companyIndex: companyIndex}
        case SET_TOPIC_ID:
            return {...state, topicId: action.topicId}
        case SET_ACCOUNT_ID:
            return {...state, accountId: action.accountId}
        case SET_COMPANY_TO_ACCOUNT:
            return {...state, superadminCompanyId: action.superadminCompanyId}
        case SET_AVAILABLE_ROLES:
            let roles = action.roles ? action.roles.map(k => {return {name: k, list: k}}) : []
            return {...state, roles: roles}
        case SET_MARKERS:
            return {...state, markers: action.markers.content}
        case SET_IS_SUPERADMIN_COMPANIES_FETCHING:
            return {...state, isSuperadminCompaniesFetching: action.isFetching}
        case SET_SELECTED_ACCOUNT:
            return {...state, account: action.account}
        default:
            return {...state}
    }
}

export const setSelectedAccount = (account) => {
    return {
        type: SET_SELECTED_ACCOUNT,
        account: account
    }
}

export const setIsSuperAdminCompaniesFetching = (isFetching) => {
    return {
        type: SET_IS_SUPERADMIN_COMPANIES_FETCHING,
        isFetching: isFetching
    }
}

export const setCompany = (company) => {
    return {
        type: SET_SUPERADMIN_COMPANY,
        company: company
    }
}

export const setSuperadminCompanies = (companies, pageType) => {
    return{
        type: SET_SUPERADMIN_COMPANIES,
        companies: companies,
        pageType: pageType
    }
}

export const setSuperadminAccounts = (accounts, isContent = false) => {
    return{
        type: SET_SUPERADMIN_ACCOUNTS,
        accounts: accounts,
        isContent: isContent
    }
}

export const setAdminAccounts = (adminAccounts) => {
    return{
        type: SET_ADMIN_ACCOUNTS,
        adminAccounts: adminAccounts
    }
}

export const setSuperadminTopics = (topics) => {
    return{
        type: SET_SUPERADMIN_TOPICS,
        topics: topics
    }
}

export const setSuperadminCompanyTopics = (topics) => {
    return{
        type: SET_SUPERADMIN_COMPANY_TOPICS,
        companyTopics: topics
    }
}

export const setTopicsAccounts = (topicAccounts) => {
    return{
        type: SET_TOPIC_ACCOUNTS,
        topicAccounts: topicAccounts
    }
}

export const setAccountTopics = (accountTopics) => {
    return{
        type: SET_ACCOUNT_TOPICS,
        accountTopics: accountTopics
    }
}

export const setCompanyId = (companyId, companyName, companyIndex) => {
    return {
        type: SET_COMPANY_ID,
        companyId: companyId,
        companyName: companyName,
        companyIndex: companyIndex
    }
}

export const setTopicId = (topicId) => {
    return {
        type: SET_TOPIC_ID,
        topicId: topicId
    }
}

export const setAccountId = (accountId) => {
    return {
        type: SET_ACCOUNT_ID,
        accountId: accountId
    }
}

export const setAvailableRoles = (roles) => {
    return {
        type: SET_AVAILABLE_ROLES,
        roles: roles
    }
}

export const setCompanyToAccount = (companyId, isSetSuperadminMenuButtons) => {
    return (dispatch) => {
        dispatch(getMe(true, isSetSuperadminMenuButtons))     
    }
}

export const setSuperadminCompany = (companyId) => {
    return {
        type: SET_COMPANY_TO_ACCOUNT,
        superadminCompanyId: companyId
    }
}

export const setMarkers = (markers) => {
    return {
        type: SET_MARKERS,
        markers: markers
    }
}
//setSuperadminMenuButtons

export const getSuperadminCompany = (companyId) => {
    return (dispatch) => {
        SuperadminService.getCompany(companyId).then( data => {
            dispatch(setCompany(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getSuperadminCompanies = (query, page, pageSize, pageType) => {
    return (dispatch) => {
        dispatch(setIsSuperAdminCompaniesFetching(true));
        SuperadminService.getSuperadminCompanies(query, page, pageSize).then(data =>{
            dispatch(setSuperadminCompanies(data, pageType));
            dispatch(setIsSuperAdminCompaniesFetching(false));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}));
            dispatch(setIsSuperAdminCompaniesFetching(false));
        })
    }
}

export const editCompany = (query) => {
    return (dispatch) => {
        SuperadminService.editCompany(query).then(data =>{
            dispatch(setSuperadminCompanies(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const companyStatus = (companyId, status, page, pageSize) => {
    return (dispatch) => {
        SuperadminService.activateDeactivateCompany(companyId, status).then( data => {
            dispatch(getSuperadminCompanies('ALL', page, pageSize))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const createCompany = (name, bin, maxNumTopics, status, expiryDate, page, pageSize) => {
    return (dispatch) => {
        SuperadminService.createCompany(name, bin, maxNumTopics, status, expiryDate).then( data => {
            dispatch(getSuperadminCompanies('ALL', page, pageSize))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateCompany = (companyId, name, bin, maxNumTopics, status, expiryDate, page, pageSize) => {
    return (dispatch) => {
        SuperadminService.updateSuperAdminCompany(companyId, name, bin, maxNumTopics, status, expiryDate).then( data => {
            dispatch(getSuperadminCompanies('ALL', page, pageSize))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getAdminAccounts = (query, page, pageSize) => {
    return (dispatch) => {
        SuperadminService.getAllAccounts(query, page, pageSize).then(data=>{
            dispatch(setAdminAccounts(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getCompanyAccounts = (companyId, isSuperadmin, page, pageSize) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.getCompanyAccounts(companyId, page, pageSize).then(data=>{
                dispatch(setSuperadminAccounts(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.getAccounts().then(data=>{
                dispatch(setSuperadminAccounts(data, false))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }

    }
}

export const getAccounts = (isSuperAdmin, page, pageSize) => {
    let service = isSuperAdmin === false ? AdminService : SuperadminService
    return (dispatch) => {
        service.getAccounts(page, pageSize).then(data=>{
            dispatch(setSuperadminAccounts(data, isSuperAdmin ? true : false))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const activateAccount = (companyId, accountId, isSuperadmin, page, pageSize) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.activateCompanyAccount(accountId).then( data => {
                if(companyId) dispatch(getCompanyAccounts(companyId, isSuperadmin, page, pageSize))
                else dispatch(getAccounts(isSuperadmin, page, pageSize))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.activateAccount(accountId, page, pageSize).then( data => {
                dispatch(getCompanyAccounts(companyId, isSuperadmin, page, pageSize))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
    }
}

export const blockAccount = (companyId, accountId, isSuperadmin, page, pageSize) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.blockCompanyAccount(accountId).then( data => {
                if(companyId) dispatch(getCompanyAccounts(companyId, isSuperadmin, page, pageSize))
                else dispatch(getAccounts(isSuperadmin, page, pageSize))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.blockAccount(accountId).then( data => {
                dispatch(getCompanyAccounts(companyId, isSuperadmin))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
    }
}

export const deleteAccount = (companyId, accountId, isSuperadmin, isAccountsPage, page, pageSize) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.deleteAccount(accountId).then( data => {
                if (isAccountsPage) {
                    dispatch(getAccounts(isSuperadmin, page, pageSize))
                } else {
                    dispatch(getCompanyAccounts(companyId, isSuperadmin, page, pageSize))
                }
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.deleteAccount(accountId).then( data => {
                dispatch(getCompanyAccounts(companyId, isSuperadmin))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
    }
}

export const deleteCompany = (companyId, page, pageSize) => {
    return (dispatch) => {
        SuperadminService.deleteSuperadminCompany(companyId).then( data => {
            dispatch(getSuperadminCompanies('ALL', page, pageSize))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getTopics = () => {
    return (dispatch) => {
        SuperadminService.getSuperadminTopics().then(data=>{
            dispatch(setSuperadminTopics(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getCompanyTopics = (companyId, isSuperadmin) => {
    return (dispatch) => {
        //getTopics
        if(isSuperadmin){
            SuperadminService.getSuperadminCompanyTopics(companyId).then(data=>{
                dispatch(setSuperadminCompanyTopics(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            TopicService.getMyTopics().then(data=>{
                dispatch(setSuperadminCompanyTopics(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
    }
}

export const getTopicAccounts = (companyId, topicId, isSuperadmin) => {
    return (dispatch) => {
        //if(isSuperadmin){
            SuperadminService.getAccountsOfTopic(companyId, topicId).then(data=>{
                dispatch(setTopicsAccounts(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        /*}
        else{
            AdminService.getTopicAccounts(topicId).then(data=>{
                dispatch(setTopicsAccounts(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }*/
        
    }
}

export const getAccountTopics = (companyId, accountId, isSuperadmin) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.getTopicsOfAnAccounts(companyId, accountId).then(data=>{
                dispatch(setAccountTopics(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.getAccountTopics(accountId).then(data => {
                dispatch(setAccountTopics(data))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        
    }
}

export const deleteCompanyTopic = (topicId, companyId) => {
    return (dispatch) => {
        TopicService.deleteTopic(topicId).then(data=>{
            setTimeout(()=>{ dispatch(getCompanyTopics(companyId));},500)
        }).catch(error => {
            let message = getErrorMessage(error)
            dispatch(checkErrorMessage({code: error.response.status, message: message}))
        })
    }
}

export const createCompanyAccount = (companyId, login, fio, email, phone, password, locale, role, authorities, isSuperadmin, page, pageSize, topics) => {
    return (dispatch) => {
        SuperadminService.createAccountInCompany(companyId, login, fio, email, phone, password, locale).then(
            data=>{
                
                if(role) dispatch(addRoleToAccount(data.id, role, isSuperadmin))
                if(authorities) dispatch(addAccountAuthorities(companyId, data.id, authorities, isSuperadmin, true))
                dispatch(getCompanyAccounts(companyId, isSuperadmin, page, pageSize))
            }
        ).catch(error => {
            let message = getErrorMessage(error)
            dispatch(checkErrorMessage({code: error.response.status, message: message}))
        })
    }
}

export const createAccount = (login, fio, email, phone, password, locale, role, authorities = null, isSuperadmin, page, pageSize, topics) => {
    return (dispatch) => {
        let service = isSuperadmin ? SuperadminService : AdminService
        service.createAccount(login, fio, email, phone, password,locale,).then(
            data=>{

                if(role) dispatch(addRoleToAccount(data.id, role, isSuperadmin))
                if(authorities) dispatch(addAccountAuthorities(data.companyId, data.id, authorities, isSuperadmin))
                dispatch(attachTopicsToAccount(isSuperadmin, data.id, topics));
                setTimeout(()=>{dispatch(getAccounts(isSuperadmin, page, pageSize))},500)
            }
        ).catch(error => {
            let message = getErrorMessage(error)
            dispatch(checkErrorMessage({code: error.response.status, message: message}))
        })
    }
}

export const startTopic = (topicId, companyId, isSuperAdmin) => {
    return (dispatch) => {
        TopicService.playTopic(topicId).then(data=>{
            dispatch(getCompanyTopics(companyId, isSuperAdmin))
        }).catch(error => {

        })
    }
}

export const pauseTopic = (topicId, companyId, isSuperAdmin) => {
    return (dispatch) => {
        TopicService.pauseTopic(topicId).then(data=>{
            dispatch(getCompanyTopics(companyId, isSuperAdmin))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}
//companyId, accountId, fio, email, phone, locale, isDemo, role, authorities, this.props.isSuperadmin
export const editCompanyAccount = (companyId, accountId, fio, email, phone, locale, isDemo, role, authorities, isSuperadmin, page, pageSize) => {
        if(!phone || phone == ''){
            phone = null
        }
        return (dispatch) => {
            if(isSuperadmin){
                SuperadminService.editCompanyAccount(accountId, fio, email, phone, locale, isDemo).then(
                    data=>{
                        /*if(companyId) dispatch(getCompanyAccounts(companyId, isSuperadmin))
                        else */
                        
                        if(role){
                            dispatch(addRoleToAccount(accountId, role, isSuperadmin))
                        }
                        if(authorities){
                            dispatch(addAccountAuthorities(companyId, accountId, authorities, isSuperadmin))
                        }
                        
                        setTimeout(()=>{dispatch(getCompanyAccounts(companyId, isSuperadmin, page, pageSize))},500)
                    }
                ).catch(error => {
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                })
            }
            else{
                AdminService.editAccount(accountId, fio, email, phone).then(
                    data=>{
                        if(role){
                            dispatch(addRoleToAccount(accountId, role, isSuperadmin))
                        }
                        if(authorities){
                            dispatch(addAccountAuthorities(companyId, accountId, authorities, isSuperadmin))
                        }
                        setTimeout(()=>{dispatch(getCompanyAccounts(companyId, isSuperadmin))},500)
                    }
                ).catch(error => {
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                })
            }
        } 

}

export const editAccount = (accountId, fio, email, phone, locale, isDemo, role, authorities, isSuperadmin, page, pageSize) => {
    if(!phone || phone == ''){
        phone = null
    }
    return (dispatch) => {
        SuperadminService.editAccount(accountId, fio, email, phone, locale, isDemo).then(
            data=>{
                
                if(role){
                    dispatch(addRoleToAccount(accountId, role, isSuperadmin))
                }
                if(authorities){
                    dispatch(addAccountAuthorities(null, accountId, authorities, isSuperadmin))
                }
                setTimeout(()=>{dispatch(getAccounts(isSuperadmin, page, pageSize))},500)
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const attachAccountToCompany = (accountId, companyId, isSuperAdmin, page, pageSize, render = true) => {
    return (dispatch) => {
        SuperadminService.attachAccountToCompany(accountId, companyId).then(
            data => {
                if(render) dispatch(getAccounts(isSuperAdmin, page, pageSize))
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const detachAccountFromCompany = (accountId, companyId, isAccountsPage, isSuperAdmin, page, pageSize) => {
    return (dispatch) => {
        SuperadminService.detachAccountFromCompany(accountId, companyId).then(
            data => {
                if (isAccountsPage) {
                    dispatch(getAccounts(isSuperAdmin, page, pageSize))
                } else {
                    dispatch(getCompanyAccounts(companyId, isSuperAdmin))
                }
            }
        )
    }
}

export const attachTopicsToAccount = (isSuperadmin, accountId, topicIds, companyId = null, role) => {
    return (dispatch) => {

        if(isSuperadmin && role === 'ROLE_MARKER'){
            SuperadminService.makeMarkerTopicVisibleToAccount(accountId, topicIds)
        }
        else if(isSuperadmin){
            SuperadminService.makeSuperadminTopicVisibleToAccount(accountId, topicIds, companyId)
        }
        else{
            AdminService.makeTopicVisibiltyToAccount(accountId, topicIds)
        }
    }
}

export const attachAccountsToTopic = (companyId, accountIds, topicId) => {
    return (dispatch) => {
        //accountIds.forEach((k, i) => {
            SuperadminService.visibleAccountsToTopic(companyId, accountIds, topicId).then( data => {

            })
        //});
    }
}

export const attachMarkersToTopic = (accountIds, topicId) => {
    return (dispatch) => {
        //accountIds.forEach((k, i) => {
            SuperadminService.makeTopicVisibleToMarkers(accountIds, topicId)
        //});
    }
}

export const detachMarkersfromTopic = (accountIds, topicId) => {
    return (dispatch) => {
        accountIds.forEach((k, i) => {
            SuperadminService.detachMarkerTopic(k, [topicId])
        });
    }
}

export const detachAccountsFromTopic = (companyId, accountIds, topicId) => {
    return (dispatch) => {
        accountIds.forEach((k, i) => {
            SuperadminService.invisibleTopicToAccount(companyId, k, topicId).then( data => {
 
            })
        });
    }
}

export const attachTopicToAccount = (companyId, accountId, topicId, isSuperadmin, isAccount) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.visibleTopicToAccount(companyId, accountId, topicId).then(
                data => {
                    !isAccount   ? dispatch(getTopicAccounts(companyId, topicId, isSuperadmin))
                                : dispatch(getAccountTopics(companyId, accountId,isSuperadmin));
                }
            ).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.attachTopicToAccount(accountId, topicId).then(
                data=>{
                    !isAccount  ? dispatch(getTopicAccounts(companyId, topicId, isSuperadmin))
                                : dispatch(getAccountTopics(companyId, accountId,isSuperadmin));
                }
            ).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
    }
}

export const detachTopicFromAccount = (companyId, accountId, topicId, isSuperadmin, isAccount) => {
    return (dispatch) => {
        if(isSuperadmin){
            SuperadminService.invisibleTopicToAccount(companyId, accountId, topicId).then(
                data => {
                    isAccount   ? dispatch(getTopicAccounts(companyId, topicId, isSuperadmin))
                                : dispatch(getAccountTopics(companyId, accountId, isSuperadmin));
                }
            ).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }
        else{
            AdminService.detachTopicFromAccount(accountId, topicId).then(
                data => {
                    isAccount   ? dispatch(getTopicAccounts(companyId, topicId, isSuperadmin))
                                : dispatch(getAccountTopics(companyId, accountId, isSuperadmin));
                }
            ).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        }

    }
}

export const detachMarkerTopic = (accountId, topicId) => {
    return (dispatch) => {
        SuperadminService.detachMarkerTopic(accountId, topicId).then(
            data => {
                dispatch(getMarkerTopics(accountId))
            }
        )
    }
}

export const addSuperAdminCompany = (companyId, accountId) => {
    return (dispatch) => {
        SuperadminService.addSuperadminCompany(companyId, accountId).then(
            data => {
                dispatch(setCompanyToAccount(companyId, true))
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const removeSuperAdminCompany = (companyId, accountId) => {
    return (dispatch) => {
        SuperadminService.removeSuperadminCompany(companyId, accountId).then(
            data => {
                dispatch(setCompanyToAccount(null, false))
                //dispatch(window.location.href = "/page/admin")
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const addAuthoritiyToAccount = (accountId, authorities) => {
    return (dispatch) => {
        SuperadminService.addAuthoritiesToAccount(accountId, authorities).then(
            data => {

            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const addRoleToAccount = (accountId, role, isSuperadmin) => {
    
    let service = isSuperadmin ? SuperadminService : AdminService
    return (dispatch) => {
        service.addRoleToAccount(accountId, role).then(
            data => {

            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getAvailableRoles = (isSuperadmin) => {
    let service = isSuperadmin ? SuperadminService : AdminService
    return (dispatch) => {
        service.getAvailableRoles().then(
            data => {
                dispatch(setAvailableRoles(data))
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const makeTopicVisibleToMarker = (accountId, topicId, companyId, isAccount) => {
    return (dispatch) => {
        SuperadminService.makeTopicVisibleToAccount(accountId, topicId, companyId).then(
            data => {
                //dispatch(getMarkerTopics(accountId))
                //!isAccount   ? dispatch(getTopicAccounts(companyId, topicId, true))
                                 dispatch(getMarkerTopics(accountId))
            }
        ).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getTopicMarkers = (topicId) => {
    return (dispatch) => {
        SuperadminService.getTopicMarkers(topicId).then(
            data => {
                dispatch(setTopicsAccounts(data))
            }
        )
    }
}

export const getMarkerTopics = (accountId) => {
    return (dispatch) => {
        SuperadminService.getMarkerTopics(accountId).then(
            data => {
                dispatch(setAccountTopics(data))
            }
        )
    }
}

export const getMarkers = () => {
    return (dispatch) => {
        SuperadminService.getMarkers().then(
            data => {
                dispatch(setMarkers(data))
            }
        )
    }
}

export const getAccountById = (accountId) => {
    return (dispatch) => {
        SuperadminService.getAccount(accountId).then(
            data => {
                dispatch(setSelectedAccount(data))
            }
        )
    }
}

export default superadmin;