import React from "react";
import CompanyTopicAddItem from "./company-topic-add-item";

class CompanyTopicAddItemController extends React.Component{
    
    constructor(props){
        super(props);

        this.state = {
            companyId: this.props.companyId,
            topicId: this.props.topicId,
            topicAccounts: this.props.topicAccounts ? this.props.topicAccounts : []
        }
    }

    closeRightMenu = () => {        
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    componentDidUpdate(){
        if(this.props.topicId !== this.state.topicId || this.props.companyId !== this.state.companyId){
            this.props.getTopicAccounts(this.props.companyId, this.props.topicId, this.props.isSuperadmin)
            this.setState({
                companyId: this.props.companyId,
                topicId: this.props.topicId,
                topicAccounts: this.props.topicAccounts ? this.props.topicAccounts : []
            })
        }
    }

    componentDidMount(){
        this.props.getTopicAccounts(this.props.companyId, this.props.topicId, this.props.isSuperadmin)
    }

    

    render(){
        return (
            <CompanyTopicAddItem {...this.props} selectedAccounts={this.props.topicAccounts} closeRightMenu={this.closeRightMenu}/>
        )
    }
}

export default CompanyTopicAddItemController;