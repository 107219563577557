import React, {useState} from "react";
import s from "./edit-user.module.scss"
import { Formik, Form } from "formik";
import { InputField, InputPasswordField } from "../../../../../commons/form-controls";
import { StandartButton } from "../../../../../commons/form-controls";
import { ReactComponent as PasswordIcon } from '../../../../../assets/icons/password.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/icons/save.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { editUserValidateSchema } from "../../../../../utils/validators";

const EditUser = (props) => {

    const [showChangePassword, setShowChangePassword] = useState(false);
    let [showPassword, setShowPassword] = useState(false);
    let [showCheckPassword, setShowCheckPassword] = useState(false);

    const onShowPassword = (passType) => {
        passType === 0 ? setShowPassword(!showPassword) : setShowCheckPassword(!showCheckPassword)
    }

    const onSubmitForm = (formData) => {
        if(formData !== undefined ){
            if(formData.password && formData.checkPassword){
                props.changeUserPassword(formData.password, formData.checkPassword)
            }
        }
    }
    const changePasswordForm = (formData) => {

    }
    return (
        <div className={s.EditUser}>
            <Formik
                    initialValues={{
                        fio: '',
                        login: '',
                        email: '',
                        phone: '',
                        password: '',
                        checkPassword: ''
                    }}
                    validationSchema={editUserValidateSchema}
                    onSubmit={onSubmitForm}
                >
                     {({handleChange, handleSubmit})=>(
                        <Form
                            onSubmit={handleSubmit}
                        >
                            <div className={s.labelRow}>
                                <div className={s.labelText}>{props.getValue('FULL_NAME')}</div>
                                <InputField name="fio" type="text" label={props.getValue('FULL_NAME')}/>
                            </div>
                            <div className={s.labelRow}>
                                <div className={s.labelText}>{props.getValue('LOGIN')}</div>
                                <InputField name="login" type="text" label={props.getValue('LOGIN')}/>
                            </div>
                            <div className={s.labelRow}>
                                <div className={s.labelText}>{props.getValue('EMAIL_2')}</div>
                                <InputField name="email" type="text" label={props.getValue('EMAIL_2')}/>
                            </div>
                            <div className={s.labelRow}>
                                <div className={s.labelText}>{props.getValue('PHONE')}</div>
                                <InputField name="phone" type="text" label={props.getValue('PHONE')}/>
                            </div>
                            <div className={s.labelRow}>
                                <StandartButton type="button" title={props.getValue('CHANGE_PASSWORD')} icon={PasswordIcon} callback={()=>{setShowChangePassword(!showChangePassword)}} stroke={'white'}/>
                            </div>
                            {showChangePassword && <div>
                                <div className="formRow">
                                    <div className={s.labelRow}>
                                        <div className={s.labelText}>{props.getValue('CHANGE_PASSWORD')}</div>
                                        <InputPasswordField handleChange={handleChange} onShowPassword={onShowPassword.bind(this, 0)} showPassword={showPassword} name="password" type="password" label={props.getValue('PASSWORD')}/>
                                    </div>
                                </div>
                                <div className="formRow">
                                <div className={s.labelRow}>
                                    <div className={s.labelText}>{props.getValue('PASSWORD_RESTORE')}</div>
                                        <InputPasswordField handleChange={handleChange} onShowPassword={onShowPassword.bind(this, 1)} showPassword={showCheckPassword} name="checkPassword" type="password" label={props.getValue('PASSWORD')}/>
                                    </div>
                                </div>
                                <div className="formRow spaceAround">
                                    <StandartButton type="submit" title={props.getValue('SAVE')} icon={SaveIcon} callback={onSubmitForm} fill={'white'}/>
                                    <StandartButton type="button" title={props.getValue('CANCEL')} icon={CloseIcon} callback={()=>{}} stroke={'white'}/>
                                </div>
                            </div>}
                        </Form>
                        
                     )}
                </Formik>
        </div>
    )
}

export default EditUser;