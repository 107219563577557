import ReportTableController from "./report-table-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getReportSettings, updateReportSettings, setSelectedReportList } from "store/report-settings";
import { getAdminReportsSettings, updateAdminReportSettings, deleteReportOperation, createAdminReportSettings } from "store/admin-reports"
import { getAdminUsers } from "selectors/admin-topics-selector";

let mapStateToProps = (state) => {
    return {
        reportsData: state.AdminReports.reportsData,
        reportsHeader: state.AdminReports.reportsHeader,
        users: getAdminUsers(state),
        controlButtons: state.AdminReports.controlButtons,
        data: {...state.reportSettings.data},
    }
}

const ReportTableContainer = connect(mapStateToProps, {  getValue, 
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                getReportSettings,
                                updateAdminReportSettings,
                                setSelectedReportList,
                                updateReportSettings,
                                deleteReportOperation,
                                createAdminReportSettings,
                                getAdminReportsSettings})(ReportTableController)

export default ReportTableContainer