import { AdminReportService } from "connection/admin-report-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_ADMIN_REPOSTS_SETTINGS = "SET-ADMIN-REPOSTS-SETTINGS";

const adminReportsData = {
    reportsHeader: ['#', 'ACCOUNT', "GROUPING_TYPE", "TEXT_REPRESENTATION"],
    reportsData: [],
    pageCount: 0,
    currentPage: 0,
    controlButtons: ["edit"],
}

const AdminReports = (state = adminReportsData, action) => {
    switch(action.type){
        case SET_ADMIN_REPOSTS_SETTINGS:
            return {...state, reportsData: action.reportSettings.content, pageCount: action.reportSettings.totalElements}
        default:
            return {...state}
    }
}

export default AdminReports;

export const setAdminReportSettings = (reportSettings) => {
    return {
        type: SET_ADMIN_REPOSTS_SETTINGS,
        reportSettings: reportSettings
    }
}

export const getAdminReportsSettings = (page, pageSize) => {
    return (dispatch) => {
        AdminReportService.getReportSettings(page, pageSize).then( data=> {
            dispatch(setAdminReportSettings(data))
        })
    }
}

export const updateAdminReportSettings = (settingId, reportsSettings) => {
    return (dispatch) => {
        AdminReportService.updateReportSettings(settingId, reportsSettings).then( data => {
            dispatch(getAdminReportsSettings())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const createAdminReportSettings = (userId, reportSettings) => {
    return (dispatch) => {
        AdminReportService.createReportSettings(userId, reportSettings).then( data => {
            dispatch(getAdminReportsSettings())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const deleteReportOperation = (reportOperationId) => {
    return (dispatch) => {
        AdminReportService.deleteReportSettings(reportOperationId).then( data => {
            dispatch(getAdminReportsSettings())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

