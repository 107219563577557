import * as Yup from 'yup';
import { getValue } from "../store/languages"

const phoneRegExp = /^[0-9\-\+]{11,13}$/;
const loginRegExp = /^^[^\W_]{3,48}$/;
const nameRegExp = /[A-Za-zА-Яа-я0-9]+$/;
const fioRegExp = /^[a-zA-ZА-Яа-я-—\s]*$/;
//ONLY_NUMBERS_AND_LETTERS_AT_LEAST_3
export const signinValidateSchema = Yup.object().shape({
    login: Yup.string()
        .matches(loginRegExp, getValue('ONLY_NUMBERS_AND_LETTERS_AT_LEAST_3'))
        .required(getValue('OBLIGATORY_FIELD')),
    password: Yup.string()
        .required(getValue('OBLIGATORY_FIELD')),
});

export const signupValidateSchema = Yup.object().shape({
    login: Yup.string()
        .required(getValue('OBLIGATORY_FIELD'))
        .matches(loginRegExp, getValue('ONLY_NUMBERS_AND_LETTERS')),
    password: Yup.string()
        .required(getValue('OBLIGATORY_FIELD'))
        .min(5, 'Слишком короткий пароль')
        .max(50, 'Слишком длинный пароль'),
    checkPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], getValue('SORRY_WRONG_PASSWORD_REPEAT')),
    name: Yup.string()
        .required(getValue('OBLIGATORY_FIELD'))
        .matches(fioRegExp, 'ONLY_LETTERS'),
    email: Yup.string()
        .required(getValue('OBLIGATORY_FIELD'))
        .email('Не верный email'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Номер телефона не верный'),
});

export const callUsValidateSchema = Yup.object().shape({
    name: Yup.string()
        .required(getValue('OBLIGATORY_FIELD'))
        .matches(fioRegExp, getValue('ONLY_LETTERS')),
    email: Yup.string()
        .email('Не верный email').when('contacts', {
            is: (contacts) => !contacts || contacts.length === 0,
            then: Yup.string().required(getValue('ONE_SHOULD_BE'))
        }),
    contacts: Yup.string()
        .matches(phoneRegExp, 'Номер телефона не верный').when('email',{
            is: (email) => !email || email.length == 0,
            then: Yup.string().required(getValue('ONE_SHOULD_BE'))
        }),
    text: Yup.string()
        .required(getValue('OBLIGATORY_FIELD')),
},['email', 'contacts']);

export const callUsValidateSchema2 = Yup.object().shape({
    contacts: Yup.string()
        .required(getValue('OBLIGATORY_FIELD')),
    text: Yup.string()
        .required(getValue('OBLIGATORY_FIELD')),
});

export const editUserValidateSchema = Yup.object().shape({
    fio: Yup.string()
        .matches(fioRegExp, getValue('ONLY_LETTERS')),
    login: Yup.string()
        .matches(nameRegExp, getValue('ONLY_NUMBERS_AND_LETTERS')),
    email: Yup.string()
        .email('Не верный email'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Номер телефона не верный'),
    password: Yup.string()
        .min(5, 'Слишком короткий пароль')
        .max(50, 'Слишком длинный пароль'),
    checkPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], getValue('SORRY_WRONG_PASSWORD_REPEAT')),
    name: Yup.string(),
});

export const companyValidateSchema = Yup.object().shape({
    company_bin: Yup.string()
        .required(getValue('OBLIGATORY_FIELD')),
    company_name: Yup.string()
        .required(getValue('OBLIGATORY_FIELD')),
    company_max_num_topics: Yup.number()
        .required(getValue('OBLIGATORY_FIELD'))
        .integer('Только целое значение')
        .min(1, 'Не менее 1 темы')
        .max(60, 'Не более 60 тем')
});