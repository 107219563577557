import SortFilterController from "./sort-filter-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setSorting } from "store/storage"

let mapStateToProps = (state) => {
    return {
        sortFilters: state.Filters.sortFilters,
        sort: state.storage.sort
    }
}

const SortFilterContainer = (connect(mapStateToProps, {getValue, setSorting})(SortFilterController));

export default SortFilterContainer;