import React, {useState, useEffect} from "react";

const CompanyUsers = (props) => {

    const [users, setUsers] = useState(props.users ? props.users : null)

    useEffect(() =>{
        setUsers(props.users ? props.users : null)
    })

    const userList = users ? users.map(
        (k,i) => <p>{k.login}</p>
    ) : null

    return (
        <div>{userList}</div>
    )
}

export default CompanyUsers;