import React, {useState, useEffect} from "react";
import AdminCompaniesContainer from "./admin-companies/admin-companies-container";
import AdminAccountsContainer from "./admin-accounts/admin-accounts-container";
import s from "./admin.module.scss"


const Admin = (props) => {

    const [companies, setCompanies] = useState(props.companies ? props.companies : null);

    const getPage = () => {

    }

    return <div className={s.Admin}>
        { /*props.companyId ? <AdminAccountsContainer companyId={props.companyId} /> : */<AdminCompaniesContainer companies={companies} /> }
    </div>
}

export default Admin;