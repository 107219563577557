import ShortAnlayticLineChartController from "./short-analytic-line-chart-controller";
import { connect } from "react-redux";
import { getTopicDataRange } from "../../../selectors/short-analytic-selector";
import { getShortAnalyticRange } from "../../../store/short-analytic";
import { getValue } from "../../../store/languages";

let mapStateToProps = (state) => {
    return {
        dataRange: getTopicDataRange(state),
        language: state.languages.selectedLanguage,
    }
}

export default 
    connect(mapStateToProps, { getShortAnalyticRange, getValue})(ShortAnlayticLineChartController)