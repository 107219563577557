import React, {useState, useEffect} from "react";
import { InputNumber, InputText, SelectDropdown, InputTimeBlock, InputDateBlock} from "commons/input-controls";
import s from "./create-company.module.scss"
import { StandartButton } from "commons/form-controls";
import moment from "moment";
import { getFormatDate } from "service/format-date";

const CreateCompany = (props) => {

    const getTime = (date) => {
        let time = {}
        time.h = moment(date).format('H');
        time.m = moment(date).format('m');
        return time
    }

    const getDate = (date) => {
        let time = {}
        time.d = moment(date).format('DD');
        time.m = moment(date).format('MM');
        time.y = moment(date).format('YYYY');
        return time
    }

    const [companyName, setCompanyName] = useState(props.company && props.company.name ? props.company.name : '');
    const [companyBin, setCompanyBin] = useState(props.company && props.company.bin ? props.company.bin : null);
    const [companyId, setCompanyId] = useState(props.company && props.company.id ? props.company.id : '');
    const [companyTopicsCount, setCompanyTopicsCount] = useState(props.company && props.company.maxNumTopics ? props.company.maxNumTopics : 1);
    const [companyStatus, setCompanyStatus] = useState(props.company && props.company.companyStatus ? props.companyStatus : props.companyStatuses[0])
    const [expiryDate, setExpiryDate] = useState(props.company && props.company.expiryDate ? getDate(props.company.expiryDate) : getDate(moment()));
    const [expiryDateTime, setExpiryDateTime] = useState(props.company && props.company.expiryDate ? getTime(props.company.expiryDate) : getTime(moment()));
    //const [expiryDateTime, setExpiryDate] = useState(props.company && props.company.createdAt ? getTime(props.company.createdAt) : null)

    useEffect(() => {
        setCompanyName(props.company && props.company.name ? props.company.name : '')
        setCompanyStatus(props.company && props.company.companyStatus ? props.company.companyStatus : props.companyStatuses[0])
        setCompanyBin(props.company && props.company.bin ? props.company.bin : null)
        setCompanyId(props.company && props.company.id ? props.company.id : '')
        setCompanyTopicsCount(props.company && props.company.maxNumTopics ? props.company.maxNumTopics : 1);
        setExpiryDate(props.company && props.company.expiryDate ? getDate(props.company.expiryDate) : getDate(moment()));
        setExpiryDateTime(props.company && props.company.expiryDate ? getTime(props.company.expiryDate) : getTime(moment()))
    }, [props.company])

    const addCompanyName = (e) => {
        setCompanyName(e)
    }

    const addCompanyBin = (e) => {
        setCompanyBin(e)
    }

    const addCompanyTopicsCount = (e) => {
        setCompanyTopicsCount(e)
    }

    const addCompanyStatus = (e) => {
        setCompanyStatus(e)
    }

    const resetAllList = () => {
        setCompanyName('');
        setCompanyBin(null);
        setCompanyTopicsCount(0);
        setCompanyStatus(props.companyStatuses[0]);
    }

    const getFormatedDate = (date) => {
        return getFormatDate(moment(date).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ssZ')//moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
    }

    const CreateCompany = () => {

        let expDate = expiryDate.y+'-'+expiryDate.m+'-'+expiryDate.d+' '+expiryDateTime.h+':'+expiryDateTime.m
        props.callback(companyName, companyBin, companyTopicsCount, companyStatus, getFormatedDate(expDate));
        resetAllList();
    }

    const updateCompany = () => {
        let expDate = expiryDate.y+'-'+expiryDate.m+'-'+expiryDate.d+' '+expiryDateTime.h+':'+expiryDateTime.m
        props.callback(companyId, companyName, companyBin, companyTopicsCount, companyStatus, getFormatedDate(expDate));
    }

    const comapnyStatuses = () => {

    }

    const setNumber = (index, value, type) => {
        
        if(type === 'first'){
            let h = value
            
            let t = {h: h, m: expiryDateTime.m}
            setExpiryDateTime(t)
        }
        else if(type === 'second'){
                let m = value
                let t = {h: expiryDateTime.h, m: m}
                setExpiryDateTime(t)
        }
    }

    const setDateNumber = (index, value, type) => {
        if(type === 'day'){
            let d = value < 10 ? 0+''+value : value
            let t = {d: d, m: expiryDate.m, y: expiryDate.y}
            setExpiryDate(t)
        }
        else if(type === 'month'){
                let m = value < 10 ? 0+''+value : value
                let t = {d: expiryDate.d, m: m, y: expiryDate.y}
                setExpiryDate(t)
        }
        else if(type === 'year'){
            let y = value < 10 ? 0+''+value : value
            let t = {d: expiryDate.d, m: expiryDate.m, y: y}
            setExpiryDate(t)
        }
    }

    const createButton = (type) => {
        switch(type){
            case 'create':
                return <StandartButton 
                    title={props.getValue('CREATE')} 
                    callback={CreateCompany}
                    disabled={!companyName || !companyStatus || !companyTopicsCount}
                />
            case 'edit':
                return <StandartButton 
                    title={props.getValue('UPDATE')} 
                    callback={updateCompany}
                    disabled={!companyName || !companyStatus || !companyTopicsCount}
                />
            default:
                return null
        }
    }

    return (
        <div className={s.CreateCompany}>
            <div className={s.formRow}>
                <InputText 
                    callback={addCompanyName} 
                    value={companyName} 
                    placeholder={props.getValue('COMPANY_NAME')} 
                    readOnly={!props.isEdit} 
                    labelText={props.getValue('COMPANY_NAME')}
                />
            </div>
            <div className={s.formRow}>
                <InputText 
                    callback={addCompanyBin} 
                    value={companyBin} 
                    placeholder={props.getValue('BIN')} 
                    readOnly={!props.isEdit} 
                    labelText={props.getValue('BIN')}
                />
            </div>
            <div className={s.formRow}>
                <InputNumber 
                    callback={addCompanyTopicsCount} 
                    value={companyTopicsCount}
                    placeholder={props.getValue('COUNT')} 
                    showOnly={!props.isEdit} 
                    labelText={props.getValue('COUNT')}
                />
            </div>
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('EXPIRE_DATE')}</span>
                <div className={s.DateBlock}>
                    {<InputDateBlock readOnly={!props.isEdit} values={expiryDate} name={'date'} callback={setDateNumber} maxFirst={31} minFirst={1} maxSecond={12} minSecond={1} maxThird={2030} minThird={2020}/>}
                    {<InputTimeBlock readOnly={!props.isEdit} values={expiryDateTime} name={'hour'} callback={setNumber} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/> }
                </div>

            </div>
            {/*props.type !== 'create' ? <div className={s.formRow}>
                <SelectDropdown 
                    selected={companyStatus} 
                    items={props.companyStatuses} 
                    placeholder={props.getValue('STATUS')} 
                    showOnly={!props.isEdit}
                    labelText={props.getValue('STATUS')} 
                    setSelected={addCompanyStatus}
                />
    </div> : null */}


            <div className={s.formRow}>
                { createButton(props.type)}
            </div>
            
        </div>
    )
}

export default CreateCompany