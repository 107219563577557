import { exit } from "./authorization";
const SET_ERROR_MESSAGE_IN_MODAL = "SET-ERROR-MESSAGE-IN-MODAL";
const SET_AUTHORIZATION_ERROR = "SET-AUTHORIZATION-ERROR";
const SET_SHOW_ERROR_IN_WINDOW = "SET-SHOW-ERROR-IN-WINDOW";
const SET_INGNORE_MODAL_ERROR = "SET-INGNORE-MODAL-ERROR";
const SET_NOTIFICATION_IN_MODAL = "SET-NOTIFICATION-IN-MODAL";
const CHECK_ERROR_MESSAGE = "CHECK-ERROR-MESSAGE";

let errorMessages = {
    errorMessageInModal: null,//{code: null, message: null},
    authorizationError: null,
    showErrorInWindow: null,
    ignoreModalError: null,
    notificationInModal: null
}

const ErrorHandler = (state = errorMessages, action) => {
    switch(action.type){
        case SET_ERROR_MESSAGE_IN_MODAL:
            return {...state, errorMessageInModal: action.errorMessage}
        case SET_NOTIFICATION_IN_MODAL:
            return {...state, notificationInModal: action.notificationInModal}
        case SET_AUTHORIZATION_ERROR:
            return {...state, authorizationError: action.authorizationError}
        case SET_SHOW_ERROR_IN_WINDOW:
            return {...state, showErrorInWindow: action.showErrorInWindow}
        case SET_INGNORE_MODAL_ERROR:
            return {...state, ignoreModalError: action.ignoreModalError}
        default:
            return {...state}
    }
}

export default ErrorHandler;

export const getErrorMessage = (error) => {
    return error.response && error.response.data && error.response.data.ApiError && error.response.data.ApiError.message
                        ? error.response.data.ApiError.message
                        : error.message
}

export const getErrorCodeMessage = (error) => {
    return error.response && error.response.data && error.response.data.ApiError && error.response.data.ApiError.message
                        ? {message: error.response.data.ApiError.message, code: error.response.status}
                        : error.message
}

export const setErrorMessageInModal = (errorMessage) => {
    return{
        type: SET_ERROR_MESSAGE_IN_MODAL,
        errorMessage: errorMessage
    }
}

export const setAuthorizationError = (authorizationError) => {
    return {
        type: SET_AUTHORIZATION_ERROR,
        authorizationError: authorizationError
    }
}

export const setShowErrorInWindow = (showErrorInWindow) => {
    return {
        type: SET_SHOW_ERROR_IN_WINDOW,
        showErrorInWindow: showErrorInWindow
    }
}

export const setIngoreModalError = (ignoreModalError) => {
    return {
        type: SET_INGNORE_MODAL_ERROR,
        ignoreModalError: ignoreModalError
    }
}

export const setNotificationInModal = (notificationInModal) => {
    return {
        type: SET_NOTIFICATION_IN_MODAL,
        notificationInModal, notificationInModal
    }
}

export const checkErrorMessage = (error) => {

    let errorMessage = error && error.code && error.code.response ? error.code.response.status : error;

    return (dispatch) => {
        if(errorMessage.code && errorMessage.code === 401){
            dispatch(exit())
        }
        else if(errorMessage.code && errorMessage.code >= 500){
            console.log("Error message ", errorMessage)
        }
        else{
            dispatch(setErrorMessageInModal(errorMessage))
        }
    }
}

export const setNotification = (notification) => {
    return (dispatch) => {
        dispatch(setNotificationInModal(notification))
    }
}

