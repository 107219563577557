import React from "react";
import EditUser from "./edit-user";

class EditUserController extends React.Component{

    changeUserPassword = (password, checkPassword) => {
        this.props.changeUserPassword(password, checkPassword)
    }

    render(){
        return <EditUser {...this.props} />
    }

}

export default EditUserController;