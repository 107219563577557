import ReportFormController from "./report-form-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getReportSettings, updateReportSettings } from "store/report-settings"

let mapStateToProps = (state) => {
    return {
        selectedReport: state.reportSettings.selectedReport,
        updatedReport: state.reportSettings.updatedReport,
        data: {...state.reportSettings.data},
    }
}

const ReportFormContainer = connect(mapStateToProps, {  getValue, 
                                getReportSettings,
                                updateReportSettings})(ReportFormController)

export default ReportFormContainer