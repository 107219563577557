import React from "react";
import AddTopicsItem from "./add-admin-topic-item";

class AddTopicsItemController extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            markerId: props.markerId,
            topicId: props.topicId,
            topicName: '',
            detachAccountId: null,
            detachTopicId: null
        }
    }

    componentDidMount(){
        //this.props.getAllTopics(0, 20, 'ACTIVE');
        //this.props.getAllMarkers(0, 20, 'ACTIVE');
        
        if(this.props.type === 'topics' && this.props.markerId){
            this.props.getMarkerTopics(this.props.markerId)
        }
        else if(this.props.type === 'markers' && this.props.topicId){
            this.props.getTopicMarkers(this.props.topicId)
        }
    }

    componentDidUpdate(){

        if(this.props.type === 'topics' && this.props.markerId !== this.state.markerId){
            this.props.getMarkerTopics(this.props.markerId);
            this.setState({
                markerId: this.props.markerId
            })
        }
        else if(this.props.type === 'markers' && this.props.topicId !== this.state.topicId){
            this.props.getTopicMarkers(this.props.topicId);
            this.setState({
                topicId: this.props.topicId
            })
        }
    }

    confirm = (type) => {
        if(type === 'delete'){
            this.detachMarkerTopic(this.state.detachAccountId, this.state.detachTopicId)
        }
        else
            this.props.modalShowToggle(false)
        this.setState({
            detachAccountId: null,
            detachTopicId: null
        })
    }

    confirmDetach = (type, accountId, topicId) => {
        this.setState({
            detachAccountId: accountId,
            detachTopicId: topicId
        })
        this.props.modalShowToggle('delete', true, this.confirm, this.props.getValue('DETACH'), this.props.getValue('SURE_WANT_TO_DETACH'), this.props.getValue('DETACH'));
    }

    detachMarkerTopic = (accountId, topicId) => {
        if(this.props.type === 'topics'){
            this.props.detachMarkerTopic(accountId, topicId)
        }
        else if(this.props.type === 'markers'){
            this.props.detachTopicMarker(accountId, topicId)
        }
    }

    getTypeItems = (type) => {
        if(type === 'markers'){
            return this.props.adminMarkers ? this.props.adminMarkers : {content: []}
        }
        else if(type === 'topics'){
            return this.props.adminTopics ? this.props.adminTopics : {content: [], totalPages: 0}
        }
    }

    getItemItems = (type) => {
        if(type === 'markers'){
            return this.props.topicMarkers
        }
        else if(type === 'topics'){
            return this.props.markerTopics
        }
    }

    setTypePage = (type, page) => {
        if(type === 'markers'){
            this.props.setTopicsPage(page)
        }
        else if(type === 'topics'){
            this.props.setTopicsPage(page)
        }
    }

    getItemId = (type) => {
        if(type === 'markers'){
            return this.props.topicId
        }
        else if(type === 'topics'){
            return this.props.markerId
        }
    }

    setSearchText = (text) => {
        this.props.setSearchText(text)
    }

    startSearchText = (start) => {
        this.props.setTopicsPage(0)

        this.props.setStartSearch(start)
    }

    render(){
        return (
            this.getTypeItems(this.props.type) ? <AddTopicsItem 
                            getValue={this.props.getValue}
                            adminTopics={this.props.adminTopics}
                            adminMarkers={this.props.adminMarkers}
                            topicsPage={this.props.topicsPage}
                            detachMarkerTopic={this.confirmDetach}
                            markerTopics={this.props.markerTopics}
                            topicMarkers={this.props.topicMarkers}
                            typeItems={this.getTypeItems(this.props.type)}
                            itemItems={this.getItemItems(this.props.type)}
                            markerId={this.props.markerId}
                            itemId ={this.getItemId(this.props.type)}
                            callback={this.props.callback}
                            setPage={this.props.setPage}
                            type={this.props.type}
                            title={this.props.topic.name ? this.props.topic.name : this.props.topic.login}
                            setSearchText={this.setSearchText}
                            searchText={this.props.searchText}
                            startSearchText={this.startSearchText}
            /> : <div></div>
        )
    }

    componentWillUnmount(){
        this.setSearchText('');
    }
}

export default AddTopicsItemController;