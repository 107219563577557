import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as CheckboxOn } from "assets/icons/checkbox-on.svg"
import { ReactComponent as CheckboxOff } from "assets/icons/checkbox-off.svg";
import { ReactComponent as CheckboxException } from "assets/icons/checkbox-exception.svg";
import CheckBox from "assets/styles/input-checkbox.module.scss";
import InputTextBlock from "assets/styles/input-block.module.scss";
import { ReactComponent as OkIcon } from "assets/icons/ok.svg";
import b from "assets/styles/standart-button.module.scss";
import { ReactComponent as AngelUpIcon } from "assets/icons/angle-up.svg";
import { ReactComponent as AngelDownIcon } from "assets/icons/angle-down.svg";
import useComponentVisible from "hooks/useComponentVisible";
import ErrorMessageLabel from "pages/authorization/signin/error-message/error-message-label";

export const InputCheckBox = (props) => {
    const [checked, setChecked] = useState(props.isChecked);
    const [indexKey, setIndexKey] = useState(props.indexKey);
    const [isDisabled, setIsDisabled] = useState(props.isDisabled);

    useEffect(() => {
        setChecked(props.isChecked);
        setIsDisabled(props.isDisabled);
      }, [props.isChecked, props.isDisabled]);

    const checkboxset = (event, e) => {
        setChecked(e.target.checked)
        props.callback(e.target.checked, props.name, indexKey)
    }

    return (<div className={CheckBox.checkboxBlock} data-checked={props.isChecked ? 'checked' : ''}>
        <label 
            htmlFor={'checkbox_id'+'_'+props.name} 
            datatype={isDisabled ? 'disabled' : ''}
            title={props.title}
            index={props.indexKey}
            >
            {props.isChecked ? <CheckboxOn /> : <CheckboxOff />}
        </label>
        <input 
            onChange={checkboxset.bind(this, props.callback)} 
            //defaultChecked={!!checked}
            disabled={isDisabled}
            type="checkbox" name="checkbox" id={"checkbox_id"+'_'+props.name}
            checked={!!checked} />
    </div>)
}

export const InputCheckBoxException = (props) => {

    const typesIndex = (index) => {
        switch(index){
            case 0:
                return 
        }
    }
    const [checked, setChecked] = useState(Number(props.isChecked));
    const [indexKey, setIndexKey] = useState(props.isChecked)
    const [typeIndex, setTypeIndex] = useState(props.isChecked)
    useEffect(() => {
        setChecked(Number(props.isChecked));
        setTypeIndex(props.isChecked)
      }, [props.isChecked]);



    const checkboxset = (event, e) => {
        setChecked(e.target.checked)
        let index = typeIndex < 1 ? typeIndex+1 : -1
        setTypeIndex(index)
        props.callback(index, props.name, indexKey, true)
    }

    const getIconByIndex = (index) => {
        switch(index){
            case -1:
                return <CheckboxException />;
            case 0:
                return <CheckboxOff />;
            case 1:
                return <CheckboxOn />;
            default:
                return <CheckboxOff />;
        }
    }

    return (<div className={CheckBox.checkboxBlock}>
        {/*<label 
            htmlFor={'checkbox_id'+'_'+props.name} 
            datatype={props.isDisabled ? 'disabled' : ''}
            title={props.title}
            index={props.indexKey}
            >
            {getIconByIndex(props.isChecked)}
    </label>*/}
        <button 
            onClick={checkboxset.bind(this, props.callback)} 
            //defaultChecked={!!checked}
            
            disabled={props.isDisabled}
            type="button" name="checkbox" id={"chkbx_id"+'_'+props.name}
            checked={checked}>
                {getIconByIndex(typeIndex)}
        </button>
    </div>)
}

export const TextArea = (props) => {

    const [text, setText] = useState(props.value ? props.value : '');
    let [height, setHeight] = useState(props.height ? props.height : 12);
    const [reRender, setRerender] = useState(false)

    useEffect(() => {
        setText(props.value ? props.value : '');
        setHeight(props.height ? props.height : 12);
        setRerender(!reRender)
      }, [props.value ? props.value : '', props.height]);

    const onPasteText = (e) => {
        if(props.onPasteText){
            setTimeout(()=>{
                let value = e.target.value;
                props.onPasteText(value)
            }, 100)
        }
    }

    const inputChange = (event, e) => {
        //event.target.value = event.target.value.replace(/[\r\n\v]+/g, '')
        let ttext = e.target.value//.replace(/[\r\n\v]+/g, '');
        //if(ttext !== ''){
            props.callback(ttext.trim())
            setText(ttext)
        //}

        //setTimeout(()=>{setText(ttext.replace(/[\r\n\v]+/g, ''))}, 5)
        

    }

    const goto = (event, e,) => {
        let txt = text.trim()
        if(e.key === "Enter" && props.goto){
            if(txt && txt !== '' && txt !== ' ' && txt !== '\n'){
                txt !== '\n' ? props.goto(txt) : null
            
                setTimeout(() => {
                    setRerender(!reRender);
                    setText('')
                }, 10)
            }

        }
    }
    let classE = props.showError ? InputTextBlock.inputBlock+" "+InputTextBlock.inputError : InputTextBlock.inputBlock;
    let classN = InputTextBlock.inputWrapper+" "+(props.readOnly ? InputTextBlock.readOnly : InputTextBlock.inputBlock);
    let style = null;

    if(props.bordered){
        classN += ' '+InputTextBlock.bordered
    }
    if(props.resize === false){
        classN += ' '+InputTextBlock.notResized
    }

    if(props.rows){
        style += {minHeight: height*props.rows*props.rows};
    }

    return (
        <div className={classE}>
            <div className={InputTextBlock.block}>
                {props.labelText ? <div className={InputTextBlock.LabelText}><span>{props.labelText}</span></div> : ''}
                <div style={{minHeight: height*props.rows}} className={classN}>
                    <textarea onPaste={onPasteText} rows={props.rows} title={props.title ? props.title : null} placeholder={props.placeholder} onKeyDown={goto.bind(this, props.updateKeyWordsText)} onChange={inputChange.bind(this, props.callback)} type={!props.type ? 'text' : props.type} value={text} readOnly={props.readOnly}/>
                </div>
            </div>


        </div>
    )
}

export const InputText = (props) => {

    let [text, setText] = useState(props.value || props.value !== undefined ? props.value : '');
    const [error, setError] = useState(props.error ? props.error : false);
    useEffect(() => {
        setText(props.value || props.value !== undefined ? props.value : '');
        setError(props.error ? props.error : false);
      }, [props.value, props.error]);

    const inputChange = (event, e) => {
        text = e.target.value;
        setText(text)
        props.callback ? props.callback(text, e) : null
    }

    const goto = (event, e) => {
        e.key === "Enter" && props.goto(text)
    }

    let classE = error ? InputTextBlock.inputBlock+" "+InputTextBlock.inputError : InputTextBlock.inputBlock;
    let classN = InputTextBlock.inputWrapper+" "+(props.readOnly ? InputTextBlock.readOnly : InputTextBlock.inputBlock)
    if(props.bordered){
        classN += ' '+InputTextBlock.bordered
    }

    return (
        <div className={classE}>
            <div className={InputTextBlock.block}>
                {props.labelText 
                                ?   <div className={InputTextBlock.LabelText}>
                                        {error ? <ErrorMessageLabel message={error}/> : null}
                                        <span>{props.labelText}</span>
                                    </div> 
                                : ''}
                <div className={classN}>
                    <input title={props.title ? props.title : null} placeholder={props.placeholder} onKeyDown={goto.bind(this, props.updateKeyWordsText)} onChange={inputChange.bind(this, props.callback)} type={!props.type ? 'text' : props.type} value={text} readOnly={props.readOnly}/>
                </div>
            </div>


        </div>
    )
}

export const InputNumber = (props) => {
    let [numberS, setNumberS] = useState(props.value ? props.value : 0);
    const numberInput = useRef(props.value ? props.value : 0);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    useEffect(() => {
        setErrorMessage(props.errorMessage);
        setNumberS(props.value);
      }, [props.errorMessage, props.value]);

    /*const setNumber = (e) => {
        numberInput.current.value = null
        props.callback(numberS);
        
        //setErrorMessage(null)
    }*/

    const numberChange = (event, e) => {
        numberS = Math.round(Number(event.target.value));
        props.callback(numberS);
    }
    const goto = (e) => {
        e.key === "Enter" && setNumber();
    }
    return(
        <div className={errorMessage ? InputTextBlock.inputBlock+" "+InputTextBlock.inputError : InputTextBlock.inputBlock}>
            {props.labelText ? <div className={InputTextBlock.LabelText}><span>{props.labelText}</span></div> : ''}
            <div className={InputTextBlock.inputWrapper+" "+InputTextBlock.NumberInput}>
                <input 
                    style={{width: props.width ? props.width : ''}} 
                    type="number" 
                    disabled={props.showOnly} 
                    value={numberS} 
                    onKeyDown={goto.bind(this)} 
                    onChange={numberChange} 
                    max={props.max} 
                    min={props.min} 
                    ref={numberInput}
                    readOnly={props.readOnly}
                />
                {props.withButton
                && <button 
                        disabled={!numberInput} 
                        onClick={setNumber} className={b.standartButton+" "+b.whiteButton}>
                            <OkIcon />
                    </button>
            }
            </div>
            {errorMessage && <div className={InputTextBlock.errorMessage}>{errorMessage}</div>}
        </div>
    )
}

export const InputTime = (props) => {
    const max = props.type === 'hour' ? 24 : 60;
    const min = 0;

    const valChange = (e) => {
        props.callback(e.value)
    }

    const valueChange = (e) => {
        props.callback(props.value)
    }

    const addValue = () => {
        if(parseInt(props.value)+1 < max){
            let n = parseInt(props.value)+1;
            props.callback(n)
        }
    }

    const removeValue = () => {
        if(parseInt(props.value)-1 >= min){
            let n = parseInt(props.value)-1;
            props.callback(n) 
        }
    }

    const onMouse = () => {
        props.callback()
    }

    const overMouse = (e) => {
        if(parseInt(e.target.value)){
            if(parseInt(e.target.value) > max || parseInt(e.target.value) < min){
                props.callback('00')
            }
            else{
                props.callback(e.target.value)
            }         
        }
        else{
            props.callback('00')
        }
        
    }

    return (
        <div className={InputTextBlock.inputBlock}>
            <div className={InputTextBlock.inputWrapper+" "+InputTextBlock.TimeInput}>
                <input onMouseDown={onMouse} onMouseLeave={overMouse} type="text" value={(parseInt(props.value) < 10 && (props.value+"").length < 2) ? '0'+''+props.value : props.value} onChange={valChange} onKeyDown={valueChange.bind(this)}/>
                <div className={InputTextBlock.ButtonsBlock}>
                    <button onClick={addValue}><AngelDownIcon /></button>
                    <button onClick={removeValue}><AngelUpIcon /></button>
                </div>
            </div>
        </div>

    )
}

export const InputTimeBlock = (props) => {
    
    let first = useRef();
    let [firstNumber, setFirstNumber] = useState(props.values ? props.values.h : 0);
    let second = useRef();
    let [secondNumber, setSecondNumber] = useState(props.values ? props.values.m : 0);

    const [rerender, setRerender] = useState(false);
    const [readOnly, setReadOnly] = useState(props.readOnly);

    useEffect(() => {
        setFirstNumber(props.values ? props.values.h : 0);
        setSecondNumber(props.values ? props.values.m : 0);
        setRerender(!rerender);
        setReadOnly(props.readOnly);
      }, [props.errorMessage, props.values, props.readOnly]);

    const numberChange = (type, e) => {    
        if(type === 'first'){
            first.current.value = Math.round(Number(e.target.value));
            e.target.value <= props.maxFirst 
                ? (setFirstNumber(e.target.value), props.callback(props.name,Number(e.target.value), type)) 
                : (setFirstNumber(props.maxFirst), props.callback(props.name,props.maxFirst, type))
        }
        else{
            second.current.value = Math.round(Number(e.target.value));
            e.target.value <= props.maxSecond 
                ? (setSecondNumber(e.target.value), props.callback(props.name,Number(e.target.value), type) ) 
                : (setSecondNumber(props.maxSecond), props.callback(props.name,props.maxSecond, type)) 
        }

    }

    return (
        <div className={InputTextBlock.TimeBlockWrapper}>
            {props.label && <div className={InputTextBlock.LabelText}><span>{ props.label }</span></div>}
            <div className={InputTextBlock.TimeBlock}>
                <input readOnly={readOnly} value={firstNumber < 10 && firstNumber !== "00" ? "0"+firstNumber : firstNumber} ref={first} onChange={numberChange.bind(this, 'first')} type={readOnly ? 'text' : 'number'} max={props.maxFirst} min={props.minFirst}/> 
                <span className={InputTextBlock.separator}>:</span> 
                <input readOnly={readOnly} value={secondNumber < 10 && secondNumber !== "00" ? "0"+secondNumber : secondNumber} ref={second} onChange={numberChange.bind(this, 'second')} type={readOnly ? 'text' : 'number'} max={props.maxSecond} min={props.minSecond}/>
            </div>
        </div>

    )
}

export const InputDateBlock = (props) => {
    let day = useRef();
    let [dayNumber, setDayNumber] = useState(props.values ? props.values.d : 0);
    let month = useRef();
    let [monthNumber, setMonthNumber] = useState(props.values ? props.values.m : 0);
    let year = useRef();
    let [yearNumber, setYearNumber] = useState(props.values ? props.values.y : 0);

    const [rerender, setRerender] = useState(false);
    useEffect(() => {
        setDayNumber(props.values ? props.values.d : 0);
        setMonthNumber(props.values ? props.values.m : 0);
        setYearNumber(props.values ? props.values.y : 0);
        setRerender(!rerender);
      }, [props.errorMessage, props.values]);


    const numberChange = (type, e) => {
        switch(type){
        case 'day':
            day.current.value = Math.round(Number(e.target.value));
            e.target.value <= props.maxFirst 
                ? props.callback(props.name,Number(e.target.value), type)
                : props.callback(props.name,props.maxFirst, type)
            break;
        case 'month':
            month.current.value = Math.round(Number(e.target.value));
            e.target.value <= props.maxSecond 
                ? props.callback(props.name,Number(e.target.value), type)
                : props.callback(props.name,props.maxSecond, type)
            break;
        case 'year':
            year.current.value = Math.round(Number(e.target.value));
            e.target.value <= props.maxThird 
                ? props.callback(props.name,Number(e.target.value), type) 
                : props.callback(props.name,props.maxThird, type)
            break
        }
    }

    return (
        <div className={InputTextBlock.DateBlockWrapper}>
            {props.label && <div className={InputTextBlock.LabelText}><span>{ props.label }</span></div>}
            <div className={InputTextBlock.TimeBlock}>
                <input readOnly={props.readOnly} value={dayNumber < 10 ? dayNumber : dayNumber} ref={day} onChange={numberChange.bind(this, 'day')} type="number" max={props.maxFirst} min={props.minFirst}/> 
                <span className={InputTextBlock.separator}>-</span> 
                <input readOnly={props.readOnly} value={monthNumber < 10 ? monthNumber : monthNumber} ref={month} onChange={numberChange.bind(this, 'month')} type="number" max={props.maxSecond} min={props.minSecond}/>
                <span className={InputTextBlock.separator}>-</span> 
                <input readOnly={props.readOnly} value={yearNumber < 10 ? yearNumber : yearNumber} ref={year} onChange={numberChange.bind(this, 'year')} type="number" max={props.maxThird} min={props.minThird}/>
            </div>
        </div>

    )
}

export const SelectDropdown = (props) => {
    let [selected, setSelected] = useState(props.selected);
    let {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);
    let [notUppercase, setNotUppercase] = useState(props.notUppercase ? props.notUppercase : true)
 
    useEffect(()=>{
        setSelected(props.selected)
    })

    let showDropdown = () =>{
        if(!props.showOnly)
            setIsComponentVisible(!isComponentVisible)
    }

    let setSelectedItem = (item) => {
        typeof(item) === 'object' ? setSelected(item.name) : setSelected(item);
        props.setSelected ? props.setSelected(item, Number(props.indexKey) >= 0 ? Number(props.indexKey) : null) : null
    }

    return (
        <div style={{width: '100%'}} ref={ref}>
            {props.labelText ? <div className={InputTextBlock.LabelText}><span>{props.labelText}</span></div> : ''}
            <div onClick={showDropdown} className={InputTextBlock.SelectDropdown}>
                
                <div className={InputTextBlock.SelectRow}>
                    { selected !== null && selected !== '' ? <span className={InputTextBlock.SelectedText}>{selected}</span> : <span className={InputTextBlock.DropdownPlaceholder}>{props.placeholder}</span>}
                    { !props.showOnly && <AngelUpIcon />}
                </div>
                
                {isComponentVisible && <div className={InputTextBlock.SelectOption} style={{maxHeight: props.height ? props.height : 'auto'}}>
                    {(props.items && isComponentVisible) && props.items.map((e, i) => {
                        return <div onClick={setSelectedItem.bind(this, e)} key={i} className={InputTextBlock.Option}>
                                    <span >{typeof(e) === 'object' ? e.name : e}</span>
                                </div>
                    })}
                </div>}
            </div>
        </div>

    )
}