import { createSelector } from "reselect";

const topicBlackListState = (state) => {
    return state.BlackList.topicBlackList
}


export const getTopicBlackListData = createSelector(topicBlackListState, (blackListd) => {
    let bl = []
    if(blackListd.length){
        bl = blackListd.map((k, i) => {
            let type = k.url ? 'PROFILE' : 'SOURCE_2';
            return [i+1, k.id, k.topicId, k.name, k.url, k.domain, type]  
        });
        return bl
    }
    else{
        return []
    }
})

