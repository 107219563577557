import React from "react";
import ButtonBlock from "./buttons-block";

class ButtonsBlockController extends React.Component{
    
    showExitModal = (e, name) => {
        if(e === 'Exit'){
            if(this.props.role && this.props.role === 'ROLE_SUPER_ADMIN' && (this.props.adminCompanyId, this.props.accountId)){
                this.props.exitCompany(this.props.companyId, this.props.accountId);
            }
            this.props.exit();

        }
        
        else if(e === 'exitCompany') this.props.exitCompany(this.props.adminCompanyId, this.props.accountId);
    }

    setSelectedPage = (name, type, text, title) => {
        if(type === 'modal'){
            switch(name){
                case 'Exit':
                    this.props.modalShowToggle(name, true, this.showExitModal, title, this.props.getValue('SURE_WANT_TO_EXIT'), this.props.getValue("EXIT"));
                    break;
                case 'ExitCompany':
                    this.props.modalShowToggle('exitCompany', true, this.showExitModal, title, this.props.getValue('SURE_WANT_TO_EXIT_COMPANY'), this.props.getValue('EXIT'));
                    break;
                case 'CallUs':
                    this.props.modalShowToggle(name, true, null, this.props.getValue('FEEDBACK_FORM'))
                    break;
                case 'NOTIFICATIONS':
                    this.props.modalShowToggle(name, true, null, this.props.getValue('STATUSES_OF_SERVER_REQUESTS'));
                    break;
                case 'ACCOUNT':
                    this.props.modalShowToggle('Account', true, null, this.props.getValue('ACCOUNT'));
                    break;
            }
            /*if(name === "Exit"){
                this.props.modalShowToggle(name, true, this.showExitModal, title, 'Вы действительно хотите выйти из системы?', "Выйти");
            }
            else{
                this.props.modalShowToggle(name, true, null, "Форма обратной связи")
            }*/

        }
        else
            this.props.selectPage(name);
            
    }

    setClearSelected = (page) => {
        this.props.setClearSelected(true);
        this.props.clearAllSelected()
    }

    render(){
        return (
            <ButtonBlock {...this.props} setSelectedPage={this.setSelectedPage} clearSelected={this.setClearSelected}/>
        )
    }
}

export default ButtonsBlockController;