import PanelListOfPublicationController from "./panel-list-of-publications-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../hoc/AuthRedirect";
import { setShowLeftMenu, setShowRightMenu } from "../../store/side-bar";
import { modalShowToggle } from "../../store/modal";
import { getValue } from "../../store/languages"
import { setSelectAllItems, getNewPublicationsCount, setSelectedAllList } from "../../store/storage"

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        showLeftMenu: state.SideBarMenu.showLeftMenu,
        showRightMenu: state.SideBarMenu.showRightMenu,
        selectedLanguage: state.languages.selectedLanguage,
        isAuth: state.authorization.isAuth,
        paginator: state.header.paginator,
        postsCount: state.storage.storageData.numFoundTotal,
        posts: state.storage.storageData.docs,
        showedItemsCount: state.paginator.showedItemsCount,
        selectedPostItems: state.storage.selectedPostItems,
        selectedAllList: state.storage.selectedAllList,
        //selectedUUId: state.Tree.selectedUUId,
        statisticPostsCount: state.storage.statisticPostsCount,
        newPostsCount: state.storage.newPostsCount,
        page: state.storage.page,
    }
}

export default 
    connect(mapStateToProps, {setShowLeftMenu, setShowRightMenu, modalShowToggle, getValue, setSelectAllItems, setSelectedAllList, getNewPublicationsCount})(PanelListOfPublicationController)