const SET_RETURN_BACK_PAGE_NAME = "SET-RETURN-BACK-PAGE-NAME";
const SET_RETURN_BACK_FILTERS = "SET-RETURN-BACK-FILTERS";
const SET_RETURN_BACK_MENU_SELECTED = "SET-RETURN-BACK-MENU-SELECTED";
const SET_RETURN_BACK_GROUPING = "SET-RETURN-BACK-GROUPING";
const SET_IS_RETURNED_BACK = "SET-IS-RETURNED-BACK";
const SET_RETURN_BACK_POST_COUNT = "SET-RETURN-BACK-POST-COUNT";

const returnBackData = {
    pageName: null,
    menuSelected: null,
    grouping: null,
    isReturnedBack: false,
    docsCount: 0,
    filters: {}
}

const ReturnBack = (state = returnBackData, action) => {
    switch(action.type){
        case SET_RETURN_BACK_PAGE_NAME:
            return {...state, pageName: action.pageName}
        case SET_RETURN_BACK_FILTERS:
            return {...state, filters: action.filters}
        case SET_RETURN_BACK_MENU_SELECTED:
            return {...state, menuSelected: action.menuSelected}
        case SET_RETURN_BACK_GROUPING:
            return {...state, grouping: action.grouping}
        case SET_IS_RETURNED_BACK:
            return {...state, isReturnedBack: action.isReturnedBack}
        case SET_RETURN_BACK_POST_COUNT:
            let docsCount = action.docsCount
            return {...state, docsCount: docsCount}
        default:
            return {...state}
    }
}

export default ReturnBack;

export const setReturnBackPostCount = (docsCount) => {
    return {
        type: SET_RETURN_BACK_POST_COUNT,
        docsCount, docsCount
    }
}

export const setIsReturnedBack = (isReturnedBack) => {
    return {
        type: SET_IS_RETURNED_BACK,
        isReturnedBack: isReturnedBack
    }
}

export const setReturnBackGrouping = (grouping) => {
    return {
        type: SET_RETURN_BACK_GROUPING,
        grouping: grouping
    }
}

export const setMenuSelected = (menuSelected) => {
    return {
        type: SET_RETURN_BACK_MENU_SELECTED,
        menuSelected: menuSelected
    }
}

export const setReturnBackPageName = (pageName) => {
    return{
        type: SET_RETURN_BACK_PAGE_NAME,
        pageName: pageName
    }
}

export const setReturnBackFilters = (filters) => {
    return{
        type: SET_RETURN_BACK_FILTERS,
        filters: filters
    }
}
