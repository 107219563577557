import React from "react";
import FiltersItem from "./filters-item";
import { getFormatedDate } from "service/format-date";
import moment from 'moment';

class FiltersItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedMyFilterId: null
        }
    }

    closeMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    componentDidMount(){
        if(this.props.selectedMyFilterId.id){
            this.setState({
                selectedMyFilterId: this.props.selectedMyFilterId.id
            })
            this.props.getMyFilter(this.props.selectedMyFilterId.id, true)
            //this.props.setTreeSelectedItems(0, 'a66a37ae-89f3-455b-bb2c-882ed94bbb0a', 2381)
        }
        if(!this.props.tags || !this.props.tags.length && this.props.role !== "ROLE_MARKER"){
            this.props.getCompanyTags(this.props.companyId);
        }
    }

    componentDidUpdate(){
        if((this.props.selectedMyFilterId && this.props.selectedMyFilterId.id) !== this.state.selectedMyFilterId){
            this.setState({
                selectedMyFilterId: this.props.selectedMyFilterId.id
            })
            this.props.getMyFilter(this.props.selectedMyFilterId.id, true)
            //this.props.setTreeSelectedItems(0, 'a66a37ae-89f3-455b-bb2c-882ed94bbb0a', 2381)
        }

    }

    getFormatedDate = (date) => {
        return getFormatedDate(date)
    }

    getFiltersList = (startDate, endDate, topics, fields, subtopics) => {
        this.props.getTopicsFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, subtopics.length && [...subtopics]);  
    }

    render(){
        return(
            <FiltersItem {...this.props} getFiltersList={this.getFiltersList}/>
        )
    }
}

export default FiltersItemController;