import HeaderController from "./header-controller";
import { connect } from "react-redux";
import { selectPage, setShowPanel, setDeviceType } from "../store/header";
import { modalShowToggle } from "../store/modal";
import { exit } from '../store/authorization';
import { getMe } from "../store/account";
import { setlocale, setSelectedLanguage, setSelectedUnAuthorizedLanguage } from "../store/languages";
import { setSelectedMainTheme } from "../store/main-theme";
import { getValue } from "../store/languages";
import { setErrorMessageInModal, setNotificationInModal } from "../store/errors";
import { setSuperadminMenuButtons } from "store/header";
import { removeSuperAdminCompany } from "store/superadmin"
import { setPageName, setClearSelected, setMySelectedFilter, setMySelectedTopicsFilter } from "store/filters";
import { setReturnBackFilters, setReturnBackPageName, setMenuSelected } from "store/return-back-button";
import { setTreeSelectedItems, setTreeSelectedUUId, setToNull } from "store/tree";
import { setSettingsMenuToNull } from "store/settings-menu";
import { clearSearchList } from 'store/search-text';
import { setIsReturnedBack } from "store/return-back-button";
//import { requestForToken } from '../utils/firebase2'

let mapStateToProps = (state) => {
    return {
        isAuth: state.authorization.isAuth,
        login: state.Account.login,
        role: state.Account.role,
        accountId: state.Account.id,
        companyId: state.Account.companyId,
        topButtons: state.header.topButtons,
        mediumButtons: state.header.mediumButtons,
        adminMediumButtons: state.header.adminMediumButtons,
        superAdminTopButtons: state.header.superAdminTopButtons,
        superAdminMediumButtons: state.header.superAdminMediumButtons,
        markerMediumButtons: state.header.markerMediumButtons,
        rightTopButtons: state.header.rightTopButtons,
        rightBottomButton: state.header.rightBottomButton,
        superAdminRightTopButtons: state.header.superAdminRightTopButtons,
        superAdminRightBottomButtons: state.header.superAdminRightBottomButtons,
        markerRightTopButtons: state.header.markerRightTopButtons,
        markerTopButtons: state.header.markerTopButtons,
        defaultRightTopButtons: state.header.defaultRightTopButtons,
        selected: state.header.selected,
        showMenu: state.header.showMenu,
        isShowPanel: state.header.isShowPanel,
        selectedLanguage: state.languages.selectedLanguage,
        icon: state.languages.icon,
        LanguageItems: state.languages.LanguageItems,
        AccountItems: state.header.AccountItems,
        ThemeItems: state.mainThemes.ThemeItems,
        selectedTheme: state.mainThemes.selectedTheme,
        themeIcon: state.mainThemes.icon,
        errorMessage: state.ErrorHandler.errorMessageInModal,
        notificationInModal: state.ErrorHandler.notificationInModal,
        companies: state.superadmin.companies,
        companyName: state.superadmin.companyName,
        adminCompanyId: state.Account.companyId,
        pageName: state.Filters.pageName,
        selectedMyFilter: state.Filters.selectedMyFilter,
        applyedFilters: state.Filters.applyedFilters,
        deviceType: state.header.deviceType
    }
}

const HeaderContainer = connect(mapStateToProps, { selectPage, setDeviceType, modalShowToggle, exit, getMe, setlocale, setSelectedMainTheme, getValue, setShowPanel, setErrorMessageInModal, setNotificationInModal, setSelectedLanguage, setSelectedUnAuthorizedLanguage, setSuperadminMenuButtons, removeSuperAdminCompany, setPageName, setClearSelected, setReturnBackFilters, setReturnBackPageName, setMenuSelected, setToNull, setSettingsMenuToNull, setMySelectedFilter, setMySelectedTopicsFilter, clearSearchList, setIsReturnedBack })(HeaderController);
export default HeaderContainer;