import React, { useState, useEffect } from "react";
import { InputText } from "commons/input-controls";
import s from "./tags-item.module.scss";
import { StandartButton } from "commons/form-controls";

const TagsItem = (props) => {
    const [tagName, setTagName] = useState(props.tag ? props.tag.name : null);
    const [colors, setColors] = useState(props.colors);
    const [textColor, setTextColor] = useState(props.tag ? props.tag.textColor : null);
    const [tagColor, setTagColor] = useState(props.tag ? props.tag.color : null);

    useEffect(() => {
        setTagName(props.tag ? props.tag.name : null);
        setColors(props.colors);
        setTagColor(props.tag ? props.tag.color : null);
        setTextColor(props.tag ? props.tag.textColor : null);
    }, [props.tag])

    const getTagTextColor = (color) => {
        let tg = colors.findIndex((k,i) => k.color === color)
        let colorFinded = tg !== -1 ? colors[tg].textColor : color === '#000000' ? '#FFFFFF' : '#000000';
        return colorFinded
    }

    const addTagName = (e) => {
        setTagName(e)
    }

    const editTag = () => {
        props.updateCompanyTag(props.companyId, props.tag.id, {name: tagName, color: tagColor, textColor: getTagTextColor(tagColor)})
    }

    const selectColor = (e) => {
        setTagColor(e)
    }

    const tagsColorsItems = colors.map((k, i) => <div className={tagColor === k.color ? s.tagColorItem+' '+ s.active : s.tagColorItem} key={i} style={{background: k.color, color: textColor}} onClick={selectColor.bind(this, k.color)}></div>)

    return(
        <div className={s.TagsItem}>
            <div className={s.formRow}>
                <InputText callback={addTagName} value={tagName} placeholder={props.getValue('TEXT_OF_TAG')} readOnly={props.isEdit === 'show' && true} labelText={props.getValue('TEXT_OF_TAG')}/>
            </div>
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('FILL_COLOR')}</span>
                <div className={s.TagsColors}>
                    {tagsColorsItems}
                </div>
            </div>
            <div className={s.formRow}>
                <StandartButton disabled={!tagName || tagName === ''} title={props.getValue('UPDATE')} fill='white' callback={editTag.bind(this, "edit")}/>
            </div>
        </div>
    )
}

export default TagsItem