import React from "react";
import Analytic from "./analytic";
import SettingsMenuContainer from "pages/settings-page/menu/settings-menu-container";
import PeriodCalendarContainer from "items/period-calendar/period-calendar-container";
import FiltersContainer from "items/filters/filters-container";
import MyFiltersContainer from "items/myFilters/my-filters-container";
import TopicMenuContainer from "pages/topic-menu/topic-menu-container";
import { getFormatedDate } from "service/format-date";

class AnalyticController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selected: props.selected,
            selectedLanguage: this.props.selectedLanguage,
            selectedTopics: this.props.selectedTopics && this.props.selectedTopics.length ? this.props.selectedTopics.join(",") : "",
            selectedSubtopics: this.props.selectedSubtopics,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            grouping: this.props.grouping,
            topics: this.props.topics,
            firstStart: false,
            firstChart: this.props.first.chartId,
            firstTopicIDs: this.props.first.topicIDs,
            firstLabels: this.props.first.labels.join(","),
            firstTotal: this.props.first.total,
            secondChart: this.props.second.chartId,
            secondTopicIDs: this.props.second.topicIDs,
            secondLabels: this.props.second.labels.join(","),
            secondTotal: this.props.second.total,
            thirdChart: this.props.third.chartId,
            locationsName: this.props.locationsName ? Object.values(this.props.locationsName) : '' //Object.values(nextProps.locationsDictionary).join(",")
        }
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date);
    }

    getFiltersList = (startDate, endDate, topics, fields, subtopics, filters) => {
        this.props.getFiltersStatistics(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);  
    }

    getData = (menuId, selectedUUid, startDate, endDate, grouping, filters, selectedSubUUId, tags, isFetchingAnalytic) => {
        if(!isFetchingAnalytic)
        this.props.getDataByType(this.props.menuItems[menuId].title, selectedUUid, this.getFormatedDate(startDate), this.getFormatedDate(endDate), grouping, filters, selectedSubUUId, this.props.tags);
    }

    showRightMenu = (topics) => {
        this.props.setShowRightMenu(true);
        this.props.setRightMenuComponent(<PeriodCalendarContainer footerButtons={'grouping'}/>, 'periodCalendar', true);
        this.props.setRightMenuComponent(<MyFiltersContainer />, 'myFilters', true)
       if(topics && topics.length){
            this.props.setPageName('Analytic')
            this.props.setRightMenuComponent(<FiltersContainer type={'Analytic'} topics={[topics]} getFiltersList={this.getFiltersList}/>, 'filters')
       }
    }



    showLeftMenu = (topics) => {
        if(topics && topics.length){
            this.props.setLeftMenuComponent(
                <TopicMenuContainer 
                    items={topics}
                    selected={this.props.selectedTopics}
                    showSubtopics={true}
                    type={'tree'}
                />, 'topics', true)
        }
    }

    showInfrastructure = () => {
        this.props.setMenus(this.props.menu);
        this.showRightMenu(this.props.topics && this.props.topics.length ? this.props.topics[0] : null)
        this.props.setShowLeftMenu(true);
        this.showLeftMenu(this.props.topics);
        this.props.setShowPanel(true)
    }

    componentDidMount(){
        /*if(this.props.startDate && this.props.endDate){
            this.props.setGroupingByPeriod(this.props.startDate, this.props.endDate);
           // this.props.setGr
        }*/
        if(this.props.selectedTopics && this.props.selectedTopics.length && this.props.selectedTopics.length > 1){
            this.props.setSelectedTopicsAll([this.props.selectedItems[0]])
        }
        this.showInfrastructure();
        this.props.getMyTopics();
        this.setState({
            firstStart: true
        })
    }

    checkUpdate = (props, state, updateAll = false, ) => {
        if(updateAll){
            if( props.selected !== state.selected
                || props.selectedTopics.join(',') !== state.selectedTopics
                || props.selectedSubtopics.join(',') !== state.selectedSubtopics
                || props.startDate !== state.startDate
                || props.endDate !== state.endDate
                || props.grouping !== state.grouping
                || props.first.chartId !== state.firstChart
                || props.first.labels.join(',') !== state.firstLabels
                || props.first.topicIDs !== state.firstTopicIDs
                || props.first.total !== state.firstTotal
                || props.second.chartId !== state.secondChart
                || props.second.topicIDs !== state.secondTopicIDs
                || props.second.labels.join(',') !== state.secondLabels
                || props.second.total !== state.secondTotal
                || props.third.chartId !== state.thirdChart
                || props.isChanged
                //|| props.menuItems[props.selected].charts[0] !== props.first.chartId
                || props.locationsName && Object.values(props.locationsName).join(",") !== state.locationsName
            ){
                    return true
            }
            else return false
        }
        else{
            if( props.selected !== state.selected
                || props.startDate !== state.startDate
                || props.endDate !== state.endDate
                || props.grouping !== state.grouping
                || props.selectedTopics.join(',') !== state.selectedTopics
                || props.selectedSubtopics.join(',') !== state.selectedSubtopics
                || props.isChanged
            ){
                return true
            }
            else return false
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.isFetchingAnalytic){
            setTimeout(() => {
                this.props.setAnalyticIsFetching(false)
            }, 300)
            
        }
        if(this.props.leftMenuComponent.findIndex( c => c.componentName === 'settingsMenu') === -1){
            this.props.setLeftMenuComponent(<SettingsMenuContainer items={this.props.menuItems}/>, 'settingsMenu')
        }
        if(nextProps.topics && nextProps.topics.length && (!nextState.topics || nextState.topics == undefined )){
            this.showLeftMenu(nextProps.topics);
            this.showRightMenu(nextProps.selectedTopics)
            this.setState({
                topics: nextProps.topics, 
            })
            return true
        }
        if(nextProps.topics && nextProps.topics.length && nextState.firstStart && !nextProps.selectedTopics.length){ 
           let t = {name: '', id: ''}
            t.name = nextProps.topics[0].name
            t.id = nextProps.topics[0].uuid
            this.props.setSelectedTopicsAll([t])
        }
        if(nextProps.selectedTopics && nextProps.selectedTopics.length && nextProps.selectedTopics.length > 1){
            this.props.setSelectedTopicsAll([nextProps.selectedItems[0]])
        }
        if(!isNaN(this.props.first.total)){
            this.props.setReturnBackPostCount(this.props.first.total) 
        }
        if(this.checkUpdate(nextProps, nextState, true)){
            let filters = nextProps.selectedMyFilter;
            filters.clauses = nextProps.searchList
            this.props.setSelectedFiltersTopics(nextProps.selectedTopics)
            if(nextProps.isChanged)
                this.props.setIsFilterChanged(false)
            if(this.checkUpdate(nextProps, nextState, false) && nextProps.selectedTopics.length){
                this.getData(nextProps.selected, nextProps.selectedTopics, nextProps.startDate, nextProps.endDate, nextProps.grouping, filters, nextProps.selectedSubtopics, nextProps.tags, nextProps.isFetchingAnalytic)
                this.showRightMenu(nextProps.selectedTopics)
            }
            
            this.setState({
                selected: nextProps.selected,
                selectedTopics: nextProps.selectedTopics.join(','),
                selectedSubtopics: nextProps.selectedSubtopics.join(','),
                firstChart: nextProps.first.chartId,
                firstLabels: nextProps.first.labels.join(","),
                firstTopicIDs: nextProps.first.topicIDs,
                firstTotal: nextProps.first.total,
                secondChart: nextProps.second.chartId,
                secondTopicIDs: nextProps.second.topicIDs,
                secondLabels: nextProps.second.labels.join(","),
                secondTotal: nextProps.second.total,
                thirdChart: nextProps.third.chartId,
                startDate: nextProps.startDate,
                endDate: nextProps.endDate,
                grouping: nextProps.grouping,
                locationsName: nextProps.locationsName ? Object.values(nextProps.locationsName).join(",") : ""
            })
            
            return true
        }
        else return false
    }

    componentWillUnmount(){
        this.props.setRemoveLeftMenuComponent();
        this.props.setRemoveRightMenuComponent();
        this.props.setShowLeftMenu(false);
        this.props.setShowRightMenu(false);
        this.props.setShowPanel(false);
    }

    render(){
        return <div>
            <Analytic firstData={this.props.first} secondData={this.props.second} thirdData={this.props.third} getValue={this.props.getValue} locationsName={this.props.locationsName}/>
        </div>
        
    }
}

export default AnalyticController