import { header, getLanguage, checkFilters } from "./header";

const report = header('report/api/v1/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    report.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    report.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const ReportService = {
    getReport(type, topicId, email, since, till, grouping = "DAILY", filters = null, feedType, signatures){        
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters), feedType}
        if(signatures && signatures.length) params.signatures = signatures
        setLanguage();
        getAuth();
        return report.post(`report/export/${type}/topics/${topicId}?emails=${email}`, {...params}).then(response => {
            return response.data
        })
    },
    getMultipleTopicReport(type, topicUUId, email, since, till, grouping = "DAILY", filters = null, feedType, signatures){        
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters), feedType}
        if(signatures && signatures.length) params.signatures = signatures
        setLanguage();
        getAuth();
        return report.post(`report/export/${type}?topics=${topicUUId}&emails=${email}&feedType=${feedType}`, {...params}).then(response => {
            return response.data
        })
    },
    getSubtopicReport(docType, topicId, subtopicId, email, since, till, grouping = "DAILY", filters = null){
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters)}
        setLanguage();
        getAuth();
        return report.post(`report/export/${docType}/topics/${topicId}/subtopics/${subtopicId}?emails=${email}`, {...params}).then(response => {
            return response.data
        })
    },
    getReportOperations(pageIndex = 0, pageSize = 9999){
        setLanguage();
        getAuth();
        return report.get(`report-operations?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(response => {
            return response.data
        })
    },
    getCompanyReportOperations(pageIndex = 0, pageSize = 9999){
        setLanguage();
        getAuth();
        return report.get(`admin/report-operations?pageIndex=${pageIndex}&pageSize=${pageSize}`).then(response => {
            return response.data
        })
    },
    retryReportOperation(reportOperationId){
        setLanguage();
        getAuth();
        return report.post(`report-operations/${reportOperationId}/retry`).then( response => {
            return response
        })
    },
    retryAdminReportOperation(reportOperationId){
        setLanguage();
        getAuth();
        return report.post(`admin/report-operations/${reportOperationId}/retry`).then( response => {
            return response
        })
    },
    deleteReportOperation(reportOperationId){
        setLanguage();
        getAuth();
        return report.delete(`report-operations/${reportOperationId}`).then( response => {
            return response
        })
    },
    deleteAdminReportOperation(reportOperationId){
        setLanguage();
        getAuth();
        return report.delete(`admin/report-operations/${reportOperationId}`).then( response => {
            return response
        })
    },
    getReportOperation(reportOperationId){
        setLanguage();
        getAuth();
        return report.get(`report-operations/${reportOperationId}`).then( response => {
            return response
        })
    },
    getReportSettings(){
        setLanguage();
        getAuth();
        return report.get(`report-settings/`).then( response => {
            return response
        })
    },
    updateReportSettings(params){
        setLanguage();
        getAuth();
        return report.put(`report-settings/`, {...params}).then( response => {
            return response
        })
    }
}