import React, {useState, useEffect} from "react";
import { InputNumber, InputText, SelectDropdown, InputTimeBlock, InputDateBlock } from "commons/input-controls";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { StandartButton } from "commons/form-controls";
import s from '../../telegram-item.module.scss';
import moment from "moment";

const EveryPublication = (props) => {

    const getTargetMinValue = (alertPolicy) => {
        //setRerender(!rerender)
        if(alertPolicy && alertPolicy.metricType){
            if(alertPolicy.alertPolicy.includes('AGGREGATE') && (alertPolicy.metricType === 'COUNT' || alertPolicy.metricType === 'ABSOLUTE_COUNT')){
                return props.alertPolicy.metricType === 'COUNT' || props.alertPolicy.metricType === 'ABSOLUTE_COUNT' ? 1 : null
            }
            else
                return null
        }
        else
            return null

    }

    const getDate = (dateTime) => {
        let time = {}
        let date = dateTime ? moment(dateTime, "YYYY-MM-DDTHH:mm:ssZ") : moment();
        time.d = moment(date).format('DD');
        time.m = moment(date).format('MM');
        time.y = moment(date).format('YYYY');
        return time
    }

    const getTime = (dateTime) => {
        let time = {}
        let date = dateTime ? moment(dateTime, "YYYY-MM-DDTHH:mm:ssZ") : moment();
        time.h = moment(date).format('H');
        time.m = moment(date).format('mm');
        return time
    }

    const getDateTime = (date, time) => {
 
        let d = moment()
        d.set('year', date.y);
        d.set('month', date.m-1); 
        d.set('date', date.d);
        d.set('hour', time.h);
        d.set('minute', time.m);
        d.set('second', 0)
        props.updatePolicy('policyStartTime',d.format('YYYY-MM-DDTHH:mm:ssZ'), props.keyIndex)
    }

    const [alertPolicy, setAlertPolicy] = useState((props.alertPolicy && props.alertPolicy.alertPolicy) ? props.alertPolicy.alertPolicy : props.alertPolicy.policyType )
    const [metricTypes, setMetricTypes] = useState(props.metricTypes ? props.metricTypes : null )
    const [metricType, setMetricType] = useState((props.alertPolicy && props.alertPolicy.metricType) ? props.getValue(props.alertPolicy.metricType) : null );
    const [countOfPolicy, setCountOfPolicy] = useState((props.alertPolicy && props.alertPolicy.target) ? props.alertPolicy.target : 0 );
    const [deliverySystem, setDeliverySystem] = useState((props.alertPolicy && props.alertPolicy.deliveryType) ? props.alertPolicy.deliveryType : 'TELEGRAM' );
    const [sentiments, setSentiments] = useState(props.telegramSentiments ? props.telegramSentiments : 'NEUTRAL');
    const [sentiment, setSentiment] = useState((props.alertPolicy && props.alertPolicy.sentiment) ? props.getValue(props.alertPolicy.sentiment) : null );
    const [grouping, setGrouping] = useState(props.alertPolicy ? props.getValue(props.alertPolicy.grouping) : 'DAYLI');
    const [showOnly, setShowOnly] = useState(props.alertPolicy.id ? true : false);
    const [minTargetValue, setMinTargetvalue] = useState(getTargetMinValue(props.alertPolicy));
    const [showPolicyStartTime, setShowPolicyStartTime] = useState(props.alertPolicy && props.alertPolicy.policyStartTime ? true : false);
    const [expiryDate, setExpiryDate] = useState(getDate(props.alertPolicy && props.alertPolicy.policyStartTime ? props.alertPolicy.policyStartTime : null));
    const [policyStartTime, setPolicyStartTime] = useState(getTime(props.alertPolicy && props.alertPolicy.policyStartTime ? props.alertPolicy.policyStartTime : null));
    const [rerender, setRerender] = useState(false);

    useEffect(()=>{
        setAlertPolicy((props.alertPolicy && props.alertPolicy.alertPolicy) ? props.alertPolicy.alertPolicy : props.alertPolicy.policyType )
        setCountOfPolicy((props.alertPolicy && props.alertPolicy.target) ? props.alertPolicy.target : 0);
        setGrouping(props.alertPolicy ? props.getValue(props.alertPolicy.grouping) : 'DAYLI');
        setDeliverySystem((props.alertPolicy && props.alertPolicy.deliveryType) ? props.alertPolicy.deliveryType : 'TELEGRAM' );
        setSentiments(props.telegramSentiments && props.telegramSentiments !== undefined ? props.telegramSentiments : 'NEUTRAL');
        setSentiment((props.alertPolicy && props.alertPolicy.sentiment) ? props.getValue(props.alertPolicy.sentiment) : null );
        setMetricType((props.alertPolicy && props.alertPolicy.metricType) ? props.getValue(props.alertPolicy.metricType) : null );
        setShowOnly(props.alertPolicy.id ? true : false)
        setShowPolicyStartTime(props.alertPolicy && props.alertPolicy.policyStartTime ? true : false);
        setMinTargetvalue(getTargetMinValue(props.alertPolicy))
        setExpiryDate(getDate(props.alertPolicy && props.alertPolicy.policyStartTime ? props.alertPolicy.policyStartTime : null));
        setPolicyStartTime(getTime(props.alertPolicy && props.alertPolicy.policyStartTime ? props.alertPolicy.policyStartTime : null));
        setRerender(!rerender);
    },[props]);

    const selectCountOfPolicy = (e) => {
        props.updatePolicy('target', e, props.keyIndex)
    }

    const setDeliveryType = (deliveryType) => {
        props.updatePolicy('deliveryType', deliveryType, props.keyIndex)
    }

    const setSentimentType = (sentiment) => {
        props.updatePolicy('sentiment', sentiment.list, props.keyIndex)
    }
    const setGroupingType = (grouping) => {
        props.updatePolicy('grouping', grouping.list, props.keyIndex)
    }
    const setMetricTypeItem = (metricType) => {
        props.updatePolicy('metricType', metricType.list, props.keyIndex)
    }

    const setShowPolicyStartTimeButton = (type) => {

        setShowPolicyStartTime(!showPolicyStartTime)
        if(type){
            props.updatePolicy('policyStartTime',null, props.keyIndex)
        }
    }


    const setDateNumber = (index, value, type) => {
        let t = {d: null, m: null, y: null}
        if(type === 'day'){
            let d = value < 10 ? 0+''+value : value
            t = {d: d, m: expiryDate.m, y: expiryDate.y}
        }
        else if(type === 'month'){
                let m = value < 10 ? 0+''+value : value
                
                t = {d: expiryDate.d, m: m, y: expiryDate.y}
        }
        else if(type === 'year'){
            let y = value < 10 ? 0+''+value : value
            t = {d: expiryDate.d, m: expiryDate.m, y: y}
        }
        getDateTime(t, policyStartTime);
    }

    const setNumber = (index, value, type) => {
        let n = policyStartTime;
        if(!n)
            n = {h: 0, m: 0}
        if(type === 'first'){     
            n.h = value //< 10 ? 0+''+value : value;
        }
       else if(type === 'second'){
            n.m = value;
        }
        getDateTime(expiryDate, n);
    }

    const targetSelect = <InputNumber min={minTargetValue} showOnly={showOnly} labelText={props.getValue('COUNT')} callback={selectCountOfPolicy} value={countOfPolicy}/>
    const deliverySystemSelect = <SelectDropdown showOnly={showOnly} selected={deliverySystem} items={['TELEGRAM', 'EMAIL']} placeholder={props.getValue('DELIVERY_TYPE')} labelText={props.getValue('DELIVERY_TYPE')} setSelected={setDeliveryType}/>
    const sentimentsSelect = <SelectDropdown showOnly={showOnly} selected={sentiment} items={sentiments} placeholder={props.getValue('TONE')} labelText={props.getValue('TONE')} setSelected={setSentimentType}/>
    const groupingSelect = <SelectDropdown showOnly={showOnly} selected={grouping} items={props.groupings} placeholder={props.getValue('PERIODICITY')} labelText={props.getValue('PERIODICITY')} setSelected={setGroupingType}/>
    const metricTypesSelect = <SelectDropdown showOnly={showOnly} selected={metricType} items={metricTypes} placeholder={props.getValue('METRIC_TYPES')} labelText={props.getValue('METRIC_TYPES')} setSelected={setMetricTypeItem}/>
    const addPolicyStartButton = <StandartButton width={34} icon={PlusIcon} stroke={'dark'} background="background" callback={setShowPolicyStartTimeButton}/>
    const policyStart = <div className={s.policyStartTime}>
                            <div className={s.policyStartDate}>
                                <div style={{margin: '6px 0'}}>
                                    <InputDateBlock readOnly={showOnly} values={expiryDate} name={'date'} callback={setDateNumber} maxFirst={31} minFirst={1} maxSecond={12} minSecond={1} maxThird={2030} minThird={2020}/>
                                </div>
                                
                                <InputTimeBlock readOnly={showOnly} values={policyStartTime} callback={setNumber} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/>
                            </div>

                            {!showOnly ? <StandartButton width={32} icon={MinusIcon} stroke={'dark'} background="background" callback={setShowPolicyStartTimeButton.bind(this, true)}/> : null } 
                        </div>

    const startTime =   props.alertPolicy.alertPolicy.includes("AGGREGATE") ? <div className={s.policyStartTimeBlock}>
                            <span className={s.labelText}>{props.getValue('DELAYED_START_TIME')}</span>
                            {
                                showPolicyStartTime ? policyStart : addPolicyStartButton
                            }
                        </div> : null
    
    const getBlocksByType = (type, metricType = null) => {
        /*
    "AGGREGATE_PERCENT",
    "AGGREGATE_COUNT",
    "AGGREGATE_ABSOLUTE_COUNT"
        */
        switch(type){
            case 'EVERY_PUBLICATION':
                return  <div className={s.policyItem}>
                            { deliverySystemSelect }          
                        </div>
            case 'EVERY_PUBLICATION_PERCENT':
                return  <div className={s.policyItem}>
                            { targetSelect }
                            { deliverySystemSelect }
                            { groupingSelect }
                        </div>
            case 'AGGREGATE':
                return  <div className={s.policyItem}>
                            { metricTypesSelect }
                            { targetSelect }
                            { deliverySystemSelect }
                            { metricType !== 'ABSOLUTE_COUNT' ? groupingSelect : null }
                            { startTime }
                        </div>
            case 'AGGREGATE_PERCENT':
                return  <div className={s.policyItem}>
                            { targetSelect }
                            { deliverySystemSelect }
                            { groupingSelect }
                            { startTime }
                        </div>
            case 'AGGREGATE_COUNT':
                return  <div className={s.policyItem}>
                            { targetSelect }
                            { deliverySystemSelect }
                            { groupingSelect }
                            { startTime }
                        </div>
            case 'AGGREGATE_ABSOLUTE_COUNT':
                return  <div className={s.policyItem}>
                            { targetSelect }
                            { deliverySystemSelect }
                            { startTime }
                        </div>
            default:
                return  <div className={s.policyItem}>
                            { deliverySystemSelect }
                            { startTime }
                        </div>
        }
    }

    return (
        /*props.showPolicyItem ? */getBlocksByType(alertPolicy, props.alertPolicy && props.alertPolicy.metricType)
        /*:
        <React.Fragment></React.Fragment>*/
    )
}

export default EveryPublication