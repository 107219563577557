import { header, getLanguage, checkFilters } from "./header";

const analyticPage = header('storage/api/v1/topicscomparison/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    analyticPage.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    analyticPage.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const ComparisonService = {
    getComparisonSentiments(type = 'sentiment', uuids, since, till, grouping = "DAILY", filters = {}){
        setLanguage();
        getAuth();
        
        const params = {since, till, grouping, ...checkFilters(filters)}
        return analyticPage.post(`sentiments?${type}s=${uuids.join(',')}`, {...params}).then(response => {
            return response.data
        })
    },
    getComparisonSpecialMarks(type, uuids, since, till, grouping = "DAILY", filters = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}

        return analyticPage.post(`specialmarks?${type}s=${uuids.join(',')}`, {...params}).then(response => {
            return response.data
        })
    },
    getComparisonProfileLocations(type, uuids, since, till, grouping = "DAILY", filters = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}

        return analyticPage.post(`profilelocations?${type}s=${uuids.join(',')}`, {...params}).then(response => {
            return response.data
        })
    },
    getComparisonMediaTypes(type, uuids, since, till, grouping = "DAILY", filters = null, isNotMatched = false){

        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}

        return analyticPage.post(`mediatypes?${type}s=${uuids.join(',')}`, {...params}).then(response => {
            return response.data
        })
    },   
    getComparisonMediaScales(type, uuids, since, till, grouping = "DAILY", filters = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}
        return analyticPage.post(`mediascales?${type}s=${uuids.join(',')}`, {...params}).then(response => {
            return response.data
        })
    },
    getComparisonLanguages(type, uuids, since, till, grouping = "DAILY", filters = null){
        setLanguage();
        getAuth();
        const params = {since, till, grouping, ...checkFilters(filters)}

        return analyticPage.post(`languages?${type}s=${uuids.join(',')}`, {...params}).then(response => {
            return response.data
        })
    }
}