import React from "react";
import MigrationItem from "./migration-item";

class MigrationItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            migrationId: this.props.migrationId,
            migrationItemId: this.props.migration ? this.props.migration.id : null,
            userId: this.props.migration ? this.props.migration.userId : null,
            accountId: this.props.account && this.props.account.id ? this.props.account.id : null,
            type: this.props.type
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    componentDidMount(){
        if(this.props.migrationId){
            this.props.getMigrationOperation(this.props.migrationId)
        }
    }

    getAccount(){
        if(this.props.migration)
            this.props.getAccountById(this.props.migration.userId)
    }

    getAccountNameById = (accountId) => {
        if(this.props.adminAccounts){
            //console.log(accountId, this.props.adminAccounts)
        }
    }

    componentDidUpdate(){
        if(this.props.migration && !this.props.account){
            this.getAccount()
        }
    }

    getDocsProgress = (expectedDocs,  currentDocs) => {
        let result = currentDocs * 100 / expectedDocs
        return Number.isInteger(result) ? result : result.toFixed(2)
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.migrationId && (nextProps.migrationId && nextProps.migrationId !== nextState.migrationId || (nextProps.migration && (nextProps.migration.id !== nextState.migrationItemId || nextProps.migration.userId !== nextState.userId)) || (nextProps.account && nextProps.account.id !== nextState.accountId)) || nextProps.type !== nextState.type){
            this.setState({
                migrationId: nextProps.migrationId,
                migrationItemId: nextProps.migration ? nextProps.migration.id : null,
                userId: nextProps.migration ? nextProps.migration.userId : null,
                accountId: nextProps.account ? nextProps.account.id : null,
                type: nextProps.type
            })
            this.props.getMigrationOperation(nextProps.migrationId);
            this.getAccount()
            return true;
        }
        else{
            return false;
        }
    }

    render(){
        return <MigrationItem 
                            getValue={this.props.getValue} 
                            migration={this.props.migration} 
                            type={this.props.type} 
                            getTopicName={this.props.getTopicName}
                            getCompanyName={this.props.getCompanyName}
                            //getAccountNameById={this.props.adminAccounts}
                            getDocsProgress={this.getDocsProgress}
                            account={this.props.account}
                        />
    }

}

export default MigrationItemController;