import React from "react";
import Dropdown from "./dropdown";

class DropdownController extends React.Component{
    
    setSelectedPage = (name, type) => {
        this.props.selectPage(name, type);      
    }

    render(){
        return (
            <Dropdown {...this.props} setSelectedPage={this.setSelectedPage}/>
        )
    }
}

export default DropdownController;
