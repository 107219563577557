import React, {useState, useEffect, useRef, useLayoutEffect} from "react";
import s from "./filters.module.scss";
import { ReactComponent as AngleUp } from '../../assets/icons/angle-up.svg';
import { ReactComponent as AngleDown } from '../../assets/icons/angle-down.svg';
import { ReactComponent as OkIcon } from '../../assets/icons/ok.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import DropdownWithSearch from "../dropdown-with-search/dropdown-with-search";
import { StandartButton } from "../../commons/form-controls";
import Badge from "../badge/badge";

const Filters = (props) => {

    const [topicFilters, setTopicFilters] = useState(props.topicFilters ? props.topicFilters : []);

    useEffect(() => {
        setTopicFilters(props.topicFilters ? props.topicFilters : []);
    }, [props.topicFilters])

    const setSelected = (item) => {
        props.setFiltersSelected(item)
    }

    const setSelectedItems = (itemIndex, item) => {
        props.setFiltersItemsItemsSelected(itemIndex, item)
    }

    const getRandomInt = (max = 50) => {
        return Math.floor(Math.random() * max);
      }

    const isSelected = (item) => {
        return props.selected.includes(item) ? true : false
    }

    const buttonClick = (index, key) => {


    }

    const setFiltersChanged = () => {
        props.filtersIsChanged(true)
        props.setSelectedMyFilterMenu('')
    }
    
    const clearFilters = () => {
        props.clearFilters()
    }

    let filtersItems = topicFilters.map((item, j)=>{
        return <div className={s.filtersItemsBlock} key={j}>
                <button onClick={setSelected.bind(this, j)} className={s.filtersItem}>
                    {props.getValue(item.name)}
                    {<div className={s.filterRightBlock}>{props.applyedFilters[item.list] ? <Badge sum={props.applyedFilters[item.list].length} /> : null}{ isSelected(j) ? <AngleDown /> : <AngleUp />}</div>}
                </button>
                {isSelected(j) && <DropdownWithSearch 
                                                    filters={props.topicFilters}
                                                    clickOnCheckBox={buttonClick} 
                                                    getValue={props.getValue} 
                                                    indexkey={j} 
                                                    items={item.items}
                                                    selectedItems={props.selected}
                                                    clearAll={props.clearAll}
                                                    selectedItemItems={props.selectedItems}
                                                    setFiltersItemsSelected={props.setFiltersItemsSelected} 
                                                    setFiltersItemsItemsSelected={props.setFiltersItemsItemsSelected}
                                                    setClearSelected={props.setClearSelected}
                                                    selectedMyFilter={props.selectedMyFilter}
                                                    setMySelectedFilter={props.setMySelectedFilter}
                                                    setAddSelectedMyFilter={props.setAddSelectedMyFilter}
                                                    exceptions={props.exceptions} 
                                                    setException={props.setException} 
                                                    applyedFilters={props.applyedFilters}
                                                    name={item.list}
                                                    filterTags={props.tags}
                                                    pageName={props.pageName}
                                                    isChanged={props.isChanged}
                                                    withSelectAll={item.withSelectAll}
                                                    locations={props.locations}
                                                    filtersLocations={props.filtersLocations}
                                                    //isException={true}
                                                    />}
            </div>
    });
    return (
        <div className={s.Filters}>
            <div id={'FiltersButtons'}>
                <div className={s.buttonsBlock}>
                    <StandartButton callback={setFiltersChanged} disabled={!props.selectedMyFilter} title={props.getValue('APPLY')} icon={OkIcon} fill="white"/>
                    <StandartButton callback={clearFilters} disabled={false} title={props.getValue('RESET_ALL')} icon={CloseIcon} stroke="white"/>
                </div>
            </div>

            {filtersItems}
        </div>
    )
}

export default Filters;