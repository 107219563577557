import { header, getLanguage, checkFilters, getClauses } from "./header";

const storage = header('storage/api/v1/')

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    storage.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    storage.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const StorageService = {
    getTopicDocuments(uuid, rows = 10, page = 0, since, till, grouping = "DAILY", filters = null, subtopics = null, sort = null){
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters)}
        if(sort) params.sort = sort.list;
        let subtopicsURL = (subtopics && subtopics.length) ? `/subtopics/${[...subtopics]}` : '';
        setLanguage();
        getAuth();
        return storage.post(`search/topics/${uuid}${subtopicsURL}?rows=${rows}&page=${page}`, {...params}).then(response => {
            return response.data
        })
    },
    getTopicBlackListDocuments(uuid, rows = 10, page = 0, since, till, grouping = "DAILY", filters = null, subtopics = null, sort = null){
        let topics = [...uuid]
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters)}
        if(sort) params.sort = sort.list;
        setLanguage();
        getAuth();

        return storage.post(`search/blacklisted?rows=${rows}&page=${page}&topics=${topics}`, {...params}).then(response => {
            return response.data
        })
    },
    getDeletedDocuments(uuid, rows = 10, page = 0, since, till, grouping = "DAILY", filters = null, subtopics = null, sort = null){
        let topics = [...uuid]
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters)}
        if(sort) params.sort = sort.list;
        setLanguage();
        getAuth();
        return storage.post(`search/deleted?rows=${rows}&page=${page}&topics=${topics}`, {...params}).then(response => {
            return response.data
        })
    },
    getFavouriteDocuments(uuid, rows = 10, page = 0, since, till, grouping = "DAILY", filters = null, subtopics = null, sort = null){
        let topics = [...uuid]
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters)}
        if(sort) params.sort = sort.list;
        
        setLanguage();
        getAuth();
        return storage.post(`search/favourite/auto?rows=${rows}&page=${page}&topics=${topics}`, {...params}).then(response => {
            return response.data
        })
    },
    getFavouritePublications(uuid, rows = 10, page = 0, since, till, grouping = "DAILY", filters = null, sort = null){
        let topics = [...uuid]
        let params = {since, till, grouping, sort: "DATE_DESC", ...checkFilters(filters)}
        if(sort) params.sort = sort.list;
        
        setLanguage();
        getAuth();
        return storage.post(`search/favourite/manual?rows=${rows}&page=${page}&topics=${topics}`, {...params}).then(response => {
            return response.data
        })
    },
    getFiltersStatistic(uuid, rows = 10, page = 0, since, till, grouping = "DAILY"){
        
        const params = {since, till, grouping, sort: "DATE_DESC"}
        setLanguage();
        getAuth();
        
        return storage.post(`search/topics/${uuid}/filter?rows=${rows}&page=${page}`, {...params}).then(response => {
            return response.data
        })
    },
    getFiltersStatistics(since, till, topics, filtersFields, filters, subtopics){
        let clauses = null;
        if(filtersFields.clauses){
            let f = {...filtersFields}
            clauses = getClauses(f)
            delete filtersFields.clauses
        }
        let params = {since, till, sort: "DATE_DESC", ...checkFilters(filters)}
        let url = (subtopics && subtopics.length) ? `subtopics=${[...subtopics]}` : `topics=${topics}`
        
        if(clauses) params.clauses = clauses.clauses;
        
        setLanguage();
        getAuth();
        let fields = (filtersFields && filtersFields.length) ? filtersFields : []

        return storage.post(`search/filter?${url}&${fields.join("&")}`, {...params})
    },
    getTopicsFiltersStatistics(since, till, topicId, filtersFields, filters, subtopics, type){
        let clauses = null;
        if(filtersFields.clauses){
            let f = {...filtersFields}
            clauses = getClauses(f)
            delete filtersFields.clauses
        }
        let subtopicsURL = (subtopics && subtopics.length) ? `/subtopics/${[...subtopics]}` : '';
        //let params = {since, till, sort: "DATE_DESC"}
        let params = {since, till, sort: "DATE_DESC", ...checkFilters(filters)}
        
        if(clauses) params.clauses = clauses.clauses;
        
        setLanguage();
        getAuth();
        let fields = (filtersFields && filtersFields.length) ? filtersFields : []

        return storage.post(`search/topics/${topicId}${subtopicsURL}/filter?${fields.join("&")}`, {...params})
    },
    getBlackListFiltersStatistics(since, till, topics, filtersFields, filters, subtopics){
        let clauses = null;
        if(filtersFields.clauses){
            let f = {...filtersFields}
            clauses = getClauses(f)
            delete filtersFields.clauses
        }
        let params = {since, till, sort: "DATE_DESC", ...checkFilters(filters)}

        if(clauses) params.clauses = clauses.clauses;

        setLanguage();
        getAuth();
        let fields = (filtersFields && filtersFields.length) ? filtersFields : [];
        return storage.post(`search/filter/blacklisted?${fields.join("&")}&topics=${topics}`, {...params})
    },
    getDeletedFiltersStatistics(since, till, topics, filtersFields, filters){
        let clauses = null;
        if(filtersFields.clauses){
            let f = {...filtersFields}
            clauses = getClauses(f)
            delete filtersFields.clauses
        }
        let params = {since, till, sort: "DATE_DESC", ...checkFilters(filters)}
        if(clauses) params.clauses = clauses.clauses;
        setLanguage();
        getAuth();
        let fields = (filtersFields && filtersFields.length) ? filtersFields : [];
        return storage.post(`search/filter/deleted?${fields.join("&")}&topics=${topics}`, {...params})
    },
    getFavouriteManualFiltersStatistics(since, till, topics, filtersFields, filters){
        let clauses = null;
        if(filtersFields.clauses){
            let f = {...filtersFields}
            clauses = getClauses(f)
            delete filtersFields.clauses
        }
        let params = {since, till, sort: "DATE_DESC", ...checkFilters(filters)}
        if(clauses) params.clauses = clauses.clauses;

        setLanguage();
        getAuth();
        let fields = (filtersFields && filtersFields.length) ? filtersFields : [];
        return storage.post(`search/filter/favourite/manual?${fields.join("&")}&topics=${topics}`, {...params})
    },
    getFavouriteAutoFiltersStatistics(since, till, topics, filtersFields, filters){
        let clauses = null;
        if(filtersFields.clauses){
            let f = {...filtersFields}
            clauses = getClauses(f)
            delete filtersFields.clauses
        }
        let params = {since, till, sort: "DATE_DESC", ...checkFilters(filters)}
        if(clauses) params.clauses = clauses.clauses;

        setLanguage();
        getAuth();
        let fields = (filtersFields && filtersFields.length) ? filtersFields : [];
        return storage.post(`search/filter/favourite/auto?${fields.join("&")}&topics=${topics}`, {...params})
    },
    setDocumentStatus(signature, status){
        setLanguage();
        getAuth();
        return storage.patch(`documents/statuses/${status}`, [...signature]).then(response => {
            return response;
        })
    },
    setAuthorsStatus(topicUUId, profileUrl, status){
        setLanguage();
        getAuth();
        return storage.patch(`documents/topics/${topicUUId}/authors/${profileUrl}/statuses/${status}`).then(response => {
            return response;
        })
    },
    setFavouriteStatus(topicUUId, status, signatures){
        setLanguage();
        getAuth();
        return storage.patch(`documents/topics/${topicUUId}/favourite?status=${status}`, [...signatures]).then(response => {
            return response;
        })
    },
    setFavouriteFilter(topicUUId, status, since, till, grouping, filters){
        let f = {...filters}
        f['topics'] ? delete f['topics'] : null
        let params = {since, till, grouping, ...f}
        setLanguage();
        getAuth();
        return storage.post(`documents/topics/${topicUUId}/favourite?status=${status}`, {...params}).then(response => {
            return response;
        })
    },
    setDomainsStatus(topicUUId, domain, status){
        getAuth();
        return storage.patch(`documents/topics/${topicUUId}/domains/${domain}/statuses/${status}`).then(response => {
            return response
        })
    },
    addDocumentsTags(signature, tagUUID){
        setLanguage();
        getAuth();
        return storage.post(`documents/signatures/${signature}/tags/${tagUUID}`).then(response => {
            return response.data
        })
    },
    addListDocumnetsTags(signatures, tagUUID){
        setLanguage();
        getAuth();
        return storage.post(`documents/tags/${tagUUID}`, [...signatures]).then(response => {
            return response.data
        })
    },
    removeDocumentsTag(signature, tagUUID){
        setLanguage();
        getAuth();
        return storage.delete(`documents/signatures/${signature}/tags/${tagUUID}`).then(response => {
            return response.data
        })
    },
    removeListDocumentsTags(signatures, tagUUID){
        setLanguage();
        getAuth();
        return storage.delete(`documents/tags/${tagUUID}`, {data: [...signatures]}).then(response => {
            return response.data
        })
    },
    getNewPublications(uuid){
        setLanguage();
        getAuth();
        return storage.get(`search/topics/${uuid}/statistics`, {headers: { 'Content-Type': 'application/json'},data: {}}).then(response => {
            return response.data
        })
    },
    getSubtopicsNewPublications(uuid, subtopicUUId){
        setLanguage();
        getAuth();
        return storage.get(`search/topics/${uuid}/subtopics/${subtopicUUId}/statistics`, {headers: { 'Content-Type': 'application/json'},data: {}}).then(response => {
            return response.data
        })
    },
    updateSentimentByFilter(topicId, sentiment, since, till, grouping ,filters){
        let params = {since, till, grouping, ...checkFilters(filters)}
        setLanguage();
        getAuth();
        return storage.patch(`documents/topics/${topicId}/sentiments/${sentiment}`, {...params}).then(response => {
            return response
        })
    },
    addTagByFilter(topicId, tagUuid, since, till, grouping ,filters){
        let params = {since, till, grouping, ...checkFilters(filters)}
        setLanguage();
        getAuth();
        return storage.post(`documents/topics/${topicId}/tags/${tagUuid}`, {...params}).then(response => {
            return response
        })
    },
    removeTagByFilter(topicId, tagUuid, since, till, grouping ,filters){
        let params = {since, till, grouping, ...filters}
        setLanguage();
        getAuth();
        return storage.delete(`documents/topics/${topicId}/tags/${tagUuid}`, {headers: { 'Content-Type': 'application/json'}, data: {...params}}).then(response => {
            return response
        })
    },
    setStatusByFilter(topicId, status, since, till, grouping ,filters){
        
        let params = {since, till, grouping, ...checkFilters(filters)}
        setLanguage();
        getAuth();
        return storage.patch(`documents/topics/${topicId}/statuses/${status}`, {...params}).then(response => {
            return response
        })
    },
    updateFavouriteMultipleTopics(status, topics, feedType, since, till, grouping, filters){
        let params = {since, till, grouping, ...checkFilters(filters)}
        setLanguage();
        getAuth();
        return storage.post(`documents/favourite/?status=${status}&topics=${topics}&feedType=${feedType}`, {...params}).then(response => {
            return response
        })
    },
    updateSentimentMultipleTopics(sentiment, topics, feedType, since, till, grouping, filters){
        let f = {...filters}
        f['topics'] ? delete f['topics'] : null
        let params = {since, till, grouping, ...checkFilters(f)}
        setLanguage();
        getAuth();
        return storage.post(`documents/sentiments/${sentiment}?topics=${topics}&feedType=${feedType}`, {...params}).then(response => {
            return response
        })
    },
    updateStatusMultipleTopics(status, topics, feedType, since, till, grouping, filters){
        let params = {since, till, grouping, ...checkFilters(filters)}
        setLanguage();
        getAuth();
        return storage.post(`documents/statuses/${status}?topics=${topics}&feedType=${feedType}`, {...params}).then(response => {
            return response
        })
    },
    updateTagsMultipleTopics(tagUUID, topics, subtopicIds, feedType, since, till, grouping, filters){
        let f = {...filters}
        f['topics'] ? delete f['topics'] : null

        let params = {since, till, grouping, ...checkFilters(f)}
        if(subtopicIds) params.subtopicIds = subtopicIds
        setLanguage();
        getAuth();
        return storage.patch(`documents/tags/${tagUUID}?topics=${topics}&feedType=${feedType}`, {...params}).then(response => {
            return response
        })
    },
    deleteTagsMultipleTopics(tagUUID, topics, subtopicIds, feedType, since, till, grouping, filters){
        //filters['topics'] ? delete filters['topics'] : null
        let f = {...filters}
        f['topics'] ? delete f['topics'] : null
        let params = {since, till, grouping, ...checkFilters(f)}
        if(subtopicIds) params.subtopicIds = subtopicIds
        setLanguage();
        getAuth();
        return storage.delete(`documents/tags/${tagUUID}/filter?topics=${topics}&feedType=${feedType}`, {headers: { 'Content-Type': 'application/json'},data: {...params}}).then(response => {
            return response
        })
    },
    checkProfiles(profile){
        setLanguage();
        getAuth();
        let params = {
            url: profile
        }
        return storage.post(`/topics/socnetprofiles/archive`, {...params}).then( response => {
            return response.data
        })
    },
    checkResources(profile){
        setLanguage();
        getAuth();
        let params = {
            domain: profile
        }
        return storage.post(`/topics/resources/archive`, {...params}).then( response => {
            return response.data
        })
    },
}