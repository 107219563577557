export const getMediaTypesLabelText = (mediaType) => {
    switch(mediaType){
        case 'social':
            return 'SOCIAL_NETWORKS'
        case 'news_agency':
            return 'NEWS_AGENCIES_2'
        case 'print':
            return 'PRINT_MAS_MEDIA'
        case 'internet':
            return 'WEBSITES'
        case 'tv':
            return 'TELEVISION_2'
        default:
            return mediaType
    }
}

let language = 'rus'

export const getLanguage = () => {
    return sessionStorage.getItem('selectedLanguage') ? sessionStorage.getItem('selectedLanguage').toLocaleLowerCase() : 'ru';
}

let languagesPack = null

const retJs = () => {
    let m = getLanguage();
    
    if(m === undefined || m === 'undefined')
        m = language;
    languagesPack = require(`./tiltles_${m ? m : 'rus'}.js`).default;
}



export const getTranslate = (value) => {
    if(language !== getLanguage() || !languagesPack){
        language = getLanguage()
        retJs();
    }
    return languagesPack[value] ? languagesPack[value] : value 
}