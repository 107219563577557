const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";

const selectDropdown = {
    selectedItems: []
}

const SelectDropdownWithSearch = (state = selectDropdown, action) => {
    switch(action.type){
        case SET_SELECTED_ITEMS:
            let index = {key: action.key, index: action.selectedItem}
            let item = state.selectedItems.find(k => {return k.key === action.key && k.index === action.selectedItem});
            if(item !== undefined) return {...state, selectedItems: state.selectedItems.map(k => k.index !== action.index)}
            else return {...state, selectedItems: [...state.selectedItems, index]}
        default:
            return state
    }
}

export default SelectDropdownWithSearch;

export const setSelectedItems = (selectedItem, key = null) => {
    return {
        type: SET_SELECTED_ITEMS,
        selectedItem: selectedItem,
        key: key
    }
}