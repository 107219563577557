import AdminCompaniesController from "./admin-companies-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { getValue } from "store/languages";
import { getMe } from "store/account";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { modalShowToggle } from "store/modal"
import { getSuperadminCompanies, getCompanyAccounts, getTopics, getCompanyTopics, deleteCompany, setCompanyId, setCompanyName, setCompanyIndex, createCompany, updateCompany, getTopicAccounts, getAccountTopics, getMarkers, getMarkerTopics, setTopicId } from "store/superadmin";
import { getCompany } from "store/admin"
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getCompaniesTable } from "selectors/superadmin-selector"
import { setItemsCount, setCurrentPage, setShowedItemsCount, setToDefaultPaginator } from "store/paginator";

let mapStateToProps = (state) => {

    return {
        companies: state.superadmin.companies,
        companiesData: getCompaniesTable(state),
        accounts: state.superadmin.accounts,
        isSuperadmin: state.Account.isSuperadmin,
        companyStatuses: state.superadmin.companyStatuses,
        companyId: state.superadmin.companyId,
        companyName: state.superadmin.companyName,
        companyIndex: state.superadmin.companyIndex,
        topics: state.superadmin.topics,
        topicId: state.superadmin.topicId,
        accountId: state.superadmin.accountId,
        role: state.Account.role,
        adminCompanyId: state.Account.companyId,
        selectedLanguage: state.languages.selectedLanguage,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        isFetching: state.superadmin.isSuperadminCompaniesFetching,
        companiesCount: state.superadmin.companiesCount
        //companyTopics: getCompanyTopicsSelector(state)
    }
}

const AdminCompaniesContainer =  compose(
    connect(mapStateToProps, {
        getValue,
        getMe,
        getSuperadminCompanies,
        getTopics,
        getCompany,
        getCompanyAccounts,
        getCompanyTopics,
        createCompany,
        updateCompany,
        deleteCompany,
        setCompanyId,
        modalShowToggle,
        setRightMenuComponent, 
        setShowRightMenu, 
        setRemoveRightMenuComponent,
        getTopicAccounts,
        getAccountTopics,
        getMarkers,
        getMarkerTopics,
        setTopicId,
        setItemsCount, 
        setCurrentPage, 
        setShowedItemsCount,
        setToDefaultPaginator,
    }),
withAuthRedirect)(AdminCompaniesController)

export default AdminCompaniesContainer;